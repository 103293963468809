import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_USER_ID',
  'VERIFY_USER_ID_SUCCESS',
  'VERIFY_USER_ID_FAIL',
  'SET_USER_ID',
  'ENABLE_USERID_SUBMIT_BUTTON',
  'CANCEL_REGISTRATION_VERIFY_USERID',  
  'CHECK_ERROR_MESSAGE',
  'GET_UID_OPTIONS_SUCCESS'
);

const { VERIFY_USER_ID, SET_USER_ID , ENABLE_USERID_SUBMIT_BUTTON , CANCEL_REGISTRATION_VERIFY_USERID , CHECK_ERROR_MESSAGE , GET_UID_OPTIONS_SUCCESS } = types;

const verifyUserIdSent = createAction(VERIFY_USER_ID);

const setUserIdSent = createAction(SET_USER_ID);

const enableSubmitButtonSent = createAction(ENABLE_USERID_SUBMIT_BUTTON);

const cancelRegistrationSent = createAction(CANCEL_REGISTRATION_VERIFY_USERID);

const checkUserIdErrMessageSent = createAction(CHECK_ERROR_MESSAGE);

const getIdentityOptionsSucessSent = createAction(GET_UID_OPTIONS_SUCCESS);

const verifyUserId = inputUserId => verifyUserIdSent(inputUserId);

const setUserId = inputUserId => setUserIdSent({ userId: inputUserId });

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelRegistration = () => cancelRegistrationSent();

const checkUserIdErrMessage = () => checkUserIdErrMessageSent();

const getIdentityOptionsSucess = () => getIdentityOptionsSucessSent();

export const actions = {
  verifyUserId,
  setUserId,
  enableSubmitButton,
  cancelRegistration,
  checkUserIdErrMessage,
  getIdentityOptionsSucess
};
