import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(SMS_NAMESPACE, 'GET_TRANSACTION_DETAILS', 'GET_TRANSACTION_DETAILS_SUCCESS', 'GET_TRANSACTION_DETAILS_FAIL','UPDATE_LOCALE_FIELD','NEXO_SSR_REDIRECTION');

const { GET_TRANSACTION_DETAILS , NEXO_SSR_REDIRECTION } = types;

const getTransactionDetailsSent = createAction(GET_TRANSACTION_DETAILS);

const getNexoSSRRedirectionSent = createAction(NEXO_SSR_REDIRECTION);

const getTransactionDetails = () => getTransactionDetailsSent();

const getNexoSSRRedirection = () => getNexoSSRRedirectionSent();

export const loadTransactionDetails = () => getTransactionDetails();

export const actions = {
  getTransactionDetails,
  getNexoSSRRedirection
};
