import React, { Component } from 'react';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Radio,
  Alert
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { ReactGA, WindowUtil } from '../../util';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import { constants } from '../../redux/ducks/find-me/find-me-constants';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import BuildingIcon from 'adp-react-icons/lib/fa/building-o';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';

const isBlank = str => (str || '').trim().length === 0;
var ua = window.navigator.userAgent;
var isIE = (ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident") > -1 || ua.indexOf("Edge") > -1) ? true : false;
let overlayPlacement = "right";
let showAlertFlag = false;
let setAutoFocusOff;

class FindMeContractorSivForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: constants.SSN
    };
    this.handleSivRadio = this.handleSivRadio.bind(this);
    this.handleMonthDaySelection = this.handleMonthDaySelection.bind(this);
  }

  componentWillMount() {
    this.props.enableSubmitButton(false);
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
    WindowUtil.setlayoutback(false);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.contractorSivObj.sivOptions != null) {
      this.checkContractorSivFormReadiness(nextProps);
      this.handleMonthDaySelection(nextProps);
    }
  };

  componentDidUpdate(oldProps) {
    if (oldProps.contractorSivObj != undefined) {
      const nextProps = this.props
      const nextVerifyUser = nextProps.contractorSivObj.verifyUserCall || false;
      const prevVerifyUser = oldProps.contractorSivObj.verifyUserCall || false;

      if (nextVerifyUser && !prevVerifyUser) {
        nextProps.contractorVerifyUserInfo();
      }
    }
  }

  handleSivRadio(option) {
    this.setState({
      option
    });
    let payload = {};
    payload.fieldKey = constants.RADIO;
    payload.fieldValue = option;
    this.props.setContractorSivField(payload);
  }

  checkContractorSivFormReadiness = (nextProps) => {
    let formValid = true;

    if (this.state.option === '')
      formValid = false;
    if (isBlank(nextProps.contractorSivObj.firstName) || !nextProps.contractorSivObj.firstNameValid)
      formValid = false;
    if (isBlank(nextProps.contractorSivObj.lastName) || !nextProps.contractorSivObj.lastNameValid)
      formValid = false;
    if (isBlank(nextProps.contractorSivObj.zipcode) || !nextProps.contractorSivObj.zipcodeValid)
      formValid = false;
    if (isBlank(nextProps.contractorSivObj.fullDay) || isBlank(nextProps.contractorSivObj.fullMonth) || isBlank(nextProps.contractorSivObj.fullYear))
      formValid = false;
    if (nextProps.radioValue === constants.SSN && (isBlank(nextProps.contractorSivObj.ssn) || !nextProps.contractorSivObj.ssnValid))
      formValid = false;
    if (nextProps.radioValue === constants.TIN) {
      if (isBlank(nextProps.contractorSivObj.tin) || !nextProps.contractorSivObj.tinValid)
        formValid = false;
      if (isBlank(nextProps.contractorSivObj.companyName) || !nextProps.contractorSivObj.companyNameValid)
        formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  };

  handleMonthDaySelection = (nextProps) => {
    if (nextProps.contractorSivObj.sivOptions.siv_fullhiredate) {
      const inputMonthId = nextProps.contractorSivObj.sivOptionsCalenderItems.fullMonthItems.indexOf(nextProps.contractorSivObj.fullMonth) + 1;
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        nextProps.contractorSivObj.sivOptionsCalenderItems.fullDayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        nextProps.contractorSivObj.sivOptionsCalenderItems.fullDayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        nextProps.contractorSivObj.sivOptionsCalenderItems.fullDayItems = constants.monthWith29days;
      }
    }
  }

  render() {
    const {
      findMeObj,
      isMobileBrowser,
      isMobile,
      gaCategory,
      isFormValid,
      showModalMessage,
      showModalWarn,
      showModalTitle,
      cancelAndCheckYourEntries,
      contractorSivObj,
      setContractorSivField,
      verifyContractorSivData,
      goToEmailPhoneSearchPage,
      contractorOrgSelected,
      radioValue
    } = this.props;

    const tooltip = (message) =>
      (<Popover id="C_tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="contractorErrorPopOver"
        style={{ display: 'none' }}
        />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
          >
          <div role="alert">{msg}</div>
        </Popover>
      ));

    const updateSivFieldValueHandler = (fieldKey, fieldValue) => {
      let payload = {};
      payload.fieldKey = fieldKey;
      payload.fieldValue = fieldValue;
      return setContractorSivField(payload);
    }

    const _handleSivSubmitKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'CONTRACTOR_SIV_DATA', 'SUBMIT');
      this.props.verifyContractorSivData();
    }

    return (
      <div>

        <div className="fm-form-info-padding">

          <form id="contractorInfoForm" onSubmit={_handleSivSubmitKeyPress}>

            {/* Web contractor options start */}
            {(contractorSivObj.showAlert) && <Alert className="fm-variant-alert-align" type="error" id="contractorInfoAlert" > <p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(contractorSivObj.showAlertMessage)}</p></Alert>}

            {(!contractorSivObj.showAlert) && !contractorSivObj.showMultiOrgs && <div id="contractorInfoDiv" style={{ paddingLeft: '7%', paddingRight: '6%' }} className="heading-text fm-emailphone-page-content">{<FormattedMessage id="findMe.variant.contractorInfo" />}</div>}

            {window.innerWidth > 479 && !contractorSivObj.showMultiOrgs && (<div>
              <div className="vdl-row">
                <div className="vdl-col-sm-6 vdl-col-xs-12">
                  <label id="contractor_fname_label">{contractorSivObj.sivOptions["siv_firstname"].labelName}
                    <span className="fm-pii-required-field-icon">*</span>
                    <span id="contractor_fname_tooltip" className="vb-question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                      <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']}
                      isAriaExpanded={ false }
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc' }))} >
                        <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                      </OverlayTrigger>
                    </span></label>
                </div>

                <div className="vdl-col-sm-6 vdl-col-xs-12">
                  <label id="contractor_lname_label">{contractorSivObj.sivOptions["siv_lastname"].labelName}
                    <span className="fm-pii-required-field-icon">*</span>
                  </label>
                </div>
              </div>

              <div className="vdl-row">
                <div className="vdl-col-sm-6 vdl-col-xs-12">
                  <OverlayTrigger isAriaExpanded={ false }
                    trigger={['click', 'focus']} placement="bottom"
                    overlay={errorPopover(contractorSivObj.firstNameValid, contractorSivObj.sivOptions["siv_firstname"].invalidMessage)}
                    >
                    <Textbox style={{ marginTop: "5px" }} aria-label={contractorSivObj.sivOptions["siv_firstname"].labelName}
                      id="contractor_fname_textbox" autoFocus={setAutoFocusOff} autocomplete="given-name"
                      value={contractorSivObj.firstName}
                      className={contractorSivObj.firstNameValid ? undefined : 'vdl-validation-error'}
                      onChange={(value) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_firstname"].idenDataId });
                        return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_firstname"].idenDataId, value);
                      } }
                      />
                  </OverlayTrigger>
                </div>

                <div className="vdl-col-sm-6 vdl-col-xs-12">
                  <OverlayTrigger isAriaExpanded={ false }
                    trigger={['click', 'focus']} placement="bottom"
                    overlay={errorPopover(contractorSivObj.lastNameValid, contractorSivObj.sivOptions["siv_lastname"].invalidMessage)}
                    >
                    <Textbox style={{ marginTop: "5px" }} aria-label={contractorSivObj.sivOptions["siv_lastname"].labelName}
                      id="contractor_lname_textbox" autocomplete="family-name"
                      value={contractorSivObj.lastName}
                      className={contractorSivObj.lastNameValid ? undefined : 'vdl-validation-error'}
                      onChange={(value) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_lastname"].idenDataId });
                        return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_lastname"].idenDataId, value);
                      } }
                      />
                  </OverlayTrigger>
                </div>

              </div>

              {/* hiredate and zipcode Start */}

              <div className="form-group form-align" style={{ marginTop: "15px" }}>
                <div className="vdl-row">
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <label id="contractor_fulldob_label" htmlFor="fulldob_Label">{contractorSivObj.sivOptions["siv_fullhiredate"].labelName}
                      <span className="fm-pii-required-field-icon">*</span>
                    </label>
                  </div>
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <label id="contractor_zipcode_label" htmlFor="zipcode_Label">{contractorSivObj.sivOptions["siv_zipcode"].labelName}
                      <span className="fm-pii-required-field-icon">*</span>
                    </label>
                  </div>
                </div>
                <div className="vdl-row">
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <div className="fm-pii-dropdown-container-div">
                      <div className="fm-pii-month-dropdown-div">
                        <DropdownList aria-label="monthDropdown" 
                          id="contractor_month" autocomplete="bday-month"
                          value={contractorSivObj.fullMonth}
                          data={contractorSivObj.sivOptionsCalenderItems.fullMonthItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                            return updateSivFieldValueHandler(constants.SIV_MONTH, value)
                          } }
                          />
                      </div>
                      <div className="fm-pii-day-dropdown-div">
                        <DropdownList aria-label="dayDropdown" 
                          id="contractor_day" autocomplete="bday-day"
                          value={contractorSivObj.fullDay}
                          data={contractorSivObj.sivOptionsCalenderItems.fullDayItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                            return updateSivFieldValueHandler(constants.SIV_DAY, value)
                          } }
                          />
                      </div>
                      <div className="fm-pii-year-dropdown-div">
                        <DropdownList aria-label="yearDropdown" 
                          id="contractor_year" autocomplete="bday-year"
                          value={contractorSivObj.fullYear}
                          data={contractorSivObj.sivOptionsCalenderItems.fullYearItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_year' })}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR');
                            return updateSivFieldValueHandler(constants.SIV_YEAR, value)
                          } }
                          />
                      </div>
                    </div>
                  </div>
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.zipcodeValid, contractorSivObj.sivOptions["siv_zipcode"].invalidMessage)}
                      >
                      <Textbox aria-label={contractorSivObj.sivOptions["siv_zipcode"].labelName}
                        id="contractor_zipcode_textbox" autocomplete="postal-code"
                        value={contractorSivObj.zipcode}
                        className={contractorSivObj.zipcodeValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_zipcode"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_zipcode"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger>
                  </div>
                </div>

              </div>
              {/* hiredate and zipcode End */}

              {/* TIN and SSN start */}
              {(<div style={{ marginTop: "20px" }}>
                <div className="vdl-row">

                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <Radio className="cf-radio-toggle"
                      id="contractor_ssn_radio"
                      radioGroupValue={this.state.option}
                      value="ssn"
                      inline={true}
                      onKeyPress={ (e) => {    
                        if(e.key === 'Enter'){
                          e.preventDefault();
                          return;    
                        }                                        
                      } }
                      onChange={this.handleSivRadio}
                      >
                      {contractorSivObj.sivOptions["siv_ssn"].labelName}
                    </Radio>
                    <span id="contractor_ssn_tooltip" className="fm-pii-ssn-span" onClick={() =>

                      ReactGA.sendevent(gaCategory, "SSN", 'TOOLTIP')}>
                      <OverlayTrigger placement="right" trigger={['click','focus']} isAriaExpanded={ false }
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                        <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                      </OverlayTrigger>
                    </span>
                  </div>

                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <Radio className="cf-radio-toggle"
                      id="contractor_tin_radio"
                      radioGroupValue={this.state.option}
                      value="tin"
                      inline={true}
                      onKeyPress={ (e) => {    
                        if(e.key === 'Enter'){
                          e.preventDefault();
                          return;    
                        }                                        
                      } }
                      onChange={this.handleSivRadio}>
                      {contractorSivObj.sivOptions["siv_tin"].labelName}
                    </Radio>
                  </div>
                </div>

                <div className="vdl-row">

                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.ssnValid, contractorSivObj.sivOptions["siv_ssn"].invalidMessage)}
                      >
                      <Textbox style={{ marginTop: "5px", width: '92%' }}
                        id="contractor_ssn_textBox" type="password" aria-label={contractorSivObj.sivOptions["siv_ssn"].labelName}
                        disabled={this.state.option == "tin" ? true : false}
                        value={contractorSivObj.ssn}
                        className={contractorSivObj.ssnValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_ssn"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_ssn"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                  </div>

                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.tinValid, contractorSivObj.sivOptions["siv_tin"].invalidMessage)}
                      >
                      <Textbox style={{ marginTop: "5px" }} aria-label={contractorSivObj.sivOptions["siv_tin"].labelName}
                        id="contractor_tin_textBox" type="password"
                        value={contractorSivObj.tin}
                        disabled={this.state.option == "ssn" ? true : false}
                        className={contractorSivObj.tinValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_tin"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_tin"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>)}

              {/* ********************* TIN and SSN End ********************* */}


              {/* companyname start */}
              {this.state.option == constants.TIN && (<div style={{ marginTop: '15px' }}>
                <div className="vdl-row">
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <label>
                      <span></span>
                    </label>
                  </div>

                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <label id="contractor_companyname_label">{contractorSivObj.sivOptions["siv_companyname"].labelName}
                      <span className="fm-pii-required-field-icon">*</span>
                    </label>
                  </div>
                </div>

                <div className="vdl-row">
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                  </div>
                  <div className="vdl-col-sm-6 vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.companyNameValid, contractorSivObj.sivOptions["siv_companyname"].invalidMessage)}
                      >
                      <Textbox style={{ marginTop: "5px" }} aria-label={contractorSivObj.sivOptions["siv_companyname"].labelName}
                        id="contractor_companyName_textBox" autocomplete="organization"
                        value={contractorSivObj.companyName}
                        className={contractorSivObj.companyNameValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_companyname"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_companyname"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>)}
            </div>)}
            {/* companyname End */}
            {/* Web contractor options End */}

            {/* Mobile contractor options start */}
            {window.innerWidth <= 479 && !contractorSivObj.showMultiOrgs && (<div>

              <div className="vdl-row">
                <div className="vdl-col-xs-12">
                  <label id="contractor_fname_label">{contractorSivObj.sivOptions["siv_firstname"].labelName}
                    <span className="fm-pii-required-field-icon">*</span>
                    <span id="contractor_fname_tooltip" className="vb-question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                      <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc' }))} >
                        <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                      </OverlayTrigger>
                    </span></label>
                </div>
                <div className="vdl-col-xs-12">
                  <OverlayTrigger isAriaExpanded={ false }
                    trigger={['click', 'focus']} placement="bottom"
                    overlay={errorPopover(contractorSivObj.firstNameValid, contractorSivObj.sivOptions["siv_firstname"].invalidMessage)}
                    >
                    <Textbox style={{ marginBottom: "20px" }} aria-label={contractorSivObj.sivOptions["siv_firstname"].labelName}
                      id="contractor_fname_textbox" autoFocus={setAutoFocusOff}
                      value={contractorSivObj.firstName} autocomplete="given-name"
                      className={contractorSivObj.firstNameValid ? undefined : 'vdl-validation-error'}
                      onChange={(value) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_firstname"].idenDataId });
                        return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_firstname"].idenDataId, value);
                      } }
                      />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="vdl-row">
                <div className="vdl-col-xs-12">
                  <label id="contractor_lname_label">{contractorSivObj.sivOptions["siv_lastname"].labelName}
                    <span className="fm-pii-required-field-icon">*</span>
                  </label>
                </div>
                <div className="vdl-col-xs-12">
                  <OverlayTrigger isAriaExpanded={ false }
                    trigger={['click', 'focus']} placement="bottom"
                    overlay={errorPopover(contractorSivObj.lastNameValid, contractorSivObj.sivOptions["siv_lastname"].invalidMessage)}
                    >
                    <Textbox style={{ marginBottom: "20px" }} aria-label={contractorSivObj.sivOptions["siv_lastname"].labelName}
                      id="contractor_lname_textbox" autocomplete="family-name"
                      value={contractorSivObj.lastName}
                      className={contractorSivObj.lastNameValid ? undefined : 'vdl-validation-error'}
                      onChange={(value) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_lastname"].idenDataId });
                        return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_lastname"].idenDataId, value);
                      } }
                      />
                  </OverlayTrigger>
                </div>
              </div>

              {/* hiredate and zipcode Start */}

              <div className="form-group form-align" style={{ marginBottom: "20px" }}>
                <div className="vdl-row">
                  <div className="vdl-col-xs-12">
                    <label id="contractor_fulldob_label" htmlFor="fulldob_Label">{contractorSivObj.sivOptions["siv_fullhiredate"].labelName}
                      <span className="fm-pii-required-field-icon">*</span>
                    </label>
                  </div>
                  <div className="vdl-col-xs-12">
                    <div className="fm-pii-dropdown-container-div" id={contractorSivObj.sivOptions["siv_fullhiredate"].labelName}>
                      <div className="fm-mobile-pii-month-dropdown-div" role="group" aria-labelledby={contractorSivObj.sivOptions["siv_fullhiredate"].labelName}>
                        <DropdownList aria-label="monthDropdown" 
                          id="contractor_month" autocomplete="bday-month"
                          value={contractorSivObj.fullMonth}
                          data={contractorSivObj.sivOptionsCalenderItems.fullMonthItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                            return updateSivFieldValueHandler(constants.SIV_MONTH, value)
                          } }
                          />
                      </div>
                      <div className="fm-mobile-pii-day-dropdown-div">
                        <DropdownList aria-label="dayDropdown" 
                          id="contractor_day" autocomplete="bday-day"
                          value={contractorSivObj.fullDay}
                          data={contractorSivObj.sivOptionsCalenderItems.fullDayItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                            return updateSivFieldValueHandler(constants.SIV_DAY, value)
                          } }
                          />
                      </div>
                      <div className="fm-mobile-pii-day-dropdown-div">
                        <DropdownList aria-label="yearDropdown" autocomplete="bday-year"
                          id="contractor_year"
                          value={contractorSivObj.fullYear} 
                          data={contractorSivObj.sivOptionsCalenderItems.fullYearItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_year' })}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR');
                            return updateSivFieldValueHandler(constants.SIV_YEAR, value)
                          } }
                          />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="vdl-row" style={{ marginTop: "20px" }}>
                  <div className="vdl-col-xs-12">
                    <label id="contractor_zipcode_label">{contractorSivObj.sivOptions['siv_zipcode'].labelName}
                      <span className="fm-pii-required-field-icon">*</span>
                    </label>
                  </div>

                  <div className="vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.zipcodeValid, contractorSivObj.sivOptions["siv_zipcode"].invalidMessage)}
                      >
                      <Textbox aria-label={contractorSivObj.sivOptions["siv_zipcode"].labelName}
                        id="contractor_zipcode_textbox"
                        value={contractorSivObj.zipcode} autocomplete="postal-code"
                        className={contractorSivObj.zipcodeValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_zipcode"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_zipcode"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger>
                  </div>
                </div>

              </div>
              {/*hiredate and zipcode End */}

              {/* SSN and TIN start */}
              {(<div >

                {/* SSN block  */}
                <div className="vdl-row">
                  <div className="vdl-col-xs-12">
                    <Radio className="cf-radio-toggle"
                      id="contractor_ssn_radio"
                      radioGroupValue={this.state.option}
                      value="ssn"
                      inline={true}
                      onKeyPress={ (e) => {    
                        if(e.key === 'Enter'){
                          e.preventDefault();
                          return;    
                        }                                        
                      } }
                      onChange={this.handleSivRadio}
                      >
                      {contractorSivObj.sivOptions["siv_ssn"].labelName}
                    </Radio>
                    <span id="contractor_ssn_tooltip" className="fm-pii-ssn-span" onClick={() => ReactGA.sendevent(gaCategory, "SSN", 'TOOLTIP')}>
                      <OverlayTrigger placement="bottom" trigger="click" isAriaExpanded={ false }
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                        <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                      </OverlayTrigger>
                    </span>
                  </div>

                  <div className="vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.ssnValid, contractorSivObj.sivOptions["siv_ssn"].invalidMessage)}
                      >
                      <Textbox style={{ marginTop: "5px", width: '92%' }}
                        id="contractor_ssn_textBox" type="password" aria-label={contractorSivObj.sivOptions["siv_ssn"].labelName}
                        disabled={this.state.option == "tin" ? true : false}
                        value={contractorSivObj.ssn}
                        className={contractorSivObj.ssnValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_ssn"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_ssn"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                  </div>
                </div>

                {/* TIN block */}
                <div className="vdl-row">
                  <div className="vdl-col-xs-12">
                    <Radio className="cf-radio-toggle"
                      id="contractor_tin_radio"
                      radioGroupValue={this.state.option}
                      value="tin"
                      inline={true}
                      onKeyPress={ (e) => {    
                        if(e.key === 'Enter'){
                          e.preventDefault();
                          return;    
                        }                                        
                      } }
                      onChange={this.handleSivRadio}
                      >
                      {contractorSivObj.sivOptions["siv_tin"].labelName}
                    </Radio>
                  </div>
                  <div className="vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.tinValid, contractorSivObj.sivOptions["siv_tin"].invalidMessage)}
                      >
                      <Textbox style={{ marginTop: "5px", marginBottom: "20px" }}
                        id="contractor_tin_textBox" type="password" aria-label={contractorSivObj.sivOptions["siv_tin"].labelName}
                        value={contractorSivObj.tin}
                        disabled={this.state.option == "ssn" ? true : false}
                        className={contractorSivObj.tinValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_tin"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_tin"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>)}

              {/* ********************* SSN and TIN End ********************* */}

              {/* companyname start */}
              {this.state.option === constants.TIN && (<div>
                <div className="vdl-row">

                  <div className="vdl-col-xs-12">
                    <label id="contractor_companyname_label">{contractorSivObj.sivOptions["siv_companyname"].labelName}
                      <span className="fm-pii-required-field-icon">*</span>
                    </label>
                  </div>
                </div>

                <div className="vdl-row">
                  <div className="vdl-col-xs-12">
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={['click', 'focus']} placement="bottom"
                      overlay={errorPopover(contractorSivObj.companyNameValid, contractorSivObj.sivOptions["siv_companyname"].invalidMessage)}
                      >
                      <Textbox style={{ marginTop: "5px" }} aria-label={contractorSivObj.sivOptions["siv_companyname"].labelName}
                        id="contractor_companyName_textBox" autocomplete="organization"
                        value={contractorSivObj.companyName}
                        className={contractorSivObj.companyNameValid ? undefined : 'vdl-validation-error'}
                        onChange={(value) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: contractorSivObj.sivOptions["siv_companyname"].idenDataId });
                          return updateSivFieldValueHandler(contractorSivObj.sivOptions["siv_companyname"].idenDataId, value);
                        } }
                        />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>)}
            </div>)}
            {/* companyName End */}
            {/* Mobile contractor options End */}

            {/* Multi Org start */}
            {(contractorSivObj.showMultiOrgs) && (<div className="fm-multi-form-padding" style={{ marginBottom: "20px" }}>
              <div id="contractor_multiorg">
                {contractorSivObj.multiOrgs.map((value, index) => (

                  <div role="button" tabIndex="0" className="vdl-row vf-row-size highlight-options focus-selector" id={index} key={index} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'ORG'); return contractorOrgSelected(index) } } 
                 onKeyPress={ (e) => { if(e.key === 'Enter'){ return contractorOrgSelected(index);     
                            ReactGA.sendevent(gaCategory, 'CLICK', 'ORG');  }                                        
                        } }>
                    <BuildingIcon className="link-content vf-col-sm-2 vf-email-icon" />
                    <div className="vf-col-sm-9 vf-text-margin-left">
                      <div className="vf-text fm-multi-org-div"><span className="link-content">{value}</span></div>
                    </div>
                    <FaAngleRight style={{ fontSize: "24px" }} className=" link-content vf-col-sm-1 vf-chevron-icon" />
                  </div>
                ))}
              </div>
            </div>)}
            {/* Multi Org end */}
            
            {!contractorSivObj.showMultiOrgs && (<div className="fm-width-100 button-padding"><Button
              style={{ minHeight: "35px", minWidth: "180px" }} role="button"
              id="contractor_continueBtn" type="submit"
              className="primary cf-btn-cnt safari-button-align"
              disabled={!isFormValid}
              onClick={() => {
                showAlertFlag = true;
              } }>
              {<FormattedMessage id="findMe.searchBtn" />}
            </Button>
            </div>)}
            {!contractorSivObj.showMultiOrgs && (<div className="fm-emailphonesearch-back-btn-div"><Button
              style={{ paddingBottom: "0px" }} role="button" tabIndex="0"
              id="contractor_backBtn"
              className="primary cf-btn-cnt"
              buttonStyle="link"
              className="cf-font-size-btn cf-cancel-btn"
              onClick={() => {
                ReactGA.sendevent(gaCategory, 'CONTRACTOR', 'BACK'); return goToEmailPhoneSearchPage()
              } }
              >
              <ChevronLeft className="back-icon-align" />
              <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
            </Button>
            </div>)}

          </form>
        </div>
      </div>
    );
  }
}
export default injectIntl(FindMeContractorSivForm);