import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Textbox, Button, Popover,
  OverlayTrigger, DropdownList,
  Checkbox, Modal, ModalHeader, ModalFooter, ModalBody, ModalTitle, Alert
} from '@synerg/vdl-react-components';
import { getLocaleObjFromPropKey } from '../../util/locale';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { constants } from '../../redux/ducks/register-user/register-user-constants';
import CheckCircleIcon from 'adp-react-icons/lib/fa/check-circle';
import CancelIcon from 'adp-react-icons/lib/fa/times';
import TimesIcon from 'adp-react-icons/lib/fa/times-circle';
import CheckIcon from 'adp-react-icons/lib/fa/check';
import classNames from 'classnames';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import { ReactGA, WindowUtil } from '../../util';
import { PasswordStrengthIndicator } from 'nexo-react-components';

import {
  PasswordRule,
  StrengthTextComponent,
  StrengthTextInfoPromptsComponent,
} from '../password-strength-indicator/password-rule-component';
import { minimumRequirements, minOneNumber } from '../password-strength-indicator/password-strength-rules';
import { validatePassword } from '../password-strength-indicator/password-rules';
import {
  infoPromptsTextColor,
  orangeBarColor,
} from '../password-strength-indicator/indicator-constants';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
let showAlertFlag = false;
let setAutoFocusOff;

class RegisterUserForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      strengthText: `passwordStrengthIndicator.min8char_1number_1Upper_1Lower_1Special`,
      strengthTextInfoPrompts: ' ',
      strengthTextColor: '#4a4a4a',
      strengthBarColor: 'none',
      strengthWidth: 0,
      hasNoError: true,
      validPassword: false,
      validUserId:false,
      validConformPwd:false
    };
  }
  static PropTypes = {
    userIdType: PropTypes.string,
    userIdLabelName: PropTypes.string,
    userIdInvalidMessage: PropTypes.string,
    userIdRegExp: PropTypes.string,
    userIdValue: PropTypes.string,
    passwordLabelName: PropTypes.string,
    passwordInvalidMessage: PropTypes.string,
    passwordRegExp: PropTypes.string,
    passwordValue: PropTypes.string,
    confirmPasswordLabelName: PropTypes.string,
    confirmPasswordInvalidMessage: PropTypes.string,
    confirmPasswordRegExp: PropTypes.string,
    confirmPasswordValue: PropTypes.string,
    showSecurityQuestions: PropTypes.bool,
    firstNameForTitle: PropTypes.string,
    showTc: PropTypes.bool,
    getUserIdOptions: PropTypes.func,
    setRegisterOptionsField: PropTypes.func,
    verifyRegisterOptions: PropTypes.func,
    enableRegisterButton: PropTypes.func,
    partialUserId: PropTypes.string,
    partialUserIdValid: PropTypes.bool,
    userId: PropTypes.string,
    userIdValid: PropTypes.bool,
    password: PropTypes.string,
    passwordValid: PropTypes.bool,
    confirmpassword: PropTypes.string,
    confirmpasswordValid: PropTypes.bool,
    uiderrorMessage: PropTypes.string,
    partialuiderrorMessage: PropTypes.string,
    pswerrorMessage: PropTypes.string,
    confirmpswerrorMessage: PropTypes.string,
    isFormValid: PropTypes.bool,
    title: PropTypes.string,
    firstQuestion: PropTypes.string,
    secondQuestion: PropTypes.string,
    thirdQuestion: PropTypes.string,
    firstAnsValid: PropTypes.bool,
    secondAnsValid: PropTypes.bool,
    thirdAnsValid: PropTypes.bool,
    firstAnswer: PropTypes.string,
    secondAnswer: PropTypes.string,
    thirdAnswer: PropTypes.string,
    firstAnsErrMessage: PropTypes.string,
    secondAnsErrMessage: PropTypes.string,
    thirdAnsErrMessage: PropTypes.string,
    setQuestionsField: PropTypes.func,
    setAnswersField: PropTypes.func,
    verifySecurityQuestions: PropTypes.func,
    enableSecurityQAButton: PropTypes.func,
    availableUserResponse: PropTypes.string,
    checkUserIdAvailability: PropTypes.func,
    securityQnATextList: PropTypes.array,
    securityQnAIdList: PropTypes.array,
    firstAnsInlineErrMessage: PropTypes.string,
    secondAnsInlineErrMessage: PropTypes.string,
    thirdAnsInlineErrMessage: PropTypes.string,
    agreeTnC: PropTypes.bool,
    cancelRegistration: PropTypes.func,
    showFailedModal: PropTypes.bool,
    welcomeMsg: PropTypes.string,
    errorMsgTitle: PropTypes.string,
    userLastName: PropTypes.string,
    companyName: PropTypes.string,
    errorTitle: PropTypes.string,
    finalUserIdValue: PropTypes.string,
    mobileFlow: PropTypes.bool
  }

  componentWillMount() {
    if(!WindowUtil.getlayoutback()){
    this.props.getUserIdOptions();
  }
  WindowUtil.setlayoutback(false);
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }

  componentWillReceiveProps = (nextProps) => {
    this.checkFormReadyness(nextProps);
    if (nextProps.errorTitle != '' && showAlertFlag) {
      showAlertFlag = false;
      window.scrollTo(0, 0);
    }
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if(nextProps.showSecurityQuestions === false){

      if ( nextProps.userIdType
        && nextProps.userIdType === constants.fullUserIdType && (isBlank(nextProps.userId) || !nextProps.userIdValid || nextProps.userId !== nextProps.fetchedUserId)
      ) {
        formValid = false;
        this.setState((state)=>{return {validUserId:false}});
      }
  
      if ( nextProps.userIdType
        && nextProps.userIdType === constants.fullUserIdType && (!isBlank(nextProps.userId) && nextProps.userIdValid && nextProps.userId === nextProps.fetchedUserId)
      ) {
        this.setState((state)=>{return {validUserId:true}});
      }
  
      if ( nextProps.userIdType
        && nextProps.userIdType === constants.partialUserIdType && (isBlank(nextProps.partialUserId) || !nextProps.partialUserIdValid)
      ) {
        formValid = false;
      }
  
      if ( (isBlank(nextProps.password) || !nextProps.password)) {
        formValid = false;
      }
  
      if ((isBlank(nextProps.confirmpassword) || !nextProps.confirmpassword)) {
        formValid = false;
      }

      if ( this.state.strength === 1 && isBlank(nextProps.confirmpassword)) {
        formValid = false;
      }

      if ( nextProps.password != nextProps.confirmpassword) {
        formValid = false;
      }
     
      if (!(this.state.strength === 1) && !isBlank(nextProps.password) && !isBlank(nextProps.confirmpassword) && nextProps.password === nextProps.confirmpassword) {
       
        this.setState((state)=>{return {validConformPwd:true}});
      }
      if ((this.state.strength === 1) || isBlank(nextProps.password) || isBlank(nextProps.confirmpassword) || nextProps.password !== nextProps.confirmpassword) {
        this.setState((state)=>{return {validConformPwd:false}});
      }
  
    }

    if(nextProps.showSecurityQuestions === true ){

      if ((isBlank(nextProps.firstQuestion) || !nextProps.firstQuestion)) {
        formValid = false;
      }
  
      if ((isBlank(nextProps.secondQuestion) || !nextProps.secondQuestion)) {
        formValid = false;
      }
  
      if ((isBlank(nextProps.thirdQuestion) || !nextProps.thirdQuestion)) {
        formValid = false;
      }
  
      if ((isBlank(nextProps.firstAnswer) || !nextProps.firstAnswer)) {
        formValid = false;
      }
    
      if ((isBlank(nextProps.secondAnswer) || !nextProps.secondAnswer)) {
        formValid = false;
      }
  
      if ((isBlank(nextProps.thirdAnswer) || !nextProps.thirdAnswer)) {
        formValid = false;
      }
  
    }

    if (nextProps.agreeTnC === false && nextProps.showTc) {
      formValid = false;
    }
  
    if (!nextProps.passwordValid) {
      formValid = false;
    }
    this.props.enableRegisterButton(formValid);
  };

  _handleKeyPress = (e) => {
    ReactGA.sendevent(this.props.gaCategory, 'REGISTER_USER', 'SUBMIT');
    e.preventDefault();
    this.props.verifyRegisterOptions();
  }

  _handleKeyPressQA = (e) => {
    ReactGA.sendevent(this.props.gaCategory, 'Q_AND_A', 'SUBMIT');
    e.preventDefault();
    this.props.verifySecurityQuestions();
  }

  printTnC() {
    var termsNConditions = document.getElementById("tncFrame").contentWindow;
    termsNConditions.print();
  }

  onPasswordChange = (password) => {
    const obj = validatePassword(password);
    let strengthTextMsg = `passwordStrengthIndicator.${obj.strengthTextVal}`;
    let strengthTextInfoPromptsMsg = `passwordStrengthIndicator.${obj.strengthTextInfoPromptsVal}`;
    let validPasswordVal = typeof obj.hasError === 'undefined' && minimumRequirements(password);
    let strengthTextColorVal = obj.strengthTextColorVal;
    let strengthBarColorVal = obj.strengthBarColorVal;

    if (!this.props.passwordIndicatorToggle && validPasswordVal && password != '' && !minOneNumber(password)) {
      strengthTextMsg = `passwordStrengthIndicator.min1number`;
      strengthTextInfoPromptsMsg = `passwordStrengthIndicator.nothing`;
      strengthTextColorVal = infoPromptsTextColor;
      strengthBarColorVal = orangeBarColor;
      validPasswordVal = false;
    }
    this.setState({
      password,
      strengthText: strengthTextMsg,
      strengthTextInfoPrompts: strengthTextInfoPromptsMsg,
      strengthTextColor: strengthTextColorVal,
      strengthBarColor: strengthBarColorVal,
      strengthWidth: obj.strengthWidthVal,
      hasNoError: typeof obj.hasError === 'undefined',
      validPassword: validPasswordVal,
    });
    this.props.setRegisterOptionsField(constants.registerOptionIds.password, password);

    if (password === '') {
      validPasswordVal = true;
    }
    WindowUtil.setFirstAttemptGA({ gaCategory: this.props.gaCategory, fieldName: "PASSWORD" });
    this.props.setPwdValid(validPasswordVal);
  };

  render() {
    console.log('rendering Register User Form');
    const {
      getUserIdOptions,
      setRegisterOptionsField,
      verifyRegisterOptions,
      userId,
      userIdValid,
      partialUserId,
      partialUserIdValid,
      passwordValid,
      confirmpassword,
      confirmpasswordValid,
      uiderrorMessage,
      partialuiderrorMessage,
      pswerrorMessage,
      confirmpswerrorMessage,
      isFormValid,
      title,
      firstQuestion,
      secondQuestion,
      thirdQuestion,
      firstAnsValid,
      secondAnsValid,
      thirdAnsValid,
      firstAnswer,
      secondAnswer,
      thirdAnswer,
      firstAnsErrMessage,
      secondAnsErrMessage,
      thirdAnsErrMessage,
      setQuestionsField,
      setAnswersField,
      verifySecurityQuestions,
      availableUserResponse,
      checkUserIdAvailability,
      userIdType,
      userIdLabelName,
      userIdInvalidMessage,
      userIdRegExp,
      userIdValue,
      passwordLabelName,
      passwordInvalidMessage,
      passwordRegExp,
      passwordValue,
      confirmPasswordLabelName,
      confirmPasswordInvalidMessage,
      confirmPasswordRegExp,
      confirmPasswordValue,
      showSecurityQuestions,
      firstNameForTitle,
      showTc,
      securityQnATextList,
      securityQnAIdList,
      firstAnsInlineErrMessage,
      secondAnsInlineErrMessage,
      thirdAnsInlineErrMessage,
      showTnCModal,
      setCancelTnCModal,
      agreeTnC,
      setAgreeTermsCondition,
      cancelRegistration,
      showFailedModal,
      welcomeMsg,
      errorMsgTitle,
      userLastName,
      companyName,
      errorTitle,
      finalUserIdValue,
      mobileFlow,
      isMobile,
      isMobileBrowser,
      gaCategory,
      setPwdValid,
      progressBarData,
      footer,
      locale
    } = this.props;

    const {
      password,
      strengthText,
      strengthTextInfoPrompts,
      strengthTextColor,
      strengthWidth,
      strengthBarColor,
      hasNoError,
      validPassword,
    } = this.state;

    const isNotBlank = value => value !== undefined && value !== null && value.trim() !== '';

    const errorPopover = (fieldValid, errorMessage) =>
      (fieldValid && <Popover
        id="dummyPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && errorMessage === "" && <Popover
        id="dummyPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{errorMessage}</div>
        </Popover>

      ));

    const handleUserId = () => {
      checkUserIdAvailability();
      var psw_field = document.getElementById("psw_field");
      psw_field.click();
    }
    const tooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-userid">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );

    const onSetUserId = userId => { 
        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "USER_ID" }); 
        setRegisterOptionsField(constants.registerOptionIds.userid, userId);
        handleUserId(); 
    }

    const onSetPartialUserId = partialUid => { 
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PARTIAL_USERID" }); 
      setRegisterOptionsField(constants.registerOptionIds.partialuserid, partialUid); 
      handleUserId(); 
    }

    const onSetPassword = password => setRegisterOptionsField(constants.registerOptionIds.password, password);

    const onSetConfirmpassword = confirmpassword => {
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "CONFIRM_PASSWORD" });
      return setRegisterOptionsField(constants.registerOptionIds.confirmpassword, confirmpassword);
    }

    const onSetFirstQuestion = firstQuestion => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'QUESTION1'); return setQuestionsField(constants.registerOptionIds.firstquestion, firstQuestion); }

    const onSetSecondQuestion = secondQuestion => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'QUESTION2'); return setQuestionsField(constants.registerOptionIds.secondquestion, secondQuestion); }

    const onSetThirdQuestion = thirdQuestion => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'QUESTION3'); return setQuestionsField(constants.registerOptionIds.thirdquestion, thirdQuestion); }

    const onSetFirstAnswer = firstAnswer => {
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "FIRST_ANSWER" });
      return setAnswersField(constants.registerOptionIds.firstanswer, firstAnswer);
    }

    const onSetSecondAnswer = secondAnswer => {
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SECOND_ANSWER" });
      return setAnswersField(constants.registerOptionIds.secondanswer, secondAnswer);
    }

    const onSetThirdAnswer = thirdAnswer => {
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "THIRD_ANSWER" });
      return setAnswersField(constants.registerOptionIds.thirdanswer, thirdAnswer);
    }

    const onCancelTnCModal = () => { let showTnCModal = false; return setCancelTnCModal(showTnCModal); }

    const onOpenTnCModal = () => { ReactGA.sendevent(gaCategory, 'T_AND_C', 'MODAL'); let showTnCModal = true; return setCancelTnCModal(showTnCModal); }

    const onSelectAgreeTnC = () => { let agreeTnC = true; onCancelTnCModal(); return setAgreeTermsCondition(agreeTnC); }

    const onDeSelectAgreeTnC = () => { let agreeTnC = false; return setAgreeTermsCondition(agreeTnC); }

    const handleAgreeTnC = () => {

      if (agreeTnC) {
        onDeSelectAgreeTnC();
      } else {
        onSelectAgreeTnC();
      }
    }

    const updatedFirstQuestionsList = securityQnATextList.reduce((accum, currentQuestion, currQIndex) => {

      if (!(currentQuestion === secondQuestion || currentQuestion === thirdQuestion)) {
        accum.push(currentQuestion);
      }
      return accum;
    }, []);

    const updatedSecondQuestionsList = securityQnATextList.reduce((accum, currentQuestion, currQIndex) => {

      if (!(currentQuestion === firstQuestion || currentQuestion === thirdQuestion)) {
        accum.push(currentQuestion);
      }
      return accum;
    }, []);

    const updatedThirdQuestionsList = securityQnATextList.reduce((accum, currentQuestion, currQIndex) => {
      if (!(currentQuestion === firstQuestion || currentQuestion === secondQuestion)) {
        accum.push(currentQuestion);
      }
      return accum;
    }, []);
    return (
      (
        <div className="registration-content">
          <div className="registration-form-container" >
          {(window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
          }
          {
            (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
          }
            {showSecurityQuestions === false && (!isMobile) && (
              <div className="reg-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'REGISTER_USER', 'CANCEL');
                  return cancelRegistration()
                }}>
                <svg id="pwd_cancel" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'REGISTER_USER', 'CANCEL');
                  return cancelRegistration()
                }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            )}

            {showSecurityQuestions === true && (!isMobile ) && (
              <div className="reg-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'Q_AND_A', 'CANCEL');
                  return cancelRegistration()
                }}>
                <svg id="pwd_secqa_cancel" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'Q_AND_A', 'CANCEL');
                  return cancelRegistration()
                }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            )}
          </div>
          {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
           <div className="registration-progress-bar">
              <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
            </div>
          )}
          {(!isMobile) && <hr className="header-hr" />}
          <div className="vdl-row registration-form">
            <div className="welcome-title title-width">
              <div className="vdl-col-md-12">
                <h1 id="registration-layout_welcome" className={showSecurityQuestions ? 'page-title-for-lengthy-text' : 'page-title'}>{title}</h1>
              </div>
            </div>
            {errorTitle != undefined && errorTitle != '' && (<Alert
              type="error" style={{ width: '100%', marginLeft:'10%',marginRight:'10%' }}
            ><p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(errorTitle)}</p></Alert>
            )}
            <div className="vdl-col-md-12">
              {showSecurityQuestions === false && <label className="reg-form-cmpny-label" >{ReactHtmlParser(companyName)} </label>}
               {showSecurityQuestions === true && <label className="reg-form-sec-qa-label">{
               this.props.intl.formatMessage({ id: 'r_registerUser.securityQApageInfo' })} </label>  }
              <form className="useridpsw-padding" onSubmit={this._handleKeyPress}>

                {showSecurityQuestions === false && userIdType === constants.fullUserIdType && availableUserResponse != constants.invalidUserId && (
                  <div>
                    <label htmlFor="user_id_Label">{userIdLabelName}
                      <span className="reg-form-dob-req-icon">*</span>
                      <span id="pwd_full_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'USER_ID', 'TOOLTIP')}>
                        <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                          overlay={tooltip(this.props.intl.formatMessage({ id: 'Txt_UIDRules' }))} >
                          <span tabIndex="0" role="button" className="focus-selector"><InfoCircle  style={{marginBottom:"2px"}} role="button" aria-label="tooltip"/></span>
                        </OverlayTrigger>
                      </span>  </label>
                  </div>
                )}

                {showSecurityQuestions === false && userIdType === constants.partialUserIdType && availableUserResponse != constants.invalidUserId && (
                  <div>
                    <label htmlFor="user_id_Label">{userIdLabelName}
                      <span className="reg-form-dob-req-icon">*</span>
                      <span id="pwd_partial_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'USER_ID', 'TOOLTIP')}>
                        <OverlayTrigger placement="right" trigger={['click','focus']} isAriaExpanded={ false }
                          overlay={tooltip(this.props.intl.formatMessage({ id: 'Txt_UIDRules2' }))} >
                           <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{marginBottom:"2px"}}/></span>
                        </OverlayTrigger>
                      </span></label>
                  </div>
                )}

                {showSecurityQuestions === false && availableUserResponse === constants.invalidUserId && (

                  <p role="alert"><label htmlFor="user_id_display" className="reg-form-invalid-text-label">
                    {<FormattedMessage id="registerUser.invalidUserText" />}
                  </label></p>

                )}
                {showSecurityQuestions === false && userIdType === constants.fullUserIdType && (
                  <div className="form-group form-align">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(userIdValid, uiderrorMessage)}
                      >
                        <Textbox style={{ width: '85%' }} autocomplete="username" aria-label={userIdLabelName}
                          id="userId_full" autoFocus={setAutoFocusOff}
                          className={userIdValid ? undefined : 'vdl-validation-error'}
                          value={userId}
                          onChange={onSetUserId} 
                        />
                      </OverlayTrigger>
                      <span className="vdl-password-strength__rule-icon reg-form-green-check-icon" role="status">
                          { this.state.validUserId && <CheckCircleIcon aria-label={this.props.intl.formatMessage({ id: 'userIdCheckCircleDone' })}/>}
                      </span>
                    </div>
                    <div />
                  </div>
                )}

                {showSecurityQuestions === false && userIdType === constants.partialUserIdType && (
                  <div className="form-group form-align">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(partialUserIdValid, partialuiderrorMessage)}
                      >
                        <Textbox style={{ width: '50%' }} autocomplete="username"
                          aria-label={userIdLabelName}
                          id="userId_partial" autoFocus={setAutoFocusOff}
                          className={partialUserIdValid ? undefined : 'vdl-validation-error'}
                          value={partialUserId}
                          onChange={onSetPartialUserId}
                        />
                      </OverlayTrigger>
                    </div>
                    <label htmlFor="partial_client_id" className="partial-uid-align">
                      {userIdValue}</label>
                    <div />
                  </div>
                )}

                {showSecurityQuestions === false && userIdType === constants.sysgeneratedUserIdType && (
                  <div className="form-group form-align">
                    <label htmlFor="user_id_display" className="reg-form-userid-text-label">
                      {<FormattedMessage id="r_registerUser.userIdText" />}
                      {userIdValue}
                    </label>
                  </div>
                )}

                {showSecurityQuestions === false && !pswerrorMessage && (
                  <label htmlFor="password_label" className="reg-form-pwd-label" >{passwordLabelName}
                    <span className="reg-form-dob-req-icon">*</span>  </label>
                )}

                {showSecurityQuestions === false && pswerrorMessage && (
                 <p role="alert"> <label htmlFor="password_error" className="reg-form-pwd-error-label">{pswerrorMessage}
                  </label></p>
                )}
                {showSecurityQuestions === false && (
                  <div>
                    <div className="password-input">
                      <div className="input-group-full-password-with-indicator">
                        <Textbox 
                        aria-label={passwordLabelName} autocomplete="new-password"
                          id="psw_field" type="password" style={{ width: '85%' }}
                          className={passwordValid ? undefined : 'vdl-validation-error'}
                          value={password}
                          onChange={this.onPasswordChange}
                          disabled={this.state.disabled}
                        />
                        <span role="status"
                          className="vdl-password-strength__rule-icon"
                          style={{ color: strengthTextColor, paddingLeft: '10px', paddingTop: '5px' }}>
                          {hasNoError && minimumRequirements(password) && <CheckCircleIcon 
                          aria-label={this.props.intl.formatMessage({ id: 'passwordCheckCircleDone' })}/>}
                        </span>
                      </div>
                    </div>
                    <div className="reg-form-pwd-ind-div">
                      <PasswordStrengthIndicator
                        id="passwordIndicator"
                        strengthWidth={strengthWidth}
                        strengthBarColor={strengthBarColor}
                        rulesHeading=" "
                      >
                        <PasswordRule
                          valid={hasNoError}
                          strengthTextColor={strengthTextColor}
                        >
                          <StrengthTextComponent strengthTextColor={strengthTextColor}>
                            <FormattedMessage
                              id={strengthText}
                              defaultMessage={strengthText}
                              description="Strength Text"
                            />
                          </StrengthTextComponent>
                          <StrengthTextInfoPromptsComponent>
                            <FormattedMessage
                              id={strengthTextInfoPrompts}
                              defaultMessage={strengthTextInfoPrompts}
                              description="Strength Text Info Prompts"
                            />
                          </StrengthTextInfoPromptsComponent>
                        </PasswordRule>
                      </PasswordStrengthIndicator>
                    </div>
                  </div>
                )}

                {showSecurityQuestions === false && !confirmpswerrorMessage && (
                  <label htmlFor="confirm_password_label" className="reg-form-pwd-label reg-form-conform-pwd-label" >{confirmPasswordLabelName}
                    <span className="reg-form-dob-req-icon">*</span> </label>
                )}

                {showSecurityQuestions === false && confirmpswerrorMessage && (<p role="alert">
                  <label htmlFor="confirm_password_error" className="reg-form-pwd-error-label reg-form-conform-pwd-label">{confirmpswerrorMessage}
                  </label></p>
                )}
                {showSecurityQuestions === false && (
                  <div className="form-group form-align">
                    <div className="input-group-full">
                      <Textbox 
                        aria-label={confirmPasswordLabelName}
                        id="confirm_psw_field" type="password" style={{ width: '85%' }}
                        className={confirmpasswordValid ? undefined : 'vdl-validation-error'}
                        value={confirmpassword}
                        onChange={onSetConfirmpassword}
                      />
                      <span role="status"
                          className="vdl-password-strength__rule-icon"
                          style={{ color: strengthTextColor, paddingLeft: '10px', paddingTop: '5px' }}>
                          {hasNoError && minimumRequirements(password) && this.state.validConformPwd && <CheckCircleIcon 
                          aria-label={this.props.intl.formatMessage({ id: 'confirmpasswordCheckCircleDone' })}/>}
                      </span>
                    </div>
                  </div>
                )}

                {showSecurityQuestions === false && showTc === true && (
                  <div className="form-group form-align">
                    <hr />
                    <div className="vdl-row">
                      <div className="reg-vpilot-terms-div">
                        <label className="reg-vpilot-terms-label">{<FormattedMessage id="Txt_VPilotTerms" />}</label>
                        <div>
                          <Checkbox id="wfnCheckbox" aria-label="TermsAndConditionsCheckbox" checked={agreeTnC} onChange={handleAgreeTnC} style={{ display: 'inline' }} onKeyPress={ (e) => {    
                            if(e.key === 'Enter'){
                              e.preventDefault();
                              return;    
                            }                                        
                          } }/>
                          <span className="heading-text reg-vpilot-terms-span"> {<FormattedMessage id="Txt_VPilotTermsAgreementReadAndAgree" />}<a id="pwd_tandc" tabIndex="0" aria-label={this.props.intl.formatMessage({id : 'Txt_VPilotTermsAgreementReadAndAgree1'})} role="link" onClick={onOpenTnCModal}
                            onKeyPress={onOpenTnCModal} className="reg-vpilot-terms-a focus-selector" > {<FormattedMessage id="Txt_VPilotTermsAgreementReadAndAgree1" />}</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


                {showSecurityQuestions === false && (
                  <div
                    id="reguser-form_submit-button" style={{marginLeft:'-15%',marginTop:'30px'}}
                    className="form-group submit vdl-text-xs-center align-submit"
                  >
                    <Button type="submit" role="button"
                      id="pwd_continueBtn"
                      disabled={!isFormValid}
                      className="primary submit-button-align safari-button-align" onClick={() => showAlertFlag = true}
                    >
                      <CheckIcon style={{marginBottom:'1%',paddingRight:'1%'}}/>{<FormattedMessage id="r_register_createaccount"/>}
                    </Button>
                  </div>)}
                {showSecurityQuestions === false && (

                  <Modal id="TermsNConditionsModal"
                    className="vdl-modal--alert"
                    show={showTnCModal}
                    onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                  >
                    <ModalHeader closeButton={true} onHide={onCancelTnCModal} className="tnc-modal-header" />
                    <ModalBody className="tnc-modal-body-footercolor">
                      <div>
                        <iframe id="tncFrame" src={ getLocaleObjFromPropKey(footer.terms.legalPage, locale) } width="100%" height="500px" frameBorder="0" />
                      </div>
                    </ModalBody>
                    <ModalFooter className="tnc-modal-body-footercolor">
                      <Button id="pwd_printBtn" onClick={this.printTnC} className="white-plain-button tnc-print-button-align safari-button-align">
                        {<FormattedMessage id="lbl_printbutton" />}
                      </Button>
                      <Button id="pwd_closeBtn" onClick={onCancelTnCModal} className="white-plain-button tnc-close-button-align safari-button-align">
                        {<FormattedMessage id="lbl_closebutton" />}
                      </Button>
                      <Button id="pwd_agreeBtn" onClick={onSelectAgreeTnC} className="tnc-agree-button-align safari-button-align">
                        {<FormattedMessage id="lbl_agreebutton" />}
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}


              </form>

              {/************************************Security Questions  and Answers*******************************************/}
              <form className="adp-form padding-align-inc-width" onSubmit={this._handleKeyPressQA}>

                {showSecurityQuestions === true && (
                  <div className="form-group form-align">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label htmlFor="question1_label" >{<FormattedMessage id="registerUser.question1" />}
                          <span className="reg-req-dropdown-icon">*</span></label>
                      </div>
                    </div>
                    <div className="form-group form-align">
                      <div >
                        <div>
                          <DropdownList aria-label="firstQuestionDropdown" 
                            id="pwd_question1"
                            value={firstQuestion}
                            onChange={onSetFirstQuestion}
                            data={updatedFirstQuestionsList}
                            placeholder={this.props.intl.formatMessage({ id: 'please_select_placeholder' })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}


                {showSecurityQuestions === true && firstAnsInlineErrMessage && (<p role="alert">
                  <label htmlFor="first_ans_inline_err" className="reg-form-pwd-error-label">{firstAnsInlineErrMessage}

                  </label></p>
                )}

                {showSecurityQuestions === true && (
                  <div className="form-group form-align">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(firstAnsValid, firstAnsErrMessage)}
                      >
                        <Textbox
                          id="pwd_answer1" 
                          aria-label="FirstAnswerTextBox"
                          className={firstAnsValid ? undefined : 'vdl-validation-error'}
                          value={firstAnswer}
                          onChange={onSetFirstAnswer} placeholder={this.props.intl.formatMessage({ id: 'Lbl_SecurityAnswer1' })}
                        />
                      </OverlayTrigger>
                    </div>
                    <div />
                  </div>
                )}


                {showSecurityQuestions === true && (
                  <div className="form-group form-align">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label htmlFor="question2_label" >{<FormattedMessage id="registerUser.question2" />}
                          <span className="reg-req-dropdown-icon">*</span></label>
                      </div>
                    </div>
                    <div className="form-group form-align">
                      <div>
                        <div>
                          <DropdownList aria-label="secondQuestionDropdown" 
                            id="pwd_question2"
                            value={secondQuestion}
                            onChange={onSetSecondQuestion}
                            data={updatedSecondQuestionsList}
                            placeholder={this.props.intl.formatMessage({ id: 'please_select_placeholder' })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}


                {showSecurityQuestions === true && secondAnsInlineErrMessage && (<p role="alert">
                  <label htmlFor="second_ans_inline_err" className="reg-form-pwd-error-label">{secondAnsInlineErrMessage}
                  </label></p>
                )}

                {showSecurityQuestions === true && (
                  <div className="form-group form-align">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(secondAnsValid, secondAnsErrMessage)}
                      >
                        <Textbox
                          id="pwd_answer2" 
                          aria-label="SecondAnswerTextBox"
                          className={secondAnsValid ? undefined : 'vdl-validation-error'}
                          value={secondAnswer}
                          onChange={onSetSecondAnswer} placeholder={this.props.intl.formatMessage({ id: 'Lbl_SecurityAnswer2' })}
                        />
                      </OverlayTrigger>
                    </div>
                    <div />
                  </div>
                )}


                {showSecurityQuestions === true && (
                  <div className="form-group form-align">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label htmlFor="question3_label" >{<FormattedMessage id="registerUser.question3" />}
                          <span className="reg-req-dropdown-icon">*</span></label>
                      </div>
                    </div>
                    <div className="form-group form-align">
                      <div>
                        <div>
                          <DropdownList aria-label="thirdQuestionDropdown" 
                            id="pwd_question3"
                            value={thirdQuestion}
                            onChange={onSetThirdQuestion}
                            data={updatedThirdQuestionsList}
                            placeholder={this.props.intl.formatMessage({ id: 'please_select_placeholder' })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {showSecurityQuestions === true && thirdAnsInlineErrMessage && (<p role="alert">
                  <label htmlFor="third_ans_inline_err" className="reg-form-pwd-error-label">{thirdAnsInlineErrMessage}
                  </label></p>
                )}
                {showSecurityQuestions === true && (
                  <div className="form-group form-align">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(thirdAnsValid, thirdAnsErrMessage)}
                      >
                        <Textbox
                          id="pwd_answer3" 
                          aria-label="thirdAnswerTextBox"
                          className={thirdAnsValid ? undefined : 'vdl-validation-error'}
                          value={thirdAnswer}
                          onChange={onSetThirdAnswer} placeholder={this.props.intl.formatMessage({ id: 'Lbl_SecurityAnswer3' })}
                        />
                      </OverlayTrigger>
                    </div>
                    <div />
                  </div>
                )}

                {showSecurityQuestions === true && (
                  <div
                    id="reguser-form_submit-button"
                    className="form-group submit vdl-text-xs-center align-submit"
                  >
                    <Button type="submit" role="button"
                    style={{ minHeight: "35px", minWidth: "180px" }}
                      id="pwd_sec_continueBtn" disabled={!isFormValid}
                      className="primary submit-button-align safari-button-align" onClick={() => showAlertFlag = true}
                    >
                      <CheckIcon style={{marginBottom:'1%',paddingRight:'1%'}}/>{<FormattedMessage id="submitCBtn.Label"/>}
                    </Button>
                  </div>
                )}

                <Modal
                  className="vdl-modal--alert"
                  show={showFailedModal}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className="modal-title-error">
                      <WarnIcon className="vdl-modal__title-icon warn-icon-error"
                      />
                      {errorMsgTitle ? errorMsgTitle : this.props.intl.formatMessage({ id: 'Txt_VGeneralErrorWindowTitle' })}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="pwd_okBtn" onClick={cancelRegistration} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>
              </form>
            </div>
          </div>
        </div>
      )

    );
  }
}
export default injectIntl(RegisterUserForm);
