var progressBarStages = require('./ProgressBarStages.json');
import { constants as progressBarConstants } from './progress-bar-constants';
import { ReactGA } from '../../util';
import Cookies from 'js-cookie';
import React from 'react';
let fieldNameList = [];
let fromBranding = false;
let brandingDropdownList = [];
let showAutoFocusOff = true;
const ADP_LANG_LOCALE_COOKIE = 'ADPLangLocaleCookie';
const ADP_USERID_COOKIE = 'ADPUserIdCookie';
const ADP_LANG_LOCALE_COOKIE_EXPIRES = 60 * 60 * 24; //  24 hours
const DOMAIN = '.adp.com';
const PATH = '/';
let layoutback = false;
let resendEnabled = false;
let brandingEvo = {};
let showBrandingEvoChanges = false;

export default class WindowUtil {

  static flagIcon = (localeIcon, localeText) => (
    <span>
      <span id={ `country-flag-icon-${localeIcon}` }>
      {localeIcon !== 'tw' && <img
          id={ `country-flag-icon-${localeIcon}` }
          className="flag-icon"
          // eslint-disable-next-line import/no-dynamic-require
          src={ require(`../../components/images/country-flags/${localeIcon}.svg`) }
          alt=""
        />}
      </span>
      {localeIcon !== 'tw' && <span
        id={ `country-flag-text-${localeIcon}` }
        style={ { paddingLeft: '10px' } }
      >
        { localeText }
      </span>}
      {localeIcon === 'tw' && <span
        id={ `country-flag-text-${localeIcon}` }
        style={ { paddingLeft: '30px' } }
      >
        { localeText }
      </span>}
    </span>
  );




  static getLocaleList() {
    if (fromBranding) return brandingDropdownList;
    const localeList = [
      {
        id: 4,
        language: 'English (US)',
        suffix: 'en_US'
      },
      {
        id: 20,
        language: 'Čeština (CR)',
        suffix: 'cs_CZ'
      },
      {
        id: 7,
        language: 'Deutsch (DE)',
        suffix: 'de_DE'
      },
      {
        id: 19,
        language: 'English (CA)',
        suffix: 'en_CA'
      },
      {
        id: 17,
        language: 'Español (ES)',
        suffix: 'es_ES'
      },
      {
        id: 18,
        language: 'Español (US)',
        suffix: 'es_US'
      },
      {
        id: 6,
        language: 'Français (CA)',
        suffix: 'fr_CA'
      },
      {
        id: 5,
        language: 'Français (FR)',
        suffix: 'fr_FR'
      },
      {
        id: 8,
        language: 'Magyar (HU)',
        suffix: 'hu_HU'
      },
      {
        id: 9,
        language: 'Italiano (IT)',
        suffix: 'it_IT'
      },
      {
        id: 10,
        language: '日本語 (JP)',
        suffix: 'ja_JP'
      },
      {
        id: 11,
        language: '한국어 (KR)',
        suffix: 'ko_KR'
      },
      {
        id: 3,
        language: 'Nederlands (NL)',
        suffix: 'nl_NL'
      },
      {
        id: 12,
        language: 'Polski (PL)',
        suffix: 'pl_PL'
      },
      {
        id: 13,
        language: 'Português (BR)',
        suffix: 'pt_BR'
      },
      {
        id: 14,
        language: 'Português (PT)',
        suffix: 'pt_PT'
      },
      {
        id: 15,
        language: 'Русский (RU)',
        suffix: 'ru_RU'
      },
      {
        id: 16,
        language: 'Slovenčina (SK)',
        suffix: 'sk_SK'
      },
      {
        id: 1,
        language: '简体中文 (CN)',
        suffix: 'zh_CN'
      },
      {
        id: 2,
        language: '繁體中文 (TW)',
        suffix: 'zh_TW'
      },
      {
        id: 19,
        language: 'English (GB)',
        suffix: 'en_GB'
      }
    ];

    return localeList;

  }

  static getCurrentYear() {
    var currentDate = new Date();
    return currentDate.getFullYear();
  }

  static setBrandingLocaleList(footer) {
    let localeList = this.getLocaleList();
    let brandingLocaleList = null;
    let response = [];
    footer.items.forEach((value) => {
      if (value.id == 'privacy') {
        brandingLocaleList = value.component.text;
      }
    });
    if (brandingLocaleList != null) {
      localeList.forEach((value, index) => {
        const resultObj = Object.keys(brandingLocaleList).filter(key => key === value.suffix);
        if (resultObj.length !== 0) {
          response.push(localeList[index])
        }
      });
      brandingDropdownList = response;
      fromBranding = true;
    }
    else brandingDropdownList = localeList;
  }

  static isBlank = str => (str || '').trim().length === 0;

  static redirectTo(redirectUrl) {
    window.location.assign(redirectUrl);
  }
  static validateRtnUrl(rtnUrl, organizationId) {
    if (rtnUrl !== undefined && rtnUrl !== null && !WindowUtil.isBlank(rtnUrl)) {
      const parser = document.createElement('a');

      parser.href = rtnUrl;
      const hostName = parser.host;

      if (!hostName.endsWith('.adp.com')) {
        const redirectionUrl = `https://${window.location.host}`;

        return redirectionUrl;
      }

      if(organizationId !== undefined && organizationId !== null && !WindowUtil.isBlank(organizationId)) {
        if (rtnUrl.indexOf('?') > -1) {
          rtnUrl = rtnUrl+'&organizationId='+organizationId;
        } else {
          rtnUrl = rtnUrl+'?organizationId='+organizationId;
        }
      }
    }
    return rtnUrl;
  }

  static getInitalData() {
    const res = {};

    res.mobileFlow = this.getParameterByName('mobileFlow');

    res.transactionId = this.getParameterByName('transactionId');
    
    return res;
  }


  static getParameterByName(givenName, givenUrl) {
    let name = givenName;

    let url = givenUrl;

    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }



  static firstNameFormat(str) {
    var output = "";
    if (str == undefined || str == null || str == '') {
      return str;
    }
    else {
      output += str[0].toUpperCase();
      for (var i = 1; i < str.length; i++) {
        var ch = str[i];
        output += ch.toLowerCase();
      }
    }
    return output;
  }
  static openNewWindow(targetUrl) {
    console.log(targetUrl);
    window.open(targetUrl, '_blank');
  }

  static getQueryParam(queryParam) {
    const urls = window.location.href;
    const myurls = urls.split(`${queryParam}=`);
    const mylasturls = myurls[1];
    const mynexturls = mylasturls.split('&');
    const queryValue = mynexturls[0];

    return queryValue;
  }

  static getQueryParams() {
    const urls = window.location.href;
    const myurls = urls.split('?');

    if (urls.includes('accountantconnect')) {
        let url = '';
        for (var i = 1; i < myurls.length; i++) {
          if (myurls[i] != null) {
            url = url.concat(myurls[i]);
            if (myurls.length - 1 != i) {
              url = url.concat('%3F');
            }
          }
      }
      return url;
    } else {
      if (myurls.length > 1) {
        const mylasturls = myurls[1];
  
        return mylasturls;
      }
    }
    
    return '';
  }

  static FIDOAuthenticationCheck() {
    console.log('checking for authentication.');
    let isAvailableForFIDO = false;

    if (window.PublicKeyCredential === undefined ||
        typeof window.PublicKeyCredential !== "function" ||
        typeof window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== "function") 
        {
            isAvailableForFIDO = false;
        } else {
            isAvailableForFIDO = true;
        }
        return isAvailableForFIDO; 
  }


  static deviceAssessment(orgId, daUrl) {
    if (orgId != undefined && orgId != null) {
      const scriptNode = document.createElement('script');

      scriptNode.setAttribute('type', 'text/javascript');
      scriptNode.setAttribute(
    	'src', daUrl,
      );
      if (typeof scriptNode != 'undefined') {
        document.getElementsByTagName('body')[0].appendChild(scriptNode);
      }
    }
  }

  static appendAudieoEyeJs() {
    const scriptNode = document.createElement('script');

    scriptNode.setAttribute('type', 'text/javascript');
    scriptNode.innerText = '!function(){var t=function(){var t=document.createElement("script");t.src="https://ws.audioeye.com/ae.js",t.type="text/javascript",t.setAttribute("async",""),document.getElementsByTagName("body")[0].appendChild(t)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",t):window.attachEvent&&window.attachEvent("onload",t):t()}()';
    document.getElementsByTagName('head')[0].appendChild(scriptNode);
  }

  static recaptchaCall() {
    const scriptNode = document.createElement('script');

    scriptNode.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    scriptNode.async = true;
    scriptNode.defer = true;
    document.getElementsByTagName('head')[0].appendChild(scriptNode);
  }

  static getProgressBarViewId(viewId) {
    if (progressBarConstants.findMeViewId == viewId) {
      return progressBarConstants.findMe;
    }
    else if (progressBarConstants.verifyUserViewId == viewId) {
      return progressBarConstants.verifyUser;
    }
    else if (progressBarConstants.verify401kViewId == viewId
      || progressBarConstants.verifyPicViewId == viewId
      || progressBarConstants.identifyYourselfViewId == viewId
      || progressBarConstants.verifyUserIdViewId == viewId
      || progressBarConstants.verifyPrcViewId == viewId
    ) {
      return progressBarConstants.identifyYourself;
    }
    else if (progressBarConstants.contactInfoViewId == viewId) {
      return progressBarConstants.contactInfo;
    }
    else if (progressBarConstants.verifyAdminViewId == viewId) {
      return progressBarConstants.verifyAdmin;
    }
    return progressBarConstants.verifyUser;
  }

  static getProgressBarData(currentStage, state) {
    let currentFlow = state.registration.currentStage;
    let progressBarEnabled = state.registration.progressBarEnabled || false;
    let messages = state.intl.messages;
    let response = {};
    if (!progressBarEnabled) {
      response.showProgressBar = false;
      return response;
    }
    response.showProgressBar = false;
    response.steps = [];
    let stageData = progressBarStages.flowIdProgressBarDetails[currentFlow];
    stageData.initialSteps.forEach(value => {
      response.steps.push({ title: messages[value] })
    });
    stageData.initialIndexes.forEach(value => {
      if (value[currentStage] !== undefined) {
        response.activeStep = value[currentStage];
        response.showProgressBar = true;
      }
    });
    return response;
  }
  static getLocaleValue(locale) {


    let localeList = WindowUtil.getLocaleList();
    let response = "English (US)";

    localeList.some(value => {

      if (value.suffix == locale || value.suffix.split("_")[0] == locale) {
        response = value.language;
        return response;
      }
    })

    return response;

  }

  static setFirstAttemptGA(eventObj) {
    let gaCategory = eventObj.gaCategory;
    let fieldName = eventObj.fieldName;
    if (fieldNameList.indexOf(gaCategory + fieldName) === -1) {
      fieldNameList.push(gaCategory + fieldName);
      console.log(gaCategory + fieldName);
      ReactGA.sendevent(gaCategory, fieldName, 'FIRST_ATTEMPT');
    }
  }

  static setAutoFocusOff(isMobile) {   
      showAutoFocusOff = false;
  }

  static getAutoFocusOff() {
    return showAutoFocusOff;
  }

  static setCookieValue(locale) {
    Cookies.set(ADP_LANG_LOCALE_COOKIE, locale, {
      expires: ADP_LANG_LOCALE_COOKIE_EXPIRES,
      domain: DOMAIN,
      path: PATH,
    });
  }

  static setUserIdCookieValue(userId) {
    if(userId !== undefined) {
       Cookies.set(ADP_USERID_COOKIE, userId, {
       expires: ADP_LANG_LOCALE_COOKIE_EXPIRES,
       domain: DOMAIN,
       path: PATH,
      });
    }
  }

  static getlayoutback() {
    return layoutback;
  }

  static setlayoutback(backPress) {
    layoutback = backPress;
  }

  static setresend(resendPress) {
    resendEnabled = resendPress;
  }

  static isresendEnabled() {
    return resendEnabled;
  }

  static getContrastColor = (hexcolor) => {
    // eslint-disable-next-line id-length
    // If a leading # is provided, remove it
    let hexcolor1 = '#FFFFFF';

    if (hexcolor && hexcolor.slice(0, 1) === '#') {
      hexcolor1 = hexcolor.slice(1);
    }
    const redColor = parseInt(hexcolor1.substr(0, 2), 16);
    const greenColor = parseInt(hexcolor1.substr(2, 2), 16);
    const blueColor = parseInt(hexcolor1.substr(4, 2), 16);
    const colorScheme = (redColor * 299 + greenColor * 587 + blueColor * 114) / 1000;

    return colorScheme >= 128 ? 'black' : 'white';
  };

  static setBrandingParameterChanges(element, parameter, type, disabledColor) {
    let textColor;
    var elements = document.getElementsByTagName(element);
    var i;
    for (i = 0; i < elements.length; i++) {
      if (element == 'h1' && elements[i].className.indexOf('header-content') === -1) {
        elements[i].style.color = parameter;
      }
      else if (element == 'svg') {
        if (elements[i].getAttribute("class") != null && elements[i].getAttribute("class") != undefined && elements[i].getAttribute("class").indexOf('link-content') !== -1) {
          elements[i].style.color = parameter;
        }
      }
      else if (element == 'label') {
        elements[i].style.color = parameter;
      }
      else if (element == 'a') {
        elements[i].style.color = parameter;
      }
      else if (element == 'h5') {
        if (elements[i].className.indexOf('heading-text') !== -1) {
          elements[i].style.color = parameter;
        }
      }
      else if (element == 'div') {
        if (elements[i].className.indexOf('vf-heading') !== -1 && type == 'heading') {
          elements[i].style.color = parameter;
        }

        else if (elements[i].className.indexOf('heading-text') !== -1 && type == 'heading') {
          elements[i].style.color = parameter;
        }

        else if (elements[i].className.indexOf('title') !== -1 && elements[i].className.indexOf('vdl-alert__title') === -1 && type == 'title') {
          elements[i].style.color = parameter;
        }

      }
      else if (element == 'span') {
        if ((elements[i].className.indexOf('label') !== -1 || elements[i].className.indexOf('vf-otc-color') !== -1 || elements[i].className.indexOf('heading-text') !== -1 || elements[i].className.indexOf('cs-text-lbl') !== -1) && type == 'content') {
          elements[i].style.color = parameter;
        }
        else if ((elements[i].id.indexOf('info-text') !== -1 || elements[i].id.indexOf('tc-text') !== -1) && type == 'content') {
          elements[i].style.color = parameter;
        }
        else if ((elements[i].className.indexOf('vb-sample-doc') !== -1 || elements[i].className.indexOf('link-content') !== -1 || elements[i].className.indexOf('vp-handicon-span') !== -1 || elements[i].className.indexOf('cs-user-id') !== -1 || elements[i].className.indexOf('cs-copy') !== -1) && type === 'link') {
          elements[i].style.color = parameter;
        }
      }
      else if (element == 'button' && elements[i].id.indexOf('downIndicator') === -1) {
        if (elements[i].className.indexOf('vdl-button--link') !== -1 && type == 'link') {
          elements[i].style.color = parameter;
        }
        if (elements[i].className.indexOf('opt-resend') !== -1 && type == 'link') {
          if (elements[i].disabled === true) {
            elements[i].style.color = disabledColor;
          }
          else {
            elements[i].style.color = parameter;
          }
        }
        else if (elements[i].className.indexOf('vdl-button--primary') !== -1 && type == 'primary') {
          if (elements[i].disabled === true) {
            elements[i].style.background = disabledColor;
            if (disabledColor !== undefined) {
              textColor = this.getContrastColor(disabledColor)
            }
            elements[i].style.color = textColor;
          }
          else {
            elements[i].style.background = parameter;
            if (parameter !== undefined) {
              textColor = this.getContrastColor(parameter)
            }
            elements[i].style.color = textColor;
          }
        }
        else if (elements[i].className.indexOf('vdl-button--secondary') !== -1 && type === 'secondary') {
          elements[i].style.background = parameter;
          if (parameter !== undefined) {
            textColor = this.getContrastColor(parameter)
          }
          elements[i].style.color = textColor;
        }
        if (elements[i].className.indexOf('vdl-button--link') === -1 && type == 'buttonRadius') {
          elements[i].style.borderRadius = parameter;
        }

      }
    }
  }

  static setBrandingParameters(brandingEvoData, showBrandingEvo) {
    brandingEvo = brandingEvoData;
    showBrandingEvoChanges = showBrandingEvo;
  }

  static applyBrandingChanges() {
    if (brandingEvo !== undefined && showBrandingEvoChanges === true) {
      this.setBrandingParameterChanges('h1', brandingEvo.primaryColorTitleText);
      this.setBrandingParameterChanges('label', brandingEvo.primaryColorContentText);
      this.setBrandingParameterChanges('span', brandingEvo.primaryColorContentText, 'content');
      this.setBrandingParameterChanges('span', brandingEvo.primaryColorLinks, 'link');
      this.setBrandingParameterChanges('svg', brandingEvo.primaryColorLinks);
      this.setBrandingParameterChanges('a', brandingEvo.primaryColorLinks);
      this.setBrandingParameterChanges('div', brandingEvo.primaryColorContentText, 'heading');
      this.setBrandingParameterChanges('h5', brandingEvo.primaryColorContentText);
      this.setBrandingParameterChanges('div', brandingEvo.primaryColorTitleText, 'title');
      if (brandingEvo.primaryColorLinks !== undefined && brandingEvo.primaryColorLinks !== null) {
        if( brandingEvo.primaryBtnDisabledColor !== undefined && brandingEvo.primaryBtnDisabledColor !== null) {
          this.setBrandingParameterChanges(
            'button',
            brandingEvo.primaryColorLinks,
            'link',
            brandingEvo.primaryBtnDisabledColor,
          );
        } else {
          this.setBrandingParameterChanges(
            'button',
            brandingEvo.primaryColorLinks,
            'link',
            '#bbb',
          );
        }
      }
      this.setBrandingParameterChanges('button', brandingEvo.btnBorderRadius, 'buttonRadius');
      if (brandingEvo.primaryBtnColor !== undefined && brandingEvo.primaryBtnColor !== null) {
        if (
          brandingEvo.primaryBtnDisabledColor !== undefined &&
          brandingEvo.primaryBtnDisabledColor !== null
        ) {
          this.setBrandingParameterChanges(
            'button',
            brandingEvo.primaryBtnColor,
            'primary',
            brandingEvo.primaryBtnDisabledColor,
          );
        } else {
          this.setBrandingParameterChanges(
            'button',
            brandingEvo.primaryBtnColor,
            'primary',
            '#bbb',
          );
        }
      }
      this.setBrandingParameterChanges('button', brandingEvo.secondaryBtnColor, 'secondary');
    }
  }

 static startCoBrowseSession = (event) => {
    if (event) {
      event.preventDefault();
    }
    // eslint-disable-next-line no-undef
    GLANCE.Cobrowse.Visitor.startSession();
  };

  static addScript = (url, onload, attrs) => {
    let found = false;
 
    if (url) {
      // Check for an existing script element with the same URL. If found, don't load it again.
      found = Array.from(document.scripts).some((script) => {
        // Use getAttribute in order to get the src as provided, not the fully resolved version in the src property.
        if (script.getAttribute('src') === url) {
          return true;
        }
      });
   
      if (found) {
        if (onload) {
          onload();
        }
      }
      else {
        const newScript = document.createElement('script');
   
        if (onload) {
          newScript.onload = onload;
        }
   
        if (attrs) {
          Object.keys(attrs).forEach((attrName) => newScript.setAttribute(attrName, attrs[attrName]));
        }
   
        newScript.src = url;
        document.body.appendChild(newScript);
      }
    }
  }
   
  static loadCobrowse = () => {
    const env = document.location.hostname.startsWith('netsecure.adp.com') ? 'production' : 'staging';  // UPDATE THIS LOGIC FOR EACH BU

    this.addScript(`https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=20820&site=${env}`, null, {
      'id': 'glance-cobrowse',
      'type': 'text/javascript',
      'data-groupid': '20820',
      'data-site': env,
      'charset': 'UTF-8'
    });
    }


}