import React, { Component } from 'react';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  DropdownButton
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { ReactGA, WindowUtil } from '../../util';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import { constants } from '../../redux/ducks/find-me/find-me-constants';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';

const isBlank = str => (str || '').trim().length === 0;
var ua = window.navigator.userAgent;
var isIE = (ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident") > -1 || ua.indexOf("Edge") > -1) ? true : false;
let overlayPlacement = "right";
let showAlertFlag = false;
let setAutoFocusOff;

class FindMeEmailPhoneSivForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: ''
    };
    this.handleSivRadio = this.handleSivRadio.bind(this);
    this.handleMonthDaySelection = this.handleMonthDaySelection.bind(this);
  }

  componentWillMount() {
    this.props.enableSubmitButton(false);
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
    WindowUtil.setlayoutback(false);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.emailPhoneSearchSivObj.sivOptions != null && nextProps.emailPhoneSearchSivObj.availableSivOptions != null) {
      this.checkSivFormReadyness(nextProps);
      this.handleMonthDaySelection(nextProps);
    }
  };

  checkSivFormReadyness = (nextProps) => {
    let formValid = true;
    if (nextProps.emailPhoneSearchSivObj.initialLoad) {
      if (nextProps.emailPhoneSearchSivObj.initialSivData === constants.SSN4 && (isBlank(nextProps.emailPhoneSearchSivObj.ssn4) || !nextProps.emailPhoneSearchSivObj.ssn4Valid))
        formValid = false;
      if (nextProps.emailPhoneSearchSivObj.initialSivData === constants.EMPID && (isBlank(nextProps.emailPhoneSearchSivObj.empId) || !nextProps.emailPhoneSearchSivObj.empIdValid))
        formValid = false;
      if (nextProps.emailPhoneSearchSivObj.initialSivData === constants.WFNID && (isBlank(nextProps.emailPhoneSearchSivObj.wfnId) || !nextProps.emailPhoneSearchSivObj.wfnIdValid))
        formValid = false;
      if (nextProps.emailPhoneSearchSivObj.initialSivData === constants.DOB && (isBlank(nextProps.emailPhoneSearchSivObj.month) || isBlank(nextProps.emailPhoneSearchSivObj.day)))
        formValid = false;
    } else {
      if (this.state.option === '')
        formValid = false;
      if (this.state.option === constants.SSN4 && (isBlank(nextProps.emailPhoneSearchSivObj.ssn4) || !nextProps.emailPhoneSearchSivObj.ssn4Valid))
        formValid = false;
      if (this.state.option === constants.EMPID && (isBlank(nextProps.emailPhoneSearchSivObj.empId) || !nextProps.emailPhoneSearchSivObj.empIdValid))
        formValid = false;
      if (this.state.option === constants.WFNID && (isBlank(nextProps.emailPhoneSearchSivObj.wfnId) || !nextProps.emailPhoneSearchSivObj.wfnIdValid))
        formValid = false;
      if (this.state.option === constants.DOB && (isBlank(nextProps.emailPhoneSearchSivObj.month) || isBlank(nextProps.emailPhoneSearchSivObj.day)))
        formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  };

  handleSivRadio(option) {
    this.setState({
      option
    });
  }

  handleMonthDaySelection = (nextProps) => {
    if ((nextProps.emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth && this.state.option === constants.DOB) || (nextProps.emailPhoneSearchSivObj.initialLoad && nextProps.emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth)) {
      const inputMonthId = nextProps.emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.monthItems.indexOf(nextProps.emailPhoneSearchSivObj.month) + 1;
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        nextProps.emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        nextProps.emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        nextProps.emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith29days;
      }
    }
  }

  render() {
    const {
      findMeObj,
      isMobileBrowser,
      isMobile,
      gaCategory,
      isFormValid,
      showModalMessage,
      showModalWarn,
      showModalTitle,
      cancelAndCheckYourEntries,
      emailPhoneSearchSivObj,
      setSivField,
      verifySivData,
      errorInSIV,
      goToEmailPhoneSearchPage
    } = this.props;

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="findmeErrorPopOver"
        style={{ display: 'none' }}
        />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
          >
          <div role="alert">{msg}</div>
        </Popover>
      ));

    const updateSivFieldValueHandler = (fieldKey, fieldValue) => {
      let payload = {};
      payload.fieldKey = fieldKey;
      payload.fieldValue = fieldValue;
      return setSivField(payload);
    }

    const _handleSivSubmitKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'SIV_DATA', 'SUBMIT');
      this.props.verifySivData();
    }

    return (
      findMeObj.showDiv && (<div>

        <div className="registration-form padding-findme-siv-align">
          <div className="welcome-title title-width ev-margin-h">
            <div className="vdl-col-md-12">
              <div>
                <h1 id="registration-layout_welcome" style={{ paddingBottom: '0px' }} className="page-title fm-emailsearch-title" >{(!emailPhoneSearchSivObj.initialLoad && errorInSIV) || (emailPhoneSearchSivObj.initialLoad && emailPhoneSearchSivObj.singleSiv) ? <FormattedMessage id="findMe.emailPhoneSearch.title" /> : <FormattedMessage id="findMe.multiorg.title" values={{ 0: WindowUtil.firstNameFormat(findMeObj.identifiedUserFirstName) }} />}</h1>
                {emailPhoneSearchSivObj.initialLoad && (errorInSIV === undefined || errorInSIV === '') && <div className="div-h5 heading-text" style={{ fontWeight: "normal" }}> {
                  <FormattedMessage id="findMe.sivpage.pageLoadInfo" />}</div>}
              </div>
            </div>
          </div>
      {((!emailPhoneSearchSivObj.initialLoad && errorInSIV) || (emailPhoneSearchSivObj.initialLoad && emailPhoneSearchSivObj.singleSiv)) && <div className="fm-text-align-centre"><WarnIcon className="warn-icon-info" style={{ verticalAlign: 'sub' }} />
            <span className="fm-error-page-info-span" role="alert">{errorInSIV}</span></div>}
          {/**************************SIV data page***************/}
          <div style={{ paddingTop: '17px' }}>

            <form id="emailPhoneSivForm" onSubmit={_handleSivSubmitKeyPress}>
              {emailPhoneSearchSivObj.initialLoad && (<div>

                {emailPhoneSearchSivObj.initialSivData === constants.SSN4 ? (
                  <div className="siv-ssn-fix">
                    <div><span className="fm-siv-ssn4-label-align">{emailPhoneSearchSivObj.availableSivOptions.siv_ssn4.labelName}</span>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(emailPhoneSearchSivObj.ssn4Valid,
                          emailPhoneSearchSivObj.ssn4ErrorMsg)}
                        >
                        <Textbox autoFocus={setAutoFocusOff} aria-label={emailPhoneSearchSivObj.availableSivOptions.siv_ssn4.labelName}
                          id="initSiv_ssn4_textBox" style={{ width: '25%' }}
                          type="password"
                          className={emailPhoneSearchSivObj.ssn4Valid ? 'fm-sivoptions-align' :
                            'vdl-validation-error fm-sivoptions-align'}
                          value={emailPhoneSearchSivObj.ssn4}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'SSN4');
                            return updateSivFieldValueHandler(constants.SIV_SSN4, value)
                          } }
                          />
                      </OverlayTrigger> <span className="lock-icon-align"><LockIcon /></span>
                    </div>
                  </div>
                ) : (null)}

                {emailPhoneSearchSivObj.initialSivData === constants.DOB ? (
                  <div id={emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth.labelName}>
                    <div style={{ display: 'inline-flex' }} role="group" aria-labelledby={emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth.labelName}>
                      <span style={{ paddingTop: '7px', color: '#4a4a4a' }}>{emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth.labelName}</span>
                      <div className="dob-column-width">
                        <DropdownList aria-label="monthDropdown" 
                          id="initSiv_month"
                          value={emailPhoneSearchSivObj.month} className="monthdropdown-align"
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                            return updateSivFieldValueHandler(constants.SIV_MONTH, value)
                          } }
                          data={emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.monthItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                          />
                      </div>

                      <div className="dob-column-width">
                        <DropdownList style={{ width: '63%' }} aria-label="dayDropdown" 
                          id="initSiv_day" className="daydropdown-align"
                          value={emailPhoneSearchSivObj.day}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                            return updateSivFieldValueHandler(constants.SIV_DAY, value)
                          } }
                          data={emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.dayItems}
                          placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                          />
                      </div>
                    </div>
                  </div>
                ) : (null)
                }

                {emailPhoneSearchSivObj.initialSivData === constants.EMPID ? (
                  <div style={{ textAlign: 'center' }}>
                    <div><span style={{ color: '#4a4a4a' }}>{emailPhoneSearchSivObj.availableSivOptions.siv_empid.labelName}</span>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(emailPhoneSearchSivObj.empIdValid,
                          emailPhoneSearchSivObj.empIdErrorMsg)}
                        >
                        <Textbox autoFocus={setAutoFocusOff} aria-label={emailPhoneSearchSivObj.availableSivOptions.siv_empid.labelName}
                          id="initSiv_empid_textBox" style={{ width: '25%' }}
                          className={emailPhoneSearchSivObj.empIdValid ? 'sivoptions-align' :
                            'vdl-validation-error sivoptions-align'}
                          value={emailPhoneSearchSivObj.empId}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID');
                            return updateSivFieldValueHandler(constants.SIV_EMPID, value)
                          } }
                          />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)}

                {emailPhoneSearchSivObj.initialSivData === constants.WFNID ? (
                  <div style={{ textAlign: 'center' }}>
                    <div><span style={{ color: '#4a4a4a' }}>{emailPhoneSearchSivObj.availableSivOptions.siv_wfnid.labelName}</span>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(emailPhoneSearchSivObj.wfnIdValid,
                          emailPhoneSearchSivObj.wfnIdErrorMsg)}
                        >
                        <Textbox autoFocus={setAutoFocusOff} aria-label={emailPhoneSearchSivObj.availableSivOptions.siv_wfnid.labelName}
                          id="initSiv_wfnid_textBox" style={{ width: '25%' }}
                          className={emailPhoneSearchSivObj.wfnIdValid ? 'sivoptions-align' :
                            'vdl-validation-error sivoptions-align'}
                          value={emailPhoneSearchSivObj.wfnId}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'WFN_ID');
                            return updateSivFieldValueHandler(constants.SIV_WFNID, value)
                          } }
                          />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)}
                <br />
              </div>)}


              {/***************On error of options entry*****************/}
              {!emailPhoneSearchSivObj.initialLoad && (<div>


                {emailPhoneSearchSivObj.availableSivOptions.siv_ssn4 ? (
                  <div style={{ width: '100' }}>

                    <Radio id="errorSiv_ssn4_radio" radioGroupValue={this.state.option} value={constants.SSN4} style={{ display: 'inline' }}
                      onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'SSN4')} onChange={this.handleSivRadio}>{emailPhoneSearchSivObj.availableSivOptions.siv_ssn4.labelName}</Radio>

                    {this.state.option === constants.SSN4 && emailPhoneSearchSivObj.availableSivOptions.siv_ssn4 ? (
                      <div style={{ display: 'inline' }}>
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                          overlay={errorPopover(emailPhoneSearchSivObj.ssn4Valid,
                            emailPhoneSearchSivObj.ssn4ErrorMsg)}
                          >
                          <Textbox autoFocus={setAutoFocusOff} aria-label={emailPhoneSearchSivObj.availableSivOptions.siv_ssn4.labelName}
                            id="errorSiv_ssn4_textBox" style={{ width: '25%' }}
                            type="password"
                            className={emailPhoneSearchSivObj.ssn4Valid ? 'sivoptions-align' :
                              'vdl-validation-error sivoptions-align'}
                            value={emailPhoneSearchSivObj.ssn4}
                            onChange={(value) => {
                              ReactGA.sendevent(gaCategory, 'CLICK', 'SSN4');
                              return updateSivFieldValueHandler(constants.SIV_SSN4, value)
                            } }
                            />
                        </OverlayTrigger> <span className="lock-icon-align"><LockIcon /></span>
                      </div>
                    ) : (null)
                    }
                  </div>
                ) : (null)}



                {/***************SSN4 block end and DOB start*****************/}
                {emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth && (
                  <div style={{ width: '100%' }}>
                    <Radio id="errorSiv_dob_radio" radioGroupValue={this.state.option} value={constants.DOB} style={{ display: 'inline' }}
                      onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'DOB')} onChange={this.handleSivRadio}>{emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth.labelName}</Radio>
                    {this.state.option === constants.DOB && emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth ? (
                      <div id={emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth.labelName}>  <div style={{ display: 'inline-flex',width:'60%' }} role="group" aria-labelledby={emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth.labelName}>
                            <DropdownList aria-label="monthDropdown" 
                              id="errorSiv_month"
                              value={emailPhoneSearchSivObj.month} className="fm-siv-monthdropdown-align"
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                                return updateSivFieldValueHandler(constants.SIV_MONTH, value)
                              } }
                              data={emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.monthItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                              />
                            <DropdownList aria-label="dayDropdown" 
                              id="errorSiv_day" className="fm-siv-daydropdown-align"
                              value={emailPhoneSearchSivObj.day}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                                return updateSivFieldValueHandler(constants.SIV_DAY, value)
                              } }
                              data={emailPhoneSearchSivObj.sivOptions.sivOptionsCalenderItems.dayItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                              />
                      </div></div>
                    ) : (null)
                    }

                  </div>)}

                {/***************DOB block end and Empid/wfnid start*****************/}
                {emailPhoneSearchSivObj.availableSivOptions.siv_empid ? (
                  <div style={{ width: '100%' }}>

                    <Radio id="errorSiv_empid_radio" radioGroupValue={this.state.option} value={constants.EMPID} style={{ display: 'inline' }}
                      onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'EMPID')} onChange={this.handleSivRadio}>{emailPhoneSearchSivObj.availableSivOptions.siv_empid.labelName}</Radio>

                    {this.state.option === constants.EMPID && emailPhoneSearchSivObj.availableSivOptions.siv_empid ? (
                      <div style={{ display: 'inline' }}>
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                          overlay={errorPopover(emailPhoneSearchSivObj.empIdValid,
                            emailPhoneSearchSivObj.empIdErrorMsg)}
                          >
                          <Textbox autoFocus={setAutoFocusOff} aria-label={emailPhoneSearchSivObj.availableSivOptions.siv_empid.labelName}
                            id="errorSiv_empid_textBox" style={{ width: '25%' }}
                            className={emailPhoneSearchSivObj.empIdValid ? 'sivoptions-align' :
                              'vdl-validation-error sivoptions-align'}
                            value={emailPhoneSearchSivObj.empId}
                            onChange={(value) => {
                              ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID');
                              return updateSivFieldValueHandler(constants.SIV_EMPID, value)
                            } }
                            />
                        </OverlayTrigger>
                      </div>
                    ) : (null)
                    }
                  </div>
                ) : (null)}



                {emailPhoneSearchSivObj.availableSivOptions.siv_wfnid ? (
                  <div style={{ width: '100%' }}>
                    <Radio id="errorSiv_wfnid_radio" radioGroupValue={this.state.option} value={constants.WFNID} style={{ display: 'inline' }}
                      onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'WFNID')} onChange={this.handleSivRadio}>{emailPhoneSearchSivObj.availableSivOptions.siv_wfnid.labelName}</Radio>

                    {this.state.option === constants.WFNID && emailPhoneSearchSivObj.availableSivOptions.siv_wfnid ? (
                      <div style={{ display: 'inline' }}>
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                          overlay={errorPopover(emailPhoneSearchSivObj.wfnIdValid,
                            emailPhoneSearchSivObj.wfnIdErrorMsg)}
                          >
                          <Textbox autoFocus={setAutoFocusOff} aria-label={emailPhoneSearchSivObj.availableSivOptions.siv_wfnid.labelName}
                            id="errorSiv_wfnid_textBox" style={{ width: '25%' }}
                            className={emailPhoneSearchSivObj.wfnIdValid ? 'sivoptions-align' :
                              'vdl-validation-error sivoptions-align'}
                            value={emailPhoneSearchSivObj.wfnId}
                            onChange={(value) => {
                              ReactGA.sendevent(gaCategory, 'CLICK', 'WFN_ID');
                              return updateSivFieldValueHandler(constants.SIV_WFNID, value)
                            } }
                            />
                        </OverlayTrigger>
                      </div>
                    ) : (null)
                    }
                  </div>
                ) : (null)}


              </div>)}



              <div style={{ width: '100%', paddingTop: '20px' }}><Button role="button"
                style={{ minHeight: "35px", minWidth: "180px" }}
                id="findMe_siv_continuebtn" type="submit"
                className="primary cf-btn-cnt safari-button-align"
                disabled={!isFormValid}
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SIV', 'CLICK');
                } }
                >
                {emailPhoneSearchSivObj.initialLoad ? <FormattedMessage id="findMe.continueBtn" /> : <FormattedMessage id="findMe.searchBtn" />}
              </Button>
              </div>

              <div className="fm-emailphonesearch-back-btn-div"><Button role="button"
                style={{ paddingBottom: '0px' }} tabIndex="0"
                id="findMe_siv_backBtn"
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SIV', 'BACK');
                  return goToEmailPhoneSearchPage();
                } }>
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button>
              </div>

            </form>
          </div>

        </div>
      </div>

      )
    );
  }
}
export default injectIntl(FindMeEmailPhoneSivForm);