// TODO - fix the onlyContries props. Currently expects that as an array of country object, but users should be able to send in array of country isos


import React from 'react';

import classNames from 'classnames';
import ReactDOM from 'react-dom';
import Calendar from 'adp-react-icons/lib/fa/clock-o';




const numDigits = (x) => (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;

var style = require('./Timer.scss');


class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentCount: (this.props.noOfSecs === undefined) ? 15 : this.props.noOfSecs };
    this.setTimer = this.setTimer.bind(this);
  }

  setTimer() {
    this.setState({
      currentCount: (this.props.noOfSecs === undefined) ? 30 : this.props.noOfSecs
    });
  }

  timer() {
    if (this.props.isBlocked) return;

    let secs = this.state.currentCount;
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    minutes = numDigits(minutes) == 1 ? '0' + minutes : minutes;


    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    seconds = numDigits(seconds) == 1 ? '0' + seconds : seconds;


    this.setState({
      currentCount: this.state.currentCount - 1,
      h: hours,
      m: minutes,
      s: seconds
    })
    if (this.state.currentCount < 0) {
      clearInterval(this.intervalId);
      if (this.props.onFinalCount) {
        this.props.onFinalCount(this.state);
      }
    }
  }
  componentDidMount() {

    this.intervalId = setInterval(this.timer.bind(this), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }



  render() {
    return (
      <div>
        {this.state.s != null && (<div className="vdl-row">
          <Calendar className="vf-calender" />
          {((this.state.currentCount + 1 <= 5) && <div className="t-current-count " style={{ color: 'red' }}>
            {this.state.m}
            :
           {this.state.s}
          </div>)}
          {((this.state.currentCount + 1 > 5) && <div className="t-current-count " >
            {this.state.m}
            :
            {this.state.s}

          </div>)}

        </div>)}

      </div>
    );
  }
}

export default Timer;

