import React from 'react';
import PropTypes from 'prop-types';
import ExternalLinkIcon from 'adp-react-icons/lib/fa/external-link';
import MobileFooterPopUp from '../common/MobileFooterPopUp';
import { getLocaleObjFromPropKey } from '../../util/locale';
import {
  LINK,
  POP_UP,
  CO_BROWSE
} from './footer-constants';
import { ReactGA, WindowUtil } from '../../util';

const mobileFooter = ({ footer, locale }) => (
  <footer >{
<div
  id="headingId"
  className="vf-heading-2 mobile-padding"
>

  {getLocaleObjFromPropKey(footer.copyRightValue.component.text, locale)}  </div>

  }
      {
         []
        .concat(footer.items)
        .sort((elem1, elem2) => (elem1.order < elem2.order ? -1 : 0))
        .map((sortedFooter) => {
        if (sortedFooter.component.type === LINK){
         return(
        
               <a aria-label={ "footer"+sortedFooter.id } style={{textDecoration:'none'}}
                className="vdl-row vf-mobile-row-size"
                  href={ getLocaleObjFromPropKey(sortedFooter.component.url, locale) }
                  target="_blank"
                  rel="noopener noreferrer"
                  id={ sortedFooter.id+"mobilefooter" }
                  onClick={ () => {
                    ReactGA.sendevent('OLP_FOOTER', sortedFooter.component.type, sortedFooter.id);
                  } }
               > <div className="vf-col-sm-9 vf-text-margin-left">
               <div
                className="vdl-mobile-text fm-multi-org-div"
               >
                 <span  className="link-content"> { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }</span>
                  </div>
           </div>
           <ExternalLinkIcon className="link-content vf-col-sm-1 mobile-chevron-icon" />
           </a>
            
         
         );}
         if(sortedFooter.component.type === CO_BROWSE) {
          return (
            <a aria-label={ "footer"+sortedFooter.id } style={{textDecoration:'none'}}
                className="vdl-row vf-mobile-row-size"
                  href= '#'
                  target="_blank"
                  rel="noopener noreferrer"
                  id={ sortedFooter.id+"mobilefooter" }
                  onClick={WindowUtil.startCoBrowseSession}
               > <div className="vf-col-sm-9 vf-text-margin-left">
               <div
                className="vdl-mobile-text fm-multi-org-div"
               >
                 <span  className="link-content"> { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }</span>
                  </div>
           </div>
           </a>
          );
        }
         if (sortedFooter.component.type === POP_UP){
          return(
            <MobileFooterPopUp
            displayOrder={ sortedFooter.order }
            displayText={ sortedFooter.component.text }
            url={ sortedFooter.component.url }
            locale={ locale }
            id={ sortedFooter.id }
            />
         );
         }
        }) }
  </footer>
);

mobileFooter.propTypes = {
  locale: PropTypes.string,
};

export default mobileFooter;