import { handleActions } from 'redux-actions';
import { types } from './verify-admin-actions';
import { ReactGA } from '../../../util';

const {
  VERIFY_IDENTITY_OPTIONS,
  VERIFY_IDENTITY_OPTIONS_SUCCESS,
  VERIFY_IDENTITY_OPTIONS_FAIL,
  ENABLE_ADMIN_FLOW_SUBMIT_BUTTON,
  CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES,
  FETCH_DETAILS,
  SET_IDENTITY_FIELD,
  SET_IDENTITY_FIELD_SUCCESS,
  SET_IDENTITY_FIELD_FAIL,
  VERIFICATION_CODE_SENT,
  VERIFICATION_CODE_SENT_SUCCESS,
  VERIFY_ADMIN_OPTIONS_SUCCESS,
  SET_AGREE_TERMS_CONDITION,
  SET_MODAL_FIELD,
  VERIFY_ADMIN_RESEND_OTP
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  transactionId: '',
  backgroundImagePath: '',
  firstName: '',
  lastName: '',
  ssn4Valid: true,
  mobilePhoneValid: true,
  showVerifyCode:false
};

export default handleActions(
  {

    [VERIFY_IDENTITY_OPTIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_IDENTITY_OPTIONS_FAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [ENABLE_ADMIN_FLOW_SUBMIT_BUTTON]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
   [FETCH_DETAILS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_IDENTITY_FIELD_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_IDENTITY_FIELD_FAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFICATION_CODE_SENT_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_ADMIN_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_AGREE_TERMS_CONDITION]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_MODAL_FIELD]: (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);
