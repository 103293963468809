export const constants = {
    getBEVFeedData: '/pages/sms/ess/v3/ws/rest/pub/ssr/bev/getBEVData',
    chooseProduct: '/pages/sms/ess/v3/ws/rest/pub/ssr/bev/chooseProduct',
    verifyBEVUser: '/pages/sms/ess/v3/ws/rest/pub/ssr/bev/identifyUser',
    ALREADY_REGISTERED: 'ALREADY_REGISTERED',
    REDIRECT_FORGOT: '/pages/sms/ess/v3/pub/ssr/redirectToForgotFlow.jsp',
    redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
    PATHBEVFLOW: '/ssr/VERIFYBEV',
    monthWith31days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    monthWith30days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
    monthWith29days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"]
};
