import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {
  Loader, FindMeContainer, FindMeMobileContainer, IdentifyYourselfContainer, VerifyUserContainer, VerifyUserIdContainer, VerifyUserPinContainer, ErrorContainer, VerifyPICContainer, ContactInfoContainer, RegisterUserContainer, ConfirmationContainer, VerifyPrcContainer, ExternalVendorContainer, VerifyKbaContainer, Verify401kContainer, VerifyBevContainer,VerifyAdminContainer
} from '../containers';

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/loader"
      component={Loader}
      />
    <Route
      exact
      path="/verifyKba"
      component={VerifyKbaContainer}
      />
    <Route
      exact
      path="/chooseVendors"
      component={ExternalVendorContainer}
      />
    <Route
      exact
      path="/verifyPrc"
      component={VerifyPrcContainer}
      />
    <Route
      exact
      path="/verifyUser"
      component={VerifyUserContainer}
      />
    <Route
      exact
      path="/verifyUserId"
      component={VerifyUserIdContainer}
      />
    <Route
      exact
      path="/verifyUserPin"
      component={VerifyUserPinContainer}
      />
    <Route
      exact
      path="/identifyYourself"
      component={IdentifyYourselfContainer}
      />
    <Route
      exact
      path="/verifyPic"
      component={VerifyPICContainer}
      />
    <Route
      exact
      path="/contactInfo"
      component={ContactInfoContainer}
      />
    <Route
      exact
      path="/registration"
      component={RegisterUserContainer}
      />
    <Route
      exact
      path="/confirmation"
      component={ConfirmationContainer}
      />
    <Route
      path="/error"
      component={ErrorContainer}
      />
    <Route
      exact
      path="/verify401k"
      component={Verify401kContainer}
      />
    <Route
      exact
      path="/verifyBev"
      component={VerifyBevContainer}
      />
    <Route
      exact
      path="/findMe"
      component={FindMeContainer}
      />
    <Route
      exact
      path="/findMeDownloadMobileApp"
      component={FindMeMobileContainer}
      /> 
    <Route
      exact
      path="/runAdminV2"
      component={VerifyAdminContainer}
      />
  </Switch>
);

export default Routes;
