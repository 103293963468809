import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { types } from '../find-me-mobile-actions';
import { ReactGA, PendoUtil } from '../../../../util';

const {
  FIND_ME_MOBILE_INFO_OPTIONS,
  FIND_ME_MOBILE_INFO_OPTIONS_SUCCESS,
  FIND_ME_MOBILE_INFO_OPTIONS_FAIL
} = types;


const getFindMeMobileInfoOptionsSuccess = createAction(FIND_ME_MOBILE_INFO_OPTIONS_SUCCESS);
const getFindMeMobileInfoOptionsFail = createAction(FIND_ME_MOBILE_INFO_OPTIONS_FAIL);


const isBlank = str => (str || '').trim().length === 0;


const getFindMeMobileData = (getState) => {
  const isMobile = getState.registration.mobileFlow;
  let findMeMobileObj = Object.assign({}, getState.findMeMobile.findMeMobileObj);
  const contactInfoObj = Object.assign({}, getState.contactInfo.contactInfoObj);
  const findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let adminMsg = getState.intl.messages['findMeMobile.contactAdmin'];
  if (contactInfoObj.contactAdminMsg != undefined && contactInfoObj.contactAdminMsg != null) {
    adminMsg = contactInfoObj.contactAdminMsg;
  }
  if (findMeObj.contactAdminMsg != undefined && findMeObj.contactAdminMsg != null) {
    adminMsg = findMeObj.contactAdminMsg;
  }


  let gaCategory = "WEB_REGISTRATION_FIND_ME_MOBILE";
  let pagePath = '/ssr/findMe/findMeMobile';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_FIND_ME_MOBILE"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_FIND_ME_MOBILE"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  let selectedLocale = getState.intl.locale;
  if (selectedLocale != undefined) {
    findMeMobileObj.srcApple = "images/Badges/apple_" + selectedLocale + ".png";
    findMeMobileObj.srcAndriod = "images/Badges/andriod_" + selectedLocale + ".png";
  }
  else {
    findMeMobileObj.srcApple = "images/Badges/apple_en.png";
    findMeMobileObj.srcAndriod = "images/Badges/andriod_en.png";

  }
  findMeMobileObj.showDiv = true;
  findMeMobileObj.transId = getState.intl.messages['Txt_Transaction_ID'] + ": " + getState.registration.transactionId;
  findMeMobileObj.contactAdmin = adminMsg;
  return of({
    gaCategory,
    isMobile,
    findMeMobileObj
  });

}




const getFindMeMobileInfo = (getState) =>
  getFindMeMobileData(getState)
    .pipe(map(getFindMeMobileInfoOptionsSuccess),
      catchError(err => [getFindMeMobileInfoOptionsFail(getErrorMessage(err))]));

export const getFindMeMobileInfoEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_MOBILE_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getFindMeMobileInfo(state.value))),
);

export const getFindMeMobileInfoFailureEpic = action$ => action$.pipe(
  ofType(FIND_ME_MOBILE_INFO_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))),
);