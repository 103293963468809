import { handleActions } from 'redux-actions';
import { types } from './sending-request-actions';

const { SENDING_REQUEST } = types;

export const initialState = {
  currentlySending: false,
  errorMessage: '',
  showBusyIndicator: false
};

export default handleActions(
  {
    // eslint-disable-next-line no-confusing-arrow
    [SENDING_REQUEST]: (state, { payload }) =>{
      let indicator = false;
      if(payload.flag!=undefined && payload.flag == true)
      indicator = true;
      
     
      return payload.sending === true ?
        { ...state, errorMessage: '', currentlySending: payload.sending,showBusyIndicator: indicator } :
    { ...state, currentlySending: payload.sending,showBusyIndicator: indicator }},
  },
  initialState,
);
