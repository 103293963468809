import { handleActions } from 'redux-actions';
import { types } from './registration-actions';

const { GET_TRANSACTION_DETAILS_SUCCESS, GET_TRANSACTION_DETAILS_FAIL, NEXO_SSR_REDIRECTION } = types;

export const initialState = {
  flowType: 'SSR-REACT',
  transactionId: '',
  backgroundImagePath: '',
  sivOptions:'',
  sitekey:'',
  showExternalVendorsPage:false,
  isRecaptchaOn: false,
  orgIdForDeviceAssessment : ''
};

export default handleActions(
  {
    [GET_TRANSACTION_DETAILS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [GET_TRANSACTION_DETAILS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [NEXO_SSR_REDIRECTION]: (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);
