import { handleActions } from 'redux-actions';
import { types } from './verify-bev-actions';

const {
  VERIFY_BEV_INFO_OPTIONS_SUCCESS,
  VERIFY_BEV_INFO_OPTIONS_FAIL,
  VERIFY_BEV_INFO_UPDATE

} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  transactionId: '',
  showDraggable: false,
  isFormValid: true,
  verifyBevObj: {
    showDiv: false,
    alreadyRegistered: false
  }

};

export default handleActions(
  {
    [VERIFY_BEV_INFO_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [VERIFY_BEV_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [VERIFY_BEV_INFO_UPDATE]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
