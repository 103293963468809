import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions as localeActions } from '../../redux/ducks/locale';
import { actions as verifyPicActions } from '../../redux/ducks/verify-pic';
import { actions as timeoutActions } from '../../redux/ducks/timeout';
import { RegistrationLayout } from '../../layouts';
import { VerifyPICForm } from '../../components';

export const VerifyPICContainer = props => <RegistrationLayout { ...props } />;

const mapStateToProps = ({
  locale, sendingRequest, verifyPic, timeout, registration
}) => {
  const branding = {};
  branding.footer = registration.footer;
  branding.organizationLogo = registration.organizationLogo;
  branding.productLogo = registration.productLogo;
  branding.background = registration.background;
  
  return ({
  ...locale,
  ...sendingRequest,
  ...verifyPic,
  ...timeout,
  ...branding
});}
// prettier-ignore
const mapDispatchToProps = dispatch => bindActionCreators(
  Object.assign({}, { ...localeActions }, { ...verifyPicActions }, { ...timeoutActions }), dispatch,
);
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };

  Object.assign(props, { mainPane: <VerifyPICForm { ...props } /> });

  return props;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(VerifyPICContainer);
