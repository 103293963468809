import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/external-vendor/external-vendor-constants';
import EnvelopeIcon from 'adp-react-icons/lib/fa/envelope';
import MobileIcon from 'adp-react-icons/lib/fa/mobile';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import ThIcon from 'adp-react-icons/lib/fa/th';
import ReactDOM from 'react-dom';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

var ua = window.navigator.userAgent;
var isIE = (ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident") > -1 || ua.indexOf("Edge") > -1) ? true : false;


class ExternalVendorForm extends Component {

  componentWillMount() {
    this.props.getExternalVendorInfo();
    WindowUtil.setlayoutback(false);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sendRedirect)
      nextProps.setRedirect();
  }

  render() {
    const {
      externalVendorObj,
      title,
      showBusyIndicator,
      cancelAndCheckYourEntries,
      setmanualSelected,
      openExternalVendor,
      isMobileBrowser,
      isMobile,
      gaCategory,
      progressBarData
    } = this.props;

    const manualSelected = () => setmanualSelected();

    const submitAccessCode = () => setSubmitAccessCode();

    return (
      externalVendorObj.showDiv && (<div className="registration-content">

        <div className="registration-form-container" >
          {(window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
          }
          {
            (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
          }
          {(!isMobile) && (
            <div className="reg-cancel-icon">
              <svg id="evf_cancel" onClick={() => {
                ReactGA.sendevent(gaCategory, 'EXTERNAL_VENDOR', 'CANCEL');
                return cancelAndCheckYourEntries()
              }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                }}>
                <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
              </svg>
            </div>
          )}
        </div>
        {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
          <div className="registration-progress-bar">
            <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
          </div>)}
        {(!isMobile) && <hr className="header-hr" />}
        <div >
          <div className="vdl-row registration-form">
            <div className="welcome-title title-width ev-margin-h" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              <div className="vdl-col-md-12">
                <h1 id="registration-layout_welcome" className="page-title">{externalVendorObj.title}</h1>
              </div>
            </div>
            {/*********************External Vendor page start *****************************/}

            <div className="padding-align-inc-width" style={{ paddingBottom: "40px" }}>
              <div id="manualInfo" className="vdl-row ev-row-size"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CLICK', 'MANUAL_SELECT');
                  return manualSelected()
                }}>
                <ThIcon className={isIE === true ? "link-content ev-col-2 ie-margin-auto-icon" : "link-content ev-col-2 ev-th-icon"} />
                <div className={isIE === true ? "ev-col-9 ie-margin-auto-fix" : "ev-col-9 ev-margin-auto"}>
                  <div className="ev-text-label ev-font-size" ><span className="link-content">{<FormattedMessage id="externalVendor.manualInfo" />}</span></div>
                </div>
                <FaAngleRight style={{ fontSize: "24px" }} className={isIE === true ? "link-content ev-col-1 ie-chevron-icon" : "link-content ev-col-1 ev-chevron-icon"} />
              </div>


              {externalVendorObj.extVendorList &&
               <div id="evf_options">
                {externalVendorObj.extVendorList.map((value, index) => (
                  <div id={"p" + index} key={"p" + index} style={index == 0 ? { marginTop: "2px" } : undefined}
                    className="vdl-row ev-row-size"
                    onClick={() => {
                      ReactGA.sendevent(gaCategory, 'CLICK', 'CAPITAL_ONE');
                      return openExternalVendor()
                    }}>
                    <img className="ev-col-2 ev-image-heigth" src={value.logoPath} />
                     <div className="ev-col-9">
                      <div className="ev-list-div">
                        <div className="ev-sub-text-label ev-font-size ev-list-label-div"><span className="link-content">{this.props.intl.formatMessage({ id: value.title })}</span></div>
                        {(value.title.indexOf("2") == -1) && (<br />)}
                        {(value.title.indexOf("2") != -1) && (<span>&nbsp;</span>)}
                        <span className="link-content ev-list-span">(</span>
                        &nbsp;
                        <div className="ev-list-img-div"><img className="ev-sub-image-heigth ev-list-img" src={value.imgPath} /></div>
                        <div className="ev-list-subtitle-div"><span className="link-content">{this.props.intl.formatMessage({ id: value.subTitle })}</span></div>
                        <span className="link-content ev-list-span">)</span>
                      </div>
                     </div>
                    <FaAngleRight style={{ fontSize: "24px" }} className="link-content ev-col-1 ev-chevron-icon" />
                  </div> 
                  ))}
                </div> 
              }
            </div>

            {/*********************External Vendor  page end *****************************/}



          </div>
        </div>
      </div>
      )
    );
  }
}
export default injectIntl(ExternalVendorForm);
