import { handleActions } from 'redux-actions';
import { types } from './verify-401k-actions';

const {
  GET_401k_SIV_OPTIONS_SUCCESS,
  GET_401k_SIV_OPTIONS_FAIL,
  SET_401k_SIV_FIELD_SUCCESS,
  SET_401k_SIV_FIELD_FAIL,
  VERIFY_401k_ORG_RESPONSE,
  VERIFY_401k_MULTIORG_RESPONSE,
  ENABLE_401k_SUBMIT_BUTTON,
  SET_401k_RECAPTCHA_RESPONSE,
  SET_401k_ORG_SELECTED_SUCCESS,
  SET_401k_ORG_SELECTED_FAIL,
  LAUNCH_401k_IDENTITY_SUCCESS,
  LAUNCH_401k_IDENTITY_FAIL,
  VERIFY_401k_SIV_OPTIONS_FAIL,
  CANCEL_AND_CHECK_401k_ENTRIES
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  sivOptions: null,
  transactionId: '',
  backgroundImagePath: '',
  firstName: '',
  lastName: '',
  ssn: '',
  firstNameValid: true,
  lastNameValid: true,
  ssnValid: true,
  fullDobValid: true,
  errorCode:'',
  isRecaptchaOn: false,
  recaptchaSuccess:false
};

export default handleActions(
  {
    [GET_401k_SIV_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload}),

    [GET_401k_SIV_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_401k_SIV_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_401k_SIV_FIELD_FAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_401k_ORG_RESPONSE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_401k_MULTIORG_RESPONSE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [ENABLE_401k_SUBMIT_BUTTON]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_401k_RECAPTCHA_RESPONSE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_401k_ORG_SELECTED_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),    
    [SET_401k_ORG_SELECTED_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [LAUNCH_401k_IDENTITY_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),    
    [LAUNCH_401k_IDENTITY_FAIL]: (state, { payload }) => ({ ...state, ...payload }),  
    [VERIFY_401k_SIV_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),  
    [CANCEL_AND_CHECK_401k_ENTRIES]:  (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);
