/* eslint-disable max-len */

import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, BusyIndicator, Button } from '@synerg/vdl-react-components';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import InfoIcon from 'adp-react-icons/lib/fa/info-circle';
import { FormattedMessage } from 'react-intl';
import { getLocaleObjFromPropKey } from '../../util/locale';
import {MobileFooter} from '../../components/footer'
import SecurePage from '../../components/secure-page';
import { ReactGA, WindowUtil } from '../../util';

import AdpIcon from 'adp-react-icons/lib/adp/adp';
import {
  Footer, Timeout, Header
} from '../../components';

const isBlank = str => (str || '').trim().length === 0;

class RegistrationLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLayout: true
    };
  }

  componentDidUpdate(){
    WindowUtil.applyBrandingChanges();
  }

  render() {
    const {
      locale,
      mainPane, 
      footer,
      showBusyIndicator
    } = this.props;

  return (
    <div className="content-height">
    {this.state.showLayout &&
    <main
      className="content-height vdl-registration one-ux"
      role="main"
    >
      {showBusyIndicator && (<div aria-label="loading page" role="alert" aria-live="polite"><BusyIndicator
        style={{ color: "#1a8099" }}
        size="xl"
        overlay={true}
        fullScreen={true}
      /></div>)}

     <Header { ...this.props } />

      <div className={(footer != undefined && footer.showFooterContent != undefined && footer.showFooterContent === true)?"ssr-mobile-container":"vdl-container"}>
      <div className={this.props.contactInfoObj !== undefined || this.props.findMeMobileObj !== undefined ? 'registration-page-container cf-layout-width' : this.props.findMeObj !== undefined && (this.props.findMeObj.page == 'piiInfo' || this.props.findMeObj.page == 'contractorInfo') ? 'registration-page-container cf-layout-width' : 'registration-page-container'}>
          {mainPane}
        </div>
        {footer !== undefined && footer.showFooterContent !== undefined && footer.showFooterContent === true && 
        
        <div className="footerheight"></div>
        }
      </div>
      <div className="vdl-container">
        <div className="vdl-row">
          <div className="vdl-hidden-xs-ms">
            {footer !== undefined && <Footer {...this.props} />}
          </div>
        </div>
      </div>
      <div> 
            {footer !== undefined && footer.copyRightValue !== undefined && footer.showFooterContent !== undefined && footer.showFooterContent === true && 
            (
            <div className='mobile-footer' >
            <div className='mobile-footer-text'>{ getLocaleObjFromPropKey(footer.copyRightValue.component.text, locale) }</div>
            <span id='mobile-footer-info' className='mobile-footer-icon'>
            <InfoIcon  onClick={()=>{(this.props.verifyKbaObj!= undefined && this.props.verifyKbaObj.showDiv)?null: this.setState({ showLayout:false }); }} ></InfoIcon></span>
            </div>
            )

            }
      </div>
      <Timeout {...this.props} />
    </main>
    }
    {!this.state.showLayout &&
      <main
      className="vdl-registration"
      role="mainfooter"
      >
      
    <div className="registration-form-container" >
           {<div className="iy-form-sp-div"> <SecurePage /></div>}
        
              <div className="iy-form-cancel-div">
                <svg role="button" id="iden_cancel" onClick={() => {
                  ReactGA.sendevent('SSR_MOBILE_FOOTER', 'INFO_CANCEL', 'CANCEL');
                   WindowUtil.setlayoutback(true); 
                  this.setState({ showLayout:true})
                }}
                  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
           
          </div>
           { <hr style={{marginTop:"5px"}} className="iy-form-hr" />}
      <div className="vdl-mobile-container">
       <div className={this.props.contactInfoObj !== undefined || this.props.findMeMobileObj !== undefined ? 'registration-page-container cf-layout-width' : this.props.findMeObj !== undefined && this.props.findMeObj.page == 'piiInfo' ? 'registration-page-container cf-layout-width' : 'registration-page-container'}>
          <div className="ssr-mobile-content">
           <MobileFooter {...this.props}/>
           <div style={{paddingTop:"10px"}}>
              <Button
                id="mobileFooter_backbtn"
                style={{ paddingBottom: "0px",textDecoration:"none" }}
                className=" cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  WindowUtil.setlayoutback(true); 
                  this.setState({ showLayout:true})
                }}
               >
                  <ChevronLeft className="back-icon-align"  />
                  <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button>
           </div>
         </div>
        </div>
       </div>
      </main>
     }
     </div>
  );
};
}

RegistrationLayout.propTypes = {
  children: PropTypes.element,
  mainPane: PropTypes.element.isRequired,
  title: PropTypes.string,
  errorTitle: PropTypes.string,
};

export default RegistrationLayout;
