import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  Tooltip
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-bev/verify-bev-constants';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import Draggable from 'react-draggable';
import WindowClose from 'adp-react-icons/lib/fa/window-close';

const isBlank = str => (str || '').trim().length === 0;
let overlayPlacement = "right";


export default class VerifyBevEtimeForm extends Component {

  componentWillMount() {
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
  }

  render() {
    const {
      verifyBevObj,
      handleUpdateData

    } = this.props;

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
        />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
          >
          <div role="alert">{msg}</div>
        </Popover>
      ));


    const {
      etimeBev
    } = this.props.verifyBevObj.initData;


    const gaToolTip = (label) => ReactGA.sendevent(this.props.gaCategory, label, 'TOOLTIP');

    const handleDataChange = (parentName, fieldName, fieldValue, regEx, index) => {
      let payload = {};
      payload.type = 2;
      payload.parentName = parentName;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.regEx = regEx;
      payload.index = index;
      WindowUtil.setFirstAttemptGA({ gaCategory: this.props.gaCategory + "_" + "ETIME", fieldName: fieldName });
      return handleUpdateData(payload);
    }
    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );

    const handleDraggable = (value) => {
      let showDraggable = value;
      showDraggableModal(showDraggable);
    }
    return (
      <div>
        {/* employee number component start */}
      <div id={etimeBev.employee.label}>
        <div role="group" aria-labelledby={etimeBev.employee.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="employeeLabel">{etimeBev.employee.label}
              {etimeBev.employee.required && (<span className="vb-form-req-icon">*</span>)} 

              {!isBlank(etimeBev.employee.toolTip) && (<span id="Etime_empid_ques" aria-label="tooltip" className="vb-question-circle" onClick={() => gaToolTip('ETIME_EMPLOYEE_NUMBER')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(etimeBev.employee.toolTip)} >
                  <InfoCircle tabIndex="0" role="button" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[0], etimeBev.employee.message)}
              >
              <Textbox
                id="Etime_empid_textBox"
                maxLength={etimeBev.employee.maxLength}
                placeholder={etimeBev.employee.placeHolder}
                value={etimeBev.employee.value}
                className={!verifyBevObj.errorFieldsBackend[0] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("etimeBev", "employee", value, etimeBev.employee.regEx, 0)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* employee number component end */}







      </div>)


  }
}
