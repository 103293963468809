import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  Tooltip
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-bev/verify-bev-constants';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import Draggable from 'react-draggable';
import WindowClose from 'adp-react-icons/lib/fa/window-close';

const isBlank = str => (str || '').trim().length === 0;
let overlayPlacement = "right";



export default class VerifyBevHRNetForm extends Component {

  componentWillMount() {
    this.props.getDropdownValues("hrNetMBean");
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
  }

  render() {
    const {
      verifyBevObj,
      hideDraggableSet,
      showDraggableModal,
      showDraggable,
      onDropDownSelect,
      handleUpdateData,
      gaCategory

    } = this.props;

    const gaToolTip = (label) => ReactGA.sendevent(gaCategory, label, 'TOOLTIP');

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{msg}</div>
        </Popover>
      ));


    const {
      hrNetMBean
    } = this.props.verifyBevObj.initData;

    const handleDataChange = (parentName, fieldName, fieldValue, regEx, index) => {
      let payload = {};
      payload.type = 2;
      payload.parentName = parentName;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.regEx = regEx;
      payload.index = index;
      WindowUtil.setFirstAttemptGA({ gaCategory: this.props.gaCategory + "_" + "HRNET", fieldName: fieldName });
      return handleUpdateData(payload);
    }


    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );

    const handleDraggable = (value) => {
      let showDraggable = value;
      showDraggableModal(showDraggable);
    }
    return (
      <div>
        <div className="form-group form-align">
          <div className="vdl-row">
            <div id="hrnet_sampleDocBtn" className="vdl-col-sm-12 vdl-col-xs-12">
              <span role="button" className="vb-sample-doc focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'HRNET_SAMPLE_DOC', 'MODAL');
                      return handleDraggable(true);
                    }
                  }} onClick={() => {
                ReactGA.sendevent(gaCategory, 'HRNET_SAMPLE_DOC', 'MODAL');
                return handleDraggable(true)
              }}>{hrNetMBean.viewSampleDocs.label}</span>
            </div>
          </div>
        </div>
        {/* Draggable component start */}
        {showDraggable && (
          <div className="vb-draggable-header" id="draggable">
            <Draggable defaultPosition={{ x: 50, y: -500 }} >
              <div className="vb-draggable-body" >
                <div style={{ textAlign: 'right' }}>
                  <WindowClose className="focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return handleDraggable(false);
                    }
                  }} onClick={() => handleDraggable(false)} className="vb-draggable-close" />
                </div>
                <div className="vb-touch-scroll vb-form-draggable-div">
                  {hrNetMBean.viewSampleDocs.options.map((value, index) => (
                    <div key={index}>
                      <div className="vb-form-draggable-label">{value.label}</div>
                      <img id="cs-andriod-badget" width="300px" height="400px"
                        src={value.value} draggable="false"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Draggable>
          </div>)}
        {/* Draggable component end */}

        {/* employee number component start */}
      <div id={hrNetMBean.employeeNumber.label}>
        <div role="group" aria-labelledby={hrNetMBean.employeeNumber.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="employeeLabel">{hrNetMBean.employeeNumber.label}
                {hrNetMBean.employeeNumber.required && (<span className="vb-form-req-icon">*</span>)}

                {!isBlank(hrNetMBean.employeeNumber.toolTip) && (<span role="button" aria-label="tooltip" id="hrnetEN_ques" className="vb-question-circle" onClick={() => gaToolTip('HRNET_EMPLOYEE_NUMBER')}>
                  <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                    overlay={tooltip(hrNetMBean.employeeNumber.toolTip)} >
                    <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                  </OverlayTrigger>
                </span>)} </label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[0], hrNetMBean.employeeNumber.message)}
            >
              <Textbox aria-label={hrNetMBean.employeeNumber.label}
                id="hrnetEN_textBox"
                maxLength={hrNetMBean.employeeNumber.maxLength}
                placeholder={hrNetMBean.employeeNumber.placeHolder}
                value={hrNetMBean.employeeNumber.value}
                className={!verifyBevObj.errorFieldsBackend[0] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("hrNetMBean", "employeeNumber", value, hrNetMBean.employeeNumber.regEx, 0)}
              />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* employee number component end */}



        {/* NI number component start */}
      <div id={hrNetMBean.niNumber.label}>
        <div role="group" aria-labelledby={hrNetMBean.niNumber.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="niLabel">{hrNetMBean.niNumber.label}
                {hrNetMBean.niNumber.required && (<span className="vb-form-req-icon">*</span>)}

                {!isBlank(hrNetMBean.niNumber.toolTip) && (<span role="button" aria-label="niNumberTip" id="hrnetNiN_ques" className="vb-question-circle" onClick={() => gaToolTip('HRNET_NI_NUMBER')}>
                  <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                    overlay={tooltip(hrNetMBean.niNumber.toolTip)} >
                    <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                  </OverlayTrigger>
                </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[1], hrNetMBean.niNumber.message)}
            >
              <Textbox aria-label={hrNetMBean.niNumber.label}
                id="hrnetNiN_textBox"
                maxLength={hrNetMBean.niNumber.maxLength}
                placeholder={hrNetMBean.niNumber.placeHolder}
                value={hrNetMBean.niNumber.value}
                className={!verifyBevObj.errorFieldsBackend[1] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("hrNetMBean", "niNumber", value, hrNetMBean.niNumber.regEx, 1)}
              />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* NI number component end */}




        {/* Date of Birth component start */}
      <div id={hrNetMBean.fullMonthId.label}>
        <div role="group" aria-labelledby={hrNetMBean.fullMonthId.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-8 vdl-col-xs-15">
              <label id="fulldob" htmlFor="fulldob_Label">{hrNetMBean.fullMonthId.label}
                <span className="vb-form-dob-req-icon">*</span></label>
            </div>
          </div>
          <div className="vb-form-dropdown-div">
            <div style={{ width: window.innerWidth <= 479 ? '36%' : "33%" }}>
              <DropdownList aria-label="monthDropdown" 
                id="hrnet_month"
                data={verifyBevObj.fullMonthItems}
                placeholder={hrNetMBean.fullMonthId.placeHolder}
                onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return handleDataChange("hrNetMBean", "fullMonthId", value, null) }}
                value={hrNetMBean.fullMonthId.value}
              />
            </div>
            <div style={{ width: window.innerWidth <= 479 ? '30%' : "33%", marginLeft: "2%" }}>
              <DropdownList aria-label="dayDropdown" 
                id="hrnet_day"
                data={verifyBevObj.fullDayItems}
                placeholder={hrNetMBean.fullDayId.placeHolder}
                onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return handleDataChange("hrNetMBean", "fullDayId", value, null) }}
                value={hrNetMBean.fullDayId.value}
              />
            </div>
            <div className="vb-form-dropdown-year-div">
              <DropdownList aria-label="yearDropdown" 
                id="hrnet_year"
                data={verifyBevObj.w2YearList}
                placeholder={hrNetMBean.fullYearId.placeHolder}
                onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return handleDataChange("hrNetMBean", "fullYearId", value, null) }}
                value={hrNetMBean.fullYearId.value}
              />
            </div>
          </div>
        </div>
      </div>
        {/* Date of Birth component end */}


        {/* postalCode component start */}
      <div id="hrnetPCGroup">
        <div role="group" aria-labelledby="hrnetPCGroup" className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="postalLabel">{hrNetMBean.postalCode.label}
                {hrNetMBean.postalCode.required && (<span className="vb-form-req-icon">*</span>)} </label>

              {!isBlank(hrNetMBean.postalCode.toolTip) && (<span role="button" aria-label="postalCodeTip" id="hrnetPC_ques" className="vb-question-circle" onClick={() => gaToolTip('HRNET_POSTAL_CODE')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(hrNetMBean.postalCode.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                </OverlayTrigger>
              </span>)}
            </div>
          </div>
          <div className="input-group-full vb-freedom-postalcodediv">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[2], hrNetMBean.postalCode.message)}
            >
              <Textbox
                id="hrnetPC_textBox"
                maxLength={hrNetMBean.postalCode.maxLength}
                placeholder={hrNetMBean.postalCode.placeHolder}
                value={hrNetMBean.postalCode.value}
                className={!verifyBevObj.errorFieldsBackend[2] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("hrNetMBean", "postalCode", value, hrNetMBean.postalCode.regEx, 2)}

              />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* postalCode component end */}





      </div>)


  }
}
