import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { store } from '../../../store';
import { types } from '../timeout-actions';
import { constants } from '../timeout-constants';
import { WindowUtil } from '../../../../util';

const {
  CHECK_SESSION_TIMEOUT,
  GET_SESSION_TIMEOUT_CONFIG,
  GET_SESSION_TIMEOUT_CONFIG_SUCCESS,
  GET_SESSION_TIMEOUT_CONFIG_FAIL,
  SESSION_TIMEOUT,
  SESSION_TIMEOUT_WARN,
  SESSION_TIMEOUT_INITIATE,
  CHECK_WARNING_TIMEOUT,
  UPDATE_SESSION_TIMEOUT,
  REDIRECT_TO_LOGIN_PAGE
} = types;

const { checkSessionEndPoint, sessionTimeoutEndPoint } = constants;

const setInitiateSuccess = createAction(SESSION_TIMEOUT);

const sessionTimeoutWarnSent = createAction(SESSION_TIMEOUT);
const getSessionTimeoutConfigSuccess = createAction(GET_SESSION_TIMEOUT_CONFIG_SUCCESS);
const getSessionTimeoutConfigFail = createAction(GET_SESSION_TIMEOUT_CONFIG_FAIL);
const setTimeOutField = createAction(UPDATE_SESSION_TIMEOUT);

const getSessionTimeoutConfigApi = getState => ajax({
  url: `${sessionTimeoutEndPoint}`,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
  },
  method: 'POST',
  responseType: 'json',
}).pipe(
  map(response => handleGetSessionTimeoutConfigResponse(response.response, getState)),
  catchError(err => of(getSessionTimeoutConfigFail(getErrorMessage(err)))),
);

const warningTimeoutApi = (payload, getState) => ajax({
  url: `${checkSessionEndPoint}`,
  headers: payload.headers,
  method: 'POST',
  responseType: 'json',
}).pipe(
  concatMap(response => handleWarningTimeoutResponse(response.response, payload, getState)),
  catchError(err => of(getSessionTimeoutConfigFail(getErrorMessage(err)))),
);

const handleWarningTimeoutResponse = (response, payload, getState) => {
  const timeout = Object.assign({}, getState.timeout);
  response = response.status;
  if (payload.returnClick) {
    if (response !== null && response === 'valid') {
      return of({ timeoutWarn: false, timeout: false, reloadTimeout: true });
    }
    return of({ timeout: true });
  }

  if (response !== null && response === 'valid') {
    return of({ timeoutWarn: true, timeout: false });
  }
  return of({ timeout: true });
};

const handleGetSessionTimeoutConfigResponse = (response, getState) => {
  const timeout = Object.assign({}, getState.timeout);

  if (response !== null) {
    timeout.warningTime = response.warningTimeout || constants.warningTime;
    timeout.signoutTime = response.sessionTimeOut || constants.signoutTime;
    timeout.userActivityCutoff = response.userActivityCutoff || constants.userActivityCutoff;
    return getSessionTimeoutConfigSuccess(timeout);
  }
  return getSessionTimeoutConfigFail();
};

const callWarningTimeoutService = (revClientTimestamp, getState) => callWarningTimeoutServiceHandler(revClientTimestamp, getState).pipe(
  map((response) => {
    const payload = {};

    if (response.reloadTimeout) {
      payload.reloadTimeout = response.reloadTimeout;
    } else if (response != undefined) {
      payload.timeout = response.timeout;
      payload.timeoutWarn = response.timeoutWarn;
    }
    return setTimeOutField({ payload });
  }),
  catchError(err => of(getSessionTimeoutConfigFail(getErrorMessage(err)))),
);

const callWarningTimeoutServiceHandler = (payload, getState) => {

  if (payload.returnClick) {
    const headers = {};

    headers['Content-Type'] = 'application/json';
    headers['cache-control'] = 'no-cache';
    payload.headers = headers;
    return warningTimeoutApi(payload, getState);
  }

  const revClientTimestamp = payload.revClientTimestamp;
  const userActivityCutoff = getState.timeout.userActivityCutoff;
  let isUserActive = true;
  const currentTimestamp = new Date();
  const timeSinceClientActive = currentTimestamp.getTime() - revClientTimestamp.getTime();

  if (timeSinceClientActive > userActivityCutoff) {
    isUserActive = false;
  }
  if (!isUserActive) {
    const headers = {};

    headers['Content-Type'] = 'application/json';
    headers['cache-control'] = 'no-cache';
    headers['x-lastaccessedtime'] = timeSinceClientActive;
    payload.headers = headers;
    payload.returnClick = false;
    return warningTimeoutApi(payload, getState);
  }
  return of({ reloadTimeout: true });
};

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId;
  if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const callSessionTimeoutConfigService = getState => getSessionTimeoutConfigApi(getState);

export const getSessionTimeoutConfigEpic = (action$, state) => action$.pipe(
  ofType(GET_SESSION_TIMEOUT_CONFIG),
  switchMap(action => sendingAsyncRequest(callSessionTimeoutConfigService(state.value))),
);

export const getWarningTimeoutEpic = (action$, state) => action$.pipe(
  ofType(CHECK_WARNING_TIMEOUT),
  switchMap(action => sendingAsyncRequest(callWarningTimeoutService(action.payload, state.value))),
);

export const getSessionTimeoutConfigFailEpic = action$ => action$.pipe(
  ofType(GET_SESSION_TIMEOUT_CONFIG_FAIL),
  switchMap(action$ => of(push('/error'))),
);

export const redirectToLoginPageEpic = (action$, state) => action$.pipe(
  ofType(REDIRECT_TO_LOGIN_PAGE),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);