import { handleActions } from 'redux-actions';
import { types } from './identify-yourself-actions';
import { ReactGA } from '../../../util';
const {
  GET_SIV_OPTIONS_SUCCESS,
  GET_SIV_OPTIONS_FAIL,
  SET_SIV_FIELD_SUCCESS,
  SET_SIV_FIELD_FAIL,
  VERIFY_SIV_OPTIONS_SUCCESS,
  VERIFY_SIV_OPTIONS_FAIL,
  ENABLE_SUBMIT_BUTTON,
  SET_RECAPTCHA_RESPONSE,
  NAVIGATE_TO_BEV,
  NAVIGATE_TO_BEV_SUCCESS,
  CANCEL_AND_CHECK_YOUR_ENTRIES
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  sivOptions: null,
  transactionId: '',
  backgroundImagePath: '',
  firstName: '',
  lastName: '',
  ssn: '',
  empId:'',
  wfnId:'',
  zipcode:'',
  ssn4:'',
  firstNameValid: true,
  lastNameValid: true,
  ssnValid: true,
  fullDobValid: true,
  empIdValid: true,
  wfnIdValid: true,
  ssn4Valid: true,
  zipcodeValid: true,
  errorCode:'',
  isRecaptchaOn: false,
  recaptchaSuccess:false,
  day:'',
  month:'',
  year:'',
  fullDay:'',
  fullMonth:'',
  fullYear:''
};

export default handleActions(
  {
    [GET_SIV_OPTIONS_SUCCESS]: (state, { payload }) => {
      let isMobile = payload.isMobile;
      let isMobileBrowser = true;
      if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
      let gaCategory = "WEB_REGISTRATION_IDENTIFY_YOURSELF";
      let pagePath1 = "/ssr/flowType/cpc";
      let pagePath2 = "/ssr/cpc/verifyUserRegcode";
      let pageView1 = pagePath1 + "/web";
      let pageView2 = pagePath2 + "/web";
      if (isMobile) { pageView1 = pagePath1 + "/mobile"; pageView2 = pagePath2 + "/mobile"; gaCategory = "MOBILE_WEB_REGISTRATION_IDENTIFY_YOURSELF"; }
      if (window.innerWidth <= 479 && isMobile == false) { pageView1 = pagePath1 + "/mobilebrowser"; pageView2 = pagePath2 + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_WEB_REGISTRATION_IDENTIFY_YOURSELF"; }
      ReactGA.pageview(pageView1);
      ReactGA.pageview(pageView2);
  
      let sivOptions = '';
      let sivGroup = '';
      let sivGroupOther = '';
      let empIdPDOB,wfnIdPDOB,ssnPDOB,ssn4PDOB,empIdFDOB,wfnIdFDOB,ssnFDOB,ssn4FDOB,keyWithBothDOB = false;
      let empIdCounter = 0;
      let ssnCounter = 0;
      let wfnIdCounter = 0;
      let ssn4Counter = 0;
      let clientName = payload.clientName;
      if (payload.sivOptions) {
        sivOptions = payload.sivOptions;        
      } else if (payload.regCodeData) {
        sivOptions = payload.regCodeData.sivOptions;
        clientName = payload.regCodeData.companyName;
      }
      if(sivOptions != '' && sivOptions.sivCPCOr){
          var sivGroups = sivOptions.identifierTypes;
           if(sivOptions.identifierTypes != null && sivOptions.identifierTypes.length > 1){
            var group;
             for(group in sivGroups){
              
               if(sivGroups[group].includes('PDOB')){
                  if(sivGroups[group].includes('EMPID')){
                    empIdPDOB = true;
                    empIdCounter = empIdCounter + 1;
                  }
                  if(sivGroups[group].includes('WFNID')){
                    wfnIdPDOB = true;
                    wfnIdCounter = wfnIdCounter + 1;
                  }
                  if(sivGroups[group].includes('SSN') && !sivGroups[group].includes('SSN4')){
                    ssnPDOB = true;
                    ssnCounter = ssnCounter + 1;
                  }
                  if(sivGroups[group].includes('SSN4')){
                    ssn4PDOB = true;
                    ssn4Counter = ssn4Counter + 1;
                  }
               }
                if(sivGroups[group].includes('FDOB')){
                  if(sivGroups[group].includes('EMPID')){
                    empIdFDOB = true;
                    empIdCounter = empIdCounter + 1;
                  }
                  if(sivGroups[group].includes('WFNID')){
                     wfnIdFDOB = true;
                     wfnIdCounter = wfnIdCounter + 1;
                  }
                  if(sivGroups[group].includes('SSN') && !sivGroups[group].includes('SSN4')){
                    ssnFDOB = true;
                    ssnCounter = ssnCounter + 1;
                  }
                  if(sivGroups[group].includes('SSN4')){
                    ssn4FDOB = true;
                    ssn4Counter = ssn4Counter + 1;
                  }
                }                
             }
          }
          if(empIdCounter > 1 || wfnIdCounter > 1 || ssnCounter > 1 || ssn4Counter > 1){
            keyWithBothDOB = true;
          }
      }  
      return ({ ...state, ...payload, sivOptions,clientName,empIdPDOB,wfnIdPDOB,ssnPDOB,ssn4PDOB,empIdFDOB,wfnIdFDOB,
        ssnFDOB,ssn4FDOB,isMobile,isMobileBrowser,gaCategory,keyWithBothDOB})
    },

    [GET_SIV_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_SIV_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_SIV_FIELD_FAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_SIV_OPTIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_SIV_OPTIONS_FAIL]: (state, { payload }) => ({
     ...state,
      ...payload,
    }),
    [ENABLE_SUBMIT_BUTTON]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_RECAPTCHA_RESPONSE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [NAVIGATE_TO_BEV_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }), 
    [CANCEL_AND_CHECK_YOUR_ENTRIES]: (state, { payload }) => ({ ...state, ...payload }),    
  },
  initialState,
);
