import { createTypes, async } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'CHECK_SESSION_TIMEOUT',
  'SESSION_TIMEOUT',
  'SESSION_TIMEOUT_WARN',
  'GET_SESSION_TIMEOUT_CONFIG',
  'GET_SESSION_TIMEOUT_CONFIG_SUCCESS',
  'GET_SESSION_TIMEOUT_CONFIG_FAIL',
  'SESSION_TIMEOUT_INITIATE',
  'UPDATE_SESSION_TIMEOUT',
  'CHECK_WARNING_TIMEOUT',
  'REDIRECT_TO_LOGIN_PAGE'
);

const {
  CHECK_SESSION_TIMEOUT,
  GET_SESSION_TIMEOUT_CONFIG,
  UPDATE_SESSION_TIMEOUT,
  CHECK_WARNING_TIMEOUT,
  REDIRECT_TO_LOGIN_PAGE
} = types;

const checkSessionTimeoutSent = createAction(CHECK_SESSION_TIMEOUT);

const getSessionTimeoutConfigSent = createAction(GET_SESSION_TIMEOUT_CONFIG);

const setTimeoutWarnSent = createAction(UPDATE_SESSION_TIMEOUT);

const checkTimeOutSent = createAction(CHECK_WARNING_TIMEOUT);

const redirectToLoginPageSent = createAction(REDIRECT_TO_LOGIN_PAGE);

const checkSessionTimeout = () => checkSessionTimeoutSent();

const loadSessionTimeoutConfig = () => getSessionTimeoutConfigSent();

const setTimeoutWarn = value => setTimeoutWarnSent({ timeoutWarn: value });

const checkTimeOut = payload => checkTimeOutSent(payload);

const redirectToLoginPage = () => redirectToLoginPageSent();

export const actions = {
  checkSessionTimeout,
  loadSessionTimeoutConfig,
  setTimeoutWarn,
  checkTimeOut,
  redirectToLoginPage
};
