import { handleActions } from 'redux-actions';
import { types } from './verify-user-pin-actions';

const { VERIFY_USER_PIN_SUCCESS, VERIFY_USER_PIN_FAIL, SET_USER_PIN , ENABLE_USERPIN_SUBMIT_BUTTON , CHECK_ERROR_MESSAGE } = types;

export const initialState = {
  userPinValid: true
};

export default handleActions(
  {
    [SET_USER_PIN]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_USER_PIN_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_USER_PIN_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [ENABLE_USERPIN_SUBMIT_BUTTON]: (state, { payload }) => ({ ...state, ...payload }),

    [CHECK_ERROR_MESSAGE]: (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);
