/* eslint-disable max-len, jsx-a11y/href-no-hash */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';

const ErrorForm = ({ message }) => (
  <div className="vdl-row login-form">
    <div className="vdl-col-md-12">
      <form className="adp-form">
        <p role="alert"><Alert
          style={{ width: '100%' }}
          type="error">
          <FormattedMessage id="Txt_VGeneralErrorWindowTitle" />
          <br />
          <FormattedMessage id="Err_SystemErrorinRequest" />
        </Alert></p>
      </form>
    </div>
  </div>
);

ErrorForm.propTypes = {
  message: PropTypes.string,
};

export default ErrorForm;
