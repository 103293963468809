import { handleActions } from 'redux-actions';
import { types } from './find-me-actions';

const {
  FIND_ME_UPDATE_FIELD,
  REDIRECT_TO_IDENTITY_PAGE_SUCCESS,
  CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES,
  UPDATE_LOCALE_FIELD,
  SET_FINDME_SIV_FIELD_FAIL,SET_FINDME_SIV_FIELD_SUCCESS,
  VERIFY_SIV_DATA_SUCCESS,
  SET_CONTRACTOR_SIV_FIELD_FAIL,SET_CONTRACTOR_SIV_FIELD_SUCCESS,
  VERIFY_CONTRACTOR_SIV_DATA_SUCCESS,
  REGISTRATION_LESS_FLOW,
  CONTINUE_REGISTRATION_FLOW_SUCCESS
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  findMeObj: {
    showDiv: false
  }

};

export default handleActions(
  {
    [FIND_ME_UPDATE_FIELD]: (state, { payload }) => ({ ...state, ...payload }),

    [REDIRECT_TO_IDENTITY_PAGE_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [UPDATE_LOCALE_FIELD]: (state, { payload }) => ({ ...state, ...payload }),

    [CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_FINDME_SIV_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_FINDME_SIV_FIELD_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_SIV_DATA_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_CONTRACTOR_SIV_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_CONTRACTOR_SIV_FIELD_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_CONTRACTOR_SIV_DATA_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [REGISTRATION_LESS_FLOW]: (state, { payload }) => ({ ...state, ...payload }),

    [CONTINUE_REGISTRATION_FLOW_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

  },
  initialState,
);
