import { handleActions } from 'redux-actions';
import { types } from './register-user-actions';

const {
  GET_USER_ID_OPTIONS_SUCCESS , GET_USER_ID_OPTIONS_FAIL,
  SET_REGISTER_OPTIONS_FIELD_SUCCESS, SET_REGISTER_OPTIONS_FIELD_FAIL,
  VERIFY_REGISTER_OPTIONS_SUCCESS, VERIFY_REGISTER_OPTIONS_FAIL,
  ENABLE_REGISTER_BUTTON,
  SET_QUESTIONS_FIELD_SUCCESS,SET_QUESTIONS_FIELD_FAIL,
  SET_ANSWERS_FIELD_SUCCESS,SET_ANSWERS_FIELD_FAIL,
  VERIFY_SECURITY_QUESTIONS_SUCCESS, VERIFY_SECURITY_QUESTIONS_FAIL,
  ENABLE_SECURITY_QA_BUTTON,
  CHECK_USERID_AVAILABILITY_SUCCESS,CHECK_USERID_AVAILABILITY_FAIL,
  SET_MODAL_FIELD,
  SET_AGREE_TERMS_CONDITION,
  HIGHLIGHT_INVALID_FIELDS
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  transactionId: '',
  backgroundImagePath: '',
  userId: '',
  partialUserId: '',
  password: '',
  confirmpassword: '',
  userIdValid: true,
  partialUserIdValid: true,
  passwordValid: true,
  confirmpasswordValid: true,
  title: '',
  firstQuestion: '',
  secondQuestion: '',
  thirdQuestion: '',
  firstAnsValid: true,
  secondAnsValid: true,
  thirdAnsValid: true,
  firstAnswer: '',
  secondAnswer: '',
  thirdAnswer: '',
  availableUserResponse:'',
  userIdType:'',
  userIdLabelName:'',
  userIdInvalidMessage:'',
  userIdRegExp:'',
  userIdValue:'',
  passwordLabelName:'',
  passwordInvalidMessage:'',
  passwordRegExp:'',
  passwordValue:'',
  confirmPasswordLabelName:'',
  confirmPasswordInvalidMessage:'',    
  confirmPasswordRegExp:'',
  confirmPasswordValue:'',
  showSecurityQuestions: false,
  firstNameForTitle:'',
  showTc:false,
  securityQnATextList:[],
  securityQnAIdList:[],
  agreeTnC:false,
  userLastName:'',
  companyName:'',
  firstAnsInlineErrMessage:'',
  secondAnsInlineErrMessage:'',
  thirdAnsInlineErrMessage:'',
  title:'',
  errorTitle:'',
  finalUserIdValue:'',
  fetchedUserId:null
};

export default handleActions(
  {
    [GET_USER_ID_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [GET_USER_ID_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_REGISTER_OPTIONS_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_REGISTER_OPTIONS_FIELD_FAIL]: (state, { payload }) => ({ ...state, ...payload, }),

    [VERIFY_REGISTER_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload, }),

    [VERIFY_REGISTER_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload,  }),

    [ENABLE_REGISTER_BUTTON]: (state, { payload }) => ({ ...state, ...payload, }),

    [SET_QUESTIONS_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_QUESTIONS_FIELD_FAIL]: (state, { payload }) => ({ ...state, ...payload, }),

    [SET_ANSWERS_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload, }),

    [SET_ANSWERS_FIELD_FAIL]: (state, { payload }) => ({ ...state, ...payload,  }),

    [VERIFY_SECURITY_QUESTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload, }),    

    [VERIFY_SECURITY_QUESTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload,  }),

    [ENABLE_SECURITY_QA_BUTTON]: (state, { payload }) => ({ ...state, ...payload, }),    

    [CHECK_USERID_AVAILABILITY_SUCCESS]: (state, { payload }) => ({ ...state, ...payload, }),
    
    [CHECK_USERID_AVAILABILITY_FAIL]: (state, { payload }) => ({ ...state, ...payload, }),

    [SET_MODAL_FIELD]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_AGREE_TERMS_CONDITION]: (state, { payload }) => ({ ...state, ...payload }),

    [HIGHLIGHT_INVALID_FIELDS]: (state, { payload }) => ({ ...state, ...payload, showFailedModal:false }),

  },
  initialState,

);
