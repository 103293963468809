import { handleActions } from 'redux-actions';
import { types } from './verify-prc-actions';

const {
  VERIFY_PRC_INFO_OPTIONS_SUCCESS,
  VERIFY_PRC_INFO_OPTIONS_FAIL,
  SET_IDENTITY_SELECTED_SUCCESS,
  ACCESS_CODE_SENT_SUCCESS,
  SET_VERIFY_PRC_FIELD_VALUE


} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  showOtcModal: false,
  transactionId: '',
  verifyPrcObj: {
    prcValid: true,
    showAlert: false,
    showDiv: false,
    disableFields: [],
    showDiffMethodBtn: true,
    contactId:null,
    commType:null,
    showNudgePage:false
  },
  prevNumber:0,
  prevDialCode:0,
};

export default handleActions(
  {
    [VERIFY_PRC_INFO_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [VERIFY_PRC_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_IDENTITY_SELECTED_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [ACCESS_CODE_SENT_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_VERIFY_PRC_FIELD_VALUE]: (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);