export const constants = {
  Enter_Code: "Enter_Code",
  Identification: "Identification",
  Contact_Info: "Contact_Info",
  Create_Account: "Create_Account",
  Search: "Search",
  Get_Started: "Get_Started",
  Verify: "Verify",
  verifyUser: "ORC_PIC_FLOW",
  verify401k: "SKIP_CODE_FLOW",
  verifyPic: "SKIP_CODE_FLOW",
  verifyBev: "SKIP_CODE_FLOW",
  identifyYourself: "SKIP_CODE_FLOW",
  verifyUserId: "SKIP_CODE_FLOW",
  verifyPrc: "SKIP_CODE_FLOW",
  verifyKba: "SKIP_CODE_FLOW",
  contactInfo: "SKIP_PII_FLOW",
  findMe: "CODE_LESS_FLOW",
  verifyAdmin: "SKIP_CONTACTINFO_FLOW",
  findMeViewId: "/findMe",
  verifyUserViewId: "/verifyUser",
  verify401kViewId: "/verify401k",
  verifyPicViewId: "/verifyPic",
  identifyYourselfViewId: "/identifyYourself",
  verifyUserIdViewId: "/verifyUserId",
  verifyPrcViewId: "/verifyPrc",
  contactInfoViewId: "/contactInfo",
  verifyAdminViewId: "/runAdminV2"
};
