import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_BEV_INFO_OPTIONS',
  'VERIFY_BEV_INFO_OPTIONS_SUCCESS',
  'VERIFY_BEV_INFO_OPTIONS_FAIL',
  'VERIFY_BEV_INFO_UPDATE',
  'VERIFY_BEV_DROPDOWN_SELECT',
  'VERIFY_BEV_UPDATE_DATA',
  'VERIFY_BEV_GET_DROPDOWN_VALUES',
  'VERIFY_BEV_GET_W2_DROPDOWN_VALUES',
  'VERIFY_BEV_SET_IPAY_RADIO_BTN',
  'VERIFY_BEV_SUBMIT_BTN',
  'CANCEL_BEV_AND_CHECK_YOUR_ENTRIES',
  'ON_FINAL_VERIFY_BEV_SUCCESS'


);

const {
  VERIFY_BEV_INFO_OPTIONS,
  VERIFY_BEV_INFO_UPDATE,
  VERIFY_BEV_DROPDOWN_SELECT,
  VERIFY_BEV_UPDATE_DATA,
  VERIFY_BEV_GET_DROPDOWN_VALUES,
  VERIFY_BEV_GET_W2_DROPDOWN_VALUES,
  VERIFY_BEV_SET_IPAY_RADIO_BTN,
  VERIFY_BEV_SUBMIT_BTN,
  CANCEL_BEV_AND_CHECK_YOUR_ENTRIES,
  ON_FINAL_VERIFY_BEV_SUCCESS


} = types;

const getVerifyBevInfoSent = createAction(VERIFY_BEV_INFO_OPTIONS);
const setVerfiyBevUpdateSent = createAction(VERIFY_BEV_INFO_UPDATE);
const onDropDownSelectSent = createAction(VERIFY_BEV_DROPDOWN_SELECT);
const handleUpdateDataSent = createAction(VERIFY_BEV_UPDATE_DATA);
const getDropdownValuesSent = createAction(VERIFY_BEV_GET_DROPDOWN_VALUES);
const getW2DropdownValuesSent = createAction(VERIFY_BEV_GET_W2_DROPDOWN_VALUES);
const handleIpayRadioBtnSent = createAction(VERIFY_BEV_SET_IPAY_RADIO_BTN);
const onBevSubmitBtnSent = createAction(VERIFY_BEV_SUBMIT_BTN);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_BEV_AND_CHECK_YOUR_ENTRIES);
const setRedirectPageSent = createAction(ON_FINAL_VERIFY_BEV_SUCCESS);


const getVerifyBevInfo = () => getVerifyBevInfoSent();
const showDraggableModal = (showDraggable) => setVerfiyBevUpdateSent({ showDraggable });
const onDropDownSelect = (payload) => onDropDownSelectSent(payload);
const enableSubmitButton = (isFormValid) => setVerfiyBevUpdateSent({isFormValid});
const handleUpdateData = (payload) => handleUpdateDataSent(payload);
const getDropdownValues = (payload) => getDropdownValuesSent(payload);
const getW2DropdownValues = (payload) => getW2DropdownValuesSent(payload);
const handleIpayRadioBtn = (payload) => handleIpayRadioBtnSent(payload);
const onBevSubmitBtn = () => onBevSubmitBtnSent();
const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();
const setRedirectPage = () => setRedirectPageSent();
const setRecaptchaResponse = (recaptchaResponse) =>setVerfiyBevUpdateSent({recaptchaResponse});
const showIpayDraggableModal = (showIpayDraggable) => setVerfiyBevUpdateSent({ showIpayDraggable });


export const actions = {
  getVerifyBevInfo,
  showDraggableModal,
  onDropDownSelect,
  enableSubmitButton,
  handleUpdateData,
  getDropdownValues,
  getW2DropdownValues,
  handleIpayRadioBtn,
  onBevSubmitBtn,
  cancelAndCheckYourEntries,
  setRedirectPage,
  setRecaptchaResponse,
  showIpayDraggableModal
};
