import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'FIND_ME_MOBILE_INFO_OPTIONS',
  'FIND_ME_MOBILE_INFO_OPTIONS_SUCCESS',
  'FIND_ME_MOBILE_INFO_OPTIONS_FAIL'
);

const {
  FIND_ME_MOBILE_INFO_OPTIONS
} = types;

const getFindMeMobileInfoSent = createAction(FIND_ME_MOBILE_INFO_OPTIONS);


const getFindMeMobileInfo = () => getFindMeMobileInfoSent();

export const actions = {
  getFindMeMobileInfo

};
