import locale from './locale';
import registration from './registration';
import identifyYourself from './identify-yourself';
import verifyUser from './verify-user';
import verifyUserId from './verify-user-id';
import verifyUserPin from './verify-user-pin';
import sendingRequest from './sending-request';
import contactInfo from './contact-info';
import timeout from './timeout';
import verifyPic from './verify-pic';
import registerUser from './register-user';
import confirmation from './confirmation';
import verifyPrc from './verify-prc';
import externalVendor from './external-vendor';
import verifyKba from './verify-kba';
import verify401k from './verify-401k';
import verifyBev from './verify-bev';
import findMe from './find-me';
import findMeMobile from './find-me-mobile';
import verifyAdmin from './verify-admin';

export const reducers = {
  locale,
  registration,
  identifyYourself,
  verifyUser,
  verifyUserId,
  verifyUserPin,
  sendingRequest,
  contactInfo,
  timeout,
  verifyPic,
  registerUser,
  confirmation,
  verifyPrc,
  externalVendor,
  verifyKba,
  verify401k,
  verifyBev,
  findMe,
  findMeMobile,
  verifyAdmin
};
