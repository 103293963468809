import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalTitle,
} from '@synerg/vdl-react-components';
import { ReactGA } from '../../util';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';
import OTPFooter from '../verify-prc/OTPFooter';
import { WindowUtil } from '../../util';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
let disableResend = false;
const isBlank = str => (str || '').trim().length === 0;

const allowEnterKeyFields = ["prcTextBox"];

class PersonalPhoneForm extends Component {

    constructor(props) {
        super(props);
        if (!WindowUtil.getlayoutback()) {
            this.state = { disableResendBtn: false };
        }
        else {
            this.state = { disableResendBtn: WindowUtil.isresendEnabled() };
        }
    }

    componentWillMount() {
        if (!WindowUtil.getlayoutback()) {
            this.props.getChannelInfo();
        }
        WindowUtil.setlayoutback(false);
    }

    componentWillReceiveProps = (nextProps) => {
        this.checkFormReadyness(nextProps);
    }
    checkFormReadyness = (nextProps) => {
        let formValid = true;
        if (nextProps.contactInfoObj.showVerify == true && isBlank(nextProps.otpCode))
            formValid = false;
        this.props.enableOtpSubmitButton(formValid);
    }

    _handleKeyPress = (e, valid) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if(e.target !== undefined && e.target.id!==undefined && valid){
                if(allowEnterKeyFields.indexOf(e.target.id)!="-1"){
                    ReactGA.sendevent(this.props.gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                    this.props.setSubmitAccessCode();
                }
              }
        }
    }

    render() {
        const {
            otpCode,
            setOtpValue,
            isOTPFormValid,
            gaCategory,
            isMobileBrowser,
            isMobile,
            cancelAndCheckYourEntries,
            getChannelInfo,
            setSubmitAccessCode,
            showModalError

        } = this.props;

        const {

            showVerify,
            selectedIdentityValue,
            expiry,
            showAlert,
            showAlertMessage,
            prcValid,
            showOnScreen,
            accessCode,
            progressBarData,
            showModalTitle,
            showModalMessage

        } = this.props.contactInfoObj;

        const onSetOtpValue = (event) => {
            const inputValue = event.target.value.replace(/\D/g, '');

            if (this.props.otpCode === inputValue) {
                return;
            }
            const otcCode = inputValue;

            setOtpValue(otcCode);
        }

        const onResendBtnClick = () => {
            this.setState({ disableResendBtn: true });
            WindowUtil.setresend(true);
            disableResend = true;
            ReactGA.sendevent(gaCategory, 'RESEND_OTP', 'CLICK');
            getChannelInfo();
        }

        return (
            <div className="registration-content">
                <div className="registration-form-container" >
                    {
                        (window.innerWidth > 479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
                    }
                    {
                        (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
                    }
                    {((isMobileBrowser && !isMobile && showVerify) || window.innerWidth > 479) && (

                        <div tabIndex = "0" className="vf-cancel-icon focus-selector" onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'CANCEL');
                              return cancelAndCheckYourEntries();
                            }
                          }}>
                            <svg id="prc_cancel" role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                                onClick={() => {
                                    ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'CANCEL');
                                    return cancelAndCheckYourEntries();
                                }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor" style={{
                                    cursor: "pointer",
                                    float: "right",
                                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                                }}><g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                            </svg>
                        </div>
                    )}
                </div>
                {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
                    <div className="registration-progress-bar">
                        <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
                    </div>
                )}
                {(!isMobile) && <hr className="header-hr" />}


                {/*********************ONE  TIME CODE  page start *****************************/}
                <div className="vdl-row registration-form">
                    {(showVerify == true) && (
                        <div style={{ width: "100%" }}>
                            <div className="vdl-col-md-12">
                                <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="otp.title" />}</h1>
                            </div>
                            <form id="verify-prc-form-2" onKeyPress={(e) => this._handleKeyPress(e, isOTPFormValid)} 
                            onSubmit={(e) => {
                                e.preventDefault();
                              }}
                            className="vf-sub-form-padding padding-align-inc-width">
                                <div>
                                    <div id="verify-prc-heading" className="vf-heading vf-marginbottom-0">
                                        <span id="cellphone-message">
                                            <FormattedMessage
                                                id="vf.otpPhnHeading"
                                                values={{
                                                    0: selectedIdentityValue,
                                                }}
                                            />
                                        </span>
                                    </div>
                                    <div id="verify-prc-heading" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="findMe.otpSubTitle" values={{ 0: expiry }} />}</div>
                                </div>
                                <div className="vf-emailphone-width-otp vf-otp-div" >

                                    {(!showAlert) &&
                                        (<span className="label-fields label-otp-align">{<FormattedMessage id="findMe.otpCode" />}</span>)}
                                    {(showAlert) && (<div className="vf-prc-otc-alert" role="alert">
                                        {ReactHtmlParser(showAlertMessage)}
                                    </div>
                                    )}
                                    <input
                                        id="prcTextBox" aria-label={this.props.intl.formatMessage({ id: 'findMe.otpCode' })}
                                        type="tel"
                                        maxLength={6}
                                        className={prcValid ? 'vf-otc-txt otp-textbox' : 'otp-textbox vf-otc-txt vdl-validation-error'}
                                        onChange={
                                            (event) => {
                                                WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "OTP" });
                                                return onSetOtpValue(event);
                                            }
                                        }
                                        value={otpCode}
                                    />
                                </div>
                                {showOnScreen && (<div id="verify-prc-access-code" className="vf-access-code">{accessCode}</div>)}
                                <div className="vf-prc-otc-submit-div">  <Button id="prc_otp_continueBtn" disabled={!isOTPFormValid}
                                    style={{ minHeight: "35px", minWidth: "180px" }}
                                    onClick={() => {
                                        ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                                        return setSubmitAccessCode();
                                    }}
                                    className="vf-otc-submit safari-button-align">
                                    {<FormattedMessage id="continueBtn.Label" />}
                                </Button>

                                </div>
                            </form>
                        </div>)}
                    {/*********************ONE  TIME CODE  page end *****************************/}
                    <OTPFooter idVal="prc_otp_resend" btnName="verifyPrc.btn1" footerText="findMe.otpFooterTitle" btnClick={onResendBtnClick} disableResendBtn={(this.state.disableResendBtn || disableResend)} ></OTPFooter>

                    {showModalTitle && (<Modal id="errorModal"
                        className="vdl-modal--alert"
                        show={showModalError}
                    >
                        <ModalHeader closeButton={false} className="modal-header">
                            <ModalTitle className='modal-title-error'>
                                <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                                {showModalTitle}
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody className="modal-body-footercolor">
                            <div>
                                <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
                            </div>
                        </ModalBody>
                        <ModalFooter className="modal-body-footercolor">
                            <Button id="cInfo_okBtn1" onClick={cancelAndCheckYourEntries} className="safari-button-align">
                                {<FormattedMessage id="Lbl_OkButton" />}</Button>
                        </ModalFooter>
                    </Modal>)}

                </div>
            </div>
        )
    }
}
export default injectIntl(PersonalPhoneForm);
