import { handleActions } from 'redux-actions';
import { types } from './verify-pic-actions';
import { ReactGA } from '../../../util';

const {
  GET_PIC_SIV_OPTIONS_SUCCESS,
  GET_PIC_SIV_OPTIONS_FAIL,
  SET_PIC_SIV_FIELD_SUCCESS,
  SET_PIC_SIV_FIELD_FAIL,
  VERIFY_PIC_SIV_OPTIONS_SUCCESS,
  VERIFY_PIC_SIV_OPTIONS_FAIL,
  ENABLE_PIC_SUBMIT_BUTTON,
  CANCEL_PIC_AND_CHECK_YOUR_ENTRIES,
  UPDATE_LOCALE_FIELD_PIC
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  sivOptions: null,
  transactionId: '',
  backgroundImagePath: '',
  firstName: '',
  lastName: '',
  empId:'',
  wfnId: '',
  ssn: '',
  ssn4: '',
  firstNameValid: true,
  lastNameValid: true,
  empIdValid: true,
  wfnIdValid: true,
  ssnValid: true,
  fullDobValid: true,
  ssn4Valid: true,
  errorCode:''
};

export default handleActions(
  {

    [GET_PIC_SIV_OPTIONS_SUCCESS]: (state, { payload }) => {

      let isMobile = payload.isMobile;
      let isMobileBrowser = true;
      let pagePath1 = "/ssr/pic/verifyUserPic";
      let pagePath2 = "/ssr/flowType/pic";
      let pageView1 = pagePath1 + "/web";
      let pageView2 = pagePath2 + "/web";
      if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
      let gaCategory = "WEB_REGISTRATION_VERIFY_PIC";
      if (isMobile) { pageView1 = pagePath1 + "/mobile"; pageView2 = pagePath2 + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_PIC";}
      if (window.innerWidth <= 479 && isMobile == false) { pageView1 = pagePath1 + "/mobilebrowser"; pageView2 = pagePath2 + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_PIC";}

      ReactGA.pageview(pageView1);
      ReactGA.pageview(pageView2);
      
      let sivOptions='';
      let clientName = payload.clientName;
      if(payload.sivOptions){
        sivOptions = payload.sivOptions;
      }else if(payload.regCodeData){
        sivOptions = payload.regCodeData.sivOptions;   
        clientName = payload.regCodeData.companyName;
      }
    return ({ ...state, ...payload, sivOptions, isMobileBrowser, isMobile, gaCategory,clientName})},

    [GET_PIC_SIV_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [SET_PIC_SIV_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_PIC_SIV_FIELD_FAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_PIC_SIV_OPTIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFY_PIC_SIV_OPTIONS_FAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [ENABLE_PIC_SUBMIT_BUTTON]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [CANCEL_PIC_AND_CHECK_YOUR_ENTRIES]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [UPDATE_LOCALE_FIELD_PIC]: (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);
