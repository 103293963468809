import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-user-pin-constants';
import { types } from '../verify-user-pin-actions';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const { VERIFY_USER_PIN, VERIFY_USER_PIN_SUCCESS, VERIFY_USER_PIN_FAIL, CANCEL_REGISTRATION_VERIFY_USERPIN, CHECK_ERROR_MESSAGE } = types;

const verifyUserPinSuccess = createAction(VERIFY_USER_PIN_SUCCESS);
const verifyUserPinFail = createAction(VERIFY_USER_PIN_FAIL);
const cancelRegistrationSent = createAction(CANCEL_REGISTRATION_VERIFY_USERPIN);
const checkUserPinErrMessageSent = createAction(CHECK_ERROR_MESSAGE);

const isBlank = str => (str || '' || 'undefined').trim().length === 0;

const checkUserPinErrMessage = (getState) =>
  checkUserPinErr(getState)
    .pipe(map(response => handleMessageResponse(response, getState)),
    catchError(err => [handleMessageResponse(getErrorMessage(err))]));

const checkUserPinErr = (getState) => {
  if (getState.registration.mobileFlow && getState.registration.message != '' && getState.registration.message != undefined) {
    getState.verifyUserPin.errorTitle = getState.registration.message;
    return of({
      errorTitle: getState.registration.message
    });
  }
  return of({
    errorTitle: ''
  });
}

const handleMessageResponse = (response, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_USER_PIN";
  let pagePath = '/ssr/flowType/verifyUserPin';
  let pageView = pagePath + "/web";

  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_USER_PIN"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_USER_PIN"; }

  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end
  return verifyUserPinFail({
    showFailedModal: false,
    errorTitle: response.errorTitle,
    isMobile,
    isMobileBrowser,
    gaCategory,
    progressBarData
  });
}

//verifyUserPinSuccess
const verifyUserPin = (ajax, userPin, getState) =>
  verifyUserPinApi(ajax, userPin, getState)
    .pipe(map(response => handleResponse(getState, response.response)),
    catchError(err => [handleVerifyUserPinResponseFail(getState, getErrorMessage(err))]));

const verifyUserPinApi = (ajax, userPin, getState) => {
  console.log('registration code latest ==' + userPin);
  if (isBlank(userPin) || userPin == 'undefined' || userPin == undefined) {
    return throwError(new Error(getState.intl.messages['verifyUser.emptyRegCode']));
  }
  return ajax({
    url: constants.getVerifyUserPinEndPoint,
    method: 'POST',
    responseType: 'json',
    body: userPin,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId,
    }
  });
};

const handleResponse = (getState, response) => {
  let gaCategory = getState.verifyUserPin.gaCategory;
  console.log('handleresponse=======>>' + response.viewId);
  if (response.viewId != 'Error') {
    console.log('valid reg code=======>>');
    ReactGA.sendevent(gaCategory, 'VERIFY_USER_PIN', 'SUCCESS');
    // call SIV options and then return the viewId
    return verifyUserPinSuccess({
      viewId: response.viewId
    });
  } else {
    if (response.code != '' && (response.code === constants.EXCEEDED_ATTEMPTS || response.code === constants.ALREADY_REGISTERED)) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_PIN', response.code);
      return verifyUserPinFail({
        showFailedModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: ''
      });
    }
    if (response.code != '' && response.code === constants.INVALID_USERPIN) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_PIN', 'INVALID_USERPIN');
      return verifyUserPinFail({
        showFailedModal: false,
        errorTitle: response.message
      });
    }
    if (response.code != '' && response.code === constants.INVALID_PIN) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_PIN', 'INVALID_PIN');
      return verifyUserPinFail({
        showFailedModal: false,
        errorTitle: response.message
      });
    }
    return verifyUserPinFail({
      showFailedModal: true,
      errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
      welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
      errorTitle: ''
    });
  }
};

const handleVerifyUserPinResponseFail = (getState, errorMessage) => {
  console.log('handle verify userPin response fail response' + errorMessage);
  return verifyUserPinFail({
    showFailedModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    errorTitle: ''
  });
};


{/************************************Cancel Registration*******************************************/ }

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId;
  if (isMobile) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of common.js
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

{/************************************Epics declaration*******************************************/ }

export const verifyUserPinEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_USER_PIN),
  switchMap(action => sendingAsyncRequest(verifyUserPin(ajax, action.payload, state.value))));

export const verifyUserPinSuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_USER_PIN_SUCCESS),
  switchMap(action$ => of(push(state.value.verifyUserPin.viewId))));

export const verifyUserPinFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_USER_PIN_FAIL),
  switchMap(action => of()));

export const cancelRegistrationEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_REGISTRATION_VERIFY_USERPIN),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))));

export const checkRegCodeErrMessageEpic = (action$, state) => action$.pipe(
  ofType(CHECK_ERROR_MESSAGE),
  switchMap(action => sendingAsyncRequest(checkUserPinErrMessage(state.value))));
