import React from 'react';
import AdpIcon from 'adp-react-icons/lib/adp/adp';
import PropTypes from 'prop-types';
import { getLocaleImage } from '../../util/locale/locale-helper';
import { ALIGN_CENTER } from './header-constants';

let defaultBranding = false;

const Header = ({ productLogo, organizationLogo, locale, footer }) => {
  let productLocaleJson = '';

  let productLogoAlignment = '';

  let organizationLocaleJson = '';

  let orgLogoAlignment = '';

  let productLogoImage = '';

  let organizationLogoImage = '';

  defaultBranding = (footer !== undefined && footer.defaultBranding) ? true : false;

  if (productLogo) {
    productLocaleJson = productLogo.i18n || '';
    productLogoImage =
      productLocaleJson === '' ? productLogo.default : getLocaleImage(productLocaleJson, locale);
    productLogoAlignment = productLogo.alignment ? productLogo.alignment : 'right';
  }
  if (organizationLogo) {
    organizationLocaleJson = organizationLogo.i18n || '';
    organizationLogoImage =
      organizationLocaleJson === '' ?
        organizationLogo.default :
        getLocaleImage(organizationLocaleJson, locale);
    orgLogoAlignment = organizationLogo.alignment ? organizationLogo.alignment : 'left';
  }
  return (
    <header className="vdl-container-fluid vdl-hidden-xs-ms">
      <div className="vdl-row-fluid site-logo">
        <div className="vdl-col-md-12">
          <h1 className ="header-content">
            {
              //  hidden will be removed once api is ready...
            }
            <span hidden>
              <AdpIcon />
            </span>
            {productLogoAlignment === ALIGN_CENTER ? (
              <span className="float_center">
                <ImageLogo
                  id="productLogo1"
                  logo={productLogoImage}
                  name="productLogo"
                />
              </span>
            ) : (
                <span style={{ float: productLogoAlignment }}>
                  <ImageLogo
                    id="productLogo2"
                    logo={productLogoImage}
                    name="productLogo"
                  />
                </span>
              )}
            {orgLogoAlignment === ALIGN_CENTER ? (
              <span className="float_center">
                <ImageLogo
                  id="organizationLogo1"
                  logo={organizationLogoImage}
                  name="organizationLogo"
                />
              </span>
            ) : (
                <span style={{ float: orgLogoAlignment }}>
                  <ImageLogo
                    id="organizationLogo2"
                    logo={organizationLogoImage}
                    name="organizationLogo"
                  />
                </span>
              )}
            <AdpIcon id="adpIcon" style={{ display: 'none', color: '#d40f0f', float: 'right', fontSize: '32px' }} />
          </h1>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  productLogo: PropTypes.shape({
    i18n: PropTypes.object,
  }),
  organizationLogo: PropTypes.shape({
    i18n: PropTypes.object,
  }),
  locale: PropTypes.string,
};

export const ImageLogo = (logo) => {
  if (logo.logo) {
    return (
      <img
        src={logo.logo}
        height="32"
        max-width="100%"
        id={logo.id}
        alt={logo.name}
        onError={() => {
          if (defaultBranding && (logo.id == 'productLogo1' || logo.id == 'productLogo2')) {
            document.getElementById("adpIcon").style.display = 'block';
          }
          document.getElementById(logo.id).style.display = 'none';
        }}
      />
    );
  }
  return <span />;
};

export default Header;
