import React from 'react';
import PropTypes from 'prop-types';
import FaLock from 'adp-react-icons/lib/fa/lock'
import { Button,Popover, OverlayTrigger } from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { ReactGA } from '../../util';
class SecurePage extends React.Component {

  render() {

    const handleClick = () => {
      ReactGA.sendevent(this.props.gaCategory, "FA_LOCK", 'CLICK');
    }
    const handleOverlayClick = () =>
      (<Popover
        id="info-popover"
        placement="left"
        className={'vdl-alert--default-style vdl-alert--sm'}
      >
       <div role="alert" aria-label="tooltip">{<FormattedMessage id="securePage.info" />}</div>
      </Popover>);


    return (
      <OverlayTrigger
        trigger={['click','focus']} placement="right"
        isAriaExpanded={ false }
        overlay={handleOverlayClick()}
      >
        <div id="secure_page"  className="focus-selector" style={{
          cursor: "pointer", marginBottom: '-24px'
        }}>
         <Button role="button" 
          buttonStyle="link"
          variant="text"
          aria-label={this.props.intl.formatMessage({ id: 'securePage.label' })}
          style={ { color: 'currentcolor', left: '10px' } }
          onClick={handleClick}
        >
         <FaLock             
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
            style={{
              verticalAlign: 'baseline',
              fontSize: "8px", color: '#9b9b9b', marginBottom: "1px"
            }}
          ></FaLock></Button>
        </div>
      </OverlayTrigger>

    );
  }
}

export default injectIntl(SecurePage);

