export const constants = {
  getSivOptionsDetailsEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/sivOptions',
  sendOrcSivOptionsDetailsEndPoint:'/pages/sms/ess/v3/ws/rest/pub/ssr/verifyIdentity',
  redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
  sivOptionIds: {
    siv_firstName: 0,
    siv_lastName: 1,
    siv_ssn: 2,
    siv_dob_month: 3,
    siv_dob_day: 4,
    siv_dob_year: 5, 
    siv_empid: 6,  
    siv_dob_full_month: 7,
    siv_dob_full_day: 8,
    siv_dob_full_year: 9, 
    siv_ssn4: 10,
    siv_zipcode: 11,
    siv_wfnid: 12 
  },
  EXCEEDED_ATTEMPTS:'EXCEEDED_ATTEMPTS',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
  REDIRECT_FORGOT:'/pages/sms/ess/v3/pub/ssr/redirectToForgotFlow.jsp',
  FUNCTION_LOCKED:'FUNCTION_LOCKED',
  RECAPTCHA_FAILED:'RECAPTCHA_FAILED',
  HAS_BEVPRODUCT:'HAS_BEVPRODUCT',
  EMAIL_NOT_EXISTS:'EMAIL_NOT_EXISTS',
  NO_SIV_DEFINED:'NO_SIV_DEFINED',
  ALREADY_REGISTERED_MULTI:'ALREADY_REGISTERED_MULTI',
  ALREADY_REGISTERED_SUSPENDED: 'ALREADY_REGISTERED_SUSPENDED',
  PATHVERIFYCPC: '/ssr/CPC/verifySIVData',
  PATHCPCFLOW: '/ssr/flowType/CPC',
  FINDME_VIEW: '/findMeHelpUsFindYou',
  FINDME_VIEWID: '/findMe',
  ERROR:'Error',
  EMPID: 'empId',
  WFNID: 'wfnId',
  SSN: 'ssn',
  SSN4: 'ssn4',
  RADIO:'radio',
  monthWith31days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  monthWith30days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
  monthWith29days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"]
};