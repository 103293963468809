import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'CONFIRMATION_INFO_OPTIONS',
  'CONFIRMATION_INFO_OPTIONS_SUCCESS',
  'CONFIRMATION_INFO_OPTIONS_FAIL',
  'CONFIRMATION_INFO_OPTIONS_UPDATE',
  'CONFIRMATION_GO_TO_PRODUCT'
);

const {
  CONFIRMATION_INFO_OPTIONS,
  CONFIRMATION_INFO_OPTIONS_UPDATE,
  CONFIRMATION_GO_TO_PRODUCT

} = types;

const getConfirmationInfoSent = createAction(CONFIRMATION_INFO_OPTIONS);
const getConfirmationInfoUpdateSent = createAction(CONFIRMATION_INFO_OPTIONS_UPDATE);
const goToProductSent = createAction(CONFIRMATION_GO_TO_PRODUCT);


const getConfirmationInfo = () => getConfirmationInfoSent();
const getConfirmationInfoUpdate = (isCopied) => getConfirmationInfoUpdateSent({ isCopied });
const goToProduct = (link) => goToProductSent(link);

export const actions = {
  getConfirmationInfo,
  getConfirmationInfoUpdate,
  goToProduct

};
