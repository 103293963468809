import React, { Component } from 'react';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import MobileIcon from 'adp-react-icons/lib/fa/mobile';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
class FindMeMobileForm extends Component {

  componentWillMount() {
    //Need to update flow type CPC/PIC
    this.props.getFindMeMobileInfo();
    WindowUtil.setlayoutback(false);
  }

  render() {
    const {
      findMeMobileObj,
      gaCategory,
      isMobile
    } = this.props;




    return (
      findMeMobileObj.showDiv && (

        <div >

          <div className="registration-content" >
            <div className="registration-form-container" >
            {(window.innerWidth > 479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
            }
            {
              (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
            }
            </div>
            {(!isMobile) && <hr className="header-hr" />}
            <div className="vdl-row registration-form fmm-content-div">
              <div className="welcome-title title-width ev-margin-h">
                <div className="vdl-col-md-12">

                  <div>
                    <MobileIcon className="cs-mobile-icon" />

                    <h1 id="registration-layout_welcome" className="page-title fmm-page-title-h1" >{<FormattedMessage id="findMeMobile.title" />}</h1>
                    <div className="heading-text fmm-page-msgs-div">
                      <div id="info-text-1" className="cf-vdl-font-size fmm-page-msg" >{<FormattedMessage id="findMeMobile.message1" />}</div>
                      <div id="info-text-2" className="cf-vdl-font-size fmm-page-msg" >{<FormattedMessage id="findMeMobile.message2" />}</div>
                      <div id="info-text-2" className="cf-vdl-font-size fmm-page-msg" >{findMeMobileObj.contactAdmin}</div>
                      <div id="info-text-2" className="cf-vdl-font-size fmm-page-msg-transid">{findMeMobileObj.transId}</div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div className="registration-content cs-margin-top cs-background-white fmm-page-footer-content-div" >
            <div className="vdl-row welcome-title">
              <div className="vdl-col-md-12">
                <div id="confirmation-info-5" className="heading-text cs-label fmm-page-footer-content-label" > {<FormattedMessage id="findMeMobile.info5" />}</div>
              </div>
            </div>
            <div className="fmm-page-footer-imgs-div">

              <img role="link" id="cs-apple-badget2" onClick={() => {
                ReactGA.sendevent(gaCategory, 'LINK', "APPLE");
                return WindowUtil.openNewWindow("https://itunes.apple.com/us/app/adp-mobile-solutions/id444553167?mt=8")
              }}
                src={findMeMobileObj.srcApple}
                onError={(e) => e.target.src = "images/Badges/apple_en.png"}
                className="cs-apple-badget" />
              <img role="link" id="cs-andriod-badget2" onClick={() => {
                ReactGA.sendevent(gaCategory, 'LINK', "ANDRIOD");
                return WindowUtil.openNewWindow("https://play.google.com/store/apps/details?id=com.adpmobile.android")
              }}
                src={findMeMobileObj.srcAndriod}
                onError={(e) => e.target.src = "images/Badges/andriod_en.png"}
                className="cs-andriod-badget" />
            </div>
          </div>

        </div>
      )
    );
  }
}
export default injectIntl(FindMeMobileForm);