import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'EXTERNAL_VENDOR_INFO_OPTIONS',
  'EXTERNAL_VENDOR_INFO_OPTIONS_SUCCESS',
  'EXTERNAL_VENDOR_INFO_OPTIONS_FAIL',
  'CANCEL_VENDOR_AND_CHECK_YOUR_ENTRIES',
  'SET_MANUAL_SELECTED',
  'SET_MANUAL_SELECTED_SUCCESS',
  'SET_EXTERNAL_VENDOR_REDIRECT',
  'OPEN_EXTERNAL_VENDOR_REDIRECT'


);

const {
  EXTERNAL_VENDOR_INFO_OPTIONS,
  CANCEL_VENDOR_AND_CHECK_YOUR_ENTRIES,
  SET_MANUAL_SELECTED,
  SET_EXTERNAL_VENDOR_REDIRECT,
  OPEN_EXTERNAL_VENDOR_REDIRECT


} = types;

const getExternalVendorInfoSent = createAction(EXTERNAL_VENDOR_INFO_OPTIONS);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_VENDOR_AND_CHECK_YOUR_ENTRIES);
const setmanualSelectedSent = createAction(SET_MANUAL_SELECTED);
const setRedirectSent = createAction(SET_EXTERNAL_VENDOR_REDIRECT);
const openExternalVendorSent = createAction(OPEN_EXTERNAL_VENDOR_REDIRECT);

const getExternalVendorInfo = () => getExternalVendorInfoSent();
const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();
const setmanualSelected = () => setmanualSelectedSent();
const setRedirect = () => setRedirectSent();
const openExternalVendor = () => openExternalVendorSent();

export const actions = {
  getExternalVendorInfo,
  cancelAndCheckYourEntries,
  setmanualSelected,
  setRedirect,
  openExternalVendor

};
