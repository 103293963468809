import { equals } from 'ramda';
import {
  minOneUpperCaseLetter,
  minOneLowerCaseLetter,
  minOneNumber,
  minEightChars,
  minimumRequirements,
  mixedCaseLetters,
  minTwelveChars,
  minOneSpecialCharacter,
  checkforErrors,
} from './password-strength-rules';
import {
  greenTextColor,
  greenBarColor,
  orangeBarColor,
  infoPromptsTextColor,
  redTextColor,
  redBarColor,
} from './indicator-constants';

const hasErrors = password => checkforErrors(password).length > 0;

export const strongest = (password) => {
  const rulesTrue = [
    minimumRequirements,
    mixedCaseLetters,
    minTwelveChars,
    minOneSpecialCharacter,
    minOneNumber,
  ];
  const rulesFalse = [hasErrors];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'strongest',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const veryStrongAddUpperCase = (password) => {
  const rulesTrue = [minimumRequirements, minTwelveChars, minOneSpecialCharacter, minOneNumber];
  const rulesFalse = [hasErrors, mixedCaseLetters, minOneUpperCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'veryStrong',
      strengthTextInfoPromptsVal: 'veryStrong_UC',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const veryStrongAddLowerCase = (password) => {
  const rulesTrue = [minimumRequirements, minTwelveChars, minOneSpecialCharacter, minOneNumber];
  const rulesFalse = [hasErrors, mixedCaseLetters, minOneLowerCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'veryStrong',
      strengthTextInfoPromptsVal: 'veryStrong_LC',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const veryStrongAddSpecialChar = (password) => {
  const rulesTrue = [minimumRequirements, mixedCaseLetters, minTwelveChars, minOneNumber];
  const rulesFalse = [hasErrors, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'veryStrong',
      strengthTextInfoPromptsVal: 'veryStrong_SC',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const veryStrongAddNumber = (password) => {
  const rulesTrue = [minimumRequirements, mixedCaseLetters, minOneSpecialCharacter, minTwelveChars];
  const rulesFalse = [hasErrors, minOneNumber];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'veryStrong',
      strengthTextInfoPromptsVal: 'veryStrong_Number',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const veryStrongAddMoreThan12Chars = (password) => {
  const rulesTrue = [minimumRequirements, mixedCaseLetters, minOneSpecialCharacter, minOneNumber];
  const rulesFalse = [hasErrors, minTwelveChars];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'veryStrong',
      strengthTextInfoPromptsVal: 'veryStrong_12',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const strongAddUpperCase = (password) => {
  const rulesTrue = [minimumRequirements, minOneSpecialCharacter, minOneNumber];
  const rulesFalse = [hasErrors, mixedCaseLetters, minTwelveChars, minOneUpperCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'strong',
      strengthTextInfoPromptsVal: 'strong_UC',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const strongAddLowerCase = (password) => {
  const rulesTrue = [minimumRequirements, minOneSpecialCharacter, minOneNumber];
  const rulesFalse = [hasErrors, mixedCaseLetters, minTwelveChars, minOneLowerCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'strong',
      strengthTextInfoPromptsVal: 'strong_LC',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const strongAddSpecialChar = (password) => {
  const rulesTrue = [minimumRequirements, mixedCaseLetters, minOneNumber];
  const rulesFalse = [hasErrors, minTwelveChars, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'strong',
      strengthTextInfoPromptsVal: 'strong_SC',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const strongAddNumber = (password) => {
  const rulesTrue = [minimumRequirements, mixedCaseLetters, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minTwelveChars, minOneNumber];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'strong',
      strengthTextInfoPromptsVal: 'strong_Number',
      strengthTextColorVal: greenTextColor,
      strengthBarColorVal: greenBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneLowerOneNumberOneSpecial = (password) => {
  const rulesTrue = [];
  const rulesFalse = [
    hasErrors,
    minEightChars,
    minOneNumber,
    minOneLowerCaseLetter,
    minOneUpperCaseLetter,
    minOneSpecialCharacter,
  ];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Upper_1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneLowerOneSpecial = (password) => {
  const rulesTrue = [minOneNumber];
  const rulesFalse = [
    hasErrors,
    minEightChars,
    minOneLowerCaseLetter,
    minOneUpperCaseLetter,
    minOneSpecialCharacter,
  ];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1Upper_1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneNumberOneSpecial = (password) => {
  const rulesTrue = [minOneLowerCaseLetter];
  const rulesFalse = [
    hasErrors,
    minEightChars,
    minOneNumber,
    minOneUpperCaseLetter,
    minOneSpecialCharacter,
  ];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Upper_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneLowerOneNumberOneSpecial = (password) => {
  const rulesTrue = [minOneUpperCaseLetter];
  const rulesFalse = [
    hasErrors,
    minEightChars,
    minOneNumber,
    minOneLowerCaseLetter,
    minOneSpecialCharacter,
  ];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneLowerOneNumber = (password) => {
  const rulesTrue = [minOneSpecialCharacter];
  const rulesFalse = [
    hasErrors,
    minEightChars,
    minOneNumber,
    minOneLowerCaseLetter,
    minOneUpperCaseLetter,
  ];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Upper_1Lower',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneLowerOneNumberOneSpeical = (password) => {
  const rulesTrue = [minEightChars, minOneUpperCaseLetter];
  const rulesFalse = [hasErrors, minOneNumber, minOneLowerCaseLetter, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1number_1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneUpperOneNumberOneSpeical = (password) => {
  const rulesTrue = [minEightChars, minOneLowerCaseLetter];
  const rulesFalse = [hasErrors, minOneNumber, minOneUpperCaseLetter, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1number_1Upper_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneUpperOneLowerOneSpecial = (password) => {
  const rulesTrue = [minEightChars, minOneNumber];
  const rulesFalse = [
    hasErrors,
    minOneLowerCaseLetter,
    minOneUpperCaseLetter,
    minOneSpecialCharacter,
  ];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1Upper_1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneUpperOneLowerOneNumber = (password) => {
  const rulesTrue = [minEightChars, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minOneNumber, minOneLowerCaseLetter, minOneUpperCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1number_1Upper_1Lower',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneNumberOneSpecial = (password) => {
  const rulesTrue = [minOneLowerCaseLetter, minOneUpperCaseLetter];
  const rulesFalse = [hasErrors, minEightChars, minOneNumber, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneLowerOneSpecial = (password) => {
  const rulesTrue = [minOneNumber, minOneUpperCaseLetter];
  const rulesFalse = [hasErrors, minEightChars, minOneLowerCaseLetter, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneLowerOneNumber = (password) => {
  const rulesTrue = [minOneUpperCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minEightChars, minOneNumber, minOneLowerCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Lower',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneSpecial = (password) => {
  const rulesTrue = [minOneNumber, minOneLowerCaseLetter];
  const rulesFalse = [hasErrors, minEightChars, minOneUpperCaseLetter, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1Upper_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneLower = (password) => {
  const rulesTrue = [minOneNumber, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minEightChars, minOneUpperCaseLetter, minOneLowerCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1Upper_1Lower',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharsOneUpperOneNumber = (password) => {
  const rulesTrue = [minOneLowerCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minEightChars, minOneNumber, minOneUpperCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_1number_1Upper',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneNumberOrOneSpecial = (password) => {
  const rulesTrue = [minEightChars, minOneLowerCaseLetter, minOneUpperCaseLetter];
  const rulesFalse = [hasErrors, minOneNumber, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1number_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneLowerOrOneSpecial = (password) => {
  const rulesTrue = [minEightChars, minOneNumber, minOneUpperCaseLetter];
  const rulesFalse = [hasErrors, minOneLowerCaseLetter, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1Lower_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneLowerOrOneNumber = (password) => {
  const rulesTrue = [minEightChars, minOneUpperCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minOneNumber, minOneLowerCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1number_1Lower',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumOneUpperorOneSpecial = (password) => {
  const rulesTrue = [minEightChars, minOneNumber, minOneLowerCaseLetter];
  const rulesFalse = [hasErrors, minOneUpperCaseLetter, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1Upper_1Special',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const miniumOneUpperorOneLower = (password) => {
  const rulesTrue = [minEightChars, minOneNumber, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minOneLowerCaseLetter, minOneUpperCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1Upper_1Lower',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharactersOneNumber = (password) => {
  const rulesTrue = [minOneLowerCaseLetter, minOneUpperCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minOneNumber, minEightChars];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char_number',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: redTextColor,
      strengthBarColorVal: redBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharactersOneSpecial = (password) => {
  const rulesTrue = [minOneNumber, minOneLowerCaseLetter, minOneUpperCaseLetter];
  const rulesFalse = [hasErrors, minEightChars, minOneSpecialCharacter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharactersOneUpper = (password) => {
  const rulesTrue = [minOneNumber, minOneLowerCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minOneUpperCaseLetter, minEightChars];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharactersOneLower = (password) => {
  const rulesTrue = [minOneNumber, minOneUpperCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minEightChars, minOneLowerCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minimumEightCharacters = (password) => {
  const rulesTrue = [
    minOneNumber,
    minOneUpperCaseLetter,
    minOneLowerCaseLetter,
    minOneSpecialCharacter,
  ];
  const rulesFalse = [hasErrors, minEightChars];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min8char',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const minOneNumberOrOneUpper = (password) => {
  const rulesTrue = [minEightChars, minOneLowerCaseLetter, minOneSpecialCharacter];
  const rulesFalse = [hasErrors, minOneNumber, minOneUpperCaseLetter];

  return checkRules(password, rulesTrue, rulesFalse) ?
    {
      strengthTextVal: 'min1number_1Upper',
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: infoPromptsTextColor,
      strengthBarColorVal: orangeBarColor,
      strengthWidthVal: calculateStrength(password),
    } :
    {};
};

export const validatePassword = (password) => {
  if (checkforErrors(password).length > 0) {
    return {
      strengthTextVal: checkforErrors(password)[0],
      strengthTextInfoPromptsVal: 'nothing',
      strengthTextColorVal: redTextColor,
      strengthBarColorVal: redBarColor,
      strengthWidthVal: calculateStrength(password),
      hasError: true,
    };
  }

  const rules = [
    strongest,
    veryStrongAddMoreThan12Chars,
    veryStrongAddSpecialChar,
    veryStrongAddNumber,
    veryStrongAddLowerCase,
    veryStrongAddUpperCase,
    strongAddLowerCase,
    strongAddUpperCase,
    strongAddNumber,
    strongAddSpecialChar,
    minOneNumberOrOneUpper,
    minimumEightCharactersOneLower,
    minimumEightCharactersOneUpper,
    minimumEightCharactersOneNumber,
    minimumEightCharactersOneSpecial,
    minimumEightCharacters,
    miniumOneUpperorOneLower,
    minimumOneUpperorOneSpecial,
    minimumOneLowerOrOneNumber,
    minimumOneLowerOrOneSpecial,
    minimumOneNumberOrOneSpecial,
    minimumEightCharsOneUpperOneNumber,
    minimumEightCharsOneUpperOneSpecial,
    minimumEightCharsOneUpperOneLower,
    minimumEightCharsOneLowerOneNumber,
    minimumEightCharsOneLowerOneSpecial,
    minimumEightCharsOneNumberOneSpecial,
    minimumOneUpperOneLowerOneNumber,
    minimumOneUpperOneLowerOneSpecial,
    minimumOneUpperOneNumberOneSpeical,
    minimumOneLowerOneNumberOneSpeical,
    minimumEightCharsOneUpperOneLowerOneNumber,
    minimumEightCharsOneUpperOneLowerOneSpecial,
    minimumEightCharsOneUpperOneNumberOneSpecial,
    minimumEightCharsOneLowerOneNumberOneSpecial,
    minimumEightCharsOneUpperOneLowerOneNumberOneSpecial,
  ];

  let result;

  for (let i = 0; i < rules.length; i++) {
    result = rules[i](password);

    if (!equals(result, {})) {
      break;
    }
  }

  return result;
};

export const checkRules = (password, rulesTrue, rulesFalse) => {
  // every rule should be true but found false
  const trueRulesCheckFailed = rulesTrue.map(rule => rule(password)).includes(false);
  // every rule should be false but found true
  const falseRulesCheckFailed = rulesFalse.map(rule => rule(password)).includes(true);

  return !trueRulesCheckFailed && !falseRulesCheckFailed;
};

export const calculateStrength = (password) => {
  let strengthWidth = password.length * 5;

  let strengthWidthSpecialVal = 0;

  if (minOneUpperCaseLetter(password)) {
    strengthWidthSpecialVal += 5;
  }
  if (minOneLowerCaseLetter(password)) {
    strengthWidthSpecialVal += 5;
  }
  if (minOneNumber(password)) {
    strengthWidthSpecialVal += 10;
  }
  if (minOneSpecialCharacter(password)) {
    strengthWidthSpecialVal += 20;
  }

  strengthWidth = strengthWidth >= 60 ? 60 : strengthWidth;
  strengthWidthSpecialVal = strengthWidthSpecialVal >= 40 ? 40 : strengthWidthSpecialVal;
  return strengthWidth + strengthWidthSpecialVal;
};
