import Locale from 'locale';
import Cookies from 'js-cookie';
import {
  ADP_LANG_LOCALE_COOKIE,
  ADP_LANG_LOCALE_COOKIE_EXPIRES,
  DEFAULT_LOCALE,
  createIntlPayload,
} from '.';

const SUPPORTED_LOCALES = [
  'de_DE',
  'en_CA',
  'en_GB',
  'en_US',
  'es_ES',
  'es_US',
  'fr_CA',
  'fr_FR',
  'hu_HU',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'nl_NL',
  'pl_PL',
  'pt_BR',
  'pt_PT',
  'ru_RU',
  'sk_SK',
  'zh_CN',
  'zh_TW',
  'cs_CZ'
];
const supported = new Locale.Locales(SUPPORTED_LOCALES, DEFAULT_LOCALE);
const DOMAIN = '.adp.com';
const PATH = './';

export const getBestFitLocale = locale => new Locale.Locales(locale).best(supported).toString();
export const getLanguageCookie = () => Cookies.get(ADP_LANG_LOCALE_COOKIE);
export const setLanguageCookie = (locale) => {
  Cookies.set(ADP_LANG_LOCALE_COOKIE, locale, {
    expires: ADP_LANG_LOCALE_COOKIE_EXPIRES,
    domain: DOMAIN,
    path: PATH,
  });
};

export const getLocaleInitialState = (locale) => {
  
  if (locale != undefined && locale != null)
    locale = locale.replace("-", "_");

  const bestFitLocale = getBestFitLocale(locale || getLanguageCookie());

  console.log('getLocaleInitialState', bestFitLocale);

  return {
    locale: {
      locale: bestFitLocale,
    },
    intl: {
      ...createIntlPayload(bestFitLocale),
    },
  };
};

export const getLocaleObjFromPropKey = (propElement, locale) => {
  const resultObj = Object.keys(propElement).filter(key => key === locale);
  const resultWithDefault =
    propElement[resultObj] === undefined ? propElement.default : propElement[resultObj];

  return resultWithDefault;
};

export const getLocaleImage = (i18nJson, locale) => i18nJson[locale];
