import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import CountryCodeDropdown from '../../components/country-code';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-prc/verify-prc-constants';
import EnvelopeIcon from 'adp-react-icons/lib/fa/envelope';
import PostalIcon from 'adp-react-icons/lib/fa/envelope-o';
import MobileIcon from 'adp-react-icons/lib/fa/mobile';
import NewMobileIcon from 'adp-react-icons/lib/fa/plus-circle';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import Comments from 'adp-react-icons/lib/fa/comments';
import ReactDOM from 'react-dom';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import AdpIcon from 'adp-react-icons/lib/adp/adp';
import OTPFooter from './OTPFooter';

const isBlank = str => (str || '').trim().length === 0;
const numDigits = (x) => (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
let gaCategoryPhoneOnFly = "WEB_REGISTRATION_NEW_PHONEONFLY";

const allowEnterKeyFields = ["phoneFly_textBox","prcTextBox","prc_exist_textBox"];

class VerifyPrcForm extends Component {

  constructor(props){
    super(props);
    if(!WindowUtil.getlayoutback()){
     this.state={disableResendBtn:false, showNudgeFooter:true};
    }
    else{
      this.state={disableResendBtn:WindowUtil.isresendEnabled(), showNudgeFooter:true};
    }
  }

  componentWillMount() {
    if(!WindowUtil.getlayoutback()){
    this.props.getVerfiyPicInfo();
  }
  WindowUtil.setlayoutback(false);
  }
  _handleKeyPress = (e, valid, page) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if(e.target !== undefined && e.target.id!==undefined){
        if(allowEnterKeyFields.indexOf(e.target.id)!="-1"){
          if (valid) {
            if(page === 'POF' && !isBlank(this.props.mnumber)){
              ReactGA.sendevent(this.props.gaCategory, 'PHONEONFLY', 'PHONE_ENTERED');
              this.props.verifyPhoneOnFly();
            }
            else{
            ReactGA.sendevent(this.props.gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
            this.props.setSubmitAccessCode();
          }
         }
        }
      }
    }
  }

  componentDidMount = () => {
    
    if (this.props.isMobile)  gaCategoryPhoneOnFly = "MOBILE_REGISTRATION_NEW_PHONEONFLY"; 
    if (window.innerWidth <= 479 && this.props.isMobile == false) gaCategoryPhoneOnFly = "MOBILEBROSWER_REGISTRATION_NEW_PHONEONFLY";
  }
  componentWillReceiveProps = (nextProps) => {

   
    if (nextProps.sendRedirect)
      nextProps.setRedirect();
    this.checkFormReadyness(nextProps);
  }
  checkFormReadyness = (nextProps) => {
    let formValid = true;
    if ((nextProps.showVerify == false || nextProps.prcAlreadyExists == true) && isBlank(nextProps.otcCode))
      formValid = false;
    this.props.enableSubmitButton(formValid);
  }

  render() {
    const {
      skipNudgeActivation,
      editContactInfo,
      resendOTP,
      verifyPrcObj,
      title,
      intl,
      showPhoneOnFly,
      showBusyIndicator,
      cancelAndCheckYourEntries,
      setIdentitySelected,
      setDifferentMethod,
      showVerify,
      setSubmitAccessCode,
      isFormValid,
      setCancelModal,
      showModalError,
      otcCode,
      setOtcValue,
      showOtcModal,
      goToKBASent,
      prcAlreadyExists,
      goToPostal,
      showModalInfo,
      showModalWarn,
      setWarnModal,
      isMobileBrowser,
      isMobile,
      gaCategory,
      progressBarData,
      selectedPhone,
      onSetMobileType,
      mobilePhoneObj,
      mobilePhoneValid,
      onSetMobilePhoneValue,
      countryCode,
      mnumber,
      setPhoneOnFly,
      backToOptionsPage,
      verifyPhoneOnFly,
      phoneOnFlyFail,
      phoneOnFlyAlert,
      hideText,
      pofBackEnabled,
      phoneReEntryError,
    } = this.props;


    const tooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );
    
    const editContactInfoPage = () =>{ 
      WindowUtil.setlayoutback(true);
      ReactGA.sendevent(gaCategory, 'EDIT_CONTACT_INFO', 'CLICK');
      editContactInfo();
    }

    const skipNudge = () =>{
      skipNudgeActivation();
    }

    const onResendBtnClick = () =>{
      this.setState({ disableResendBtn: true });
      WindowUtil.setresend(true);
      ReactGA.sendevent(gaCategory, 'RESEND_OTP', 'CLICK');
      resendOTP();
    }

    const onSetMobilePhone = mPhone =>{ 
       WindowUtil.setFirstAttemptGA({ gaCategory: gaCategoryPhoneOnFly, fieldName: "PHONEONFLY" });
      ReactGA.sendevent(gaCategoryPhoneOnFly, 'PHONEONFLY',mPhone.selectedCountry.iso2)
      return onSetMobilePhoneValue(mPhone);}

    const identitySelected = (index, disabled) => {
      if (disabled)
        return;
      this.setState({ disableResendBtn: false });
      WindowUtil.setresend(false);
      if(verifyPrcObj.showNudgePage === true){
        this.setState({showNudgeFooter:false});
      }
      return setIdentitySelected(index);
    }

    const phoneOnFlySelected = (disabled) => {
      if (disabled)
        return;
      this.setState({ disableResendBtn: false });
      WindowUtil.setresend(false);
      return setPhoneOnFly();
    }

    const onClickDifferentMethod = () => {
      ReactGA.sendevent(gaCategory, 'SELECT_DIFFERENT_METHOD', 'YES');
      let showVerify = true;
      let prcAlreadyExists = false;
      let showModalWarn = false;
      let title = constants.VERIFYPRC_TITLE;
      let phoneOnFlyFail = false;
      this.setState({showNudgeFooter:true});
      return setDifferentMethod(showVerify, title, prcAlreadyExists, showModalWarn, phoneOnFlyFail);
    }
    const submitAccessCode = () => setSubmitAccessCode();



    const onCancelModal = () => {
      let showModalError = false;
      return setCancelModal(showModalError);
    }

    const handleWarnModal = (value) => {
      if (value == false) ReactGA.sendevent(gaCategory, 'SELECT_DIFFERENT_METHOD', 'NO');
      let showModalWarn = value;
      return setWarnModal(showModalWarn);

    }
    const goToKBA = () => goToKBASent();



    const onSetOtcValue = (event) => {
      const inputValue = event.target.value.replace(/\D/g, '');

      if(this.props.otcCode === inputValue){
        return;
      }
      const otcCode = inputValue;

      setOtcValue(otcCode);
    }

    const onSetPrcValue = (value) => {
      let otcCode = value;
      setOtcValue(otcCode);
    }

    return (
      verifyPrcObj.showDiv && (<div className="registration-content">
        <div className="registration-form-container" >
             { 
              (window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
               }
              {
               (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
               }
          {((isMobileBrowser && !isMobile && ((prcAlreadyExists) || (showVerify && !prcAlreadyExists) || (!showVerify && !prcAlreadyExists))) || window.innerWidth > 479) && (

            <div tabIndex = "0" className="vf-cancel-icon focus-selector" onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'CANCEL');
                return cancelAndCheckYourEntries();
              }
            }}>
              <svg id="prc_cancel" role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
              onClick={() => {
                ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'CANCEL');
                return cancelAndCheckYourEntries();
              }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor" style={{
                cursor: "pointer",
                float: "right",
                fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
              }}><g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
              </svg>
            </div>
          )}
        </div>
        {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
          <div className="registration-progress-bar">
            <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
          </div>
        )}
        {(!isMobile) && <hr className="header-hr" />}
        <div >

          <div className="vdl-row registration-form">
            <div className="welcome-title title-width vf-margin-auto">
              {(verifyPrcObj.showNudgePage == true) && (this.state.showNudgeFooter == true) && (
                  <div className="vdl-col-md-12">
                    <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.nudgetitle" values={{ 0: verifyPrcObj.firstName }} />}</h1>
                  </div>)}
              {(verifyPrcObj.showNudgePage == false || this.state.showNudgeFooter == false) &&
                <div>
                  {(title == constants.VERIFYPRC_TITLE) && (verifyPrcObj.multiOrg == false) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.title" values={{ 0: verifyPrcObj.firstName }} />}</h1>
                    </div>)}
                  {(title == constants.VERIFYPRC_TITLE) && (verifyPrcObj.multiOrg == true) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.multiOrgtitle" />}</h1>
                    </div>)}
                  {(title == constants.OTP_HEADING) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="otp.title" />}</h1>
                    </div>)}
                    {(title == constants.OTP_HEADING_ALREADY_PRC_EXIST) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.otcHeading" />}</h1>
                    </div>)}
                    {(title == constants.POF_OTP_HEADING) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.phoneOnFlyOtpTitle" />}</h1>
                    </div>)}
                  {(title == constants.PHONEONTHEFLY_TITLE) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.phoneOnFlyTitle" />}</h1>
                    </div>)}
                  {(title == constants.PHONEONTHEFLYFAIL_TITLE) && (
                    <div className="vdl-col-md-12">
                      <h1 id="verify-prc-layout" className="page-title vf-title-label">{<FormattedMessage id="verifyPrc.phoneOnFlyFailTitle" />}</h1>
                    </div>)}
                  </div>
              }
            </div>
            {/*********************Choose identity page start *****************************/}
            {showVerify && (prcAlreadyExists == false) && (<div id="verify-prc-form-1" className="vf-form-padding" >

              { verifyPrcObj.showNudgePage ==false && <div>
              {(verifyPrcObj.findMeFlow || verifyPrcObj.mykplanFlow) && (verifyPrcObj.multiOrg == false) && (
                <div>
                  {(phoneOnFlyFail) && (<div className="pof-error"><span className="warn-icon-margin"> <WarnIcon style={{ marginBottom: "2px" }} className="vdl-modal__title-icon warn-icon-info_pof" /> </span> <span id="verify-prc-heading" className="vf-heading vf-marginbottom-0">{<FormattedMessage id="verifyPrcFindMe.PhoneOnFlyFail" values={{ 0: <b>{verifyPrcObj.enteredNumber}</b> }} />} </span></div>)}
                  {(!phoneOnFlyFail) && (<div id="verify-prc-heading-1" className="vf-heading vf-marginbottom-0">{<FormattedMessage id="verifyPrcFindMe.heading" values={{ 0: <b>{verifyPrcObj.clientName}</b> }} />} </div>)}
                  {(phoneOnFlyFail  && verifyPrcObj.disableFields.length === 1 ) && (<div id="verify-prc-heading-2" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading1" />}</div>)}
                  {(phoneOnFlyFail  && verifyPrcObj.disableFields.length > 1 ) && (<div id="verify-prc-heading-3" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading" />}</div>)}
                  {(!phoneOnFlyFail) && (<div id="verify-prc-heading-4" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading" />}</div>)}
                  
                </div>
              )
              }

              {(verifyPrcObj.findMeFlow || verifyPrcObj.mykplanFlow) && (verifyPrcObj.multiOrg == true) && (
                <div>
                  {(phoneOnFlyFail) && (<div className="pof-error"><span className="warn-icon-margin"> <WarnIcon style={{ marginBottom: "2px" }} className="vdl-modal__title-icon warn-icon-info_pof" /> </span> <span id="verify-prc-heading-5" className="vf-heading vf-marginbottom-0">{<FormattedMessage id="verifyPrcFindMe.PhoneOnFlyFail" values={{ 0: <b>{verifyPrcObj.enteredNumber}</b> }} />} </span></div>)}
                  {(phoneOnFlyFail  && verifyPrcObj.disableFields.length === 1 ) && (<div id="verify-prc-heading-6" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading1" />}</div>)}
                  {(phoneOnFlyFail  && verifyPrcObj.disableFields.length > 1 ) && (<div id="verify-prc-heading-7" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading" />}</div>)}
                  {(!phoneOnFlyFail) && (<div id="verify-prc-heading-8" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading" />}</div>)}
                </div>
              )
              }

              {(verifyPrcObj.findMeFlow == false && verifyPrcObj.mykplanFlow == false) && (
                <div>
                  {(phoneOnFlyFail) && (<div className="pof-error"><span className="warn-icon-margin"> <WarnIcon style={{ marginBottom: "2px" }} className="vdl-modal__title-icon warn-icon-info_pof" /> </span> <span id="verify-prc-heading-9" className="vf-heading vf-marginbottom-0">{<FormattedMessage id="verifyPrcFindMe.PhoneOnFlyFail" values={{ 0: <b>{verifyPrcObj.enteredNumber}</b> }} />} </span></div>)}
                  {(phoneOnFlyFail  && verifyPrcObj.disableFields.length === 1 ) && (<div id="verify-prc-heading-10" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading1" />}</div>)}
                  {(phoneOnFlyFail  && verifyPrcObj.disableFields.length > 1 ) && (<div id="verify-prc-heading-11" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading" />}</div>)}
                  {(!phoneOnFlyFail) && (<div id="verify-prc-heading-12" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.heading" />}</div>)}
                </div>
              )
              } </div>}
              { verifyPrcObj.showNudgePage ==true && 
                <div id="verify-prc-heading-13" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.nudgesubtitle" />}</div>
              }

              {verifyPrcObj.associateContactInfo &&
               <div id="prc_comm">
                {verifyPrcObj.associateContactInfo.map((value, index) => (
                  value.communicationType != constants.TYPE_POSTAL_CODE && (
                    
                      <div tabIndex="0" role="button" className={verifyPrcObj.disableFields[index] ? 'vdl-row vf-margin-size vf-row-size vf-disabled vf-row-padding highlight-options focus-selector' : 'vdl-row vf-margin-size vf-row-size vf-row-padding highlight-options focus-selector'} id={"p" + value.contactId} key={"p" + index}

                        onClick={() => {
                          ReactGA.sendevent(gaCategory, 'CHANNEL', value.communicationType);
                          return identitySelected(index, verifyPrcObj.disableFields[index])
                         }} 
                         onKeyPress={() => {
                          ReactGA.sendevent(gaCategory, 'CHANNEL', value.communicationType);
                          return identitySelected(index, verifyPrcObj.disableFields[index])
                         }} >
                        {value.communicationType == constants.TYPE_EMAIL && (<EnvelopeIcon className="link-content vf-col-sm-2 vf-email-icon" />)}
                        {value.communicationType == constants.TYPE_CELL_PHONE && (<MobileIcon className="link-content vf-col-sm-2 vf-phone-icon" />)}

                        <div className="vf-col-sm-9 vf-text-margin-left">
                          {value.communicationType == constants.TYPE_EMAIL && (<div className="vf-text-label vf-font-size"><span className="link-content">{<FormattedMessage id="verifyPrc.email" />}</span></div>)}
                          {value.communicationType == constants.TYPE_CELL_PHONE && (<div className="vf-text-label vf-font-size"><span  className="link-content">{<FormattedMessage id="verifyPrc.phone" />}</span></div>)}

                          <div className="vf-prc-text"><span className="link-content">{value.contactInfo}</span></div>
                        </div>
                        <FaAngleRight style={{ fontSize: "24px" }} className="link-content vf-col-sm-1 vf-chevron-icon" />
                      </div>
                    
                  )
                ))}
               </div>
              }

              {
                verifyPrcObj.isPhoneOnFlyEnabled && (
                  <div id="prc_phonefly" tabIndex="0" role="button" className={verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1] ? 'vdl-row vf-margin-size vf-row-size vf-disabled vf-row-padding highlight-options focus-selector' : 'vdl-row vf-margin-size vf-row-size vf-row-padding focus-selector highlight-options'}


                    onClick={() => {
                      ReactGA.sendevent(gaCategory, 'CHANNEL', "PHONEONFLY");

                      return phoneOnFlySelected(verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1])
                    }} 
                    onKeyPress={() => {
                      ReactGA.sendevent(gaCategory, 'CHANNEL', "PHONEONFLY");
                      return phoneOnFlySelected(verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1])
                    }} >

                    <NewMobileIcon className="link-content vf-col-sm-2 vf-newPhone-icon" />

                    <div className="vf-col-sm-9 vf-text-margin-left">

                       <div className="vf-text-label vf-font-size"><span className="link-content">{<FormattedMessage id="verifyPrc.phoneOnFly" />}</span></div>
                       <div> <span className="link-content"> {<FormattedMessage id="verifyPrc.phoneOnFlyUsOnly" values={{0: <span className="pof-flag pof-us"></span>}} />}</span></div>   
                    </div>
                    <FaAngleRight style={{ fontSize: "24px" }} className="link-content vf-col-sm-1 vf-chevron-icon" />
                  </div>
                )
              }

              {(verifyPrcObj.enablePostal == true) && (<div id="prc_postal" tabIndex="0" role="button" className="vdl-row vf-margin-size vf-row-size vf-row-padding highlight-options focus-selector"

                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CHANNEL', 'POSTAL');
                  return goToPostal()
                }}
                 onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'CHANNEL', 'POSTAL');
                  return goToPostal()
                }} >
                <PostalIcon className="link-content vf-col-sm-2 vf-email-icon" />
                <div className="vf-col-sm-9 vf-text-margin-left">
                  <div className="vf-text-label-kba vf-font-size"><span className="link-content">{verifyPrcObj.postalInfo}</span></div>
                </div>
                <FaAngleRight style={{ fontSize: "24px" }} className="link-content vf-col-sm-1 vf-chevron-kba-icon" />
              </div>)}

              {(verifyPrcObj.kbaDisabled == false) && (<div id="prc_KBA" tabIndex="0" role="button" className="vdl-row vf-margin-size vf-row-size vf-row-padding focus-selector highlight-options"


                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CHANNEL', 'KBA');
                  return goToKBA()
                }}
                 onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'CHANNEL', 'KBA');
                  return goToKBA()
                }} >
                <Comments className="link-content vf-col-sm-2 vf-kba-icon" />
                <div className="vf-col-sm-9 vf-text-margin-left">
                  <div className="vf-text-label-kba vf-font-size"><span className="link-content">{<FormattedMessage id="verifyPrc.kba" />}</span></div>
                </div>
                <FaAngleRight style={{ fontSize: "24px" }} className="link-content vf-col-sm-1 vf-chevron-kba-icon" />
              </div>)}
              { verifyPrcObj.showNudgePage ==true && <div className="vf-prc-otc-submit-div vf-prc-nudge-skip-div">
                <Button tabIndex = "0" role="button"
                  className="vf-code-subpanel2"
                  id="prc_otp_skipnudge"
                  className="fm-otc-footer-font"
                  buttonStyle="link"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return skipNudge();
                    }
                  }}
                  onClick={() => { return skipNudge()}}
                  > {<FormattedMessage id="verifyPrc.nudgeskip" />}
                </Button>
              </div>}
            </div>)}
            {/*********************Choose identity page end *****************************/}



            {/********************Enter mobile number page start****************************/}
            {(showVerify == false) && (prcAlreadyExists == false) && (showPhoneOnFly) && (<form id="verify-prc-form-2" onKeyPress={(e) => this._handleKeyPress(e, mobilePhoneValid, 'POF')} className="vf-sub-form-padding padding-align-inc-width-pof">
            {(hideText) && (<div className="pof-error"><span className="warn-icon-margin"> <WarnIcon style={{ marginBottom: "2px" }} className="vdl-modal__title-icon warn-icon-info_pof" /> </span> <span id="verify-prc-heading-15" className="vf-heading vf-marginbottom-0">{<FormattedMessage id="verifyPrcFindMe.PhoneOnFlyFail" values={{ 0: <b>{verifyPrcObj.enteredNumber}</b> }} />} </span></div>)}
            {(!hideText) &&( <div id="verify-prc-heading-16" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="verifyPrc.phoneOnFlyHeading" />}</div>)}
              {(phoneOnFlyAlert) && (<div className="vf-prc-otc-alert" role="alert">
                {ReactHtmlParser(verifyPrcObj.showAlertMessage)}
              </div>)}
                <div  className="pof-padding">
                  {(!phoneOnFlyAlert) && (<span
                    id="mobileLabel"
                    className="label-fields"
                  >
                    <FormattedMessage id="verifyPrc.poflabel1" />
                    <span className="vp-required-icon">

                      *
                          </span>
                          <span id="phoneFly_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'PHONENUMBER', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'phoneonfly_linkdesc' }))} >
                              <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{marginBottom:"2px"}}/></span>
                            </OverlayTrigger>
                          </span>
                  </span>)}
                  <div className="pof-countrycode-div">
                  <CountryCodeDropdown                
                    id="phoneFly_textBox"
                    title="PhoneValue"
                    flagId="phoneFly_PhoneCountryCd"
                    onlyCountries={verifyPrcObj.onlyCountries}
                    onChange={onSetMobilePhone}
                    value={mobilePhoneObj}
                    validationRule={constants.PHONE_REGEX}
                    gaName={gaCategoryPhoneOnFly}
                    errorMessage={this.props.intl.formatMessage({ id: 'mobileInvalidMessage' })}
                    defaultCountry="us"
                    fieldValid={mobilePhoneValid && !phoneReEntryError}
                    phoneOnFlyDisable = {true}
                    fieldType="mobile"
                    indexField="0"
                  />
                  </div>
                </div>
              <div className="vf-prc-submit-div-pof" >  <Button tabIndex = "0" id="phoneFly_continueBtn" disabled={!mobilePhoneValid || isBlank(mnumber)}
                style={{ minHeight: "35px", minWidth: "180px", marginTop: "0px" }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    ReactGA.sendevent(gaCategory, 'PHONEONFLY', 'SUBMIT');
                    return verifyPhoneOnFly();
                  }
                }}
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'PHONEONFLY', 'SUBMIT');
                  return verifyPhoneOnFly();
                }}
                className="vf-otc-submit safari-button-align">
                {<FormattedMessage id="verifyPrc.phoneOnFlySubmit" />}
              </Button></div>
              {(pofBackEnabled) &&(
                <Button tabIndex = "0"
                id="phoneFly_backBtn"
                style={{ paddingBottom: "0px", paddingTop: "15px" }}
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    ReactGA.sendevent(gaCategory, 'PHONEONFLY', 'BACK');
                    return backToOptionsPage();
                  }
                }}
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'PHONEONFLY', 'BACK');
                  return backToOptionsPage();
                }}
                >
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
                </Button>
              )}
            </form>)}

            {/*********************Enter mobile number page end *****************************/}


            {/*********************ONE  TIME CODE  page start *****************************/}
            {(showVerify == false) && (prcAlreadyExists == false) && (showPhoneOnFly == false) && ( 
            <form id="verify-prc-form-2"
             onKeyPress={(e) => this._handleKeyPress(e, isFormValid, 'OTP')}
             onSubmit={(e) => {
                      e.preventDefault();
                    }}
             className="vf-sub-form-padding padding-align-inc-width">
                <div>
               <div id="verify-prc-heading-17" className="vf-heading vf-marginbottom-0">
                {verifyPrcObj.commType === constants.TYPE_EMAIL ? (
                      <span id="verify-prc-info-1">
                        <FormattedMessage
                          id="vf.otpEmailHeading"
                          values={{
                            0: verifyPrcObj.selectedIdentityValue,
                          }}
                        />
                      </span>
                    ):  (
                      <span id="cellphone-message">
                        <FormattedMessage
                          id="vf.otpPhnHeading"
                          values={{
                            0: verifyPrcObj.selectedIdentityValue,
                          }}
                        />
                      </span>
                    )}
                    </div>
                  <div id="verify-prc-heading-18" className="vf-heading vf-marginbottom-10">{<FormattedMessage id="findMe.otpSubTitle"  values={{ 0: verifyPrcObj.expiry }} />}</div>
                </div>
              <div className="vf-emailphone-width-otp vf-otp-div" >

                {(!verifyPrcObj.showAlert) &&
                  (<span className="label-fields label-otp-align">{<FormattedMessage id="findMe.otpCode" />}</span>)}
                {(verifyPrcObj.showAlert) && (<div className="vf-prc-otc-alert" role="alert">
                  {ReactHtmlParser(verifyPrcObj.showAlertMessage)}
                </div>
                )}
                 <input
                        id="prcTextBox" aria-label={this.props.intl.formatMessage({ id: 'findMe.otpCode' })}
                        type="tel"
                        maxLength={6}
                        className={verifyPrcObj.prcValid ? 'vf-otc-txt otp-textbox' : 'otp-textbox vf-otc-txt vdl-validation-error'}
                        onChange={
                         (event) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "OTP" });
                          return onSetOtcValue(event);
                        } 
                        }
                        value={otcCode}
                      />
              </div>
              {verifyPrcObj.showOnScreen && (<div id="verify-prc-access-code" className="vf-access-code">{verifyPrcObj.accessCode}</div>)}
              <div className="vf-prc-otc-submit-div">  <Button id="prc_otp_continueBtn" disabled={!isFormValid}
                style={{ minHeight: "35px", minWidth: "180px" }}
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                  return submitAccessCode();
                }}
                className="vf-otc-submit safari-button-align">
                {<FormattedMessage id="continueBtn.Label" />}
                </Button></div>
                {verifyPrcObj.showDiffMethodBtn && (
                <Button id="prc_otp_backBtn" tabIndex = "0"
                style={{paddingBottom:"0px",paddingTop:"15px",margin:"0 auto"}}
                className="vf-otc-resend" 
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    return handleWarnModal(true);
                  }
                }}
                onClick={() => handleWarnModal(true)} buttonStyle="link" >
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
                </Button> )}
                { showVerify == false && prcAlreadyExists == false && showPhoneOnFly == false &&
                  verifyPrcObj.showDiffMethodBtn == false && 
                  verifyPrcObj.showNudgePage == true && <div tabIndex = "0" role="button" className="focus-selector" 
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return skipNudge()
                    }
                  }} >
                <Button 
                  className="vf-otc-resend vf-prc-nudge-otp-skip-div"
                  id="nudge_skip"
                  buttonStyle="link"
                  onClick={() => { return skipNudge()}}
                  > {<FormattedMessage id="verifyPrc.nudgeskip" />}
                </Button>
              </div>}
                  
            </form>)}
            {/*********************ONE  TIME CODE  page end *****************************/}

            {/*********************prcAlreadyExists  page start *****************************/}

            {(prcAlreadyExists == true) && (<form id="verify-prc-form-2" onKeyPress={(e) => this._handleKeyPress(e, isFormValid, 'OTP')} className="vf-sub-form-padding padding-align-inc-width">

              <div className="fm-emailphone-width vf-otp-div">
                {(!verifyPrcObj.showAlert) &&
                  (<label id="verify-prc-otc" className="vf-otc vf-prc-info3-label">{<FormattedMessage id="verifyPrc.info3" />}</label>)}
                {(verifyPrcObj.showAlert) && (<div className="vf-prc-otc-alert" role="alert">
                  {ReactHtmlParser(verifyPrcObj.showAlertMessage)}
                </div>
                )}
                <Textbox
                  id="prc_exist_textBox" aria-label={this.props.intl.formatMessage({ id: 'verifyPrc.info3' })}

                  maxLength={100}
                  className={verifyPrcObj.prcValid ? 'vf-otc-txt' : 'vf-otc-txt vdl-validation-error'}
                  onChange={
                    (value) => {
                      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PRC" });
                      return onSetPrcValue(value);
                    }
                  }
                  value={otcCode}
                />
              </div>
              {verifyPrcObj.showOnScreen && (<div id="verify-prc-access-code" className="vf-access-code">{verifyPrcObj.accessCode}</div>)}
              {verifyPrcObj.showDiffMethodBtn && (<Button tabIndex = "0" id="prc_exist_backBtn" 
              onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        return onClickDifferentMethod();
                      }
                    }} 
                onClick={onClickDifferentMethod} buttonStyle="link" className="vf-otc-resend">
                {<FormattedMessage id="verifyPrc.btn2" />}
              </Button>)}
              <div tabIndex = "0" className="vf-prc-otc-submit-div focus-selector">  <Button id="prc_exist_continueBtn" disabled={!isFormValid}
                style={{ minHeight: "35px", minWidth: "180px" }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                    return submitAccessCode();
                  }
                }} 
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                  return submitAccessCode();
                }}
                className="vf-otc-submit safari-button-align">
                {<FormattedMessage id="submitCBtn.Label" />}
              </Button>
              </div>


            </form>)}

            {/*********************prcAlreadyExists  page end *****************************/}

            {/********************Error modal identity page start  *****************************/}
            {verifyPrcObj.showModalMessage && (<Modal id="errorModal"
              className="vdl-modal--alert"
              show={showModalError}
              onEnter={() => { WindowUtil.applyBrandingChanges(); }}
            >
              <ModalHeader closeButton={false} className="modal-header">
                <ModalTitle className='modal-title-error'>
                  <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                  {verifyPrcObj.showModalTitle}
                </ModalTitle>
              </ModalHeader>
              <ModalBody className="modal-body-footercolor">
                <div>
                  <label className="modal-text">{ReactHtmlParser(verifyPrcObj.showModalMessage)}</label>

                </div>
              </ModalBody>
              <ModalFooter className="modal-body-footercolor">
                {!showOtcModal && (<Button id="prc_okBtn1" onClick={onCancelModal} className="safari-button-align">
                  {<FormattedMessage id="Lbl_OkButton" />}
                </Button>)}
                {showOtcModal && (<Button id="prc_okBtn2" onClick={cancelAndCheckYourEntries} className="safari-button-align">
                  {<FormattedMessage id="Lbl_OkButton" />}
                </Button>)}
              </ModalFooter>
            </Modal>)}

            {/*********************Error modal identity page end*****************************/}



            {/********************Info modal identity page start  *****************************/}
            {verifyPrcObj.showModalMessage && (<Modal id="infoModal"

              show={showModalInfo}
              onEnter={() => { WindowUtil.applyBrandingChanges(); }}
            >
              <ModalHeader closeButton={false}>
                <ModalTitle>
                  <WarnIcon className="vdl-modal__title-icon" />
                  {verifyPrcObj.showModalTitle}
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div>
                  <br />
                  {ReactHtmlParser(verifyPrcObj.showModalMessage)}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button id="prc_okBtn3" onClick={cancelAndCheckYourEntries} className="safari-button-align">
                  {<FormattedMessage id="Lbl_OkButton" />}
                </Button>
              </ModalFooter>
            </Modal>)}

            {/*********************Info modal identity page end*****************************/}

            {/*********************Warn modal identity page end*****************************/}

            {verifyPrcObj.title && (<Modal id="warnModal"
              className="vdl-modal--alert"
              show={showModalWarn}
              onEnter={() => { WindowUtil.applyBrandingChanges(); }}
            >
              <ModalHeader closeButton={false} className="modal-header">
                <ModalTitle className='modal-title-info'>
                  <WarnIcon className="vdl-modal__title-icon warn-icon-info" />
                  {verifyPrcObj.title}
                </ModalTitle>
              </ModalHeader>
              <ModalBody className="modal-body-footercolor">
                <div>
                  <label htmlFor="showModalMessage" className="modal-text">
                    {verifyPrcObj.confirmMsg1}
                    <br />
                    {verifyPrcObj.selectedIdentityValue}
                    <br />
                    {verifyPrcObj.confirmMsg2}
                    <br />
                  </label>
                </div>
              </ModalBody>
              <ModalFooter className="modal-body-footercolor">
                <Button id="prc_yesBtn" onClick={onClickDifferentMethod} className="safari-button-align">
                  {<FormattedMessage id="Msg_VCompanyIdentificationYes" />}
                </Button>
                <Button id="prc_noBtn" onClick={() => handleWarnModal(false)} className="safari-button-align">
                  {<FormattedMessage id="Msg_VCompanyIdentificationNo" />}

                </Button>
              </ModalFooter>
            </Modal>)}
            {/*********************Warn modal identity page end*****************************/}

            
         	{ /** **************************Footer OTP page starts************************************** */}

	          {((showVerify == false) && (prcAlreadyExists == false) && (showPhoneOnFly == false)) && (
	            <OTPFooter idVal="prc_otp_resend" btnName="verifyPrc.btn1"  footerText="findMe.otpFooterTitle" btnClick={onResendBtnClick} disableResendBtn={this.state.disableResendBtn} ></OTPFooter>
            )}

            {((verifyPrcObj.showNudgePage == true)) && (this.state.showNudgeFooter == true) && (
	            <OTPFooter idVal="edit_contact_info" btnName="verifyPrc.editcontactinfobtn"  footerText="verifyPrc.editcontactinfotitle" btnClick={editContactInfoPage} disableResendBtn={false} ></OTPFooter>
            )}
            

        	{ /** **************************Footer OTP page end************************************** */}

          </div>
        </div>
      </div>
      )
    );
  }
}
export default injectIntl(VerifyPrcForm);
