import { handleActions } from 'redux-actions';
import { types } from './verify-kba-actions';

const {
  VERIFY_KBA_INFO_OPTIONS_SUCCESS,
  VERIFY_KBA_INFO_OPTIONS_FAIL,
  VERIFY_KBA_INFO_OPTIONS_UPDATE
  
} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  transactionId: '',
  verifyKbaObj: {
    showDisclaimer: true
  }

};

export default handleActions(
  {
    [VERIFY_KBA_INFO_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [VERIFY_KBA_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [VERIFY_KBA_INFO_OPTIONS_UPDATE]: (state, { payload }) => ({ ...state, ...payload })
    
  },
  initialState,
);
