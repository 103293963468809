import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { types } from '../external-vendor-actions';
import { constants } from '../external-vendor-constants';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  EXTERNAL_VENDOR_INFO_OPTIONS,
  EXTERNAL_VENDOR_INFO_OPTIONS_SUCCESS,
  EXTERNAL_VENDOR_INFO_OPTIONS_FAIL,
  CANCEL_VENDOR_AND_CHECK_YOUR_ENTRIES,
  SET_MANUAL_SELECTED,
  SET_MANUAL_SELECTED_SUCCESS,
  OPEN_EXTERNAL_VENDOR_REDIRECT,
  SET_EXTERNAL_VENDOR_REDIRECT
} = types;

const getExternalVendorInfoOptionsSuccess = createAction(EXTERNAL_VENDOR_INFO_OPTIONS_SUCCESS);
const getVerifyPrcOptionsFail = createAction(EXTERNAL_VENDOR_INFO_OPTIONS_FAIL);
const setManualSelectedSuccess = createAction(SET_MANUAL_SELECTED_SUCCESS);

const getFeedDataApi = (getState) => {
  //return Observable.ajax.getJSON('/public/mocks/externalVendor.json')
  if (getState.verifyUser.extVendorList != undefined && getState.verifyUser.extVendorList != '') {
    return of({
      "extVendorList": getState.verifyUser.extVendorList,
      "viewId": getState.verifyUser.sivViewId,
      "companyName": getState.verifyUser.companyName
    });
  } else {
    return of({
      "extVendorList": getState.registration.extVendorList,
      "viewId": getState.registration.sivViewId,
      "companyName": getState.registration.companyName
    });
  }
};

const getManualSelected = (getState) => {
  let sendRedirect = true;
  return of({
    sendRedirect
  });
}

const logExternalVendor = (ajax,getState) => {
  return ajax({
    url: constants.LOG_EXTERNAL_VENDOR,
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      "X-TRANSACTIONID": getState.registration.transactionId
    }
  });
  //return Observable.ajax.getJSON('/public/mocks/logExternalVendor.json');
}

const handleExternalVendorResponse = (data, getState) => {
  let redirectUrl = getState.externalVendor.externalVendorObj.extVendorList[0].redirectUrl;
  window.open(unescape(decodeURIComponent(redirectUrl)), '_self')
}

const openExtVendor = (ajax,getState) => logExternalVendor(ajax,getState).pipe(
  map(response => handleExternalVendorResponse(response, getState)),
  catchError(err => [getErrorMessage(err)]),
);

const getVerifyPrcInfo = (getState) => getFeedDataApi(getState).pipe(
  map(response => handleResponse(response, getState)),
  catchError(err => getVerifyPrcOptionsFail(getErrorMessage(err))),
);

const setManualSelected = (getState) =>
  getManualSelected(getState).pipe(map(setManualSelectedSuccess));

const setSubmitAccessCode = (payload,getState) => setSubmitAccessCodeApi(payload, getState).pipe(
  map(response => handleResponseAccessCode(response,payload, getState)),
  catchError(err => setSubmitAccessCodeFail(getErrorMessage(err))),
);

const handleResponse = (data, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_CHOOSE_VENDORS";
  let pagePath = '/ssr/flowType/chooseManuallyOrCapitalone';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_CHOOSE_VENDORS"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_CHOOSE_VENDORS"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  let externalVendorObj = Object.assign({}, getState.externalVendor.externalVendorObj);
  externalVendorObj = data;
  externalVendorObj.showDiv = true;
  externalVendorObj.title = getState.intl.messages['externalVendor.title'] + " " + externalVendorObj.companyName;
  let viewId = data.viewId;
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end

  return getExternalVendorInfoOptionsSuccess({
    externalVendorObj, viewId, isMobileBrowser, isMobile, gaCategory, progressBarData
  });
}

export const getVerifyPrcInfoEpic = (action$, state) => action$.pipe(
  ofType(EXTERNAL_VENDOR_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getVerifyPrcInfo(state.value))),
);

export const openExternalVendorEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(OPEN_EXTERNAL_VENDOR_REDIRECT),
  switchMap(action => sendingAsyncRequest(openExtVendor(ajax,state.value))),
);

export const setManualSelectedEpic = (action$, state) => action$.pipe(
  ofType(SET_MANUAL_SELECTED),
  switchMap(action => sendingAsyncRequest(setManualSelected(state.value))),
);

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId
  if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

export const getExternalVendorInfoOptionsFailureEpic = action$ => action$.pipe(
  ofType(EXTERNAL_VENDOR_INFO_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))),
);

export const setRedirectSentEpic = (action$, state) => action$.pipe(
  ofType(SET_EXTERNAL_VENDOR_REDIRECT),
  switchMap(action$ => of(push(state.value.externalVendor.viewId))),
);

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_VENDOR_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

