/* eslint-disable newline-after-var */
/* eslint-disable no-unneeded-ternary */
const minOneLetter = (password) => {
  const validationResult =
    password && password.length > 0 && (password.match(/[A-Z]/) || password.match(/[a-z]/)) ?
      true :
      false;
  return validationResult;
};

const minOneUpperCaseLetter = (password) => {
  const validationResult =
    password && password.length > 0 && password.match(/[A-Z]/) ? true : false;
  return validationResult;
};

const minOneLowerCaseLetter = (password) => {
  const validationResult =
    password && password.length > 0 && password.match(/[a-z]/) ? true : false;
  return validationResult;
};

const minOneNumber = (password) => {
  const validationResult = password && password.length > 0 && password.match(/\d/) ? true : false;
  return validationResult;
};

const minEightChars = (password) => {
  const validationResult = password && password.length >= 8 ? true : false;
  return validationResult;
};

const minimumRequirements = (password) => {
  let count = 0;
  if (minOneNumber(password)) {
    count += 1;
  }
  if (minOneUpperCaseLetter(password)) {
    count += 1;
  }
  if (minOneLowerCaseLetter(password)) {
    count += 1;
  }
  if (minOneSpecialCharacter(password)) {
    count += 1;
  }
  return minEightChars(password) && count >= 3 ? true : false;
};

const mixedCaseLetters = (password) => {
  const validationResult = minOneUpperCaseLetter(password) && minOneLowerCaseLetter(password);
  return validationResult;
};

const minTwelveChars = (password) => {
  const validationResult = password && password.length >= 12 ? true : false;
  return validationResult;
};

const minOneSpecialCharacter = (password) => {
  const validationResult =
    password && password.match(/[~`!@#$%^&*+=\-_[\]\\;.,()/{}|\\:<>?]/g) ? true : false;
  return validationResult;
};

const moreThan3SequentialCharacters = (password) => {
  // Check for sequential numerical characters
  let validationResult = (password && password.length > 0 && password.match(/^(.*((?=.*0123)|(?=.*1234)|(?=.*2345)|(?=.*3456)|(?=.*4567)|(?=.*5678)|(?=.*6789)|(?=.*9876)|(?=.*8765)|(?=.*7654)|(?=.*6543)|(?=.*5432)|(?=.*4321)|(?=.*3210)|(?=.*[aA][bB][cC][dD])|(?=.*[bB][cC][dD][eE])|(?=.*[cC][dD][eE][fF])|(?=.*[dD][eE][fF][gG])|(?=.*[eE][fF][gG][hH])|(?=.*[fF][gG][hH][iI])|(?=.*[gG][hH][iI][jJ])|(?=.*[hH][iI][jJ][kK])|(?=.*[iI][jJ][kK][lL])|(?=.*[jJ][kK][lL][mM])|(?=.*[kK][lL][mM][nN])|(?=.*[lL][mM][nN][oO])|(?=.*[mM][nN][oO][pP])|(?=.*[nN][oO][pP][qQ])|(?=.*[oO][pP][qQ][rR])|(?=.*[pP][qQ][rR][sS])|(?=.*[qQ][rR][sS][tT])|(?=.*[rR][sS][tT][uU])|(?=.*[sS][tT][uU][vV])|(?=.*[tT][uU][vV][wW])|(?=.*[uU][vV][wW][xX])|(?=.*[vV][wW][xX][yY])|(?=.*[wW][xX][yY][zZ])|(?=.*[zZ][yY][xX][wW])|(?=.*[yY][xX][wW][vV])|(?=.*[xX][wW][vV][uU])|(?=.*[wW][vV][uU][tT])|(?=.*[vV][uU][tT][sS])|(?=.*[uU][tT][sS][rR])|(?=.*[tT][sS][rR][qQ])|(?=.*[sS][rR][qQ][pP])|(?=.*[rR][qQ][pP][oO])|(?=.*[qQ][pP][oO][nN])|(?=.*[pP][oO][nN][mM])|(?=.*[oO][nN][mM][lL])|(?=.*[nN][mM][lL][kK])|(?=.*[mM][lL][kK][jJ])|(?=.*[lL][kK][jJ][iI])|(?=.*[kK][jJ][iI][hH])|(?=.*[jJ][iI][hH][gG])|(?=.*[iI][hH][gG][fF])|(?=.*[hH][gG][fF][eE])|(?=.*[gG][fF][eE][dD])|(?=.*[fF][eE][dD][cC])|(?=.*[eE][dD][cC][bB])|(?=.*[dD][cC][bB][aA]))^.*)/)) ? true : false;
  return validationResult ? 'moreThan3SequentialCharacters' : false;    
};

const moreThan3RepeatedCharacters = (password) => {
  const validationResult = password && String(password).match(/(.)\1\1\1/) ? true : false;
  return validationResult ? 'moreThan3RepeatedCharacters' : false;
};

const nonASCIICharacter = (password) => {
  const validationResult = password && String(password).match(/[^ -~]/) ? true : false;
  return validationResult ? 'nonASCIICharacter' : false;
};

const beginOrEndWithSpace = (password) => {
  const validationResult =
    password && (String(password).endsWith(' ') || String(password).startsWith(' ')) ? true : false;
  return validationResult ? 'beginOrEndWithSpace' : false;
};

const maximum64Characters = (password) => {
  const validationResult = password && password.length > 64 ? true : false;
  return validationResult ? 'maximum64Characters' : false;
};

const checkforErrors = (password) => {
  const errorRules = [
    maximum64Characters,
    moreThan3RepeatedCharacters,
    moreThan3SequentialCharacters,
    nonASCIICharacter,
    beginOrEndWithSpace,
  ];
  const noError = 'noError';
  return errorRules
    .map((errorFunction) => {
      const errorName = errorFunction(password);

      return errorName ? errorName : noError;
    })
    .filter(result => result !== 'noError');
};

export {
  minOneLetter,
  minOneUpperCaseLetter,
  minOneLowerCaseLetter,
  minOneNumber,
  minEightChars,
  minimumRequirements,
  mixedCaseLetters,
  minTwelveChars,
  minOneSpecialCharacter,
  moreThan3SequentialCharacters,
  moreThan3RepeatedCharacters,
  nonASCIICharacter,
  beginOrEndWithSpace,
  maximum64Characters,
  checkforErrors,
};
