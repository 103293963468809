import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(SMS_NAMESPACE, 'SENDING_REQUEST');

const { SENDING_REQUEST } = types;

const sendingRequest = (sending,flag) =>{ 
  let payload = {};
  payload.sending = sending;
  payload.flag = flag;
  return createAction(SENDING_REQUEST)(payload)};

export const actions = {
  sendingRequest,
};
