import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'GET_PIC_SIV_OPTIONS', 'GET_PIC_SIV_OPTIONS_SUCCESS', 'GET_PIC_SIV_OPTIONS_FAIL',
  'SET_PIC_SIV_FIELD',
  'SET_PIC_SIV_FIELD_SUCCESS',
  'SET_PIC_SIV_FIELD_FAIL',
  'VERIFY_PIC_SIV_OPTIONS',
  'VERIFY_PIC_SIV_OPTIONS_SUCCESS',
  'VERIFY_PIC_SIV_OPTIONS_FAIL',
  'ENABLE_PIC_SUBMIT_BUTTON',
  'CANCEL_PIC_AND_CHECK_YOUR_ENTRIES',
  'VERIFY_PIC_SET_LOCALE',
  'GET_PIC_SIV_OPTIONS_LOCALE_UPDATE',
  'UPDATE_LOCALE_FIELD_PIC'
);

const {
  GET_PIC_SIV_OPTIONS,
  SET_PIC_SIV_FIELD,
  VERIFY_PIC_SIV_OPTIONS,
  ENABLE_PIC_SUBMIT_BUTTON,
  CANCEL_PIC_AND_CHECK_YOUR_ENTRIES,
  VERIFY_PIC_SET_LOCALE,
  GET_PIC_SIV_OPTIONS_LOCALE_UPDATE
} = types;

const getSivOptionsSent = createAction(GET_PIC_SIV_OPTIONS);

const setSivFieldSent = createAction(SET_PIC_SIV_FIELD);

const verifySivOptionsSent = createAction(VERIFY_PIC_SIV_OPTIONS);

const enableSubmitButtonSent = createAction(ENABLE_PIC_SUBMIT_BUTTON);

const setLocaleSent = createAction(VERIFY_PIC_SET_LOCALE);

const cancelAndCheckYourEntriesSent = createAction(CANCEL_PIC_AND_CHECK_YOUR_ENTRIES);

const setSivField = (fieldkey, fieldValue) => setSivFieldSent({ fieldkey, fieldValue });

const verifySivOptions = () => verifySivOptionsSent();

const getSivOptions = () => getSivOptionsSent();

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();

const setLocale = (payload)=>setLocaleSent({ payload });

const getPicSivOptions = () => getPicSivOptionsSent();

const getPicSivOptionsSent = createAction(GET_PIC_SIV_OPTIONS_LOCALE_UPDATE);

export const actions = {
  getSivOptions,
  setSivField,
  verifySivOptions,
  enableSubmitButton,
  cancelAndCheckYourEntries,
  setLocale,
  getPicSivOptions,
};
