import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_KBA_INFO_OPTIONS',
  'VERIFY_KBA_INFO_OPTIONS_SUCCESS',
  'VERIFY_KBA_INFO_OPTIONS_FAIL',
  'VERIFY_KBA_INFO_OPTIONS_UPDATE',
  'VERIFY_KBA_SET_ANSWER_SELECTED',
  'VERIFY_KBA_REDIRECT',
  'VERIFY_KBA_TIME_EXPIRED',
  'CANCEL_KBA_AND_CHECK_YOUR_ENTRIES',
  'VERIFY_KBA_PRC_REDIRECT',
  'KBA_DISCLAIMER_INFO',
  'CANCEL_KBA'

);

const {
  VERIFY_KBA_INFO_OPTIONS,
  VERIFY_KBA_SET_ANSWER_SELECTED,
  VERIFY_KBA_INFO_OPTIONS_UPDATE,
  VERIFY_KBA_REDIRECT,
  VERIFY_KBA_TIME_EXPIRED,
  CANCEL_KBA_AND_CHECK_YOUR_ENTRIES,
  KBA_DISCLAIMER_INFO,
  VERIFY_KBA_PRC_REDIRECT,
  CANCEL_KBA


} = types;

const getVerifyKbaInfoSent = createAction(VERIFY_KBA_INFO_OPTIONS);
const setAnswerSelectedSent = createAction(VERIFY_KBA_SET_ANSWER_SELECTED);
const setRedirectSent = createAction(VERIFY_KBA_REDIRECT);
const setVerifyKbaUpdate = createAction(VERIFY_KBA_INFO_OPTIONS_UPDATE);
const setOnTimeExpiredSent = createAction(VERIFY_KBA_TIME_EXPIRED);
const setDisableFieldsSent = createAction(VERIFY_KBA_INFO_OPTIONS_UPDATE);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_KBA_AND_CHECK_YOUR_ENTRIES);
const getDisclaimerInfoSent = createAction(KBA_DISCLAIMER_INFO);
const getVerfifyKbaPrcRedirectSent = createAction(VERIFY_KBA_PRC_REDIRECT);
const cancelKbaCheckYourEntriesSent = createAction(CANCEL_KBA);


const getVerifyKbaInfo = () => getVerifyKbaInfoSent();
const showDisclaimerModal = () => showDisclaimerModalSent();
const setAnswerSelected = (index) => setAnswerSelectedSent(index);
const setRedirect = () => setRedirectSent();
const setCancelErrorModal = sendRedirect => setVerifyKbaUpdate({ sendRedirect });
const setOnTimeExpired = () => setOnTimeExpiredSent();
const setDisableFields = disableFields => setDisableFieldsSent({disableFields});
const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();
const setConfirmModal = showModalCancel => setVerifyKbaUpdate({showModalCancel});
const getDisclaimerInfo = () => getDisclaimerInfoSent();
const getVerfifyKbaPrcRedirect = () => getVerfifyKbaPrcRedirectSent();
const cancelKbaCheckYourEntries = () => cancelKbaCheckYourEntriesSent();


export const actions = {
  getVerifyKbaInfo,
  showDisclaimerModal,
  setAnswerSelected,
  setRedirect,
  setCancelErrorModal,
  setOnTimeExpired,
  setDisableFields,
  cancelAndCheckYourEntries,
  setConfirmModal,
  getDisclaimerInfo,
  getVerfifyKbaPrcRedirect,
  cancelKbaCheckYourEntries
};
