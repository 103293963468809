import { messages } from '.';

export const createIntlPayload = (locale) => {
  console.log('createIntlPayload', locale);

  return {
    locale: locale.split(/[-_]/)[0],
    messages: messages[locale],
  };
};
