import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { createMemoryHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { addLocaleData } from 'react-intl';
import { intlReducer } from 'react-intl-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createActionCallbackOnceMiddleware } from './middlewares';
import { reducers, rootEpics } from './ducks';
import { localeData, getLocaleInitialState } from '../util/locale';
import { constants as initialLoadConstants } from './ducks/initial-load';

const addAppLocaleData = () => addLocaleData(localeData);

addAppLocaleData();

const history = createMemoryHistory({

  initialEntries: [ '/loader', '/verifyUser', '/identifyYourself', '/verifyPic', '/contactInfo', '/registration', '/confirmation', '/verifyUserId', '/verifyUserPin', '/verify401k','/error',
  '/verifyAdmin' ], 

  initialIndex: 0,
  getUserConfirmation: null
}); 
const initialState = {
  ...getLocaleInitialState(),
};

const epicMiddleware = createEpicMiddleware();
const middlewares = [routerMiddleware(history), thunk, epicMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}

const { INITIAL_LOAD_SUCCESS_ACTION_TYPES } = initialLoadConstants;
const store = (render) => {
  const reduxStore = createStore(
    combineReducers({ ...reducers, router: connectRouter(history), intl: intlReducer }),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        ...middlewares,
        createActionCallbackOnceMiddleware(INITIAL_LOAD_SUCCESS_ACTION_TYPES, render),
      ),
    ),
  );

  epicMiddleware.run(rootEpics);

  return reduxStore;
};

export { history, store };
