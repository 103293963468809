import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { types } from '../verify-pic-actions';
import { constants } from '../verify-pic-constants';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';
import { getLocaleInitialState } from '../../../../util/locale';

const {
  GET_PIC_SIV_OPTIONS,
  GET_PIC_SIV_OPTIONS_SUCCESS,
  GET_PIC_SIV_OPTIONS_FAIL,
  SET_PIC_SIV_FIELD,
  SET_PIC_SIV_FIELD_SUCCESS,
  SET_PIC_SIV_FIELD_FAIL,
  VERIFY_PIC_SIV_OPTIONS,
  VERIFY_PIC_SIV_OPTIONS_SUCCESS,
  VERIFY_PIC_SIV_OPTIONS_FAIL,
  CANCEL_PIC_AND_CHECK_YOUR_ENTRIES,
  VERIFY_PIC_SET_LOCALE,
  GET_PIC_SIV_OPTIONS_LOCALE_UPDATE,
  UPDATE_LOCALE_FIELD_PIC

} = types;

const getPicSivOptionsSuccess = createAction(GET_PIC_SIV_OPTIONS_SUCCESS);
const getPicSivOptionsFail = createAction(GET_PIC_SIV_OPTIONS_FAIL);
const setSivFieldSuccessSent = createAction(SET_PIC_SIV_FIELD_SUCCESS);
const setSivFieldFailSent = createAction(SET_PIC_SIV_FIELD_FAIL);
const verifySivOptionsSuccessSent = createAction(VERIFY_PIC_SIV_OPTIONS_SUCCESS);
const verifySivOptionsFailSent = createAction(VERIFY_PIC_SIV_OPTIONS_FAIL);
const isBlank = str => (str || '').trim().length === 0;
const cancelAndCheckYourEntriesSent = createAction(CANCEL_PIC_AND_CHECK_YOUR_ENTRIES);
const setUpdateLocaleSuccess = createAction(UPDATE_LOCALE_FIELD_PIC);

const sivOptionsApi = (ajax, transactionId) => ajax({
  url: constants.getPicSivOptionsDetailsEndPoint,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    "cache-control": "no-cache",
    'X-TRANSACTIONID': transactionId,
  }
});

const handleResponse = (getState, response) => {
  let embeddedPrcFlow = getState.registration.embeddedPrcFlow || false;
  const isMobile = getState.registration.mobileFlow;
  
  console.log('Embedded prc invalid flow and redirected to pic after successful entry=======');
  // progress bar start
  if(embeddedPrcFlow) {
    if(getState.verifyUser.embeddedPrcInvalidSuccess !== undefined && getState.verifyUser.embeddedPrcInvalidSuccess) {
      getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.verifyUserViewId);
      embeddedPrcFlow = false;
    } else {
      getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.verifyPicViewId);
    } 
  } 
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end

  PendoUtil.pendoIdentify(response.orgCd,response.regCodeData.companyName,getState.registration.transactionId)  
  PendoUtil.pendoVisitorIdUpdate(response.associateId)

  let updatedlocale = WindowUtil.getLocaleValue(getState.locale.locale);
  return getPicSivOptionsSuccess({
    sivOptions: response.regCodeData.sivOptions,
    clientName: response.regCodeData.companyName,
    progressBarData,
    isMobile,
    response,
    embeddedPrcFlow,
    updatedlocale
  });
}

const handlePICResponse = (getState, response) => {
 let updatedlocale = WindowUtil.getLocaleValue(getState.locale.locale);
  return setSivFieldSuccessSent({
    sivOptions: response.regCodeData.sivOptions,
    updatedlocale
  });
}


const sivOptionsFromState = (stateObj) => of({
  sivOptions: stateObj
});

const handleStateSivResponse = (getState, response) => {
  const isMobile = getState.registration.mobileFlow;
  let updatedlocale = WindowUtil.getLocaleValue(getState.locale.locale);
  let embeddedPrcFlow = getState.registration.embeddedPrcFlow || false;
  let clientName = getState.registration.companyName || getState.verifyUser.companyName;
  const runAdminFlow = getState.verifyUser.runAdminFlow;
  const runAdminAccountantConnectFlow = getState.verifyUser.runAdminAccountantConnectFlow;

  console.log('Embedded prc invalid flow and redirected to pic after successful entry=======');
  // progress bar start
  if(embeddedPrcFlow) {
    if(getState.verifyUser.embeddedPrcInvalidSuccess !== undefined && getState.verifyUser.embeddedPrcInvalidSuccess) {
      getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.verifyUserViewId);
      embeddedPrcFlow = false;
    } else {
      getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.verifyPicViewId);
    } 
  } 
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end

  return getPicSivOptionsSuccess({
    sivOptions: response.sivOptions,
    isMobile,
    clientName,
    progressBarData,
    embeddedPrcFlow,
    updatedlocale,
    runAdminFlow,
    runAdminAccountantConnectFlow,
  });
}

const getSivOptions = (ajax, getState) => {
  const isMobile = getState.registration.mobileFlow;
  console.log('sivOptions call in PIC ======>' + getState.verifyUser.sivOptions);
  if (getState.registration.sivOptions != '' && getState.registration.sivOptions != undefined) {
    console.log('sivOptions from transaction in PIC');
    return sivOptionsFromState(getState.registration.sivOptions)
      .pipe(map(response => handleStateSivResponse(getState, response)),
        catchError(err => [getPicSivOptionsFail(getErrorMessage(err))]));
  } else if (getState.verifyUser.sivOptions != '' && getState.verifyUser.sivOptions != undefined) {
    console.log('sivOptions from orgverify in PIC');
    return sivOptionsFromState(getState.verifyUser.sivOptions)
      .pipe(map(response => handleStateSivResponse(getState, response)),
        catchError(err => [getPicSivOptionsFail(getErrorMessage(err))]));
  } else {
    console.log('invoke sivOptions in PIC');
    return sivOptionsApi(ajax, getState.registration.transactionId)
      .pipe(map(response => { response.response.isMobile = isMobile; return handleResponse(getState, response.response) }),
        catchError(err => [getPicSivOptionsFail(getErrorMessage(err))]));
  }
}

const validateAndSetSivField = (payload, getState) => {
  const identifyYourself = getState.verifyPic;

  switch (payload.fieldkey) {
    case constants.RADIO_OPTION: {
        return of({
          radioValue : payload.fieldValue
        });
    }
    case constants.sivOptionIds.siv_firstName: {
      const firstNameRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_firstname.regExp);

      if (isBlank(payload.fieldValue) || firstNameRegEx.test(payload.fieldValue)) {
        return of({
          firstName: payload.fieldValue,
          firstNameValid: true,
        });
      }

      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_firstname.invalidMessage));
    }
    case constants.sivOptionIds.siv_lastName: {
      const lastNameRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_lastname.regExp);

      if (isBlank(payload.fieldValue) || lastNameRegEx.test(payload.fieldValue)) {
        return of({
          lastName: payload.fieldValue,
          lastNameValid: true,
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_lastname.invalidMessage));
    }
    case constants.sivOptionIds.siv_employee_id: {
      const employeeIdRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_empid.regExp);

      if (isBlank(payload.fieldValue) || employeeIdRegEx.test(payload.fieldValue)) {
        return of({
          empId: payload.fieldValue,
          empIdValid: true,
          wfnId: '',
          ssn: '',
          ssn4: '',
          fullMonth: '',
          fullDay: ''
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_empid.invalidMessage));
    }
    case constants.sivOptionIds.siv_wfn_associate_id: {
      const wfnAssociateIdRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_wfnid.regExp);

      if (isBlank(payload.fieldValue) || wfnAssociateIdRegEx.test(payload.fieldValue)) {
        return of({
          wfnId: payload.fieldValue,
          wfnIdValid: true,
          empId: '',
          ssn: '',
          ssn4: '',
          fullMonth: '',
          fullDay: ''
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_wfnid.invalidMessage));
    }
    case constants.sivOptionIds.siv_ssn: {
      const ssnRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_ssn.regExp);

      if (isBlank(payload.fieldValue) || ssnRegEx.test(payload.fieldValue)) {
        var replacedSSN = payload.fieldValue.replace(/-/g, "");
        return of({
          ssn: replacedSSN,
          ssnValid: true,
          wfnId: '',
          empId: '',
          ssn4: '',
          fullMonth: '',
          fullDay: ''
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_ssn4.invalidMessage));
    }
    case constants.sivOptionIds.siv_last4_ssn: {
      const ssn4RegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_ssn4.regExp);

      if (isBlank(payload.fieldValue) || ssn4RegEx.test(payload.fieldValue)) {
        return of({
          ssn4: payload.fieldValue,
          ssn4Valid: true,
          wfnId: '',
          empId: '',
          ssn: '',
          fullMonth: '',
          fullDay: ''
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_ssn4.invalidMessage));
    }
    case constants.sivOptionIds.siv_dob_month: {
      return of({
        fullMonth: payload.fieldValue,
        wfnId: '',
        ssn: '',
        ssn4: '',
        empId: '',
        fullDay: ''
      });
    }
    case constants.sivOptionIds.siv_dob_day: {
      return of({
        fullDay: payload.fieldValue,
        wfnId: '',
        ssn: '',
        ssn4: '',
        empId: ''
      });
    }

    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};

const verifySivOptions = (ajax, getState) => {
  const identifyYourself = getState.verifyPic;
  const sivOptions = identifyYourself.sivOptions;
  const gaCategory = identifyYourself.gaCategory;
  var uiOptionsMap = {};
  var radioOptionValue = '';

  if (sivOptions.availableSivOptions.siv_firstname && identifyYourself.firstName != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'FIRST_NAME');
    uiOptionsMap.siv_firstname = identifyYourself.firstName;
    sivOptions.availableSivOptions.siv_firstname.idenDataValue = identifyYourself.firstName;
  }
  if (sivOptions.availableSivOptions.siv_lastname && identifyYourself.lastName != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'LAST_NAME');
    uiOptionsMap.siv_lastname = identifyYourself.lastName;
    sivOptions.availableSivOptions.siv_lastname.idenDataValue = identifyYourself.lastName;
  }
  if (sivOptions.availableSivOptions.siv_empid && identifyYourself.empId != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'EMP_ID');
    uiOptionsMap.siv_empid = identifyYourself.empId;
    radioOptionValue = 'siv_empid';
    sivOptions.availableSivOptions.siv_empid.idenDataValue = identifyYourself.empId;
  }
  if (sivOptions.availableSivOptions.siv_wfnid && identifyYourself.wfnId != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'WFN_ID');
    uiOptionsMap.siv_wfnid = identifyYourself.wfnId;
    radioOptionValue = 'siv_wfnid';
    sivOptions.availableSivOptions.siv_wfnid.idenDataValue = identifyYourself.wfnId;
  }
  if (sivOptions.availableSivOptions.siv_ssn && identifyYourself.ssn != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'SSN');
    uiOptionsMap.siv_ssn = identifyYourself.ssn;
    radioOptionValue = 'siv_ssn';
    sivOptions.availableSivOptions.siv_ssn.idenDataValue = identifyYourself.ssn;
  }
  if (sivOptions.availableSivOptions.siv_ssn4 && identifyYourself.ssn4 != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'SSN4');
    if (identifyYourself.ssn4 != '' && identifyYourself.ssn4 != undefined) {
      var encryptedSsn = Crypto.AES.encrypt(identifyYourself.ssn4, registrationConstants.registrationEncryption);
      uiOptionsMap.siv_ssn4 = encryptedSsn + '';
    }
    radioOptionValue = 'siv_ssn4';
    sivOptions.availableSivOptions.siv_ssn4.idenDataValue = identifyYourself.ssn4;
  }
  var inputFullMonthId = sivOptions.sivOptionsCalenderItems.monthItems.indexOf(identifyYourself.fullMonth) + 1;
  inputFullMonthId = ('0' + inputFullMonthId).slice(-2);
  var fulldob = '' + inputFullMonthId + identifyYourself.fullDay;

  if (sivOptions.availableSivOptions.siv_dateofbirth && identifyYourself.fullDay != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'DOB');
    uiOptionsMap.siv_dateofbirth = fulldob;
    radioOptionValue = 'siv_dateofbirth';
    sivOptions.availableSivOptions.siv_dateofbirth.idenDataValue = fulldob;
  }

  return ajax({
    url: constants.sendPicSivOptionsDetailsEndPoint,
    body: {
      'sivPIC': sivOptions.sivPIC,
      'radioOptionValue': radioOptionValue,
      'uiSivOptionOptions': uiOptionsMap
    },
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
};

const handleSetSivFieldFailResponse = (payload, errorMessage) => {
  switch (payload.fieldkey) {
    case constants.sivOptionIds.siv_firstName:
      return setSivFieldFailSent({
        errorMessageFN: errorMessage,
        firstName: payload.fieldValue,
        firstNameValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_lastName:
      return setSivFieldFailSent({
        errorMessageLN: errorMessage,
        lastName: payload.fieldValue,
        lastNameValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_employee_id:
      return setSivFieldFailSent({
        errorMessageEmpId: errorMessage,
        empId: payload.fieldValue,
        empIdValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_wfn_associate_id:
      return setSivFieldFailSent({
        errorMessageWfnId: errorMessage,
        wfnId: payload.fieldValue,
        wfnIdValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_last4_ssn:
      return setSivFieldFailSent({
        errorMessageSsn4: errorMessage,
        ssn4: payload.fieldValue,
        ssn4Valid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_ssn:
      return setSivFieldFailSent({
        errorMessageSsn: errorMessage,
        ssn: payload.fieldValue,
        ssnValid: false,
        sivOptionsValidated: false,
      });
    default: {
      return setSivFieldFailSent({
        errorMessage,
      });
    }
  }
};

const setSivField = (payload, getState) =>
  validateAndSetSivField(payload, getState)
    .pipe(map(setSivFieldSuccessSent),
      catchError(err => [handleSetSivFieldFailResponse(payload, getErrorMessage(err))]));

const handleSubmitSivResponse = (response, getState) => {
  const identifyYourself = getState.verifyPic;
  const gaCategory = identifyYourself.gaCategory;

  console.log('handleSubmitSivResponse:' + response)

  if (response.code != null && response.code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(gaCategory, 'VERIFY_PIC', response.code);
    
    return cancelAndCheckYourEntriesSent({
      errorCode : response.code
    })
  }

  if (response.viewId.indexOf(constants.ERROR) == -1) {
    PendoUtil.pendoIdentify(response.orgCd,getState.verifyPic.clientName,getState.registration.transactionId)
    PendoUtil.pendoVisitorIdUpdate(response.associateId)
    
    ReactGA.sendevent(gaCategory, 'VERIFY_PIC', 'SUCCESS');
    return verifySivOptionsSuccessSent({
      viewId: response.viewId
    });
  }

  if (response.code != null) {

    if (response.code == constants.INVALID_SIVDATA || response.code == constants.DUPLICATE_SSN4) {
      ReactGA.sendevent(gaCategory, 'VERIFY_PIC', response.code);
      return verifySivOptionsFailSent({
        showErrorModal: false,
        errorTitle: response.message,
        ssn4: '',
        ssn: ''
      });
    }
    if (response.code != null && (response.code == constants.EXCEEDED_ATTEMPTS || response.code == constants.EMAIL_NOT_EXISTS
      || response.code == constants.NO_SIV_DEFINED || response.code == constants.CLIENT_SETUP_ERROR || response.code == constants.ALREADY_REGISTERED_SUSPENDED)) {
      console.log('TRUE;response.code=' + response.code);
      ReactGA.sendevent(gaCategory, 'VERIFY_PIC', response.code);
      return verifySivOptionsFailSent({
        showErrorModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: '',
        ssn4: '',
        ssn: ''
      });
    }

    console.log('response.message=' + response.message);
    return verifySivOptionsFailSent({
      showErrorModal: false,
      errorTitle: response.message,
      ssn4: '',
      ssn: ''
    });
  }
};

const handleSubmitSivResponseFail = (response) => {
  return verifySivOptionsFailSent({
    showErrorModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    ssn4: '',
    ssn: ''
  });
};
const submitSivOptions = (ajax, getState) =>
  verifySivOptions(ajax, getState)
    .pipe(map(response => handleSubmitSivResponse(response.response, getState)),
      catchError(err => handleSubmitSivResponseFail(getErrorMessage(err))));

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const code = getState.verifyPic.errorCode;
  const organizationId = getState.registration.organizationId;
  if (code != null && code === constants.ALREADY_REGISTERED) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  }
  if (isMobile && code != constants.ALREADY_REGISTERED) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const handleAlreadyRegisteredResponse = (response) => {
  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});

/**********************Set language**********************/
const setLocale = (payload, getState) =>
  setLocaleApi(payload, getState)
    .pipe(map(response => setLocaleHandler(response.response, payload, getState)),
      catchError(err => [getPicSivOptionsFail(getErrorMessage(err))]));

const setLocaleApi = (payload, getState) => ajax({
  url: constants.setLocaleEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload.payload.suffix,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const setLocaleHandler = (response, payload, getState) => {
  if (response.code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_PIC_SET_LOCALE', 'SUCCESS');
    const updatedlocale = payload.payload.language;
    const localeResponse = getLocaleInitialState(payload.payload.suffix);
    getState.locale.locale = localeResponse.locale.locale;
    getState.intl.locale = localeResponse.intl.locale;
    getState.intl.messages = localeResponse.intl.messages;
    WindowUtil.setCookieValue(getState.locale.locale);
    let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
    return setUpdateLocaleSuccess({
      updatedlocale, progressBarData
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_PIC_SET_LOCALE', 'FAILED');
    return getPicSivOptionsFail();
  }

}


/**********************Pic Sivoptions**********************/
const getPicSivOptions = (ajax, getState) =>
  sivOptionsApi(ajax, getState.registration.transactionId)
    .pipe(map(response => { 
      const isMobile = getState.registration.mobileFlow;
      response.response.isMobile = isMobile;
       return handlePICResponse(getState, response.response) 
      }),
      catchError(err => [getPicSivOptionsFail(getErrorMessage(err))]));


export const getSivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(GET_PIC_SIV_OPTIONS),
  switchMap(action => sendingAsyncRequest(getSivOptions(ajax, state.value))));

export const setSivFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_PIC_SIV_FIELD),
  switchMap(action => sendingAsyncRequest(setSivField(action.payload, state.value))));

export const verifySivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_PIC_SIV_OPTIONS),
  switchMap(action => sendingAsyncRequest(submitSivOptions(ajax, state.value))));

export const getSivOptionsFailureEpic = action$ => action$.pipe(
  ofType(GET_PIC_SIV_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))));

export const verifySivOptionsSuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_PIC_SIV_OPTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.verifyPic.viewId))));

export const verifySivOptionsFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_PIC_SIV_OPTIONS_FAIL),
  switchMap(action => of()));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_PIC_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const setLocaleEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_PIC_SET_LOCALE),
  switchMap(action => sendingAsyncRequest(setLocale(action.payload, state.value))));

export const getPicSivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(GET_PIC_SIV_OPTIONS_LOCALE_UPDATE),
  switchMap(action => sendingAsyncRequest(getPicSivOptions(ajax, state.value))));