import React from 'react';
import { Textbox } from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from 'adp-react-icons/lib/fa/check-circle';
import { PasswordStrengthIndicator } from 'nexo-react-components';
import {
  PasswordRule,
  StrengthTextComponent,
  StrengthTextInfoPromptsComponent,
} from './password-rule-component';
import { minimumRequirements } from './password-strength-rules';
import { validatePassword } from './password-rules';

class PasswordIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      strengthText: 'passwordStrengthIndicator.min8char_1number_1Upper_1Lower_1Special',
      strengthTextInfoPrompts: 'passwordStrengthIndicator.nothing',
      strengthTextColor: '#4a4a4a',
      strengthBarColor: 'none',
      strengthWidth: 0,
      hasNoError: true,
    };
  }

  onPasswordChange = (password) => {
    const obj = validatePassword(password);

    this.setState({
      password,
      strengthText: `passwordStrengthIndicator.${obj.strengthTextVal}`,
      strengthTextInfoPrompts: `passwordStrengthIndicator.${obj.strengthTextInfoPromptsVal}`,
      strengthTextColor: obj.strengthTextColorVal,
      strengthBarColor: obj.strengthBarColorVal,
      strengthWidth: obj.strengthWidthVal,
      hasNoError: typeof obj.hasError === 'undefined',
    });
  };

  render() {
    const {
      password,
      strengthText,
      strengthTextInfoPrompts,
      strengthTextColor,
      strengthWidth,
      strengthBarColor,
      hasNoError,
    } = this.state;

    return (
      <div style={ { paddingTop: '20px' } }>
        <div>
          <div style={ { width: '250px', float: 'left' } }>
            <Textbox
              id="form_passwordIndicator"
              placeholder="Password indicator to be removed"
              type="password"
              value={ password }
              onChange={ this.onPasswordChange }
            />
          </div>
          <span
            className="vdl-password-strength__rule-icon"
            style={ { color: strengthTextColor, paddingLeft: '10px', paddingTop: '5px' } }
          >
            { hasNoError && minimumRequirements(password) && <CheckCircleIcon /> }
          </span>
        </div>
        <div style={ { maxWidth: '250px', paddingTop: '20px' } }>
          <PasswordStrengthIndicator
            id="passwordIndicator"
            strengthWidth={ strengthWidth }
            strengthBarColor={ strengthBarColor }
            rulesHeading=" "
          >
            <PasswordRule
              valid={ hasNoError }
              strengthTextColor={ strengthTextColor }
            >
              <StrengthTextComponent strengthTextColor={ strengthTextColor }>
                <FormattedMessage
                  id={ strengthText }
                  defaultMessage={ strengthText }
                  description="Strength Text"
                />
              </StrengthTextComponent>
              <StrengthTextInfoPromptsComponent>
                <FormattedMessage
                  id={ strengthTextInfoPrompts }
                  defaultMessage={ strengthTextInfoPrompts }
                  description="Strength Text Info Prompts"
                />
              </StrengthTextInfoPromptsComponent>
            </PasswordRule>
          </PasswordStrengthIndicator>
        </div>
      </div>
    );
  }
}

export default PasswordIndicator;
