import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-user-constants';
import { types } from '../verify-user-actions';
import { ReactGA } from '../../../../util';
import { WindowUtil,PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';
import { getLocaleInitialState } from '../../../../util/locale';

const { VERIFY_REG_CODE, VERIFY_REG_CODE_SUCCESS, VERIFY_REG_CODE_FAIL, CANCEL_REGISTRATION_VERIFYUSER, CHECK_ERROR_MESSAGE, GET_IDENTITY_OPTIONS_SUCCESS, VERIFY_USER_INVALID_PRC_SET_LOCALE, UPDATE_LOCALE_FIELD } = types;

const verifyRegCodeSuccess = createAction(VERIFY_REG_CODE_SUCCESS);
const verifyRegCodeFail = createAction(VERIFY_REG_CODE_FAIL);
const cancelRegistrationSent = createAction(CANCEL_REGISTRATION_VERIFYUSER);
const checkRegCodeErrMessageSent = createAction(CHECK_ERROR_MESSAGE);
const getIdentityOptionsSuccess = createAction(GET_IDENTITY_OPTIONS_SUCCESS);
const setUpdateLocaleSuccess = createAction(UPDATE_LOCALE_FIELD);

const isBlank = str => (str || '' || 'undefined').trim().length === 0;

const checkRegCodeErrMessage = (getState) =>
  checkRegCodeErr(getState)
    .pipe(map(response => handleMessageResponse(response, getState)),
    catchError(err => [handleMessageResponse(getErrorMessage(err))]));

const checkRegCodeErr = (getState) => {
  if (getState.registration.mobileFlow && getState.registration.message != '' && getState.registration.message != undefined) {
    getState.verifyUser.errorTitle = getState.registration.message;
    if (getState.registration.code != null && (getState.registration.code == constants.NO_SIV_DEFINED || getState.registration.code == constants.FUNCTION_LOCKED ||
      getState.registration.code == constants.EMAIL_NOT_EXISTS || getState.registration.code == constants.ALREADY_REGISTERED_MULTI ||
      getState.registration.code == constants.ALREADY_REGISTERED_SUSPENDED || getState.registration.code == constants.EXCEEDED_ATTEMPTS ||
      getState.registration.code == constants.ALREADY_REGISTERED) && getState.registration.title != undefined) {
      getState.verifyUser.errorTitle = '';
      getState.verifyUser.errorMsgTitle = getState.registration.title;
      getState.verifyUser.welcomeMsg = getState.registration.message;
      getState.verifyUser.showFailedModal = true;
      return of({
        showFailedModal: true,
        errorMsgTitle: getState.registration.title,
        welcomeMsg: getState.registration.message,
        errorTitle: '',
        code: getState.registration.code
      });
    } else {
      return of({
        errorTitle: getState.registration.message,
        code: getState.registration.code
      });
    }
  }
  if (getState.registration.code != null && getState.registration.code != undefined && getState.registration.code == constants.ALREADY_REGISTERED) {
    getState.verifyUser.errorTitle = '';
    getState.verifyUser.errorMsgTitle = getState.registration.title;
    getState.verifyUser.welcomeMsg = getState.registration.message;
    getState.verifyUser.showFailedModal = true;
    return of({
      showFailedModal: true,
      errorMsgTitle: getState.registration.title,
      welcomeMsg: getState.registration.message,
      errorTitle: '',
      code: constants.ALREADY_REGISTERED_DUAL
    });
  } else if (getState.registration.code != null && getState.registration.code != undefined && getState.registration.code == constants.INVALID_REGCODE) {
    getState.registration.code = '';
    return of({
      errorTitle: getState.registration.message,
      code: getState.registration.code
    });
  }

  return of({
    errorTitle: '',
    code: getState.registration.code
  });
}

const handleMessageResponse = (response, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_USER";
  let pagePath = '/ssr/flowType/verifyUserForm';
  let pageView = pagePath + "/web";
  let isFindMeFlow = getState.findMe.findMeObj.showDiv || getState.registration.isEligibleForCodelessFlow || false;
  getState.findMe.sendRedirect = false;
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_USER"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_USER"; }

  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  let hideFields = false;
  if (response.code != null && (response.code === constants.ALREADY_REGISTERED_DUAL || response.code === constants.PII_ERROR)) {
    hideFields = true;
  }
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Enter_Code, getState);
  // progress bar end
  if (response.showFailedModal == true && response.code != null && response.code == constants.ALREADY_REGISTERED_DUAL) {
    return verifyRegCodeFail({
      showFailedModal: true,
      errorMsgTitle: response.errorMsgTitle,
      welcomeMsg: response.welcomeMsg,
      code: response.code,
      hideFields: hideFields,
      isFindMeFlow,
      progressBarData
    });
  }
   if(response.code === constants.ALREADY_REGISTERED) {
        return cancelRegistrationSent ({
          code : getState.registration.code
        });
      }
  if (response.showFailedModal == true) {
    return verifyRegCodeFail({
      showFailedModal: true,
      errorMsgTitle: response.errorMsgTitle,
      welcomeMsg: response.welcomeMsg,
      errorTitle: '',
      isMobile,
      isMobileBrowser,
      gaCategory,
      hideFields: hideFields,
      isFindMeFlow,
      progressBarData
    });
  }
  return verifyRegCodeFail({
    showFailedModal: false,
    errorTitle: response.errorTitle,
    code: response.code,
    isMobile,
    isMobileBrowser,
    gaCategory,
    hideFields: hideFields,
    isFindMeFlow,
    progressBarData
  });
}

//verifyRegCodeSuccess
const verifyRegCode = (ajax, regCode, getState) =>
  verifyRegCodeApi(ajax, regCode, getState)
    .pipe(map(response => handleResponse(getState, response.response)),
    catchError(err => [handleVerifyRegCodeResponseFail(getState, getErrorMessage(err))]));

const verifyRegCodeApi = (ajax, regCode, getState) => {
  console.log('registration code latest ==' + regCode + getState.registration.transactionId);
  if (isBlank(regCode) || regCode == 'undefined' || regCode == undefined) {
    return throwError(new Error(getState.intl.messages['verifyUser.emptyRegCode']));
  }
  return ajax({
    url: constants.getVerifyRegCodeEndPoint,
    method: 'POST',
    responseType: 'json',
    body:  regCode,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId,
    }
  });
};

const handleResponse = (getState, response) => {
  let updatedlocale =  WindowUtil.getLocaleValue(getState.locale.locale);
  let gaCategory = getState.verifyUser.gaCategory;
  console.log('handleresponse=======>>' + response.viewId);
  let regCodeToFindMeFlow = false;
  if (response.viewId != 'Error') {
    ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'SUCCESS');
    // call SIV options and then return the viewId

    console.log('valid reg code recaptcha =======>>' + response.recaptchaOn);
    getState.registration.isRecaptchaOn = response.recaptchaOn;
    
    if(response.viewId !== undefined && response.viewId === constants.FINDME_VIEW) {
      getState.findMe.sendRedirect = false;
      regCodeToFindMeFlow = true;
      return getIdentityOptionsSuccess({
      viewId: constants.FINDME_VIEWID,
      regCodeToFindMeFlow,
      regCode: '',
      errorTitle: ''
    });
    }


    return verifyRegCodeSuccess({
      viewId: getState.verifyUser.viewId,
      sivViewId: getState.verifyUser.sivViewId,
      sivOptions: getState.verifyUser.sivOptions,
      showExternalVendorsPage: getState.verifyUser.showExternalVendorsPage,
      extVendorList: getState.verifyUser.extVendorList,
      companyName: getState.verifyUser.companyName,
      regCode:''
    });
  } else {
    if (response.code != '' && response.code === constants.EXCEEDED_ATTEMPTS) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'EXCEEDED_ATTEMPTS');
      return verifyRegCodeFail({
        showFailedModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: '',
        embeddedPrcInvalid: true
      });
    }
    if (response.code != '' && response.code === constants.INVALID_REGCODE) {
     const embeddedPrcFlow = getState.registration.embeddedPrcFlow || false;
     let embeddedPrcInvalidSuccess = false;
     if(embeddedPrcFlow) {
       console.log('Embedded prc invalid flow=======');
       embeddedPrcInvalidSuccess = true;
     }
      ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'INVALID_REGCODE');
      return verifyRegCodeFail({
        showFailedModal: false,
        errorTitle: response.message,
        embeddedPrcInvalid: true,
        updatedLocale: updatedlocale,
        embeddedPrcInvalidSuccess
      });
    }
    return verifyRegCodeFail({
      showFailedModal: true,
      errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
      welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
      errorTitle: '',
      embeddedPrcInvalid: true
    });
  }
};

const handleVerifyRegCodeResponseFail = (getState, errorMessage) => {
  console.log('handle verify regcode response fail response' + errorMessage);
  return verifyRegCodeFail({
    showFailedModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    errorTitle: ''
  });
};

{/************************************Siv Options call*******************************************/ }

const getSivOptions = (ajax, getState) =>
  sivOptionsApi(ajax, getState)
    .pipe(map(response => handleSivResponse(getState, response.response)),
    catchError(err => [handleSivResponseFail(getState, getErrorMessage(err))]));

const sivOptionsApi = (ajax, getState) => ajax({
  url: constants.getPicSivOptionsDetailsEndPoint,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    "cache-control": "no-cache",
    'X-TRANSACTIONID': getState.registration.transactionId,
  }
});

const handleSivResponse = (getState, response) => {
  console.log('handle siv response-----recaptcha:', response.recaptchaOn);
  if (response.viewId != 'Error') {
    if (getState.registration.orgIdForDeviceAssessment != ''
      && getState.registration.viewId != '/findMe'
      && response.viewId != '/verifyPic'
      && !getState.registration.isDeviceAssessmentDone) {
      WindowUtil.deviceAssessment(getState.registration.orgIdForDeviceAssessment, getState.registration.transactionId);
    }

    //Check regCodeData undefined and check showExternalVendorsPage flag
    if (response.regCodeData != undefined && response.regCodeData != '') {
      let viewId = response.viewId;

      if(response.runAdminV2Flow != undefined && response.runAdminV2Flow) {
          return getIdentityOptionsSuccess({
          viewId: viewId,
          sivViewId: response.viewId,
          sivOptions: response.regCodeData.sivOptions,
          runAdminFlow: response.runAdminFlow,
          runAdminAccountantConnectFlow: response.runAdminAccountantConnectFlow,
          runAdminV2Flow: response.runAdminV2Flow,
          firstName: response.firstName,
          lastName: response.lastName
        }); 
      }
      if (response.regCodeData.showExternalVendorsPage) {
        viewId = constants.chooseVendorsViewId;
      }
      if (response.recaptchaOn != undefined && response.recaptchaOn == true && response.regCodeData.sivOptions.sivPIC == false) {
        WindowUtil.recaptchaCall();
      }
    
      PendoUtil.pendoIdentify(response.orgCd,response.regCodeData.companyName,getState.registration.transactionId)
      PendoUtil.pendoVisitorIdUpdate(response.associateId)

      return getIdentityOptionsSuccess({
        viewId: viewId,
        sivViewId: response.viewId,
        sivOptions: response.regCodeData.sivOptions,
        showExternalVendorsPage: response.regCodeData.showExternalVendorsPage,
        extVendorList: response.regCodeData.extVendorList,
        companyName: response.regCodeData.companyName,
        isRecaptchaOn: response.recaptchaOn,
        runAdminFlow: response.runAdminFlow,
        runAdminAccountantConnectFlow: response.runAdminAccountantConnectFlow,
      });
    } else {
      console.log('BEV flow.');
      if (response.recaptchaOn != undefined && response.recaptchaOn == true) {
        WindowUtil.recaptchaCall();
      }
      return getIdentityOptionsSuccess({
        viewId: response.viewId,
        sivViewId: response.viewId,
        isRecaptchaOn: response.recaptchaOn
      });
    }
  } else {
    if (response.viewId == constants.ERROR && response.title != null && response.title != undefined) {
      return verifyRegCodeFail({
        showFailedModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: ''
      });
    }
    getState.verifyUser.errorTitle = response.message;
    return verifyRegCodeFail({
      errorTitle: response.message,
    });
  }
}
const handleSivResponseFail = (getState, errorMessage) => {
  console.log('handle siv response fail response', errorMessage);
  if (getState.verifyUser.errorTitle != '') {
    return verifyRegCodeFail({
      errorTitle: getState.verifyUser.errorTitle,
    });
  }
  return verifyRegCodeFail({
    showFailedModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    errorTitle: ''
  });
};

{/************************************Cancel Registration*******************************************/ }

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const code = getState.registration.code;
  const verifyUserCode = getState.verifyUser.code;
  const organizationId = getState.registration.organizationId;
  console.log('code==========' + getState.verifyUser.code);
  if (verifyUserCode != null && verifyUserCode === constants.ALREADY_REGISTERED_DUAL) {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
  if (code != null && code === constants.ALREADY_REGISTERED) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  }
  if (isMobile && code != constants.ALREADY_REGISTERED) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of common.js
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const handleAlreadyRegisteredResponse = (response) => {
  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});

/**********************Set language**********************/
const setLocale = (payload, getState) =>
  setLocaleApi(payload, getState)
    .pipe(map(response => setLocaleHandler(response.response, payload, getState)),
      catchError(err => [verifyRegCodeFail(getErrorMessage(err))]));

const setLocaleApi = (payload, getState) => ajax({
  url: constants.setLocaleEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload.payload.suffix,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const setLocaleHandler = (response, payload, getState) => {
  if (response.code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'REGCODE_SET_LOCALE', 'SUCCESS');
    const updatedLocale = payload.payload.language;
    const localeResponse = getLocaleInitialState(payload.payload.suffix);
    getState.locale.locale = localeResponse.locale.locale;
    getState.intl.locale = localeResponse.intl.locale;
    getState.intl.messages = localeResponse.intl.messages;
    let errorTitle = '';
    WindowUtil.setCookieValue(getState.locale.locale);
    let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Enter_Code, getState);
    return setUpdateLocaleSuccess({
      updatedLocale, errorTitle,
      progressBarData
    });
  }
  else {
    return verifyRegCodeFail({
      showFailedModal: true,
      errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
      welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
      errorTitle: ''
    });
  }

}

{/************************************Epics declaration*******************************************/ }
export const verifyRegCodeEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_REG_CODE),
  switchMap(action => sendingAsyncRequest(verifyRegCode(ajax, action.payload, state.value))));

export const verifyRegCodeSuccessEpic = (action$, state) => action$.pipe(
  ofType(GET_IDENTITY_OPTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.verifyUser.viewId))));

export const verifyRegCodeFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_REG_CODE_FAIL),
  switchMap(action => of()));

export const getSivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_REG_CODE_SUCCESS),
  switchMap(action => sendingAsyncRequest(getSivOptions(ajax, state.value))));

export const cancelRegistrationEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_REGISTRATION_VERIFYUSER),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))));

export const checkRegCodeErrMessageEpic = (action$, state) => action$.pipe(
  ofType(CHECK_ERROR_MESSAGE),
  switchMap(action => sendingAsyncRequest(checkRegCodeErrMessage(state.value))));

  export const setLocaleEpic = (action$, state) => action$.pipe(
    ofType(VERIFY_USER_INVALID_PRC_SET_LOCALE),
    switchMap(action => sendingAsyncRequest(setLocale(action.payload, state.value))));
