export const constants = {
  verify401kDetailsEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/verify401KIdentity',
  confirm401kDetailsEndPoint:'/pages/sms/ess/v3/ws/rest/pub/ssr/confirm401KIdentity',
  launch401kDetailsEndPoint:'/pages/sms/ess/v3/ws/rest/pub/ssr/launch401KIdentity',
  redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
  sivOptionIds: {
    siv_firstName: 0,
    siv_lastName: 1,
    siv_ssn: 2,
    siv_dob_month: 3,
    siv_dob_day: 4,
    siv_dob_year: 5, 
    siv_empid: 6,  
    siv_dob_full_month: 7,
    siv_dob_full_day: 8,
    siv_dob_full_year: 9, 
    siv_ssn4: 10,
    siv_zipcode: 11,
    siv_wfnid: 12 
  },
  EXCEEDED_ATTEMPTS:'EXCEEDED_ATTEMPTS',
  ALREADY_REGISTERED: 'ALREADY_REGISTERED',
  REDIRECT_FORGOT:'/pages/sms/ess/v3/pub/ssr/redirectToForgotFlow.jsp',
  FUNCTION_LOCKED:'FUNCTION_LOCKED',
  ERROR:'Error',
  monthWith31days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  monthWith30days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
  monthWith29days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
  EMAIL_NOT_EXISTS:'EMAIL_NOT_EXISTS',
  KBA_LOCKED:'KBA_LOCKED',
  SUCCESS:'SUCCESS',
  RECAPTCHA_FAILED:'RECAPTCHA_FAILED',
  PATH401KFLOW:'/ssr/401K/seachassociate',
  PATHVERIFY401K:'/ssr/flowType/401K',
  ALREADY_REGISTERED_MULTI:'ALREADY_REGISTERED_MULTI'  
};