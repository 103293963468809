export const constants = {
    validateMobileAndSSN: '/pages/sms/ess/v3/ws/rest/pub/ssr/admin/validateMobileAndSSN',
    TYPE_SUCCESS: 'SUCCESS',
    NON_UNIQUE_PHONE: 'NON_UNIQUE_MOBILE',
    INVALID_PHONE:'INVALID_MOBILE',
    MAX_ATTEMPTS_EXCEEDED: 'MAX_ATTEMPTS_EXCEEDED',
    PHONE_REGEX: '[0-9\\s-.()]{4,20}$',
    TYPE_ERROR_FAIL_ATTEMPTS: "Error_maxFailAttemptsExceeded",
    TYPE_ERROR_LOCKED: "Error_FunctionLocked",
    TYPE_ERROR_INVALID: "Error_SecCodeInvalid",
    TYPE_ERROR_EXPIRED: "Error_SecurityCodeExpired",
    TYPE_ERROR_INTERAL_ERROR: "Error_InternalError",
    TYPE_ERROR_FAILED: "Error_SendSecurityCodeFailed",
    SIV_SSN4:"ssn4",
    MOBILE:"mobile",
    OTP_CODE:"otp",
    getVerifyCodeAPI:'/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/adminVerifyCode',
    getGenerateCodeAPI:'/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/generateCode',
    TNC:"tnc",
    Err_VerifyIntegrationFailed:"Err_VerifyIntegrationFailed"
};

