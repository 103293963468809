import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Textbox,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import CancelIcon from 'adp-react-icons/lib/fa/times';
import { ReactGA } from '../../util';
import Stepper from '../../components/react-stepper-horizontal';
import { WindowUtil } from '../../util';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
let setAutoFocusOff;

class VerifyUserPinForm extends Component {

  static propTypes = {
    userPin: PropTypes.string,
    userPinValid: PropTypes.bool,
    setUserPin: PropTypes.func,
    verifyUserPin: PropTypes.func,
    errorTitle: PropTypes.string,
    isFormValid: PropTypes.bool,
    enableSubmitButton: PropTypes.func,
    showFailedModal: PropTypes.bool,
    welcomeMsg: PropTypes.string,
    errorMsgTitle: PropTypes.string,
    cancelRegistration: PropTypes.func,
    checkUserPinErrMessage: PropTypes.func
  };

  componentWillMount() {
    WindowUtil.setlayoutback(false);
    this.props.checkUserPinErrMessage();
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }

  componentWillReceiveProps = (nextProps) => {
    this.checkFormReadyness(nextProps);
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (isBlank(nextProps.userPin)) {
      formValid = false;
    }

    this.props.enableSubmitButton(formValid);
  };

  _handleKeyPress = (e) => {
    ReactGA.sendevent(this.props.gaCategory, 'VERIFY_USER_PIN', 'SUBMIT');
    e.preventDefault();
    this.props.verifyUserPin(this.props.userPin);
  }

  render() {
    console.log('rendering verify user pin form...');
    const {
      userPin,
      userPinValid,
      setUserPin,
      verifyUserPin,
      errorTitle,
      isFormValid,
      showFailedModal,
      welcomeMsg,
      errorMsgTitle,
      cancelRegistration,
      checkUserPinErrMessage,
      isMobileBrowser,
      isMobile,
      gaCategory,
      progressBarData
    } = this.props;

    return (
      <div className="registration-content">
        <div className="registration-form-container" >
          { 
              (window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
               }
              {
               (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
               }
          {(!isMobile)  && (
            <div id="verifyUserPin_cancel" className="reg-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
                ReactGA.sendevent(gaCategory, 'VERIFY_USER_PIN', 'CANCEL');
                return cancelRegistration()
              }}>
              <svg onClick={() => {
                ReactGA.sendevent(gaCategory, 'VERIFY_USER_PIN', 'CANCEL');
                return cancelRegistration()
              }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                }}>
                <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
              </svg>
            </div>
          )}
        </div>
        {progressBarData != undefined && progressBarData.showProgressBar && !isMobile && (
          <div className="registration-progress-bar">
            <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
          </div>
        )}
        {(!isMobile) && <hr className="header-hr" />}
        <div className="vdl-row registration-form">
          <div className="welcome-title title-width">
            <div className="vdl-col-md-12">
              <h1 id="registration-layout_welcome" className="page-title">{<FormattedMessage id="verifyUser.title" />}</h1>
            </div>
          </div>
          {errorTitle != undefined && errorTitle != '' && (<Alert
            type="error" style={{ width: '100%', marginLeft: '10%', marginRight: '10%' }}
          ><p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(errorTitle)}</p></Alert>
          )}
          <div className="vdl-col-md-12">
            <form className="adp-form padding-align-inc-width" onSubmit={this._handleKeyPress}>
              <div className="v-userid-form-div">
                <div className="vdl-row">
                  <div className="vdl-col-sm-12 vdl-col-xs-12" style={{ paddingBottom: '10px' }}>
                    <label htmlFor="registrationCd_Label" className="v-userid-label">
                      {<FormattedMessage id="verifyUserPin.enterPinText" />}
                    </label>
                  </div>
                </div>
                <div className="input-group-full">
                  <Textbox aria-label={this.props.intl.formatMessage({ id: 'verifyUserPin.enterPinText' })}
                    id="userPin_textBox"
                    className={userPinValid ? 'regcode-textbox' : 'vdl-validation-error regcode-textbox'}
                    value={userPin} autoFocus={setAutoFocusOff}
                    onChange={
                      (value) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "USERPIN_CODE" });
                        return setUserPin(value);
                      }
                    }
                  />

                </div>
                <div />
              </div>
         
              <div
                id="verifyUserPin-form_submit-button"
                className="form-group submit vdl-text-xs-center submit-button-align button-padding"
              >
                <Button type="submit" role="button"
                  id="verifyUserPin_continueBtn"
                  style={{ minHeight: "35px", minWidth: "180px" }}
                  className="primary submit-button-align safari-button-align" disabled={!isFormValid}
                >
                  {<FormattedMessage id="submitCBtn.Label" />}
                </Button>
              </div>

              {errorMsgTitle && (<Modal id="errorModal"
                className="vdl-modal--alert"
                show={showFailedModal}
                onEnter={() => { WindowUtil.applyBrandingChanges(); }}
              >
                <ModalHeader closeButton={false} className="modal-header">
                  <ModalTitle className="modal-title-error">
                    <WarnIcon className="vdl-modal__title-icon warn-icon-error"
                    />
                    {errorMsgTitle}
                  </ModalTitle>
                </ModalHeader>
                <ModalBody className="modal-body-footercolor">
                  <div>
                    <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                  </div>
                </ModalBody>
                <ModalFooter className="modal-body-footercolor">
                  <Button id="verifyUPin_okBtn" onClick={cancelRegistration} className="safari-button-align">
                    {<FormattedMessage id="Lbl_OkButton" />}
                  </Button>
                </ModalFooter>
              </Modal>)}

            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(VerifyUserPinForm);
