import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,debounceTime
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { types } from '../register-user-actions';
import { constants } from '../register-user-constants';
import { ReactGA, WindowUtil, PendoUtil  } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  GET_USER_ID_OPTIONS,
  GET_USER_ID_OPTIONS_SUCCESS, GET_USER_ID_OPTIONS_FAIL,
  SET_REGISTER_OPTIONS_FIELD,
  SET_REGISTER_OPTIONS_FIELD_SUCCESS, SET_REGISTER_OPTIONS_FIELD_FAIL,
  VERIFY_REGISTER_OPTIONS,
  VERIFY_REGISTER_OPTIONS_SUCCESS, VERIFY_REGISTER_OPTIONS_FAIL,
  ENABLE_REGISTER_BUTTON,
  SET_QUESTIONS_FIELD,
  SET_QUESTIONS_FIELD_SUCCESS, SET_QUESTIONS_FIELD_FAIL,
  SET_ANSWERS_FIELD,
  SET_ANSWERS_FIELD_SUCCESS, SET_ANSWERS_FIELD_FAIL,
  VERIFY_SECURITY_QUESTIONS,
  VERIFY_SECURITY_QUESTIONS_SUCCESS, VERIFY_SECURITY_QUESTIONS_FAIL,
  ENABLE_SECURITY_QA_BUTTON,
  CHECK_USERID_AVAILABILITY,
  CHECK_USERID_AVAILABILITY_SUCCESS, CHECK_USERID_AVAILABILITY_FAIL,
  CANCEL_REGISTRATION
} = types;

const isBlank = str => (str || '').trim().length === 0;

const comparePasswordFields = (otherVal, enteredVal) => {
  let result = true;
  if (otherVal == undefined || otherVal == '' || enteredVal == '') {
    return result;
  }

  if (enteredVal.length > 1 && otherVal.length > 1 && enteredVal.length > otherVal.length) {
    result = false;
    console.log('length mismatch result=======================>' + result);
    return result;
  }

  for (var i = 0; i < enteredVal.length; i++) {
    if (otherVal[i] != enteredVal[i]) {
      result = false;
      break;
    }
  }
  console.log('result=======================>' + result);
  return result;
}

const getUserIdOptionsSuccess = createAction(GET_USER_ID_OPTIONS_SUCCESS);
const getUserIdOptionsFail = createAction(GET_USER_ID_OPTIONS_FAIL);
const setRegisterOptionsFieldSuccessSent = createAction(SET_REGISTER_OPTIONS_FIELD_SUCCESS);
const setRegisterOptionsFieldFailSent = createAction(SET_REGISTER_OPTIONS_FIELD_FAIL);
const verifyRegisterOptionsSuccessSent = createAction(VERIFY_REGISTER_OPTIONS_SUCCESS);
const verifyRegisterOptionsFailSent = createAction(VERIFY_REGISTER_OPTIONS_FAIL);

const setQuestionsFieldSuccessSent = createAction(SET_QUESTIONS_FIELD_SUCCESS);
const setQuestionsFieldFailSent = createAction(SET_QUESTIONS_FIELD_FAIL);
const setAnswersFieldSuccessSent = createAction(SET_ANSWERS_FIELD_SUCCESS);
const setAnswersFieldFailSent = createAction(SET_ANSWERS_FIELD_FAIL);
const verifySecurityQuestionsSuccessSent = createAction(VERIFY_SECURITY_QUESTIONS_SUCCESS);
const verifySecurityQuestionsFailSent = createAction(VERIFY_SECURITY_QUESTIONS_FAIL);
const enableSecurityQAButtonSent = createAction(ENABLE_SECURITY_QA_BUTTON);

const checkUserIdAvailableSuccessSent = createAction(CHECK_USERID_AVAILABILITY_SUCCESS);
const checkUserIdAvailableFailSent = createAction(CHECK_USERID_AVAILABILITY_FAIL);

const cancelRegistrationSent = createAction(CANCEL_REGISTRATION);
const handleUserIdOptionsResponse = (response, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_REGISTER_USER";
  let pagePath = '/ssr/cpc/registration';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_REGISTER_USER"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_REGISTER_USER"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Create_Account, getState);
  // progress bar end
  let title = getState.intl.messages['r_registerUser.title'];
  let replacedTitle = title.replace("{0}", response.firstNameForTitle);
  let pageInfo = getState.intl.messages['r_registerUser.pageInfo'];
  let replacedPageInfo = pageInfo.replace("{0}", '<b>' + response.companyName + '</b>');
  const passwordIndicatorToggle = response.passwordRuleFlag;

  return getUserIdOptionsSuccess({
    passwordIndicatorToggle,
    title: replacedTitle,
    userIdType: response.userIdType,
    userIdLabelName: response.userIdLabelName,
    passwordLabelName: response.passwordLabelName,
    confirmPasswordLabelName: response.confirmPasswordLabelName,
    showSecurityQuestions: false,
    showTc: response.showTc,
    userIdValue: response.userIdValue,
    userLastName: response.lastName,
    companyName: replacedPageInfo,
    mobileFlow: getState.registration.mobileFlow,
    isMobile,
    isMobileBrowser,
    gaCategory,
    progressBarData
  });
}

{/************************************Get UserId and Password options*******************************************/ }
const getUserIdOptions = (ajax, getState) =>
  userIdOptionsApi(ajax, getState.registration.transactionId).pipe(
    map(response => handleUserIdOptionsResponse(response.response, getState)),
    catchError(err => [getUserIdOptionsFail(getErrorMessage(err))])
  );

const userIdOptionsApi = (ajax, transactionId) => ajax({
  url: constants.getuserIdOptionsEndPoint,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': transactionId,
  }
});

{/************************************set the UserId and Password options*******************************************/ }

const setRegisterOptionsField = (payload, getState) =>
  validateAndSetRegisterField(payload, getState)
    .pipe(map(setRegisterOptionsFieldSuccessSent),
    catchError(err => [handleSetRegisterFieldFailResponse(payload, getErrorMessage(err), getState)]));

const validateAndSetRegisterField = (payload, getState) => {
  const registerUser = Object.assign({}, getState.registerUser);
  switch (payload.fieldkey) {
    case constants.registerOptionIds.userid: {
      const userIdRegex = new RegExp(registerUser.userIdRegExp);

      if (isBlank(payload.fieldValue) || userIdRegex.test(payload.fieldValue)) {
        return of({
          userId: payload.fieldValue,
          userIdValid: true,
          availableUserResponse: ''
        });
      }
      return throwError(new Error(registerUser.userIdInvalidMessage));
    }
    case constants.registerOptionIds.partialuserid: {
      const userIdRegex = new RegExp(registerUser.userIdRegExp);

      if (isBlank(payload.fieldValue) || userIdRegex.test(payload.fieldValue)) {
        return of({
          partialUserId: payload.fieldValue,
          partialUserIdValid: true,
          availableUserResponse: ''
        });
      }
      return throwError(new Error(registerUser.userIdInvalidMessage));
    }
    case constants.registerOptionIds.password: {
      const pswRegex = new RegExp(registerUser.passwordRegExp);

      if (!comparePasswordFields(registerUser.confirmpassword, payload.fieldValue)) {
        return throwError(new Error(getState.intl.messages['registerUser.passwordMismatch']));
      }
      if (isBlank(payload.fieldValue) || pswRegex.test(payload.fieldValue)) {
        return of({
          password: payload.fieldValue,
          passwordValid: true,
          pswerrorMessage: '',
          confirmpswerrorMessage: '',
          confirmpasswordValid: true
        });
      }
      return throwError(new Error(registerUser.passwordInvalidMessage));
    }
    case constants.registerOptionIds.confirmpassword: {
      const confirmpswRegex = new RegExp(registerUser.confirmPasswordRegExp);

      if (!comparePasswordFields(registerUser.password, payload.fieldValue)) {
        return throwError(new Error(getState.intl.messages['registerUser.passwordMismatch']));
      }
      if (isBlank(payload.fieldValue) || confirmpswRegex.test(payload.fieldValue)) {
        return of({
          confirmpassword: payload.fieldValue,
          confirmpasswordValid: true,
          pswerrorMessage: '',
          confirmpswerrorMessage: ''
        });
      }
      return throwError(new Error(registerUser.confirmPasswordInvalidMessage));
    }
    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};

const handleSetRegisterFieldFailResponse = (payload, errorMessage, getState) => {
  switch (payload.fieldkey) {
    case constants.registerOptionIds.userid:
      return setRegisterOptionsFieldFailSent({
        uiderrorMessage: errorMessage,
        userId: payload.fieldValue,
        userIdValid: false
      });
    case constants.registerOptionIds.partialuserid:
      return setRegisterOptionsFieldFailSent({
        partialuiderrorMessage: errorMessage,
        partialUserId: payload.fieldValue,
        partialUserIdValid: false
      });
    case constants.registerOptionIds.password: {
      if (errorMessage != null && errorMessage == getState.intl.messages['registerUser.passwordMismatch']) {
        return setRegisterOptionsFieldFailSent({
          pswerrorMessage: errorMessage,
          password: payload.fieldValue,
          passwordValid: false,
          confirmpswerrorMessage: ''
        });
      }
      return setRegisterOptionsFieldFailSent({
        pswerrorMessage: errorMessage,
        password: payload.fieldValue,
        passwordValid: false
      });
    }
    case constants.registerOptionIds.confirmpassword: {
      if (errorMessage != null && errorMessage == getState.intl.messages['registerUser.passwordMismatch']) {
        return setRegisterOptionsFieldFailSent({
          pswerrorMessage: '',
          confirmpswerrorMessage: errorMessage,
          confirmpassword: payload.fieldValue,
          confirmpasswordValid: false
        });
      }
      return setRegisterOptionsFieldFailSent({
        confirmpswerrorMessage: errorMessage,
        confirmpassword: payload.fieldValue,
        confirmpasswordValid: false
      });
    }
    default: {
      return setRegisterOptionsFieldFailSent({
        errorMessage,
      });
    }
  }
};

{/************************************check User Availability*******************************************/ }

const submitUserId = (ajax, getState) =>
  checkUserIdAvailability(ajax, getState)
    .pipe(map(response => handleSubmitUserIdResponse(getState, response.response)),
    catchError(err => [handleSubmitUserIdResponseFail(getErrorMessage(err))]));

const checkUserIdAvailability = (ajax, getState) => {
  const registerUser = getState.registerUser;
  let userId = '';
  if (registerUser.userIdType === constants.fullUserIdType) {
    userId = registerUser.userId;
  }
  if (registerUser.userIdType === constants.partialUserIdType && registerUser.partialUserId != '') {
    userId = registerUser.partialUserId + registerUser.userIdValue;
  }
  registerUser.finalUserIdValue = userId;
  var encryptedUID = Crypto.AES.encrypt(registerUser.finalUserIdValue, registrationConstants.registrationEncryption);
  if (userId != '' && registerUser.availableUserResponse === '') {
    return ajax({
      url: constants.checkuserIdAvailabilityEndPoint,
      body: {
        'userIdType': registerUser.userIdType,
        'userIdValue': (encryptedUID + '')
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'X-TRANSACTIONID': getState.registration.transactionId,
      }
    });
  }
  return of({
    userId: userId
  })
}

const handleSubmitUserIdResponse = (getState, response) => {
  const registerUser = getState.registerUser;
  let userId = '';
  if (registerUser.userIdType === constants.fullUserIdType) {
    userId = registerUser.userId;
  }
  if (registerUser.userIdType === constants.partialUserIdType) {
    userId = registerUser.partialUserId;
  }
  if (registerUser.availableUserResponse === constants.invalidUserId) {
    return checkUserIdAvailableSuccessSent({
      availableUserResponse: registerUser.availableUserResponse,
      userIdValid: false,
      partialUserIdValid: false,
      uiderrorMessage: '',
      partialuiderrorMessage: '',
      fetchedUserId:userId
    });
  }
  if (registerUser.availableUserResponse === constants.validUserId) {
    return checkUserIdAvailableSuccessSent({
      availableUserResponse: registerUser.availableUserResponse,
      userIdValid: true,
      partialUserIdValid: true,
      uiderrorMessage: '',
      partialuiderrorMessage: '',
      errorTitle: '',
      fetchedUserId:userId
    });
  }
  if (userId === '') {
    return checkUserIdAvailableSuccessSent({
      availableUserResponse: '',
      userIdValid: true,
      partialUserIdValid: true,
      uiderrorMessage: '',
      partialuiderrorMessage: '',
      fetchedUserId:userId
    });
  }
  if ((response.code != null && response.code === constants.validUserId) || registerUser.availableUserResponse === constants.validUserId) {
    return checkUserIdAvailableSuccessSent({
      availableUserResponse: response.code,
      userIdValid: true,
      partialUserIdValid: true,
      errorTitle: '',
      fetchedUserId:userId
    });
  }
  if (response.code != null && response.code === constants.invalidUserId) {
    return checkUserIdAvailableSuccessSent({
      availableUserResponse: response.code,
      userIdValid: false,
      partialUserIdValid: false,
      uiderrorMessage: '',
      partialuiderrorMessage: '',
      fetchedUserId:userId
    });
  }
  if (response.title !== null && response.message !== null) {
    return checkUserIdAvailableFailSent({
      showFailedModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorTitle: '',
    });
  }
  return checkUserIdAvailableFailSent({
    errorTitle: constants.TROUBLE_SHOOT_MSG
  });
}

const handleSubmitUserIdResponseFail = (errorMessage) => {
  console.log('handle submit userId fail response' + errorMessage);
  return checkUserIdAvailableFailSent({
    errorTitle: errorMessage,
  });
};

{/************************************Submit userId and password fields*******************************************/ }

const submitRegisterOptions = (ajax, getState) =>
  verifyRegisterOptions(ajax, getState)
    .pipe(map(response => handleSubmitRegisterResponse(getState, response.response)),
    catchError(err => [handleSubmitRegisterResponseFail(getState, getErrorMessage(err))]));

const verifyRegisterOptions = (ajax, getState) => {
  const registerUser = getState.registerUser;
  // verify the userId and psw fields
  let agreedTnC = false;

  if (registerUser.showTc === true && registerUser.agreeTnC === true) {
    agreedTnC = registerUser.agreeTnC;
  }
  if (registerUser.userIdType === constants.sysgeneratedUserIdType) {
    registerUser.finalUserIdValue = registerUser.userIdValue;
  }
  if (registerUser.password === registerUser.confirmpassword) {
    var encryptedPW = Crypto.AES.encrypt(registerUser.password, registrationConstants.registrationEncryption);
    var encryptedUID = Crypto.AES.encrypt(registerUser.finalUserIdValue, registrationConstants.registrationEncryption);

    return ajax({
      url: constants.postUserIdPswOptionsEndPoint,
      body: {
        'userIdType': registerUser.userIdType,
        'userIdValue': (encryptedUID + ''),
        'passwordValue': (encryptedPW + ''),
        'confirmPasswordValue': (encryptedPW + ''),
        'userAgreedTc': agreedTnC
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'X-TRANSACTIONID': getState.registration.transactionId,
      }
    });

  }
  if (registerUser.password !== registerUser.confirmpassword) {
    return throwError(new Error(getState.intl.messages['registerUser.passwordMismatch']));
  }
};

const handleSubmitRegisterResponse = (getState, response) => {
  const isMobile = getState.registration.mobileFlow;
  const gaCategory = getState.registerUser.gaCategory;
  if (response.viewId.indexOf(constants.ERROR) == -1 && response.viewId === constants.securityQnA) {
    let pagePath = '/ssr/cpc/securityQna';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)
    return verifyRegisterOptionsSuccessSent({
      showSecurityQuestions: true,
      title: getState.intl.messages['r_securityQA.title'],
      securityQnATextList: response.securityQnATextList,
      availableUserResponse: '',
      securityQnAIdList: response.securityQnAIdList,
      secondAnsValid: true,
      thirdAnsValid: true,
      firstAnsValid: true,
      errorTitle: ''
    });
  }
  if (response.viewId.indexOf(constants.ERROR) == -1 && response.viewId === constants.confirmationPage) {
    ReactGA.sendevent(gaCategory, 'REGISTER_USER', 'REGISTER_USER_SUCCESS');
    const isMobile = getState.registration.mobileFlow;
    if (isMobile) {
      console.log('Entered Mobile flow');
      saveMobileUserIdInContainer(getState.registerUser.finalUserIdValue);
      closeMobileContainer(); //this is part of common.js
      return true;
    } else {
      return verifyRegisterOptionsSuccessSent({
        viewId: response.viewId
      });
    }
  }
  if (response.code == constants.INVALID_REG_DATA) {
    ReactGA.sendevent(gaCategory, 'REGISTER_USER', response.code);
    return verifyRegisterOptionsFailSent({
      showFailedModal: false,
      errorTitle: response.message,
      pswerrorMessage: '',
      passwordValid: false,
      confirmpasswordValid: false,
      pswerrorMessage: response.errFieldsMap.password
    });
  }
  if (response.code == constants.REGISTRATION_FAILED || response.code == constants.CLIENT_SETUP_ERROR || (response.title !== null && response.message !== null)) {
    ReactGA.sendevent(gaCategory, 'REGISTER_USER', response.code);
    return verifyRegisterOptionsFailSent({
      showFailedModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorTitle: '',
    });
  }
  return verifyRegisterOptionsFailSent({
    showFailedModal: false,
    errorTitle: response.message,
    pswerrorMessage: '',
    passwordValid: false,
    confirmpasswordValid: false
  });
}

const handleSubmitRegisterResponseFail = (getState, errorMessage) => {
  console.log('handle submit register fail response' + errorMessage);
  const registerUser = getState.registerUser;
  if (registerUser.password !== registerUser.confirmpassword) {
    return verifyRegisterOptionsFailSent({
      pswerrorMessage: errorMessage,
      password: '',
      confirmpassword: '',
      passwordValid: false,
      confirmpasswordValid: false,
      availableUserResponse: ''
    });
  }
  return verifyRegisterOptionsFailSent({
    showFailedModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    pswerrorMessage: '',
    availableUserResponse: ''
  });
};

{/************************************Security Questions  and Answers*******************************************/ }

const setQuestionsField = (payload, getState) =>
  validateAndSetQuestionField(payload, getState)
    .pipe(map(setQuestionsFieldSuccessSent),
    catchError(setQuestionsFieldFailSent));

const validateAndSetQuestionField = (payload, getState) => {
  const registerUser = Object.assign({}, getState.registerUser);
  switch (payload.fieldkey) {
    case constants.registerOptionIds.firstquestion: {
      return of({
        firstQuestion: payload.fieldValue,
        firstAnswer: ''
      });
    }
    case constants.registerOptionIds.secondquestion: {
      return of({
        secondQuestion: payload.fieldValue,
        secondAnswer: ''
      });
    }
    case constants.registerOptionIds.thirdquestion: {
      return of({
        thirdQuestion: payload.fieldValue,
        thirdAnswer: ''
      });
    }
    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};


const setAnswersField = (payload, getState) =>
  validateAndSetAnswersField(payload, getState)
    .pipe(map(setAnswersFieldSuccessSent),
    catchError(err => [handleSetAnswersFieldFailResponse(payload, getErrorMessage(err))]));

const validateAndSetAnswersField = (payload, getState) => {
  const registerUser = Object.assign({}, getState.registerUser);
  switch (payload.fieldkey) {
    case constants.registerOptionIds.firstanswer: {
      const ansRegex = new RegExp(constants.SecurityAnsRegex);
      if (isBlank(payload.fieldValue) || !ansRegex.test(payload.fieldValue)) {
        return of({
          firstAnswer: payload.fieldValue,
          firstAnsValid: true,
          firstAnsInlineErrMessage: ''
        });
      }
      return throwError(new Error(getState.intl.messages['securityQA.invalidAnsText']));
    }
    case constants.registerOptionIds.secondanswer: {
      const ansRegex = new RegExp(constants.SecurityAnsRegex);

      if (isBlank(payload.fieldValue) || !ansRegex.test(payload.fieldValue)) {
        return of({
          secondAnswer: payload.fieldValue,
          secondAnsValid: true,
          secondAnsInlineErrMessage: ''
        });
      }
      return throwError(new Error(getState.intl.messages['securityQA.invalidAnsText']));
    }
    case constants.registerOptionIds.thirdanswer: {
      const ansRegex = new RegExp(constants.SecurityAnsRegex);

      if (isBlank(payload.fieldValue) || !ansRegex.test(payload.fieldValue)) {
        return of({
          thirdAnswer: payload.fieldValue,
          thirdAnsValid: true,
          thirdAnsInlineErrMessage: ''
        });
      }
      return throwError(new Error(getState.intl.messages['securityQA.invalidAnsText']));
    }
    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};

const handleSetAnswersFieldFailResponse = (payload, errorMessage) => {
  switch (payload.fieldkey) {
    case constants.registerOptionIds.firstanswer:
      return setAnswersFieldFailSent({
        firstAnsErrMessage: errorMessage,
        firstAnswer: payload.fieldValue,
        firstAnsValid: false
      });
    case constants.registerOptionIds.secondanswer:
      return setAnswersFieldFailSent({
        secondAnsErrMessage: errorMessage,
        secondAnswer: payload.fieldValue,
        secondAnsValid: false
      });
    case constants.registerOptionIds.thirdanswer:
      return setAnswersFieldFailSent({
        thirdAnsErrMessage: errorMessage,
        thirdAnswer: payload.fieldValue,
        thirdAnsValid: false
      });
    default: {
      return setAnswersFieldFailSent({
        errorMessage,
      });
    }
  }
};

{/************************************Submit Security Questions  and Answers*******************************************/ }

const submitSecurityQuestions = (ajax, getState) =>
  verifySecurityQuestions(ajax, getState)
    .pipe(map(response => handleRegistrationFinalResponse(getState, response.response)),
    catchError(err => [handleSubmitSecurityQuestionsResponseFail(getState, getErrorMessage(err))]));

const verifySecurityQuestions = (ajax, getState) => {
  const registerUser = getState.registerUser;
  const firstIndex = registerUser.securityQnATextList.indexOf(registerUser.firstQuestion);
  const secondIndex = registerUser.securityQnATextList.indexOf(registerUser.secondQuestion);
  const thirdIndex = registerUser.securityQnATextList.indexOf(registerUser.thirdQuestion);

  if (registerUser.firstAnswer.toUpperCase() === registerUser.secondAnswer.toUpperCase() &&
    registerUser.firstAnswer.toUpperCase() === registerUser.thirdAnswer.toUpperCase()) {
    return throwError(new Error(getState.intl.messages['securityQA.uniqueAnswerText']));
  }

  // verify the security question and answers
  if ((registerUser.firstAnswer.toUpperCase() === registerUser.secondAnswer.toUpperCase()) ||
    (registerUser.firstAnswer.toUpperCase() === registerUser.thirdAnswer.toUpperCase()) ||
    (registerUser.secondAnswer.toUpperCase() === registerUser.thirdAnswer.toUpperCase())) {
    return throwError(new Error(getState.intl.messages['securityQA.uniqueAnswerText']));
  }

  if (registerUser.showSecurityQuestions === true) {
    var encryptedAnswer1 = Crypto.AES.encrypt(registerUser.firstAnswer, registrationConstants.registrationEncryption);
    var encryptedAnswer2 = Crypto.AES.encrypt(registerUser.secondAnswer, registrationConstants.registrationEncryption);
    var encryptedAnswer3 = Crypto.AES.encrypt(registerUser.thirdAnswer, registrationConstants.registrationEncryption);
    var encryptedPassword = Crypto.AES.encrypt(registerUser.password, registrationConstants.registrationEncryption);
    var encryptedUID = Crypto.AES.encrypt(registerUser.finalUserIdValue, registrationConstants.registrationEncryption);
    return ajax({
      url: constants.postUserIdPswOptionsEndPoint,
      body: {
        'userIdValue': (encryptedUID + ''),
        'passwordValue': (encryptedPassword + ''),
        'securityQnAResp': {
          'questionText1': registerUser.firstQuestion,
          'questionId1': registerUser.securityQnAIdList[firstIndex],
          'answer1': (encryptedAnswer1 + ''),
          'questionText2': registerUser.secondQuestion,
          'questionId2': registerUser.securityQnAIdList[secondIndex],
          'answer2': (encryptedAnswer2 + ''),
          'questionText3': registerUser.thirdQuestion,
          'questionId3': registerUser.securityQnAIdList[thirdIndex],
          'answer3': (encryptedAnswer3 + '')
        },
        'userAgreedTc': registerUser.agreeTnC
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        'X-TRANSACTIONID': getState.registration.transactionId,
      }
    });
  }
};

const handleRegistrationFinalResponse = (getState, response) => {
  const gaCategory = getState.registerUser.gaCategory;
  
  if (response.code == constants.REGISTRATION_COMPLETE || response.viewId === constants.confirmationPage) {
    ReactGA.sendevent(gaCategory, 'REGISTER_USER', 'Q_AND_A_SUCCESS');
    const isMobile = getState.registration.mobileFlow;
    if (isMobile) {
      console.log('Entered Mobile flow');
      saveMobileUserIdInContainer(getState.registerUser.finalUserIdValue);
      closeMobileContainer(); //this is part of common.js
      return true;
    }
    return verifySecurityQuestionsSuccessSent({
      viewId: response.viewId
    });

  }
  else if (response.code == constants.INVALID_REG_DATA) {
    ReactGA.sendevent(gaCategory, 'REGISTER_USER', response.code);
    let firstAnswerErr = '', secondAnswerErr = '', thirdAnswerErr = '', firstAnswerValid = true, secondAnswerValid = true, thirdAnswerValid = true;
    if (response.errFieldsMap != null && response.errFieldsMap != undefined) {
      if (response.errFieldsMap.answer1 != null && response.errFieldsMap.answer1 != undefined) {
        firstAnswerErr = response.errFieldsMap.answer1;
        firstAnswerValid = false;
      }
      if (response.errFieldsMap.answer2 != null && response.errFieldsMap.answer2 != undefined) {
        secondAnswerErr = response.errFieldsMap.answer2;
        secondAnswerValid = false;
      }
      if (response.errFieldsMap.answer3 != null && response.errFieldsMap.answer3 != undefined) {
        thirdAnswerErr = response.errFieldsMap.answer3;
        thirdAnswerValid = false;
      }
    }
    return verifySecurityQuestionsFailSent({
      showFailedModal: false,
      errorTitle: response.message,
      firstAnsInlineErrMessage: firstAnswerErr,
      secondAnsInlineErrMessage: secondAnswerErr,
      thirdAnsInlineErrMessage: thirdAnswerErr,
      firstAnsValid: firstAnswerValid,
      secondAnsValid: secondAnswerValid,
      thirdAnsValid: thirdAnswerValid,
      firstAnsErrMessage: '',
      secondAnsErrMessage: '',
      thirdAnsErrMessage: ''
    });
  }
  else if (response.code == constants.REGISTRATION_FAILED || response.code == constants.CLIENT_SETUP_ERROR || (response.title !== null && response.message !== null)) {
    ReactGA.sendevent(gaCategory, 'REGISTER_USER', response.code);
    return verifySecurityQuestionsFailSent({
      showFailedModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorTitle: '',
    });
  }
};

const handleSubmitSecurityQuestionsResponseFail = (getState, errorMessage) => {
  console.log('handle submit security QA fail response' + errorMessage);
  const registerUser = getState.registerUser;
  if (registerUser.firstAnswer.toUpperCase() === registerUser.secondAnswer.toUpperCase()
    && registerUser.firstAnswer.toUpperCase() === registerUser.thirdAnswer.toUpperCase()) {
    return verifySecurityQuestionsFailSent({
      firstAnsInlineErrMessage: errorMessage,
      firstAnsValid: false,
      secondAnsInlineErrMessage: errorMessage,
      secondAnsValid: false,
      thirdAnsInlineErrMessage: errorMessage,
      thirdAnsValid: false,
      firstAnsErrMessage: '',
      secondAnsErrMessage: '',
      thirdAnsErrMessage: ''
    });
  }
  if (registerUser.firstAnswer.toUpperCase() === registerUser.secondAnswer.toUpperCase()) {
    return verifySecurityQuestionsFailSent({
      firstAnsInlineErrMessage: errorMessage,
      firstAnsValid: false,
      secondAnsInlineErrMessage: errorMessage,
      secondAnsValid: false,
      firstAnsErrMessage: '',
      secondAnsErrMessage: '',
    });
  }
  if (registerUser.firstAnswer.toUpperCase() === registerUser.thirdAnswer.toUpperCase()) {
    return verifySecurityQuestionsFailSent({
      firstAnsInlineErrMessage: errorMessage,
      firstAnsValid: false,
      thirdAnsInlineErrMessage: errorMessage,
      thirdAnsValid: false,
      firstAnsErrMessage: '',
      thirdAnsErrMessage: '',
    });
  }
  if (registerUser.secondAnswer.toUpperCase() === registerUser.thirdAnswer.toUpperCase()) {
    return verifySecurityQuestionsFailSent({
      thirdAnsInlineErrMessage: errorMessage,
      thirdAnsValid: false,
      secondAnsInlineErrMessage: errorMessage,
      secondAnsValid: false,
      secondAnsErrMessage: '',
      thirdAnsErrMessage: ''
    });
  }

  return verifySecurityQuestionsFailSent({
    errorTitle: errorMessage
  });
};

{/************************************Cancel Registration*******************************************/ }

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId;
  if (isMobile) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of common.js
    return true;
  } else {
    WindowUtil.setUserIdCookieValue(getState.registerUser.finalUserIdValue);
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

{/************************************Epics declarations*******************************************/ }
export const getUserIdOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(GET_USER_ID_OPTIONS),
  switchMap(action => sendingAsyncRequest(getUserIdOptions(ajax, state.value))));

export const setRegisterFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_REGISTER_OPTIONS_FIELD),
  switchMap(action => sendingAsyncRequest(setRegisterOptionsField(action.payload, state.value))));

export const verifyRegisterOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_REGISTER_OPTIONS),
  switchMap(action => sendingAsyncRequest(submitRegisterOptions(ajax, state.value))));

export const getUserIdOptionsFailureEpic = action$ => action$.pipe(
  ofType(GET_USER_ID_OPTIONS_FAIL), switchMap(action$ => of(push('/error'))));

export const verifyRegisterOptionsSuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_REGISTER_OPTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.registerUser.viewId))));

export const verifySecurityQuestionsSuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_SECURITY_QUESTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.registerUser.viewId))));

export const verifyRegisterOptionsFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_REGISTER_OPTIONS_FAIL),
  switchMap(action => of()));

export const verifySecurityQuestionsFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_SECURITY_QUESTIONS_FAIL),
  switchMap(action => of()));

export const setQuestionsFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_QUESTIONS_FIELD),
  switchMap(action => sendingAsyncRequest(setQuestionsField(action.payload, state.value))));

export const setAnswersFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_ANSWERS_FIELD),
  switchMap(action => sendingAsyncRequest(setAnswersField(action.payload, state.value))));

export const verifySecurityQuestionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_SECURITY_QUESTIONS),
  switchMap(action => sendingAsyncRequest(submitSecurityQuestions(ajax, state.value))));

export const checkUserIdAvailabilityEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CHECK_USERID_AVAILABILITY),
  debounceTime(constants.checkUserIdAvailabilityDebounceTime),
  switchMap(action => sendingAsyncRequest(submitUserId(ajax, state.value))));

export const cancelRegistrationEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_REGISTRATION),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))));