import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-user-id-constants';
import { types } from '../verify-user-id-actions';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const { VERIFY_USER_ID, VERIFY_USER_ID_SUCCESS, VERIFY_USER_ID_FAIL, CANCEL_REGISTRATION_VERIFY_USERID, CHECK_ERROR_MESSAGE, GET_UID_OPTIONS_SUCCESS } = types;

const verifyUserIdSuccess = createAction(VERIFY_USER_ID_SUCCESS);
const verifyUserIdFail = createAction(VERIFY_USER_ID_FAIL);
const cancelRegistrationSent = createAction(CANCEL_REGISTRATION_VERIFY_USERID);
const checkUserIdErrMessageSent = createAction(CHECK_ERROR_MESSAGE);
const getIdentityOptionsSuccess = createAction(GET_UID_OPTIONS_SUCCESS);

const isBlank = str => (str || '' || 'undefined').trim().length === 0;

const checkUserIdErrMessage = (getState) =>
  checkUserIdErr(getState)
    .pipe(map(response => handleMessageResponse(response, getState)),
    catchError(err => [handleMessageResponse(getErrorMessage(err))]));

const checkUserIdErr = (getState) => {
  if (getState.registration.mobileFlow && getState.registration.message != '' && getState.registration.message != undefined) {
    getState.verifyUserId.errorTitle = getState.registration.message;
    return of({
      errorTitle: getState.registration.message
    });
  }
  return of({
    errorTitle: ''
  });
}

const handleMessageResponse = (response, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_USER_ID";
  let pagePath = '/ssr/flowType/verifyUserId';
  let pageView = pagePath + "/web";

  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_USER_ID"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_USER_ID"; }

  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end
  return verifyUserIdFail({
    showFailedModal: false,
    errorTitle: response.errorTitle,
    isMobile,
    isMobileBrowser,
    gaCategory,
    progressBarData
  });
}

//verifyUserIdSuccess
const verifyUserId = (ajax, userId, getState) =>
  verifyUserIdApi(ajax, userId, getState)
    .pipe(map(response => handleResponse(getState, response.response)),
    catchError(err => [handleVerifyUserIdResponseFail(getState, getErrorMessage(err))]));

const verifyUserIdApi = (ajax, userId, getState) => {
  console.log('user id latest ==' + userId);
  if (isBlank(userId) || userId == 'undefined' || userId == undefined) {
    return throwError(new Error(getState.intl.messages['verifyUser.emptyRegCode']));
  }
  return ajax({
    url: constants.getVerifyUserIdEndPoint,
    method: 'POST',
    responseType: 'json',
    body: userId,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId,
    }
  });
};

const handleResponse = (getState, response) => {
  console.log('handleresponse=======>>' + response.code);
  let gaCategory = getState.verifyUserId.gaCategory;
  if (response.code === constants.SUCCESS) {
    ReactGA.sendevent(gaCategory, 'VERIFY_USER_ID', 'SUCCESS');
    // call SIV options and then return the viewId
    return verifyUserIdSuccess({

    });
  } else {
    if(response.code === constants.ALREADY_REGISTERED) {
        return cancelRegistrationSent ({
          code : response.code
        });
     }
    if (response.code != '' && response.code === constants.EXCEEDED_ATTEMPTS) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_ID', response.code);
      return verifyUserIdFail({
        showFailedModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: '',
        code: response.code
      });
    }
    if (response.code != '' && response.code === constants.INVALID_USERID) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_ID', 'INVALID_USER_ID');
      return verifyUserIdFail({
        showFailedModal: false,
        errorTitle: response.message
      });
    }
    if (response.code != '' && response.code === constants.USERID_NOT_FOUND) {
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_ID', 'USERID_NOT_FOUND');
      return verifyUserIdFail({
        showFailedModal: false,
        errorTitle: response.message
      });
    }

    return verifyUserIdFail({
      showFailedModal: true,
      errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
      welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
      errorTitle: ''
    });
  }
};

const handleVerifyUserIdResponseFail = (getState, errorMessage) => {
  console.log('handle verify userId response fail response' + errorMessage);
  return verifyUserIdFail({
    showFailedModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    errorTitle: ''
  });
};

{/************************************Siv Options call*******************************************/ }

const getSivOptions = (ajax, getState) =>
  sivOptionsApi(ajax, getState)
    .pipe(map(response => handleSivResponse(getState, response.response)),
    catchError(err => [handleSivResponseFail(getState, getErrorMessage(err))]));

const sivOptionsApi = (ajax, getState) => ajax({
  url: constants.getVerifyUserIdAfterPodRedirectEndPoint,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    "cache-control": "no-cache",
    'X-TRANSACTIONID': getState.registration.transactionId,
  }
});

const handleSivResponse = (getState, response) => {
  console.log('handle user id siv response');
  if (response.viewId != 'Error') {
    return getIdentityOptionsSuccess({
      viewId: response.viewId,
    });
  } else {
    if(response.code != '' && response.code === constants.ALREADY_REGISTERED) {
       let gaCategory = getState.verifyUserId.gaCategory;
       ReactGA.sendevent(gaCategory, 'VERIFY_USER_ID', response.code);
        return cancelRegistrationSent ({
          code : response.code
        });
     }
    if (response.code != '' && response.code === constants.EXCEEDED_ATTEMPTS) {
      let gaCategory = getState.verifyUserId.gaCategory;
      ReactGA.sendevent(gaCategory, 'VERIFY_USER_ID', response.code);
      return verifyUserIdFail({
        showFailedModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: '',
        code: response.code
      });
    }
    getState.verifyUserId.errorTitle = response.message;
    return verifyUserIdFail({
      errorTitle: response.message,
    });
  }
}
const handleSivResponseFail = (getState, errorMessage) => {
  console.log('handle user id siv response fail response');
  if (getState.verifyUserId.errorTitle != '') {
    return verifyUserIdFail({
      errorTitle: getState.verifyUserId.errorTitle,
    });
  }
  return verifyUserIdFail({
    showFailedModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    errorTitle: ''
  });
};

{/************************************Cancel Registration*******************************************/ }

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const code = getState.verifyUserId.code;
  const organizationId = getState.registration.organizationId;
  if (code != null && code === constants.ALREADY_REGISTERED) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  }
  if (isMobile) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of common.js
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}
const handleAlreadyRegisteredResponse = (response) => {
  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});

{/************************************Epics declaration*******************************************/ }
export const verifyUserIdEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_USER_ID),
  switchMap(action => sendingAsyncRequest(verifyUserId(ajax, action.payload, state.value))));

export const verifyUserIdSuccessEpic = (action$, state) => action$.pipe(
  ofType(GET_UID_OPTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.verifyUserId.viewId))));

export const verifyUserIdFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_USER_ID_FAIL),
  switchMap(action => of()));

export const getSivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_USER_ID_SUCCESS),
  switchMap(action => sendingAsyncRequest(getSivOptions(ajax, state.value))));

export const cancelRegistrationEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_REGISTRATION_VERIFY_USERID),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))));

export const checkRegCodeErrMessageEpic = (action$, state) => action$.pipe(
  ofType(CHECK_ERROR_MESSAGE),
  switchMap(action => sendingAsyncRequest(checkUserIdErrMessage(state.value))));
