import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Radio,
  Alert,
  DropdownButton
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { constants } from '../../redux/ducks/verify-pic/verify-pic-constants';
import { ReactGA, WindowUtil } from '../../util';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import HandIcon from 'adp-react-icons/lib/fa/hand-o-right';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

var empIdEnable = false;
const isBlank = str => (str || '').trim().length === 0;
let showAlertFlag = false;
let isFirstTime = false;
let initialLoad = false;
let setAutoFocusOff;

class VerifyPICForm extends Component {
  constructor() {
    super();
    this.state = {
      option: '',
      inline: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMonthDaySelection = this.handleMonthDaySelection.bind(this);
  }
  static propTypes = {
    sivOptions: PropTypes.shape({
      availableSivOptions: PropTypes.shape({
        siv_lastname: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_empid: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_wfnid: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_dateofbirth: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_firstname: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_ssn: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_ssn4: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_fulldateofbirth: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_dateofbirth: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        })
      }),
      sivPIC: PropTypes.bool,
      sivCPCOr: PropTypes.bool,
      sivOptionsCalenderItems: PropTypes.shape({
        monthItems: PropTypes.arrayOf(PropTypes.string),
        dayItems: PropTypes.arrayOf(PropTypes.string),
        fullYearItems: PropTypes.arrayOf(PropTypes.string),
        fullMonthItems: PropTypes.arrayOf(PropTypes.string),
        fullDayItems: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    setSivField: PropTypes.func,
    firstName: PropTypes.string,
    firstNameValid: PropTypes.bool,
    lastName: PropTypes.string,
    lastNameValid: PropTypes.bool,
    ssn: PropTypes.string,
    ssnValid: PropTypes.bool,
    ssn4: PropTypes.string,
    ssn4Valid: PropTypes.bool,
    empId: PropTypes.string,
    empIdValid: PropTypes.bool,
    wfnId: PropTypes.string,
    wfnIdValid: PropTypes.bool,
    fullMonth: PropTypes.string,
    fullDay: PropTypes.string,
    fullYear: PropTypes.string,
    verifySivOptions: PropTypes.func,
    errorMessage: PropTypes.string,
    errorMessageLN: PropTypes.string,
    errorMessageEmpId: PropTypes.string,
    errorMessageWfnId: PropTypes.string,
    errorMessageSsn: PropTypes.string,
    errorMessageSsn4: PropTypes.string,
    getSivOptions: PropTypes.func,
    welcomeMsg: PropTypes.string,
    errorMsgTitle: PropTypes.string,
    showErrorModal: PropTypes.bool,
    isFormValid: PropTypes.bool,
    enableSubmitButton: PropTypes.func,
    cancelAndCheckYourEntries: PropTypes.func,
    errorCode: PropTypes.string,
    errorTitle: PropTypes.string,
    errorMessageFN: PropTypes.string
  };

  componentWillMount() {
    if (!isFirstTime)
      this.props.getSivOptions();
    isFirstTime = true;
    WindowUtil.setlayoutback(false);
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }

  componentDidMount() {
    if (initialLoad)
      this.props.getPicSivOptions();
    initialLoad = true;

  }

  componentWillReceiveProps = (nextProps) => {
    console.log('In componentWillReceiveProps');
    if (nextProps.sivOptions != null && nextProps.sivOptions.availableSivOptions != null) {
      this.checkFormReadyness(nextProps);
      this.handleMonthDaySelection(nextProps);
      if (nextProps.errorTitle != '' && showAlertFlag) {
        showAlertFlag = false;
        window.scrollTo(0, 0);
      }
    }
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (
      nextProps.sivOptions.availableSivOptions.siv_firstname &&
      (isBlank(nextProps.firstName) || !nextProps.firstNameValid)
    ) {
      formValid = false;
    }

    if (
      nextProps.sivOptions.availableSivOptions.siv_lastname &&
      (isBlank(nextProps.lastName) || !nextProps.lastNameValid)
    ) {
      formValid = false;
    }

    if (!this.state.option && (nextProps.sivOptions.availableSivOptions.siv_empid ||
      nextProps.sivOptions.availableSivOptions.siv_wfnid ||
      nextProps.sivOptions.availableSivOptions.siv_ssn ||
      nextProps.sivOptions.availableSivOptions.siv_ssn4 ||
      nextProps.sivOptions.availableSivOptions.siv_dateofbirth)) {
      formValid = false;
    }

    if (nextProps.sivOptions.availableSivOptions.siv_empid && nextProps.radioValue === constants.EMPID && (isBlank(nextProps.empId) || !nextProps.empIdValid)) {
      formValid = false;
    }

    if (nextProps.sivOptions.availableSivOptions.siv_wfnid && nextProps.radioValue === constants.WFNID && (isBlank(nextProps.wfnId) || !nextProps.wfnIdValid)) {
      formValid = false;
    }

    if (nextProps.sivOptions.availableSivOptions.siv_ssn && nextProps.radioValue === constants.SSN && (isBlank(nextProps.ssn) || !nextProps.ssnValid)) {
      formValid = false;
    }

    if (nextProps.sivOptions.availableSivOptions.siv_ssn4 && nextProps.radioValue === constants.SSN4 && (isBlank(nextProps.ssn4) || !nextProps.ssn4Valid)) {
      formValid = false;
    }

    if (
      nextProps.sivOptions.availableSivOptions.siv_dateofbirth && nextProps.radioValue === constants.DOB &&
      (isBlank(nextProps.fullMonth) || isBlank(nextProps.fullDay))
    ) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  };

  handleChange(option) {
    document.getElementById('pic-division').style.display = "none";
    this.setState({
      option
    });
    this.props.setSivField(constants.RADIO_OPTION, option);
  }

  handleMonthDaySelection = (nextProps) => {
    if (nextProps.sivOptions.availableSivOptions.siv_dateofbirth && this.state.option === constants.DOB) {
      const inputMonthId = nextProps.sivOptions.sivOptionsCalenderItems.monthItems.indexOf(nextProps.fullMonth) + 1;
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        nextProps.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        nextProps.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        nextProps.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith29days;
      }
    }
  }

  onInlineChange = (inline) => {
    this.setState({
      inline
    });
  }

  _handleKeyPress = (e,formValid) => {
    if (e.key === 'Enter') {
      const allowEnterKeyFields = ["pic_fn_textBox","pic_ln_textBox","pic_ssn_textBox","pic_ssn4_textBox","pic_empid_textBox","pic_wfnid_textBox"];
     e.preventDefault();
     ReactGA.sendevent(this.props.gaCategory, 'VERIFY_PIC', 'SUBMIT');
      if(formValid && e.target !== undefined && e.target.id !== undefined && allowEnterKeyFields.includes(e.target.id)){
       this.props.verifySivOptions();
      }     
    }
  }

  render() {
    console.log('rendering verify pic form...');
    const {
      sivOptions,
      setSivField,
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      empId,
      empIdValid,
      wfnId,
      wfnIdValid,
      ssn,
      ssnValid,
      ssn4,
      ssn4Valid,
      fullMonth,
      fullDay,
      verifySivOptions,
      showErrorModal,
      errorMsgTitle,
      welcomeMsg,
      errorMessage,
      errorMessageLN,
      errorMessageEmpId,
      errorMessageWfnId,
      errorMessageSsn,
      errorMessageSsn4,
      isFormValid,
      cancelAndCheckYourEntries,
      errorCode,
      errorTitle,
      errorMessageFN,
      isMobileBrowser,
      isMobile,
      gaCategory,
      clientName,
      progressBarData,
      setLocale,
      changeLocale,
      embeddedPrcFlow,
      radioValue,
      runAdminFlow,
      runAdminAccountantConnectFlow,
    } = this.props;

    const errorPopover = (fieldValid, errorMessage) =>
      (fieldValid && <Popover
        id="dummyPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error align-messages'}
        >
          <div role="alert">{errorMessage}</div>
        </Popover>
      ));

    const tooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );

    const onSetFirstName = fName => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "FIRST_NAME" }); return setSivField(constants.sivOptionIds.siv_firstName, fName); }

    const onSetLastName = lName => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "LAST_NAME" }); return setSivField(constants.sivOptionIds.siv_lastName, lName); }

    const onSetEmployeeId = empId => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EMP_ID" }); return setSivField(constants.sivOptionIds.siv_employee_id, empId); }

    const onSetWfnAssociateId = wfnId => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "WFN_ID" }); return setSivField(constants.sivOptionIds.siv_wfn_associate_id, wfnId); }

    const onSetSSN = inputSsn => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SSN" }); return setSivField(constants.sivOptionIds.siv_last4_ssn, inputSsn); }

    const onSetSSN4 = inputSsn4 => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SSN4" }); return setSivField(constants.sivOptionIds.siv_last4_ssn, inputSsn4); }

    const onSetDobMonth = month => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return setSivField(constants.sivOptionIds.siv_dob_month, month); }

    const onSetDobDay = day => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return setSivField(constants.sivOptionIds.siv_dob_day, day); }

    return (
      sivOptions && (
        <div className="registration-content">
          <div className="registration-form-container" >
             { 
              (window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
               }
              {
               (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
               }
            {(!isMobile) && embeddedPrcFlow == true && (
              <div className="vp-lang-dropdown">

                <DropdownButton 
                  id="pic_langLocale"
                  valueField="language"
                  textField="language"
                  buttonStyle='link'
                  data={WindowUtil.getLocaleList()}
                  onSelect={(valueField) => {
                    changeLocale(valueField.suffix);
                    ReactGA.sendevent(gaCategory, 'LANGUAGE_DROPDOWN_SELECT', valueField.suffix);
                    return setLocale(valueField)

                  }}>
                  <span style={{width:'100px'}}>{this.props.updatedlocale}</span>
                </DropdownButton>
              </div>
            )}
            {(!isMobile) && embeddedPrcFlow == false && (
              <div className="vp-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_PIC', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                <svg id="pic_cancel" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_PIC', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            )}
          </div>

          {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
            <div className="registration-progress-bar">
              <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
            </div>
          )}

          {(!isMobile) && <hr className="header-hr" />}
          <div className="vdl-row registration-form">
            <div className="welcome-title title-width">
              <div className="vdl-col-md-12">
                <h1 id="registration-layout_welcome" className="page-title">{<FormattedMessage id="verifyPic.title" />}</h1>
                <div className="div-h5 heading-text info-align-padding" style={{ textAlign: 'center', fontWeight: "normal" }}> {
                  <FormattedMessage id="identity_page_infoText" values={{ 0: <b>{clientName}</b> }} />}</div>
              </div>
            </div>
            {errorTitle != undefined && errorTitle != '' && (<Alert
              type="error" style={{ width: '100%', marginLeft: '10%', marginRight: '10%' }}
            ><p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(errorTitle)}</p></Alert>
            )}
            <div className="vdl-col-md-12">
              <form className="adp-form" onKeyPress={(e) => this._handleKeyPress(e,isFormValid)}>
                {sivOptions.availableSivOptions.siv_firstname && (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label htmlFor="first_name_Label">{sivOptions.availableSivOptions.siv_firstname.labelName}
                          <span className="vp-required-icon">*</span>
                          <span id="pic_fn_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(ReactHtmlParser(this.props.intl.formatMessage({ id: runAdminFlow === true ? (runAdminAccountantConnectFlow === true ?'verifyPic_ac_linkdesc': 'verifyPic_run_linkdesc'):'verifyPic_linkdesc' })))} >
                             <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(firstNameValid, errorMessageFN)}
                      >
                        <Textbox autoFocus={setAutoFocusOff} 
                          id="pic_fn_textBox" autocomplete="given-name"
                          aria-label={sivOptions.availableSivOptions.siv_firstname.labelName}
                          className={firstNameValid ? 'text-size' : 'vdl-validation-error text-size'}
                          value={firstName}
                          onChange={onSetFirstName}
                        />
                      </OverlayTrigger>
                    </div>
                    <div />
                  </div>
                )}

                {sivOptions.availableSivOptions.siv_lastname && (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label htmlFor="last_name_Label" >{sivOptions.availableSivOptions.siv_lastname.labelName}
                          <span className="vp-required-icon">*</span>
                          <span id="pic_ln_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'LAST_NAME', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click', 'focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc2' }))} >
                              <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(lastNameValid, errorMessageLN)}
                      >
                        <Textbox autocomplete="family-name"
                          id="pic_ln_textBox" 
                          aria-label={sivOptions.availableSivOptions.siv_lastname.labelName}
                          className={lastNameValid ? 'text-size' : 'vdl-validation-error text-size'}
                          value={lastName}
                          onChange={onSetLastName}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}

                <div id={this.props.intl.formatMessage({ id: 'verifyPic.oneOptionLabel' })}>
                <div role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'verifyPic.oneOptionLabel' })}>
                {
                  ((sivOptions.availableSivOptions.siv_empid && sivOptions.availableSivOptions.siv_ssn) ||
                    (sivOptions.availableSivOptions.siv_empid && sivOptions.availableSivOptions.siv_dateofbirth) ||
                    (sivOptions.availableSivOptions.siv_ssn && sivOptions.availableSivOptions.siv_dateofbirth) ||
                    (sivOptions.availableSivOptions.siv_wfnid && sivOptions.availableSivOptions.siv_ssn) ||
                    (sivOptions.availableSivOptions.siv_wfnid && sivOptions.availableSivOptions.siv_dateofbirth) ||
                    (sivOptions.availableSivOptions.siv_empid && sivOptions.availableSivOptions.siv_ssn4) ||
                    (sivOptions.availableSivOptions.siv_ssn4 && sivOptions.availableSivOptions.siv_dateofbirth) ||
                    (sivOptions.availableSivOptions.siv_wfnid && sivOptions.availableSivOptions.siv_ssn4)) && (
                    <div>
                      <div >
                        <div className="identity-page-padding vp-oneoption-div">
                          <span id="oneOption" className="vp-oneoption-label">{<FormattedMessage id="verifyPic.oneOptionLabel" />}<span className="vp-oneoption-req-icon">*</span></span>
                        </div>
                      </div>
                    </div>
                  )
                }

                {sivOptions.availableSivOptions.siv_ssn && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="pic_ssn_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.SSN ? '#086274' : '' }} value="ssn"
                        onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'SSN')} onChange={this.handleChange} inline={this.state.inline}>{sivOptions.availableSivOptions.siv_ssn.labelName}</Radio>
                    </div>
                  </div>
                )}
                {this.state.option === "ssn" && sivOptions.availableSivOptions.siv_ssn ? (
                  <div className="identity-page-padding  siv-options-height">
                    <div>

                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(ssnValid, errorMessageSsn4)}
                      >
                        <Textbox autoFocus={this.state.option === constants.SSN ? {setAutoFocusOff} : ''}
                          id="pic_ssn_textBox" style={{ width: '25%' }}
                          aria-label={sivOptions.availableSivOptions.siv_ssn.labelName}
                          type="password"
                          className={ssnValid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={ssn}
                          onChange={onSetSSN}
                        />
                      </OverlayTrigger> <span className="lock-icon-align"><LockIcon /></span>

                      <div className="ssn-info-tooltip-align">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                          overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))}
                        >
                          <span className="vp-handicon-span">
                            <HandIcon style={{ marginBottom: "2px" }} />

                            <a aria-label="tooltip" id="pic_ssn_info" className="tooltip-link vp-ssn-info-tooltip" >  {<FormattedMessage id="ssn_info_tooltip" />}</a></span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                ) : (null)
                }

                {sivOptions.availableSivOptions.siv_ssn4 && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="pic_ssn4_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.SSN4 ? '#086274' : '' }} value="ssn4"
                        onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'SSN4')} onChange={this.handleChange} inline={this.state.inline}>{sivOptions.availableSivOptions.siv_ssn4.labelName}</Radio>
                    </div>
                  </div>
                )}
                {this.state.option === "ssn4" && sivOptions.availableSivOptions.siv_ssn4 ? (
                  <div className="identity-page-padding  siv-options-height">
                    <div>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(ssn4Valid, errorMessageSsn4)}
                      >
                        <Textbox autoFocus={this.state.option === constants.SSN4 ? {setAutoFocusOff} : ''}
                          id="pic_ssn4_textBox" style={{ width: '25%' }}
                          aria-label={sivOptions.availableSivOptions.siv_ssn4.labelName}
                          type="password"
                          className={ssn4Valid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={ssn4}
                          onChange={onSetSSN4}
                        />
                      </OverlayTrigger> <span className="lock-icon-align"><LockIcon /></span>
                      <div className="ssn-info-tooltip-align">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                          overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))}
                        >
                          <span tabIndex="0" className="vp-handicon-span focus-selector">
                            <HandIcon style={{ marginBottom: "2px" }} />

                            <a aria-label="tooltip" id="pic_ssn4_info" className="tooltip-link vp-ssn-info-tooltip" >  {<FormattedMessage id="ssn_info_tooltip" />}</a></span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                ) : (null)
                }

                {sivOptions.availableSivOptions.siv_empid && (
                  <div className="identity-page-padding ">
                    <div className="input-group-full ">
                      <Radio id="pic_empid_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.EMPID ? '#086274' : '' }} value="empId"
                        onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID')} onChange={this.handleChange} inline={this.state.inline}>{sivOptions.availableSivOptions.siv_empid.labelName}</Radio>
                    </div>
                  </div>
                )}

                {this.state.option === "empId" && sivOptions.availableSivOptions.siv_empid ? (
                  <div className="identity-page-padding  siv-options-height">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(empIdValid, errorMessageEmpId)}
                      >
                        <Textbox
                          id="pic_empid_textBox" autoFocus={this.state.option === constants.EMPID ? {setAutoFocusOff} : ''}
                          aria-label={sivOptions.availableSivOptions.siv_empid.labelName}
                          className={empIdValid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={empId} style={{ width: '50%' }}
                          onChange={onSetEmployeeId}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)
                }

                {sivOptions.availableSivOptions.siv_wfnid && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="pic_wfnid_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.WFNID ? '#086274' : '' }} value="wfnId"
                        onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'WFN_ID')} onChange={this.handleChange} inline={this.state.inline}>{sivOptions.availableSivOptions.siv_wfnid.labelName}</Radio>
                    </div>
                  </div>
                )}
                {this.state.option === "wfnId" && sivOptions.availableSivOptions.siv_wfnid ? (
                  <div className="identity-page-padding  siv-options-height">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                        overlay={errorPopover(wfnIdValid, errorMessageWfnId)}
                      >
                        <Textbox autoFocus={this.state.option === constants.WFNID ? {setAutoFocusOff} : ''}
                          id="pic_wfnid_textBox"
                          aria-label={sivOptions.availableSivOptions.siv_wfnid.labelName}
                          className={wfnIdValid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={wfnId} style={{ width: '50%' }}
                          onChange={onSetWfnAssociateId}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)
                }

                {sivOptions.availableSivOptions.siv_dateofbirth && (
                  <div className="identity-page-padding ">
                    <div className="input-group-full">
                      <Radio id="pic_dob_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.DOB ? '#086274' : '' }} value="dob"
                        onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', 'DOB')} onChange={this.handleChange} inline={this.state.inline}>{sivOptions.availableSivOptions.siv_dateofbirth.labelName}</Radio>
                    </div>
                  </div>
                )}
                {this.state.option === "dob" && sivOptions.availableSivOptions.siv_dateofbirth ? (
                  <div className="identity-page-padding  siv-options-height" id={sivOptions.availableSivOptions.siv_dateofbirth.labelName}>
                    <div style={{ display: 'inline-flex' }} role="group" aria-labelledby={sivOptions.availableSivOptions.siv_dateofbirth.labelName}>
                      <div className="dob-column-width">
                        <DropdownList aria-label="monthDropdown" 
                          id="pic_month" autocomplete="bday-month"
                          value={fullMonth} className="monthdropdown-align"
                          onChange={onSetDobMonth}
                          data={sivOptions.sivOptionsCalenderItems.monthItems}
                          placeholder={'Month'}
                        />
                      </div>

                      <div className="dob-column-width">
                        <DropdownList aria-label="dayDropdown" 
                          id="pic_day" className="daydropdown-align"
                          value={fullDay} autocomplete="bday-day"
                          onChange={onSetDobDay}
                          data={sivOptions.sivOptionsCalenderItems.dayItems}
                          placeholder={'Day'}
                        />
                      </div>
                    </div>
                  </div>
                ) : (null)
                }
                </div>
              </div>
               <div id="pic-division" className="siv-options-height" style={{display:"block"}}></div>
                <div
                  id="sivoptions-form_submit-button"
                  className="form-group submit vdl-text-xs-center submit-button-align" style={{paddingTop:"7px", marginBottom: '40px' }}
                >
                  <Button role="button"
                    style={{ minHeight: "35px", minWidth: "180px" }}
                    id="pic_continueBtn"
                    disabled={!isFormValid} onClick={() => {showAlertFlag = true; return this.props.verifySivOptions();}}
                    onKeyPress={(e) => {if (e.key === 'Enter') {showAlertFlag = true; return this.props.verifySivOptions();}}}
                    className="primary submit-button-align safari-button-align"
                  >
                    {<FormattedMessage id="submitCBtn.Label" />}
                  </Button></div>

                <Modal
                  className="vdl-modal--alert"
                  show={showErrorModal}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className={errorCode === constants.ALREADY_REGISTERED ? 'modal-title-info' : 'modal-title-error'}>
                      <WarnIcon className={errorCode === constants.ALREADY_REGISTERED ? 'vdl-modal__title-icon warn-icon-info' : 'vdl-modal__title-icon warn-icon-error'}
                      />
                      {errorMsgTitle ? errorMsgTitle : this.props.intl.formatMessage({ id: 'Txt_VGeneralErrorWindowTitle' })}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="pic_okBtn" onClick={cancelAndCheckYourEntries} onKeyPress={cancelAndCheckYourEntries} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>
              </form>
            </div>
          </div>
        </div>
      )
    );
  }
}
export default injectIntl(VerifyPICForm);
