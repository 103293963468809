
 let isPendoDefined = false;

export default class PendoUtil {

  static pendoInitialize(pendoApiKey,transactionId) {
    isPendoDefined = true;
    const scriptNode = document.createElement('script');
    scriptNode.setAttribute('type', 'text/javascript');
    scriptNode.innerText = "! (function(apiKey){   (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){  o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');pendo.initialize({  visitor: {id:'',sessionId:'"+transactionId+"' },});})  ('" + pendoApiKey + "');";
    document.getElementsByTagName('head')[0].appendChild(scriptNode);
  }

  static pendoUpdate(pageView){
    
    if(isPendoDefined){
       pendo.updateOptions({
       visitor: {
            pageView: pageView 
           }
        }
      )
    }
  }

  static pendoIdentify(orgCd,clientName,transactionId){
    
    if(isPendoDefined && orgCd != undefined){
       pendo.identify({
       account: {
            id:  orgCd,
            clientName: clientName,
            TransactionId: transactionId
           }
        }
      )
    }
  }

  static pendoVisitorIdUpdate(associateId){
    
    if(isPendoDefined && associateId != undefined){
       pendo.identify({
       visitor: {
            id:  associateId,
           }
        }
      )
    }
  }

}
