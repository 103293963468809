export const constants = {
  getPicSivOptionsDetailsEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/sivOptions',
  sendPicSivOptionsDetailsEndPoint:'/pages/sms/ess/v3/ws/rest/pub/ssr/verifyIdentity',
  redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
  setLocaleEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/updateLocale",
  monthWith31days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  monthWith30days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
  monthWith29days:["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
  sivOptionIds: {
    siv_firstName: 0,
    siv_lastName: 1,
    siv_employee_id:2,
    siv_wfn_associate_id:3,
    siv_last4_ssn: 4,
    siv_dob_month: 5,
    siv_dob_day: 6,    
    siv_ssn:7,
  },
  INVALID_SIVDATA: 'INVALID_SIVDATA',
  DUPLICATE_SSN4: 'DUPLICATE_SSN4',
  CLIENT_SETUP_ERROR: 'CLIENT_SETUP_ERROR',
  EXCEEDED_ATTEMPTS: 'EXCEEDED_ATTEMPTS',
  ERROR: 'Error',
  EMPID: 'empId',
  WFNID: 'wfnId',
  SSN: 'ssn',
  SSN4: 'ssn4',
  DOB: 'dob',
  PATHVERIFYPIC: '/ssr/PIC/verifySIVData',
  PATHPICFLOW: '/ssr/flowType/PIC',
  TROUBLE_SHOOT_MSG: 'something went wrong',
  ALREADY_REGISTERED:'ALREADY_REGISTERED',
  REDIRECT_FORGOT:'/pages/sms/ess/v3/pub/ssr/redirectToForgotFlow.jsp',
  EMAIL_NOT_EXISTS:'EMAIL_NOT_EXISTS',
  NO_SIV_DEFINED:'NO_SIV_DEFINED',
  ALREADY_REGISTERED_SUSPENDED:'ALREADY_REGISTERED_SUSPENDED',
  SUCCESS:"200",  
  FINDME_VIEW: '/findMeHelpUsFindYou',
  FINDME_VIEWID: '/findMe',
  RADIO_OPTION:'radio'
};

