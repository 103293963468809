import ReactGA from 'react-ga';

export default class GAUtil {
  static initialize(gaTrackingId) {
    setTimeout(ReactGA.initialize(gaTrackingId), 0);
  }

  static pageview(pagePath) {
    setTimeout(ReactGA.pageview(pagePath), 0);
  }

  static sendevent(_category, _action, _label) {
    setTimeout(
      ReactGA.event({
        category: _category,
        action: _action,
        label: _label,
      }),
      0,
    );
  }
}
