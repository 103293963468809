import React from 'react';
import ExternalLinkIcon from 'adp-react-icons/lib/fa/external-link';
import PropTypes from 'prop-types';
import { getLocaleObjFromPropKey } from '../../util/locale';
import {
  LINK,
  RIGHT,
  ITEM_STYLE_LEFT,
  ITEM_STYLE_RIGHT,
  STYLE_COPYRIGHT,
  CO_BROWSE,
  POP_UP,
} from './footer-constants';
import FooterPopUpModal from '../common/FooterPopUpModal';
import { ReactGA, WindowUtil } from '../../util';

const Footer = ({ footer, locale }) => (
  <footer className="vdl-footer">
    <ul>
      { []
        .concat(footer.items)
        .sort((elem1, elem2) => (elem1.order < elem2.order ? -1 : 0))
        .map((sortedFooter) => {
          if (sortedFooter.component.type === LINK) {
            return (
              <li
                className={ sortedFooter.alignment === RIGHT ? ITEM_STYLE_RIGHT : ITEM_STYLE_LEFT }
                key={ sortedFooter.order }
              >
                <a aria-label={ "footer"+sortedFooter.id }
                  href={ getLocaleObjFromPropKey(sortedFooter.component.url, locale) }
                  target="_blank"
                  rel="noopener noreferrer"
                  id={ sortedFooter.id }
                  onClick={ () => {
                    ReactGA.sendevent('SSR_FOOTER', sortedFooter.component.type, sortedFooter.id);
                  } }
                >
                  <ExternalLinkIcon />
                  { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }
                </a>
              </li>
            );
          }
          if(sortedFooter.component.type === CO_BROWSE) {
            return (
              <li
                className={ sortedFooter.alignment === RIGHT ? ITEM_STYLE_RIGHT : ITEM_STYLE_LEFT }
                key={ sortedFooter.order }
              >
                <a
                  id={ sortedFooter.order }
                  href="#"
                  onClick={WindowUtil.startCoBrowseSession}
                  class= "primary"
                >
                { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }
                </a>
              </li>
            );
          }
          if (sortedFooter.component.type === POP_UP) {
            return (
              <li
                className={ sortedFooter.alignment === RIGHT ? ITEM_STYLE_RIGHT : ITEM_STYLE_LEFT }
                key={ sortedFooter.order }
                id={ sortedFooter.id }
              >
                <FooterPopUpModal
                  displayOrder={ sortedFooter.order }
                  displayText={ sortedFooter.component.text }
                  url={ sortedFooter.component.url }
                  locale={ locale }
                  id={ sortedFooter.id }
                />
              </li>
            );
          }
          return (
            <li
              className={ sortedFooter.alignment === RIGHT ? STYLE_COPYRIGHT : ITEM_STYLE_LEFT }
              key={ sortedFooter.order }
              id={ sortedFooter.id }
            >
              <div>{ getLocaleObjFromPropKey(sortedFooter.component.text, locale) }</div>
            </li>
          );
        }) }
    </ul>
  </footer>
);

Footer.propTypes = {
  footer: PropTypes.objectOf(PropTypes.array),
  locale: PropTypes.string,
};

export default Footer;