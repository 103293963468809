export const constants = {
    getVerifyRegCodeEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyRegCode',
    getPicSivOptionsDetailsEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/sivOptions',
    redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
    setLocaleEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/updateLocale",
    EXCEEDED_ATTEMPTS:'EXCEEDED_ATTEMPTS',
    INVALID_REGCODE:'INVALID_REGCODE',
    chooseVendorsViewId:'/chooseVendors',
    PII_ERROR:'SSR_CODELESS_OFF',
    ERROR:'Error',
    NO_SIV_DEFINED:'NO_SIV_DEFINED',
    FUNCTION_LOCKED:'FUNCTION_LOCKED',
    EMAIL_NOT_EXISTS:'EMAIL_NOT_EXISTS',
    ALREADY_REGISTERED_MULTI:'ALREADY_REGISTERED_MULTI',
    ALREADY_REGISTERED_SUSPENDED: 'ALREADY_REGISTERED_SUSPENDED',
    EXCEEDED_ATTEMPTS:'EXCEEDED_ATTEMPTS',
    ALREADY_REGISTERED: 'ALREADY_REGISTERED',
    ALREADY_REGISTERED_DUAL : 'ALREADY_REGISTERED_DUAL',
    SUCCESS :'200',
    FINDME_VIEW: '/findMeHelpUsFindYou',    
    FINDME_VIEWID: '/findMe',
};
