import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { types } from '../verify-admin-actions';
import { constants } from '../verify-admin-constants';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';
import { getLocaleInitialState } from '../../../../util/locale';

const {
  VERIFY_IDENTITY_OPTIONS,
  VERIFY_IDENTITY_OPTIONS_SUCCESS,
  VERIFY_IDENTITY_OPTIONS_FAIL,
  ENABLE_ADMIN_FLOW_SUBMIT_BUTTON,
  CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES,
  FETCH_DETAILS,
  SET_IDENTITY_FIELD,
  SET_IDENTITY_FIELD_SUCCESS,
  SET_IDENTITY_FIELD_FAIL,
  VERIFICATION_CODE_SENT,
  VERIFICATION_CODE_SENT_SUCCESS,
  VERIFY_ADMIN_OPTIONS_SUCCESS,
  VERIFY_ADMIN_RESEND_OTP
} = types;

const verifyIdentitySuccessSent = createAction(VERIFY_IDENTITY_OPTIONS_SUCCESS);
const verifyIdentityFailSent = createAction(VERIFY_IDENTITY_OPTIONS_FAIL);
const isBlank = str => (str || '').trim().length === 0;
const cancelAndCheckYourEntriesSent = createAction(CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES);
const fetchDetailsSent = createAction(FETCH_DETAILS);
const setIdentityFieldSuccessSent = createAction(SET_IDENTITY_FIELD_SUCCESS);
const setIdentityFieldFailSent = createAction(SET_IDENTITY_FIELD_FAIL);
const setAccessCodeSuccess = createAction(VERIFICATION_CODE_SENT_SUCCESS); 
const verifyAdminOptionsSucess = createAction(VERIFY_ADMIN_OPTIONS_SUCCESS);

/**********************Fetch firstname and lastname frm sivoptions**********************/
const fetchAdminDetails = (getState) =>
  fetchDetails(getState)
    .pipe(map(response => handleMessageResponse(response, getState)),
    catchError(err => [handleMessageResponse(getErrorMessage(err))]));

const fetchDetails = (getState) => {
  console.log('fetchDetails'+getState.verifyUser.runAdminV2Flow);
  if (getState.verifyUser.runAdminV2Flow != undefined && getState.verifyUser.runAdminV2Flow === true) {    
    getState.verifyAdmin.firstName = getState.verifyUser.firstName;
    getState.verifyAdmin.lastName = getState.verifyUser.lastName;
    getState.verifyAdmin.sivOptions = getState.verifyUser.sivOptions;
    getState.verifyAdmin.viewId = getState.verifyUser.viewId;
      return of({
        firstName: getState.verifyAdmin.firstName,
        lastName: getState.verifyAdmin.lastName,
        sivOptions: getState.verifyAdmin.sivOptions,
        viewId : getState.verifyAdmin.viewId
      });
  }
  return of({
     firstName: '',
     lastName: ''
  });
}

const handleMessageResponse = (response, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_ADMIN";
  let pagePath = '/ssr/flowType/verifyAdminForm';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_ADMIN"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_ADMIN"; }

  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
 
  getState.registration.currentStage = WindowUtil.getProgressBarViewId(response.viewId);
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end
  
  return verifyIdentityFailSent({
    isMobile,
    isMobileBrowser,
    gaCategory,
    progressBarData,
    firstName : getState.verifyAdmin.firstName,
    lastName : getState.verifyAdmin.lastName,
    sivOptions : getState.verifyAdmin.sivOptions
  });
}


/**********************Set the values to the fields**********************/

const setIdentityField = (payload, getState) =>
  validateAndSetIdentityField(payload, getState)
    .pipe(map(setIdentityFieldSuccessSent),
      catchError(err => [handleSetIdentityFieldFailResponse(payload, getErrorMessage(err))]));

const validateAndSetIdentityField = (payload, getState) => {
  
  const verifyAdmin = getState.verifyAdmin;

  switch (payload.fieldkey) {
    
    case constants.SIV_SSN4: {
      const ssn4RegEx = new RegExp("^[0-9]{4}$");

      if (isBlank(payload.fieldValue) || ssn4RegEx.test(payload.fieldValue)) {
        return of({
          ssn4: payload.fieldValue,
          ssn4Valid: true,
          errorTitle:''
        });
      }
      return throwError(new Error(verifyAdmin.sivOptions.availableSivOptions.siv_ssn4.invalidMessage));
    }
    case constants.MOBILE: {
      const dialCode = payload.fieldValue.selectedCountry.dialCode;
      const pos = dialCode.length;
      const number = payload.fieldValue.formattedNumber.replace(/\D/g, '').slice(pos);
      const iso2Code = payload.fieldValue.selectedCountry.iso2;

      return of({
        mobilePhone: number,
        mobilePhoneValid: payload.fieldValue.isValid,
        countryCode: iso2Code,
        errorTitle:''
      });
    }
     case constants.OTP_CODE: {
        return of({
          verificationCode: payload.fieldValue,
          codeValid: true,
          errorTitle:'',
          showAlertMessage:''
        });
    }
    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};

const handleSetIdentityFieldFailResponse = (payload, errorMessage) => {
  switch (payload.fieldkey) {
  
    case constants.SIV_SSN4:
      return setIdentityFieldFailSent({
        errorMessageSsn4: errorMessage,
        ssn4: payload.fieldValue,
        ssn4Valid: false,
      });
   
    default: {
      return setIdentityFieldFailSent({
        errorMessage,
      });
    }
  }
};

/**********************Validate mobile and ssn options**********************/

const submitIdentityOptions = (getState) =>
  verifyIdentityOptions(getState)
    .pipe(map(response => handleSubmitIdentityResponse(response.response, getState)),
      catchError(err => [handleSubmitFailIdentityResponse(getErrorMessage(err))]));

const verifyIdentityOptions = (getState) => {
  const verifyAdmin = getState.verifyAdmin;
  const sivOptions = verifyAdmin.sivOptions;
  const gaCategory = verifyAdmin.gaCategory; 
 
  ReactGA.sendevent(gaCategory, 'SUBMITTED', 'SSN4'); 
  var encryptedSsn4 = Crypto.AES.encrypt(verifyAdmin.ssn4, registrationConstants.registrationEncryption); 

  if(getState.verifyAdmin.prevNumber === getState.verifyAdmin.mobilePhone && 
  getState.verifyAdmin.prevCountryCd === getState.verifyAdmin.countryCode && 
  getState.verifyAdmin.prevSsn4 === getState.verifyAdmin.ssn4) {
    let message = getState.verifyAdmin.prevErrorMsg;
    if(message !== undefined && message === '') {
      message = getState.intl.messages['findMe.sivpage.single.notFoundInfo'];
    }
    return throwError(new Error(message));
  }
  return ajax({
    url: constants.validateMobileAndSSN,
    body: {
      'ssn4': encryptedSsn4+'',
      'mobile': verifyAdmin.mobilePhone,
      'countryCd': verifyAdmin.countryCode
    },
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
};

const handleSubmitFailIdentityResponse = (errorMessage) => { 
   return verifyIdentityFailSent({
     errorTitle: errorMessage,
     ssn4: ''
    });
}

const handleSubmitIdentityResponse = (response, getState) => {
  const verifyAdmin = getState.verifyAdmin;
  const gaCategory = verifyAdmin.gaCategory;  
  let showVerifyCode = false;
  let prevNumber = getState.verifyAdmin.mobilePhone;
  let prevCountryCd = getState.verifyAdmin.countryCode;
  let prevSsn4 = getState.verifyAdmin.ssn4;
  let prevErrorMsg = '';

  if (response.code != null && response.code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(gaCategory, 'VERIFY_ADMIN', response.code);    
    return cancelAndCheckYourEntriesSent({
      errorCode : response.code
    })
  }

  if (response.viewId.indexOf(constants.ERROR) == -1 && response.viewId === '/generateOtp') {    
    ReactGA.sendevent(gaCategory, 'VERIFY_ADMIN', 'SUCCESS');    
    return verifyIdentitySuccessSent({
      showVerifyCode: false
    });
  }

  if (response.title != null && response.message != null) {
    console.log('response.message=' + response.message);
    return verifyIdentityFailSent({
      showErrorModal: true,
      showModalMessage: response.message,
      showModalTitle: response.title,
      ssn4: '',
      errorCode: response.code
    });
  }

  if (response.code != null) {
    console.log('response.message=' + response.message);
    let message = response.message;
    if(response.code === constants.Err_VerifyIntegrationFailed) {
      message = getState.intl.messages['findMe.sivpage.single.notFoundInfo'];
    }
    return verifyIdentityFailSent({
      showErrorModal: false,
      errorTitle: message,
      ssn4: '',
      prevNumber,
      prevCountryCd,
      prevSsn4,
      prevErrorMsg : message
    });
  }
};

/**********************Generate code block**********************/

const generateCode = (ajax, getState) =>
  generateCodeApi(ajax, getState)
    .pipe(map(response => handleResponseIdentitySelected(response.response,getState)),
    catchError(err => [setIdentityFieldFailSent(getState, getErrorMessage(err))]));

const generateCodeApi = (ajax, getState) => {
  let contactId= 'RUN_PAYROLL_ADMIN_V2_PHONE';
    return ajax({
    url: constants.getGenerateCodeAPI,
    method: 'POST',
    responseType: 'json',
    body: contactId,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
}
const handleResponseIdentitySelected = (response, getState) => {

  let showAlert = false;
  let otpValid = true;
  let verificationCode = "";
  let showVerifyCode = false;

  if (response.code == constants.TYPE_SUCCESS) {
    let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Verify, getState);
    let showOnScreen = response.showOnScreen || false;
   /* if(showOnScreen === true) {
      verificationCode = response.accessCode;
    }*/
    let expiry = response.expiry;
    let maskedNumber = response.maskedValue;
    let accessCode = response.accessCode;
    return verifyAdminOptionsSucess({
       otpValid,verificationCode, showOnScreen, accessCode, expiry, showVerifyCode:true,viewId:'/runAdminV2',maskedNumber,progressBarData
    });

  }
  else {
    let showErrorModal = true;
    let showModalTitle = response.title;
    let showModalMessage = response.message;
    return setIdentitySelectedSuccess({
      showErrorModal, otcCode, showModalTitle, showModalMessage
    });
  }
}

/**********************submit otp code block**********************/

const submitAccessCode = (ajax, payload, getState) => {
  let inputPRC = getState.verifyAdmin.verificationCode;
  let consent = getState.verifyAdmin.agreeTnC;
  if (getState.verifyAdmin.isFormValid != undefined && getState.verifyAdmin.isFormValid == false)
    return of();
  return setSubmitAccessCodeApi(ajax, inputPRC,consent, getState)
    .pipe(map(response => handleResponseAccessCode(response.response, payload, getState)),
      catchError(err => [setIdentityFieldFailSent(getErrorMessage(err))]));
}

const setSubmitAccessCodeApi = (ajax, code,consent, getState) => ajax({
  url: constants.getVerifyCodeAPI,
  method: 'POST',
  responseType: 'json',
  body: {
    'inputPRCCode': code,
    'tandc': consent
  },
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleResponseAccessCode = (response, payload, getState) => {
  let showAlert = false;
  let codeValid = false;
  let showAlertMessage = false;
  if (response.code == constants.TYPE_SUCCESS) {
    return setAccessCodeSuccess({
      viewId: response.viewId,
      sendRedirect: true
    });

  }
  else {
    if (response.code == constants.TYPE_ERROR_INVALID || response.code == constants.TYPE_ERROR_EXPIRED) {
      showAlert = true;
      codeValid = false;
      showAlertMessage = response.message;

      return setAccessCodeSuccess({
        showAlert,
        codeValid,
        showAlertMessage
      });
    }
    else {
      let showErrorModal = true;
      let showModalTitle = response.title;
      let showModalMessage = response.message;
      return setAccessCodeSuccess({
        showErrorModal : true,
        showModalTitle,
        showModalMessage,
        errorCode : response.code
      });
    }
  }
}

/**********************Resend otp block**********************/

/*const resendOTP = (ajax, getState) => {
  return generateCodeApi(ajax, getState)
    .pipe(map(response => handleResponseIdentitySelected(response.response,getState)),
    catchError(err => [setIdentityFieldFailSent(getState, getErrorMessage(err))]));
}*/

/**********************Cancel options**********************/

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const code = getState.verifyPic.errorCode;
  const organizationId = getState.registration.organizationId;
  if (code != null && code === constants.ALREADY_REGISTERED) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  }
  if (isMobile && code != constants.ALREADY_REGISTERED) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const handleAlreadyRegisteredResponse = (response) => {
  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});



/**********************Epic options**********************/


export const verifyIdentityOptionsEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_IDENTITY_OPTIONS),
  switchMap(action => sendingAsyncRequest(submitIdentityOptions(state.value))));

export const generateCodeEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_IDENTITY_OPTIONS_SUCCESS),
  switchMap(action => sendingAsyncRequest(generateCode(ajax, state.value))));

export const getIdentitySuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_ADMIN_OPTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.verifyAdmin.viewId))));

export const verificationCodeSuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFICATION_CODE_SENT_SUCCESS),
  switchMap(action$ => of(push(state.value.verifyAdmin.viewId))));

export const verifyIdentityOptionsFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_IDENTITY_OPTIONS_FAIL),
  switchMap(action => of()));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const fetchAdminDetailsEpic = (action$, state) => action$.pipe(
  ofType(FETCH_DETAILS),
  switchMap(action => sendingAsyncRequest(fetchAdminDetails(state.value))));

export const setIdentityFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_IDENTITY_FIELD),
  switchMap(action => sendingAsyncRequest(setIdentityField(action.payload, state.value))));

export const submitAccessCodeEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFICATION_CODE_SENT),
  switchMap(action => sendingAsyncRequest(submitAccessCode(ajax, action.payload, state.value))));

export const resendOTPEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_ADMIN_RESEND_OTP),
  switchMap(action => sendingAsyncRequest(generateCode(ajax, state.value))),
);
