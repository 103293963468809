import { actions as registrationActions, types as registrationActionTypes } from '../registration';

const { getTransactionDetails } = registrationActions;

const { GET_TRANSACTION_DETAILS_SUCCESS } = registrationActionTypes;

const initialLoadRequestActions = [getTransactionDetails];
const initialLoadSuccessActionTypes = [GET_TRANSACTION_DETAILS_SUCCESS];

export const constants = {
  INITIAL_LOAD_REQUEST_ACTIONS: initialLoadRequestActions,
  INITIAL_LOAD_SUCCESS_ACTION_TYPES: initialLoadSuccessActionTypes,
};
