export const constants = {
    getVerifyUserIdEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/ssrbyadmin/podredirect',
    getVerifyUserIdAfterPodRedirectEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/ssrbyadmin/verifyuser',
    redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
    EXCEEDED_ATTEMPTS:'EXCEEDED_ATTEMPTS',
    ALREADY_REGISTERED:'ALREADY_REGISTERED',
    SUCCESS:'SUCCESS',
    INVALID_USERID:'INVALID_USERID',
    USERID_NOT_FOUND:'USERID_NOT_FOUND'
};
