import React, { Component } from 'react';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  DropdownButton
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import AdpIcon from 'adp-react-icons/lib/adp/adp';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { ReactGA, WindowUtil } from '../../util';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import BuildingIcon from 'adp-react-icons/lib/fa/building-o';
import CountryCodeDropdown from '../../components/country-code';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import { constants } from '../../redux/ducks/find-me/find-me-constants';
import EmailPhoneInput from '../../components/email-phone-input';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import FindMeEmailPhoneSivForm from './FindMeEmailPhoneSivForm';
import FindMeContractorSivForm from './FindMeContractorSivForm';
import FaArrowDown from 'adp-react-icons/lib/fa/arrow-down';

const isBlank = str => (str || '').trim().length === 0;
var ua = window.navigator.userAgent;
var isIE = (ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident") > -1 || ua.indexOf("Edge") > -1) ? true : false;
let overlayPlacement = "right";
let showAlertFlag = false;
let isFirstTimeLoad = false;

let downIndicator;
let continueButton;
let setAutoFocusOff;

class FindMeVariantForm extends Component {

  constructor() {
    super();
    this.state = {
      option: constants.EMAIL_PHONE_RADIO,
      reglessOption: ''
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleReglessRadioChange = this.handleReglessRadioChange.bind(this);
  }

  componentWillMount() {
    if (!isFirstTimeLoad)
      this.props.getFindMeInfo();
    isFirstTimeLoad = true;
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
    WindowUtil.setlayoutback(false);
  }

  componentDidMount() {
    this.downIndicatorUpdate();
    window.addEventListener('scroll', this.scrollFunction, true);
  }

  componentWillReceiveProps = (nextProps) => {
    this.downIndicatorUpdate();
    if (nextProps.sendRedirect)
      nextProps.setRedirect();
    if (nextProps.findMeObj.showAlert && showAlertFlag) {
      showAlertFlag = false;
      window.scrollTo(0, 0);
    }
    const redirectToPiiFLow = nextProps.findMeObj.redirectToPiiPage || false;
    if (redirectToPiiFLow) {
      nextProps.getPiiOptions();
      nextProps.findMeObj.redirectToPiiPage = false;
    }
    if (nextProps.findMeObj.page == constants.PII_PAGE) {
      this.checkFormInfoReadyness(nextProps);
    }
    else if (nextProps.findMeObj.page == constants.EMAILPHONESEARCH_PAGE && nextProps.findMeObj.searchType == constants.EMAIL) {
      this.checkFormEmailReadyness(nextProps);
    }
    else if (nextProps.findMeObj.page == constants.EMAILPHONESEARCH_PAGE && nextProps.findMeObj.searchType == constants.PHONE) {
      this.checkFormPhoneReadyness(nextProps);
    }
    else if (nextProps.findMeObj.page == constants.OTP_PAGE) {
      this.checkEmailPhoneOTP(nextProps);
    }
  }

downIndicatorUpdate = () => {
    downIndicator = document.getElementById("downIndicator");
    continueButton = document.getElementById("findMeVariantForm");
    if (window.innerWidth <= 479) {
      if (continueButton != undefined && continueButton !== null) {
        if (window.innerHeight > continueButton.scrollHeight) {
          downIndicator.style.display = "none";
        }
        else {
          if(document.documentElement.scrollTop < document.documentElement.scrollHeight-window.innerHeight-1) { 
            downIndicator.style.display = "block";
          } else {
            downIndicator.style.display = "none";
          } 
        }
      }
    }
  }

 scrollFunction = (nextProps) => {
  let downIndicator = document.getElementById("downIndicator");
    if(window.innerWidth<=479) {
      if(downIndicator !== undefined && downIndicator !== null){
        if (document.documentElement.scrollTop < document.documentElement.scrollHeight-window.innerHeight-1) {
          downIndicator.style.display = "block";
          } else {
          downIndicator.style.display = "none";
          }
        }
      }
  }

  componentDidUpdate(oldProps) {    
    this.downIndicatorUpdate();
    if (oldProps.findMeObj != undefined) {
      const nextProps = this.props
      const nextVerifyUser = nextProps.findMeObj.verifyUserCall || false;
      const prevVerifyUser = oldProps.findMeObj.verifyUserCall || false;

      if (nextVerifyUser && !prevVerifyUser) {
        nextProps.setRedirectVerifyUser();
      }
    }

  }

  checkEmailPhoneOTP = (nextProps) => {
    let formValid = true;
    if (isBlank(nextProps.findMeObj.otpValue)) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  }

  checkFormEmailReadyness = (nextProps) => {
    let formValid = true;
    if (isBlank(nextProps.findMeObj.email) || !nextProps.findMeObj.emailPhoneValid) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  }

  checkFormPhoneReadyness = (nextProps) => {
    let formValid = true;
    if (isBlank(nextProps.findMeObj.phone) || !nextProps.findMeObj.emailPhoneValid) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  }

  checkFormInfoReadyness = (nextProps) => {
    let formValid = true;

    if (nextProps.findMeObj.sivOptions) {

      if (nextProps.findMeObj.sivOptions["siv_firstname"] &&
        (isBlank(nextProps.findMeObj.sivOptions["siv_firstname"].idenDataValue)
          || nextProps.findMeObj.errorFields["siv_firstname"].validationError)) {
        formValid = false;
      }

      if (nextProps.findMeObj.sivOptions["siv_lastname"] &&
        (isBlank(nextProps.findMeObj.sivOptions["siv_lastname"].idenDataValue)
          || nextProps.findMeObj.errorFields["siv_lastname"].validationError)) {
        formValid = false;
      }

      if (nextProps.findMeObj.sivOptions["siv_fulldateofbirth"] &&
        (isBlank(nextProps.findMeObj.fullMonth) || isBlank(nextProps.findMeObj.fullDay) || isBlank(nextProps.findMeObj.fullYear))) {
        formValid = false;
      }

      {/*** Radio option check**/ }

      if (nextProps.findMeObj.selectedCountry == nextProps.findMeObj.countryDropdown[0]) {
        if (nextProps.findMeObj.radio1 == "empId" &&
          (isBlank(nextProps.findMeObj.sivOptions["siv_empid"].idenDataValue)
            || nextProps.findMeObj.errorFields["siv_empid"].validationError)) {
          formValid = false;
        }
        else if (nextProps.findMeObj.radio1 == "ssn" &&
          (isBlank(nextProps.findMeObj.sivOptions["siv_ssn"].idenDataValue)
            || nextProps.findMeObj.errorFields["siv_ssn"].validationError)) {
          formValid = false;
        }

      }

      if (nextProps.findMeObj.selectedCountry != nextProps.findMeObj.countryDropdown[0] &&
        (isBlank(nextProps.findMeObj.sivOptions["siv_empid"].idenDataValue)
          || nextProps.findMeObj.errorFields["siv_empid"].validationError)) {
        formValid = false;
      }

    }
    this.props.enableSubmitButton(formValid);
  };

  handleTooltipClick = (e) => {
    e.stopPropagation();
    ReactGA.sendevent(this.props.gaCategory, 'REGCODE', 'TOOLTIP');
  }

  handleRadioChange = (option) => {
        this.setState({
            option
        });
        this.downIndicatorUpdate();
        this.props.updateRadioChangeInfo(option);
        if(option === constants.PII_RADIO) {
          this.props.getPiiOptions();           
        }
    }
  
  handleReglessRadioChange = (reglessOption) => {
      this.setState({
          reglessOption
      });      
  }

  invokeReglessSubmit = (reglessOption) => {
    if(reglessOption === constants.REGLESS_RADIO) {
      this.props.registrationLessFlow();           
    }
    if(reglessOption === constants.CREATE_ACC_RADIO) {
      this.props.continueRegistration();           
    }
  }

   updatePrevPage = (value) => {
      let prevPage = value;
      this.props.setPrevPage(prevPage);
      let option = constants.PII_RADIO;
      this.setState({ option  });
      this.props.updateRadioChangeInfo(option);
      return this.props.getPiiOptions();
    }
  render() {
    const {
      findMeObj,
      isMobileBrowser,
      isMobile,
      gaCategory,
      updateCountryOption,
      updateFieldValue,
      isFormValid,
      submitIdentityOptions,
      showModalMessage,
      showModalWarn,
      showModalTitle,
      cancelAndCheckYourEntries,
      hasRegCode,
      orgSelected,
      goToMainPage,
      goToPiiInfoPage,
      channelSelect,
      submitAccessCode,
      resendOTP,
      getPiiOptions,
      setPrevPage,
      prevPage,
      goToErrorOptionsPage,
      goToEmailPhoneSearchPage,
      errorPageInfo,
      setErrorPageInfo,
      redirectToContactInfo,
      code,
      setLocale,
      changeLocale,
      redirectToIdentityPage,
      updateRadioChangeInfo,
      emailPhoneSearchSivObj,
      getContractorOptions,
      footer,
      contractorSivObj,
      registrationLessFlow,
      continueRegistration      
    } = this.props;

    const updateFieldValueHandler = (fieldName, fieldValue, isDateField) => {
      let payload = {};
      isDateField = isDateField || false;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.isDateField = isDateField;
      return updateFieldValue(payload);
    }

    
    const updateFieldValueHandleOtp = (fieldName, event, isDateField) => {
      let payload = {};
      isDateField = isDateField || false;
      payload.fieldName = fieldName;

      const inputValue = event.target.value.replace(/\D/g, '');

      if(this.props.otcCode === inputValue){
        return;
      }
      const otcCode = inputValue;

      payload.fieldValue = otcCode;
      payload.isDateField = isDateField;
      return updateFieldValue(payload);
    }

    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );
    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="findmeErrorPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{msg}</div>
        </Popover>
      ));

    const handleEmailPhoneChange = (value) => {
      const obj = {};
      obj.fieldName = value.type;
      obj.fieldValue = value.value;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EMAIL_PHONE_SEARCH" });
      if (value.type != undefined && value.type != null && value.type === constants.PHONE) {
        obj.fieldValue = value.phoneValue;
        obj.isoCode = value.iso;
        obj.ccode = value.dialCode;
      }
      obj.isValid = value.isValid;
      return updateFieldValue(obj);
    }

    const _handleSearchKeyPress = (e) => {

      if (e.key === 'Enter') {
        e.preventDefault();
        const allowEnterKeyFields = ["emailPhone-textId", "emailPhone_continueBtn"];
        if(e.target !== undefined && e.target.id!==undefined){

          if(allowEnterKeyFields.indexOf(e.target.id)!="-1" && this.props.isFormValid){
              ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'SUBMIT');
              if (this.props.findMeObj.searchType == constants.EMAIL) {
                let payload = {};
                payload.value = findMeObj.email;
                payload.endPoint = constants.findUserbyEmail;
                ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'EMAIL');
                return channelSelect(payload);
              }
              else if (this.props.findMeObj.searchType == constants.PHONE) {
                let payload = {};
                let value = {};
                value.siv_mobile = findMeObj.phone;
                value.siv_country = findMeObj.isoCode;
                payload.value = value;
                payload.endPoint = constants.findUserbyPhone;
                ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'PHONE');
                return channelSelect(payload);
              }
          }
        }
    }
   }

   const handleEmailPhoneSearch = () => {
    ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'SUBMIT');
    if (this.props.findMeObj.searchType == constants.EMAIL) {
      let payload = {};
      payload.value = findMeObj.email;
      payload.endPoint = constants.findUserbyEmail;
      ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'EMAIL');
      return channelSelect(payload);
    }
    else if (this.props.findMeObj.searchType == constants.PHONE) {
      let payload = {};
      let value = {};
      value.siv_mobile = findMeObj.phone;
      value.siv_country = findMeObj.isoCode;
      payload.value = value;
      payload.endPoint = constants.findUserbyPhone;
      ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'PHONE');
      return channelSelect(payload);
    }
   }

    const _handlePiiKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'PII_INFO', 'SUBMIT');
      this.props.submitIdentityOptions();
    }

    const _handleOtpKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'OTP_CODE', 'SUBMIT');
      this.props.submitAccessCode();
    }
    
    const bottomFunction = () => {
      document.body.scrollTop = document.body.scrollHeight - document.body.clientHeight;
      document.documentElement.scrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    }

    return (
      findMeObj.showDiv && (<div id="findMeVariantForm">
       {(window.innerWidth <= 479) && (<Button onClick={bottomFunction} id="downIndicator" 
       className={(footer != undefined && footer.showFooterContent != undefined && footer.showFooterContent === true)?"downIndicatorMobileWeb":null}
       style={{ cursor: "pointer" }} aria-label="Scroll to bottom" >
        <FaArrowDown className="down-arrow" />
       </Button>)}
        <div className="registration-content">

          <div className="registration-form-container" >
          {(window.innerWidth > 479) && <div className="findme-securepage"> <SecurePage gaCategory={gaCategory} /></div>
            }
            {
              (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
            }
            {((isMobileBrowser && !isMobile) || window.innerWidth > 479) && (findMeObj.page == constants.LANDING_PAGE) && (

              <div className="registration-lang-dropdown">
                <DropdownButton aria-label="Language Dropdown" tabIndex="-1"
                  id="findMeV_langLocale"
                  valueField="language"
                  textField="language"
                  buttonStyle='link'
                  data={WindowUtil.getLocaleList()}
                  onSelect={(valueField) => {
                    changeLocale(valueField.suffix);
                    ReactGA.sendevent(gaCategory, 'LANGUAGE_DROPDOWN_SELECT', valueField.suffix);
                    return setLocale(valueField)
                  }}>
                  <span style={{width:'100px'}}>{findMeObj.locale}</span>
                </DropdownButton>
              </div>

            )}
            {((isMobileBrowser && !isMobile) || window.innerWidth > 479) && (findMeObj.page != constants.LANDING_PAGE) && (
              <div className="registartion-cancel-icon-container focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'CODELESS', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                <svg id="findMeV_cancel" role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })} onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CODELESS', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor" style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                }}><g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>

            )}
          </div>

          {(findMeObj.page != constants.LANDING_PAGE && findMeObj.progressBarData != undefined && findMeObj.progressBarData.showProgressBar && !isMobile)&& (
            <div className="registration-progress-bar">
              <Stepper steps={findMeObj.progressBarData.steps} activeStep={findMeObj.progressBarData.activeStep} />
            </div>
          )}

         {(!isMobile)&& <hr style={{ borderTop: "1px solid #BEBEBE" }} />
         }
          <div className="registration-form padding-align-allflows">
            <div className="welcome-title title-width ev-margin-h">
              <div className="vdl-col-md-12">


              </div>
              <div className="vdl-col-md-12">

                {(findMeObj.page == constants.LANDING_PAGE) && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-lp-signup-title">{<FormattedMessage id="findMeWeb.signUptitle" />}</h1>
                  <div className="div-h5 fm-web-landingpage heading-text"> {<FormattedMessage id="findMeWeb.Landingpage1" />}</div>
                  <div className="div-h5 fm-web-landingpage heading-text"> {<FormattedMessage id="findMeWeb.Landingpage2" />}</div>
                </div>)}

                {(findMeObj.page == constants.EMAILPHONESEARCH_PAGE || findMeObj.page == constants.CONTRACTOR_PAGE) && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="findMe.emailPhoneSearch.title" />}</h1>
                </div>)}

                {findMeObj.page == constants.OTP_PAGE && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="otp.title" />}</h1>
                </div>)}

                {findMeObj.page == constants.IDENTIFIED_PAGE && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="identified.title" values={{ 0: WindowUtil.firstNameFormat(findMeObj.firstName) }} />}</h1>
                </div>)}

                {findMeObj.page == constants.REGISTRATIONLESS_DUAL_PAGE && (<div>
                <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="registrationless.title" values={{ 0 : <b>{findMeObj.firstName}</b>}}/>} </h1>
                <div className="registrationless-page heading-text">{<FormattedMessage id="registrationless.subtitle1" values={{ 0 : <b>{findMeObj.clientName}</b>}}/>}</div>
                </div>)}

                {findMeObj.page == constants.REGISTRATIONLESS_FED_PAGE && (<div>
                <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="registrationless.title" values={{ 0 : <b>{findMeObj.firstName}</b>}}/>} </h1>                
                <div className="registrationless-page">{<FormattedMessage id="registrationless.fedsubtitle" values={{ 0 : <b>{findMeObj.clientName}</b>}}/>}</div>
                </div>)}

                {(findMeObj.page == constants.MULTIORG_PAGE) && (
                  <div>
                    <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="findMe.multiorg.title" values={{ 0: WindowUtil.firstNameFormat(findMeObj.sivOptions["siv_firstname"].idenDataValue) }} />}</h1>
                    <div className=" heading-text fm-centre">{<FormattedMessage id="findMe.multiorg.subTitle" />}</div>
                  </div>
                )}

                {(findMeObj.page === constants.ERROR_OPTIONS_PAGE) && (
                  <div>
                {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && (<h1 id="registration-layout_welcome" className="page-title fm-error-options-page">{<FormattedMessage id="findMeWeb.title" />}</h1>)}

                {(findMeObj.redirectionFrom === constants.REGCODE_PAGE) && (<div>
                  <h1 id="registration-layout_welcome" className="fm-regcode-page-options" >
                  {<FormattedMessage id="invalidAttempts_RegcodeOptionsPage" />}</h1>
                  <div className="fm-invalid-attempts-options-page">{<FormattedMessage id="invalidAttemptsInfo_RegcodeOptionsPage" />}</div></div>)}

                 {(findMeObj.redirectionFrom === constants.ORC_IDENTITY_PAGE) && (<div>
                   <h1 id="registration-layout_welcome" className="fm-regcode-page-options">
                   {<FormattedMessage id="invalidAttempts_IdentityOptionsPage" />}</h1>
                <div className="fm-invalid-attempts-options-page">{<FormattedMessage id="invalidAttemptsInfo_IdentityOptionsPage" values={{ 0 : <b>{findMeObj.companyName}</b>}}/>}</div></div>)}

                </div>
                )}

                {(findMeObj.page == constants.PII_PAGE) && (
                  <div className="fm-centre">
                    <h1 id="registration-layout_welcome" className="fm-pii-page">{(code !== undefined && code === constants.ALREADY_REGISTERED) ? errorPageInfo : <FormattedMessage id="findMeWeb.title" />}</h1>
                  </div>
                )}
              </div>
            </div>

            {/*********************Find Me main page start *****************************/}
            {(findMeObj.page == constants.LANDING_PAGE) && (<div className="fm-form-padding" style={{ paddingBottom: "0 !important" }}>
              <div
                id="regcodeNo"
                className="form-group submit vdl-text-xs-center submit-button-align"
              >
                <Button type="submit" role="button" buttonStyle="primary" style={{
                  width: window.innerWidth <= 479 ? '100%' : "83%"
                }}
                  id="findMeV_findmeButton" className="safari-button-align"
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'SUBMIT');
                    return hasRegCode("EMAIL_OR_PHONE");
                  }}>
                  <div style={{textAlign : 'center'}}>{<FormattedMessage id="findMe.newUserSearchButtonVariant" />}</div>
                </Button>
              </div>

              <div
                id="regcodeYes"
                className="form-group submit vdl-text-xs-center submit-button-align"
              >
                <Button type="submit"  role="button" buttonStyle="secondary" style={{
                  width: window.innerWidth <= 479 ? '100%' : "83%"
                }}
                  id="findMeV_regcodeButton" className="safari-button-align"
                  onClick={() => {
                    isFirstTimeLoad = false;
                    ReactGA.sendevent(gaCategory, 'HAVE_REG_CODE', 'SUBMIT');
                    return hasRegCode("REGCODE");
                  }}>
                  {<FormattedMessage id="findMe.haveRegCodeButton" />}
                </Button>
              </div>
              {((isMobileBrowser && !isMobile) || window.innerWidth > 479) && (<div className="form-group submit vdl-text-xs-center cancel-button-align back-btn-div submit-button-align"><Button
                id="landingPage_backBtn" tabIndex="0" role="button"
                style={{ paddingBottom: "0px"}}
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CODELESS_LANDING', 'BACK');
                  return cancelAndCheckYourEntries();
                }}>
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button></div>)}

            </div>)}

            {/**************************find me page 2 email/phone search variant***************/}

             {(findMeObj.page === constants.EMAILPHONESEARCH_PAGE || findMeObj.page === constants.PII_PAGE) && 
               (<div>
                  <div className="fm-radio-align">
                    <Radio id="emailSearchRadio" className="cdless-email-radio-align" radioGroupValue={findMeObj.radioOption} value={constants.EMAIL_PHONE_RADIO} 
                    onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', constants.EMAIL_PHONE_RADIO)} onChange={this.handleRadioChange} ><FormattedMessage id="findMe.emailPhone.placeholder"/></Radio>
                
                    <Radio id="piiSearchRadio" className="cdless-pii-radio-align" radioGroupValue={findMeObj.radioOption} value={constants.PII_RADIO} 
                    onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', constants.PII_RADIO)} onChange={this.handleRadioChange} 
                    ><FormattedMessage id="findMe.variant.piiplaceholder"/></Radio>
                 </div> 

                 </div>
               )}

            {/**************************find me page 2 email/phone search variant***************/}

              {/**************************Identity page after email/phone search page start***************/}

            {findMeObj.page === constants.EMAIL_PHONE_SIV_PAGE && emailPhoneSearchSivObj != undefined && emailPhoneSearchSivObj.sivOptions !== undefined && (
              <FindMeEmailPhoneSivForm {...this.props}/>
            )}


            {/**************************Identity page after email/phone search page end***************/}

            {/**************************find me page 2 email/phone search***************/}
            {(findMeObj.page == constants.EMAILPHONESEARCH_PAGE && findMeObj.radioOption === constants.EMAIL_PHONE_RADIO) && (<div className="fm-form-code-padding">
             
              <form onKeyPress={_handleSearchKeyPress}>         
               <hr className="cdless-hr-align"/>     
                <div>
                    <div> 
               {(findMeObj.showAlert) && <Alert className="fm-variant-alert-align" type="error" id="searchalert" >
               <p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(findMeObj.showAlertMessage)}</p></Alert>}

               {(!findMeObj.showAlert) && <div id="emailPhoneSearch" className="heading-text cdless-email-radio-info fm-emailphone-page-content">{<FormattedMessage id="findMe.emailPhoneSearchInfo" />}</div>}

                  <div className="cdless-email-radio-content fm-emailphone-width">
                    <label>{<FormattedMessage id="findMe.emailPhone.placeholder" />}</label>
                    <EmailPhoneInput flagId="fm_PhoneCountryCd" id="emailPhone" onChange={handleEmailPhoneChange} showAutoFocus = {setAutoFocusOff}
                      onlyCountries={findMeObj.onlyCountries} errorMessage={this.props.intl.formatMessage({ id: 'findMe.searchValueError' })} />
                  </div><div style={{marginBottom:'3%'}}/></div> 
                  
                </div>
                              
                <div className="fm-continue-btn-div br-padding"><Button role="button"
                  style={{minHeight: '35px',minWidth: '180px'}}
                  id="emailPhone_continueBtn" 
                  className="primary cf-btn-cnt safari-button-align"
                  disabled={!isFormValid}
                  onClick={() => {
                    showAlertFlag = true;
                    handleEmailPhoneSearch();
                  }}
                >
                 <FormattedMessage id="findMe.searchBtn" /> 
                </Button>
                </div>
                </form>
                <div className="fm-emailphonesearch-back-btn-div"><Button
                  style={{paddingBottom: '0px'}} role="button" tabIndex="0"
                  id="emailPhone_backBtn"
                  className="primary cf-btn-cnt"
                  buttonStyle="link"
                  className="cf-font-size-btn cf-cancel-btn"
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'BACK');
                    setPrevPage(constants.LANDING_PAGE);
                    return goToMainPage();
                  }}
                >
                  <ChevronLeft className="back-icon-align"  />
                  <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
                </Button>
                </div>

               
               {findMeObj.contractorEnableFlag && ( <div className="contractor-footer-div br-padding">
              
                <div className=" fm-otc-footer">
                 <hr style={{borderTop: '1px solid rgb(190, 190, 190)',marginBottom:'10px'}}/>
                    <div>
                        <span
                            className="heading-text vf-code-subpanel1 fm-otp-footer-span"
                            id="contractor_signup_text"
                        >
                            {<FormattedMessage id="findMe.contractorSignUpTitle" />}
                        </span>
                        <Button role="button" tabIndex="0"
                            style={{ fontSize: "14px"}}
                            className="vf-code-subpanel2"
                            id="contractor_signup_link"
                            className="fm-otc-footer-font"
                            buttonStyle="link"
                           onClick={() => {return getContractorOptions();}}

                        > {<FormattedMessage id="findMe.contractorSignUpLink" />}
                        </Button>
                    </div>
                </div>
               </div>)}

              
            </div>)}

             {/**************************Contractor SIV page start***************/}

            {findMeObj.page === constants.CONTRACTOR_PAGE && contractorSivObj !== undefined && contractorSivObj.sivOptions !== undefined && (
              <FindMeContractorSivForm {...this.props}/>
            )}


            {/**************************Contractor SIV page end***************/}

            {/**************************Identified user page***************/}
            {(findMeObj.page == constants.IDENTIFIED_PAGE) && (<div className="heading-text fm-form-code-padding" style={{ textAlign: "center" }}>
              {(!findMeObj.identityText) && (

            		<span> <FormattedMessage id="externalVendor.title"/><span style={{paddingLeft:'3px'}}><b>{findMeObj.clientName}</b></span></span>)
              }
              {(findMeObj.identityText) && (

                <FormattedMessage id="findMe.identifiedUserenter" values={{ 0: <b>{findMeObj.clientName}</b> }} />)
              }
              <div className='fm-identity-margins'><Button role="button"
                style={{ minHeight: "35px", minWidth: "180px" }}
                id="findMeV_iden_continueBtn" type="submit"
                className="primary cf-btn-cnt safari-button-align"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'IDENTITY_PAGE', 'CLICK');
                  redirectToContactInfo();
                }}
              >
                {<FormattedMessage id="findMe.continueBtn" />}
              </Button>
              </div>
            </div>)}

            {/*********************Find Me Pii flow page*****************************/}

            {((findMeObj.page == constants.PII_PAGE && findMeObj.radioOption === constants.PII_RADIO && findMeObj.sivOptions != undefined)) && (<div className="fm-form-info-padding">
            
              <form onSubmit={_handlePiiKeyPress}>
               <hr className="cdless-hr-align"/>
                {/* Web pii options start */}
            {(findMeObj.showAlert) && <Alert className="fm-variant-alert-align" type="error" id="piiAlert" ><p role="alert" style={{marginTop: 0, marginBottom: 0}}> {ReactHtmlParser(findMeObj.showAlertMessage)}</p></Alert>}
                
              {(!findMeObj.showAlert) && <div id="piiSearchDiv" style={{paddingTop:'2%',paddingLeft:'7%',paddingRight:'6%'}} className="heading-text fm-emailphone-page-content">{<FormattedMessage id="findMe.variant.piiSearchInfo" />}</div>}
              
                {window.innerWidth > 479 && (<div>
                  <div className="vdl-row">
                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <label id="firstnameLabel">{findMeObj.sivOptions["siv_firstname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                        <span id="findMeV_fn_ques" className="vb-question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                          <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                            overlay={tooltip(ReactHtmlParser(this.props.intl.formatMessage({ id: findMeObj.runAdminFlow === true ? 'verifyPic_run_linkdesc' :'verifyPic_linkdesc' })))} >

                            <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                          </OverlayTrigger>
                        </span></label>
                    </div>

                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <label id="lastnameLabel">{findMeObj.sivOptions["siv_lastname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                      </label>
                    </div>
                  </div>

                  <div className="vdl-row">
                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <OverlayTrigger
                        trigger={['click', 'focus']} placement="bottom" isAriaExpanded={ false }
                        overlay={errorPopover(!findMeObj.errorFields["siv_firstname"].validationError, findMeObj.sivOptions["siv_firstname"].invalidMessage)}
                      >

                        <Textbox style={{ marginTop: "5px" }} aria-label={findMeObj.sivOptions["siv_firstname"].labelName}
                          id="findMeV_fn_textBox" autoFocus={setAutoFocusOff} autocomplete="given-name"
                          value={findMeObj.sivOptions["siv_firstname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_firstname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_firstname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_firstname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>

                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <OverlayTrigger
                        trigger={['click', 'focus']} placement="bottom" isAriaExpanded={ false }
                        overlay={errorPopover(!findMeObj.errorFields["siv_lastname"].validationError, findMeObj.sivOptions["siv_lastname"].invalidMessage)}
                      >


                        <Textbox style={{ marginTop: "5px" }} aria-label={findMeObj.sivOptions["siv_lastname"].labelName}
                          id="findMeV_ln_textBox" autocomplete="family-name"
                          value={findMeObj.sivOptions["siv_lastname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_lastname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_lastname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_lastname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>

                  </div>

                  {/* DOB And Country Start */}

                  <div className="form-group form-align" style={{ marginTop: "15px" }}>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="fulldob" htmlFor="fulldob_Label">{findMeObj.sivOptions["siv_fulldateofbirth"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="countryLabel" htmlFor="fulldob_Label">{<FormattedMessage id="findMe.country" />}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12" id={findMeObj.sivOptions["siv_fulldateofbirth"].labelName}>
                        <div className="fm-pii-dropdown-container-div" role="group" aria-labelledby={findMeObj.sivOptions["siv_fulldateofbirth"].labelName}>
                          <div className="fm-pii-month-dropdown-div">
                            <DropdownList aria-label="monthDropdown" 
                              id="findMeV_month"
                              value={findMeObj.fullMonth}
                              data={findMeObj.sivOptionsCalenderItems.fullMonthItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                                return updateFieldValueHandler("fullMonth", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-pii-day-dropdown-div">
                            <DropdownList aria-label="dayDropdown" 
                              id="findMeV_day"
                              value={findMeObj.fullDay}
                              data={findMeObj.sivOptionsCalenderItems.fullDayItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                                return updateFieldValueHandler("fullDay", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-pii-year-dropdown-div">
                            <DropdownList aria-label="yearDropdown" 
                              id="findMeV_year"
                              value={findMeObj.fullYear}
                              data={findMeObj.sivOptionsCalenderItems.fullYearItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_year' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR');
                                return updateFieldValueHandler("fullYear", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div">
                          <div className="fm-pii-country-dropdown-div">
                            <DropdownList aria-label="countryDropdown" 
                              id="findMeV_country" autocomplete="country"
                              value={findMeObj.selectedCountry}
                              data={findMeObj.countryDropdown}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'COUNTRY');
                                return updateFieldValueHandler("radio2", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {/* DOB and Country End */}

                  {/* EMP and SSN start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[0] && (<div style={{ marginTop: "20px" }}>
                    <div className="vdl-row">

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="findMeV_ssn_radio"
                          onKeyPress={ (e) => {    
                            if(e.key === 'Enter'){
                              e.preventDefault();
                              return;  
                            }                                        
                          } }
                          radioGroupValue={findMeObj.radio1}
                          value="ssn"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'SSN');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_ssn"].labelName}
                        </Radio>
                        <span id="findMeV_ssn_ques" className="fm-pii-ssn-span" onClick={() =>

                          ReactGA.sendevent(gaCategory, findMeObj.ssnType == "siv_ssn4" ? "SSN4" : "SSN", 'TOOLTIP')}>
                          <OverlayTrigger placement="right" trigger={['click','focus']} isAriaExpanded={ false }
                            overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                            <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                          </OverlayTrigger>
                        </span>
                      </div>

                        <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="findMeV_empid_radio"
                          onKeyPress={ (e) => {    
                            if(e.key === 'Enter'){
                              e.preventDefault();
                              return;   
                            }                                        
                          } }
                          radioGroupValue={findMeObj.radio1}
                          value="empId"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_empid"].labelName}
                        </Radio>
                      </div>
                    </div>

                    <div className="vdl-row">

                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_ssn"].validationError, findMeObj.sivOptions["siv_ssn"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px", width: findMeObj.ssnType == "siv_ssn4" ? '30%' : '92%' }}
                            id="findMeV_ssn_textBox" type="password" aria-label={findMeObj.sivOptions["siv_ssn"].labelName}
                            disabled={findMeObj.radio1 == "empId" ? true : false}
                            value={findMeObj.sivOptions["siv_ssn"].idenDataValue}
                            className={!findMeObj.errorFields["siv_ssn"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_ssn"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_ssn"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                      </div>

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >

                          <Textbox style={{ marginTop: "5px" }} aria-label={findMeObj.sivOptions["siv_empid"].labelName}
                            id="findMeV_empId_textBox"
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            disabled={findMeObj.radio1 == "ssn" ? true : false}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>)}

                  {/* ********************* EMP and SSN End ********************* */}


                  {/* EMP start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[1] && (<div>
                    <div className="vdl-row">

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="empLabel">{findMeObj.sivOptions["siv_empid"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px" }}
                            id="findMeV_onlyEmpid_textBox" aria-label={findMeObj.sivOptions["siv_empid"].labelName}
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>)}
                </div>)}
                {/* EMP End */}
                {/* Web pii options End */}


                {/* Mobile pii options start */}
                {window.innerWidth <= 479 && (<div>

                  <div className="vdl-row">
                    <div className="vdl-col-xs-12">
                      <label id="firstnameLabel">{findMeObj.sivOptions["siv_firstname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                        <span id="mobileFMV_fn_ques" className="vb-question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                          <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                            overlay={tooltip(ReactHtmlParser(this.props.intl.formatMessage({ id: findMeObj.runAdminFlow === true ? 'verifyPic_run_linkdesc' :'verifyPic_linkdesc' })))} >
                            <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                          </OverlayTrigger>
                        </span></label>
                    </div>
                    <div className="vdl-col-xs-12">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(!findMeObj.errorFields["siv_firstname"].validationError, findMeObj.sivOptions["siv_firstname"].invalidMessage)}
                      >


                        <Textbox style={{ marginBottom: "20px" }} aria-label={findMeObj.sivOptions["siv_firstname"].labelName}
                          id="mobileFMV_fn_textBox" autoFocus={setAutoFocusOff} autocomplete="given-name"
                          value={findMeObj.sivOptions["siv_firstname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_firstname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_firstname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_firstname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="vdl-row">
                    <div className="vdl-col-xs-12">
                      <label id="lastnameLabel">{findMeObj.sivOptions["siv_lastname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                      </label>
                    </div>
                    <div className="vdl-col-xs-12">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(!findMeObj.errorFields["siv_lastname"].validationError, findMeObj.sivOptions["siv_lastname"].invalidMessage)}
                      >
                        <Textbox style={{ marginBottom: "20px" }} autocomplete="family-name"
                          id="mobileFMV_ln_textBox"  aria-label={findMeObj.sivOptions["siv_lastname"].labelName}
                          value={findMeObj.sivOptions["siv_lastname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_lastname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_lastname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_lastname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>

                  {/* DOB And Country Start */}

                  <div className="form-group form-align" style={{ marginBottom: "20px" }}>
                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <label id="fulldob" htmlFor="fulldob_Label">{findMeObj.sivOptions["siv_fulldateofbirth"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                      <div className="vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div" id={findMeObj.sivOptions["siv_fulldateofbirth"].labelName}>
                          <div className="fm-mobile-pii-month-dropdown-div" role="group" aria-labelledby={findMeObj.sivOptions["siv_fulldateofbirth"].labelName}>
                            <DropdownList aria-label="monthDropdown" 
                              id="mobileFMV_month" autocomplete="bday-month"
                              value={findMeObj.fullMonth}
                              data={findMeObj.sivOptionsCalenderItems.fullMonthItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                                return updateFieldValueHandler("fullMonth", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-mobile-pii-day-dropdown-div">
                            <DropdownList aria-label="dayDropdown" 
                              id="mobileFMV_day" autocomplete="bday-day"
                              value={findMeObj.fullDay}
                              data={findMeObj.sivOptionsCalenderItems.fullDayItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                                return updateFieldValueHandler("fullDay", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-mobile-pii-day-dropdown-div">
                            <DropdownList aria-label="yearDropdown" 
                              id="mobileFMV_year" autocomplete="bday-year"
                              value={findMeObj.fullYear}
                              data={findMeObj.sivOptionsCalenderItems.fullYearItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_year' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR');
                                return updateFieldValueHandler("fullYear", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="vdl-row" style={{ marginTop: "20px" }}>
                      <div className="vdl-col-xs-12">
                        <label id="countryLabel" htmlFor="fulldob_Label">{<FormattedMessage id="findMe.country" />}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>

                      <div className="vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div">
                          <div className="fm-pii-country-dropdown-div">
                            <DropdownList aria-label="countryDropdown" 
                              id="mobileFMV_country" autocomplete="country"
                              value={findMeObj.selectedCountry}
                              data={findMeObj.countryDropdown}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'COUNTRY');
                                return updateFieldValueHandler("radio2", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {/* DOB and Country End */}

                  {/* EMP and SSN start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[0] && (<div >

                    {/* SSN block when codeless variant is on */}
                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="mobileFMV_ssn_radio"
                          onKeyPress={ (e) => {    
                            if(e.key === 'Enter'){
                              e.preventDefault();
                              return;    
                            }                                        
                          } }
                          radioGroupValue={findMeObj.radio1}
                          value="ssn"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'SSN');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_ssn"].labelName}
                        </Radio>
                        <span id="mobileFMV_ssn_ques" className="fm-pii-ssn-span" onClick={() => ReactGA.sendevent(gaCategory, findMeObj.ssnType == "siv_ssn4" ? "SSN4" : "SSN", 'TOOLTIP')}>
                          <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                            overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                            <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                          </OverlayTrigger>
                        </span>
                      </div>

                      <div className="vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom" isAriaExpanded={ false }
                          overlay={errorPopover(!findMeObj.errorFields["siv_ssn"].validationError, findMeObj.sivOptions["siv_ssn"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px", width: findMeObj.ssnType == "siv_ssn4" ? '30%' : '92%' }}
                            id="mobileFMV_ssn_textBox" type="password" aria-label={findMeObj.sivOptions["siv_ssn"].labelName}
                            disabled={findMeObj.radio1 == "empId" ? true : false}
                            value={findMeObj.sivOptions["siv_ssn"].idenDataValue}
                            className={!findMeObj.errorFields["siv_ssn"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_ssn"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_ssn"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                      </div>
                  </div>

                  {/* EMP block */}
                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="mobileFMV_empid_radio"
                          onKeyPress={ (e) => {    
                            if(e.key === 'Enter'){
                              e.preventDefault();
                              return;    
                            }                                        
                          } }
                          radioGroupValue={findMeObj.radio1}
                          value="empId"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_empid"].labelName}
                        </Radio>
                      </div>
                      <div className="vdl-col-xs-12">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px", marginBottom: "20px" }}
                            id="mobileFMV_empid_textBox" aria-label={findMeObj.sivOptions["siv_empid"].labelName}
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            disabled={findMeObj.radio1 == "ssn" ? true : false}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>)}

                  {/* ********************* EMP and SSN End ********************* */}


                  {/* EMP start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[1] && (<div>
                    <div className="vdl-row">

                      <div className="vdl-col-xs-12">
                        <label id="empLabel">{findMeObj.sivOptions["siv_empid"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px" }}
                            id="mobileFMV_onlyEmpid_textBox" aria-label={findMeObj.sivOptions["siv_empid"].labelName}
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>)}
                </div>)}
                {/* EMP End */}
                {/* Mobile pii options End */}

              
                <div className="fm-width-100 br-padding"><Button role="button"
                  style={{ minHeight: "35px", minWidth: "180px" }}
                  id="pii_continueBtn" type="submit"
                  className="primary cf-btn-cnt safari-button-align"
                  disabled={!isFormValid}
                  onClick={() => {
                    showAlertFlag = true;
                  }}>
                  {<FormattedMessage id="findMe.searchBtn" />}
                </Button>
                </div>
                <div className="fm-emailphonesearch-back-btn-div"><Button
                  style={{ paddingBottom: "0px" }} role="button" tabIndex="0"
                  id="pii_backBtn"
                  className="primary cf-btn-cnt"
                  buttonStyle="link"
                  className="cf-font-size-btn cf-cancel-btn"
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'PII_INFO', 'BACK'); if (prevPage == constants.EMAILPHONESEARCH_PAGE) return goToEmailPhoneSearchPage();
                    else if (prevPage == constants.ERROR_OPTIONS_PAGE) return goToErrorOptionsPage();
                    else {this.handleRadioChange(constants.EMAIL_PHONE_RADIO); return goToMainPage()};
                  }}
                >
                  <ChevronLeft className="back-icon-align"  />
                  <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
                </Button>
                </div>

              </form>
            </div>)}

            {/* Multi Org start */}
            {(findMeObj.page == constants.MULTIORG_PAGE) && (<div className="fm-multi-form-padding" style={{ marginBottom: "20px" }}>
             <div id="findMeV_morg"> 
              {findMeObj.multiOrgs.map((value, index) => (
              
                 <div className="vdl-row vf-row-size fm-row-size highlight-options focus-selector" tabIndex="0" id={index} key={index} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'ORG'); return orgSelected(index) }} 
                  onKeyPress={ (e) => {    
                          if(e.key === 'Enter'){
                            return orgSelected(index);              
                            ReactGA.sendevent(gaCategory, 'CLICK', 'ORG');   
                          }                                        
                        } }>
                  <BuildingIcon className="link-content vf-col-sm-2 vf-email-icon" />
                  <div className="vf-col-sm-9 vf-text-margin-left">
                    <div className="vf-text fm-multi-org-div"><span className="link-content">{value}</span></div>
                  </div>
                  <FaAngleRight style={{ fontSize: "24px" }} className="link-content vf-col-sm-1 vf-chevron-icon" />
                 </div>             
              ))}
             </div>
            </div>)}
            {/* Multi Org end */}

            {/* ONE TIME CODE START */}
            {(findMeObj.page == constants.OTP_PAGE) && (
              <form onSubmit={_handleOtpKeyPress} style={{ width: "100%" }}>
                <div id="verify-prc-form-2" className="vf-sub-form-padding ">
                  <div  className="heading-text" style={{ textAlign: "center", paddingBottom: "3%" }} id="verify-otp-info-2" >{
                    <FormattedMessage
                      id="findMeUniqueChannel.verifyOtp"
                      values={{
                        0: <b>{findMeObj.firstName}</b>,
                        1: <b>{findMeObj.clientName}</b>,
                        2: <b>{findMeObj.selectedIdentityValue}</b>,
                        3: findMeObj.expiry
                      }}
                    />}</div>
                  <div className="fm-form-code-padding fm-emailphone-width" style={{ margin: "0 auto" }}>
                    {(!findMeObj.showAlert) &&
                      (<span className="label-fields label-otp-align">{<FormattedMessage id="findMe.otpCode" />}</span>
                      )}
                    {(findMeObj.showAlert) && (<div style={{ textAlign: "center", color: "red" }} role="alert">
                      {ReactHtmlParser(findMeObj.showAlertMessage)}
                    </div>
                    )}

                    <input aria-label={this.props.intl.formatMessage({ id: 'findMe.otpCode' })}
                        id="prcTextBox"
                        type="tel"
                        autoFocus={setAutoFocusOff}
                        maxLength={6}
                        className='vf-otc-txt otp-textbox'
                        onChange={(event) => {
                          WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "OTP_VALUE" });
                          return updateFieldValueHandleOtp("otpValue", event)
                        }}
                        value={findMeObj.otpValue}
                      />
                    {findMeObj.showOnScreen && (<div id="accessCode" className="vf-access-code">{findMeObj.accessCode}</div>)}

                  </div>
                
                  <div className="fm-width-100 br-padding"><Button role="button"
                    style={{ minHeight: "35px", minWidth: "180px" }}
                    id="findMeV_otp_continueBtn" type="submit"
                    disabled={!isFormValid}
                    className="primary cf-btn-cnt safari-button-align"
                    onClick={() => {
                      showAlertFlag = true;
                    }}
                  >
                    {<FormattedMessage id="submitCBtn.Label" />}
                  </Button>
                  </div>
                </div></form>)}

            {/* ONE TIME CODE END */}



            {/*************************************Error page with options for invalid email/mobile entry*******************************************/}

            {(findMeObj.page == constants.ERROR_OPTIONS_PAGE) && (<div className="fm-multi-form-padding" >
             {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && (<div className="fm-text-align-centre"><WarnIcon className="warn-icon-info" style={{ verticalAlign: 'sub' }} />
                <span className="fm-error-page-info-span">{ReactHtmlParser(errorPageInfo)}</span></div>)} 

              {(errorPageInfo === undefined || errorPageInfo === '') && (<div tabIndex="0" role="button" id="findMeV_signup_errorPage" className="vdl-row fm-row-size fm-error-page-options-div focus-selector"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'CLICK');
                  this.handleRadioChange(constants.EMAIL_PHONE_RADIO);
                  return hasRegCode("EMAIL_OR_PHONE");
                }} onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                  ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'CLICK');
                  this.handleRadioChange(constants.EMAIL_PHONE_RADIO);
                  return hasRegCode("EMAIL_OR_PHONE");
                  }
                }}>
                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>              
                 <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.invalidAttmpt_searchInfo" />}</div>
                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
              </div>)}

              {(findMeObj.redirectionFrom !== constants.ORC_IDENTITY_PAGE) && (<div id="findMeV_piiOption" tabIndex="0" role="button" className="vdl-row fm-row-size fm-error-page-options-div focus-selector highlight-options" 
              onClick={() => {
                setErrorPageInfo(errorPageInfo);
                ReactGA.sendevent(gaCategory, 'PII_INFO', 'CLICK');
                if(findMeObj.redirectionFrom === constants.REGCODE_PAGE)
                  return this.updatePrevPage(constants.EMAILPHONESEARCH_PAGE);
                else  
                  return this.updatePrevPage(constants.ERROR_OPTIONS_PAGE);
              }} onKeyPress={() => {
                setErrorPageInfo(errorPageInfo);
                ReactGA.sendevent(gaCategory, 'PII_INFO', 'CLICK');
                if(findMeObj.redirectionFrom === constants.REGCODE_PAGE)
                  return this.updatePrevPage(constants.EMAILPHONESEARCH_PAGE);
                else  
                  return this.updatePrevPage(constants.ERROR_OPTIONS_PAGE);
              }}>
                 <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.piiInfo" />}</div>
                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
              </div>)}

               {errorPageInfo !== undefined && errorPageInfo !== '' && (<div id="findMeV_signupOption" tabIndex="0" role="button" className="vdl-row fm-row-size fm-error-page-options-div focus-selector highlight-options"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'CLICK');
                  this.handleRadioChange(constants.EMAIL_PHONE_RADIO);
                  return hasRegCode("EMAIL_OR_PHONE");
                }} onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                  ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'CLICK');
                  this.handleRadioChange(constants.EMAIL_PHONE_RADIO);
                  return hasRegCode("EMAIL_OR_PHONE");
                  }
                }}>
                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>
                  {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && (
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.signUpInfo" />}</div>)}
                   {(findMeObj.redirectionFrom === constants.REGCODE_PAGE || findMeObj.redirectionFrom === constants.ORC_IDENTITY_PAGE) && (
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.invalidAttmpt_searchInfo" />}</div>)}
                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
               </div>)}
              
             {(findMeObj.redirectionFrom !== constants.ORC_IDENTITY_PAGE) && ( <div id="findMeV_regcodeOption" tabIndex="0" role="button" className="vdl-row fm-row-size fm-error-page-options-div focus-selector highlight-options"
                onClick={() => {
                  isFirstTimeLoad = false;
                  ReactGA.sendevent(gaCategory, 'HAVE_REG_CODE', 'CLICK');
                  return hasRegCode("REGCODE");
                }} onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                  isFirstTimeLoad = false;
                  ReactGA.sendevent(gaCategory, 'HAVE_REG_CODE', 'CLICK');
                  return hasRegCode("REGCODE");
                  }
                }}>
                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>      
                  <div className="fm-font-size fm-error-page-option-color">
                  {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') ? <FormattedMessage id="findMe.errPage.regCodeInfo" />
                : <FormattedMessage id="findMe.errPage.invalidAttmpt_regcodeInfo" />}
                  
                  {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && <span id="findMeV_regcodeOption_ques" className="question-circle" onClick={this.handleTooltipClick}>
                      <OverlayTrigger placement="right" trigger={['click','focus']} isAriaExpanded={ false }
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyUser_pageInfo' }))} >
                        <span tabIndex="0" className="focus-selector"><InfoCircle style={{ verticalAlign: 'sub' }} /></span>
                      </OverlayTrigger>
                  </span>}
                </div>
                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
             </div>)}

             {(findMeObj.redirectionFrom === constants.ORC_IDENTITY_PAGE) && ( <div tabIndex="0" role="button" id="findMeV_identityRedirection" className="vdl-row fm-row-size fm-error-page-options-div focus-selector"
                onClick={() => {
                  isFirstTimeLoad = false;
                  ReactGA.sendevent(gaCategory, 'IDENTITY', 'CLICK');
                  return redirectToIdentityPage(constants.ORC_IDENTITY_PAGE);
                }} onKeyPress={() => {
                  isFirstTimeLoad = false;
                  ReactGA.sendevent(gaCategory, 'IDENTITY', 'CLICK');
                  return redirectToIdentityPage(constants.ORC_IDENTITY_PAGE);
                }}>
                 <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>                  
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.invalidAttmpt_tryagain" />}</div>
                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
              </div>)}

             {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && ( <div className="fm-width-100"><Button
              style={{paddingBottom:"0px",paddingTop:"20px"}} role="button" tabIndex="0"
                id="findMeV_options_backBtn"
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'ERROR_OPTIONS', 'BACK');
                  return goToMainPage();
                }}
              >
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button>
              </div>)}
              <div className="fm-error-page-bottom-padding"></div>
            </div>)}

            {/*************************************Error page end*******************************************/}

{ /** *******************Registration page view start **************************** */}

{findMeObj.page == constants.REGISTRATIONLESS_DUAL_PAGE && (<form id="registrationless-new-form" className="findme-registrationless-padding">
  
  <div style={{paddingTop:'10px'}}>
    <Radio id="regLessRadio" radioGroupValue={this.state.reglessOption} value={constants.REGLESS_RADIO} 
    onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', constants.REGLESS_RADIO)} onChange={this.handleReglessRadioChange} ><FormattedMessage id="registrationless.signinoption" values={{ 0 : <b>{findMeObj.clientName}</b>}}/> {ReactHtmlParser(this.props.intl.formatMessage({ id : 'registrationless.recommended' }))}</Radio>

    <Radio id="createAccRadio" radioGroupValue={this.state.reglessOption} value={constants.CREATE_ACC_RADIO} 
    onClick={() => ReactGA.sendevent(gaCategory, 'CLICK', constants.CREATE_ACC_RADIO)} onChange={this.handleReglessRadioChange} 
    ><FormattedMessage id="registrationless.createAccountOption"/></Radio>
  </div> 
  <div style={{textAlign : 'center',paddingTop:'25px'}}><Button role="button" buttonStyle="primary"
    id="findMeV_regless_Button" className="safari-button-align"
    onClick={() => {
      ReactGA.sendevent(gaCategory, 'REG_LESS_SUBMIT', 'SUBMIT');
      return this.invokeReglessSubmit(this.state.reglessOption);
    }}
    onKeyPress={ (e) => {    
      if(e.key === 'Enter'){
        e.preventDefault();
        return this.invokeReglessSubmit(this.state.reglessOption);
      }                                        
    }}>
   {<FormattedMessage id="continueBtn.Label" />}
  </Button></div>
</form>
)}

{findMeObj.page == constants.REGISTRATIONLESS_FED_PAGE && (<form id="registrationless-new-form" className="findme-registrationless-padding">
  <div style={{textAlign : 'center',paddingTop:'25px'}}><Button role="button" buttonStyle="primary" 
    id="findMeV_regless_Button" className="safari-button-align"
    onClick={() => {
      return registrationLessFlow();
    }}
    onKeyPress={ (e) => {    
      if(e.key === 'Enter'){
        e.preventDefault();
        return registrationLessFlow();  
      }                                        
    }}>
   {<FormattedMessage id="continueBtn.Label" />}
  </Button></div>
</form>
)}

{ /** *******************Registrationless page end **************************** */}

{ /** *********************************** Registrationless WARN MODAL****************************************** */}
              {showModalTitle && (
                <Modal
                  id="warnModal"
                  className="vdl-modal--alert"
                  show={findMeObj.showReglessModalWarn}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader
                    closeButton={false}
                    className="modal-header"
                  >
                    <ModalTitle className={findMeObj.errorCode === constants.TYPE_UNAUTHORIZED ? 'modal-title-error' : 'modal-title-info'}>
                      <WarnIcon className={findMeObj.errorCode === constants.TYPE_UNAUTHORIZED ? 'vdl-modal__title-icon warn-icon-error' : 'vdl-modal__title-icon warn-icon-info'} />
                      {showModalTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label
                        htmlFor="showModalMessage"
                        className="modal-text"
                      >
                        {ReactHtmlParser(showModalMessage)}
                      </label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button onClick={findMeObj.errorCode === constants.TYPE_UNAUTHORIZED ? cancelAndCheckYourEntries : continueRegistration}>
                      <FormattedMessage id="Lbl_OkButton" />
                    </Button>
                  </ModalFooter>
                </Modal>
              )}


            {/*************************************Error MODAL*******************************************/}
            {showModalTitle && (<Modal id="errorModal"
              className="vdl-modal--alert"
              show={showModalWarn}
              onEnter={() => { WindowUtil.applyBrandingChanges(); }}
            >
              <ModalHeader closeButton={false} className="modal-header">
                <ModalTitle className='modal-title-error'>
                  <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                  {showModalTitle}
                </ModalTitle>
              </ModalHeader>
              <ModalBody className="modal-body-footercolor">
                <div>
                  <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
                </div>
              </ModalBody>
              <ModalFooter className="modal-body-footercolor">
                <Button id="findMeV_okBtn" className="safari-button-align" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'ERROR_MODAL', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                  {<FormattedMessage id="Lbl_OkButton" />}
                </Button>
              </ModalFooter>
            </Modal>)}
            {/*************************************Error MODAL END*******************************************/}


          </div>
        </div>


        { /** **************************Footer OTP page starts************************************** */}

        {(findMeObj.page == constants.OTP_PAGE) && (
          <div>
            <div
              className="registration-content"
              className="fm-otc-footer"
            >
              <div>
                <span
                  className="heading-text vf-code-subpanel1 fm-otp-footer-span"
                  id="subTitle1"
                >
                  {<FormattedMessage id="findMe.otpFooterTitle" />}
                </span>
                <Button role="button" tabIndex="0"
                  style={{ fontSize: "14px" }}
                  className="vf-code-subpanel2"
                  id="findMeV_otp_resend"
                  disabled={findMeObj.disableResend}
                  className="fm-otc-footer-font opt-resend"
                  buttonStyle="link"
                  onClick={() => {
                    showAlertFlag = true;
                    let payload = findMeObj.otpChannelId;
                    ReactGA.sendevent(gaCategory, 'RESEND_OTP', 'CLICK');
                    return resendOTP(payload);
                  }}

                > {<FormattedMessage id="verifyPrc.btn1" />}
                </Button>
              </div>
            </div>
          </div>
        )}

        { /** **************************Footer OTP page end************************************** */}
      </div>
      )
    );
  }
}
export default injectIntl(FindMeVariantForm);