import enLocaleData from 'react-intl/locale-data/en';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import esLocaleData from 'react-intl/locale-data/es';
import zhLocaleData from 'react-intl/locale-data/zh';
import huLocaleData from 'react-intl/locale-data/hu';
import itLocaleData from 'react-intl/locale-data/it';
import jaLocaleData from 'react-intl/locale-data/ja';
import koLocaleData from 'react-intl/locale-data/ko';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import ruLocaleData from 'react-intl/locale-data/ru';
import skLocaleData from 'react-intl/locale-data/sk';
import csLocaleData from 'react-intl/locale-data/cs';

export const localeData = [
  ...enLocaleData,
  ...deLocaleData,
  ...frLocaleData,
  ...esLocaleData,
  ...zhLocaleData,
  ...huLocaleData,
  ...itLocaleData,
  ...jaLocaleData,
  ...koLocaleData,
  ...nlLocaleData,
  ...plLocaleData,
  ...ptLocaleData,
  ...ruLocaleData,
  ...skLocaleData,
  ...csLocaleData
];
