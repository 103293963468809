import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  Tooltip
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-bev/verify-bev-constants';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import Draggable from 'react-draggable';
import WindowClose from 'adp-react-icons/lib/fa/window-close';

const isBlank = str => (str || '').trim().length === 0;
let overlayPlacement = "right";


export default class VerifyBevIpayW2Form extends Component {

  componentWillMount() {
    this.props.getW2DropdownValues("iPayW2ServiceMBean");
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
  }

  render() {
    const {
      verifyBevObj,
      hideDraggableSet,
      showDraggableModal,
      showDraggable,
      onDropDownSelect,
      handleUpdateData,
      gaCategory

    } = this.props;

    const gaToolTip = (label) => ReactGA.sendevent(gaCategory, label, 'TOOLTIP');

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
        />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
          >
          <div role="alert">{msg}</div>
        </Popover>
      ));


    const {
      iPayW2ServiceMBean
    } = this.props.verifyBevObj.initData;

    const handleDataChange = (parentName, fieldName, fieldValue, regEx, index) => {
      let payload = {};
      payload.type = 2;
      payload.parentName = parentName;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.regEx = regEx;
      payload.index = index;
      WindowUtil.setFirstAttemptGA({ gaCategory: this.props.gaCategory + "_" + "IPAYW2", fieldName: fieldName });
      return handleUpdateData(payload);
    }
    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );
    const handleDraggable = (value) => {
      let showDraggable = value;
      showDraggableModal(showDraggable);
    }
    return (
      <div>
        <div className="form-group form-align">
          <div className="vdl-row">
            <div id="ipayw2_sampleDocBtn" className="vdl-col-sm-12 vdl-col-xs-12">
              <span role="button" className="vb-sample-doc focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'IPAY_W2_SAMPLE_DOC', 'MODAL');
                      return handleDraggable(true);
                    }
                  }} onClick={() => {
                ReactGA.sendevent(gaCategory, 'IPAY_W2_SAMPLE_DOC', 'MODAL');
                return handleDraggable(true)
              } }>{iPayW2ServiceMBean.viewSampleDocs.label}</span>
            </div>
          </div>
        </div>
        {/* Draggable component start */}
        {showDraggable && (
          <div className="vb-draggable-header" id="draggable">
            <Draggable defaultPosition={{ x: 50, y: -500 }} >
              <div className="vb-draggable-body" >
                <div style={{ textAlign: 'right' }}>
                  <WindowClose className="focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return handleDraggable(false);
                    }
                  }} onClick={() => handleDraggable(false)} className="vb-draggable-close" />
                </div>
                <div className="vb-touch-scroll vb-form-draggable-div">
                  {iPayW2ServiceMBean.viewSampleDocs.options.map((value, index) => (
                    <div key={index}>
                      <div className="vb-form-draggable-label">{value.label}</div>
                      <img id="cs-andriod-badget" width="300px" height="400px"
                        src={value.value} draggable="false"
                        />
                    </div>
                  ))}
                </div>
              </div>
            </Draggable>
          </div>)}
        {/* Draggable component end */}

        {/* Year component start */}
      <div id={iPayW2ServiceMBean.w2Year.label}>
        <div role="group" aria-labelledby={iPayW2ServiceMBean.w2Year.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-8 vdl-col-xs-15">
              <label id="fulldob" htmlFor="fulldob_Label">{iPayW2ServiceMBean.w2Year.label}
                <span className="vb-form-dob-req-icon">*</span></label>
            </div>
          </div>
          <div style={{ display: 'inline-flex' }}>
            <div className="full-dob-column-width">
              <DropdownList aria-label="monthDropdown" 
                id="ipayw2_month"
                data={verifyBevObj.w2YearList}
                placeholder={iPayW2ServiceMBean.w2Year.placeHolder}
                onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return handleDataChange("iPayW2ServiceMBean", "w2Year", value, null)}}
                value={iPayW2ServiceMBean.w2Year.value}
                />
            </div>
          </div>
        </div>
      </div>
        {/* Year component end */}

        {/* controlNumber component start */}
      <div id={iPayW2ServiceMBean.controlNumber.label}>
        <div role="group" aria-labelledby={iPayW2ServiceMBean.controlNumber.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="cnLabel">{iPayW2ServiceMBean.controlNumber.label}
              {iPayW2ServiceMBean.controlNumber.required && (<span className="vb-form-req-icon">*</span>)} 

              {!isBlank(iPayW2ServiceMBean.controlNumber.toolTip) && (<span role="button" aria-label="tooltip" id="ipayw2CN_ques" className="vb-question-circle" onClick={() => gaToolTip('IPAY_W2_CONTROL_NUMBER')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(iPayW2ServiceMBean.controlNumber.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[0], iPayW2ServiceMBean.controlNumber.message)}
              >
              <Textbox aria-label={iPayW2ServiceMBean.controlNumber.label}
                id="ipayw2CN_textBox"
                maxLength={iPayW2ServiceMBean.controlNumber.maxLength}
                placeholder={iPayW2ServiceMBean.controlNumber.placeHolder}
                value={iPayW2ServiceMBean.controlNumber.value}
                className={!verifyBevObj.errorFieldsBackend[0] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("iPayW2ServiceMBean", "controlNumber", value, iPayW2ServiceMBean.controlNumber.regEx, 0)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* controlNumber component end */}



        {/* ssn component start */}
      <div id={iPayW2ServiceMBean.ssn.label}>
        <div role="group" aria-labelledby={iPayW2ServiceMBean.ssn.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="ssnLabel">{iPayW2ServiceMBean.ssn.label}
              {iPayW2ServiceMBean.ssn.required && (<span className="vb-form-req-icon">*</span>)} 

              {!isBlank(iPayW2ServiceMBean.ssn.toolTip) && (<span role="button" aria-label="tooltip" id="ipayw2ssn_ques" className="vb-question-circle" onClick={() => gaToolTip('IPAY_W2_SSN_NUMBER')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(iPayW2ServiceMBean.ssn.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[1], iPayW2ServiceMBean.ssn.message)}
              >
              <Textbox
                id="ipayw2ssn_textBox" aria-label={iPayW2ServiceMBean.ssn.label}
                maxLength={iPayW2ServiceMBean.ssn.maxLength}
                type="password"
                placeholder={iPayW2ServiceMBean.ssn.placeHolder}
                value={iPayW2ServiceMBean.ssn.value}
                className={!verifyBevObj.errorFieldsBackend[1] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("iPayW2ServiceMBean", "ssn", value, iPayW2ServiceMBean.ssn.regEx, 1)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* ssn component end */}



        {/* wages component start */}
      <div id={iPayW2ServiceMBean.wages.label}>
        <div role="group" aria-labelledby={iPayW2ServiceMBean.wages.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="wagesLabel">{iPayW2ServiceMBean.wages.label}
              {iPayW2ServiceMBean.wages.required && (<span className="vb-form-req-icon">*</span>)} 

              {!isBlank(iPayW2ServiceMBean.wages.toolTip) && (<span role="button" aria-label="tooltip" id="ipayw2WC_ques" className="vb-question-circle" onClick={() => gaToolTip('IPAY_W2_WAGES')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(iPayW2ServiceMBean.wages.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[2], iPayW2ServiceMBean.wages.message)}
              >
              <Textbox aria-label={iPayW2ServiceMBean.wages.label}
                id="ipayw2WC_textBox"
                maxLength={iPayW2ServiceMBean.wages.maxLength}
                placeholder={iPayW2ServiceMBean.wages.placeHolder}
                value={iPayW2ServiceMBean.wages.value}
                className={!verifyBevObj.errorFieldsBackend[2] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("iPayW2ServiceMBean", "wages", value, iPayW2ServiceMBean.wages.regEx, 2)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* wages component end */}




      </div>)


  }
}
