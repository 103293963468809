export const constants = {
    updateCountryOptionApiEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/chooseCountry",
    hasRegCodeApiEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/helpUsFindYou",
    submitIdentityOptionsApiEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/findUserByPii",
    submitMultiOrgOptionApiEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/chooseClient",
    submitPodRedirectionApiEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/verifyUserDetails",
    verifyOTPEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/verifyOtp",
    generateOTPEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/generateOtp",
    piiOptionsEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/helpUsFindYou",
    verifySivDataEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/findme/verifyUserSecurityDetails',
    TYPE_ERROR_LOCKED: "Error_FunctionLocked",
    TYPE_ERROR_INVALID: "Error_SecCodeInvalid",
    TYPE_ERROR_EXPIRED: "Error_SecurityCodeExpired",
    TYPE_ERROR_INTERAL_ERROR: "Error_InternalError",
    TYPE_ERROR_FAILED: "Error_SendSecurityCodeFailed",
    findUserbyEmail: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/findUserByEmail",
    findUserbyPhone: "/pages/sms/ess/v3/ws/rest/pub/ssr/findme/findUserByMobile",
    setLocaleEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/updateLocale",
    contractorOptionsEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/contractor/sivoptions",
    verifyContractorSivEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/contractor/verifyidentity",
    contractorMultiOrgApiEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/contractor/chooseclient",
    contractorPodRedirectionEndPoint: "/pages/sms/ess/v3/ws/rest/pub/ssr/contractor/verifyuserdetails",
    SUCCESS: "200",
    findMeViewId: "/findMe",
    findMeVerifyPrcViewId: "/verifyPrc",
    identifyYourselfViewId: "/identifyYourself",
    verifyPicViewId: "/verifyPic",
    findMeEmailPhoneSearchViewId: "/findMeEmailPhoneSearch",
    findMePiiSearchViewId:"/findMePiiSearch",
    ALREADY_REGISTERED: "ALREADY_REGISTERED",
    FINDME_MULTIPLE_USERS_FOUND:"MULTIPLE_USERS_FOUND",
    FINDME_USER_NOT_FOUND:"USER_NOT_FOUND",
    EMAILPHONESEARCH_PAGE:"emailPhoneSearch",
    LANDING_PAGE:"landing",
    PII_PAGE:"piiInfo",
    ERROR_OPTIONS_PAGE:"errorOptions",
    CONTRACTOR_PAGE:"contractorInfo",
    IDENTIFIED_PAGE: "identified",
    OTP_PAGE:"otp",
    MULTIORG_PAGE:"multiOrg",
    REGCODE_PAGE: "REGCODE_PAGE",
    ORC_IDENTITY_PAGE: "ORC_IDENTITY_PAGE",
    PRC_IDENTITY_PAGE: "PRC_IDENTITY_PAGE",
    REGISTRATIONLESS_FED_PAGE: "REGISTRATIONLESS_FED_PAGE",
    REGISTRATIONLESS_DUAL_PAGE: "REGISTRATIONLESS_DUAL_PAGE",
    getRegistrationlessEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/findme/loginGoRegistrationLess',
    TYPE_UNAUTHORIZED: 'UNAUTHORIZED_ACCESS',
    EMAIL_PHONE_SIV_PAGE: "emailPhoneSearchSiv",
    EMAIL:"email",
    PHONE:"phone",
    EMAIL_PHONE_RADIO: "emailPhoneSearch",
    PII_RADIO: "piiSearch",
    REGLESS_RADIO: "regLessRadio",
    CREATE_ACC_RADIO: "createAccRadio",
    EMP_ID:'empId',
    SSN:'ssn',
    TIN:'tin',
    redirectToForgotFlowEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/alreadyregistered',
    MAX_SECURITY_OPTION_ATTEMPTS_REACHED:"MAX_SECURITY_OPTION_ATTEMPTS_REACHED",
    INVALID_SIVDATA:"INVALID_SIVDATA",
    DOB:"dob",
    SSN4:"ssn4",
    EMPID:"empId",
    WFNID:"wfnId",
    FNAME:"firstName",
    LNAME:"lastName",
    SIV_EMPID:"siv_empid",
    SIV_WFNID:"siv_wfnid",
    SIV_MONTH:"siv_month",
    SIV_DAY:"siv_day",
    SIV_YEAR:"siv_year",
    SIV_SSN4:"siv_ssn4",
    SIV_DOB:"siv_dateofbirth",
    RADIO:"radio",
    monthWith31days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    monthWith30days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
    monthWith29days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
    FIRSTNAME_COOKIE:"adp_user_fname",
    cookieExpiry:60 * 60 * 24 * 365,
    cookieDomain:".adp.com",
    cookiePath:"/",
    SPINFO_COOKIE:"adp_user_spInfo"

};