import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Textbox,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Alert,
  Popover,
  OverlayTrigger,
  DropdownButton
} from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { ReactGA } from '../../util';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import CancelIcon from 'adp-react-icons/lib/fa/times';
import { constants } from '../../redux/ducks/verify-user/verify-user-constants';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import Stepper from '../../components/react-stepper-horizontal';
import { WindowUtil } from '../../util';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
var isPageLoaded = false;
let setAutoFocusOff;

class VerifyUserForm extends Component {

  static propTypes = {
    regCode: PropTypes.string,
    regCodeValid: PropTypes.bool,
    setRegCode: PropTypes.func,
    verifyRegCode: PropTypes.func,
    errorTitle: PropTypes.string,
    isFormValid: PropTypes.bool,
    enableSubmitButton: PropTypes.func,
    showFailedModal: PropTypes.bool,
    welcomeMsg: PropTypes.string,
    errorMsgTitle: PropTypes.string,
    cancelRegistration: PropTypes.func,
    checkRegCodeErrMessage: PropTypes.func,
    code: PropTypes.string,
    hideFields: PropTypes.bool
  };

  componentWillMount() {
    if (!isPageLoaded) {
      //Handling embedded Prc changes
      let isEmbeddedPrcFlow = this.props.embeddedPrcFlow || false;
      if (isEmbeddedPrcFlow && (this.props.embeddedPrcInvalid == undefined || this.props.embeddedPrcInvalid == null)) {
        this.props.verifyRegCode("EMBEDDED_PRC_FLOW");
      }
      this.props.checkRegCodeErrMessage();
    }
    WindowUtil.setlayoutback(false);
    isPageLoaded = true;
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }

  componentWillReceiveProps = (nextProps) => {
    this.checkFormReadyness(nextProps);
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (isBlank(nextProps.regCode)) {
      formValid = false;
    }

    this.props.enableSubmitButton(formValid);
  };

  _handleKeyPress = (e) => {
    e.preventDefault();
    ReactGA.sendevent(this.props.gaCategory, 'VERIFY_USER', 'SUBMIT');
    this.props.verifyRegCode(this.props.regCode);
  }

  render() {
    console.log('rendering verify user form...');
    const {
      regCode,
      regCodeValid,
      setRegCode,
      verifyRegCode,
      errorTitle,
      isFormValid,
      showFailedModal,
      welcomeMsg,
      errorMsgTitle,
      cancelRegistration,
      checkRegCodeErrMessage,
      code,
      isMobileBrowser,
      isMobile,
      gaCategory,
      hideFields,
      goToFindMePage,
      isFindMeFlow,
      embeddedPrcFlow,
      embeddedPrcInvalid,
      progressBarData,
      setLocale
    } = this.props;


    const tooltip = (
      <Popover id="RegCodePopover">
        <div className="popover-align" role="alert" aria-label="tooltip">{<FormattedMessage id="verifyUser_pageInfo" />}</div>
      </Popover>
    );

    const regCodeTooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-userid">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );

    return (
      <div className={(embeddedPrcFlow == undefined || !embeddedPrcFlow || (embeddedPrcFlow == true && embeddedPrcInvalid)) ? "registration-content" : ""}>
        <div className="registration-form-container" >
          {!hideFields && (embeddedPrcFlow == undefined || !embeddedPrcFlow || (embeddedPrcFlow == true && embeddedPrcInvalid)) && window.innerWidth>479 && (<div style={{ paddingLeft: "14px", height: "1.5em" }}> <SecurePage gaCategory={gaCategory} /></div>)}
          {!hideFields && (embeddedPrcFlow == undefined || !embeddedPrcFlow || (embeddedPrcFlow == true && embeddedPrcInvalid)) && window.innerWidth<=479 && (<div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>)}

          {!hideFields && (!isMobile) && (embeddedPrcFlow == true && embeddedPrcInvalid) && (

            <div className="registration-lang-dropdown verify-user-lang-drop">

              <DropdownButton 
                id="verifyUser_langLocale"
                valueField="language"
                textField="language"
                buttonStyle='link'
                data={WindowUtil.getLocaleList()}
                onSelect={(valueField) => {

                  ReactGA.sendevent(gaCategory, 'LANGUAGE_DROPDOWN_SELECT', valueField.suffix);
                  return setLocale(valueField)

                }}>
                <span style={{width:'100px'}}>{this.props.updatedLocale}</span>
              </DropdownButton>
            </div>

          )}
          {!hideFields && (!isMobile) && (!(embeddedPrcFlow == true && embeddedPrcInvalid)) && (
            <div className="registartion-cancel-icon-container focus-selector" tabIndex="0" onKeyPress={() => {
                ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'CANCEL');
                return cancelRegistration()
              }}>
              <svg id="verifyUser_cancel" onClick={() => {
                ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'CANCEL');
                return cancelRegistration()
              }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                className="verify-user-cancel-reg"
              >
                <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
              </svg>
            </div>
          )}
        </div>

        {!hideFields && (embeddedPrcFlow == undefined || !embeddedPrcFlow || (embeddedPrcFlow == true && embeddedPrcInvalid)) && progressBarData != undefined && progressBarData.showProgressBar && !isMobile && (
          <div className="registration-progress-bar">
            <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
          </div>
        )}

        {!hideFields && (embeddedPrcFlow == undefined || !embeddedPrcFlow || (embeddedPrcFlow == true && embeddedPrcInvalid)) && !isMobile && (<hr style={{ borderTop: "1px solid #BEBEBE" }} />)}

        {(embeddedPrcFlow == undefined || !embeddedPrcFlow || (embeddedPrcFlow == true && embeddedPrcInvalid)) && (<div className="vdl-row registration-form">
          <div className="welcome-title title-width">
            <div className="vdl-col-md-12">
              {!hideFields && (<h1 id="registration-layout_welcome" className="page-title">{
                isFindMeFlow === true ? this.props.intl.formatMessage({ id: 'findMeWeb.regCodeTitle' }) : this.props.intl.formatMessage({ id: 'verifyUser.title' })}</h1>)}
            </div>
          </div>
          {errorTitle != undefined && errorTitle != '' && (<Alert
            type="error" style={{ width: '100%', marginLeft: '10%', marginRight: '10%' }}
          ><p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(errorTitle)}</p></Alert>
          )}
          <div className="vdl-col-md-12">
            <form className="adp-form padding-align-inc-width" onSubmit={this._handleKeyPress}>
              <div className="verify-user-reg-code-text" >
                <div>  {!hideFields &&
                  <label className="registration-code">{<FormattedMessage id="verifyUser.registrationCd" />}
                    {isFindMeFlow && (<span id="verifyUser_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'TOOLTIP')}>
                      <OverlayTrigger placement="top" trigger={['click','focus']} isAriaExpanded={ false }
                        overlay={regCodeTooltip(this.props.intl.formatMessage({ id: 'verifyUser_pageInfo' }))} >
                         <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                      </OverlayTrigger>
                    </span>)}</label>}
                </div>
                {!hideFields && <Textbox aria-label={this.props.intl.formatMessage({ id: 'verifyUser.registrationCd' })}
                  id="verifyUser_textBox"
                  className={regCodeValid ? 'vf-otc-txt' : 'vf-otc-txt vdl-validation-error'}
                  value={regCode} autoFocus={setAutoFocusOff}
                  onChange={
                    (value) => {
                      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "REG_CODE" });
                      return setRegCode(value);
                    }
                  }
                />}
              </div>

              {!hideFields && !isFindMeFlow && (
                <div role="button"
                  id="tooltip_text"
                  className="form-group submit vdl-text-xs-center submit-button-align" onClick={() => ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'TOOLTIP')}
                >
                  <OverlayTrigger placement="top" trigger={['click','focus']} isAriaExpanded={ false }
                    overlay={tooltip} >
                    <span tabIndex="0" className="focus-selector"><a aria-label="tooltip" id="verifyUser_info" className="tooltip-link">{<FormattedMessage id="verifyUser_linkdesc" />}</a></span>
                  </OverlayTrigger>
                </div>)}


              {!hideFields && (<div
                id="verifyUser-form_submit-button" 
                className="form-group submit vdl-text-xs-center submit-button-align button-padding"
              >
                <Button type="submit" role="button"
                  style={{ minHeight: "35px", minWidth: "180px" }}
                  id="verifyUser_continueBtn"
                  className="primary submit-button-align safari-button-align" disabled={!isFormValid}
                >
                  {<FormattedMessage id="submitCBtn.Label" />}
                </Button>
              </div>)}
              {/**** Back btn in case of find Me START ***/}
              {isFindMeFlow && (<div style={{ width: '100%' }}><Button
                id="verifyUser_backBtn" tabIndex="0" role="button"
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  isPageLoaded = false;
                  ReactGA.sendevent(gaCategory, 'VERIFY_USER', 'BACK');
                  return goToFindMePage("/findMe");
                }}
              >
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button>
              </div>)}

              {/**** Back btn in case of find Me END ***/}

              {errorMsgTitle && (<Modal id="errorModal"
                className="vdl-modal--alert"
                show={showFailedModal}
                onEnter={() => { WindowUtil.applyBrandingChanges(); }}
              >
                <ModalHeader closeButton={false} className="modal-header">
                  <ModalTitle className="modal-title-error">
                    <WarnIcon className="vdl-modal__title-icon warn-icon-error"
                    />
                    {errorMsgTitle}
                  </ModalTitle>
                </ModalHeader>
                <ModalBody className="modal-body-footercolor">
                  <div>
                    <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                  </div>
                </ModalBody>
                <ModalFooter className="modal-body-footercolor">
                  <Button id="verifyUser_okBtn" onClick={cancelRegistration} className="safari-button-align">
                    {<FormattedMessage id="Lbl_OkButton" />}
                  </Button>
                </ModalFooter>
              </Modal>)}

            </form>
          </div>
        </div>)
        }
      </div>
    );
  }
}
export default injectIntl(VerifyUserForm);
