import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { constants } from '../../redux/ducks/verify-401k/verify-401k-constants';
import { ReactGA, WindowUtil } from '../../util';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import Recaptcha from 'react-recaptcha';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import BuildingIcon from 'adp-react-icons/lib/fa/building-o';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
let showAlertFlag = false;
let setAutoFocusOff;

class Verify401kForm extends Component {
  static propTypes = {
    sivOptions: PropTypes.shape({
      availableSivOptions: PropTypes.shape({
        siv_firstname: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_lastname: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_ssn: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_fulldateofbirth: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
      }),
      sivPIC: PropTypes.bool,
      sivCPCOr: PropTypes.bool,
      sivOptionsCalenderItems: PropTypes.shape({
        fullYearItems: PropTypes.arrayOf(PropTypes.string),
        fullMonthItems: PropTypes.arrayOf(PropTypes.string),
        fullDayItems: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    setSivField: PropTypes.func,
    firstName: PropTypes.string,
    firstNameValid: PropTypes.bool,
    lastName: PropTypes.string,
    lastNameValid: PropTypes.bool,
    ssn: PropTypes.string,
    ssnValid: PropTypes.bool,
    fullMonth: PropTypes.string,
    fullDay: PropTypes.string,
    fullYear: PropTypes.string,
    verifySivOptions: PropTypes.func,
    errorMessage: PropTypes.string,
    getSivOptions: PropTypes.func,
    welcomeMsg: PropTypes.string,
    showErrorModal: PropTypes.bool,
    isFormValid: PropTypes.bool,
    enableSubmitButton: PropTypes.func,
    cancelAndCheckYourEntries: PropTypes.func,
    errorTitle: PropTypes.string,
    errorMessageFN: PropTypes.string,
    errorMessageLN: PropTypes.string,
    errorMessageSsn: PropTypes.string,
    errorMsgTitle: PropTypes.string,
    recaptchaResponse: PropTypes.string,
    setRecaptchaResponse: PropTypes.func,
    sitekey: PropTypes.string,
    isRecaptchaOn: PropTypes.bool,
    showModalError: PropTypes.bool,
    showModalTitle: PropTypes.string,
    showModalMessage: PropTypes.string,
    errorCode: PropTypes.string,
    recaptchaSuccess: PropTypes.bool,
    locale: PropTypes.string,
    userWithMultiOrg: PropTypes.bool,
    multiOrgs: PropTypes.array,
    multiOrgPageInfo: PropTypes.string,
    title: PropTypes.string,
    launch401kIdentityCall: PropTypes.bool,
    launch401kIdentity: PropTypes.func
  };

  componentWillMount() {
    this.props.getSivOptions();
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
    WindowUtil.setlayoutback(false);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sivOptions != null && nextProps.sivOptions.availableSivOptions != null) {
      this.checkFormReadyness(nextProps);
      this.handleMonthDaySelection(nextProps);
      if (nextProps.errorTitle != '' && showAlertFlag) {
        showAlertFlag = false;
        window.scrollTo(0, 0);
      }

    }
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (
      nextProps.sivOptions.availableSivOptions.siv_firstname &&
      (isBlank(nextProps.firstName) || !nextProps.firstNameValid)
    ) {
      formValid = false;
    }

    if (
      nextProps.sivOptions.availableSivOptions.siv_lastname &&
      (isBlank(nextProps.lastName) || !nextProps.lastNameValid)
    ) {
      formValid = false;
    }

    if (nextProps.sivOptions.availableSivOptions.siv_ssn && (isBlank(nextProps.ssn) || !nextProps.ssnValid)) {
      formValid = false;
    }

    if (nextProps.sivOptions.availableSivOptions.siv_fulldateofbirth &&
      (isBlank(nextProps.fullMonth) || isBlank(nextProps.fullDay) || isBlank(nextProps.fullYear))) {
      formValid = false;
    }

    if (nextProps.isRecaptchaOn && isBlank(nextProps.recaptchaResponse)) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  };

  handleMonthDaySelection = (nextProps) => {
    if (nextProps.sivOptions.availableSivOptions.siv_fulldateofbirth && nextProps.fullMonth != undefined && nextProps.fullMonth != '') {
      const inputMonthId = nextProps.sivOptions.sivOptionsCalenderItems.fullMonthItems.indexOf(nextProps.fullMonth) + 1;
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        nextProps.sivOptions.sivOptionsCalenderItems.fullDayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        nextProps.sivOptions.sivOptionsCalenderItems.fullDayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        nextProps.sivOptions.sivOptionsCalenderItems.fullDayItems = constants.monthWith29days;
      }
    }
  }

  _handleKeyPress = (e) => {
    e.preventDefault();
    ReactGA.sendevent(this.props.gaCategory, 'VERIFY_401K', 'SUBMIT');
    this.props.verifySivOptions();
  }

  handleRecaptchaResponse(value) {
    this.props.setRecaptchaResponse(value);
  }

  callback(value) {
    console.log('value callback======');
  }
  handleRecaptchaExpired(value) {
    this.props.setRecaptchaResponse(value);
  }

  render() {
    const {
      sivOptions,
      setSivField,
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      ssn,
      ssnValid,
      fullMonth,
      fullDay,
      fullYear,
      verifySivOptions,
      showErrorModal,
      welcomeMsg,
      errorMessage,
      isFormValid,
      cancelAndCheckYourEntries,
      errorTitle,
      errorMessageFN,
      errorMessageLN,
      errorMessageSsn,
      errorMsgTitle,
      recaptchaResponse,
      sitekey,
      isRecaptchaOn,
      showModalError,
      showModalTitle,
      showModalMessage,
      errorCode,
      setCancelModalError,
      recaptchaSuccess,
      locale,
      userWithMultiOrg,
      multiOrgs,
      multiOrgPageInfo,
      setOrgSelected,
      title,
      isMobileBrowser,
      isMobile,
      gaCategory,
      progressBarData
    } = this.props;

    const onCancelModalError = () => {
      let showModalError = false;
      return setCancelModalError(showModalError);
    }

    const gaToolTip = (label) => ReactGA.sendevent(gaCategory, label, 'TOOLTIP');

    const orgSelected = (index, disabled) => {
      if (disabled)
        return;
      return setOrgSelected(index);
    }

    const errorPopover = (fieldValid, errorMessage) =>
      (fieldValid && <Popover
        id="dummyPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{errorMessage}</div>
        </Popover>
      ));

    const tooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );
    const onSetFirstName = fName => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "FIRST_NAME" }); return setSivField(constants.sivOptionIds.siv_firstName, fName); }

    const onSetLastName = lName => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "LAST_NAME" }); return setSivField(constants.sivOptionIds.siv_lastName, lName); }

    const onSetSSN = inputSsn => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SSN" }); return setSivField(constants.sivOptionIds.siv_ssn, inputSsn); }

    const onSetDobFullMonth = month => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return setSivField(constants.sivOptionIds.siv_dob_full_month, month); }

    const onSetDobFullDay = day => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return setSivField(constants.sivOptionIds.siv_dob_full_day, day); }

    const onSetDobFullYear = year => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return setSivField(constants.sivOptionIds.siv_dob_full_year, year); }

    return (
      sivOptions && (

        <div className="registration-content">
          <div className="registration-form-container" >
          {(window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
          }
          {
            (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
          }
            {userWithMultiOrg === false && (!isMobile) && (
              <div className="reg-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_401K', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                <svg id="verify401k_cancel" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_401K', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }} role="button"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            )}
            {userWithMultiOrg === true && (!isMobile) && (
              <div className="reg-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_401K', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                <svg id="verify401k_morg_cancel" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_401K', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            )}
          </div>
          {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
            <div className="registration-progress-bar">
              <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
            </div>
          )}
          {(!isMobile) && <hr className="header-hr" />}
          <div className="vdl-row registration-form">
            <div className="welcome-title title-width">
              <div className="vdl-col-md-12">
                <h1 id="registration-layout_welcome" className="page-title">{title}</h1>
              </div>
            </div>
            {errorTitle != undefined && errorTitle != '' && (<Alert
              type="error" style={{ width: '100%', marginLeft: '10%', marginRight: '10%' }}
            ><p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(errorTitle)}</p></Alert>
            )}
            <div className="vdl-col-md-12">
              {userWithMultiOrg === true && (<label className="v401-multiorg-info-label">{ReactHtmlParser(multiOrgPageInfo)} </label>)}
              <form className="adp-form" onSubmit={this._handleKeyPress}>
                {userWithMultiOrg === false && sivOptions.availableSivOptions.siv_firstname && (
                  <div className="v401-form-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="firstname" htmlFor="first_name_Label">{sivOptions.availableSivOptions.siv_firstname.labelName}
                          <span className="v401-form-req-icon">*</span>
                          <span id="verify401k_fn_ques" className="question-circle" onClick={() => gaToolTip('FIRST_NAME')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc' }))} >
                              <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(firstNameValid, errorMessageFN)}
                      >

                        <Textbox aria-label={sivOptions.availableSivOptions.siv_firstname.labelName} autocomplete="given-name"
                          id="verify401k_fn_textBox" autoFocus={setAutoFocusOff}
                          className={firstNameValid ? undefined : 'vdl-validation-error'}
                          value={firstName}
                          onChange={onSetFirstName}
                        />
                      </OverlayTrigger>
                    </div>
                    <div />
                  </div>
                )}

                {userWithMultiOrg === false && sivOptions.availableSivOptions.siv_lastname && (
                  <div className="v401-form-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="lastname" htmlFor="last_name_Label">{sivOptions.availableSivOptions.siv_lastname.labelName}
                          <span className="v401-form-req-icon">*</span>
                          <span id="verify401k_ln_ques" className="question-circle" onClick={() => gaToolTip('LAST_NAME')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc2' }))} >
                              <span tabIndex="0" role="button" aria-label="lastNameTip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                            </OverlayTrigger>
                          </span>  </label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(lastNameValid, errorMessageLN)}
                      >

                        <Textbox aria-label={sivOptions.availableSivOptions.siv_lastname.labelName}
                          id="verify401k_ln_textBox" autocomplete="family-name"
                          className={lastNameValid ? undefined : 'vdl-validation-error'}
                          value={lastName}
                          onChange={onSetLastName}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}

                {userWithMultiOrg === false && sivOptions.availableSivOptions.siv_ssn && (
                  <div className="v401-form-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="ssn" htmlFor="ssn_Label">{sivOptions.availableSivOptions.siv_ssn.labelName}
                          <span className="v401-form-req-icon">*</span> </label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(ssnValid, errorMessageSsn)}
                      >
                        <Textbox aria-label={sivOptions.availableSivOptions.siv_ssn.labelName}
                          id="verify401k_ssn_textBox"
                          type="password"
                          className={ssnValid ? undefined : 'vdl-validation-error'}
                          value={ssn}
                          onChange={onSetSSN}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}

                {userWithMultiOrg === false && sivOptions.availableSivOptions.siv_fulldateofbirth && (
                  <div id={sivOptions.availableSivOptions.siv_fulldateofbirth.labelName}>
                  <div className="v401dob-form-padding" role="group" aria-labelledby={sivOptions.availableSivOptions.siv_fulldateofbirth.labelName}>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-8 vdl-col-xs-15">
                        <label id="fulldob" htmlFor="fulldob_Label">{sivOptions.availableSivOptions.siv_fulldateofbirth.labelName}
                          <span className="v401-form-req-icon">*</span></label>
                      </div>
                    </div>
                    <div className="v401-dropdown-div">
                      <div className="monthdropdown-cpc-align">
                        <DropdownList aria-label="monthDropdown" autocomplete="bday-month" 
                          id="verify401k_month"
                          value={fullMonth}
                          onChange={onSetDobFullMonth}
                          data={sivOptions.sivOptionsCalenderItems.fullMonthItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_month' })}
                        />
                      </div>
                      <div className="v401dob-column-width daydropdown-align">
                        <DropdownList aria-label="dayDropdown" 
                          id="verify401k_day" autocomplete="bday-day"
                          value={fullDay}
                          onChange={onSetDobFullDay}
                          data={sivOptions.sivOptionsCalenderItems.fullDayItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_day' })}
                        />
                      </div>
                      <div className="v401dob-column-width year-dropdown-cpcflow">
                        <DropdownList aria-label="yearDropdown" 
                          id="verify401k_year" autocomplete="bday-year"
                          value={fullYear}
                          onChange={onSetDobFullYear}
                          data={sivOptions.sivOptionsCalenderItems.fullYearItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_year' })}
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                )}

                {userWithMultiOrg === false && isRecaptchaOn && recaptchaSuccess == false && (
                  <div id="verify401k_recaptcha" className="v401-form-padding">
                    <Recaptcha sitekey={sitekey} value={recaptchaResponse}
                      hl={locale} render="explicit"
                      verifyCallback={this.handleRecaptchaResponse.bind(this)}
                      onloadCallback={this.callback.bind(this)}
                      expiredCallback={this.handleRecaptchaExpired.bind(this)} />
                  </div>
                )}
                {userWithMultiOrg === false && (<div
                  id="sivoptions-form_submit-button"
                  className="form-group submit vdl-text-xs-center submit-button-align button-padding" style={{ marginBottom: '40px' }}
                >
                  <Button type="submit" role="button"
                    style={{ minHeight: "35px", minWidth: "180px" }}
                    id="verify401k_continueBtn"
                    disabled={!isFormValid} onClick={() => showAlertFlag = true}
                    className="primary submit-button-align safari-button-align"
                  >
                    {<FormattedMessage id="submitCBtn.Label" />}
                  </Button></div>)}

              </form>
              {/************************************MultiOrg Page View*******************************************/}

              <form className="adp-form v401mo-form-padding" onSubmit={this._handleKeyPress}>
                {userWithMultiOrg === true && multiOrgs && <div id="verify401k_morg" className="v401-multiorg-div">
                  {
                    multiOrgs.map((value, index) => (
                      <div  role="button" tabIndex="0" className="vdl-row vf-row-size v401k-row-size highlight-options focus-selector" id={index} key={index}
                       onKeyPress={ (e) => {    
                          if(e.key === 'Enter'){
                            return orgSelected(index);              
                            ReactGA.sendevent(gaCategory, 'CLICK', 'ORG');   
                          }                                        
                        } }
                       onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'ORG'); return orgSelected(index) }} >
                        <BuildingIcon className="link-content vf-col-sm-2 vf-email-icon" />
                        <div className="vf-col-sm-9 vf-text-margin-left">
                          <div className="vf-text v401-multiorg-val"><span className="link-content">{value}</span></div>
                        </div>
                        <FaAngleRight style={{ fontSize: "24px" }} className="link-content vf-col-sm-1 vf-chevron-icon" />
                      </div>
                    ))
                  }</div>}


                {errorMsgTitle && (<Modal
                  className="vdl-modal--alert"
                  show={showErrorModal}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className={errorCode === constants.ALREADY_REGISTERED ? 'modal-title-info' : 'modal-title-error'}>
                      <WarnIcon className={errorCode === constants.ALREADY_REGISTERED ? 'vdl-modal__title-icon warn-icon-info' : 'vdl-modal__title-icon warn-icon-error'}
                      />
                      {errorMsgTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="verify401k_okBtn" onClick={cancelAndCheckYourEntries} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}
              </form>
            </div>
          </div>
        </div>
      )
    );
  }
}
export default injectIntl(Verify401kForm);
