
import React, { Component } from 'react';

import {
  BusyIndicator
  
} from '@synerg/vdl-react-components';
export default class Loader extends Component {

  render() {


    return (


      <BusyIndicator
        style={{ color: "#1a8099" }}
        size="xl"
        overlay={true}
        fullScreen={true}
        />
    )
  }
}
