import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';

const App = ({ history }) => (
  <ConnectedRouter history={ history }>
    <Routes />
  </ConnectedRouter>
);

App.propTypes = {
  history: PropTypes.shape,
};

export default App;
