import { ReactGA } from '../util';
export const profilingDomain = 'image.online.adp.com';
export const TM_PAGE_ID = 'adp_aim_olp'; // optional parameter in threat metrix call to differentiate mywisely

export function initProfiling(orgIdForLN , transactionId) {

  try {
      atoLNVIntObj.profile(profilingDomain, orgIdForLN, transactionId);
      ReactGA.sendevent('DEVICE_ASSESSMENT_SSR', 'DEVICE_ASSESSMENT_JS', 'SUCCESS');
   } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('initalizing threatMetrix failed', error);
    ReactGA.sendevent('DEVICE_ASSESSMENT_SSR', 'DEVICE_ASSESSMENT_JS', 'FAILURE');
  }
}
