import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions as localeActions } from '../../redux/ducks/locale';
import { actions as verifyUserActions } from '../../redux/ducks/verify-user';
import { actions as timeoutActions } from '../../redux/ducks/timeout';
import { RegistrationLayout } from '../../layouts';
import { VerifyUserForm } from '../../components';

export const VerifyUserContainer = props => <RegistrationLayout { ...props } />;

const loginTitle = <FormattedMessage id="verifyUser.title" />;
const mapStateToProps = ({
  locale, sendingRequest, verifyUser, timeout, registration
}) => ({
  ...locale,
  ...sendingRequest,
  ...verifyUser,
  ...timeout,
  ...registration
});
// prettier-ignore
const mapDispatchToProps = dispatch => bindActionCreators(
  Object.assign({}, { ...localeActions }, { ...verifyUserActions }, { ...timeoutActions }), dispatch,
);
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };

  Object.assign(props, { title: loginTitle, mainPane: <VerifyUserForm { ...props } /> });

  return props;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(VerifyUserContainer);
