import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-kba-constants';
import { types } from '../verify-kba-actions';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  VERIFY_KBA_INFO_OPTIONS,
  VERIFY_KBA_INFO_OPTIONS_SUCCESS,
  VERIFY_KBA_INFO_OPTIONS_FAIL,
  VERIFY_KBA_INFO_OPTIONS_UPDATE,
  VERIFY_KBA_TIME_EXPIRED,
  VERIFY_KBA_SET_ANSWER_SELECTED,
  VERIFY_KBA_REDIRECT,
  CANCEL_KBA_AND_CHECK_YOUR_ENTRIES,
  VERIFY_KBA_PRC_REDIRECT,
  KBA_DISCLAIMER_INFO,
  CANCEL_KBA
} = types;


const getVerifyKbaInfoOptionsSuccess = createAction(VERIFY_KBA_INFO_OPTIONS_SUCCESS);
const getVerifyKbaOptionsFail = createAction(VERIFY_KBA_INFO_OPTIONS_FAIL);
const setKbaValueSuccessSent = createAction(VERIFY_KBA_INFO_OPTIONS_UPDATE);


//const getFeedDataApi = (getState) => Observable.ajax.getJSON('/public/mocks/verifyKbaInit.json');
//const getFeedDataApi1 = (getState) => Observable.ajax.getJSON('/public/mocks/verifyKbaInitFailed.json');

//const setAnswerSelectedApi = () => Observable.ajax.getJSON('/public/mocks/verifyKbaNextQst.json');
//const setAnswerSelectedApi2 = () => Observable.ajax.getJSON('/public/mocks/verifyKbaPassed.json');
//const setAnswerSelectedApi3 = () => Observable.ajax.getJSON('/public/mocks/verifyKbaFailed.json');
//const setOnTimeExpiredApi = () => Observable.ajax.getJSON('/public/mocks/verifyKbaTimeExpired.json');



const getFeedDataApi = (ajax, getState) => ajax({
  url: constants.getVerifyKbaInitialDataAPI,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const setAnswerSelectedApi = (ajax, req, getState) => ajax({
  url: constants.getVerifyKbaNextQuestionAPI,
  method: 'POST',
  responseType: 'json',
  body: {
    'answerID': req.answerID,
    'questionID': req.questionID

  },
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const setOnTimeExpiredApi = (ajax, getState) => ajax({
  url: constants.getVerifyKbaTimeoutAPI,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const getVerifyKbaInfo = (ajax, getState) =>
  getFeedDataApi(ajax, getState)
    .pipe(map(response => handleResponse(response.response, getState)),
      catchError(err => [getVerifyKbaOptionsFail(getErrorMessage(err))]));

const setOnTimeExpired = (ajax, getState) =>
  setOnTimeExpiredApi(ajax, getState)
    .pipe(map(response => handleTimeExpiredResponse(response.response, getState)),
      catchError(err => [getVerifyKbaOptionsFail(getErrorMessage(err))]));


const setOnCancel = (ajax, getState) =>
  setOnTimeExpiredApi(ajax, getState)
    .pipe(map(response => handleCancelResponse(response.response, getState)),
      catchError(err => [getVerifyKbaOptionsFail(getErrorMessage(err))]));


const handleCancelResponse = (response, getState) => {
  return setKbaValueSuccessSent({
    sendCancelRedirect: true
  });

}

const showDisclaimerModal = (getState) =>
  showDisclaimerModalHandler(getState)
    .pipe(map(setKbaValueSuccessSent),
      catchError(err => [getVerifyKbaOptionsFail(getErrorMessage(err))]));



const showDisclaimerModalHandler = (data, getState) => {
  let verifyKbaObj = Object.assign({}, getState.verifyKba.verifyKbaObj);
  verifyKbaObj.showDisclaimer = true;
  return of({
    verifyKbaObj
  });
}

const getDisclaimerInfo = (getState) =>
  getDisclaimerInfoSent(getState)
    .pipe(map(response => setKbaValueSuccessSent(response)));


const getDisclaimerInfoSent = (getState) => {
  let verifyPrc = Object.assign({}, getState.verifyPrc);
  let redirectPrc = verifyPrc.verifyPrcObj.showDiffMethodBtn;
  let disableFields = verifyPrc.verifyPrcObj.disableFields;
  let isPrc = verifyPrc.verifyPrcObj.showDiv;
  let fromPrc = false;
  const isMobile = getState.registration.mobileFlow;
  let gaCategory = "WEB_REGISTRATION_VERIFY_KBA";
  let pagePath = '/ssr/flowType/verifyKba';
  let pageView = pagePath + "/web";

  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_KBA"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_KBA"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
   // progress bar start
   let stage = progressBarConstants.Identification;
   if (getState.contactInfo.contactInfoOptions.emailItems != null) stage = progressBarConstants.Contact_Info;
   let progressBarData = WindowUtil.getProgressBarData(stage, getState);
   // progress bar end
  
  if (redirectPrc != undefined && redirectPrc && isPrc != undefined && isPrc) {
    for (var i = 0; i < disableFields.length; i++) {
      if (disableFields[i] == false) fromPrc = true;
    }

  }
  return of({
    fromPrc,
    gaCategory,
    isMobile,
    progressBarData
  });
}


const handleResponse = (response, getState) => {
  let verifyKbaObj = Object.assign({}, getState.verifyKba.verifyKbaObj);
  if (response.code == constants.TYPE_QUESTIONS) {
    verifyKbaObj = response;
    verifyKbaObj.showDisclaimer = false;
    verifyKbaObj.isBlocked = false;
    verifyKbaObj.showDiv = true;
    // progress bar start
    let stage = progressBarConstants.Identification;
    if (getState.contactInfo.contactInfoOptions.emailItems != null) stage = progressBarConstants.Contact_Info;
    let progressBarData = WindowUtil.getProgressBarData(stage, getState);
    // progress bar end
    return getVerifyKbaInfoOptionsSuccess({
      verifyKbaObj, progressBarData
    });
  }
  else {
    verifyKbaObj.showModalError = true;
    verifyKbaObj.showModalTitle = response.title;
    verifyKbaObj.showModalMessage = response.message;
    verifyKbaObj.isBlocked = true;
    verifyKbaObj.showDiv = false;
    verifyKbaObj.showDisclaimer = false;
    return setKbaValueSuccessSent({
      verifyKbaObj
    });
  }


}


const handleTimeExpiredResponse = (response, getState) => {
  let verifyKbaObj = Object.assign({}, getState.verifyKba.verifyKbaObj);
  if (response.code == constants.TYPE_EXPIRED) {
    verifyKbaObj.showModalError = true;
    verifyKbaObj.showModalTitle = response.title;
    verifyKbaObj.showModalMessage = response.message;
    return setKbaValueSuccessSent({
      verifyKbaObj
    });
  }
  else return setKbaValueSuccessSent({
    viewId: response.viewId,
    sendRedirect: true
  });

}

const setAnswerSelected = (ajax, index, getState) => {
  let verifyKbaObj = Object.assign({}, getState.verifyKba.verifyKbaObj);
  var req = {};
  req.questionID = verifyKbaObj.questionID;
  req.answerID = verifyKbaObj.answers[index].answerID;
  return setAnswerSelectedApi(ajax, req, getState)
    .pipe(map(response => handleResponseAnswerSelected(response.response, index, getState)),
      catchError(err => [getVerifyKbaOptionsFail(getErrorMessage(err))]));
}
const handleResponseAnswerSelected = (response, index, getState) => {
  let verifyKbaObj = Object.assign({}, getState.verifyKba.verifyKbaObj);
  if (response.code == constants.TYPE_QUESTIONS) {
    if(document.getElementById("promptId")!==undefined){
      document.getElementById("promptId").focus();
    }
    return handleResponse(response, getState);
  }
    

  else if (response.code == constants.TYPE_PASSED) {
    return setKbaValueSuccessSent({
      viewId: response.viewId,
      sendRedirect: true
    });
  }
  else {
    verifyKbaObj.showModalError = true;
    verifyKbaObj.showModalTitle = response.title;
    verifyKbaObj.showModalMessage = response.message;
    verifyKbaObj.isBlocked = true;
    return setKbaValueSuccessSent({
      verifyKbaObj
    });
  }
}

export const getVerifyKbaInfoEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_KBA_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getVerifyKbaInfo(ajax, state.value))));

export const setOnTimeExpiredEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_KBA_TIME_EXPIRED),
  switchMap(action => sendingAsyncRequest(setOnTimeExpired(ajax, state.value))));

export const cancelKbaEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CANCEL_KBA),
  switchMap(action => sendingAsyncRequest(setOnCancel(ajax, state.value))));


const redirectToLoginPage = getState => {
  getState.verifyKba.sendCancelRedirect = false;
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url))), '_self');
  }
}

export const setAnswerSelectedEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_KBA_SET_ANSWER_SELECTED),
  switchMap(action => sendingAsyncRequest(setAnswerSelected(ajax, action.payload, state.value))));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_KBA_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const getDisclaimerInfoEpic = (action$, state) => action$.pipe(
  ofType(KBA_DISCLAIMER_INFO),
  switchMap(action => sendingAsyncRequest(getDisclaimerInfo(state.value))));

export const getVerfiyKbaInfoOptionsFailureEpic = action$ => action$.pipe(
  ofType(VERIFY_KBA_INFO_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))));

export const setRedirectSentEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_KBA_REDIRECT),
  switchMap(action$ => {
    ReactGA.sendevent(state.value.verifyKba.gaCategory, 'VERIFY_KBA', 'SUCCESS');
    return of(push(state.value.verifyKba.viewId))
  }));

export const setPrcRedirectSentEpic = action$ => action$.pipe(
  ofType(VERIFY_KBA_PRC_REDIRECT),
  switchMap(action$ => of(push('/verifyPrc'))));

