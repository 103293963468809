import { combineEpics } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import * as initialLoad from './initial-load/epics';
import * as registration from './registration/epics';
import * as identifyYourself from './identify-yourself/epics';
import * as verifyUser from './verify-user/epics';
import * as verifyUserId from './verify-user-id/epics';
import * as verifyUserPin from './verify-user-pin/epics';
import * as timeout from './timeout/epics';
import * as verifyPic from './verify-pic/epics';
import * as contactInfo from './contact-info/epics';
import * as registerUser from './register-user/epics';
import * as confirmation from './confirmation/epics';
import * as verifyPrc from './verify-prc/epics';
import * as ExternalVendor from './external-vendor/epics';
import * as verifyKba from './verify-kba/epics';
import * as verify401k from './verify-401k/epics';
import * as verifyBev from './verify-bev/epics';
import * as findMe from './find-me/epics';
import * as findMeMobile from './find-me-mobile/epics';
import * as verifyAdmin from './verify-admin/epics';

const epics = [
  ...Object.values(initialLoad),
  ...Object.values(registration),
  ...Object.values(identifyYourself),
  ...Object.values(verifyUser),
  ...Object.values(verifyUserId),
  ...Object.values(verifyUserPin),
  ...Object.values(verifyPic),
  ...Object.values(timeout),
  ...Object.values(contactInfo),
  ...Object.values(registerUser),
  ...Object.values(confirmation),
  ...Object.values(verifyPrc),
  ...Object.values(ExternalVendor),
  ...Object.values(verifyKba),
  ...Object.values(verify401k),
  ...Object.values(verifyBev),
  ...Object.values(findMe),
  ...Object.values(findMeMobile),
  ...Object.values(verifyAdmin)
];

export const rootEpics = (...args) => combineEpics(...epics)(...args, { ajax });
