import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-bev-constants';
import { types } from '../verify-bev-actions';
import { ReactGA } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  VERIFY_BEV_INFO_OPTIONS,
  VERIFY_BEV_INFO_OPTIONS_SUCCESS,
  VERIFY_BEV_INFO_OPTIONS_FAIL,
  VERIFY_BEV_DROPDOWN_SELECT,
  VERIFY_BEV_UPDATE_DATA,
  VERIFY_BEV_GET_DROPDOWN_VALUES,
  VERIFY_BEV_GET_W2_DROPDOWN_VALUES,
  VERIFY_BEV_SET_IPAY_RADIO_BTN,
  VERIFY_BEV_SUBMIT_BTN,
  CANCEL_BEV_AND_CHECK_YOUR_ENTRIES,
  ON_FINAL_VERIFY_BEV_SUCCESS

} = types;

const isBlank = str => (str || '').trim().length === 0;

const getVerifyBevInfoOptionsSuccess = createAction(VERIFY_BEV_INFO_OPTIONS_SUCCESS);
const getVerifyBevOptionsFail = createAction(VERIFY_BEV_INFO_OPTIONS_FAIL);

const getFeedDataApi = (ajax,transactionId) => {
  return ajax({
    url: constants.getBEVFeedData,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': transactionId,
    }
  });
}

const getBEVProductDetails = (ajax,transactionId, reqObj) => {
  
  return ajax({
    url: constants.chooseProduct,
    method: 'POST',
    responseType: 'json',
    body: reqObj,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': transactionId,
    }
  });
}

const verifyBevSubmitBtnApi = (ajax,getState) => {
  let transactionId = getState.registration.transactionId;

  let verifyBevObj = getState.verifyBev.verifyBevObj;
  let initData = verifyBevObj.initData;
  let reqObj = {};
  
  reqObj.firstNameValueLabel = {};
  reqObj.lastNameValueLabel = {};
  reqObj.productMBean = {};

  let fn = Crypto.AES.encrypt(verifyBevObj.firstName, registrationConstants.registrationEncryption);
  let ln = Crypto.AES.encrypt(verifyBevObj.lastName, registrationConstants.registrationEncryption);

  reqObj.firstNameValueLabel.value = fn + '';
  reqObj.lastNameValueLabel.value = ln + '';
  reqObj.productMBean.selectedProduct = initData.productMBean.selectedProduct;
  reqObj.productMBean.iPayFormType = initData.productMBean.iPayFormType;
  reqObj.recaptchaResponse = getState.verifyBev.recaptchaResponse;
  const gaCategory = getState.verifyBev.gaCategory;
  ReactGA.sendevent(gaCategory, 'SUBMITTED', 'FIRST_NAME');
  ReactGA.sendevent(gaCategory, 'SUBMITTED', 'LAST_NAME');

  let selectedName = getSelectedBean(reqObj.productMBean.selectedProduct, verifyBevObj.isIPayYes);

  if (selectedName != null) {
    reqObj[selectedName] = {};
    for (var propName in initData[selectedName]) {
      if (initData[selectedName][propName].value != undefined && !isBlank(initData[selectedName][propName].value)) {
        reqObj[selectedName][propName] = {};
        let value = initData[selectedName][propName].value;
        ReactGA.sendevent(gaCategory, 'SUBMITTED', propName);
        var encryptedValue = Crypto.AES.encrypt(value, registrationConstants.registrationEncryption);
        reqObj[selectedName][propName].value = encryptedValue + '';
      }
    }
  } else {
    console.log("selectedName name is null");
    reqObj = initData;
    reqObj.firstNameValueLabel.value = verifyBevObj.firstName;
    reqObj.lastNameValueLabel.value = verifyBevObj.lastName;
  }
  return ajax({
    url: constants.verifyBEVUser,
    method: 'POST',
    responseType: 'json',
    body:reqObj,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': transactionId,
    }
  });
}

//const getFeedDataApi = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBev.json');
//const getBEVProductDetails = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevFreedom.json');
//const getBEVProductDetails = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevEtime.json');
//const getBEVProductDetails = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevHrNet.json');
//const getBEVProductDetails = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevIpay.json');
//const getBEVProductDetails = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevPCSNonPCS.json');
//const getBEVProductDetails = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevW2.json');
//const verifyBevSubmitBtnApi = (getState) => Observable.ajax.getJSON('/public/mocks/verifyBevAlert.json');


const verifyBevGetDropdownValues = (payload, getState) =>
  getVerifyBevDropdownHandler(payload, getState)
    .pipe(map(response => getVerifyBevInfoOptionsSuccess(response, getState)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));

const verifyBevSetIpayRadioBtn = (payload, getState) =>
  verifyBevSetIpayRadioBtnHandler(payload, getState)
    .pipe(map(response => getVerifyBevInfoOptionsSuccess(response, getState)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));

const verifyBevSubmitBtn = (ajax,getState) =>
  verifyBevSubmitBtnApi(ajax,getState)
    .pipe(map(response => getVerifyBevSubmitHandler(response.response, getState)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));

const verifyBevGetW2DropdownValues = (payload, getState) =>
  getVerifyBevW2DropdownHandler(payload, getState)
    .pipe(map(response => getVerifyBevInfoOptionsSuccess(response, getState)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));

const getVerifyBevDropdownHandler = (selectedName, getState) => {
  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  verifyBevObj.w2YearList = [];
  verifyBevObj.fullMonthItems = [];
  verifyBevObj.fullDayItems = [];
  verifyBevObj.initData[selectedName].fullYearId.options.map((value, key) => { verifyBevObj.w2YearList.push(value.label) });
  verifyBevObj.initData[selectedName].fullMonthId.options.map((value, key) => { verifyBevObj.fullMonthItems.push(value.label) });
  verifyBevObj.initData[selectedName].fullDayId.options.map((value, key) => { verifyBevObj.fullDayItems.push(value.label) });

  verifyBevObj.initData[selectedName].fullYearId.options = [];
  verifyBevObj.initData[selectedName].fullMonthId.options = [];
  verifyBevObj.initData[selectedName].fullDayId.options = [];

  return of({
    verifyBevObj
  });
}

const getVerifyBevSubmitHandler = (response, getState) => {
  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  const gaCategory = getState.verifyBev.gaCategory;
  if (response.code == "SUCCESS") {
    return getVerifyBevInfoOptionsSuccess({
      viewId: response.viewId,
      sendRedirect: true
    });

  } else if (response.modelType != undefined && response.modelType != null) {
    verifyBevObj.showModalError = true;
    verifyBevObj.showModalTitle = response.title;
    verifyBevObj.alreadyRegistered = false;
    verifyBevObj.showModalMessage = response.message;
    if (response.code == constants.ALREADY_REGISTERED) {
      ReactGA.sendevent(gaCategory, 'VERIFY_BEV', 'ALREADY_REGISTERED');
      verifyBevObj.alreadyRegistered = true;
    }
    return getVerifyBevInfoOptionsSuccess({
      verifyBevObj
    });

  }
  else {
    let fieldName = null;
    verifyBevObj.showAlert = true;
    verifyBevObj.showAlertMessage = response.message;
    let selectedObj = verifyBevObj.serviceSelectedObj;
    verifyBevObj.errorFieldsBackend = [false, false, false, false, false, false, false, false, false, false];
    for (let key in response.errFieldsMap) {
      fieldName = key;
    }
    let index = getIndexErrorField(fieldName, selectedObj, verifyBevObj);
    if (index != null) {
      verifyBevObj.errorFieldsBackend[index] = true;
    }
    return getVerifyBevInfoOptionsSuccess({
      verifyBevObj
    });

  }


}

const getIndexErrorField = (field, selectedName, verifyBevObj) => {
  if (selectedName == "iPayPayStmtNonPCSMBean") {
    if (field == "co") return 0;
    if (field == "fileNumber") return 1;
    if (field == "adviceNumber") return 2;
    if (field == "ssn") return 3;
    else return null;

  }
  if (selectedName == "w2ServiceMBean") {
    if (field == "ssn") return 3;
  }
  if (field == "employeeNumber" || field == "employee" || field == "controlNumber" || field == "employeeId") {
    return 0;
  }
  if (field == "niNumber" || field == "ssn" || field == "companyCode") {
    return 1;
  }
  if (field == "postalCode" || field == "wages" || field == "zipCode") {
    return 2;
  }
  else return null;

}


const verifyBevSetIpayRadioBtnHandler = (radioValue, getState) => {
  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  let iPayPayStmtMBean = verifyBevObj.initData.iPayPayStmtMBean;
  let iPayPayStmtNonPCSMBean = verifyBevObj.initData.iPayPayStmtNonPCSMBean;
  verifyBevObj.radio = radioValue;
  verifyBevObj.isIPayYes = null;
  verifyBevObj.iPayShow = true;
  verifyBevObj.w2YearList = [];
  verifyBevObj.fullMonthItems = [];
  verifyBevObj.fullDayItems = [];


  if (radioValue == "yes") {
    verifyBevObj.initData.iPayPayStmtMBean.fullYearId.options.map((value, key) => { verifyBevObj.w2YearList.push(value.label) });
    verifyBevObj.initData.iPayPayStmtMBean.fullMonthId.options.map((value, key) => { verifyBevObj.fullMonthItems.push(value.label) });
    verifyBevObj.initData.iPayPayStmtMBean.fullDayId.options.map((value, key) => { verifyBevObj.fullDayItems.push(value.label) });
    verifyBevObj.initData.productMBean.iPayFormType = "PCS";
    verifyBevObj.isIPayYes = true;
  }
  else {
    verifyBevObj.initData.iPayPayStmtNonPCSMBean.fullYearId.options.map((value, key) => { verifyBevObj.w2YearList.push(value.label) });
    verifyBevObj.initData.iPayPayStmtNonPCSMBean.fullMonthId.options.map((value, key) => { verifyBevObj.fullMonthItems.push(value.label) });
    verifyBevObj.initData.iPayPayStmtNonPCSMBean.fullDayId.options.map((value, key) => { verifyBevObj.fullDayItems.push(value.label) });
    verifyBevObj.initData.productMBean.iPayFormType = "NONPCS";
    verifyBevObj.isIPayYes = false;
  }
  verifyBevObj.initData.iPayPayStmtMBean = iPayPayStmtMBean;
  return of({
    verifyBevObj
  });
}


const getVerifyBevW2DropdownHandler = (selectedProduct, getState) => {
  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  verifyBevObj.w2YearList = [];
  verifyBevObj.initData[selectedProduct].w2Year.options.map((value, key) => { verifyBevObj.w2YearList.push(value.label) });

  return of({
    verifyBevObj
  });
}

const handleUpdateData = (payload, getState) =>
  updateDataHandler(payload, getState)
    .pipe(map(response => getVerifyBevInfoOptionsSuccess(response)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));


const updateDataHandler = (payload, getState) => {
  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  if (payload.type == 1)
    verifyBevObj[payload.fieldName] = payload.fieldValue;
  else if (payload.type == 3) {
    if (payload.regEx != null && !isBlank(payload.regEx)) {
      const regEx = new RegExp(payload.regEx);
      if (isBlank(payload.fieldValue) || regEx.test(payload.fieldValue)) {
        verifyBevObj.errorFields[payload.index] = false;
        verifyBevObj.errorFieldsBackend[payload.index] = false;

      }
      else {
        verifyBevObj.errorFields[payload.index] = true;
        verifyBevObj.errorFieldsBackend[payload.index] = true;
      }

    }
    else {
      verifyBevObj.errorFields[payload.index] = false;
      verifyBevObj.errorFieldsBackend[payload.index] = false;
    }
    verifyBevObj.initData["iPayPayStmtNonPCSMBean"][payload.fieldName].value = payload.fieldValue;
    verifyBevObj.initData["iPayPayStmtMBean"][payload.fieldName].value = payload.fieldValue;


  }
  else {
    if (payload.regEx != null && !isBlank(payload.regEx)) {
      const regEx = new RegExp(payload.regEx);
      if (isBlank(payload.fieldValue) || regEx.test(payload.fieldValue)) {
        verifyBevObj.errorFields[payload.index] = false;
        verifyBevObj.errorFieldsBackend[payload.index] = false;

      }
      else {
        verifyBevObj.errorFields[payload.index] = true;
        verifyBevObj.errorFieldsBackend[payload.index] = true;
      }

    }
    else {
      verifyBevObj.errorFields[payload.index] = false;
      verifyBevObj.errorFieldsBackend[payload.index] = false;

    }
    verifyBevObj.initData[payload.parentName][payload.fieldName].value = payload.fieldValue;
  }
  if (payload.fieldName == "fullMonthId") {
    console.log('payload.fieldValue: ' + payload.fieldValue);
    const inputMonthId = verifyBevObj.fullMonthItems.indexOf(payload.fieldValue)+1;
    console.log('inputMonthId: ' + inputMonthId);
    if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
      verifyBevObj.fullDayItems = constants.monthWith31days;
    }
    if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
      verifyBevObj.fullDayItems = constants.monthWith30days;
    }
    if (inputMonthId == 2) {
      verifyBevObj.fullDayItems = constants.monthWith29days;
    }
     verifyBevObj.initData[payload.parentName]["fullDayId"].value = '';
  }
  return of({
    verifyBevObj
  });
}

const getVerifyBevInfo = (ajax,getState) =>
  getFeedDataApi(ajax,getState.registration.transactionId)
    .pipe(map(response => handleResponse(response.response, getState)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));

const getVerifyBevDropdownSelect = (ajax,payload, getState) => {
  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  let selectedProduct = getSelectedProduct(payload, verifyBevObj.initData.productMBean.bevProducts);
  console.log("selectedProduct", selectedProduct);
  verifyBevObj.initData.productMBean.selectedProduct = selectedProduct;
  return getBEVProductDetails(ajax,getState.registration.transactionId, selectedProduct)
    .pipe(map(response => handleResponse(response.response, getState)),
    catchError(err => [getVerifyBevOptionsFail(getErrorMessage(err))]));
}

const getDropDownValue = (value, list) => {

  for (var i = 0; i < list.length; i++) {
    if (list[i].productDisplayName == value) return list[i].value;
  }
}

const getSelectedProduct = (value, list) => {
  console.log("getSelectedProduct method", value);
  for (var i = 0; i < list.length; i++) {
    console.log("list[i].productDisplayName", list[i].productDisplayName);
    if (list[i].productDisplayName == value) {
      console.log("list[i]", list[i]);
      return list[i];
    }
  }
}


const handleResponse = (data, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_BEV";
  let pagePath = '/ssr/flowType/verifyBev';
  let pageView = pagePath + "/web";
    
  if(isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_BEV";}
  if(window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_BEV";}
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end

  let verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  verifyBevObj.initData = data;
  verifyBevObj.errorFields = [false, false, false, false, false, false, false, false, false, false];
  verifyBevObj.errorFieldsBackend = [false, false, false, false, false, false, false, false, false, false];
  verifyBevObj.showDiv = true;
  verifyBevObj.serviceDropDownList = [];
  verifyBevObj.showAlert = false;

  if (!isBlank(data.firstNameValueLabel.value))
    verifyBevObj.firstName = data.firstNameValueLabel.value;

  if (!isBlank(data.lastNameValueLabel.value))
    verifyBevObj.lastName = data.lastNameValueLabel.value;

  if (data.productMBean.selectedProduct != null && data.productMBean.selectedProduct.productDisplayName != null) {
    verifyBevObj.serviceSelected = data.productMBean.selectedProduct.productDisplayName;
    verifyBevObj.serviceSelectedObj = getObjName(data.productMBean.selectedProduct.productCd);
    verifyBevObj.sitekey = getState.registration.sitekey;

    const isMobile = getState.registration.mobileFlow;
    console.log("bev----isMobile-", isMobile);
    if (isMobile) {
      console.log("bev----isRecaptchaOn-", getState.registration.isRecaptchaOn);
      verifyBevObj.isRecaptchaOn = getState.registration.isRecaptchaOn;
    } else {
      console.log("bev----isRecaptchaOn-", getState.verifyUser.isRecaptchaOn);
      verifyBevObj.isRecaptchaOn = getState.verifyUser.isRecaptchaOn;
    }
  
    verifyBevObj.locale = getState.registration.locale;
  }
  verifyBevObj.initData.productMBean.bevProducts.map((value, key) => { verifyBevObj.serviceDropDownList.push(value.productDisplayName) });
  return getVerifyBevInfoOptionsSuccess({
    verifyBevObj,
    recaptchaResponse: null,
    showIpayDraggable: false,
    showDraggable: false,
    isMobileBrowser,
    isMobile,
    gaCategory,
    progressBarData
  });
}

const getSelectedBean = (selectedProduct, isIPayYes) => {
  let productTag = selectedProduct.productTag;
  let productCd = selectedProduct.productCd;
  let docType = "";
  if (productCd.match("#")) {
      docType = productCd.substring(productCd.indexOf("#") + 1, productCd.length);
  }
  if (productTag.match("^eTime")) {
      return "etimeBev"
  } else if (productTag.match("^iPay")) {
      if (docType == "payStmt") {
        if (isIPayYes) {
          return "iPayPayStmtMBean";
        } else {
          return "iPayPayStmtNonPCSMBean";
        }
      } else if (docType == "w2Stmt") {
         return "iPayW2ServiceMBean";
      }
  } else if (productTag.match("^W2ONLY")) {
      return "w2ServiceMBean";
  } else if (productTag.match("^Freedom")) {
      if (productCd == "Freedom") {
        return "freedomMBean";
      }
      if (productCd == "HRnet") {
        return "hrNetMBean";
      }
  }
}

const getObjName = (name) => {
  if (name == "EeT") return "etimeBev";
  if (name == "Freedom") return "freedomMBean";
  if (name == "HRnet") return "hrNetMBean";
  if (name == "iPay#w2Stmt") return "iPayW2ServiceMBean";
  if (name == "iPay#payStmt") return "iPayPayStmtNonPCSMBean";
  if (name == "W2ONLY") return "w2ServiceMBean";

}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});

const handleAlreadyRegisteredResponse = (response) => {
  //console.log(response.response, '\n already registered response::');
  //console.log('\n already registered response::'+ response);

  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const verifyBevObj = Object.assign({}, getState.verifyBev.verifyBevObj);
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId;
  if (verifyBevObj.alreadyRegistered) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
    catchError(err => [(getErrorMessage(err))]));
  } else if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
     return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

export const getVerifyBevInfoEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_BEV_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getVerifyBevInfo(ajax,state.value))));

export const getVerifyBevDropdownSelectEpic = (action$, state, {ajax}) => action$.pipe(
  ofType(VERIFY_BEV_DROPDOWN_SELECT),
  switchMap(action => sendingAsyncRequest(getVerifyBevDropdownSelect(ajax,action.payload, state.value))));

export const handleUpdateDataEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_BEV_UPDATE_DATA),
  switchMap(action => sendingAsyncRequest(handleUpdateData(action.payload, state.value))));

export const verifyBevGetDropdownValuesEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_BEV_GET_DROPDOWN_VALUES),
  switchMap(action => sendingAsyncRequest(verifyBevGetDropdownValues(action.payload, state.value))));

export const verifyBevGetW2DropdownValuesEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_BEV_GET_W2_DROPDOWN_VALUES),
  switchMap(action => sendingAsyncRequest(verifyBevGetW2DropdownValues(action.payload, state.value))));

export const verifyBevSetIpayRadioBtnEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_BEV_SET_IPAY_RADIO_BTN),
  switchMap(action => sendingAsyncRequest(verifyBevSetIpayRadioBtn(action.payload, state.value))));

export const verifyBevSubmitBtnEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_BEV_SUBMIT_BTN),
  switchMap(action => sendingAsyncRequest(verifyBevSubmitBtn(ajax,state.value))));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_BEV_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const getVerifyPrcInfoOptionsFailureEpic = action$ => action$.pipe(
  ofType(VERIFY_BEV_INFO_OPTIONS_FAIL),  switchMap(action$ => of(push('/error'))));

export const onSetFinalSubmitEpic = (action$, state) => action$.pipe(
  ofType(ON_FINAL_VERIFY_BEV_SUCCESS),
  switchMap(action$ => {
     ReactGA.sendevent(state.value.verifyBev.gaCategory, 'VERIFY_BEV', 'SUCCESS');
   return of(push(state.value.verifyBev.viewId))
  }));
