import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { updateIntl } from 'react-intl-redux';
import { types } from '../registration-actions';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import {
  initProfiling
} from '../../../../util';
import { constants } from '../registration-constants';
import { WindowUtil,PendoUtil } from '../../../../util';
import { ReactGA } from '../../../../util';
import { createIntlPayload, getLocaleInitialState } from '../../../../util/locale';
import { actions as localeActions } from '../../locale/locale-actions';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';
import { getLocaleImage } from '../../../../util/locale/locale-helper';
import axios from 'axios';

const {
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_FAIL,
  UPDATE_LOCALE_FIELD,
  NEXO_SSR_REDIRECTION
} = types;

const getTransactionDetailsSuccess = createAction(GET_TRANSACTION_DETAILS_SUCCESS);
const getTransactionDetailsFail = createAction(GET_TRANSACTION_DETAILS_FAIL);
const getNexoSSRRedirection = createAction(NEXO_SSR_REDIRECTION);

const sendFidoDetails = async ( fidoLoggingDetails, transactionId ) => {
     axios.post(constants.fidoLoggingEndpoint, fidoLoggingDetails, 
      { headers: {
			'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            'X-TRANSACTIONID': transactionId,
          } 
         }
       )
  };

const transactionApi = (getState) => {
  
  let headerOptions = { 'Content-Type': 'application/json' };
  const res = WindowUtil.getInitalData();

  if (res.mobileFlow != undefined && res.transactionId != undefined && res.mobileFlow == "true") {
    headerOptions["X-TRANSACTIONID"] = res.transactionId;
  }

  return ajax({
  url: constants.getTransactionDetailsEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: headerOptions,
  body: WindowUtil.getQueryParams()
});
}

const handleResponse = (response, getState) => {
  var ele = document.getElementsByTagName('html')[0];
  let footer, background = {}, productLogo = {}, organizationLogo = {};
  let backgroundImage = '';
  let bgLocaleJson = '';

  const localeResponse = getLocaleInitialState(response.locale);
  getState.locale.locale = localeResponse.locale.locale;
  getState.intl.locale = localeResponse.intl.locale;
  getState.intl.messages = localeResponse.intl.messages;


  let brandingEvoData = {}; 
  // Branding start
  const localBrandData = require('../../../../../local-branding/default-branding-prod.json');

  //redirection to NEXO SSR flow
  if (response.nexoPrcUrl) {
    return getNexoSSRRedirection({
      ssrDto : response,
    });
  }

  ReactGA.initialize(response.gaTrackingId);
  
   if (response.orgIdForDeviceAssessment &&
      (response.enableDeviceAssessmentV2 === 'true' || response.enableDeviceAssessmentV2 === true)) {
          initProfiling(response.orgIdForDeviceAssessment , response.transactionId); 
     }
  if (response.brandingInfoDto !== undefined && response.brandingInfoDto.brands !== undefined) {
    footer = response.brandingInfoDto.brands.footer;
    brandingEvoData.primaryBtnColor=response.brandingInfoDto.brands.primaryBtnColor;
    brandingEvoData.primaryBtnDisabledColor=response.brandingInfoDto.brands.primaryBtnDisabledColor;
    brandingEvoData.primaryColorTitleText=response.brandingInfoDto.brands.primaryColorTitleText;
    brandingEvoData.primaryColorContentText=response.brandingInfoDto.brands.primaryColorContentText;
    brandingEvoData.primaryColorLinks=response.brandingInfoDto.brands.primaryColorLinks;
    brandingEvoData.btnBorderRadius=response.brandingInfoDto.brands.btnBorderRadius;
    brandingEvoData.primaryFont=response.brandingInfoDto.brands.primaryFont;
	brandingEvoData.secondaryBtnColor=response.brandingInfoDto.brands.secondaryBtnColor;
    background = response.brandingInfoDto.brands.background;
    productLogo = response.brandingInfoDto.brands.productLogo;
    if(response.brandingInfoDto.brands.organizationLogo !== undefined ){
       if( response.brandingInfoDto.brands.organizationLogo.default !== "" || response.logoUrl.indexOf('OrganizationLogoServlet') !== -1 ){ 
        organizationLogo = response.brandingInfoDto.brands.organizationLogo;
        }
       else {
          organizationLogo.default = response.logoUrl || "";
          organizationLogo.alignment = response.brandingInfoDto.brands.organizationLogo.alignment; 
        }
    }
    //setting locale list based on footer values
    WindowUtil.setBrandingLocaleList(footer);
    
    ReactGA.sendevent('SSR_Flow', 'BRANDING_DEFAULT', 'NO');

  }
  else {
    // setting year value dynamically
    const currentYear = WindowUtil.getCurrentYear() || "2020";
    let text = null;
    localBrandData.brands.footer.items.map((value, key) => {
      if (value.id == "copyright") {
        text = value.component.text;
        Object.keys(text).forEach((componentKey) => {
          text[componentKey] = text[componentKey].replace("{year}", currentYear);
        });
        localBrandData.brands.footer.items[key].component.text = text;
      }
    });

    background.default = response.backgroundUrl;
    footer = localBrandData.brands.footer;
    productLogo.default = response.productLogoUrl || 'default';
    productLogo.alignment = "RIGHT";
    organizationLogo.default = response.logoUrl;
    organizationLogo.alignment = "LEFT";
    ReactGA.sendevent('SSR_Flow', 'BRANDING_DEFAULT', 'YES');

  }

  // setting text message terms and condition

  footer.terms = localBrandData.terms;
  
   // mobile browser footer 
   if(!response.mobileFlow && window.innerWidth < 479){
   footer.items.map((value, key) => {
    if (value.id == "copyright") {
      footer.copyRightValue = value;
    }
  });

   //check for mobileBrowser
    footer.showFooterContent = true;
    footer.cancelurl = response.returnUrl;
  }

  if (!response.mobileFlow) {
    footer.items.map((value, key) => {
      if (value.component.type == "CO_BROWSE_COMPONENT") {
          WindowUtil.loadCobrowse(); 
      }
    });
  }

  /*if(response.cssBrandingEnabledFlag && !response.mobileFlow ) {
    WindowUtil.setBrandingParameters(brandingEvoData,response.cssBrandingEnabledFlag);
  }*/

  if(response.pendoEnabled != undefined && response.pendoEnabled === true){
    PendoUtil.pendoInitialize(response.pendoApiKey,response.transactionId)
  }

  // Branding end

  // setting background start

  //Check mobile flow
  if (response.mobileFlow || window.innerWidth<479) {
    var whileBG = `background:#f8f8ff;`;
    ele.setAttribute("style", whileBG);
  }
  else if (background) {
    bgLocaleJson = background.i18n || '';
    backgroundImage =
      bgLocaleJson === '' ?
        background.default :
        getLocaleImage(bgLocaleJson, localeResponse.locale.locale);
    if (backgroundImage !== undefined) {
      const backgroundHtml = `background-image: url(${backgroundImage}); background-size: cover;`;
      ele.setAttribute('style', backgroundHtml);
    }
  
  }

  // setting background end

  if(response.fidoEnabled === true) 
  {
    let fidoLoggingDetails = {};
    fidoLoggingDetails.availableForFIDO =  WindowUtil.FIDOAuthenticationCheck();
    let device = "DESKTOP_BROWSER";
    if(response.mobileFlow === true){
      device = "MOBILE_APP";
    }
    else if(response.mobileFlow === false && window.innerWidth <= 479){
      device = "MOBILE_BROWSER";
    }
    fidoLoggingDetails.device = device;
    sendFidoDetails(fidoLoggingDetails,response.transactionId);
  }

  if (response.audioEyeEnabled) {
    WindowUtil.appendAudieoEyeJs();
  }

  ReactGA.sendevent('SSR_Flow', 'SELECTED_LOCALE', response.locale);

  response.locale = localeResponse.locale.locale;

  ReactGA.sendevent('SSR_Flow', 'RETURN_URL', response.returnUrl);

  // Setting flow type for progress bar
  getState.registration.currentStage = WindowUtil.getProgressBarViewId(response.viewId);
  
  //setting autofocus type
  WindowUtil.setAutoFocusOff(response.mobileFlow);
  
  if (response.mobileFlow) {
    if (response.code == 'INVALID_REGCODE') {
      //Invalid input from mobile
      ReactGA.sendevent('MOBILE_SSR', 'INVALID_MOBILE_INPUT', 'Invalid Input Data');
    }

    if (response.orgIdForDeviceAssessment != '' && response.daUrl != undefined) {
      WindowUtil.deviceAssessment(response.orgIdForDeviceAssessment, response.daUrl);
    }

    if (response.regCodeData != undefined && response.regCodeData != '') {
    
      PendoUtil.pendoIdentify(response.orgCd,response.regCodeData.companyName,response.transactionId)
      PendoUtil.pendoVisitorIdUpdate(response.associateId)

      if (response.regCodeData.sivOptions != null && response.regCodeData.sivOptions.isSivPIC) {
        ReactGA.pageview(constants.PATHVERIFYPICMOBILE);
        ReactGA.sendevent('MOBILE_SSR', 'MOBILE_INPUT', 'PRC');
      } else {
        ReactGA.pageview(constants.PATHVERIFYCPCMOBILE);
        ReactGA.sendevent('MOBILE_SSR', 'MOBILE_INPUT', 'ORC');
        if (response.recaptchaOn != undefined && response.recaptchaOn == true) {
          WindowUtil.recaptchaCall();
        }
      }
      if (response.regCodeData.showExternalVendorsPage) {
        return getTransactionDetailsSuccess({
          transactionId: response.transactionId,
          backgroundImagePath: response.backgroundUrl,
          viewId: constants.chooseVendors,
          returnUrl: response.returnUrl,
          gaTrackingId: response.gaTrackingId,
          mobileFlow: response.mobileFlow,
          sivViewId: response.viewId,
          sivOptions: response.regCodeData.sivOptions,
          showExternalVendorsPage: response.regCodeData.showExternalVendorsPage,
          extVendorList: response.regCodeData.extVendorList,
          companyName: response.regCodeData.companyName,
          message: response.message,
          code: response.code,
          title: response.title,
          sitekey: response.sitekey,
          isRecaptchaOn: response.recaptchaOn,
          orgIdForDeviceAssessment: response.orgIdForDeviceAssessment,
          locale: response.locale,
          progressBarEnabled: response.progressBarEnabled,
          codelessVariantEnabled: response.codelessVariantEnabled,
          footer,
          contractorEnableFlag: response.contractorEnableFlag,
          runAdminFlow: response.runAdminFlow,
          riskSessionId: response.riskSessionId,
          organizationId: response.organizationId,
          payrollPlusTextChange: response.payrollPlusTextChange
        });
      } else {
        return getTransactionDetailsSuccess({
          transactionId: response.transactionId,
          backgroundImagePath: response.backgroundUrl,
          returnUrl: response.returnUrl,
          gaTrackingId: response.gaTrackingId,
          mobileFlow: response.mobileFlow,
          sivViewId: response.viewId,
          viewId: response.viewId,
          sivOptions: response.regCodeData.sivOptions,
          showExternalVendorsPage: false,
          extVendorList: '',
          companyName: response.regCodeData.companyName,
          message: response.message,
          code: response.code,
          title: response.title,
          sitekey: response.sitekey,
          isRecaptchaOn: response.recaptchaOn,
          orgIdForDeviceAssessment: response.orgIdForDeviceAssessment,
          locale: response.locale,
          progressBarEnabled: response.progressBarEnabled,
          codelessVariantEnabled: response.codelessVariantEnabled,
          footer,
          contractorEnableFlag: response.contractorEnableFlag,
          runAdminFlow: response.runAdminFlow,
          riskSessionId: response.riskSessionId,
          organizationId: response.organizationId,
          payrollPlusTextChange: response.payrollPlusTextChange
        });
      }

    } else {
      return getTransactionDetailsSuccess({
        transactionId: response.transactionId,
        backgroundImagePath: response.backgroundUrl,
        returnUrl: response.returnUrl,
        gaTrackingId: response.gaTrackingId,
        mobileFlow: response.mobileFlow,
        sivViewId: response.viewId,
        viewId: response.viewId,
        sivOptions: '',
        showExternalVendorsPage: false,
        extVendorList: '',
        companyName: '',
        message: response.message,
        code: response.code,
        title: response.title,
        sitekey: response.sitekey,
        isRecaptchaOn: response.recaptchaOn,
        orgIdForDeviceAssessment: response.orgIdForDeviceAssessment,
        locale: response.locale,
        progressBarEnabled: response.progressBarEnabled,
        codelessVariantEnabled: response.codelessVariantEnabled,
        footer,
        contractorEnableFlag: response.contractorEnableFlag,
        runAdminFlow: response.runAdminFlow,
        riskSessionId: response.riskSessionIdt,
        organizationId: response.organizationId,
        payrollPlusTextChange: response.payrollPlusTextChange
      });
    }
  }
  if (response.is401k != null && response.is401k) {
    return getTransactionDetailsSuccess({
      transactionId: response.transactionId,
      backgroundImagePath: response.backgroundUrl,
      viewId: response.viewId,
      returnUrl: response.returnUrl,
      gaTrackingId: response.gaTrackingId,
      mobileFlow: response.mobileFlow,
      sivViewId: response.viewId,
      sivOptions: response.regCodeData.sivOptions,
      companyName: response.regCodeData.companyName,
      message: response.message,
      code: response.code,
      title: response.title,
      sitekey: response.sitekey,
      isRecaptchaOn: response.recaptchaOn,
      orgIdForDeviceAssessment: response.orgIdForDeviceAssessment,
      locale: response.locale,
      progressBarEnabled: response.progressBarEnabled,
      background,
      productLogo,
      organizationLogo,
      footer,
      riskSessionId: response.riskSessionId,
      organizationId: response.organizationId,
      payrollPlusTextChange: response.payrollPlusTextChange
    });
  }

  let isDeviceAssessmentDone = false;
  if (response.viewId === '/findMe') {
    if (response.orgIdForDeviceAssessment != '' && response.daUrl != undefined) {
      WindowUtil.deviceAssessment(response.orgIdForDeviceAssessment, response.daUrl);
      isDeviceAssessmentDone = true;
    }
  }

  return getTransactionDetailsSuccess({
    transactionId: response.transactionId,
    backgroundImagePath: response.backgroundUrl,
    viewId: response.viewId,
    returnUrl: response.returnUrl,
    gaTrackingId: response.gaTrackingId,
    mobileFlow: response.mobileFlow,
    sivViewId: response.viewId,
    sivOptions: '',
    showExternalVendorsPage: '',
    extVendorList: '',
    message: response.message,
    code: response.code,
    title: response.title,
    sitekey: response.sitekey,
    isRecaptchaOn: response.recaptchaOn,
    orgIdForDeviceAssessment: response.orgIdForDeviceAssessment,
    locale: response.locale,
    isEligibleForCodelessFlow: response.eligibleForCodelessFlow,
    isDeviceAssessmentDone: isDeviceAssessmentDone,
    embeddedPrcFlow: response.embeddedPrcFlow,
    progressBarEnabled: response.progressBarEnabled,
    background,
    productLogo,
    organizationLogo,
    footer,
    codelessVariantEnabled: response.codelessVariantEnabled,
    contractorEnableFlag: response.contractorEnableFlag,
    runAdminFlow: response.runAdminFlow,
    riskSessionId: response.riskSessionId,
    organizationId: response.organizationId,
    payrollPlusTextChange: response.payrollPlusTextChange
  });
};
const getTransactionDetails = (getState) =>
  transactionApi(getState)
    .pipe(map(response => handleResponse(response.response, getState)),
      catchError(err => [getTransactionDetailsFail(getErrorMessage(err))]));

const updateLocaleField = (getState) =>
  handleLocaleField(getState)
    .pipe(map(getTransactionDetailsSuccess));

const handleLocaleField = (getState) => {
  let locale = getState.locale.locale;
  return of({
    locale
  });
}

/** ************NEXO SSR redirection************* */
const redirectToNexoSsrFlow = getState => handleRedirectToNexoSSr(getState).pipe(map(handleRedirectToNexoSSrwRedirect));

const handleRedirectToNexoSSr = (getState) => {
  const state = getState;

  return of({
    state,
  });
};

const handleRedirectToNexoSSrwRedirect = (getState) => {
  getState = getState.state;

  window.location = getState.registration.ssrDto.nexoPrcUrl;
};

export const getTransactionDetailsEpic = (action$, state) => action$.pipe(
  ofType(GET_TRANSACTION_DETAILS),
  switchMap(action => sendingAsyncRequest(getTransactionDetails(state.value))));


export const updateLocaleEpic = (action$, state) => action$.pipe(
  ofType(UPDATE_LOCALE_FIELD),
  switchMap(action => sendingAsyncRequest(updateLocaleField(state.value))));


export const startTransactionDetailsSuccessEpic = (action$, state) => action$.pipe(
  ofType(GET_TRANSACTION_DETAILS_SUCCESS),
  switchMap(action$ => of(push(state.value.registration.viewId))));

export const startTransactionDetailsFailureEpic = action$ => action$.pipe(
  ofType(GET_TRANSACTION_DETAILS_FAIL),
  switchMap(action$ => of(push('/error'))));

export const ssrRedirectionEpic = (action$, state) => action$.pipe(
  ofType(NEXO_SSR_REDIRECTION),
  switchMap(action => sendingAsyncRequest(redirectToNexoSsrFlow(state.value))),
);