import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocaleObjFromPropKey } from '../../util/locale';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import AdpIcon from 'adp-react-icons/lib/adp/adp';
import FaArrowDown from 'adp-react-icons/lib/fa/arrow-down';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { constants } from '../../redux/ducks/contact-info/contact-info-constants';
import { WindowUtil } from '../../util';
import ExternalLinkIcon from 'adp-react-icons/lib/fa/external-link';
import PlusCircle from 'adp-react-icons/lib/fa/plus-circle';
import { ReactGA } from '../../util';
import CountryCodeDropdown from '../../components/country-code';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import WindowRestore from 'adp-react-icons/lib/fa/window-restore';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import PersonalPhoneFormfrom from './PersonalPhoneForm';


let downButton;
let continueButton;

const allowEnterKeyFields = ["PhoneValue1","PhoneValue2","AddlPhoneValue_1","AddlPhoneValue1_1","AddlPhoneValue2_1","AddlPhoneValue2_2","AddlPhoneValue1_2","AddlPhoneValue_2","EmailValue","ExtensionNum1","AddlEmailValue","ExtensionNum2","ExtensionNum3","ExtensionNum4"];

const isBlank = str => (str || '').trim().length === 0;
let showAlertFlag = false;

class ContactInfoForm extends Component {

  componentWillMount() {
    if (!WindowUtil.getlayoutback()) {
      this.props.getContactInfo();
    }
    WindowUtil.setlayoutback(false);
  }

  componentDidMount() {
    this.downIndicatorUpdate();
    window.addEventListener('scroll', this.scrollFunction, true);
  }

  componentDidUpdate() {
    window.addEventListener('scroll', this.scrollFunction, true);
    this.downIndicatorUpdate();
  }

  componentWillReceiveProps = (nextProps) => {
    window.addEventListener('scroll', this.scrollFunction, true);
    this.downIndicatorUpdate();
    if (nextProps.sendRedirect)
      nextProps.setContinueModal();

    if (nextProps.contactInfoObj.showAlert && showAlertFlag) {
      showAlertFlag = false;
      window.scrollTo(0, 0);
    }
    this.checkFormReadyness(nextProps);
  };

  downIndicatorUpdate = () => {
    downButton = document.getElementById("downIndicator");
    continueButton = document.getElementById("formId");
    if (window.innerWidth <= 479) {
      if (continueButton != undefined && continueButton !== null) {
        if (window.innerHeight > continueButton.scrollHeight) {
          downButton.style.display = "none";
        }
        else {
          if (document.documentElement.scrollTop < document.documentElement.scrollHeight - window.innerHeight - 1) { downButton.style.display = "block"; }
          else {
            downButton.style.display = "none";
          }
        }
      }
    }
  }

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (isBlank(nextProps.contactInfoObj.pEmail) || !nextProps.contactInfoObj.pEmailValid) {
      formValid = false;
    }
    if (!nextProps.contactInfoOptions.embeddedPicEmailNotUnique && nextProps.contactInfoObj.bMobileRequired && isBlank(nextProps.contactInfoObj.bMobile)
      || !nextProps.contactInfoObj.bMobileValid) {
      formValid = false;
    }
    if (!nextProps.contactInfoOptions.embeddedPicEmailNotUnique && !nextProps.contactInfoObj.bMobileRequired && !isBlank(nextProps.contactInfoObj.bMobile)
      && !nextProps.contactInfoObj.bMobileValid) {
      formValid = false;
    }
    if (!isBlank(nextProps.contactInfoObj.sEmail) && !nextProps.contactInfoObj.sEmailValid) {
      formValid = false;
    }
    if (!isBlank(nextProps.contactInfoObj.pMobile) && !nextProps.contactInfoObj.pMobileValid) {
      formValid = false;
    }
    if (!isBlank(nextProps.contactInfoObj.bLandline) && !nextProps.contactInfoObj.bLandlineValid) {
      formValid = false;
    }
    if (!isBlank(nextProps.contactInfoObj.pLandline) && !nextProps.contactInfoObj.pLandlineValid) {
      formValid = false;
    }
    if (!nextProps.contactInfoOptions.embeddedPicEmailNotUnique && !isBlank(nextProps.contactInfoObj.bMobile) && (nextProps.contactInfoObj.bmType == nextProps.contactInfoOptions.phoneItems[0] || nextProps.contactInfoObj.bmType == nextProps.contactInfoOptions.phoneItems[2] || nextProps.contactInfoOptions.runAdminFlow) && nextProps.contactInfoObj.radio1 == undefined) {
      formValid = false;
    }
    if (!nextProps.contactInfoOptions.embeddedPicEmailNotUnique && !isBlank(nextProps.contactInfoObj.pMobile) && (nextProps.contactInfoObj.pmType == nextProps.contactInfoOptions.phoneItems[0] || nextProps.contactInfoObj.pmType == nextProps.contactInfoOptions.phoneItems[2] || nextProps.contactInfoOptions.runAdminFlow) && nextProps.contactInfoObj.radio2 == undefined) {
      formValid = false;
    }
     if (nextProps.contactInfoOptions.embeddedPicEmailNotUnique && (isBlank(nextProps.contactInfoObj.pMobile) || nextProps.contactInfoObj.radio1 == null || nextProps.contactInfoObj.radio1 == undefined)) {
      formValid = false;
    }
    if (!isBlank(nextProps.contactInfoObj.bLandline) && (nextProps.contactInfoObj.blType == nextProps.contactInfoOptions.phoneItems[0] || nextProps.contactInfoObj.blType == nextProps.contactInfoOptions.phoneItems[2]) && nextProps.contactInfoObj.radio3 == undefined) {
      formValid = false;
    }
    if (!isBlank(nextProps.contactInfoObj.pLandline) && (nextProps.contactInfoObj.plType == nextProps.contactInfoOptions.phoneItems[0] || nextProps.contactInfoObj.plType == nextProps.contactInfoOptions.phoneItems[2]) && nextProps.contactInfoObj.radio4 == undefined) {
      formValid = false;
    }
    if (nextProps.contactInfoObj.tsEligible && nextProps.contactInfoObj.radio5 == undefined && nextProps.contactInfoOptions.emailItems != undefined && ((!isBlank(nextProps.contactInfoObj.sEmail) && (nextProps.contactInfoOptions.emailItems[1] == nextProps.contactInfoObj.seType)) ||
      (!isBlank(nextProps.contactInfoObj.pEmail) && (nextProps.contactInfoOptions.emailItems[1] == nextProps.contactInfoObj.peType)))) {
      formValid = false;
    }

    this.props.enableSubmitButton(formValid);
  };

  scrollFunction = (nextProps) => {

    if (window.innerWidth <= 479) {
      if (downButton !== undefined && downButton !== null) {
        if (document.documentElement.scrollTop < document.documentElement.scrollHeight - window.innerHeight - 1) {
          downButton.style.display = "block";
        } else {

          downButton.style.display = "none";
        }
      }

    }
  }
  render() {
    const {
      contactInfoOptions,
      enablePhoneField,
      enableSecoundaryForm,
      setContactField,
      setCancelModalWarn,
      setCancelModalError,
      setCancelModalInfo,
      setCancelModalLock,
      isFormValid,
      onFormSubmit,
      showModalWarn,
      showModalError,
      showModalInfo,
      showModalLock,
      setContinueModal,
      sendRedirect,
      showTnCModal,
      setCancelTnCModal,
      cancelAndCheckYourEntries,
      showTSModal,
      setCancelTSModal,
      isMobileBrowser,
      isMobile,
      gaCategory,
      isAuthorized,
      footer,
      locale
    } = this.props;

    const {

      pEmail,
      sEmail,
      bMobile,
      pMobile,
      bLandline,
      pLandline,
      pEmailValid,
      sEmailValid,
      bMobileValid,
      pMobileValid,
      bLandlineValid,
      pLandlineValid,
      peType,
      seType,
      bmType,
      pmType,
      plType,
      blType,
      errorMessage,
      showSecoundaryForm,
      semailRenderable,
      phoneCount,
      userFound,
      welcomeMsg,
      ccode1,
      ccode2,
      ccode3,
      ccode4,
      ccodeValue1,
      ccodeValue2,
      ccodeValue3,
      ccodeValue4,
      radio1,
      radio2,
      radio3,
      radio4,
      radio5,
      extn1,
      extn2,
      extn3,
      extn4,
      bMobileRequired,
      showAlert,
      showAlertMessage,
      showModalMessage,
      showModalTitle,
      fieldValid,
      fieldDisable,
      tsEligible,
      tsTypes,
      tsUrl,
      headerTitle,
      cssTitle,
      onlyCountries,
      emailDisabled,
      progressBarData,
      isProofingContactInfo
    } = this.props.contactInfoObj;
    const {
      mobileValidationPolicy,
      mobileInvalidMessage
    } = this.props.contactInfoOptions;

    const _handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'SUBMIT');
        e.preventDefault();
        if(e.target !== undefined && e.target.id!==undefined){
          if(allowEnterKeyFields.indexOf(e.target.id)!="-1"){
            this.props.onFormSubmit();
          }
        }
      }
    }

    const bottomFunction = () => {
      document.body.scrollTop = document.body.scrollHeight - document.body.clientHeight;
      document.documentElement.scrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    }

    const onCancelTSModal = (value) => {
      let showTSModal = value;
      return setCancelTSModal(showTSModal);
    }

    const onCancelTnCModal = () => {
      let showTnCModal = false;
      return setCancelTnCModal(showTnCModal);
    }
    const onOpenTnCModal = () => {
      let showTnCModal = true;
      return setCancelTnCModal(showTnCModal);
    }

    const onClickText = () => {
      (this.countryCodeDropdown1 != undefined) ? this.countryCodeDropdown1.resetDropdown() : undefined;
      (this.countryCodeDropdown2 != undefined) ? this.countryCodeDropdown2.resetDropdown() : undefined;
      (this.countryCodeDropdown3 != undefined) ? this.countryCodeDropdown3.resetDropdown() : undefined;
      (this.countryCodeDropdown4 != undefined) ? this.countryCodeDropdown4.resetDropdown() : undefined;

    }

    const errorPopover = (fieldValid, index) =>
      (fieldValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{errorMessage[index]}</div>
        </Popover>
      ));


    const onContinueModal = () => setContinueModal();


    const onCancelModalWarn = () => {
      let showModalWarn = false;

      return setCancelModalWarn(showModalWarn);
    }

    const onCancelModalError = () => {
      let showModalError = false;

      return setCancelModalError(showModalError);
    }


    const onCancelModalInfo = () => {
      let showModalInfo = false;

      return setCancelModalInfo(showModalInfo);
    }


    const onCancelModalLock = () => {
      let showModalLock = false;

      return setCancelModalLock(showModalLock);
    }


    const onSetEmail = (pEmail) => {
      const obj = {};
      obj.validationPolicy = "emailValidationPolicy";
      obj.value = pEmail;
      obj.fieldName = "pEmail";
      obj.fieldValid = "pEmailValid";
      obj.invalidMessage = "emailInvalidMessage";
      obj.errorIndex = 0;
      if (fieldDisable[0])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PRIMARY_EMAIL" });
      return setContactField(constants.contactInfoIds.fieldSet, obj);
    }

    const onSetSEmail = sEmail => {
      const obj = {};
      obj.validationPolicy = "emailValidationPolicy";
      obj.value = sEmail;
      obj.fieldName = "sEmail";
      obj.fieldValid = "sEmailValid";
      obj.invalidMessage = "emailInvalidMessage";
      obj.errorIndex = 2;
      if (fieldDisable[2])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SECONDARY_EMAIL" });
      return setContactField(constants.contactInfoIds.fieldSet, obj);
    }

    const onSetPEmailType = eType => {
      const obj = {};
      ReactGA.sendevent(gaCategory, "DROPDOWN_SELECT", getContMechPurpTypeNm(eType));
      obj.value = eType;
      obj.fieldName1 = "peType";
      obj.fieldName2 = "seType";
      if (fieldDisable[2])
        return;
      return setContactField(constants.contactInfoIds.emailTypeSet, obj);
    }

    const onSetSEmailType = eType => {
      const obj = {};
      ReactGA.sendevent(gaCategory, "DROPDOWN_SELECT", getContMechPurpTypeNm(eType));
      obj.value = eType;
      obj.fieldName1 = "seType";
      obj.fieldName2 = "peType";
      if (fieldDisable[0])
        return;
      return setContactField(constants.contactInfoIds.emailTypeSet, obj);
    }

    const onSetBMobileType = bmType => {
      const obj = {};
      ReactGA.sendevent(gaCategory, "DROPDOWN_SELECT", getPhoneMechPurpTypeNm(bmType).cType);
      obj.value = bmType;
      obj.fieldName = "bmType";
      return setContactField(constants.contactInfoIds.mobileTypeSet, obj);
    }

    const onSetPMobileType = pmType => {
      const obj = {};
      ReactGA.sendevent(gaCategory, "DROPDOWN_SELECT", getPhoneMechPurpTypeNm(pmType).cType);
      obj.value = pmType;
      obj.fieldName = "pmType";
      return setContactField(constants.contactInfoIds.mobileTypeSet, obj);
    }

    const onSetPLandlineType = plType => {
      const obj = {};
      ReactGA.sendevent(gaCategory, "DROPDOWN_SELECT", getPhoneMechPurpTypeNm(plType).cType);
      obj.value = plType;
      obj.fieldName = "plType";
      return setContactField(constants.contactInfoIds.mobileTypeSet, obj);
    }


    const onSetBLandlineType = blType => {
      const obj = {};
      ReactGA.sendevent(gaCategory, "DROPDOWN_SELECT", getPhoneMechPurpTypeNm(blType).cType);
      obj.value = blType;
      obj.fieldName = "blType";
      return setContactField(constants.contactInfoIds.mobileTypeSet, obj);
    }

    const enablePField = () => enablePhoneField();

    const enableSForm = () => enableSecoundaryForm();

    const getPhoneMechPurpTypeNm = (type) => {
      const index = contactInfoOptions.phoneItems.indexOf(type);
      if(contactInfoOptions.embeddedPicEmailNotUnique) {
        return { "cType": "PERSONAL_CELL_PHONE", "pType": "PERSONAL" };
      }
      if (index == 0) return { "cType": "BUSINESS_CELL_PHONE", "pType": "BUSINESS" };
      if (index == 1) return { "cType": "OFFICE_PHONE", "pType": "BUSINESS" };
      if (index == 2) return { "cType": "PERSONAL_CELL_PHONE", "pType": "PERSONAL" };
      else return { "cType": "HOME_PHONE", "pType": "PERSONAL" };

    }

    const getContMechPurpTypeNm = (type) => {
      const index = contactInfoOptions.emailItems.indexOf(type);
      if (index == 0) return "BUSINESS_EMAIL";
      else return "PERSONAL_EMAIL";

    }

    const onChangeRadio1 = (radio) => {
      const obj = {};
      obj.value = radio;
      obj.fieldName = "radio1";
      obj.phoneField = "ccodeValue1";
      let action = getPhoneMechPurpTypeNm(bmType).cType;
      ReactGA.sendevent(gaCategory, action + "_TC", radio);
      return setContactField(constants.contactInfoIds.radioSet, obj);
    }
    const onChangeRadio2 = (radio) => {
      const obj = {};
      obj.value = radio;
      obj.fieldName = "radio2";
      obj.phoneField = "ccodeValue2";
      let action = getPhoneMechPurpTypeNm(pmType).cType;
      ReactGA.sendevent(gaCategory, action + "_TC", radio);
      return setContactField(constants.contactInfoIds.radioSet, obj);
    }
    const onChangeRadioNonUnique = (radio) => {
      const obj = {};
      obj.value = radio;
      obj.fieldName = "radio1";
      obj.phoneField = "ccodeValue1";
      let action = getPhoneMechPurpTypeNm(pmType).cType;
      ReactGA.sendevent(gaCategory, action + "_TC", radio);
      return setContactField(constants.contactInfoIds.radioSet, obj);
    }
    const onChangeRadio3 = (radio) => {
      const obj = {};
      obj.value = radio;
      obj.fieldName = "radio3";
      obj.phoneField = "ccodeValue3";
      let action = getPhoneMechPurpTypeNm(blType).cType;
      ReactGA.sendevent(gaCategory, action + "_TC", radio);
      return setContactField(constants.contactInfoIds.radioSet, obj);
    }
    const onChangeRadio4 = (radio) => {
      const obj = {};
      obj.value = radio;
      obj.fieldName = "radio4";
      obj.phoneField = "ccodeValue4";
      let action = getPhoneMechPurpTypeNm(plType).cType;

      ReactGA.sendevent(gaCategory, action + "_TC", radio);
      return setContactField(constants.contactInfoIds.radioSet, obj);
    }
    const onChangeRadio5 = (radio) => {
      const obj = {};
      obj.value = radio;
      obj.fieldName = "radio5";
      obj.phoneField = "pEmail";
      ReactGA.sendevent(gaCategory, 'PRIMARY_EMAIL_TS', radio);
      return setContactField(constants.contactInfoIds.radioSet, obj);
    }

    const onChangeExtn1 = (extn) => {
      const obj = {};
      obj.value = extn;
      obj.fieldName = "extn1";
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EXTN" });
      return setContactField(constants.contactInfoIds.extnSet, obj);
    }
    const onChangeExtn2 = (extn) => {
      const obj = {};
      obj.value = extn;
      obj.fieldName = "extn2";
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EXTN" });
      return setContactField(constants.contactInfoIds.extnSet, obj);
    }
    const onChangeExtn3 = (extn) => {
      const obj = {};
      obj.value = extn;
      obj.fieldName = "extn3";
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EXTN" });
      return setContactField(constants.contactInfoIds.extnSet, obj);
    }
    const onChangeExtn4 = (extn) => {
      const obj = {};
      obj.value = extn;
      obj.fieldName = "extn4";
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EXTN" });
      return setContactField(constants.contactInfoIds.extnSet, obj);
    }
    const handleOnChange1 = (value) => {

      const obj = {};
      obj.fieldName = "bMobile";
      obj.fieldValid = "bMobileValid";
      obj.ccodeValueField = "ccodeValue1";
      obj.ccodeField = "ccode1";
      obj.radioField = "radio1";
      obj.errorIndex = 1;

      let ccode = value.selectedCountry.dialCode;
      let pos = ccode.length;
      if (value.formattedNumber != "+" + ccode) {
        obj.formattedNumber = value.formattedNumber.replace(/\D/g, '').slice(pos);
        obj.isValid = value.isValid;
        obj.ccode = ccode;
        obj.iso2 = value.selectedCountry.iso2;

      }
      else {
        obj.isValid = true;
        obj.formattedNumber = null;
      }
      if (fieldDisable[1])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PHONE_1" });
      return setContactField(constants.contactInfoIds.phoneSet, obj);
    }
    const handleOnChange2 = (value) => {

      const obj = {};
      obj.fieldName = "pMobile";
      obj.fieldValid = "pMobileValid";
      obj.ccodeValueField = "ccodeValue2";
      obj.ccodeField = "ccode2";
      obj.radioField = "radio2";
      obj.errorIndex = 3;

      let ccode = value.selectedCountry.dialCode;
      let pos = ccode.length;

      if (value.formattedNumber != "+" + ccode) {
        obj.formattedNumber = value.formattedNumber.replace(/\D/g, '').slice(pos);
        obj.isValid = value.isValid;
        obj.ccode = ccode;
        obj.iso2 = value.selectedCountry.iso2;

      }
      else {
        obj.isValid = true;
        obj.formattedNumber = null;
      }
      if (fieldDisable[3])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PHONE_2" });
      return setContactField(constants.contactInfoIds.phoneSet, obj);
    }
     const handleOnChangeNonUnique = (value) => {

      const obj = {};
      obj.fieldName = "pMobile";
      obj.fieldValid = "pMobileValid";
      obj.ccodeValueField = "ccodeValue1";
      obj.ccodeField = "ccode1";
      obj.radioField = "radio1";
      obj.errorIndex = 3;

      let ccode = value.selectedCountry.dialCode;
      let pos = ccode.length;

      if (value.formattedNumber != "+" + ccode) {
        obj.formattedNumber = value.formattedNumber.replace(/\D/g, '').slice(pos);
        obj.isValid = value.isValid;
        obj.ccode = ccode;
        obj.iso2 = value.selectedCountry.iso2;

      }
      else {
        obj.isValid = true;
        obj.formattedNumber = null;
      }
      if (fieldDisable[3])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PHONE_2" });
      return setContactField(constants.contactInfoIds.phoneSet, obj);
    }
    const handleOnChange3 = (value) => {

      const obj = {};
      obj.fieldName = "bLandline";
      obj.fieldValid = "bLandlineValid";
      obj.ccodeValueField = "ccodeValue3";
      obj.radioField = "radio3";
      obj.ccodeField = "ccode3";
      obj.errorIndex = 4;

      let ccode = value.selectedCountry.dialCode;
      let pos = ccode.length;

      if (value.formattedNumber != "+" + ccode) {
        obj.formattedNumber = value.formattedNumber.replace(/\D/g, '').slice(pos);
        obj.isValid = value.isValid;
        obj.ccode = ccode;
        obj.iso2 = value.selectedCountry.iso2;

      }
      else {
        obj.isValid = true;
        obj.formattedNumber = null;
      }
      if (fieldDisable[4])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PHONE_3" });
      return setContactField(constants.contactInfoIds.phoneSet, obj);
    }
    const handleOnChange4 = (value) => {

      const obj = {};
      obj.fieldName = "pLandline";
      obj.fieldValid = "pLandlineValid";
      obj.ccodeValueField = "ccodeValue4";
      obj.ccodeField = "ccode4";
      obj.radioField = "radio4";
      obj.errorIndex = 5;

      let ccode = value.selectedCountry.dialCode;
      let pos = ccode.length;

      if (value.formattedNumber != "+" + ccode) {
        obj.formattedNumber = value.formattedNumber.replace(/\D/g, '').slice(pos);
        obj.isValid = value.isValid;
        obj.ccode = ccode;
        obj.iso2 = value.selectedCountry.iso2;

      }
      else {
        obj.isValid = true;
        obj.formattedNumber = null;
      }
      if (fieldDisable[5])
        return;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "PHONE_4" });
      return setContactField(constants.contactInfoIds.phoneSet, obj);
    }
    return (
      <div>
        {isAuthorized == false && showModalTitle && (<Modal id="errorModal"
          className="vdl-modal--alert"
          show={showModalError}
        >
          <ModalHeader closeButton={false} className="modal-header">
            <ModalTitle className='modal-title-error'>
              <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
              {showModalTitle}
            </ModalTitle>
          </ModalHeader>
          <ModalBody className="modal-body-footercolor">
            <div>
              <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
            </div>
          </ModalBody>
          <ModalFooter className="modal-body-footercolor">
            <Button id="cInfo_okBtn1" onClick={cancelAndCheckYourEntries} className="safari-button-align">
              {<FormattedMessage id="Lbl_OkButton" />}</Button>
          </ModalFooter>
        </Modal>)}

        {isAuthorized == true && contactInfoOptions && !isProofingContactInfo && (contactInfoOptions.emailItems && (
          <form id="formId" onKeyPress={_handleKeyPress} className="contact-info-form">
            {(window.innerWidth <= 479) && (<Button onClick={bottomFunction} id="downIndicator"
              className={(footer != undefined && footer.showFooterContent != undefined && footer.showFooterContent === true) ? "downIndicatorMobileWeb" : null}
              style={{ cursor: "pointer" }} aria-label="Click to scroll down" >
              <FaArrowDown className="down-arrow" />
            </Button>)}
            <div className="registration-content " id="contact-form" style={{ marginBottom: '0' }}>
              <div >
                <div className="registration-form-container" >
                  {
                    (window.innerWidth > 479) && <div className="contact-info-secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
                  }
                  {
                    (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
                  }
                  {(!isMobile) && (
                    <div tabIndex = "0" className="focus-selector" style={{ marginLeft: "auto", paddingRight: "10px" }} 
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'CANCEL');
                        return cancelAndCheckYourEntries();
                      }
                    }}
                    >
                      <svg id="cInfo_cancel" 
                      onClick={() => {
                        ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'CANCEL');
                        return cancelAndCheckYourEntries();
                      }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                        style={{
                          cursor: "pointer",
                          float: "right",
                          fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                        }}>
                        <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                      </svg>
                    </div>
                  )}

                </div>

                {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
                  <div className="registration-progress-bar">
                    <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
                  </div>
                )}

                {(!isMobile) && <hr className="contact-info-hr" />
                }
              </div>
              <div className="vdl-row cf-registration-form ">
                <div className="vdl-col-md-12 welcome-title title-width">
                  <h1 id="contact-layout_welcome" className={cssTitle ? 'page-title-for-lengthy-text' : 'page-title'}>{headerTitle}</h1>
                </div>
                {(showAlert) && (<Alert style={{ width: '100%' }} type="error" id="alertId" >
                  <p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(showAlertMessage)}</p>
                </Alert>
                )}
                <div className="adp-form  cf-background-white padding-align-allflows cf-margin-top-0" id="contact-form-1">



                  <div className="form-group form-align">
                    <span id="info-text-1" className="cf-vdl-font-size cf-info-text-1">{<FormattedMessage id="infoText1" />}</span>
                    <span id="info-text-2" className="cf-vdl-font-size cf-info-text-2">{<FormattedMessage id="infoText2" />}</span>
                    <div className="vdl-row cf-border-white" />
                    <div id={this.props.intl.formatMessage({ id: 'infoText1' })}>
                      <div role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'infoText1' })}>

                        <div className="vdl-row">
                          <div className="vdl-col-sm-6 vdl-col-xs-12">
                            <label id="email-label-1" className="cf-vdl-font-size-lbl cf-required"><FormattedMessage id="emailLabel" /></label>
                          </div>
                        </div>
                        <div className="vdl-row">
                          <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                            <DropdownList aria-label={this.props.intl.formatMessage({ id: 'emailLabel' })+'dropdown'}
                              id="EmailType" 
                              value={peType}
                              onChange={onSetPEmailType}
                              disabled={fieldDisable[0] || emailDisabled}
                              data={contactInfoOptions.emailItems}
                              placeholder={'Select Email'}
                              className="cf-vdl-dropdown"
                            />
                          </div>

                          <div className="vdl-col-sm-7 vdl-col-xs-12 ">
                            <OverlayTrigger isAriaExpanded={ false }
                              trigger="click" placement="bottom"
                              overlay={errorPopover(pEmailValid, 0)}

                            >
                              <Textbox autocomplete="email"
                                maxLength={100} 
                                id="EmailValue" aria-label={this.props.intl.formatMessage({ id: 'emailLabel' })}
                                className={fieldValid[0] ? 'cf-lbl-align' : 'vdl-validation-error cf-lbl-align'}
                                disabled={fieldDisable[0]}
                                value={pEmail}
                                onChange={onSetEmail}
                                onClick={onClickText}
                              />
                            </OverlayTrigger>
                          </div>

                        </div>
                      </div>

                    </div>
                    {/*************************************Total Source Start*******************************************/}

                    {tsEligible && pEmail && contactInfoOptions.emailItems[1] == peType && (<div id="ts_text_11" className="cf-vdl-font-size ">
                      {<FormattedMessage id="Msg_TotalsourceRegistrationPaperlessElection1" />}
                      <a aria-label="TermsAndConditions"
                        onClick={() => {
                          ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_TEXT_CLICK', 'MODAL');
                          return onCancelTSModal(true);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                          e.preventDefault();
                          ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_TEXT_CLICK', 'MODAL');
                          return onCancelTSModal(true);
                          }
                        }}
                        id="ts_link_1"
                        className="focus-selector"
                        target="_blank"
                        role="link"
                        tabIndex="0"
                      >
                        {<FormattedMessage id="Msg_TotalsourceRegistrationPaperlessElection2" />}
                        <span className="cf-required-icon">*</span>
                      </a>
                      <div className="cf-personal-email-radio-div">
                        <Radio
                          value="Yes"
                          radioGroupValue={radio5}
                          onChange={onChangeRadio5}
                          id="radio51_1"
                        >
                          <div style={{ fontSize: '16px', color: '#4a4a4a' }}>
                            <span id="ts_text_12">{tsTypes[0]}</span>
                            <a aria-label="TermsAndConditions" tabIndex="0"
                              onClick={() => {
                                ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_RADIO_CLICK', 'MODAL');
                                return onCancelTSModal(true)
                              }}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();
                                ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_RADIO_CLICK', 'MODAL');
                                return onCancelTSModal(true);
                                }
                              }}
                              id="ts_link_2"
                              className="focus-selector"
                              target="_blank"
                            >
                              {tsTypes[1]}
                            </a>
                            <span id="ts-text-13" >{tsTypes[2]}</span>
                          </div>
                        </Radio>
                      </div>
                      <div>
                        <Radio
                          value="No"
                          radioGroupValue={radio5}
                          onChange={onChangeRadio5}
                          id="radio52_1"
                        >
                          <span style={{ fontSize: '16px', color: '#4a4a4a' }} id="ts-text-14" >{tsTypes[3]}</span>
                        </Radio>
                      </div>
                    </div>)}

                    {/*************************************Total Source End*******************************************/}

                    <div className="vdl-row cf-border-white" />

                    <div id={this.props.intl.formatMessage({ id: 'infoText1' })}>
                      <div role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'infoText1' })}>

                        <div className="vdl-row">
                          <div className="vdl-col-sm-6 vdl-col-xs-12">
                            <label id="mobile-label-1" className={bMobileRequired ? 'cf-vdl-font-size-lbl cf-required' : 'cf-vdl-font-size-lbl'}> {<FormattedMessage id="mobileLabel" />}</label>
                          </div>
                        </div>

                        {/*************************************start phone 1*******************************************/}
                        {((contactInfoOptions.embeddedPicEmailNotUnique === false ) && (contactInfoOptions.phoneItems[1] != bmType || contactInfoOptions.runAdminFlow)) && (<div className="vdl-row">
                          <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                            <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                              id="PhoneType1" 
                              value={bmType}
                              onChange={onSetBMobileType}
                              disabled={fieldDisable[1]}
                              data={contactInfoOptions.phoneItems}
                              placeholder={'Select Phone'}
                              className="cf-vdl-dropdown"

                            />
                          </div>

                          <div className="vdl-col-sm-7 vdl-col-xs-8">
                            <CountryCodeDropdown id="PhoneValue1" title={this.props.intl.formatMessage({ id: 'mobileLabel' })} flagId="PhoneCountryCd_1" ref={instance => { this.countryCodeDropdown1 = instance; }} onChange={handleOnChange1} validationRule={mobileValidationPolicy} value={bMobile}
                              gaName={gaCategory} type={getPhoneMechPurpTypeNm(bmType).cType} dialCode={ccode1} iso={ccodeValue1} disableField={fieldDisable[1]} fieldValid={fieldValid[1]}
                              errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="mobile" indexField="0" onlyCountries={onlyCountries} />

                          </div>
                        </div>)}
                          {(contactInfoOptions.embeddedPicEmailNotUnique) && (<div className="vdl-row">
                          <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                            <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                              id="PhoneType1" 
                              value={pmType}
                              onChange={onSetPMobileType}
                              disabled={fieldDisable[1]}
                              data={contactInfoOptions.phoneItems}
                              placeholder={'Select Phone'}
                              className="cf-vdl-dropdown"
                            />
                          </div>

                          <div className="vdl-col-sm-7 vdl-col-xs-8">
                            <CountryCodeDropdown id="PhoneValue1" title={this.props.intl.formatMessage({ id: 'mobileLabel' })} flagId="PhoneCountryCd_1" ref={instance => { this.countryCodeDropdown1 = instance; }} onChange={handleOnChangeNonUnique} validationRule={mobileValidationPolicy} value={pMobile}
                              gaName={gaCategory} type={getPhoneMechPurpTypeNm(pmType).cType} dialCode={ccode1} iso={ccodeValue1} disableField={fieldDisable[1]} fieldValid={fieldValid[1]}
                              errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="mobile" indexField="0" onlyCountries={onlyCountries} />

                          </div>
                        </div>)}
                    {/*mobile end 1*/}

                    {((contactInfoOptions.embeddedPicEmailNotUnique === false ) && contactInfoOptions.phoneItems[1] == bmType && !contactInfoOptions.runAdminFlow) && (<div className="vdl-row">
                      <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                        <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                          id="PhoneType2" 
                          value={bmType}
                          onChange={onSetBMobileType}
                          disabled={fieldDisable[1]}
                          data={contactInfoOptions.phoneItems}
                          placeholder={'Select Phone'}
                          className="cf-vdl-dropdown"
                        />
                      </div>
                      <div className="vdl-col-sm-5 vdl-col-xs-8">
                        <CountryCodeDropdown ref={instance => { this.countryCodeDropdown1 = instance; }} id="PhoneValue2" flagId="PhoneCountryCd_2" onChange={handleOnChange1} validationRule={mobileValidationPolicy} value={bMobile} title={this.props.intl.formatMessage({ id: 'mobileLabel' })}
                          gaName={gaCategory} type={getPhoneMechPurpTypeNm(bmType).cType} dialCode={ccode1} iso={ccodeValue1} disableField={fieldDisable[1]} fieldValid={fieldValid[1]}
                          errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="landline" indexField="0" onlyCountries={onlyCountries} />

                      </div>
                      <div className="vdl-col-sm-2 vdl-col-xs-4">
                        <Textbox
                          maxLength={20} aria-label={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                          id="ExtensionNum1"
                          onChange={onChangeExtn1}
                          className="cf-vdl-extn"
                          value={extn1}
                          placeholder={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                          disabled={(fieldDisable[1]) ? true : false}
                          onClick={onClickText}
                        />
                      </div>

                    </div>)}


                    {/*************************************end phone 1*******************************************/}




                    {/*start T&C*/}


                    {((contactInfoOptions.embeddedPicEmailNotUnique === false && bMobile && (bmType == contactInfoOptions.phoneItems[0] || bmType == contactInfoOptions.phoneItems[2] || contactInfoOptions.runAdminFlow))) &&
                      (<div>
                        <div className="cf-tc-div">
                          <span id="tc-text-1" className="cf-font-size-txt" >
                            {<FormattedMessage id="TermsConditions1" />}
                            <span style={{ color: '#940a0a' }}>*</span>
                          </span>
                          <a
                            onClick={() => {
                              let action = getPhoneMechPurpTypeNm(bmType).cType;
                              ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                              return onOpenTnCModal();
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                let action = getPhoneMechPurpTypeNm(bmType).cType;
                                ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                return onOpenTnCModal();
                              }
                            }}
                            id="tc_link_1"
                            aria-label="TermsAndConditions"
                            className="focus-selector"
                            target="_blank"
                            role="link"
                            tabIndex="0"
                          >
                            <ExternalLinkIcon style={{ marginLeft: "1%" }} />

                          </a>
                        </div>
                        <div className="showcase">
                          <Radio className="cf-radio-toggle"
                            value="Yes"
                            radioGroupValue={radio1}
                            inline={'true'}
                            onChange={onChangeRadio1}
                            id="radio11"
                          >
                            {<FormattedMessage id="RadioYes" />}
                          </Radio>
                          <Radio className="cf-radio-toggle"
                            value="No"
                            radioGroupValue={radio1}
                            inline={'true'}
                            onChange={onChangeRadio1}
                            id="radio12"
                          >
                            {<FormattedMessage id="RadioNo" />}
                          </Radio>

                        </div>
                      </div>)}
                      {(contactInfoOptions.embeddedPicEmailNotUnique) && pMobile &&
                      (<div>
                        <div className="cf-tc-div">
                          <span id="tc-text-1-1" className="cf-font-size-txt" >
                            {<FormattedMessage id="TermsConditions1" />}
                            <span style={{ color: '#940a0a' }}>*</span>
                          </span>
                          <a
                            onClick={() => {
                              let action = getPhoneMechPurpTypeNm(bmType).cType;
                              ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                              return onOpenTnCModal();
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                let action = getPhoneMechPurpTypeNm(bmType).cType;
                              ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                return onOpenTnCModal();
                              }
                            }}
                            id="tc_link_1_1"
                            aria-label="TermsAndConditions"
                            className="focus-selector"
                            target="_blank"
                            role="link"
                            tabIndex="0"
                          >
                            <ExternalLinkIcon style={{ marginLeft: "1%" }} />

                          </a>
                        </div>
                        <div className="showcase">
                          <Radio className="cf-radio-toggle"
                            value="Yes"
                            radioGroupValue={radio1}
                            inline={'true'}
                            onChange={onChangeRadioNonUnique}
                            id="radio111"
                          >
                            {<FormattedMessage id="RadioYes" />}
                          </Radio>
                          <Radio className="cf-radio-toggle"
                            value="No"
                            radioGroupValue={radio1}
                            inline={'true'}
                            onChange={onChangeRadioNonUnique}
                            id="radio121"
                          >
                            {<FormattedMessage id="RadioNo" />}
                          </Radio>

                        </div>
                      </div>)}

                    {/*end T&C*/}

                  </div>
                </div>
                </div>
              </div>
                    

                {showSecoundaryForm && (
                  <div className="adp-form  cf-background-white padding-align-allflows" id="contact-form-2">

                    <div className="form-group form-align">
                      <div className="form-group form-align">


                        <span id="info-text-3" className="cf-vdl-font-size cf-info-text-1">{<FormattedMessage id="infoText3" />}</span>
                        <span id="info-text-4" className="cf-vdl-font-size cf-info-text-2">{<FormattedMessage id="infoText4" />}</span>

                        <div className="vdl-row cf-border-white" />

                        <div id={this.props.intl.formatMessage({ id: 'infoText3' })}>
                          <div role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'infoText3' })}>

                            <div className="vdl-row">

                              <div className="vdl-col-sm-6 vdl-col-xs-12 ">
                                <label id="email-label-2" className="cf-vdl-font-size-lbl">{<FormattedMessage id="emailLabel" />}</label>
                              </div>
                            </div>

                            <div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'emailLabel' })+'dropdown'}
                                  id="AddlEmailType" 
                                  value={seType}
                                  onChange={onSetSEmailType}
                                  disabled={fieldDisable[2] || emailDisabled}
                                  data={contactInfoOptions.emailItems}
                                  placeholder={'Select Email'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>

                              <div className="vdl-col-sm-7 vdl-col-xs-12 ">
                                <OverlayTrigger isAriaExpanded={ false }
                                  trigger="click" placement="bottom"
                                  overlay={errorPopover(sEmailValid, 2)}

                                >
                                  <Textbox autocomplete="email" id="AddlEmailValue" aria-label={this.props.intl.formatMessage({ id: 'emailLabel' })}
                                    maxLength={100}
                                    className={fieldValid[2] ? 'cf-lbl-align' : 'vdl-validation-error cf-lbl-align'}
                                    disabled={fieldDisable[2]}
                                    value={sEmail}
                                    onChange={onSetSEmail}
                                    onClick={onClickText}
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                        {/*************************************Total Source Start*******************************************/}

                        {tsEligible && sEmail && contactInfoOptions.emailItems[1] == seType && (<div id="ts_text_21" className="cf-vdl-font-size">
                          {<FormattedMessage id="Msg_TotalsourceRegistrationPaperlessElection1" />}
                          <a aria-label="TermsAndConditions"
                            onClick={() => {
                              ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_TEXT_CLICK', 'MODAL');
                              return onCancelTSModal(true);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                              e.preventDefault();
                              ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_TEXT_CLICK', 'MODAL');
                              return onCancelTSModal(true);
                              }
                            }}
                            id="ts_link_3"
                            className="focus-selector"
                            target="_blank"
                            role="link"
                            tabIndex="0"
                          >
                            {<FormattedMessage id="Msg_TotalsourceRegistrationPaperlessElection2" />}
                            <span style={{ color: '#940a0a' }}>*</span>
                          </a>
                          <div className="cf-personal-email-radio-div">
                            <Radio
                              value="Yes"
                              radioGroupValue={radio5}
                              onChange={onChangeRadio5}
                              id="radio51_2"
                            >
                              <div className="cf-font-size-txt">
                                <span id="ts_text_2">{tsTypes[0]}</span>
                                <a aria-label="TermsAndConditions" tabIndex="0"
                                  onClick={() => {
                                    ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_RADIO_CLICK', 'MODAL');
                                    return onCancelTSModal(true);
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                    e.preventDefault();
                                    ReactGA.sendevent(gaCategory, 'PERSONAL_EMAIL_TS_RADIO_CLICK', 'MODAL');
                                    return onCancelTSModal(true);
                                    }
                                  }}
                                  id="ts_link_4"
                                  className="focus-selector"
                                  target="_blank"
                                >
                                  {tsTypes[1]}
                                </a>
                                <span id="ts-text-23">{tsTypes[2]}</span>

                              </div>
                            </Radio>
                          </div>
                          <div>
                            <Radio
                              value="No"
                              radioGroupValue={radio5}
                              onChange={onChangeRadio5}
                              id="radio52_2"
                            >
                              <span className="cf-font-size-txt" id="ts-text-24">{tsTypes[3]}</span>
                            </Radio>

                          </div>

                        </div>)}
                        </div>
                      </div>
                        
                        {/*************************************Total Source End*******************************************/}


                        <div className="vdl-row cf-border-white" />
                        <div id={this.props.intl.formatMessage({ id: 'infoText3' })}>
                          <div role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'infoText3' })}>

                            <div className="vdl-row">
                              <div className="vdl-col-sm-6 vdl-col-xs-12">
                                <label id="mobile-label-2" className="cf-vdl-font-size-lbl">{<FormattedMessage id="mobileLabel" />}</label>
                              </div>
                            </div>
                            {/*************************************start phone 2*******************************************/}

                            {(contactInfoOptions.phoneItems[1] != pmType || contactInfoOptions.runAdminFlow) && (<div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                                  id="AddlPhoneType_1" 
                                  value={pmType}
                                  onChange={onSetPMobileType}
                                  disabled={fieldDisable[3]}
                                  data={contactInfoOptions.phoneItems}
                                  placeholder={'Select Phone'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>
                              <div className="vdl-col-sm-7 vdl-col-xs-8">
                                <CountryCodeDropdown ref={instance => { this.countryCodeDropdown2 = instance; }} id="AddlPhoneValue_1" title={this.props.intl.formatMessage({ id: 'mobileLabel' })} flagId="AddlPhoneCountryCd_1" onChange={handleOnChange2} validationRule={mobileValidationPolicy} value={pMobile}
                                  gaName={gaCategory} type={getPhoneMechPurpTypeNm(pmType).cType} dialCode={ccode2} iso={ccodeValue2} disableField={fieldDisable[3]} fieldValid={fieldValid[3]}
                                  errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="mobile" indexField="1" onlyCountries={onlyCountries} />

                              </div>
                            </div>)}
                            {/*mobile end 2*/}

                            {(contactInfoOptions.phoneItems[1] == pmType && !contactInfoOptions.runAdminFlow) && (<div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                                  id="AddlPhoneType_2" 
                                  value={pmType}
                                  onChange={onSetPMobileType}
                                  disabled={fieldDisable[3]}
                                  data={contactInfoOptions.phoneItems}
                                  placeholder={'Select Phone'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>
                              <div className="vdl-col-sm-5 vdl-col-xs-8">
                                <CountryCodeDropdown ref={instance => { this.countryCodeDropdown2 = instance; }} title={this.props.intl.formatMessage({ id: 'mobileLabel' })} id="AddlPhoneValue_2" flagId="AddlPhoneCountryCd_2" onChange={handleOnChange2} validationRule={mobileValidationPolicy} value={pMobile}
                                  gaName={gaCategory} type={getPhoneMechPurpTypeNm(pmType).cType} dialCode={ccode2} iso={ccodeValue2} disableField={fieldDisable[3]} fieldValid={fieldValid[3]}
                                  errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="landline" indexField="1" onlyCountries={onlyCountries} />

                              </div>
                              <div className="vdl-col-sm-2  vdl-col-xs-4">
                                <Textbox

                                  maxLength={20} aria-label={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                                  id="ExtensionNum2"
                                  className="cf-vdl-extn"
                                  value={extn2}
                                  placeholder={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                                  onChange={onChangeExtn2}
                                  disabled={(fieldDisable[3]) ? true : false}
                                  onClick={onClickText}

                                />
                              </div>

                            </div>)}
                            {/*************************************end phone 2*******************************************/}

                            {(pMobile && (pmType == contactInfoOptions.phoneItems[0] || pmType == contactInfoOptions.phoneItems[2] || contactInfoOptions.runAdminFlow) &&
                              <div>
                                <div className="cf-tc-div">
                                  <span id="tc-text-2" className="cf-font-size-txt" >
                                    {<FormattedMessage id="TermsConditions1" />}
                                    <span style={{ color: '#940a0a' }}>*</span>
                                  </span>

                                  <a aria-label="TermsAndConditions"
                                    onClick={() => {
                                      let action = getPhoneMechPurpTypeNm(pmType).cType;
                                      ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                      return onOpenTnCModal();
                                    }}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                      e.preventDefault();
                                      let action = getPhoneMechPurpTypeNm(pmType).cType;
                                      ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                      return onOpenTnCModal();
                                      }
                                    }}
                                    id="tc_link_2"
                                    className="focus-selector"
                                    target="_blank"
                                    role="link"
                                    tabIndex="0"
                                  >
                                    <ExternalLinkIcon style={{ marginLeft: "1%" }} />

                                  </a>
                                </div>

                                <div className="showcase">
                                  <Radio className="cf-radio-toggle"
                                    value="Yes"
                                    radioGroupValue={radio2}
                                    inline={'true'}
                                    onChange={onChangeRadio2}
                                    id="radio21"
                                  >
                                    {<FormattedMessage id="RadioYes" />}
                                  </Radio>
                                  <Radio className="cf-radio-toggle"
                                    value="No"
                                    radioGroupValue={radio2}
                                    inline={'true'}
                                    onChange={onChangeRadio2}
                                    id="radio22"
                                  >
                                    {<FormattedMessage id="RadioNo" />}
                                  </Radio>

                                </div>
                              </div>)}

                            {/*end of T&C 2*/}
                            <br />

                            {/*************************************start phone 3*******************************************/}

                            {contactInfoOptions.bLandlineRenderable && (contactInfoOptions.phoneItems[1] != blType) && (<div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                                  id="AddlPhoneType1_1" 
                                  value={blType}
                                  onChange={onSetBLandlineType}
                                  disabled={fieldDisable[4]}
                                  data={contactInfoOptions.phoneItems}
                                  placeholder={'Select Phone'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>
                              <div className="vdl-col-sm-7 vdl-col-xs-8">
                                <CountryCodeDropdown ref={instance => { this.countryCodeDropdown3 = instance; }} title={this.props.intl.formatMessage({ id: 'mobileLabel' })} id="AddlPhoneValue1_1" flagId="AddlPhoneCountryCd1_1" onChange={handleOnChange3} validationRule={mobileValidationPolicy} value={bLandline}
                                  gaName={gaCategory} type={getPhoneMechPurpTypeNm(blType).cType} dialCode={ccode3} iso={ccodeValue3} disableField={fieldDisable[4]} fieldValid={fieldValid[4]}
                                  errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="mobile" indexField="2" onlyCountries={onlyCountries} />

                              </div>
                            </div>)}

                            {/*mobile end 3*/}

                            {contactInfoOptions.bLandlineRenderable && (contactInfoOptions.phoneItems[1] == blType) && (<div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                                  id="AddlPhoneType1_2" 
                                  value={blType}
                                  onChange={onSetBLandlineType}
                                  disabled={fieldDisable[4]}
                                  data={contactInfoOptions.phoneItems}
                                  placeholder={'Select Phone'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>
                              <div className="vdl-col-sm-5 vdl-col-xs-8">
                                <CountryCodeDropdown ref={instance => { this.countryCodeDropdown3 = instance; }} id="AddlPhoneValue1_2" title={this.props.intl.formatMessage({ id: 'mobileLabel' })} flagId="AddlPhoneCountryCd1_2" onChange={handleOnChange3} validationRule={mobileValidationPolicy} value={bLandline}
                                  gaName={gaCategory} type={getPhoneMechPurpTypeNm(blType).cType} dialCode={ccode3} iso={ccodeValue3} disableField={fieldDisable[4]} fieldValid={fieldValid[4]}
                                  errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="landline" indexField="2" onlyCountries={onlyCountries} />

                              </div>
                              <div className="vdl-col-sm-2 vdl-col-xs-4">
                                <Textbox

                                  maxLength={20} aria-label={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                                  id="ExtensionNum3"
                                  disabled={(fieldDisable[4]) ? true : false}
                                  onChange={onChangeExtn3}
                                  className="cf-vdl-extn"
                                  value={extn3}
                                  placeholder={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                                  onClick={onClickText}

                                />
                              </div>



                            </div>)}

                            {/*************************************end phone 3*******************************************/}


                            {contactInfoOptions.bLandlineRenderable && bLandline && (blType == contactInfoOptions.phoneItems[0] || blType == contactInfoOptions.phoneItems[2]) && (
                              <div>
                                <div className="cf-tc-div">
                                  <span id="tc-text-3" className="cf-font-size-txt" >
                                    {<FormattedMessage id="TermsConditions1" />}
                                    <span style={{ color: '#940a0a' }}>*</span>
                                  </span>
                                  <a aria-label="TermsAndConditions"
                                    onClick={() => {
                                      let action = getPhoneMechPurpTypeNm(blType).cType;
                                      ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                      return onOpenTnCModal();
                                    }} 
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        let action = getPhoneMechPurpTypeNm(blType).cType;
                                        ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                        return onOpenTnCModal();
                                      }
                                    }}
                                    id="tc_link_3"
                                    className="focus-selector"
                                    target="_blank"
                                    role="link"
                                    tabIndex="0"
                                  >
                                    <ExternalLinkIcon style={{ marginLeft: "1%" }} />
                                  </a>
                                </div>

                                <div className="showcase">
                                  <Radio className="cf-radio-toggle"
                                    value="Yes"
                                    radioGroupValue={radio3}
                                    inline={'true'}
                                    onChange={onChangeRadio3}
                                    id="radio31"
                                  >
                                    {<FormattedMessage id="RadioYes" />}
                                  </Radio>
                                  <Radio className="cf-radio-toggle"
                                    value="No"
                                    radioGroupValue={radio3}
                                    inline={'true'}
                                    onChange={onChangeRadio3}
                                    id="radio32"
                                  >
                                    {<FormattedMessage id="RadioNo" />}
                                  </Radio>

                                </div>
                              </div>)}


                            {/*end T&C 3*/}
                            <br />
                            {/*************************************start phone 4*******************************************/}

                            {contactInfoOptions.pLandlineRenderable && (contactInfoOptions.phoneItems[1] != plType) && (<div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                                  id="AddlPhoneType2_1" 
                                  value={plType}
                                  onChange={onSetPLandlineType}
                                  disabled={fieldDisable[5]}
                                  data={contactInfoOptions.phoneItems}
                                  placeholder={'Select Phone'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>
                              <div className="vdl-col-sm-7 vdl-col-xs-8">
                                <CountryCodeDropdown ref={instance => { this.countryCodeDropdown4 = instance; }} id="AddlPhoneValue2_1" title={this.props.intl.formatMessage({ id: 'mobileLabel' })} flagId="AddlPhoneCountryCd2_1" onChange={handleOnChange4} validationRule={mobileValidationPolicy} value={pLandline}
                                  gaName={gaCategory} type={getPhoneMechPurpTypeNm(plType).cType} dialCode={ccode4} iso={ccodeValue4} disableField={fieldDisable[5]} fieldValid={fieldValid[5]}
                                  errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="mobile" indexField="3" onlyCountries={onlyCountries} />

                              </div>

                            </div>)}


                            {/*mobile end 4*/}

                            {contactInfoOptions.pLandlineRenderable && (contactInfoOptions.phoneItems[1] == plType) && (<div className="vdl-row">
                              <div className="vdl-col-sm-5 vdl-col-xs-12 cf-vdl-font-size">
                                <DropdownList aria-label={this.props.intl.formatMessage({ id: 'mobileLabel' }) + 'dropdown'}
                                  id="AddlPhoneType2_2" 
                                  value={plType}
                                  onChange={onSetPLandlineType}
                                  disabled={fieldDisable[5]}
                                  data={contactInfoOptions.phoneItems}
                                  placeholder={'Select Phone'}
                                  className="cf-vdl-dropdown"
                                />
                              </div>
                              <div className="vdl-col-sm-5 vdl-col-xs-8">
                                <CountryCodeDropdown ref={instance => { this.countryCodeDropdown4 = instance; }} title={this.props.intl.formatMessage({ id: 'mobileLabel' })}
                                 id="AddlPhoneValue2_2" flagId="AddlPhoneCountryCd2_2" onChange={handleOnChange4} validationRule={mobileValidationPolicy} value={pLandline}
                                  gaName={gaCategory} type={getPhoneMechPurpTypeNm(plType).cType} dialCode={ccode4} iso={ccodeValue4} disableField={fieldDisable[5]} fieldValid={fieldValid[5]}
                                  errorMessage={mobileInvalidMessage} defaultCountry="us" fieldType="landline" indexField="3" onlyCountries={onlyCountries} />

                              </div>
                              <div className="vdl-col-sm-2 vdl-col-xs-4 ">
                                <Textbox

                                  id="ExtensionNum4" aria-label={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                                  maxLength={20}
                                  onChange={onChangeExtn4}
                                  className="cf-vdl-extn"
                                  value={extn4}
                                  placeholder={this.props.intl.formatMessage({ id: 'Lbl_PhoneExtension' })}
                                  disabled={(fieldDisable[5]) ? true : false}
                                  onClick={onClickText}

                                />
                              </div>



                            </div>)}

                            {/*************************************end phone 4*******************************************/}

                            {contactInfoOptions.pLandlineRenderable && pLandline && (plType == contactInfoOptions.phoneItems[0] || plType == contactInfoOptions.phoneItems[2]) && (<div>
                              <div style={{ 'marginTop': '1%' }}>
                                <span id="tc-text-4" className="cf-font-size-txt" >
                                  {<FormattedMessage id="TermsConditions1" />}
                                  <span style={{ color: '#940a0a' }}>*</span>
                                </span>
                                <a aria-label="TermsAndConditions"
                                  onClick={() => {
                                    let action = getPhoneMechPurpTypeNm(plType).cType;
                                    ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                    return onOpenTnCModal();
                                  }} 
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                    e.preventDefault();
                                    let action = getPhoneMechPurpTypeNm(plType).cType;
                                    ReactGA.sendevent(gaCategory, action + "_TC", 'POPUP');
                                    return onOpenTnCModal();
                                    }
                                  }}
                                  id="tc_link_4"
                                  className="focus-selector"
                                  target="_blank"
                                  role="link"
                                  tabIndex="0"
                                >
                                  <ExternalLinkIcon style={{ marginLeft: "1%" }} />
                                </a>
                              </div>

                              <div className="showcase">
                                <Radio className="cf-radio-toggle"
                                  value="Yes"
                                  radioGroupValue={radio4}
                                  inline={'true'}
                                  onChange={onChangeRadio4}
                                  id="radio41"
                                >
                                  {<FormattedMessage id="RadioYes" />}
                                </Radio>
                                <Radio className="cf-radio-toggle"
                                  value="No"
                                  radioGroupValue={radio4}
                                  inline={'true'}
                                  onChange={onChangeRadio4}
                                  id="radio42"
                                >
                                  {<FormattedMessage id="RadioNo" />}
                                </Radio>

                              </div>
                            </div>)}
                          </div>
                        </div>


                        {/*end T&C 4*/}


                        {!(phoneCount <= 0) && (
                          <div className="cf-add-phone-div">
                            <Button role="button" tabIndex = "0" style={{ textDecoration: 'none', fontWeight: 'bold', fontSize: "14px" }} id="addPhone" buttonStyle="link" className=" cf-add-phn-btn"
                              
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();
                                ReactGA.sendevent(gaCategory, 'ADD', 'NEW_PHONE');
                                return enablePField();
                                }
                                }}
                              
                              onClick={() => {
                                ReactGA.sendevent(gaCategory, 'ADD', 'NEW_PHONE');
                                return enablePField();
                              }}
                            ><PlusCircle style={{ marginBottom: '3px' }} /><span style={{ marginLeft: '5px' }}>{<FormattedMessage id="addPhoneBtn.Label" />}</span>
                            </Button>
                          </div>

                        )}
                      </div>

                    </div>
                  </div>

                )}

                <div className="cf-add-secondary-contact-div">
                  {!showSecoundaryForm && !contactInfoOptions.embeddedPicEmailNotUnique && (
                    <Button tabIndex="0" style={{ textDecoration: 'none', fontWeight: 'bold', marginTop: '3%', fontSize: "14px" }} id="addSecoundaryBtn" buttonStyle="link" className=" cf-add-phn-btn"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'ADD', 'SECONDARY_CONTACT_INFO');
                      return enableSForm();
                      }
                      }}  
                      onClick={() => {
                        ReactGA.sendevent(gaCategory, 'ADD', 'SECONDARY_CONTACT_INFO');
                        return enableSForm();
                      }} role="button" 
                    ><PlusCircle style={{ marginBottom: '3px' }} /><span style={{ marginLeft: '5px' }}>{<FormattedMessage id="addContactBtn.Label" />}</span>
                    </Button>)}
                </div>

                {/*************************************WARN MODAL*******************************************/}
                {showModalTitle && (<Modal id="warnModal"
                  className="vdl-modal--alert"
                  show={showModalWarn}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className='modal-title-info'>
                      <WarnIcon className="vdl-modal__title-icon warn-icon-info" />
                      {showModalTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="cInfo_returnBtn1" onClick={onCancelModalWarn} className="safari-button-align">
                      {<FormattedMessage id="Lbl_ReturnButton" />}
                    </Button>
                    <Button id="cInfo_model_continueBtn1" onClick={onContinueModal} className="safari-button-align">
                      {<FormattedMessage id="submitCBtn.Label" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}

                {/*************************************ERROR MODAL*******************************************/}





                {/*************************************ERROR MODAL*******************************************/}
                {showModalTitle && (<Modal id="errorModal"
                  className="vdl-modal--alert"
                  show={showModalError}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className='modal-title-error'>
                      <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                      {showModalTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="cInfo_returnBtn2" onClick={onCancelModalError} className="safari-button-align">
                      {<FormattedMessage id="Lbl_ReturnButton" />}

                    </Button>
                    <Button id="cInfo_model_continueBtn2" onClick={onContinueModal} className="safari-button-align">
                      {<FormattedMessage id="submitCBtn.Label" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}

                {/*************************************ERROR MODAL*******************************************/}




                {/*************************************INFO MODAL*******************************************/}
                {showModalTitle && (<Modal id="infoModal"

                  show={showModalInfo}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false}>
                    <ModalTitle>
                      <WarnIcon className="vdl-modal__title-icon" />
                      {showModalTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      {ReactHtmlParser(showModalMessage)}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button id="cInfo_returnBtn3" onClick={onCancelModalInfo} className="safari-button-align">
                      {<FormattedMessage id="Lbl_ReturnButton" />}
                    </Button>
                    <Button id="cInfo_model_continueBtn3" onClick={onContinueModal} className="safari-button-align">
                      {<FormattedMessage id="submitCBtn.Label" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}

                {/*************************************ERROR MODAL*******************************************/}




                {/*************************************ACCOUNT LOCKED MODAL*******************************************/}
                {showModalTitle && (<Modal id="acLockModal"
                  className="vdl-modal--alert"
                  show={showModalLock}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className='modal-title-error'>
                      <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                      {showModalTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="cInfo_okBtn2" onClick={cancelAndCheckYourEntries}  onKeyPress={(e) => {if (e.key === 'Enter') {return cancelAndCheckYourEntries();}}}
                     className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}</Button>
                  </ModalFooter>
                </Modal>)}

                {/*************************************ACCOUNT LOCKED MODAL*******************************************/}


                {/*************************************Terms and Conditions MODAL*******************************************/}
                <Modal id="TermsNConditionsModal"
                  className="vdl-modal--alert"
                  show={showTnCModal}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={true} onHide={onCancelTnCModal} className="modal-header tnc-modal-header">

                  </ModalHeader>
                  <ModalBody className="tnc-modal-body-footercolor">
                    <div>
                      <iframe className="cf-iframe-touch" src={getLocaleObjFromPropKey(footer.terms.textMessageLegalPage, locale)} width="100%" height="400px" frameBorder="0" />
                    </div>
                  </ModalBody>
                  <ModalFooter className="tnc-modal-body-footercolor">
                    <Button id="cInfo_okBtn3" onClick={onCancelTnCModal} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>



                {/*************************************Total Source MODAL*******************************************/}
                <Modal id="TermsNConditionsModal"
                  className="vdl-modal--alert"
                  show={showTSModal}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={true} onHide={() => onCancelTSModal(false)} className="modal-header tnc-modal-header">

                  </ModalHeader>
                  <ModalBody className="tnc-modal-body-footercolor">
                    <div>
                      <iframe className="cf-iframe-touch" src={tsUrl} width="100%" height="400px" frameBorder="0" />
                    </div>
                  </ModalBody>
                  <ModalFooter className="tnc-modal-body-footercolor">
                    <Button id="cInfo_okBtn4" onClick={() => onCancelTSModal(false)} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>

              </div>

            </div>

            <div className="cf-submit-btn-div"><Button
              id="cInfo_continueBtn"
              style={{ marginTop: '0', minHeight: "35px", minWidth: "180px" }}
              className="primary cf-btn-cnt safari-button-align"
              disabled={!isFormValid}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                e.preventDefault();
                ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'SUBMIT');
                showAlertFlag = true;
                return onFormSubmit();
                }
                }} role="button" tabIndex = "0"
              onClick={() => {
                ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'SUBMIT');
                showAlertFlag = true;
                return onFormSubmit();
              }}
            >
              {<FormattedMessage id="submitCBtn.Label" />}
            </Button>
            </div>


          </form>
        ))}

        {isAuthorized == true && contactInfoOptions && isProofingContactInfo && (
          <PersonalPhoneFormfrom {...this.props} />
        )}
      </div>
    );
  }
}
export default injectIntl(ContactInfoForm);
