import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_REG_CODE',
  'VERIFY_REG_CODE_SUCCESS',
  'VERIFY_REG_CODE_FAIL',
  'SET_REG_CODE',
  'ENABLE_REGCODE_SUBMIT_BUTTON',
  'CANCEL_REGISTRATION_VERIFYUSER',  
  'CHECK_ERROR_MESSAGE',
  'GET_IDENTITY_OPTIONS_SUCCESS',
  'VERIFY_USER_INVALID_PRC_SET_LOCALE',
  'UPDATE_LOCALE_FIELD'
);

const { VERIFY_REG_CODE, SET_REG_CODE , ENABLE_REGCODE_SUBMIT_BUTTON , CANCEL_REGISTRATION_VERIFYUSER , CHECK_ERROR_MESSAGE , GET_IDENTITY_OPTIONS_SUCCESS, VERIFY_USER_INVALID_PRC_SET_LOCALE  } = types;

const verifyRegCodeSent = createAction(VERIFY_REG_CODE);

const setRegCodeSent = createAction(SET_REG_CODE);

const enableSubmitButtonSent = createAction(ENABLE_REGCODE_SUBMIT_BUTTON);

const cancelRegistrationSent = createAction(CANCEL_REGISTRATION_VERIFYUSER);

const checkRegCodeErrMessageSent = createAction(CHECK_ERROR_MESSAGE);

const getIdentityOptionsSucessSent = createAction(GET_IDENTITY_OPTIONS_SUCCESS);

const goToFindMePageSent = createAction(GET_IDENTITY_OPTIONS_SUCCESS);

const setLocaleSent = createAction(VERIFY_USER_INVALID_PRC_SET_LOCALE);

const verifyRegCode = inputRegCode => verifyRegCodeSent(inputRegCode);

const setRegCode = inputRegCode => setRegCodeSent({ regCode: inputRegCode });

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelRegistration = () => cancelRegistrationSent();

const checkRegCodeErrMessage = () => checkRegCodeErrMessageSent();

const getIdentityOptionsSucess = () => getIdentityOptionsSucessSent();

const goToFindMePage = viewId => goToFindMePageSent({viewId});

const setLocale = (payload)=>setLocaleSent({ payload });

export const actions = {
  verifyRegCode,
  setRegCode,
  enableSubmitButton,
  cancelRegistration,
  checkRegCodeErrMessage,
  getIdentityOptionsSucess,
  goToFindMePage,
  setLocale,
};
