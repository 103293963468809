import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-401k-constants';
import { types } from '../verify-401k-actions';
import { ReactGA, PendoUtil } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { WindowUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  GET_401k_SIV_OPTIONS,
  GET_401k_SIV_OPTIONS_SUCCESS,
  GET_401k_SIV_OPTIONS_FAIL,
  SET_401k_SIV_FIELD,
  SET_401k_SIV_FIELD_SUCCESS,
  SET_401k_SIV_FIELD_FAIL,
  VERIFY_401k_SIV_OPTIONS,
  VERIFY_401k_ORG_RESPONSE,
  VERIFY_401k_MULTIORG_RESPONSE,
  CANCEL_AND_CHECK_401k_ENTRIES,
  SET_401k_ORG_SELECTED,
  SET_401k_ORG_SELECTED_SUCCESS,
  SET_401k_ORG_SELECTED_FAIL,
  LAUNCH_401k_IDENTITY,
  LAUNCH_401k_IDENTITY_SUCCESS,
  LAUNCH_401k_IDENTITY_FAIL,
  VERIFY_401k_SIV_OPTIONS_FAIL
} = types;

const get401kSivOptionsSuccess = createAction(GET_401k_SIV_OPTIONS_SUCCESS);
const get401kSivOptionsFail = createAction(GET_401k_SIV_OPTIONS_FAIL);
const setSivFieldSuccessSent = createAction(SET_401k_SIV_FIELD_SUCCESS);
const setSivFieldFailSent = createAction(SET_401k_SIV_FIELD_FAIL);
const verify401kOrgResponse = createAction(VERIFY_401k_ORG_RESPONSE);
const verify401kMultiOrgResponse = createAction(VERIFY_401k_MULTIORG_RESPONSE);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_AND_CHECK_401k_ENTRIES);
const setOrgSelectedSuccess = createAction(SET_401k_ORG_SELECTED_SUCCESS);
const setOrgSelectedFail = createAction(SET_401k_ORG_SELECTED_FAIL);
const launch401kIdentitySuccess = createAction(LAUNCH_401k_IDENTITY_SUCCESS);
const launch401kIdentityFail = createAction(LAUNCH_401k_IDENTITY_FAIL);
const verify401koptionFail = createAction(VERIFY_401k_SIV_OPTIONS_FAIL);
const isBlank = str => (str || '').trim().length === 0;

const getSivOptions = (getState) =>
  sivOptionsApi(getState)
    .pipe(map(response => handleSivResponse(response, getState)),
    catchError(err => [handleSivFailResponse(getState, getErrorMessage(err))]));

const sivOptionsApi = (getState) => {
  if (getState.registration.sivOptions != '' && getState.registration.sivOptions != undefined) {
    getState.verify401k.sivOptions = getState.registration.sivOptions;
    getState.verify401k.sitekey = getState.registration.sitekey;
    getState.verify401k.isRecaptchaOn = getState.registration.isRecaptchaOn;
    getState.verify401k.locale = getState.registration.locale;
    if (getState.registration.orgIdForDeviceAssessment != '') {
      WindowUtil.deviceAssessment(getState.registration.orgIdForDeviceAssessment, getState.registration.transactionId);
    }
    if (getState.registration.isRecaptchaOn != undefined && getState.registration.isRecaptchaOn == true) {
      WindowUtil.recaptchaCall();
    }
    return of({
      sivOptions: getState.registration.sivOptions
    });
  }
  return of({
    sivOptions: ''
  });
}

const handleSivResponse = (response, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_401K";
  let pagePath1 = '/ssr/401K/searchAssociate';
  let pagePath2 = '/ssr/flowType/401K';
  let pageView1 = pagePath1 + "/web";
  let pageView2 = pagePath2 + "/web";
  if (isMobile) { pageView1 = pagePath1 + "/mobile"; pageView2 = pagePath2 + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_401K"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView1 = pagePath1 + "/mobilebrowser"; pageView2 = pagePath2 + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_401K"; }
  ReactGA.pageview(pageView1);
  ReactGA.pageview(pageView2);

  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end

  return get401kSivOptionsSuccess({
    sivOptions: response.sivOptions,
    isMobile,
    isMobileBrowser,
    gaCategory,
    title: getState.intl.messages['identifyYourself.title'],
    userWithMultiOrg: false,
    progressBarData
  });
}

const handleSivFailResponse = (getState, response) => {
  console.log('Siv fail error response ============================> ' + JSON.stringify(response));
  return get401kSivOptionsFail({
    showErrorModal: false,
    errorTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle']
  });
}
{/***********************************************Validate and set siv field values****************************************************/ }

const setSivField = (payload, getState) =>
  validateAndSetSivField(payload, getState)
    .pipe(map(setSivFieldSuccessSent),
    catchError(err => [handleSetSivFieldFailResponse(payload, getErrorMessage(err))]));

const validateAndSetSivField = (payload, getState) => {
  const identifyYourself = getState.verify401k;

  switch (payload.fieldkey) {
    case constants.sivOptionIds.siv_firstName: {
      const firstNameRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_firstname.regExp);

      if (isBlank(payload.fieldValue) || firstNameRegEx.test(payload.fieldValue)) {
        return of({
          firstName: payload.fieldValue,
          firstNameValid: true,
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_firstname.invalidMessage));
    }
    case constants.sivOptionIds.siv_lastName: {
      const lastNameRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_lastname.regExp);

      if (isBlank(payload.fieldValue) || lastNameRegEx.test(payload.fieldValue)) {
        return of({
          lastName: payload.fieldValue,
          lastNameValid: true,
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_lastname.invalidMessage));
    }
    case constants.sivOptionIds.siv_ssn: {
      const ssnRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_ssn.regExp);

      if (isBlank(payload.fieldValue) || ssnRegEx.test(payload.fieldValue)) {
        var replacedSSN = payload.fieldValue.replace(/-/g, "");
        return of({
          ssn: replacedSSN,
          ssnValid: true,
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_ssn.invalidMessage));
    }

    case constants.sivOptionIds.siv_dob_full_month: {
      return of({
        fullMonth: payload.fieldValue,
        fullDay: ''
      });
    }
    case constants.sivOptionIds.siv_dob_full_day: {
      return of({ fullDay: payload.fieldValue });
    }
    case constants.sivOptionIds.siv_dob_full_year: {
      return of({ fullYear: payload.fieldValue });
    }

    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};

const handleSetSivFieldFailResponse = (payload, errorMessage) => {
  switch (payload.fieldkey) {
    case constants.sivOptionIds.siv_firstName:
      return setSivFieldFailSent({
        errorMessageFN: errorMessage,
        firstName: payload.fieldValue,
        firstNameValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_lastName:
      return setSivFieldFailSent({
        errorMessageLN: errorMessage,
        lastName: payload.fieldValue,
        lastNameValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_ssn:
      return setSivFieldFailSent({
        errorMessageSsn: errorMessage,
        ssn: payload.fieldValue,
        ssnValid: false,
        sivOptionsValidated: false,
      });
    default: {
      return setSivFieldFailSent({
        errorMessage,
      });
    }
  }
};

{/**************************************************Verify 401k options****************************************************/ }

const submitSivOptions = (ajax, getState) =>
  verifySivOptions(ajax, getState)
    .pipe(map(response => handleSubmitSivResponse(response.response, getState)),
    catchError(err => handleSubmitSivResponseFail(getState, getErrorMessage(err))));

const verifySivOptions = (ajax, getState) => {
  const identifyYourself = getState.verify401k;
  const sivOptions = identifyYourself.sivOptions;
  const gaCategory = identifyYourself.gaCategory;
  var uiOptionsMap = {};

  if (sivOptions.availableSivOptions.siv_firstname && identifyYourself.firstName != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'FIRST_NAME');
    uiOptionsMap.siv_firstname = identifyYourself.firstName;
    sivOptions.availableSivOptions.siv_firstname.idenDataValue = identifyYourself.firstName;
  }
  if (sivOptions.availableSivOptions.siv_lastname && identifyYourself.lastName != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'LAST_NAME');
    uiOptionsMap.siv_lastname = identifyYourself.lastName;
    sivOptions.availableSivOptions.siv_lastname.idenDataValue = identifyYourself.lastName;
  }

  if (sivOptions.availableSivOptions.siv_ssn && identifyYourself.ssn != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'SSN');
    var encryptedSsn = Crypto.AES.encrypt(identifyYourself.ssn, registrationConstants.registrationEncryption);
    uiOptionsMap.siv_ssn = encryptedSsn + '';
    sivOptions.availableSivOptions.siv_ssn.idenDataValue = identifyYourself.ssn;
  }

  if (sivOptions.availableSivOptions.siv_fulldateofbirth && identifyYourself.fullDay != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'DOB');
    var inputmonthId = sivOptions.sivOptionsCalenderItems.fullMonthItems.indexOf(identifyYourself.fullMonth) + 1;
    inputmonthId = ('0' + inputmonthId).slice(-2);
    var fulldob = '' + inputmonthId + identifyYourself.fullDay + identifyYourself.fullYear;
    uiOptionsMap.siv_fulldateofbirth = fulldob;
    sivOptions.availableSivOptions.siv_fulldateofbirth.idenDataValue = fulldob;
  }

  return ajax({
    url: constants.verify401kDetailsEndPoint,
    body: {
      'sivPIC': sivOptions.sivPIC,
      'uiSivOptionOptions': uiOptionsMap,
      'recaptchaResponse': identifyYourself.recaptchaResponse
    },
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
};

const handleSubmitSivResponse = (response, getState) => {
  console.log('handleSubmitSivResponse:' + response);
  const identifyYourself = getState.verify401k;
  const gaCategory = identifyYourself.gaCategory;

  if (response.code != null && response.code == constants.SUCCESS) {
    if (response.userWithMultiOrg === true && response.orgChoices != null) {
      var multiOrgs = [];
      var multiOrgIndex = [];
      let k = 0;
      for (var key in response.orgChoices) {
        multiOrgs[k] = response.orgChoices[key];
        multiOrgIndex[k] = key;
        k++;
      }
      let multiOrgtitle = getState.intl.messages['lbl_multiOrgPageTitle'];
      let replacedTitle = multiOrgtitle.replace("{0}", identifyYourself.firstName);
      return verify401kMultiOrgResponse({
        userWithMultiOrg: response.userWithMultiOrg,
        multiOrgs: multiOrgs,
        multiOrgPageInfo: response.multiOrgMsg,
        title: replacedTitle,
        multiOrgIndex: multiOrgIndex,
        launch401kIdentityCall: false,
        errorTitle: ''
      });
    }
    else if (response.userWithMultiOrg === false && response.orgIndex != '') {
      PendoUtil.pendoVisitorIdUpdate(response.associateId)
      console.log('==handleSubmitSivResponse else if block:' + response.orgIndex);
      return verify401kOrgResponse({
        launch401kIdentityCall: true
      });
    }
  }

  if (response.code != null) {
    if (response.code == constants.ALREADY_REGISTERED || response.code == constants.ALREADY_REGISTERED_MULTI || response.code == constants.ALREADY_REGISTERED_SUSPENDED) {
      ReactGA.sendevent(gaCategory, 'VERIFY_401K', response.code);
      console.log('TRUE;response.code=' + response.code);
      if(response.code == constants.ALREADY_REGISTERED) {
        return cancelAndCheckYourEntriesSent({
          errorCode: response.code
        });
      }
      return verify401koptionFail({
        showErrorModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorCode: response.code,
        errorTitle: '',
        ssn: ''
      });
    }
    if (response.code == constants.EXCEEDED_ATTEMPTS || response.code == constants.FUNCTION_LOCKED || response.code == constants.KBA_LOCKED ||
      response.code == constants.EMAIL_NOT_EXISTS || response.code == constants.RECAPTCHA_FAILED || response.code == constants.NO_SIV_DEFINED) {

      ReactGA.sendevent(gaCategory, 'VERIFY_401K', response.code);
      console.log('TRUE;response.code=' + response.code);
      return verify401koptionFail({
        showErrorModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: '',
        ssn: '',
        recaptchaSuccess: true
      });
    }
  }
  console.log('response.message=' + response.message);
  return verify401koptionFail({
    showErrorModal: false,
    errorTitle: response.message,
    ssn: '',
    recaptchaSuccess: true
  });
};

const handleSubmitSivResponseFail = (getState, response) => {
  return verify401koptionFail({
    showErrorModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    ssn: '',
    errorTitle: '',
  });
};

{/**************************************************Launch 401k Identity****************************************************/ }
const launch401kIdentity = (ajax, getState) =>
  launch401kIdentityApi(ajax, getState)
    .pipe(map(response => handlelaunch401kIdentityResponse(response.response, getState)),
    catchError(err => handlelaunch401kFailResponse(getState,getErrorMessage(err))));

const launch401kIdentityApi = (ajax, getState) => {
  return ajax({
    url: constants.launch401kDetailsEndPoint,
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
}

const handlelaunch401kIdentityResponse = (response, getState) => {
  const identifyYourself = getState.verify401k;
  const gaCategory = identifyYourself.gaCategory;
  console.log("handlelaunch401kIdentityResponse" + response);
  if (response.code != null && (response.code == constants.ALREADY_REGISTERED || response.code == constants.ALREADY_REGISTERED_MULTI)) {
    ReactGA.sendevent(gaCategory, 'VERIFY_401K', response.code);
    console.log('TRUE;response.code=' + response.code);
    if(response.code == constants.ALREADY_REGISTERED) {
        return cancelAndCheckYourEntriesSent({
          errorCode: response.code
        });
      }
    return launch401kIdentityFail({
      showErrorModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorCode: response.code,
      errorTitle: '',
      ssn: ''
    });
  }

  if (response.viewId.indexOf(constants.ERROR) == -1) {
    ReactGA.sendevent(gaCategory, 'VERIFY_401K', 'SUCCESS');
    return launch401kIdentitySuccess({
      viewId: response.viewId
    });
  }
  if (response.viewId == constants.ERROR && response.title != null && response.title != undefined) {
    return launch401kIdentityFail({
      showErrorModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorTitle: '',
    });
  }
  return launch401kIdentityFail({
    showErrorModal: false,
    errorTitle: response.message
  });
}

const handlelaunch401kFailResponse = (getState,errorMessage) => {
  console.log("handlelaunch401kFailResponse" + errorMessage);
  return launch401kIdentityFail({
    showErrorModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    ssn: '',
    errorTitle: '',
  });
}


{/**************************************************multiOrgs page-Org selection****************************************************/ }

const setOrgSelected = (ajax, payload, getState) => {
  var selectedOrg = getState.verify401k.multiOrgs[payload];
  var selectedOrgIndex = getState.verify401k.multiOrgIndex[payload];
  return setOrgSelectedApi(ajax, selectedOrgIndex, getState)
    .pipe(map(response => handleResponseOrgSelected(response.response, selectedOrg, getState)),
    catchError(err => [handleResponseOrgSelectedFailure(getErrorMessage(err),getState)]));
}

const setOrgSelectedApi = (ajax, index, getState) => {
  console.log("selected org Index", index);
  return ajax({
    url: constants.confirm401kDetailsEndPoint,
    method: 'POST',
    responseType: 'json',
    body: index,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
}

const handleResponseOrgSelected = (response,selectedOrg,  getState) => {
  console.log("handleResponseOrgSelected", response);
 
  const identifyYourself = getState.verify401k;
  const gaCategory = identifyYourself.gaCategory;
  if (response.code != null && response.code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(gaCategory, 'VERIFY_401K', response.code);
    console.log('TRUE;response.code=' + response.code);
    if(response.code == constants.ALREADY_REGISTERED) {
        return cancelAndCheckYourEntriesSent({
          errorCode: response.code
        });
      }
    return setOrgSelectedFail({
      showErrorModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorCode: response.code,
      errorTitle: '',
      ssn: ''
    });
  }
  if (response.viewId.indexOf(constants.ERROR) == -1 || response.code == constants.SUCCESS) {
    PendoUtil.pendoIdentify(response.orgCd,selectedOrg,getState.registration.transactionId)
    PendoUtil.pendoVisitorIdUpdate(response.associateId)
    return setOrgSelectedSuccess({
      launch401kIdentityCall: true
    });
  }
  if (response.viewId == constants.ERROR && response.title != null && response.title != undefined) {
    return setOrgSelectedFail({
      showErrorModal: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorTitle: '',
    });
  }
  return setOrgSelectedFail({
    showErrorModal: false,
    errorTitle: response.message
  });
}

const handleResponseOrgSelectedFailure = (response, getState) => {
  return setOrgSelectedFail({
    showErrorModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    errorTitle: '',
  });
}
{/**************************************************On cancel redirection****************************************************/ }
const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const code = getState.verify401k.errorCode;
  const organizationId = getState.registration.organizationId;
  if (code != null && code === constants.ALREADY_REGISTERED) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  }
  if (isMobile && code != constants.ALREADY_REGISTERED) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const handleAlreadyRegisteredResponse = (response) => {
  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});


{/**************************************************Epics declaration****************************************************/ }
export const getSivOptionsEpic = (action$, state) => action$.pipe(
  ofType(GET_401k_SIV_OPTIONS),
  switchMap(action => sendingAsyncRequest(getSivOptions(state.value))));

export const setSivFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_401k_SIV_FIELD),
  switchMap(action => sendingAsyncRequest(setSivField(action.payload, state.value))));

export const verifySivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_401k_SIV_OPTIONS),
  switchMap(action => sendingAsyncRequest(submitSivOptions(ajax, state.value))));

export const getSivOptionsFailureEpic = action$ => action$.pipe(
  ofType(GET_401k_SIV_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))));

export const verifySivOptionsFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_401k_SIV_OPTIONS_FAIL),
  switchMap(action => of()));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_AND_CHECK_401k_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const setOrgSelectedEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(SET_401k_ORG_SELECTED),
  switchMap(action => sendingAsyncRequest(setOrgSelected(ajax, action.payload, state.value))));

export const launch401kIdentityEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_401k_ORG_RESPONSE),
  switchMap(action => sendingAsyncRequest(launch401kIdentity(ajax, state.value))));

export const setOrgSelectedFailureEpic = (action$, state) => action$.pipe(
  ofType(SET_401k_ORG_SELECTED_FAIL), switchMap(action => of()));

export const launch401kIdentitySuccessEpic = (action$, state) => action$.pipe(
  ofType(LAUNCH_401k_IDENTITY_SUCCESS),
  switchMap(action$ => of(push(state.value.verify401k.viewId))));

export const launch401kIdentityFailEpic = (action$, state) => action$.pipe(
  ofType(LAUNCH_401k_IDENTITY_FAIL), switchMap(action => of()));

export const launch401kIdentityFromMultiOrgEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(SET_401k_ORG_SELECTED_SUCCESS),
  switchMap(action => sendingAsyncRequest(launch401kIdentity(ajax, state.value))));