import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_IDENTITY_OPTIONS',
  'VERIFY_IDENTITY_OPTIONS_SUCCESS',
  'VERIFY_IDENTITY_OPTIONS_FAIL',
  'ENABLE_ADMIN_FLOW_SUBMIT_BUTTON',
  'CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES',
  'FETCH_DETAILS',
  'SET_IDENTITY_FIELD',
  'SET_IDENTITY_FIELD_SUCCESS',
  'SET_IDENTITY_FIELD_FAIL',
  'VERIFICATION_CODE_SENT',
  'VERIFICATION_CODE_SENT_SUCCESS',
  'VERIFY_ADMIN_OPTIONS_SUCCESS',
  'VERIFY_ADMIN_RESEND_OTP',
  'SET_AGREE_TERMS_CONDITION',
  'SET_MODAL_FIELD'
);

const {
  VERIFY_IDENTITY_OPTIONS,
  ENABLE_ADMIN_FLOW_SUBMIT_BUTTON,
  CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES,
  FETCH_DETAILS,
  SET_IDENTITY_FIELD,
  VERIFICATION_CODE_SENT,
  VERIFY_ADMIN_OPTIONS_SUCCESS,
  VERIFY_ADMIN_RESEND_OTP,
  SET_AGREE_TERMS_CONDITION,
  SET_MODAL_FIELD
} = types;


const verifyAdminInfoSent = createAction(VERIFY_IDENTITY_OPTIONS);

const enableSubmitButtonSent = createAction(ENABLE_ADMIN_FLOW_SUBMIT_BUTTON);

const cancelAndCheckYourEntriesSent = createAction(CANCEL_ADMIN_FLOW_AND_CHECK_YOUR_ENTRIES);

const fetchAdminDetailsSent = createAction(FETCH_DETAILS);

const setIdentityFieldSent = createAction(SET_IDENTITY_FIELD);

const setSubmitAccessCodeSent = createAction(VERIFICATION_CODE_SENT);

const getIdentityOptionsSucessSent = createAction(VERIFY_ADMIN_OPTIONS_SUCCESS);

const resendOtpSent = createAction(VERIFY_ADMIN_RESEND_OTP);

const setAgreeTermsConditionSent = createAction(SET_AGREE_TERMS_CONDITION);

const setCancelModalSent = createAction(SET_MODAL_FIELD);

const verifyIdentityOptions = () => verifyAdminInfoSent();

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();

const fetchAdminDetails = () => fetchAdminDetailsSent();

const setIdentityField = (fieldkey, fieldValue) => setIdentityFieldSent({ fieldkey, fieldValue });

const submitAccessCode = () => setSubmitAccessCodeSent();

const verifyAdminOptionsSucess = () => getIdentityOptionsSucessSent();

const setAgreeTermsCondition = (agreeTnC) => setAgreeTermsConditionSent({ agreeTnC });

const setCancelTnCModal = (showTnCModal) => setCancelModalSent({ showTnCModal });

const resendOTP  = () => resendOtpSent();

export const actions = {
  verifyIdentityOptions,
  enableSubmitButton,
  cancelAndCheckYourEntries,
  fetchAdminDetails,
  setIdentityField,
  submitAccessCode,
  verifyAdminOptionsSucess,
  resendOTP,
  setAgreeTermsCondition,
  setCancelTnCModal
};
