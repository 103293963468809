import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  Tooltip
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-bev/verify-bev-constants';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import Draggable from 'react-draggable';
import WindowClose from 'adp-react-icons/lib/fa/window-close';

const isBlank = str => (str || '').trim().length === 0;
let overlayPlacement = "right";


export default class VerifyBevW2Form extends Component {

  componentWillMount() {
    this.props.getW2DropdownValues("w2ServiceMBean");
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
  }

  render() {
    const {
      verifyBevObj,
      hideDraggableSet,
      showDraggableModal,
      showDraggable,
      onDropDownSelect,
      handleUpdateData,
      gaCategory

    } = this.props;

    const gaToolTip = (label) => ReactGA.sendevent(gaCategory, label, 'TOOLTIP');

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
        />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
          >
          <div role="alert">{msg}</div>
        </Popover>
      ));


    const {
      w2ServiceMBean
    } = this.props.verifyBevObj.initData;

    const handleDataChange = (parentName, fieldName, fieldValue, regEx, index) => {
      let payload = {};
      payload.type = 2;
      payload.parentName = parentName;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.regEx = regEx;
      payload.index = index;
      WindowUtil.setFirstAttemptGA({ gaCategory: this.props.gaCategory + "_" + "W2", fieldName: fieldName });
      return handleUpdateData(payload);
    }
    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );
    const handleDraggable = (value) => {
      let showDraggable = value;
      showDraggableModal(showDraggable);
    }
    return (
      <div>
        <div className="form-group form-align">
          <div className="vdl-row">
            <div id="w2_sampleDocBtn" className="vdl-col-sm-12 vdl-col-xs-12">
              <span role="button" className="vb-sample-doc focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'W2_SAMPLE_DOC', 'MODAL');
                      return handleDraggable(true);
                    }
                  }} onClick={() => {
                ReactGA.sendevent(gaCategory, 'W2_SAMPLE_DOC', 'MODAL');
                return handleDraggable(true)
              } }>{w2ServiceMBean.viewSampleDocs.label}</span>
            </div>
          </div>
        </div>
        {/* Draggable component start */}
        {showDraggable && (
          <div className="vb-draggable-header">
            <Draggable defaultPosition={{ x: 50, y: -500 }} id="draggable">
              <div className="vb-draggable-body" >
                <div style={{ textAlign: 'right' }}>
                  <WindowClose className="focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return handleDraggable(false);
                    }
                  }} onClick={() => handleDraggable(false)} className="vb-draggable-close" />
                </div>
                <div className="vb-touch-scroll vb-form-draggable-div">
                  {w2ServiceMBean.viewSampleDocs.options.map((value, index) => (
                    <div key={index}>
                      <div className="vb-form-draggable-label">{value.label}</div>
                      <img id="cs-andriod-badget" width="300px" height="400px"
                        src={value.value} draggable="false"
                        />
                    </div>
                  ))}
                </div>
              </div>
            </Draggable>
          </div>)}
        {/* Draggable component end */}


        {/* Year component start */}
      <div id={w2ServiceMBean.w2Year.label}>
        <div role="group" aria-labelledby={w2ServiceMBean.w2Year.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-8 vdl-col-xs-15">
              <label id="fulldob" htmlFor="fulldob_Label">{w2ServiceMBean.w2Year.label}
                <span className="vb-form-dob-req-icon">*</span></label>
            </div>
          </div>
          <div style={{ display: 'inline-flex' }}>
            <div className="full-dob-column-width">
              <DropdownList aria-label="monthDropdown" 
                id="w2_month"
                data={verifyBevObj.w2YearList}
                placeholder={w2ServiceMBean.w2Year.placeHolder}
                onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return handleDataChange("w2ServiceMBean", "w2Year", value, null)}}
                value={w2ServiceMBean.w2Year.value}
                />
            </div>
          </div>
        </div>
      </div>
        {/* Year component end */}

        {/* employee number component start */}
      <div id={w2ServiceMBean.employeeId.label}>
        <div role="group" aria-labelledby={w2ServiceMBean.employeeId.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="employeeLabel">{w2ServiceMBean.employeeId.label}
              {w2ServiceMBean.employeeId.required && (<span className="vb-form-req-icon">*</span>)}

              {!isBlank(w2ServiceMBean.employeeId.toolTip) && (<span role="button" aria-label="tooltip" id="w2_empid_ques" className="vb-question-circle" onClick={() => gaToolTip('W2_EMPLOYEE_ID')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(w2ServiceMBean.employeeId.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)} </label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[0], w2ServiceMBean.employeeId.message)}
              >
              <Textbox
                id="w2_empid_textBox"
                maxLength={w2ServiceMBean.employeeId.maxLength}
                placeholder={w2ServiceMBean.employeeId.placeHolder}
                value={w2ServiceMBean.employeeId.value}
                className={!verifyBevObj.errorFieldsBackend[0] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("w2ServiceMBean", "employeeId", value, w2ServiceMBean.employeeId.regEx, 0)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* employee number component end */}



        {/* companyCode component start */}
      <div id={w2ServiceMBean.companyCode.label}>
        <div role="group" aria-labelledby={w2ServiceMBean.companyCode.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="ccLabel">{w2ServiceMBean.companyCode.label}
              {w2ServiceMBean.companyCode.required && (<span className="vb-form-req-icon">*</span>)}

              {!isBlank(w2ServiceMBean.companyCode.toolTip) && (<span role="button" aria-label="companyCodeTip" id="w2CC_ques" className="vb-question-circle" onClick={() => gaToolTip('W2_COMPANY_CODE')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(w2ServiceMBean.companyCode.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)} </label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[1], w2ServiceMBean.companyCode.message)}
              >
              <Textbox aria-label={w2ServiceMBean.companyCode.label}
                id="w2CC_textBox"
                maxLength={w2ServiceMBean.companyCode.maxLength}
                placeholder={w2ServiceMBean.companyCode.placeHolder}
                value={w2ServiceMBean.companyCode.value}
                className={!verifyBevObj.errorFieldsBackend[1] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("w2ServiceMBean", "companyCode", value, w2ServiceMBean.companyCode.regEx, 1)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* companyCode component end */}




        {/* zipCode component start */}
      <div id={w2ServiceMBean.zipCode.label}>
        <div role="group" aria-labelledby={w2ServiceMBean.zipCode.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="zipCodeLabel">{w2ServiceMBean.zipCode.label}
               {w2ServiceMBean.zipCode.required && (<span className="vb-form-req-icon">*</span>)} 

              {!isBlank(w2ServiceMBean.zipCode.toolTip) && (<span role="button" aria-label="tooltip" id="w2ZC_ques" className="vb-question-circle" onClick={() => gaToolTip('W2_ZIP_CODE')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(w2ServiceMBean.zipCode.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[2], w2ServiceMBean.zipCode.message)}
              >
              <Textbox aria-label={w2ServiceMBean.zipCode.label}
                id="w2ZC_textBox"
                maxLength={w2ServiceMBean.zipCode.maxLength}
                placeholder={w2ServiceMBean.zipCode.placeHolder}
                value={w2ServiceMBean.zipCode.value}
                className={!verifyBevObj.errorFieldsBackend[2] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("w2ServiceMBean", "zipCode", value, w2ServiceMBean.zipCode.regEx, 2)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* zipCode component end */}


        {/* ssn component start */}
      <div id={w2ServiceMBean.ssn.label}>
        <div role="group" aria-labelledby={w2ServiceMBean.ssn.label} className="form-group form-align">
          <div className="vdl-row">
            <div className="vdl-col-sm-12 vdl-col-xs-12">
              <label id="ssnLabel">{w2ServiceMBean.ssn.label}
              {w2ServiceMBean.ssn.required && (<span className="vb-form-req-icon">*</span>)} 

              {!isBlank(w2ServiceMBean.ssn.toolTip) && (<span role="button" aria-label="tooltip" id="w2ssn_ques" className="vb-question-circle" onClick={() => gaToolTip('W2_SSN')}>
                <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                  overlay={tooltip(w2ServiceMBean.ssn.toolTip)} >
                  <InfoCircle tabIndex="0" className="focus-selector" style={{marginBottom:"2px"}} />
                </OverlayTrigger>
              </span>)}</label>
            </div>
          </div>
          <div className="input-group-full">
            <OverlayTrigger isAriaExpanded={ false }
              trigger={['click']} placement="bottom"
              overlay={errorPopover(!verifyBevObj.errorFields[3], w2ServiceMBean.ssn.message)}
              >
              <Textbox aria-label={w2ServiceMBean.ssn.label}
                id="w2ssn_textBox"
                maxLength={w2ServiceMBean.ssn.maxLength}
                type="password"
                placeholder={w2ServiceMBean.ssn.placeHolder}
                value={w2ServiceMBean.ssn.value}
                className={!verifyBevObj.errorFieldsBackend[3] ? undefined : 'vdl-validation-error'}
                onChange={(value) => handleDataChange("w2ServiceMBean", "ssn", value, w2ServiceMBean.ssn.regEx, 3)}
                />
            </OverlayTrigger>
          </div>
          <div />
        </div>
      </div>
        {/* ssn component end */}



      </div>)


  }
}
