import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Button,
} from '@synerg/vdl-react-components';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { WindowUtil } from '../../util';
import { constants } from '../../redux/ducks/timeout/timeout-constants';
import { ReactGA } from '../../util';

let revClientTimestamp = new Date();
let initialLoad = false;

class Timeout extends Component {
  static propTypes = {
    timeoutWarn: PropTypes.bool,
    loadSessionTimeoutConfig: PropTypes.func,
    checkSessionTimeout: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    if (initialLoad === false) {
      this.props.loadSessionTimeoutConfig();
    }
  };

  componentDidMount() {
    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    const appId = document.getElementById('app');

    for (const i in this.events) {
      appId.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  componentWillReceiveProps = (nextProp) => {
    if (nextProp.reloadTimeout) {
      this.resetTimeout();
    }

    if (initialLoad == false) {
      if (nextProp.warningTime != null || nextProp.signoutTime != null) {
        this.warningTime = nextProp.warningTime;
        this.signoutTime = nextProp.signoutTime;
        this.setTimeout();
        initialLoad = true;
      }
    }
    if (nextProp.timeout) {
      this.props.redirectToLoginPage();
      //WindowUtil.redirectTo(constants.timeoutURI);
    }
  };

  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    const warningTime = this.warningTime || constants.warningTime;
    const signoutTime = this.signoutTime || constants.signoutTime;
    
    if(this.warnTimeout || this.logoutTimeout){
    this.clearTimeoutFunc();
    }
    this.warnTimeout = setTimeout(this.warn, warningTime);
    this.logoutTimeout = setTimeout(this.logout, signoutTime);
  };

  resetTimeout = () => {
    revClientTimestamp = new Date();
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    const payload = {};

    payload.revClientTimestamp = revClientTimestamp;
    this.props.checkTimeOut(payload);
    // this.props.setTimeoutWarn(true);
  };

  logout = () => {
    // Send a logout request to the API
    //WindowUtil.redirectTo(constants.timeoutURI);
    this.props.redirectToLoginPage();
  };

  resetSessionTimeout = () => {
    ReactGA.sendevent('REGISTRATION_SESSION', 'SESSION_TIMEOUT', 'RETURN');
    const payload = {};

    payload.returnClick = true;
    this.props.checkTimeOut(payload);
    // this.props.setTimeoutWarn(false);
  };

  abandonCurrentSession = () => {
    ReactGA.sendevent('REGISTRATION_SESSION', 'SESSION_TIMEOUT', 'LEAVE');
    this.props.redirectToLoginPage();
    //WindowUtil.redirectTo(constants.timeoutURI);
  };

  render() {
    const { timeoutWarn, setTimeoutWarn } = this.props;

    return (
      <Modal
        className="vdl-modal--alert"
        show={timeoutWarn}
        >
        <ModalHeader
          closeButton={false}
          className="modal-header"
          >
          <ModalTitle className="modal-title-info">
            <WarnIcon className="vdl-modal__title-icon warn-icon-info" />
            {this.props.intl.formatMessage({ id: 'sessionTimeout.title' })}
          </ModalTitle>
        </ModalHeader>
        <ModalBody className="modal-body-footercolor">
          <div className="modal-text">
            {this.props.intl.formatMessage({ id: 'sessionTimeout.message1' })}
            <ul>
              <li>{this.props.intl.formatMessage({ id: 'sessionTimeout.message2' })}</li>
              <li>
                {this.props.intl.formatMessage({ id: 'sessionTimeout.message3' })}
              </li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter className="modal-body-footercolor">
          <Button onClick={this.resetSessionTimeout} className="safari-button-align">
            {this.props.intl.formatMessage({ id: 'sessionTimeout.returnMessage' })}
          </Button>
          <Button onClick={this.abandonCurrentSession} className="safari-button-align">
            {this.props.intl.formatMessage({ id: 'sessionTimeout.leaveMessage' })}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default injectIntl(Timeout);
