export const constants = {
    getVerifyPrcInitialDataAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/initData',
    getNudgeInitialDataAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/nudgeInitData',
    getVerifyPrcGenerateCodeAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/generateCode',
    getVerifyPrcSubmitCodeAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/verifyCode',
    getPhoneOnFlyAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/verifyPhoneOnFly',
    ALREADY_REGISTERED: 'ALREADY_REGISTERED',
    TYPE_CELL_PHONE: 'CELL_PHONE',
    TYPE_PHONE_ON_FLY: 'PHONE_ON_FLY',
    TYPE_POSTAL_CODE: 'POSTAL_MAIL',
    TYPE_EMAIL: 'EMAIL',
    mykPlan_viewId:'/verify401k',
    TYPE_SUCCESS: 'SUCCESS',
    OTP_HEADING: 'otppageheading',
    POF_OTP_HEADING:'phoneonflyotpheading',
    OTP_HEADING_ALREADY_PRC_EXIST: 'prcalreadyexistheading',
    VERIFYPRC_TITLE:'verifyprctitle',
    PHONEONTHEFLY_TITLE:'phoneonethyflytitle',
    NON_UNIQUE_PHONE: 'NON_UNIQUE_MOBILE',
    INVALID_PHONE:'INVALID_MOBILE',
    MAX_ATTEMPTS_EXCEEDED: 'MAX_ATTEMPTS_EXCEEDED',
    PHONEONFLY_SUCCESS:'SUCCESS',
    PHONEONTHEFLYFAIL_TITLE:'phoneontheflyfailtitle',
    PHONE_REGEX: '[0-9\\s-.()]{4,20}$',
    TYPE_ERROR_FAIL_ATTEMPTS: "Error_maxFailAttemptsExceeded",
    TYPE_ERROR_LOCKED: "Error_FunctionLocked",
    TYPE_ERROR_INVALID: "Error_SecCodeInvalid",
    TYPE_ERROR_EXPIRED: "Error_SecurityCodeExpired",
    TYPE_ERROR_INTERAL_ERROR: "Error_InternalError",
    TYPE_ERROR_FAILED: "Error_SendSecurityCodeFailed",
    PATHPRC: '/ssr/CPC/PRC',
    PATHVERIFYPRCCODE: '/ssr/CPC/verifyAccessCode',
};