import { handleActions } from 'redux-actions';
import { types } from './verify-user-id-actions';

const { VERIFY_USER_ID_SUCCESS, VERIFY_USER_ID_FAIL, SET_USER_ID , ENABLE_USERID_SUBMIT_BUTTON , CHECK_ERROR_MESSAGE, GET_UID_OPTIONS_SUCCESS,
  CANCEL_REGISTRATION_VERIFY_USERID } = types;

export const initialState = {
  userIdValid: true
};

export default handleActions(
  {
    [SET_USER_ID]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_USER_ID_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_USER_ID_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [ENABLE_USERID_SUBMIT_BUTTON]: (state, { payload }) => ({ ...state, ...payload }),

    [CHECK_ERROR_MESSAGE]: (state, { payload }) => ({ ...state, ...payload }),

    [GET_UID_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [CANCEL_REGISTRATION_VERIFY_USERID]: (state, { payload }) => ({ ...state, ...payload }),

  },
  initialState,
);
