import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocaleObjFromPropKey } from '../../util/locale';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Radio,
  Alert,
  DropdownButton, Checkbox
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { constants } from '../../redux/ducks/verify-admin/verify-admin-constants';
import { ReactGA, WindowUtil } from '../../util';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import HandIcon from 'adp-react-icons/lib/fa/hand-o-right';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';
import CountryCodeDropdown from '../../components/country-code';
import ExternalLinkIcon from 'adp-react-icons/lib/fa/external-link';


const isBlank = str => (str || '').trim().length === 0;
let showAlertFlag = false;
let isFirstTime = false;
let initialLoad = false;
let setAutoFocusOff;

class VerifyAdminForm extends Component {
  constructor() {
    super();
    this.state = { disableResendBtn: false }
  }
  static propTypes = {
    errorTitle: PropTypes.string,
    errorMessageFN: PropTypes.string,
    fetchAdminDetails: PropTypes.func,
    setIdentityField: PropTypes.func,
    mobilePhoneObj: PropTypes.array,
    verifyIdentityOptions: PropTypes.func
  };

  componentWillMount() {
    WindowUtil.setlayoutback(false);
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
    this.props.fetchAdminDetails();
  }

  componentWillReceiveProps = (nextProps) => {
    this.checkFormReadyness(nextProps);
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (nextProps.showVerifyCode === false &&
      (isBlank(nextProps.mobilePhone) || !nextProps.mobilePhoneValid)
    ) {
      formValid = false;
    }
    if (nextProps.showVerifyCode === false &&
      (isBlank(nextProps.ssn4) || !nextProps.ssn4Valid)
    ) {
      formValid = false;
    }
    if (nextProps.showVerifyCode === true &&
      (isBlank(nextProps.verificationCode) || !nextProps.codeValid)
    ) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  };

  _handleKeyPress = (e, input, formValid) => {
    if (e.key === 'Enter') {
      const allowEnterKeyFields = ["PhoneValue", "ssn4", "verificationCode"];
      e.preventDefault();
      ReactGA.sendevent(this.props.gaCategory, 'VERIFY_ADMIN', 'SUBMIT');
      if (formValid && e.target !== undefined && e.target.id !== undefined && allowEnterKeyFields.includes(e.target.id) && input === 'validatePhone') {
        this.props.verifyIdentityOptions();
      }
      if (formValid && e.target !== undefined && e.target.id !== undefined && allowEnterKeyFields.includes(e.target.id) && input === 'validateCode') {
        this.props.submitAccessCode();
      }
    }
  }


  render() {
    console.log('rendering verify admin form...');
    const {
      firstName,
      lastName,
      mobilePhone,
      mobilePhoneValid,
      countryCode,
      ssn4,
      errorMessageSsn4,
      ssn4Valid,
      verifyAdminInfo,
      showErrorModal,
      showModalTitle,
      showModalMessage,
      errorMessage,
      isFormValid,
      cancelAndCheckYourEntries,
      errorCode,
      errorTitle,
      isMobileBrowser,
      isMobile,
      gaCategory,
      progressBarData,
      runAdminV2Flow,
      mobilePhoneObj,
      setIdentityField,
      sivOptions,
      showVerifyCode,
      verifyIdentityOptions,
      expiry,
      maskedNumber,
      showAlert,
      showAlertMessage,
      verificationCode,
      codeValid,
      showOnScreen,
      accessCode,
      agreeTnC,
      submitAccessCode,
      resendOTP,
      setAgreeTermsCondition,
      showTnCModal,
      setCancelTnCModal,
      footer,
      locale
    } = this.props;

    const errorPopover = (fieldValid, errorMessage) =>
      (fieldValid && <Popover
        id="dummyPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error align-messages'}
        >
          <div role="alert">{errorMessage}</div>
        </Popover>
      ));

    const tooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );

    const onResendBtnClick = (e) => {
      this.setState({ disableResendBtn: true });
      ReactGA.sendevent(gaCategory, 'RESEND_OTP', 'CLICK');
      e.preventDefault();
      resendOTP();
    }

    const onSetMobilePhone = mPhone => setIdentityField(constants.MOBILE, mPhone);

    const onSetOtcValue = (event) => {
      const inputValue = event.target.value.replace(/\D/g, '');
      return setIdentityField(constants.OTP_CODE, inputValue);
    }

    const onCancelTnCModal = () => { let showTnCModal = false; return setCancelTnCModal(showTnCModal); }

    const onOpenTnCModal = () => { ReactGA.sendevent(gaCategory, 'T_AND_C', 'MODAL'); let showTnCModal = true; return setCancelTnCModal(showTnCModal); }

    const onSelectAgreeTnC = () => { let agreeTnC = true; onCancelTnCModal(); return setAgreeTermsCondition(agreeTnC); }

    const onDeSelectAgreeTnC = () => { let agreeTnC = false; return setAgreeTermsCondition(agreeTnC); }

    const handleAgreeTnC = () => {

      if (agreeTnC) {
        onDeSelectAgreeTnC();
      } else {
        onSelectAgreeTnC();
      }
    }

    return (

      <div className="registration-content">
        <div className="registration-form-container" >
          {
            (window.innerWidth > 479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
          }
          {
            (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
          }

          {(!isMobile) && (
            <div className="vp-cancel-icon focus-selector" tabIndex="0" onKeyPress={() => {
              ReactGA.sendevent(gaCategory, 'VERIFY_ADMIN', 'CANCEL');
              return cancelAndCheckYourEntries()
            }}>
              <svg id="admin_cancel" onClick={() => {
                ReactGA.sendevent(gaCategory, 'VERIFY_ADMIN', 'CANCEL');
                return cancelAndCheckYourEntries()
              }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                }}>
                <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
              </svg>
            </div>
          )}
        </div>

        {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
          <div className="registration-progress-bar">
            <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
          </div>
        )}

        {(!isMobile) && <hr className="header-hr" />}
        <div className="vdl-row registration-form">
          <div className="welcome-title title-width">
            <div className="vdl-col-md-12">
              {!showVerifyCode && (<div>  <h1 id="registration-layout_welcome" className="page-title">{<FormattedMessage id="verifyPic.title" />}</h1>
                <div className="heading-text info-align-padding" style={{ textAlign: 'center', fontWeight: "normal" }}>
                  <FormattedMessage id="welcome_saluatation"
                    values={{
                      0: <b>{firstName}</b>,
                      1: <b>{lastName}</b>
                    }}></FormattedMessage>
                </div>
                <div className="heading-text info-align-padding" style={{ textAlign: 'center', fontWeight: "normal" }}>
                  <FormattedMessage id="verify_admin_identity" /></div>

                <div className="heading-text info-align-padding" style={{ textAlign: 'center', fontWeight: "normal" }}>
                  <FormattedMessage id="verify_admin_identity1" /></div> </div>)}
              {showVerifyCode && (
                <div>
                  <h1 id="registration-layout_welcome" className="page-title">{<FormattedMessage id="verifyPhone.title" />}</h1>
                  <div className="heading-text info-align-padding" style={{ textAlign: 'center', fontWeight: "normal" }}>
                    <FormattedMessage id="vf.otpPhnHeading" values={{ 0: <b>{maskedNumber}</b> }} /></div>
                  <div className="heading-text info-align-padding" style={{ textAlign: 'center', fontWeight: "normal" }}>
                    <FormattedMessage id="verifyAdmin.otpSubTitle" values={{ 0: expiry }} /></div>

                </div>
              )}
            </div>
          </div>
        </div>
        {errorTitle != undefined && errorTitle != '' && (<Alert
          type="error" className="vfadmin-alert"
        ><p role="alert" style={{ marginTop: 0, marginBottom: 0 }}>{ReactHtmlParser(errorTitle)}</p></Alert>
        )}
        <div className="vdl-col-md-12" style={{paddingBottom : '16px'}}>
          <form className="adp-form" onKeyPress={(e) => this._handleKeyPress(e, 'validatePhone', isFormValid)}>
            {!showVerifyCode && (<div> <div className="pof-padding" style={{ paddingTop: '10px' }}>
              {(<span
                id="mobileLabel"
                className="label-fields vfadmin-phone-label">
                <FormattedMessage id="verifyAdmin.phone" />
                <span className="iy-form-required-icon">*</span>
              </span>)}
              <div className="vfadmin-countrycode-div">
                <CountryCodeDropdown title={this.props.intl.formatMessage({ id: 'verifyAdmin.phone' })}
                  id="PhoneValue"
                  flagId="PhoneCountryCd"
                  onChange={onSetMobilePhone}
                  value={mobilePhoneObj}
                  validationRule={constants.PHONE_REGEX}
                  gaName={gaCategory}
                  errorMessage={this.props.intl.formatMessage({ id: 'mobileInvalidMessage' })}
                  defaultCountry="us"
                  fieldValid={mobilePhoneValid}
                  fieldType="mobile"
                  indexField="0"
                  onlyCountries={["us"]}
                  phoneOnFlyDisable = {true}
                />
              </div>
            </div>

              {(
                <div className="vfadmin-ssn-label vfadmin-align">
                  <div><span className="fm-siv-ssn4-label-align"><FormattedMessage id="verifyAdmin.ssn4Label" /></span>
                    <span className="iy-form-required-icon">*</span>
                    <span id="idenCPCOr_ssn4_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'SSN', 'TOOLTIP')}>
                      <OverlayTrigger placement="bottom" trigger={['focus', 'click']} isAriaExpanded={ false }
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                        <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{ marginBottom: "2px" }} /></span>
                      </OverlayTrigger>
                    </span>
                    <OverlayTrigger isAriaExpanded={ false }
                      trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['focus', 'click']} placement="bottom"
                      overlay={errorPopover(ssn4Valid,
                        errorMessageSsn4)}
                    >
                      <Textbox aria-label={this.props.intl.formatMessage({ id: 'verifyAdmin.ssn4Label' })}
                        id="ssn4" style={{fontSize:'14px'}}
                        type="password"
                        className={ssn4Valid ? 'vfadmin-ssn-tbox' :
                          'vdl-validation-error vfadmin-ssn-tbox'}
                        value={ssn4}
                        onChange={(value) => {
                          ReactGA.sendevent(gaCategory, 'CLICK', 'SSN4');
                          return setIdentityField(constants.SIV_SSN4, value)
                        }}
                      />
                    </OverlayTrigger> <span className="lock-icon-align"><LockIcon /></span>
                  </div>
                </div>
              )}

          
              <div
                id="sivoptions-form_submit-button"
                className="form-group submit vdl-text-xs-center submit-button-align vfadmin-align" style={{ paddingTop: "7px", marginBottom: '40px' }}
              >
                <Button role="button"
                  style={{ minHeight: "35px", minWidth: "180px" }}
                  id="pic_continueBtn"
                  disabled={!isFormValid} onClick={() => { showAlertFlag = true; return this.props.verifyIdentityOptions(); }}
                  onKeyPress={(e) => { if (e.key === 'Enter') { showAlertFlag = true; return this.props.verifyIdentityOptions(); } }}
                  className="primary submit-button-align safari-button-align"
                >
                  {<FormattedMessage id="verifyPhoneSubmit.Label" />}
                </Button></div>

              </div>)}</form>

          <form className="adp-form" onKeyPress={(e) => this._handleKeyPress(e, 'validateCode', isFormValid)}>
            {showVerifyCode && (
              <div>

                <div className="vf-emailphone-width-otp vf-otp-div" style={{ paddingTop: '15px' }}>

                  {(!showAlert) &&
                    (<span className="label-fields label-otp-align" style={{ fontWeight: '500' }}>{<FormattedMessage id="findMe.otpCode" />}</span>)}
                  {(showAlert) && (<div className="vf-prc-otc-alert" role="alert">
                    {ReactHtmlParser(showAlertMessage)}
                  </div>
                  )}
                  <input autoFocus = {false}
                    id="verificationCode" aria-label={this.props.intl.formatMessage({ id: 'findMe.otpCode' })}
                    type="tel"
                    maxLength={6}
                    className={codeValid ? 'vf-otc-txt otp-textbox' : 'otp-textbox vf-otc-txt vdl-validation-error'}
                    value={verificationCode}
                    onChange={
                      (event) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "OTP" });
                        return onSetOtcValue(event);
                      }
                    }

                  />
                </div>
                {showOnScreen && (<div id="verify-prc-access-code" className="vf-access-code">{accessCode}</div>)}

                <div className="form-group form-align">
                  <table className="uid-table-align" style={{ tableLayout: 'fixed' }}>

                    <tbody>

                      <tr>
                        <td width="20%"
                          style={{ wordBreak: 'break-word', overflow: 'hidden', textAlign: 'end',paddingTop:'10px' }}
                        >
                          <Checkbox id="consent" aria-label="TermsAndConditionsCheckbox" checked={agreeTnC} onChange={handleAgreeTnC} style={{ display: 'inline' }} onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              return;
                            }
                          }} />
                        </td>
                        <td style={{ overflow: 'hidden' }} width="80%">
                          <span style={{ fontWeight: '600' }}><FormattedMessage id="VerifyPhone_TermsAgreementLabel" /></span>
                        </td>
                      </tr>
                      <tr>
                        <td width="20%"></td>
                        <td width="80%"
                          style={{ wordBreak: 'break-word', paddingRight: '50px', overflow: 'hidden', paddingBottom: '5px' }}
                        >
                          <span style={{fontSize:'12px'}}><FormattedMessage id="VerifyPhone_TermsAgreementInfo" /></span>
                          <a
                            onClick={() => {
                              ReactGA.sendevent(gaCategory, "_TC", 'POPUP');
                              return onOpenTnCModal();
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                ReactGA.sendevent(gaCategory, "_TC", 'POPUP');
                                return onOpenTnCModal();
                              }
                            }}
                            id="tc_link"
                            aria-label="TermsAndConditions"
                            className="focus-selector"
                            target="_blank"
                            role="link"
                            tabIndex="0"
                          >
                            <ExternalLinkIcon style={{ marginLeft: "1%" }} />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="vf-prc-otc-submit-div">  <Button id="admin_otp_continueBtn" disabled={isBlank(verificationCode)}
                  style={{ minHeight: "35px", minWidth: "180px" }} role="button"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                      return submitAccessCode();
                    }
                  }}
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'VERIFY_PRC', 'SUBMIT_CODE');
                    return submitAccessCode();
                  }}
                  className="vf-otc-submit safari-button-align">
                  {<FormattedMessage id="submitCode.Label" />}
                </Button></div>
                
              </div>
            )} </form>

          {showVerifyCode && (<div className="otp-footer-div">
            <div className=" fm-otc-footer">
              <hr style={{ borderTop: '1px solid rgb(190, 190, 190)', marginBottom: '10px' }} />
              <div>
                <span
                  className="heading-text vf-code-subpanel1 fm-otp-footer-span"
                  id="subTitle1"
                >
                  {<FormattedMessage id="findMe.otpFooterTitle" />}
                </span>
                <Button role="button"
                  style={{ fontSize: "14px" }}
                  className="vf-code-subpanel2"
                  id="resendCode"
                  disabled={this.state.disableResendBtn}
                  className="fm-otc-footer-font opt-resend"
                  buttonStyle="link"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onResendBtnClick(e);
                    }
                  }}
                  onClick={onResendBtnClick}

                > {<FormattedMessage id="verifyPhone_resendCode" />}
                </Button>
              </div>
            </div>
          </div>)}

          <Modal
            className="vdl-modal--alert"
            show={showErrorModal}
            onEnter={() => { WindowUtil.applyBrandingChanges(); }}
          >
            <ModalHeader closeButton={false} className="modal-header">
              <ModalTitle className={errorCode === constants.ALREADY_REGISTERED ? 'modal-title-info' : 'modal-title-error'}>
                <WarnIcon className={errorCode === constants.ALREADY_REGISTERED ? 'vdl-modal__title-icon warn-icon-info' : 'vdl-modal__title-icon warn-icon-error'}
                />
                {showModalTitle ? showModalTitle : this.props.intl.formatMessage({ id: 'Txt_VGeneralErrorWindowTitle' })}
              </ModalTitle>
            </ModalHeader>
            <ModalBody className="modal-body-footercolor">
              <div>
                <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
              </div>
            </ModalBody>
            <ModalFooter className="modal-body-footercolor">
              <Button id="pic_okBtn" onClick={cancelAndCheckYourEntries} className="safari-button-align">
                {<FormattedMessage id="Lbl_OkButton" />}
              </Button>
            </ModalFooter>
          </Modal>

          {/*************************************Terms and Conditions MODAL*******************************************/}
          <Modal id="TermsNConditionsModal"
            className="vdl-modal--alert"
            show={showTnCModal}
            onEnter={() => { WindowUtil.applyBrandingChanges(); }}
          >
            <ModalHeader closeButton={true} onHide={onCancelTnCModal} className="modal-header tnc-modal-header">

            </ModalHeader>
            <ModalBody className="tnc-modal-body-footercolor">
              <div>
                <iframe className="cf-iframe-touch" src={getLocaleObjFromPropKey(footer.terms.textMessageLegalPage, locale)} width="100%" height="400px" frameBorder="0" />
              </div>
            </ModalBody>
            <ModalFooter className="tnc-modal-body-footercolor">
              <Button id="cInfo_okBtn3" onClick={onCancelTnCModal} className="safari-button-align">
                {<FormattedMessage id="Lbl_OkButton" />}
              </Button>
            </ModalFooter>
          </Modal>

        </div>
      </div>
    );
  }
}
export default injectIntl(VerifyAdminForm);
