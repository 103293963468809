export const ADP_LANG_LOCALE_COOKIE = 'ADPLangLocaleCookie';
export const ADP_LANG_LOCALE_COOKIE_EXPIRES = 60 * 60 * 24; //  24 hours
export const DEFAULT_LOCALE = 'en_US';

export const LOCALE_MAP = {
  en_US: { id: 1, language: 'English (US)' },
  fr_CA: { id: 2, language: 'Français (FR)' },
};

export default {
  ADP_LANG_LOCALE_COOKIE,
  ADP_LANG_LOCALE_COOKIE_EXPIRES,
  DEFAULT_LOCALE,
  LOCALE_MAP,
};
