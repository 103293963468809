import { handleActions } from 'redux-actions';
import { types } from './locale-actions';

const { CHANGE_LOCALE } = types;

export const initialState = { locale: null };

export default handleActions(
  {
    [CHANGE_LOCALE]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState,
);
