import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { constants } from '../../redux/ducks/confirmation/confirmation-constants';
import { WindowUtil } from '../../util';
import ExternalLinkIcon from 'adp-react-icons/lib/fa/external-link';
import SignInIcon from 'adp-react-icons/lib/fa/sign-in';
import PlusCircle from 'adp-react-icons/lib/fa/plus-circle';
import { ReactGA } from '../../util';
import CheckCircleIcon from 'adp-react-icons/lib/fa/check-circle';
import EnvelopeIcon from 'adp-react-icons/lib/fa/envelope';
import MobileIcon from 'adp-react-icons/lib/fa/mobile';
import CopyIcon from 'adp-react-icons/lib/fa/copy';
import ReactDOM from 'react-dom';
import SecurePage from '../../components/secure-page';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
class ConfirmationForm extends Component {

  componentWillMount() {
    //Need to update flow type CPC/PIC
    this.props.getConfirmationInfo();
    WindowUtil.setlayoutback(false);
  }

  render() {
    const {
      confirmationInfoObj,
      showBusyIndicator,
      getConfirmationInfoUpdate,
      isCopied,
      gaCategory,
      isMobile,
      goToProduct
    } = this.props;

    const popOver = (isCopied) =>
      (
        <Popover
          id="popover"
          placement="top"
          className={'cs-pop-over'}
        >
          {!isCopied && (<div style={{ color: "#086274" }} role="alert" aria-label="tooltip">{<FormattedMessage id="confirmation_copy" />}</div>)}
          {isCopied && (<div style={{ color: "#086274" }} role="alert" aria-label="tooltip">{<FormattedMessage id="confirmation_copied" />}</div>)}
        </Popover>
      );

    const copyToClipboard = (text, isCopied) => {
      var input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute('value', text);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      if (isCopied == undefined || isCopied == false) {
        let isCopied = true;
        getConfirmationInfoUpdate(isCopied);
      }
    }

    return (

      (confirmationInfoObj.showDiv && (
        <div className="registration-content">
          <div className="registration-form-container" >
            {(window.innerWidth > 479) && <div className="cs-secure-page-div"><SecurePage gaCategory={gaCategory} /></div>
            }

            {
              (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
            }
          </div>
          {(!isMobile) && <hr className="cs-secure-page-hr" />
          }
          <div className=" cs-margin-bottom cs-padding-top">

            <div className="registration-page-container">
              <CheckCircleIcon className="cs-check-circle" />
              <div className="welcome-title">
                <h1 className="vdl-col-md-12 cs-title ">
                  <div id="registration-layout_welcome">{<b><FormattedMessage id="confirmation.title" /></b>}</div>
                </h1>
              </div>
              <div className="vdl-row registration-form">
                {showBusyIndicator && (<BusyIndicator
                  style={{ color: "#1a8099" }}
                  size="xl"
                  overlay={true}
                  fullScreen={true}
                />)}
                <div className="vdl-col-md-12">
                  <form className="adp-form padding-align-allflows" id="cs-form">
                    <div className="form-group form-align">
                      <div className="vdl-row">
                        <div className="vdl-col-sm-12 vdl-col-xs-12 cs-text-center">
                          <span id="cs-info3" className="cs-text-lbl"> {<FormattedMessage id="confirmation.info3" />}</span>
                          <OverlayTrigger id="popOver" isAriaExpanded={ false }
                            trigger={["click", "hover","focus"]} placement="top"
                            overlay={popOver(isCopied)}>
                            <span id="userId">
                              <span role="button" id="cs-user-id" tabIndex="0" className="cs-user-id focus-selector" onClick={() => {
                                ReactGA.sendevent(gaCategory, 'COPY', 'USERID_TO_CLIPBOARD');
                                return copyToClipboard(confirmationInfoObj.finalUserId, isCopied)
                              }}
                              onKeyPress={() => {
                                ReactGA.sendevent(gaCategory, 'COPY', 'USERID_TO_CLIPBOARD');
                                return copyToClipboard(confirmationInfoObj.finalUserId, isCopied)
                              }}>  {confirmationInfoObj.finalUserId}</span>
                              <span role="button" aria-label={this.props.intl.formatMessage({ id: 'confirmation_copy' })} className="cs-copy focus-selector" tabIndex="0" onKeyPress={() => {
                                ReactGA.sendevent(gaCategory, 'COPY', 'USERID_TO_CLIPBOARD');
                                return copyToClipboard(confirmationInfoObj.finalUserId, isCopied)
                              }}><CopyIcon style={{ marginBottom: "2px" }} onClick={() => {
                                ReactGA.sendevent(gaCategory, 'COPY', 'USERID_TO_CLIPBOARD');
                                return copyToClipboard(confirmationInfoObj.finalUserId, isCopied)
                              }} 
                              /></span>
                            </span>
                          </OverlayTrigger>
                        </div>

                      </div>
                      {/************* Available ADP Services start ************ */}
                      {confirmationInfoObj.showLoginLinks && (<div className="br-padding">
                        { /***************** Single Product Start ***********************/}
                        {confirmationInfoObj.products && confirmationInfoObj.products.length == 1 &&
                          <div>
                            <div className="vdl-row">
                              <div id="cs-info2" className="heading-text vdl-col-sm-12 vdl-col-xs-12 cs-text-lbl cs-text-center" >
                              {(confirmationInfoObj.runPayrollAdminFlow == undefined || confirmationInfoObj.runPayrollAdminFlow == false)&&  <FormattedMessage id="confirmation.singleProductTitle" values={{ 0: <b>{confirmationInfoObj.products[0].label}</b> }} />}
                        {(confirmationInfoObj.runPayrollAdminFlow == true)&& (confirmationInfoObj.runAdminAccountantConnectFlow == true 
                        ?<FormattedMessage id="confirmation.acProductTitle" values={{ 0: <b>{confirmationInfoObj.products[0].label}</b> }} />
                        :(confirmationInfoObj.runPayrollPlusFlow == true 
                          ? <FormattedMessage id="confirmation.runPayrollPlusTitle" values={{ 0: <b>{confirmationInfoObj.products[0].label}</b> }} />
                          : <FormattedMessage id="confirmation.runProductTitle" values={{ 0: <b>{confirmationInfoObj.products[0].label}</b> }} />))}
                              
                              </div>
                            </div>

                            <div className="cs-signin-layout">
                              <Button
                                id="confirm_signin"
                                className="primary cs-signin-btn"
                                onClick={() => {
                                  ReactGA.sendevent(gaCategory, 'SINGLE_SERVICE', confirmationInfoObj.products[0].label);
                                  let link = confirmationInfoObj.products[0].value;
                                  WindowUtil.openNewWindow(link);
                                  WindowUtil.setUserIdCookieValue(confirmationInfoObj.finalUserId);
                                  return goToProduct(link);
                                }}
                              >
                                {<FormattedMessage id="confirmation.signIn" />}
                              </Button>
                            </div>
                          </div>
                        }

                        { /***************** Single Product End ***********************/}

                        { /***************** Multi Product Start ***********************/}


                        {confirmationInfoObj.products && confirmationInfoObj.products.length > 1 &&
                          <div>
                            <div className="vdl-row">
                              <div id="cs-info2" className="heading-text vdl-col-sm-12 vdl-col-xs-12 cs-text-lbl cs-text-center" >
                                {<FormattedMessage id="confirmation.multiProductTitle" />}
                              </div>
                            </div>

                            <div className="cs-multi-prod-layout">
                              <div id="confirm_multi_signin">
                                {confirmationInfoObj.products.map((value, index) => (
                                  <div role="button" tabIndex = "0" id={"p" + index} key={"p" + index} className="cs-multi-prod-box highlight-options focus-selector"
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      ReactGA.sendevent(gaCategory, 'AVAILABLE_SERVICE', value.label);
                                      let link = value.value;
                                      WindowUtil.openNewWindow(link);
                                      WindowUtil.setUserIdCookieValue(confirmationInfoObj.finalUserId);
                                      return goToProduct(link);
                                    }
                                  }}  
                                  onClick={() => {
                                      ReactGA.sendevent(gaCategory, 'AVAILABLE_SERVICE', value.label);
                                      let link = value.value;
                                      WindowUtil.openNewWindow(link);
                                      WindowUtil.setUserIdCookieValue(confirmationInfoObj.finalUserId);
                                      return goToProduct(link);

                                    }}>

                                    <div className="cs-multi-prod-row">
                                      <div style={{ width: "95%" }}>
                                        <div className="cs-text-label" key={"p" + index}><span className="link-content">{value.label}</span></div>
                                      </div>
                                      <FaAngleRight style={{ width: "5%" }} className="link-content cs-chevron-icon" />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        }
                        { /***************** Multi Product End ***********************/}
                      </div>)}


                      {/************* Available ADP Services end  ************ */}

                      {/************* Activate email and phone start ************ */}
                      {confirmationInfoObj.showActivateSection && confirmationInfoObj.showChannelActivationSection && (<div>
                        <div className="vdl-row cs-border-white" />
                        <div className="vdl-row">
                          <div id="cs-activate-lbl" className="heading-text vdl-col-sm-12 vdl-col-xs-12 cs-activate-lbl">
                            {confirmationInfoObj.activationLabelBody}
                          </div>
                        </div>
                        {/************* Activate email section  ************ */}
                        {confirmationInfoObj.showActivateEmailSection && (<div className="br-padding">
                          {confirmationInfoObj.showActivateEmail && (<div id="cs-activate-email" className="heading-text vdl-row">
                            <div className="vdl-col-sm-1 vdl-col-xs-2 cs-envelope-margin">
                              <EnvelopeIcon style={{ marginBottom: "2px" }} className="cs-envelope" />
                            </div>
                            <div className="vdl-col-sm-11 vdl-col-xs-10 cs-email cs-activate-lbl">
                              {confirmationInfoObj.email}
                            </div>
                          </div>)}

                          {confirmationInfoObj.showActivateSecondEmail && (<div id="cs-activate-semail" className="heading-text vdl-row">
                            <div className="vdl-col-sm-1 vdl-col-xs-2 cs-envelope-margin">
                              <EnvelopeIcon style={{ marginBottom: "2px" }} className="cs-envelope " />
                            </div>
                            <div className="vdl-col-sm-11 vdl-col-xs-10 cs-email cs-activate-lbl">
                              {confirmationInfoObj.secondaryEmail}
                            </div>
                          </div>)}
                        </div>
                        )}
                        {/************* Activate phone section  ************ */}
                        {confirmationInfoObj.showActivatePhoneSection && (<div className="br-padding">
                          {confirmationInfoObj.showActivatePhone && (<div id="cs-activate-phone" className="heading-text vdl-row">
                            <div className="vdl-col-sm-1 vdl-col-xs-2 cs-icon-margin">
                              <MobileIcon style={{ marginBottom: "2px" }} className="cs-phone-icon" />
                            </div>
                            <div className="vdl-col-sm-10 vdl-col-xs-8 cs-phone cs-activate-lbl">
                              {confirmationInfoObj.formatPhone}
                            </div>
                          </div>)}

                          {confirmationInfoObj.showActivateSecondPhone && (<div id="cs-activate-sphone" className="heading-text vdl-row">
                            <div className="vdl-col-sm-1 vdl-col-xs-2 cs-icon-margin">
                              <MobileIcon style={{ marginBottom: "2px" }} className="cs-phone-icon" />
                            </div>
                            <div className="vdl-col-sm-10 vdl-col-xs-8 cs-phone cs-activate-lbl">
                              {confirmationInfoObj.formatSecondPhone}
                            </div>
                          </div>)}
                        </div>)}

                        {(confirmationInfoObj.showActivatePhoneDisplay || confirmationInfoObj.showActivateSecondPhoneDisplay) && (<div>
                          {confirmationInfoObj.showActivatePhoneDisplay && (<div id="cs-activate-ph" className="heading-text vdl-row">
                            <div className="vdl-col-sm-1 vdl-col-xs-2 cs-icon-margin">
                              <MobileIcon style={{ marginBottom: "2px" }} className="cs-phone-icon" />
                            </div>
                            <div className="vdl-col-sm-10 vdl-col-xs-8 cs-phone cs-activate-lbl">
                              {confirmationInfoObj.formatPhone}
                            </div>
                            <a aria-label={this.props.intl.formatMessage({ id: 'Lbl_ActivateAction' })} id="confirm_activation" className="vdl-col-sm-1 vdl-col-xs-2 cs-phone-activation"
                              onClick={() => {
                                ReactGA.sendevent(gaCategory, 'PHONE', "ACTIVATE");
                                return WindowUtil.openNewWindow("/revadm/basic/redirect.jsp?portlet=updateContacts");
                              }}>
                              {<FormattedMessage id="Lbl_ActivateAction" />}
                            </a>
                          </div>)}

                          {confirmationInfoObj.showActivateSecondPhoneDisplay && (<div id="cs-activate-sPh" className="heading-text vdl-row">
                            <div className="vdl-col-sm-1 vdl-col-xs-2 cs-icon-margin">
                              <MobileIcon style={{ marginBottom: "2px" }} className="cs-phone-icon" />
                            </div>
                            <div className="vdl-col-sm-10 vdl-col-xs-8 cs-phone cs-activate-lbl">
                              {confirmationInfoObj.formatSecondPhone}
                            </div>
                            <a aria-label={this.props.intl.formatMessage({ id: 'Lbl_ActivateAction' })} id="confirm_activation1" className="vdl-col-sm-1 vdl-col-xs-2 cs-phone-activation"
                              onClick={() => {
                                ReactGA.sendevent(gaCategory, 'SECOND_PHONE', "ACTIVATE");
                                return WindowUtil.openNewWindow("/revadm/basic/redirect.jsp?portlet=updateContacts")
                              }
                              }>
                              {<FormattedMessage id="Lbl_ActivateAction" />}
                            </a>
                          </div>)}

                        </div>)}

                        {/************ Activate phone section end  **************/}

                      </div>)}
                      {/************* Activate email and phone end ************ */}

                      {/************* Add ADP Services and pending ************ */}
                      {confirmationInfoObj.showActivateSection && confirmationInfoObj.showAddServices && (<div>
                        <div className="vdl-row cs-border-white" />
                        <div className="vdl-row">
                          <div id="Txt_PilotAddService" className="heading-text vdl-col-sm-12 vdl-col-xs-12 cs-text-lbl">
                            {<FormattedMessage id="Txt_PilotAddService" />}
                          </div>
                        </div>
                        <div className="vdl-row" className="br-padding">
                          <div id="Txt_PilotAddService2" className="heading-text vdl-col-sm-12 vdl-col-xs-12 cs-text-lbl" >
                            {<FormattedMessage id="Txt_PilotAddService2" />}
                          </div>
                        </div>
                        {confirmationInfoObj.pendingProductList &&
                          confirmationInfoObj.pendingProductList.map((value, index) => (
                            <div id={"pp" + index} key={"pp" + index} className="heading-text vdl-row">
                              <div key={"pp" + index} className="vdl-col-sm-12 vdl-col-xs-12">
                                <span key={"arrow" + index}>>> </span>
                                <span className="cs-services cs-text-lbl" key={"pp" + index}>{value} </span>
                              </div>
                            </div>
                          ))
                        }
                        <div className="vdl-col-sm-12 vdl-col-xs-12 br-padding" 
                          >
                          <span tabIndex = "0" role="button" id="confirmation-info-4" onKeyPress={(e) => {
                             if (e.key === 'Enter') {
                               e.preventDefault();
                               ReactGA.sendevent(gaCategory, 'ADD_SERVICE', "PROVIDE_YOUR_INFORMATION");
                               return WindowUtil.openNewWindow("/revadm/basic/redirect.jsp?portlet=manageServices&ac=y");
                              }
                            }} onClick={() => {
                            ReactGA.sendevent(gaCategory, 'ADD_SERVICE', "PROVIDE_YOUR_INFORMATION");
                            return WindowUtil.openNewWindow("/revadm/basic/redirect.jsp?portlet=manageServices&ac=y");
                          }}
                            className="cs-services cs-user-id focus-selector"> {<FormattedMessage id="confirmation.info4" />} 
                          <span className="cs-user-id"> <SignInIcon style={{ marginLeft: "1%", marginBottom: "2px" }} />
                          </span>
                          </span>
                        </div>
                      </div>)}
                      {/************* end ************ */}

                    </div>
                  </form>
                </div>
              </div>
            </div>

            {!isMobile && confirmationInfoObj.runPayrollPlusFlow == false && confirmationInfoObj.showMobileEnableSection && (<div className="registration-content cs-margin-top cs-footer-color cs-footer-padding-top">
              <div className="vdl-row welcome-title">
                <div className="vdl-col-md-12 cs-margin-footer-top">
                  <div id="confirmation-info-5" className="heading-text cs-label cs-confirmation-label-div" style={{ fontSize: '12px' }}> 
                  {(confirmationInfoObj.runPayrollAdminFlow==undefined || confirmationInfoObj.runPayrollAdminFlow==false)&&  <FormattedMessage id="confirmation.info5" />}
                  {(confirmationInfoObj.runPayrollAdminFlow==true)&&  <FormattedMessage id="confirmation.info6" />}
                             
                  </div>
                </div>
              </div>
              <div className="cs-footer-links-div">

                <img role="link" id="cs-apple-badget1" tabIndex = "0" onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        let url = "https://itunes.apple.com/us/app/adp-mobile-solutions/id444553167?mt=8";
                  if(confirmationInfoObj.runPayrollAdminFlow==true) {url = "https://apps.apple.com/us/app/run-powered-by-adp-payroll/id388928133";}
                  ReactGA.sendevent(gaCategory, 'LINK', "APPLE");
                  return WindowUtil.openNewWindow(url)
                      }
                    }}
                  onClick={() => {let url = "https://itunes.apple.com/us/app/adp-mobile-solutions/id444553167?mt=8";
                  if(confirmationInfoObj.runPayrollAdminFlow==true) {url = "https://apps.apple.com/us/app/run-powered-by-adp-payroll/id388928133";}
                  ReactGA.sendevent(gaCategory, 'LINK', "APPLE");
                  return WindowUtil.openNewWindow(url)
                }} 
                  src={confirmationInfoObj.srcApple}
                  alt = "APPLE"
                  onError={(e) => e.target.src = "images/Badges/apple_en.png"}
                  className="cs-apple-badget focus-selector" />
                <img role="link" id="cs-andriod-badget1" tabIndex = "0" onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        let url = "https://play.google.com/store/apps/details?id=com.adpmobile.android";
                if(confirmationInfoObj.runPayrollAdminFlow==true) {url = "https://play.google.com/store/apps/details?id=com.adp.run.mobile";}
                  ReactGA.sendevent(gaCategory, 'LINK', "ANDRIOD");
                  return WindowUtil.openNewWindow(url)
                
                      }
                    }}
                onClick={() => { let url = "https://play.google.com/store/apps/details?id=com.adpmobile.android";
                if(confirmationInfoObj.runPayrollAdminFlow==true) {url = "https://play.google.com/store/apps/details?id=com.adp.run.mobile";}
                  ReactGA.sendevent(gaCategory, 'LINK', "ANDRIOD");
                  return WindowUtil.openNewWindow(url)
                }}
                  src={confirmationInfoObj.srcAndriod}
                  alt = "ANDRIOD"
                  onError={(e) => e.target.src = "images/Badges/andriod_en.png"}
                  className="cs-andriod-badget focus-selector" />
              </div>
            </div>)}

          </div>
        </div>

      ))

    );
  }
}
export default injectIntl(ConfirmationForm);
