import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../confirmation-constants';
import { types } from '../confirmation-actions';
import { ReactGA, PendoUtil } from '../../../../util';

const {
  CONFIRMATION_INFO_OPTIONS,
  CONFIRMATION_INFO_OPTIONS_SUCCESS,
  CONFIRMATION_INFO_OPTIONS_FAIL,
  CONFIRMATION_GO_TO_PRODUCT
} = types;

const getConfirmationInfoOptionsSuccess = createAction(CONFIRMATION_INFO_OPTIONS_SUCCESS);
const getConfirmationInfoOptionsFail = createAction(CONFIRMATION_INFO_OPTIONS_FAIL);

const isBlank = str => (str || '').trim().length === 0;

//const getConfirmationData = () => Observable.ajax.getJSON('/public/mocks/confirmation.json');
const getConfirmationData = (ajax, getState) => ajax({
  url: constants.getConfirmationDetailsEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    "cache-control": "no-cache",
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const goToProductAPI = (ajax, payload, getState) => ajax({
  url: constants.logProductUrlEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload,
  headers: {
    'Content-Type': 'application/json',
    "cache-control": "no-cache",
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const getConfirmationInfo = (ajax, getState) => getConfirmationData(ajax, getState).pipe(
  map(response => handleResponse(response.response, getState)),
  catchError(err => getConfirmationInfoOptionsFail(getErrorMessage(err))),
);

//Splunk tracking
const goToProduct = (ajax, payload, getState) => goToProductAPI(ajax, payload, getState).pipe(
  map(response => handleProductResponse(response, getState)),
  catchError(handleFailedProductResponse)
);

const handleProductResponse = (res, getState) => {
  const confirmation = Object.assign({}, getState.confirmation);
  return getConfirmationInfoOptionsSuccess({ confirmation });
}

const handleFailedProductResponse = () => {
  return of();
}

const handleResponse = (data, getState) => {

  const isMobile = getState.registration.mobileFlow;
  let gaCategory = "WEB_REGISTRATION_CONFIRMATION";
  let pagePath = '/ssr/pic/confirmation';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_CONFIRMATION"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_CONFIRMATION"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  data.showDiv = true;
  let selectedLocale = getState.intl.locale;
  if (selectedLocale != undefined) {
    data.srcApple = "images/Badges/apple_" + selectedLocale + ".png";
    data.srcAndriod = "images/Badges/andriod_" + selectedLocale + ".png";

  }
  else {
    data.srcApple = "images/Badges/apple_en.png";
    data.srcAndriod = "images/Badges/andriod_en.png";

  }
  return getConfirmationInfoOptionsSuccess({
    gaCategory,
    isMobile,
    confirmationInfoObj: data
  });
}

export const getContactInfoEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CONFIRMATION_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getConfirmationInfo(ajax, state.value))),
);

export const goToProductEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CONFIRMATION_GO_TO_PRODUCT),
  switchMap(action => sendingAsyncRequest(goToProduct(ajax, action.payload, state.value))),
);

export const getContactInfoOptionsFailureEpic = action$ => action$.pipe(
  ofType(CONFIRMATION_INFO_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))),
);
