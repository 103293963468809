import { createTypes, async } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'FIND_ME_INFO_OPTIONS',
  'FIND_ME_UPDATE_FIELD',
  'FIND_ME_FAIL',
  'FIND_ME_UPDATE_LOCATION',
  'FIND_ME_UPDATE_FIELD_VALUE',
  'FIND_ME_SUBMIT_IDENTITY_OPTIONS',
  'ON_FINAL_FIND_ME_SUCCESS',
  'CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES',
  'FIND_ME_HAS_REG_CODE_OPTION',
  'FIND_ME_ORG_SELECTED',
  'FIND_ME_VERIFY_USER',
  'FIND_ME_GO_TO_MAIN_PAGE',
  'FIND_ME_GO_TO_PII_PAGE',
  'FIND_ME_CHANNEL_SELECT',
  'FIND_ME_VERIFY_OTP',
  'FIND_ME_RESEND_OTP',
  'FIND_ME_PII_INFO_OPTIONS',
  'FIND_ME_GO_TO_SEARCH_PAGE',
  'FIND_ME_GO_TO_ERROR_OPTIONS_PAGE',
  'FIND_ME_SET_LOCALE',
  'REDIRECT_TO_IDENTITY_PAGE','REDIRECT_TO_IDENTITY_PAGE_SUCCESS',
  'UPDATE_LOCALE_FIELD','UPDATE_PAGE_DETAILS',
  'SET_FINDME_SIV_FIELD','SET_FINDME_SIV_FIELD_FAIL','SET_FINDME_SIV_FIELD_SUCCESS',
  'VERIFY_SIV_DATA','VERIFY_SIV_DATA_SUCCESS',
  'FIND_ME_CONTRACTOR_OPTIONS',
  'SET_CONTRACTOR_SIV_FIELD','SET_CONTRACTOR_SIV_FIELD_FAIL','SET_CONTRACTOR_SIV_FIELD_SUCCESS',
  'VERIFY_CONTRACTOR_SIV_DATA','VERIFY_CONTRACTOR_SIV_DATA_SUCCESS',
  'CONTRACTOR_ORG_SELECTED',
  'CONTRACTOR_VERIFY_USER',
  'REGISTRATION_LESS_FLOW',
  'CONTINUE_REGISTRATION_FLOW','CONTINUE_REGISTRATION_FLOW_SUCCESS'
);

const {
  FIND_ME_INFO_OPTIONS,
  FIND_ME_UPDATE_LOCATION,
  FIND_ME_UPDATE_FIELD,
  FIND_ME_UPDATE_FIELD_VALUE,
  FIND_ME_SUBMIT_IDENTITY_OPTIONS,
  ON_FINAL_FIND_ME_SUCCESS,
  CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES,
  FIND_ME_HAS_REG_CODE_OPTION,
  FIND_ME_ORG_SELECTED,
  FIND_ME_VERIFY_USER,
  FIND_ME_GO_TO_MAIN_PAGE,
  FIND_ME_GO_TO_PII_PAGE,
  FIND_ME_CHANNEL_SELECT,
  FIND_ME_VERIFY_OTP,
  FIND_ME_RESEND_OTP,
  FIND_ME_PII_INFO_OPTIONS,
  FIND_ME_GO_TO_SEARCH_PAGE,
  FIND_ME_GO_TO_ERROR_OPTIONS_PAGE,
  FIND_ME_SET_LOCALE,
  REDIRECT_TO_IDENTITY_PAGE,
  UPDATE_PAGE_DETAILS,
  SET_FINDME_SIV_FIELD,
  VERIFY_SIV_DATA,
  FIND_ME_CONTRACTOR_OPTIONS,
  SET_CONTRACTOR_SIV_FIELD,
  VERIFY_CONTRACTOR_SIV_DATA,
  CONTRACTOR_ORG_SELECTED,
  CONTRACTOR_VERIFY_USER,
  REGISTRATION_LESS_FLOW,
  CONTINUE_REGISTRATION_FLOW
} = types;

const getFindMeInfoSent = createAction(FIND_ME_INFO_OPTIONS);
const updateCountryOptionSent = createAction(FIND_ME_UPDATE_LOCATION);
const updateFieldValueSent = createAction(FIND_ME_UPDATE_FIELD_VALUE);
const updateFieldSent = createAction(FIND_ME_UPDATE_FIELD);
const identityOptionsSent = createAction(FIND_ME_SUBMIT_IDENTITY_OPTIONS);
const setRedirectSent = createAction(ON_FINAL_FIND_ME_SUCCESS);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES);
const hasRegCodeSent = createAction(FIND_ME_HAS_REG_CODE_OPTION);
const orgSelectedSent = createAction(FIND_ME_ORG_SELECTED);
const setRedirectVerifyUserSent = createAction(FIND_ME_VERIFY_USER);
const goToMainPageSent = createAction(FIND_ME_GO_TO_MAIN_PAGE);
const goToPiiInfoPageSent = createAction(FIND_ME_GO_TO_PII_PAGE);
const goToEmailPhoneSearchPageSent = createAction(FIND_ME_GO_TO_SEARCH_PAGE);
const goToErrorOptionsPageSent = createAction(FIND_ME_GO_TO_ERROR_OPTIONS_PAGE);
const getContractorOptionsSent = createAction(FIND_ME_CONTRACTOR_OPTIONS);
const channelSelectSent = createAction(FIND_ME_CHANNEL_SELECT);
const submitAccessCodeSent = createAction(FIND_ME_VERIFY_OTP);
const resendOTPSent = createAction(FIND_ME_RESEND_OTP);
const getPiiOptionsSent = createAction(FIND_ME_PII_INFO_OPTIONS);
const setLocaleSent = createAction(FIND_ME_SET_LOCALE);
const redirectToIdentityPageSent = createAction(REDIRECT_TO_IDENTITY_PAGE);
const updateRadioChangeInfoSent = createAction(UPDATE_PAGE_DETAILS);
const setSivFieldSent = createAction(SET_FINDME_SIV_FIELD);
const verifySivDataSent = createAction(VERIFY_SIV_DATA);
const setContractorSivFieldSent = createAction(SET_CONTRACTOR_SIV_FIELD);
const verifyContractorSivDataSent = createAction(VERIFY_CONTRACTOR_SIV_DATA);
const contractorOrgSelectedSent = createAction(CONTRACTOR_ORG_SELECTED);
const setContractorVerifyUserInfoSent = createAction(CONTRACTOR_VERIFY_USER);
const registrationLessFlowSent = createAction(REGISTRATION_LESS_FLOW);
const continueRegistrationSent = createAction(CONTINUE_REGISTRATION_FLOW);

const getFindMeInfo = () => getFindMeInfoSent();
const updateCountryOption = (payload) => updateCountryOptionSent(payload);
const updateFieldValue = (payload) => updateFieldValueSent(payload);
const enableSubmitButton = isFormValid => updateFieldSent({ isFormValid });
const submitIdentityOptions = () => identityOptionsSent();
const setRedirect = () => setRedirectSent();
const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();
const hasRegCode = (payload) => hasRegCodeSent(payload);
const orgSelected = (payload) => orgSelectedSent(payload);
const setRedirectVerifyUser = () => setRedirectVerifyUserSent();
const goToMainPage = () => goToMainPageSent();
const goToPiiInfoPage = () => goToPiiInfoPageSent();
const goToEmailPhoneSearchPage = () => goToEmailPhoneSearchPageSent();
const goToErrorOptionsPage = () => goToErrorOptionsPageSent();
const getContractorOptions = () => getContractorOptionsSent();
const channelSelect = (payload) => channelSelectSent(payload);
const submitAccessCode = () => submitAccessCodeSent();
const resendOTP = (payload) => resendOTPSent(payload);
const getPiiOptions = () => getPiiOptionsSent();
const setPrevPage = (prevPage) => updateFieldSent({ prevPage });
const setErrorPageInfo = (errorPageInfo) => updateFieldSent({ errorPageInfo });
const redirectToContactInfo = () => updateFieldSent({ sendRedirect:true });
const setLocale = (payload)=>setLocaleSent({ payload });
const redirectToIdentityPage = (payload) => redirectToIdentityPageSent(payload);
const updateRadioChangeInfo = (option) => updateRadioChangeInfoSent(option);
const setSivField = (payload) => setSivFieldSent(payload);
const verifySivData = (payload) => verifySivDataSent(payload);
const setContractorSivField = (payload) => setContractorSivFieldSent(payload);
const verifyContractorSivData = (payload) => verifyContractorSivDataSent(payload);
const contractorOrgSelected = (payload) => contractorOrgSelectedSent(payload);
const contractorVerifyUserInfo = () => setContractorVerifyUserInfoSent();
const registrationLessFlow = (payload) => registrationLessFlowSent(payload);
const continueRegistration = (payload) => continueRegistrationSent(payload);

export const actions = {
  getFindMeInfo,
  hasRegCode,
  cancelAndCheckYourEntries,
  setRedirect,
  setRedirectVerifyUser,
  updateFieldValue,
  enableSubmitButton,
  updateCountryOption,
  submitIdentityOptions,
  orgSelected,
  goToMainPage,
  channelSelect,
  submitAccessCode,
  resendOTP,
  goToPiiInfoPage,
  getPiiOptions,
  setPrevPage,
  setErrorPageInfo,
  goToErrorOptionsPage,
  goToEmailPhoneSearchPage,
  redirectToContactInfo,
  setLocale,
  redirectToIdentityPage,
  updateRadioChangeInfo,
  setSivField,
  verifySivData,
  getContractorOptions,
  setContractorSivField,
  verifyContractorSivData,
  contractorOrgSelected,
  contractorVerifyUserInfo,
  registrationLessFlow,
  continueRegistration
};
