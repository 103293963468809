import localeDe from '../../../locales/locale_de_DE';
import localeEnCA from '../../../locales/locale_en_US';
import localeEnGB from '../../../locales/locale_en_US';
import localeEn from '../../../locales/locale_en_US';
import localeEs from '../../../locales/locale_es_ES';
import localeEsUS from '../../../locales/locale_es_US';
import localeFr from '../../../locales/locale_fr_FR';
import localeFrCA from '../../../locales/locale_fr_CA';
import localeHu from '../../../locales/locale_hu_HU';
import localeIt from '../../../locales/locale_it_IT';
import localeJa from '../../../locales/locale_ja_JP';
import localeKo from '../../../locales/locale_ko_KR';
import localeNl from '../../../locales/locale_nl_NL';
import localePl from '../../../locales/locale_pl_PL';
import localePt from '../../../locales/locale_pt_BR';
import localePtPT from '../../../locales/locale_pt_PT';
import localeRu from '../../../locales/locale_ru_RU';
import localeSk from '../../../locales/locale_sk_SK';
import localeZh from '../../../locales/locale_zh_CN';
import localeZhHant from '../../../locales/locale_zh_TW';
import localeCsCZ from '../../../locales/locale_cs_CZ';

export const messages = {
  de_DE: localeDe,
  en_CA: localeEnCA,
  en_GB: localeEnGB,
  en_US: localeEn,
  es_ES: localeEs,
  es_US: localeEsUS,
  fr_FR: localeFr,
  fr_CA: localeFrCA,
  hu_HU: localeHu,
  it_IT: localeIt,
  ja_JP: localeJa,
  ko_KR: localeKo,
  nl_NL: localeNl,
  pl_PL: localePl,
  pt_BR: localePt,
  pt_PT: localePtPT,
  ru_RU: localeRu,
  sk_SK: localeSk,
  zh_CN: localeZh,
  zh_TW: localeZhHant,
  cs_CZ: localeCsCZ
};
