import { handleActions } from 'redux-actions';
import { types } from './confirmation-actions';

const {
  CONFIRMATION_INFO_OPTIONS_SUCCESS,
  CONFIRMATION_INFO_OPTIONS_FAIL,
  CONFIRMATION_INFO_OPTIONS_UPDATE


} = types;

export const initialState = {
  flowType: 'SSR-REACT',

  transactionId: '',
  confirmationInfoObj: { showDiv: false }

};

export default handleActions(
  {
    [CONFIRMATION_INFO_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [CONFIRMATION_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [CONFIRMATION_INFO_OPTIONS_UPDATE]: (state, { payload }) => ({ ...state, ...payload })

  },
  initialState,
);
