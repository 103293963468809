import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions as localeActions } from '../../redux/ducks/locale';
import { actions as verifyUserPinActions } from '../../redux/ducks/verify-user-pin';
import { actions as timeoutActions } from '../../redux/ducks/timeout';
import { RegistrationLayout } from '../../layouts';
import { VerifyUserPinForm } from '../../components';

export const VerifyUserPinContainer = props => <RegistrationLayout { ...props } />;

const loginTitle = <FormattedMessage id="verifyUser.title" />;
const mapStateToProps = ({
  locale, sendingRequest, verifyUserPin, timeout, registration
}) => {
  const branding = {};
  branding.footer = registration.footer;
  branding.organizationLogo = registration.organizationLogo;
  branding.productLogo = registration.productLogo;
  branding.background = registration.background;

  return ({
  ...locale,
  ...sendingRequest,
  ...verifyUserPin,
  ...timeout,
  ...branding
});}
// prettier-ignore
const mapDispatchToProps = dispatch => bindActionCreators(
  Object.assign({}, { ...localeActions }, { ...verifyUserPinActions }, { ...timeoutActions }), dispatch,
);
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };

  Object.assign(props, { title: loginTitle, mainPane: <VerifyUserPinForm { ...props } /> });

  return props;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(VerifyUserPinContainer);
