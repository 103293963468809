import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../identify-yourself-constants';
import { types } from '../identify-yourself-actions';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  GET_SIV_OPTIONS,
  GET_SIV_OPTIONS_SUCCESS,
  GET_SIV_OPTIONS_FAIL,
  SET_SIV_FIELD,
  SET_SIV_FIELD_SUCCESS,
  SET_SIV_FIELD_FAIL,
  VERIFY_SIV_OPTIONS,
  VERIFY_SIV_OPTIONS_SUCCESS,
  VERIFY_SIV_OPTIONS_FAIL,
  CANCEL_AND_CHECK_YOUR_ENTRIES,
  NAVIGATE_TO_BEV,
  NAVIGATE_TO_BEV_SUCCESS
} = types;

const getOrcSivOptionsSuccess = createAction(GET_SIV_OPTIONS_SUCCESS);
const getOrcSivOptionsFail = createAction(GET_SIV_OPTIONS_FAIL);
const setSivFieldSuccessSent = createAction(SET_SIV_FIELD_SUCCESS);
const setSivFieldFailSent = createAction(SET_SIV_FIELD_FAIL);
const verifySivOptionsSuccessSent = createAction(VERIFY_SIV_OPTIONS_SUCCESS);
const verifySivOptionsFailSent = createAction(VERIFY_SIV_OPTIONS_FAIL);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_AND_CHECK_YOUR_ENTRIES);
const navigateToBevSuccess = createAction(NAVIGATE_TO_BEV_SUCCESS);
const isBlank = str => (str || '').trim().length === 0;

const sivOptionsApi = (ajax, transactionId) => ajax({
  url: constants.getSivOptionsDetailsEndPoint,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': transactionId,
  }
});

const handleResponse = (response, getState) => {
 
  //Capital One changes-------start
      let isErrorForModal = false;
      let errorCode = "";
      let isMobile = response.isMobile;
      let showModalTitle = response.title;
      let showModalMessage = response.message;
     
      if (response.code) {
          showModalTitle = response.title;
          showModalMessage = response.message;
          errorCode = response.code;
          isErrorForModal = true;
          // Setting flow type for progress bar 
          getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.verifyUserViewId);
      }
      
      let showModalError = isErrorForModal;
  //Capital One changes-------end

  // progress bar start
    let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
    // progress bar end
  
    PendoUtil.pendoIdentify(response.orgCd,response.regCodeData.companyName,getState.registration.transactionId)
    PendoUtil.pendoVisitorIdUpdate(response.associateId)

  return getOrcSivOptionsSuccess({
    sivOptions: response.regCodeData.sivOptions,
    clientName: response.regCodeData.companyName,
    progressBarData,
    response,
    showModalError,
    showModalTitle,
    showModalMessage,
    errorCode,
    isMobile,
    initialOptionFlag : true,
    initialRadioFlag:true
  });
}

const sivOptionsFromState = (stateObj, recaptchaOn) => of({
  sivOptions: stateObj,
  isRecaptchaOn: recaptchaOn
});

const handleStateSivResponse = (getState, response) => {
  const isMobile = getState.registration.mobileFlow;
 
  let clientName = getState.registration.companyName || getState.verifyUser.companyName;
  // progress bar start
  let progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  // progress bar end
  return getOrcSivOptionsSuccess({
    sivOptions: response.sivOptions,
    isMobile,
    isRecaptchaOn: response.isRecaptchaOn,
    clientName, progressBarData,
    initialOptionFlag : true,
    initialRadioFlag:true
  });
}

const getSivOptions = (ajax, getState) => {
  const isMobile = getState.registration.mobileFlow;
  clearFieldValues(getState);
  getState.findMe.sendRedirect = false;
  if (getState.registration.sitekey != undefined && getState.registration.sitekey != '') {
    getState.identifyYourself.sitekey = getState.registration.sitekey;
    getState.identifyYourself.isRecaptchaOn = getState.registration.isRecaptchaOn;
    getState.identifyYourself.locale = getState.registration.locale;
  }
  if (getState.registration.sivOptions != '' && getState.registration.sivOptions != undefined) {
    return sivOptionsFromState(getState.registration.sivOptions, getState.registration.isRecaptchaOn).pipe(
      map(response => handleStateSivResponse(getState, response)),
      catchError(err => [getOrcSivOptionsFail(getErrorMessage(err))]));
  } else if (getState.verifyUser.sivOptions != '' && getState.verifyUser.sivOptions != undefined) {
    return sivOptionsFromState(getState.verifyUser.sivOptions, getState.verifyUser.isRecaptchaOn).pipe(
      map(response => handleStateSivResponse(getState, response)),
      catchError(err => [getOrcSivOptionsFail(getErrorMessage(err))]));
  } else {
    return sivOptionsApi(ajax, getState.registration.transactionId).pipe(
      map(response => { response.response.isMobile = isMobile; return handleResponse(response.response, getState) }),
      catchError(err => [getOrcSivOptionsFail(getErrorMessage(err))]));
  }
}

const clearFieldValues = (getState) => {
  getState.identifyYourself.firstName = '';
  getState.identifyYourself.lastName = '';
  getState.identifyYourself.ssn = '';
  getState.identifyYourself.ssn4 ='';
  getState.identifyYourself.empId = '';
  getState.identifyYourself.wfnId = '';
  getState.identifyYourself.fullDay = '';
  getState.identifyYourself.fullMonth = '';
  getState.identifyYourself.fullYear = '';
  getState.identifyYourself.day = '';
  getState.identifyYourself.month = '';
  getState.identifyYourself.year = '';
}
{/***********************************************Validate and set siv field values****************************************************/ }

const setSivField = (payload, getState) =>
  validateAndSetSivField(payload, getState).pipe(
    map(setSivFieldSuccessSent),
    catchError(err => [handleSetSivFieldFailResponse(payload, getErrorMessage(err))])
  );
const validateAndSetSivField = (payload, getState) => {
  const identifyYourself = getState.identifyYourself;

  switch (payload.fieldkey) {
    case constants.RADIO: {
      return of({
          initialOptionFlag : false,
          radioValue : payload.fieldValue
        });
    }
    case constants.sivOptionIds.siv_firstName: {
      const firstNameRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_firstname.regExp);

      if (isBlank(payload.fieldValue) || firstNameRegEx.test(payload.fieldValue)) {
        return of({
          firstName: payload.fieldValue,
          firstNameValid: true,
          initialRadioFlag:false
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_firstname.invalidMessage));
    }
    case constants.sivOptionIds.siv_lastName: {
      const lastNameRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_lastname.regExp);

      if (isBlank(payload.fieldValue) || lastNameRegEx.test(payload.fieldValue)) {
        return of({
          lastName: payload.fieldValue,
          lastNameValid: true,
          initialRadioFlag:false
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_lastname.invalidMessage));
    }
    case constants.sivOptionIds.siv_ssn: {
      const ssnRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_ssn.regExp);

      if (isBlank(payload.fieldValue) || ssnRegEx.test(payload.fieldValue)) {
        var replacedSSN = payload.fieldValue.replace(/-/g, "");
        if (identifyYourself.sivOptions.sivCPCOr === true) {
          return of({
            ssn: replacedSSN,
            ssnValid: true,
            empId: '',
            wfnId: '',
            ssn4: '',
          });
        } else {
          return of({
            ssn: replacedSSN,
            ssnValid: true,
          });
        }
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_ssn.invalidMessage));
    }
    case constants.sivOptionIds.siv_empid: {
      const empIdRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_empid.regExp);

      if (isBlank(payload.fieldValue) || empIdRegEx.test(payload.fieldValue)) {
        if (identifyYourself.sivOptions.sivCPCOr === true) {
          return of({
            empId: payload.fieldValue,
            empIdValid: true,
            ssn: '',
            wfnId: '',
            ssn4: ''
          });
        } else {
          return of({
            empId: payload.fieldValue,
            empIdValid: true
          });
        }
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_empid.invalidMessage));
    }
    case constants.sivOptionIds.siv_wfnid: {
      const wfnIdRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_wfnid.regExp);

      if (isBlank(payload.fieldValue) || wfnIdRegEx.test(payload.fieldValue)) {
        if (identifyYourself.sivOptions.sivCPCOr === true) {
          return of({
            wfnId: payload.fieldValue,
            wfnIdValid: true,
            ssn: '',
            empId: '',
            ssn4: ''
          });
        } else {
          return of({
            wfnId: payload.fieldValue,
            wfnIdValid: true
          });
        }

      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_wfnid.invalidMessage));
    }
    case constants.sivOptionIds.siv_ssn4: {
      const ssn4RegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_ssn4.regExp);

      if (isBlank(payload.fieldValue) || ssn4RegEx.test(payload.fieldValue)) {
        if (identifyYourself.sivOptions.sivCPCOr === true) {
          return of({
            ssn4: payload.fieldValue,
            ssn4Valid: true,
            ssn: '',
            wfnId: '',
            empId: ''
          });
        } else {
          return of({
            ssn4: payload.fieldValue,
            ssn4Valid: true
          });
        }

      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_ssn4.invalidMessage));
    }
    case constants.sivOptionIds.siv_dob_month: {
      return of({
        month: payload.fieldValue,
        day: ''
      });
    }
    case constants.sivOptionIds.siv_dob_day: {
      return of({
        day: payload.fieldValue,
        fullDay: '',
        fullMonth: '',
        fullYear: ''
      });
    }
    case constants.sivOptionIds.siv_dob_year: {
      return of({ year: payload.fieldValue });
    }
    case constants.sivOptionIds.siv_dob_full_month: {
      return of({
        fullMonth: payload.fieldValue,
        fullDay: ''
      });
    }
    case constants.sivOptionIds.siv_dob_full_day: {
      return of({
        fullDay: payload.fieldValue,
        day: '',
        month: ''
      });
    }
    case constants.sivOptionIds.siv_dob_full_year: {
      return of({ fullYear: payload.fieldValue });
    }
    case constants.sivOptionIds.siv_zipcode: {
      const zipcodeRegEx = new RegExp(identifyYourself.sivOptions.availableSivOptions.siv_zipcode.regExp);

      if (isBlank(payload.fieldValue) || zipcodeRegEx.test(payload.fieldValue)) {
        return of({
          zipcode: payload.fieldValue,
          zipcodeValid: true,
        });
      }
      return throwError(new Error(identifyYourself.sivOptions.availableSivOptions.siv_zipcode.invalidMessage));
    }
    default: {
      return throwError(new Error(constants.TROUBLE_SHOOT_MSG));
    }
  }
};

const handleSetSivFieldFailResponse = (payload, errorMessage) => {
  switch (payload.fieldkey) {
    case constants.sivOptionIds.siv_firstName:
      return setSivFieldFailSent({
        errorMessageFN: errorMessage,
        firstName: payload.fieldValue,
        firstNameValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_lastName:
      return setSivFieldFailSent({
        errorMessageLN: errorMessage,
        lastName: payload.fieldValue,
        lastNameValid: false,
        sivOptionsValidated: false,
      });
    case constants.sivOptionIds.siv_ssn:
      return setSivFieldFailSent({
        errorMessageSsn: errorMessage,
        ssn: payload.fieldValue,
        ssnValid: false,
        sivOptionsValidated: false
      });
    case constants.sivOptionIds.siv_empid:
      return setSivFieldFailSent({
        errorMessageEmpId: errorMessage,
        empId: payload.fieldValue,
        empIdValid: false,
        sivOptionsValidated: false
      });
    case constants.sivOptionIds.siv_wfnid:
      return setSivFieldFailSent({
        errorMessageWfnId: errorMessage,
        wfnId: payload.fieldValue,
        wfnIdValid: false,
        sivOptionsValidated: false
      });
    case constants.sivOptionIds.siv_ssn4:
      return setSivFieldFailSent({
        errorMessageSsn4: errorMessage,
        ssn4: payload.fieldValue,
        ssn4Valid: false,
        sivOptionsValidated: false
      });
    case constants.sivOptionIds.siv_zipcode:
      return setSivFieldFailSent({
        errorMessageZipcode: errorMessage,
        zipcode: payload.fieldValue,
        zipcodeValid: false,
        sivOptionsValidated: false,
      });
    default: {
      return setSivFieldFailSent({
        errorMessage,
      });
    }
  }
};

{/**************************************************Verify SIV options****************************************************/ }

const submitSivOptions = (ajax, getState) =>
  verifySivOptions(ajax, getState).pipe(
    map(response => handleSubmitSivResponse(response.response, getState)),
    catchError(err => handleSubmitSivResponseFail(getState, getErrorMessage(err)))
  );

const verifySivOptions = (ajax, getState) => {
  const identifyYourself = getState.identifyYourself;
  let gaCategory = identifyYourself.gaCategory;
  const sivOptions = identifyYourself.sivOptions;

  var uiOptionsMap = {};
  var sivGroups = '';
  if (sivOptions.identifierTypes.length === 1) {
    sivGroups = sivOptions.identifierTypes[0];
  }

  var combinationSb = '';
  if (sivOptions.identifierTypes.length > 1) {
    let separator = "_";
    if (sivOptions.availableSivOptions.siv_empid && identifyYourself.empId != '' && !sivOptions.availableSivOptions.siv_wfnid) {
      combinationSb = combinationSb.concat('EMPID');
      combinationSb = combinationSb.concat(separator);
    } else if (!sivOptions.availableSivOptions.siv_empid && sivOptions.availableSivOptions.siv_wfnid && identifyYourself.wfnId != '') {
      combinationSb = combinationSb.concat('WFNID');
      combinationSb = combinationSb.concat(separator);
    }

    if (sivOptions.availableSivOptions.siv_ssn && identifyYourself.ssn != '' && identifyYourself.ssn4 === '') {
      combinationSb = combinationSb.concat('SSN');
      combinationSb = combinationSb.concat(separator);
    } else if (identifyYourself.ssn === '' && sivOptions.availableSivOptions.siv_ssn4 && identifyYourself.ssn4 != '') {
      combinationSb = combinationSb.concat('SSN4');
      combinationSb = combinationSb.concat(separator);
    }

    if (sivOptions.availableSivOptions.siv_fulldateofbirth && identifyYourself.fullDay != '' && identifyYourself.day === '') {
      combinationSb = combinationSb.concat('FDOB');
      combinationSb = combinationSb.concat(separator);
    } else if (identifyYourself.fullDay === '' && sivOptions.availableSivOptions.siv_dateofbirth && identifyYourself.day != '') {
      combinationSb = combinationSb.concat('PDOB');
      combinationSb = combinationSb.concat(separator);
    }

    let size = combinationSb.length - 1;
    if (combinationSb.lastIndexOf(separator) == size) {
      combinationSb = combinationSb.substring(0, size);
    }
    sivGroups = combinationSb;
  }
  
  if (sivOptions.availableSivOptions.siv_firstname && identifyYourself.firstName != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'FIRST_NAME');
    uiOptionsMap.siv_firstname = identifyYourself.firstName;
    sivOptions.availableSivOptions.siv_firstname.idenDataValue = identifyYourself.firstName;
  }
  if (sivOptions.availableSivOptions.siv_lastname && identifyYourself.lastName != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'LAST_NAME');
    uiOptionsMap.siv_lastname = identifyYourself.lastName;
    sivOptions.availableSivOptions.siv_lastname.idenDataValue = identifyYourself.lastName;
  }
  if (sivOptions.availableSivOptions.siv_empid && identifyYourself.empId != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'EMP_ID');
    uiOptionsMap.siv_empid = identifyYourself.empId;
    sivOptions.availableSivOptions.siv_empid.idenDataValue = identifyYourself.empId;
  }
  if (sivOptions.availableSivOptions.siv_wfnid && identifyYourself.wfnId != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'WFN');
    uiOptionsMap.siv_wfnid = identifyYourself.wfnId;
    sivOptions.availableSivOptions.siv_wfnid.idenDataValue = identifyYourself.wfnId;
  }
  if (sivOptions.availableSivOptions.siv_ssn && identifyYourself.ssn != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'SSN');
    var encryptedSsn = Crypto.AES.encrypt(identifyYourself.ssn, registrationConstants.registrationEncryption);
    uiOptionsMap.siv_ssn = encryptedSsn + '';
    sivOptions.availableSivOptions.siv_ssn.idenDataValue = identifyYourself.ssn;
  }
  if (sivOptions.availableSivOptions.siv_ssn4 && identifyYourself.ssn4 != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'SSN4');
    var encryptedSsn4 = Crypto.AES.encrypt(identifyYourself.ssn4, registrationConstants.registrationEncryption);
    uiOptionsMap.siv_ssn4 = encryptedSsn4 + '';
    sivOptions.availableSivOptions.siv_ssn4.idenDataValue = identifyYourself.ssn4;
  }
  if (sivOptions.availableSivOptions.siv_dateofbirth && identifyYourself.day != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'DOB');
    var inputmonthId = sivOptions.sivOptionsCalenderItems.monthItems.indexOf(identifyYourself.month) + 1;
    inputmonthId = ('0' + inputmonthId).slice(-2);
    var fulldob = '' + inputmonthId + identifyYourself.day;
    uiOptionsMap.siv_dateofbirth = fulldob;
    sivOptions.availableSivOptions.siv_dateofbirth.idenDataValue = fulldob;
  }
  if (sivOptions.availableSivOptions.siv_fulldateofbirth && identifyYourself.fullDay != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'FULL_DOB');
    var inputmonthId = sivOptions.sivOptionsCalenderItems.fullMonthItems.indexOf(identifyYourself.fullMonth) + 1;
    inputmonthId = ('0' + inputmonthId).slice(-2);
    var fulldob = '' + inputmonthId + identifyYourself.fullDay + identifyYourself.fullYear;
    uiOptionsMap.siv_fulldateofbirth = fulldob;
    sivOptions.availableSivOptions.siv_fulldateofbirth.idenDataValue = fulldob;
  }

  if (sivOptions.availableSivOptions.siv_zipcode && identifyYourself.zipcode != '') {
    ReactGA.sendevent(gaCategory, 'SUBMITTED', 'ZIPCODE');
    uiOptionsMap.siv_zipcode = identifyYourself.zipcode;
    sivOptions.availableSivOptions.siv_zipcode.idenDataValue = identifyYourself.zipcode;
  }
 
  return ajax({
    url: constants.sendOrcSivOptionsDetailsEndPoint,
    body: {
      'sivPIC': sivOptions.sivPIC,
      'uiSivOptionOptions': uiOptionsMap,
      'recaptchaResponse': identifyYourself.recaptchaResponse,
      'identifierType': sivGroups,
      'identifierTypes': sivOptions.identifierTypes
    },
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
};

const handleSubmitSivResponse = (response, getState) => {
  let gaCategory = getState.identifyYourself.gaCategory;
 
  if (response.code != null && response.code == constants.HAS_BEVPRODUCT) {
    PendoUtil.pendoIdentify(response.orgCd,getState.identifyYourself.clientName,getState.registration.transactionId)
    PendoUtil.pendoVisitorIdUpdate(response.associateId)
    ReactGA.sendevent(gaCategory, 'IDENTIFY_YOURSELF', constants.HAS_BEVPRODUCT);
    return verifySivOptionsFailSent({
      showModalWhenBEVData: true,
      errorMsgTitle: response.title,
      welcomeMsg: response.message,
      errorCode: response.code,
      errorTitle: '',
      ssn4: '',
      ssn: '',
      recaptchaSuccess: true,
      viewId: response.viewId
    });
  }
  if (response.code != null && response.code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(gaCategory, 'IDENTIFY_YOURSELF', constants.ALREADY_REGISTERED);
    console.log('TRUE;response.code=' + response.code);
     return cancelAndCheckYourEntriesSent({
       errorCode : response.code
     });
  }

  if (response.viewId.indexOf(constants.ERROR) == -1) {
    ReactGA.sendevent(gaCategory, 'IDENTIFY_YOURSELF', "SUCCESS");
    PendoUtil.pendoIdentify(response.orgCd,getState.identifyYourself.clientName,getState.registration.transactionId)
    PendoUtil.pendoVisitorIdUpdate(response.associateId)
    let identityToFindMeFlow = false;
      if(response.viewId === constants.FINDME_VIEW) {
        getState.findMe.sendRedirect = false;        
        getState.externalVendor.sendRedirect = false;
        identityToFindMeFlow = true;
        return verifySivOptionsSuccessSent({
        viewId: constants.FINDME_VIEWID,
        identityToFindMeFlow,
        companyName: getState.identifyYourself.clientName,
        errorTitle: ''
        });
    }
    return verifySivOptionsSuccessSent({
      viewId: response.viewId
    });
  }

  if (response.code != null) {

    if (response.code == constants.EXCEEDED_ATTEMPTS || response.code == constants.FUNCTION_LOCKED || response.code == constants.RECAPTCHA_FAILED || response.code == constants.EMAIL_NOT_EXISTS
      || response.code == constants.NO_SIV_DEFINED || response.code == constants.ALREADY_REGISTERED_MULTI || response.code == constants.ALREADY_REGISTERED_SUSPENDED) {
      ReactGA.sendevent(gaCategory, 'IDENTIFY_YOURSELF', response.code);
      console.log('TRUE;response.code=' + response.code);
      return verifySivOptionsFailSent({
        showErrorModal: true,
        errorMsgTitle: response.title,
        welcomeMsg: response.message,
        errorTitle: '',
        ssn4: '',
        ssn: '',
        recaptchaSuccess: true
      });
    }
  }
  
  return verifySivOptionsFailSent({
    showErrorModal: false,
    errorTitle: response.message,
    ssn4: '',
    ssn: '',
    recaptchaSuccess: true
  });
};

const handleSubmitSivResponseFail = (getState, response) => {
  return verifySivOptionsFailSent({
    showErrorModal: true,
    errorMsgTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'],
    welcomeMsg: getState.intl.messages['Err_SystemErrorinRequest'],
    ssn4: '',
    ssn: ''
  });
};

{/**************************************************On cancel redirection****************************************************/ }
const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const code = getState.identifyYourself.errorCode;
  const organizationId = getState.registration.organizationId;
  if (code != null && code === constants.ALREADY_REGISTERED) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  }
 // return window.location.href=constants.REDIRECT_FORGOT;
  if (isMobile && code != constants.ALREADY_REGISTERED) {
    console.log('Entered Mobile flow');
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const handleAlreadyRegisteredResponse = (response) => {

  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});
{/**************************************************Redirect to BEV flow****************************************************/ }

const redirectToBevFlow = getState =>
  of({
    viewId: getState.identifyYourself.viewId
  });

const redirectWhenBevProduct = getState => {
  return redirectToBevFlow(getState)
    .pipe(map(response => handleBevFlowResponse(response)),//.response
    catchError(err => [getErrorMessage(err)]));
}

const handleBevFlowResponse = (response) => {
  return navigateToBevSuccess({
    viewId: response.viewId
  });
}

{/**************************************************Epics declaration****************************************************/ }
export const getSivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(GET_SIV_OPTIONS),
  switchMap(action => sendingAsyncRequest(getSivOptions(ajax, state.value))));

export const setSivFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_SIV_FIELD),
  switchMap(action => sendingAsyncRequest(setSivField(action.payload, state.value))));

export const verifySivOptionsEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_SIV_OPTIONS),
  switchMap(action => sendingAsyncRequest(submitSivOptions(ajax, state.value))));

export const getSivOptionsFailureEpic = action$ => action$.pipe(
  ofType(GET_SIV_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))));

export const verifySivOptionsSuccessEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_SIV_OPTIONS_SUCCESS),
  switchMap(action$ => of(push(state.value.identifyYourself.viewId))));

export const verifySivOptionsFailEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_SIV_OPTIONS_FAIL),
  switchMap(action => of()));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const navigateBevProductEpic = (action$, state) => action$.pipe(
  ofType(NAVIGATE_TO_BEV),
  switchMap(action => sendingAsyncRequest(redirectWhenBevProduct(state.value))),
);

export const navigateToBevSuccessEpic = (action$, state) => action$.pipe(
  ofType(NAVIGATE_TO_BEV_SUCCESS),
  switchMap(action$ => of(push(state.value.identifyYourself.viewId))));