import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, ModalTitle, Button,
} from '@synerg/vdl-react-components';

import { getLocaleObjFromPropKey } from '../../util/locale';
import { ReactGA } from '../../util';

class FooterPopUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: [],
      closeModal: true,
      id: props.id,
    };
    const { displayOrder } = props;

    this.showPopupModal = this.showPopupModal.bind(this, displayOrder);
    this.hidePopupModal = this.hidePopupModal.bind(this);
  }

  showPopupModal(index) {
    let { showModal } = this.state;
    const { id } = this.state;

    ReactGA.sendevent('SSR_FOOTER', 'POPUP_COMPONENT', id);

    showModal = showModal.slice();

    showModal[index] = true;
    this.setState({ showModal });
  }

  hidePopupModal() {
    this.setState({ showModal: [] });
  }

  render() {
    const {
      displayOrder, displayText, url, locale,
    } = this.props;
    const { showModal, closeModal } = this.state;

    return (
      <div>
        <Button
          buttonStyle="link"
          id={ displayOrder }
          className="primary"
          onClick={ this.showPopupModal }
        >
          { getLocaleObjFromPropKey(displayText, locale) }
        </Button>
        <Modal
          size="lg"
          show={ showModal[displayOrder] }
        >
          <ModalHeader
            className="tc-header"
            closeButton={ closeModal }
            onHide={ this.hidePopupModal }
          >
            <ModalTitle className="tc-title" />
            { getLocaleObjFromPropKey(displayText, locale) }
          </ModalHeader>
          <ModalBody id="popup-modal-body">
            <iframe
              className="popup-footer"
              title={ getLocaleObjFromPropKey(displayText, locale) }
              src={ getLocaleObjFromPropKey(url, locale) }
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

FooterPopUpModal.propTypes = {
  displayOrder: PropTypes.number,
  displayText: PropTypes.objectOf(PropTypes.string),
  url: PropTypes.objectOf(PropTypes.string),
  locale: PropTypes.string,
  id: PropTypes.string,
};

export default FooterPopUpModal;
