import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalTitle,
  Alert,
  Radio
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { constants } from '../../redux/ducks/identify-yourself/identify-yourself-constants';
import { ReactGA, WindowUtil } from '../../util';
import Recaptcha from 'react-recaptcha';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import HandIcon from 'adp-react-icons/lib/fa/hand-o-right';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
let showAlertFlag = false;
let setAutoFocusOff;

class IdentifyYourselfForm extends Component {
  constructor() {
    super();
    this.state = {
      option: '',
      inline: false,
      sivGroup: '',
      sivGroupOther: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMonthDaySelection = this.handleMonthDaySelection.bind(this);
  }
  static propTypes = {
    sivOptions: PropTypes.shape({
      availableSivOptions: PropTypes.shape({
        siv_lastname: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_empid: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_wfnid: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_firstname: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_ssn: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_ssn4: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_fulldateofbirth: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_dateofbirth: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
        siv_zipcode: PropTypes.shape({
          labelName: PropTypes.string,
          idenDataValue: PropTypes.string,
          regExp: PropTypes.string,
          invalidMessage: PropTypes.string
        }),
      }),
      sivPIC: PropTypes.bool,
      sivCPCOr: PropTypes.bool,
      sivOptionsCalenderItems: PropTypes.shape({
        monthItems: PropTypes.arrayOf(PropTypes.string),
        dayItems: PropTypes.arrayOf(PropTypes.string),
        fullYearItems: PropTypes.arrayOf(PropTypes.string),
        fullMonthItems: PropTypes.arrayOf(PropTypes.string),
        fullDayItems: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    setSivField: PropTypes.func,
    firstName: PropTypes.string,
    firstNameValid: PropTypes.bool,
    lastName: PropTypes.string,
    lastNameValid: PropTypes.bool,
    ssn: PropTypes.string,
    ssnValid: PropTypes.bool,
    empId: PropTypes.string,
    empIdValid: PropTypes.bool,
    ssn4: PropTypes.string,
    ssn4Valid: PropTypes.bool,
    wfnId: PropTypes.string,
    wfnIdValid: PropTypes.bool,
    fullMonth: PropTypes.string,
    fullDay: PropTypes.string,
    fullYear: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string,
    year: PropTypes.string,
    zipcode: PropTypes.string,
    zipcodeValid: PropTypes.bool,
    verifySivOptions: PropTypes.func,
    errorMessage: PropTypes.string,
    getSivOptions: PropTypes.func,
    welcomeMsg: PropTypes.string,
    showErrorModal: PropTypes.bool,
    isFormValid: PropTypes.bool,
    enableSubmitButton: PropTypes.func,
    cancelAndCheckYourEntries: PropTypes.func,
    errorTitle: PropTypes.string,
    errorMessageFN: PropTypes.string,
    errorMessageLN: PropTypes.string,
    errorMessageEmpId: PropTypes.string,
    errorMessageWfnId: PropTypes.string,
    errorMessageSsn: PropTypes.string,
    errorMessageSsn4: PropTypes.string,
    errorMessageZipcode: PropTypes.string,
    errorMsgTitle: PropTypes.string,
    recaptchaResponse: PropTypes.string,
    setRecaptchaResponse: PropTypes.func,
    sitekey: PropTypes.string,
    isRecaptchaOn: PropTypes.bool,
    showModalError: PropTypes.bool,
    showModalTitle: PropTypes.string,
    showModalMessage: PropTypes.string,
    errorCode: PropTypes.string,
    recaptchaSuccess: PropTypes.bool,
    locale: PropTypes.string,
    sivGroup: PropTypes.string,
    sivGroupOther: PropTypes.string,
    showModalWhenBEVData: PropTypes.bool,
    setCancelBevModalError: PropTypes.func,
    navigateToBev: PropTypes.func,
    empIdPDOB: PropTypes.bool,
    empIdFDOB: PropTypes.bool,
    wfnIdPDOB: PropTypes.bool,
    wfnIdFDOB: PropTypes.bool,
    ssnPDOB: PropTypes.bool,
    ssnFDOB: PropTypes.bool,
    ssn4PDOB: PropTypes.bool,
    ssn4FDOB: PropTypes.bool
  };

  componentWillMount() {
    if(!WindowUtil.getlayoutback()){
    this.props.getSivOptions();
  }
  WindowUtil.setlayoutback(false);
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sivOptions != null && nextProps.sivOptions.availableSivOptions != null) {
      this.checkFormReadyness(nextProps);
      this.handleMonthDaySelection(nextProps);
      if (nextProps.errorTitle != '' && showAlertFlag) {
        showAlertFlag = false;
        window.scrollTo(0, 0);
      }
    }
    if (this.props.initialOptionFlag && (this.props.ssn4PDOB == true || this.props.ssn4FDOB == true)) {
      this.handleChange(constants.SSN4);
    }
    if (this.props.initialOptionFlag && (this.props.ssnPDOB == true || this.props.ssnFDOB == true)) {
      this.handleChange(constants.SSN);
    }
    if (this.props.initialOptionFlag && (this.props.wfnIdPDOB == true || this.props.wfnIdFDOB == true)) {
      this.handleChange(constants.WFNID);
    }
    if (this.props.initialOptionFlag && (this.props.empIdPDOB == true || this.props.empIdFDOB == true)) {
      this.handleChange(constants.EMPID);
    }
  };

  checkFormReadyness = (nextProps) => {
    let formValid = true;

    if (
      nextProps.sivOptions.availableSivOptions.siv_firstname &&
      (isBlank(nextProps.firstName) || !nextProps.firstNameValid)
    ) {
      formValid = false;
    }

    if (
      nextProps.sivOptions.availableSivOptions.siv_lastname &&
      (isBlank(nextProps.lastName) || !nextProps.lastNameValid)
    ) {
      formValid = false;
    }

    if (nextProps.sivOptions.sivCPCOr === true) {
      if (!this.state.option && (nextProps.sivOptions.availableSivOptions.siv_empid ||
        nextProps.sivOptions.availableSivOptions.siv_wfnid ||
        nextProps.sivOptions.availableSivOptions.siv_ssn ||
        nextProps.sivOptions.availableSivOptions.siv_ssn4)) {
        formValid = false;
      }
      if (nextProps.sivOptions.availableSivOptions.siv_ssn && nextProps.radioValue === constants.SSN && (isBlank(nextProps.ssn) || !nextProps.ssnValid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_empid && nextProps.radioValue === constants.EMPID && (isBlank(nextProps.empId) || !nextProps.empIdValid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_ssn4 && nextProps.radioValue === constants.SSN4 && (isBlank(nextProps.ssn4) || !nextProps.ssn4Valid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_wfnid && nextProps.radioValue === constants.WFNID && (isBlank(nextProps.wfnId) || !nextProps.wfnIdValid)) {
        formValid = false;
      }
      if (nextProps.keyWithBothDOB === true) {
        if ((isBlank(nextProps.day) || isBlank(nextProps.month)) && (isBlank(nextProps.fullMonth) || isBlank(nextProps.fullDay) || isBlank(nextProps.fullYear))) {
          formValid = false;
        }
      } else {
        if (nextProps.sivOptions.availableSivOptions.siv_fulldateofbirth && nextProps.radioValue === this.state.sivGroupOther &&
          (isBlank(nextProps.fullMonth) || isBlank(nextProps.fullDay) || isBlank(nextProps.fullYear))) {
          formValid = false;
        }

        if (nextProps.sivOptions.availableSivOptions.siv_dateofbirth && nextProps.radioValue === this.state.sivGroup && (isBlank(nextProps.month) || isBlank(nextProps.day))) {
          formValid = false;
        }
      }

    } else {
      if (nextProps.sivOptions.availableSivOptions.siv_ssn && (isBlank(nextProps.ssn) || !nextProps.ssnValid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_empid && (isBlank(nextProps.empId) || !nextProps.empIdValid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_ssn4 && (isBlank(nextProps.ssn4) || !nextProps.ssn4Valid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_wfnid && (isBlank(nextProps.wfnId) || !nextProps.wfnIdValid)) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_fulldateofbirth &&
        (isBlank(nextProps.fullMonth) || isBlank(nextProps.fullDay) || isBlank(nextProps.fullYear))) {
        formValid = false;
      }

      if (nextProps.sivOptions.availableSivOptions.siv_dateofbirth && (isBlank(nextProps.month) || isBlank(nextProps.day))) {
        formValid = false;
      }
    }
    if (nextProps.sivOptions.availableSivOptions.siv_zipcode && (isBlank(nextProps.zipcode) || !nextProps.zipcodeValid)) {
      formValid = false;
    }

    if (nextProps.isRecaptchaOn && isBlank(nextProps.recaptchaResponse)) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  };

  handleChange(option) {
    let sivGroupVal, sivGroupOtherVal = '';
    if (option === constants.EMPID) {
      if (this.props.empIdPDOB == true) sivGroupVal = constants.EMPID
      if (this.props.empIdFDOB == true) sivGroupOtherVal = constants.EMPID
    }
    if (option === constants.WFNID) {
      if (this.props.wfnIdPDOB == true) sivGroupVal = constants.WFNID
      if (this.props.wfnIdFDOB == true) sivGroupOtherVal = constants.WFNID
    }
    if (option === constants.SSN) {
      if (this.props.ssnPDOB == true) sivGroupVal = constants.SSN
      if (this.props.ssnFDOB == true) sivGroupOtherVal = constants.SSN
    }
    if (option === constants.SSN4) {
      if (this.props.ssn4PDOB == true) sivGroupVal = constants.SSN4
      if (this.props.ssn4FDOB == true) sivGroupOtherVal = constants.SSN4
    }
    this.setState({
      option,
      sivGroup: sivGroupVal,
      sivGroupOther: sivGroupOtherVal
    });
    
    this.props.setSivField(constants.RADIO,option);
  }

  onInlineChange = (inline) => {
    this.setState({
      inline
    });
  }

  handleMonthDaySelection = (nextProps) => {

    if (nextProps.sivOptions.availableSivOptions.siv_dateofbirth && nextProps.month != undefined && nextProps.month != '') {
      const inputMonthId = nextProps.sivOptions.sivOptionsCalenderItems.monthItems.indexOf(nextProps.month) + 1;
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        nextProps.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        nextProps.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        nextProps.sivOptions.sivOptionsCalenderItems.dayItems = constants.monthWith29days;
      }
    }
    if (nextProps.sivOptions.availableSivOptions.siv_fulldateofbirth && nextProps.fullMonth != undefined && nextProps.fullMonth != '') {
      const inputMonthId = nextProps.sivOptions.sivOptionsCalenderItems.fullMonthItems.indexOf(nextProps.fullMonth) + 1;
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        nextProps.sivOptions.sivOptionsCalenderItems.fullDayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        nextProps.sivOptions.sivOptionsCalenderItems.fullDayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        nextProps.sivOptions.sivOptionsCalenderItems.fullDayItems = constants.monthWith29days;
      }
    }
  }

  _handleKeyPress = (e,formValid) => {

    const allowEnterKeyFields = ["iden_fn_textBox","iden_ln_textBox","idenCPCOr_empid_textBox",
    "iden_empid_textBox","idenCPCOr_wfnid_textBox","iden_wfnid_textBox","idenCPCOr_ssn_textBox",
    "iden_ssn_textBox","idenCPCOr_ssn4_textBox","iden_ssn4_textBox","iden_zipcode"];
   
    if (e.key === 'Enter') {
     e.preventDefault();
     ReactGA.sendevent(this.props.gaCategory, 'IDENTIFY_YOURSELF', 'SUBMIT');
      if(formValid && e.target !== undefined && e.target.id !== undefined && allowEnterKeyFields.indexOf(e.target.id) != "-1"){
       this.props.verifySivOptions();
      }     
    }
  }

  handleRecaptchaResponse(value) {
    this.props.setRecaptchaResponse(value);
  }

  callback(value) {
    console.log('value callback======');
  }

  handleRecaptchaExpired(value) {
    this.props.setRecaptchaResponse(value);
  }

  render() {
    const {
      sivOptions,
      setSivField,
      firstName,
      firstNameValid,
      lastName,
      lastNameValid,
      ssn,
      ssnValid,
      empId,
      empIdValid,
      ssn4,
      ssn4Valid,
      wfnId,
      wfnIdValid,
      fullMonth,
      fullDay,
      fullYear,
      verifySivOptions,
      showErrorModal,
      welcomeMsg,
      errorMessage,
      isFormValid,
      cancelAndCheckYourEntries,
      errorTitle,
      errorMessageFN,
      errorMessageLN,
      errorMessageEmpId,
      errorMessageWfnId,
      errorMessageSsn,
      errorMessageSsn4,
      errorMessageZipcode,
      month,
      day,
      year,
      zipcode,
      zipcodeValid,
      errorMsgTitle,
      recaptchaResponse,
      sitekey,
      isRecaptchaOn,
      showModalError,
      showModalTitle,
      showModalMessage,
      errorCode,
      setCancelModalError,
      recaptchaSuccess,
      locale,
      sivGroup,
      sivGroupOther,
      showModalWhenBEVData,
      setCancelBevModalError,
      navigateToBev,
      isMobileBrowser,
      isMobile,
      gaCategory,
      keyWithBothDOB,
      clientName,
      progressBarData,
      initialOptionFlag,
      initialRadioFlag,
      radioValue
    } = this.props;

    const onCancelModalError = () => {
      let showModalError = false;
      return setCancelModalError(showModalError);
    }

    const onCancelBevModalError = () => {
      let showModalWhenBEVData = false;
      return setCancelBevModalError(showModalWhenBEVData);
    }

    const errorPopover = (fieldValid, errorMessage) =>
      (fieldValid && <Popover
        id="dummyPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{errorMessage}</div>
        </Popover>
      ));

    const tooltip = (message) =>
      (<Popover id="tooltip" className="tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{message}</div>
      </Popover>
      );
    const onSetFirstName = fName => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "FIRST_NAME" }); return setSivField(constants.sivOptionIds.siv_firstName, fName); }

    const onSetLastName = lName => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "LAST_NAME" }); return setSivField(constants.sivOptionIds.siv_lastName, lName); }

    const onSetSSN = inputSsn => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SSN" }); return setSivField(constants.sivOptionIds.siv_ssn, inputSsn); }

    const onSetEmpId = inputEmpId => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EMP_ID" }); return setSivField(constants.sivOptionIds.siv_empid, inputEmpId); }

    const onSetSSN4 = inputSsn4 => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "SSN4" }); return setSivField(constants.sivOptionIds.siv_ssn4, inputSsn4); }

    const onSetWfnAssociateId = inputWfnId => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "WFN_ID" }); return setSivField(constants.sivOptionIds.siv_wfnid, inputWfnId); }

    const onSetDobMonth = month => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return setSivField(constants.sivOptionIds.siv_dob_month, month); }

    const onSetDobDay = day => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return setSivField(constants.sivOptionIds.siv_dob_day, day); }

    const onSetDobFullMonth = month => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return setSivField(constants.sivOptionIds.siv_dob_full_month, month); }

    const onSetDobFullDay = day => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return setSivField(constants.sivOptionIds.siv_dob_full_day, day); }

    const onSetDobFullYear = year => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return setSivField(constants.sivOptionIds.siv_dob_full_year, year); }

    const onSetZipcode = zipcd => { WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "ZIP" }); return setSivField(constants.sivOptionIds.siv_zipcode, zipcd); }

    return (
      sivOptions && (

        <div className="registration-content">
          <div className="registration-form-container" >
          {(window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
          }
          {
            (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
          }
            {(!isMobile) && (
              <div className="iy-form-cancel-div focus-selector" tabIndex="0" onKeyPress={() => {
                  ReactGA.sendevent(gaCategory, 'IDENTIFY_YOURSELF', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                <svg id="iden_cancel" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'IDENTIFY_YOURSELF', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            )}
          </div>
          {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
            <div className="registration-progress-bar">
              <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
            </div>
          )}
           {(!isMobile) && <hr className="iy-form-hr" />}
          <div className="vdl-row registration-form">
            <div className="welcome-title title-width">
              <div className="vdl-col-md-12">
                <h1 id="registration-layout_welcome" className="page-title">{<FormattedMessage id="identifyYourself.title" />}</h1>
                <div className="div-h5 heading-text info-align-padding iy-form-title-text"> {
                  <FormattedMessage id="identity_page_infoText" values={{ 0: <b>{clientName}</b> }} />}</div>
              </div>
            </div>
            {errorTitle != undefined && errorTitle != '' && (<Alert
              type="error" style={{ width: '100%', marginLeft:'10%',marginRight:'10%' }}
            ><p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(errorTitle)}</p></Alert>
            )}
            <div className="vdl-col-md-12">
              <form className="adp-form" onKeyPress={(e) => this._handleKeyPress(e,isFormValid)}>
                {sivOptions.availableSivOptions.siv_firstname && (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="firstname" htmlFor="first_name_Label">{sivOptions.availableSivOptions.siv_firstname.labelName}
                          <span className="iy-form-required-icon">*</span>
                          <span id="iden_fn_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc' }))} >
                              <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{marginBottom:"2px"}}/></span>
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(firstNameValid, errorMessageFN)}
                      >

                        <Textbox aria-label={sivOptions.availableSivOptions.siv_firstname.labelName} autocomplete="given-name"
                          id="iden_fn_textBox" autoFocus={setAutoFocusOff}
                          className={firstNameValid ? undefined : 'vdl-validation-error'}
                          value={firstName}
                          onChange={onSetFirstName}
                        />
                      </OverlayTrigger>
                    </div>
                    <div />
                  </div>
                )}

                {sivOptions.availableSivOptions.siv_lastname && (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="lastname" htmlFor="last_name_Label">{sivOptions.availableSivOptions.siv_lastname.labelName}
                          <span className="iy-form-required-icon">*</span>
                          <span id="iden_ln_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'LAST_NAME', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc2' }))} >
                               <span tabIndex="0" role="button" aria-label="lastNameTip" className="focus-selector"><InfoCircle style={{marginBottom:"2px"}} /></span>
                            </OverlayTrigger>
                          </span> </label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(lastNameValid, errorMessageLN)}
                      >

                        <Textbox aria-label={sivOptions.availableSivOptions.siv_lastname.labelName} autocomplete="family-name"
                          id="iden_ln_textBox"
                          className={lastNameValid ? undefined : 'vdl-validation-error'}
                          value={lastName}
                          onChange={onSetLastName}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}

                <div id={this.props.intl.formatMessage({ id: 'verifyPic.oneOptionLabel' })}>
                <div role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'verifyPic.oneOptionLabel' })}>
                {sivOptions.sivCPCOr && keyWithBothDOB === false && (
                  <div>
                    <div >
                      <div className="identity-page-padding iy-form-oneoption-div" >
                        <span id="oneOption" className="heading-text iy-form-oneoption-label">{<FormattedMessage id="verifyPic.oneOptionLabel" />}<span className="iy-form-one-option-required-icon">*</span></span>

                      </div>
                    </div>
                  </div>
                )
                }

                {sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_empid && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="idenCPCOr_empid_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.EMPID ? '#086274' : '' }} value="empId"
                        onChange={this.handleChange} inline={this.state.inline} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID'); }}>{sivOptions.availableSivOptions.siv_empid.labelName}</Radio>
                    </div>
                  </div>
                )}

                {sivOptions.sivCPCOr && this.state.option === constants.EMPID && sivOptions.availableSivOptions.siv_empid ? (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(empIdValid, errorMessageEmpId)}
                      >
                        <Textbox
                          aria-label="empid_textBox"
                          id="idenCPCOr_empid_textBox" autoFocus={this.state.option === constants.EMPID && initialRadioFlag === false ? {setAutoFocusOff} : false}
                          className={empIdValid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={empId} style={{width:'50%'}}
                          onChange={onSetEmpId}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)
                }

                {!sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_empid && (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="empId" htmlFor="empId_Label">{sivOptions.availableSivOptions.siv_empid.labelName}
                          <span className="iy-form-required-icon">*</span> </label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(empIdValid, errorMessageEmpId)}
                      >
                        <Textbox
                          id="iden_empid_textBox"
                          aria-label={sivOptions.availableSivOptions.siv_empid.labelName}
                          className={empIdValid ? undefined : 'vdl-validation-error'}
                          value={empId}
                          onChange={onSetEmpId}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}

                {sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_wfnid && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="idenCPCOr_wfnid_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.WFNID ? '#086274' : '' }} value="wfnId"
                        onChange={this.handleChange} inline={this.state.inline} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'WFN_ID') }}>{sivOptions.availableSivOptions.siv_wfnid.labelName}</Radio>
                    </div>
                  </div>
                )}
                {sivOptions.sivCPCOr && this.state.option === constants.WFNID && sivOptions.availableSivOptions.siv_wfnid ? (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(wfnIdValid, errorMessageWfnId)}
                      >
                        <Textbox autoFocus={this.state.option === constants.WFNID && initialRadioFlag === false ? {setAutoFocusOff} : false}
                          id="idenCPCOr_wfnid_textBox"
                          aria-label={sivOptions.availableSivOptions.siv_wfnid.labelName}
                          className={wfnIdValid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={wfnId} style={{width:'50%'}}
                          onChange={onSetWfnAssociateId}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)
                }

                {!sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_wfnid ? (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="wfnId" htmlFor="wfnId_Label">{sivOptions.availableSivOptions.siv_wfnid.labelName}
                          <span className="iy-form-required-icon">*</span></label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(wfnIdValid, errorMessageWfnId)}
                      >
                        <Textbox
                          id="iden_wfnid_textBox"
                          aria-label={sivOptions.availableSivOptions.siv_wfnid.labelName}
                          className={wfnIdValid ? undefined : 'vdl-validation-error'}
                          value={wfnId}
                          onChange={onSetWfnAssociateId}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : (null)
                }


                {sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_ssn && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="idenCPCOr_ssn_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.SSN ? '#086274' : '' }} value="ssn"
                        onChange={this.handleChange} inline={this.state.inline} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'SSN')}}>{sivOptions.availableSivOptions.siv_ssn.labelName}</Radio>
                    </div>
                  </div>
                )}
                {sivOptions.sivCPCOr && this.state.option === constants.SSN && sivOptions.availableSivOptions.siv_ssn ? (
                  <div className="identity-page-padding">
                    <div>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(ssnValid, errorMessageSsn)}
                      >
                        <Textbox autoFocus={this.state.option === constants.SSN && initialRadioFlag === false ? {setAutoFocusOff} : false}
                          id="idenCPCOr_ssn_textBox" style={{ width: '30%' }}
                          aria-label={sivOptions.availableSivOptions.siv_ssn.labelName}
                          type="password"
                          className={ssnValid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={ssn}
                          onChange={onSetSSN}
                        />
                      </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                      <div className="ssn-info-tooltip-align">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                          overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))}
                        >
                          <span className="vp-handicon-span focus-selector" tabIndex="0">
                            <HandIcon  style={{marginBottom:"2px"}}/>

                            <a aria-label="tooltip" id="idenCPCOr_ssn_info" className="tooltip-link iy-form-handicon-label">  {<FormattedMessage id="ssn_info_tooltip" />}</a></span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                ) : (null)
                }

                {!sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_ssn && (
                  <div className="identity-page-padding identity-page-lock-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="ssn" htmlFor="ssn_Label">{sivOptions.availableSivOptions.siv_ssn.labelName}
                          <span className="iy-form-required-icon">*</span>
                          <span id="idenCPCOr_ssn_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'SSN', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                              <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{marginBottom:"2px"}}/></span>
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div >
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(ssnValid, errorMessageSsn)}
                      >
                        <Textbox
                          id="iden_ssn_textBox" style={{ width: '93%' }}
                          type="password"
                          aria-label={sivOptions.availableSivOptions.siv_ssn.labelName}
                          className={ssnValid ? undefined : 'vdl-validation-error'}
                          value={ssn}
                          onChange={onSetSSN}
                        />
                      </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                    </div>
                  </div>
                )}


                {sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_ssn4 && (
                  <div className="identity-page-padding">
                    <div className="input-group-full">
                      <Radio id="idenCPCOr_ssn4_radio" radioGroupValue={this.state.option} style={{ color: this.state.option === constants.SSN4 ? '#086274' : '' }} value="ssn4"
                        onChange={this.handleChange} inline={this.state.inline} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'SSN4')}}>{sivOptions.availableSivOptions.siv_ssn4.labelName}</Radio>
                    </div>
                  </div>
                )}
                {sivOptions.sivCPCOr && this.state.option === constants.SSN4 && sivOptions.availableSivOptions.siv_ssn4 ? (
                  <div className="identity-page-padding">
                    <div>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(ssn4Valid, errorMessageSsn4)}
                      >
                        <Textbox autoFocus={this.state.option === constants.SSN4 && initialRadioFlag === false ? {setAutoFocusOff} : false}
                          id="idenCPCOr_ssn4_textBox" style={{ width: '25%' }}
                          type="password"
                          aria-label={sivOptions.availableSivOptions.siv_ssn4.labelName}
                          className={ssn4Valid ? 'sivoptions-align' : 'vdl-validation-error sivoptions-align'}
                          value={ssn4}
                          onChange={onSetSSN4}
                        />
                      </OverlayTrigger> <span className="lock-icon-align"><LockIcon /></span>
                      <div className="ssn-info-tooltip-align">
                        <OverlayTrigger isAriaExpanded={ false }
                          trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                          overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))}
                        >
                          <span className="vp-handicon-span focus-selector" tabIndex="0">
                            <HandIcon  style={{marginBottom:"2px"}}/>

                            <a aria-label="tooltip" id="idenCPCOr_ssn4_info" className="tooltip-link iy-form-handicon-label" >  {<FormattedMessage id="ssn_info_tooltip" />}</a></span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                ) : (null)
                }

                {!sivOptions.sivCPCOr && sivOptions.availableSivOptions.siv_ssn4 && (
                  <div className="identity-page-padding identity-page-lock-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-10 vdl-col-xs-20">
                        <label id="ssn4" htmlFor="ssn_Label">{sivOptions.availableSivOptions.siv_ssn4.labelName}
                          <span className="iy-form-required-icon">*</span>
                          <span id="idenCPCOr_ssn4_ques" className="question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'SSN', 'TOOLTIP')}>
                            <OverlayTrigger placement="bottom" trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                              <span tabIndex="0" role="button" aria-label="tooltip" className="focus-selector"><InfoCircle style={{marginBottom:"2px"}}/></span>
                            </OverlayTrigger>
                          </span> </label>
                      </div>
                    </div>
                    <div>
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(ssn4Valid, errorMessageSsn4)}
                      >
                        <Textbox
                          id="iden_ssn4_textBox" style={{ width: '93%' }}
                          type="password"
                          aria-label={sivOptions.availableSivOptions.siv_ssn4.labelName}
                          className={ssn4Valid ? undefined : 'vdl-validation-error'}
                          value={ssn4}
                          onChange={onSetSSN4}
                        />
                      </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                    </div>
                  </div>
                )}

                 <div id={this.props.intl.formatMessage({ id: 'verifyPic.oneOptionLabel' })+'*'}>
                <div style={{paddingBottom: '20px'}} role="group" aria-labelledby={this.props.intl.formatMessage({ id: 'verifyPic.oneOptionLabel' })+'*'}>
                {sivOptions.sivCPCOr && keyWithBothDOB === true && (
                  <div>
                    <div >
                      <div className="heading-text identity-page-padding iy-form-oneoption-div">
                        <span id="oneOption" className="iy-form-oneoption-label">{<FormattedMessage id="verifyPic.oneOptionLabel" />}<span className="iy-form-one-option-required-icon">*</span></span>

                      </div>
                    </div>
                  </div>

                )
                }

                {sivOptions.availableSivOptions.siv_dateofbirth && this.state.option === this.state.sivGroup && (
                  <div id={sivOptions.availableSivOptions.siv_dateofbirth.labelName}>
                  <div className="dob-padding-align" role="group" aria-labelledby={sivOptions.availableSivOptions.siv_dateofbirth.labelName}>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="partialdob" htmlFor="fulldob_Label">{sivOptions.availableSivOptions.siv_dateofbirth.labelName}
                          {keyWithBothDOB === false && (<span className="iy-form-required-icon">*</span>)}</label>
                      </div>
                    </div>
                    <div className="iy-form-dropdown-div">
                      <div className="monthdropdown-cpc-align">
                        <DropdownList aria-label="monthDropdown" 
                          id="iden_partialdob_month" autocomplete="bday-month"
                          value={month}
                          onChange={onSetDobMonth}
                          data={sivOptions.sivOptionsCalenderItems.monthItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_month' })}
                        />
                      </div>
                      <div className="full-dob-column-width daydropdown-align">
                        <DropdownList aria-label="dayDropdown" 
                          id="iden_partialdob_day"
                          value={day} autocomplete="bday-day"
                          onChange={onSetDobDay}
                          data={sivOptions.sivOptionsCalenderItems.dayItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_day' })}
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                )}
                {sivOptions.availableSivOptions.siv_fulldateofbirth && this.state.option === this.state.sivGroupOther && (
                  <div id={sivOptions.availableSivOptions.siv_fulldateofbirth.labelName}>
                  <div className="dob-padding-align" role="group" aria-labelledby={sivOptions.availableSivOptions.siv_fulldateofbirth.labelName}>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-8 vdl-col-xs-15">
                        <label id="fulldob" htmlFor="fulldob_Label">{sivOptions.availableSivOptions.siv_fulldateofbirth.labelName}
                          {keyWithBothDOB === false && (<span className="iy-form-required-icon">*</span>)}</label>
                      </div>
                    </div>
                    <div className="iy-form-month-dropdown-div">
                      <div className="monthdropdown-cpc-align">
                        <DropdownList aria-label="monthDropdown" 
                          id="iden_month"
                          value={fullMonth} autocomplete="bday-month"
                          onChange={onSetDobFullMonth}
                          data={sivOptions.sivOptionsCalenderItems.fullMonthItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_month' })}
                        />
                      </div>
                      <div className="daydropdown-cpc-align">
                        <DropdownList aria-label="dayDropdown" 
                          id="iden_day"
                          value={fullDay} autocomplete="bday-day"
                          onChange={onSetDobFullDay}
                          data={sivOptions.sivOptionsCalenderItems.fullDayItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_day' })}
                        />
                      </div>
                      <div className="yeardropdown-cpc-align">
                        <DropdownList aria-label="yearDropdown" 
                          id="iden_year"
                          value={fullYear} autocomplete="bday-year"
                          onChange={onSetDobFullYear}
                          data={sivOptions.sivOptionsCalenderItems.fullYearItems}
                          placeholder={this.props.intl.formatMessage({ id: 'lbl_year' })}
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                )}

                </div>
                </div>
                {sivOptions.availableSivOptions.siv_zipcode && (
                  <div className="identity-page-padding">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="zipcode" htmlFor="ssn_Label">{sivOptions.availableSivOptions.siv_zipcode.labelName}
                          <span className="iy-form-one-option-required-icon">*</span>  </label>
                      </div>
                    </div>
                    <div className="input-group-full">
                      <OverlayTrigger isAriaExpanded={ false }
                        trigger={window.innerWidth <= 800 && window.innerHeight <= 700 ? "click" : ['click', 'focus']} placement="bottom"
                        overlay={errorPopover(zipcodeValid, errorMessageZipcode)}
                      >
                        <Textbox
                          id="iden_zipcode" autocomplete="postal-code"
                          type="text"
                          aria-label={sivOptions.availableSivOptions.siv_zipcode.labelName}
                          className={zipcodeValid ? undefined : 'vdl-validation-error'}
                          value={zipcode}
                          onChange={onSetZipcode}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}
                </div>
                </div>

                {isRecaptchaOn && recaptchaSuccess == false && (
                  <div className="identity-page-padding">
                    <Recaptcha sitekey={sitekey} value={recaptchaResponse}
                      hl={locale} render="explicit"
                      verifyCallback={this.handleRecaptchaResponse.bind(this)}
                      onloadCallback={this.callback.bind(this)}
                      expiredCallback={this.handleRecaptchaExpired.bind(this)} />
                  </div>
                )}

                <div
                  id="sivoptions-form_submit-button"
                  className="form-group submit vdl-text-xs-center submit-button-align" style={{ marginTop: '30px', marginBottom: '40px' }}
                >
                  <Button role="button"
                    style={{ minHeight: "35px", minWidth: "180px" }}
                    id="iden_continueBtn"
                    disabled={!isFormValid} onClick={() => {showAlertFlag = true; return this.props.verifySivOptions();}}
                    onKeyPress={(e) => {if (e.key === 'Enter') {showAlertFlag = true; return this.props.verifySivOptions();}}}
                    className="primary submit-button-align safari-button-align"
                  >
                    {<FormattedMessage id="submitCBtn.Label" />}
                  </Button></div>

                {errorMsgTitle && (<Modal
                  className="vdl-modal--alert"
                  show={showErrorModal}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className={errorCode === constants.ALREADY_REGISTERED ? 'modal-title-info' : 'modal-title-error'}>
                      <WarnIcon className={errorCode === constants.ALREADY_REGISTERED ? 'vdl-modal__title-icon warn-icon-info' : 'vdl-modal__title-icon warn-icon-error'}
                      />
                      {errorMsgTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="iden_okBtn1" onClick={cancelAndCheckYourEntries} onKeyPress={cancelAndCheckYourEntries} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}

                {showModalTitle && (<Modal id="identifyErrorModal"
                  className="vdl-modal--alert"
                  show={showModalError}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className='modal-title-error'>
                      <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                      {ReactHtmlParser(showModalTitle)}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label className="modal-text">{ReactHtmlParser(showModalMessage)}</label>

                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor" style={{ display: errorCode != 'CAPITAL1_VERIFICATION_FAILED' ? 'block' : 'none' }}>
                    <Button id="iden_okBtn2" onClick={cancelAndCheckYourEntries} onKeyPress={cancelAndCheckYourEntries} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                  <ModalFooter className="modal-body-footercolor" style={{ display: errorCode == 'CAPITAL1_VERIFICATION_FAILED' ? 'block' : 'none' }}>
                    <Button id="iden_okBtn3" onClick={onCancelModalError} className="safari-button-align">
                      {<FormattedMessage id="Lbl_OkButton" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}

                {errorMsgTitle && (<Modal
                  className="vdl-modal--alert"
                  show={showModalWhenBEVData}
                  onEnter={() => { WindowUtil.applyBrandingChanges(); }}
                >
                  <ModalHeader closeButton={false} className="modal-header">
                    <ModalTitle className="modal-title-error">
                      <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                      {errorMsgTitle}
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody className="modal-body-footercolor">
                    <div>
                      <label htmlFor="welcomeMsg" className="modal-text">{ReactHtmlParser(welcomeMsg)}</label>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-body-footercolor">
                    <Button id="iden_closeBtn" onClick={onCancelBevModalError} className="safari-button-align">
                      {<FormattedMessage id="lbl_closebutton" />}
                    </Button>
                    <Button id="iden_regByServiceBtn" onClick={navigateToBev} className="safari-button-align align-mobile-bev-modal">
                      {<FormattedMessage id="registerByService_button" />}
                    </Button>
                  </ModalFooter>
                </Modal>)}
              </form>
            </div>
          </div>
        </div>
      )
    );
  }
}
export default injectIntl(IdentifyYourselfForm);
