export const constants = {
    getVerifyKbaInitialDataAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyKba/initKba',
    getVerifyKbaTimeoutAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyKba/kbaTimeout',
    getVerifyKbaNextQuestionAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyKba/nextQuestion',
    TYPE_EXPIRED: 'KBA_QUESTION_EXPIRED',
    TYPE_QUESTIONS: 'QUESTIONS',
    TYPE_PASSED: 'PASSED',
    TYPE_FAILED: 'FAILED',
    PATH_VERIFY_KBA: '/ssr/flowType/verifyKba',
    

};
