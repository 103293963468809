import { createTypes, async } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'CONTACT_INFO_OPTIONS', 'CONTACT_INFO_OPTIONS_SUCCESS', 'CONTACT_INFO_OPTIONS_FAIL',
  'SET_CONTACT_FIELD',
  'SET_CONTACT_FIELD_SUCCESS',
  'SET_CONTACT_FIELD_FAIL',
  'ENABLE_SECOUNDARY_FORM',
  'ENABLE_SECOUNDARY_FORM_SUCCESS',
  'ENABLE_PHONE_FIELD',
  'ENABLE_PHONE_FIELD_SUCCESS',
  'ENABLE_SUBMIT_BUTTON',
  'CONTACT_FINAL_SUBMIT',
  'ON_FINAL_SUBMIT_SUCCESS',
  'ON_FINAL_SUBMIT_FAILURE',
  'SET_MODAL_FIELD',
  'SET_CONTINUE_MODAL',
  'ON_FINAL_CONTACT_INFO_SUCCESS',
  'ON_FINAL_SUBMIT_ALERT_SUCCESS',
  'CANCEL_CONTACT_AND_CHECK_YOUR_ENTRIES',
  'PERSONAL_PHONE_INFO',
  'CONTACT_INFO_ACCESS_CODE_SENT'
);

const {
  CONTACT_INFO_OPTIONS,
  SET_CONTACT_FIELD,
  ENABLE_SECOUNDARY_FORM,
  ENABLE_PHONE_FIELD,
  ENABLE_SUBMIT_BUTTON,
  CONTACT_FINAL_SUBMIT,
  ON_FINAL_SUBMIT_SUCCESS,
  ON_FINAL_SUBMIT_FAILURE,
  SET_MODAL_FIELD,
  SET_CONTINUE_MODAL,
  ON_FINAL_CONTACT_INFO_SUCCESS,
  ON_FINAL_SUBMIT_ALERT_SUCCESS,
  CANCEL_CONTACT_AND_CHECK_YOUR_ENTRIES,
  PERSONAL_PHONE_INFO,
  CONTACT_INFO_ACCESS_CODE_SENT

} = types;

const getContactInfoSent = createAction(CONTACT_INFO_OPTIONS);
const setContactFieldSent = createAction(SET_CONTACT_FIELD);
const enableSecoundaryFormSent = createAction(ENABLE_SECOUNDARY_FORM);
const enablePhoneFieldSent = createAction(ENABLE_PHONE_FIELD);
const enableSubmitButtonSent = createAction(ENABLE_SUBMIT_BUTTON);
const setFinalSubmit = createAction(CONTACT_FINAL_SUBMIT);
const setCancelModalSent = createAction(SET_MODAL_FIELD);
const setContinueModalSent = createAction(ON_FINAL_CONTACT_INFO_SUCCESS);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_CONTACT_AND_CHECK_YOUR_ENTRIES);
const getChannelInfoSent = createAction(PERSONAL_PHONE_INFO);
const setSubmitAccessCodeSent = createAction(CONTACT_INFO_ACCESS_CODE_SENT);



const getContactInfo = () => getContactInfoSent();
const setContactField = (fieldkey, fieldValue) => setContactFieldSent({ fieldkey, fieldValue });
const enableSecoundaryForm = () => enableSecoundaryFormSent();
const enablePhoneField = () => enablePhoneFieldSent();
const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });
const onFormSubmit = () => setFinalSubmit();
const setCancelModalWarn = (showModalWarn) => setCancelModalSent({ showModalWarn });
const setCancelModalError = (showModalError) => setCancelModalSent({ showModalError });
const setCancelModalInfo = (showModalInfo) => setCancelModalSent({ showModalInfo });
const setCancelModalLock = (showModalLock) => setCancelModalSent({ showModalLock });
const setContinueModal = () => setContinueModalSent();
const setCancelTnCModal = (showTnCModal) => setCancelModalSent({ showTnCModal });
const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();
const setCancelTSModal = (showTSModal) => setCancelModalSent({showTSModal});
const getChannelInfo = () => getChannelInfoSent();
const setSubmitAccessCode = () => setSubmitAccessCodeSent();
const enableOtpSubmitButton = isOTPFormValid => enableSubmitButtonSent({ isOTPFormValid });
const setOtpValue = otpCode => enableSubmitButtonSent({ otpCode });

export const actions = {
  getContactInfo,
  setContactField,
  enableSecoundaryForm,
  enablePhoneField,
  enableSubmitButton,
  onFormSubmit,
  setCancelModalWarn,
  setCancelModalError,
  setCancelModalInfo,
  setCancelModalLock,
  setContinueModal,
  setContinueModal,
  setCancelTnCModal,
  cancelAndCheckYourEntries,
  setCancelTSModal,
  getChannelInfo,
  setSubmitAccessCode,
  enableOtpSubmitButton,
  setOtpValue
};
