import { handleActions } from 'redux-actions';
import { types } from './external-vendor-actions';

const {
  EXTERNAL_VENDOR_INFO_OPTIONS_SUCCESS,
  EXTERNAL_VENDOR_INFO_OPTIONS_FAIL,
  SET_MANUAL_SELECTED_SUCCESS
 

} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  showOtcModal: false,
  transactionId: '',
  externalVendorObj: {
    showDiv: false
  }

};

export default handleActions(
  {
    [EXTERNAL_VENDOR_INFO_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [EXTERNAL_VENDOR_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_MANUAL_SELECTED_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

  },
  initialState,
);
