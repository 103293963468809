import { createTypes, asyncAction } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { NEXO_NAMESPACE } from '../../helpers/constants';

export const types = createTypes(NEXO_NAMESPACE, asyncAction('INITIAL_LOAD'));

const initialLoad = createAction(types.INITIAL_LOAD_REQUEST);

export const actions = {
  initialLoad,
};
