import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';
import { getBestFitLocale } from '../../../util/locale';

export const types = createTypes(SMS_NAMESPACE, 'CHANGE_LOCALE');

const { CHANGE_LOCALE } = types;

const changeLocaleSent = createAction(CHANGE_LOCALE);

export const changeLocale = locale => changeLocaleSent({ locale: getBestFitLocale(locale) });

export const actions = {
  changeLocale,
};
