import { handleActions } from 'redux-actions';
import { types } from './verify-user-actions';

const { VERIFY_REG_CODE_SUCCESS, VERIFY_REG_CODE_FAIL, SET_REG_CODE , ENABLE_REGCODE_SUBMIT_BUTTON , CHECK_ERROR_MESSAGE, GET_IDENTITY_OPTIONS_SUCCESS ,
  CANCEL_REGISTRATION_VERIFYUSER, UPDATE_LOCALE_FIELD} = types;

export const initialState = {
  regCodeValid: true,
  sivOptions:'',
  isRecaptchaOn : false
};

export default handleActions(
  {
    [SET_REG_CODE]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_REG_CODE_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [VERIFY_REG_CODE_FAIL]: (state, { payload }) => ({ ...state, ...payload }),

    [ENABLE_REGCODE_SUBMIT_BUTTON]: (state, { payload }) => ({ ...state, ...payload }),

    [CHECK_ERROR_MESSAGE]: (state, { payload }) => ({ ...state, ...payload }),

    [GET_IDENTITY_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [CANCEL_REGISTRATION_VERIFYUSER]: (state, { payload }) => ({ ...state, ...payload }),
    
    [UPDATE_LOCALE_FIELD]: (state, { payload }) => ({ ...state, ...payload })
  },
  initialState,
);
