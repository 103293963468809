import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../contact-info-constants';
import { types } from '../contact-info-actions';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  CONTACT_INFO_OPTIONS,
  CONTACT_INFO_OPTIONS_SUCCESS,
  CONTACT_INFO_OPTIONS_FAIL,
  SET_CONTACT_FIELD,
  SET_CONTACT_FIELD_SUCCESS,
  SET_CONTACT_FIELD_FAIL,
  ENABLE_SECOUNDARY_FORM,
  ENABLE_SECOUNDARY_FORM_SUCCESS,
  ENABLE_PHONE_FIELD,
  ENABLE_PHONE_FIELD_SUCCESS,
  ON_FINAL_SUBMIT_SUCCESS,
  CONTACT_FINAL_SUBMIT,
  ON_FINAL_SUBMIT_FAILURE,
  SET_CONTINUE_MODAL,
  ON_FINAL_CONTACT_INFO_SUCCESS,
  ON_FINAL_SUBMIT_ALERT_SUCCESS,
  CANCEL_CONTACT_AND_CHECK_YOUR_ENTRIES,
  PERSONAL_PHONE_INFO,
  CONTACT_INFO_ACCESS_CODE_SENT

} = types;


const getContactInfoOptionsSuccess = createAction(CONTACT_INFO_OPTIONS_SUCCESS);
const getContactInfoOptionsFail = createAction(CONTACT_INFO_OPTIONS_FAIL);
const setContactFieldSuccessSent = createAction(SET_CONTACT_FIELD_SUCCESS);
const setContactFieldFailSent = createAction(SET_CONTACT_FIELD_FAIL);
const setSecoundaryFormEnabled = createAction(ENABLE_SECOUNDARY_FORM_SUCCESS);
const setPhoneFieldEnabled = createAction(ENABLE_PHONE_FIELD_SUCCESS);
const onSetFinalSubmit = createAction(ON_FINAL_SUBMIT_SUCCESS);
const onSetFinalSubmitAlert = createAction(ON_FINAL_SUBMIT_ALERT_SUCCESS);
const setFinalSubmitFail = createAction(ON_FINAL_SUBMIT_FAILURE);

const isBlank = str => (str || '').trim().length === 0;

const getFeedDataApi = (ajax, getState) => ajax({
  url: constants.getContactDetailsFeedDataEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const verifyContactsApi = (ajax, getState, reqObj) => ajax({
  url: constants.getVerifyContactDetailsEndPoint,
  method: 'POST',
  responseType: 'json',
  body: {
    'associateContactInfoCollection': reqObj
  },
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const getPersonalPhoneInfoApi = (ajax, getState) => ajax({
  url: constants.getVerifyPrcGenerateCodeAPI,
  method: 'POST',
  responseType: 'json',
  body: constants.PROOFING_PERSONAL_PHONE,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const setSubmitAccessCodeApi = (ajax, inputPRC, getState) => ajax({
  url: constants.getVerifyPrcSubmitCodeAPI,
  method: 'POST',
  responseType: 'json',
  body: inputPRC,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const sendingFinalSubmit = (ajax, getState, reqObj) => verifyContactsApi(ajax, getState, reqObj).pipe(
  map(response => handleSubmitResponse(response.response, getState)),
  catchError(err => setFinalSubmitFail(getErrorMessage(err))),
);

/******************************Preparing Submit Response Object START *******************************************/

const setFinalSubmit = (ajax, getState) => {
  let secoundaryPhone = false;
  const reqObj = {};
  const associateContactInfo = [];
  const contactInfo = Object.assign({}, getState.contactInfo);
  let gaCategory = contactInfo.gaCategory;
  let contactInfoObj = Object.assign({}, getState.contactInfo.contactInfoObj);
  let contactInfoOptions = Object.assign({}, getState.contactInfo.contactInfoOptions);
  if (contactInfo.isFormValid != undefined && contactInfo.isFormValid == false)
    return of();
  if (!isBlank(contactInfoObj.pEmail)) {
    const contMechPurpTypeNm = getContMechPurpTypeNm(contactInfo, contactInfoObj.peType);
    associateContactInfo.push({
      "contactInfo": contactInfoObj.pEmail, "communicationType": "EMAIL",
      "contMechPurpTypeNm": contMechPurpTypeNm, "tcChecked": null, "countryPhoneCode": null,
      "contactExtn": null, "primary": true, "tsType": (contactInfoObj.radio5 == 'Yes' ? 'ACCEPT' : 'DECLINE')
    });
    ReactGA.sendevent(gaCategory, 'PRIMARY', 'EMAIL');
    if (contMechPurpTypeNm == "PERSONAL" && contactInfoObj.tsEligible)
      ReactGA.sendevent(gaCategory, 'TOTALSOURCE', contactInfoObj.radio5 == 'Yes' ? 'ACCEPT' : 'DECLINE');
  }
  if (!isBlank(contactInfoObj.bMobile)) {
    let extn = contactInfoObj.extn1;
    const phoneMechPurpTypeNm = getPhoneMechPurpTypeNm(contactInfo, contactInfoObj.bmType);
    if (phoneMechPurpTypeNm.cType == "OFFICE_PHONE") {
      contactInfoObj.radio1 = null;
    }
    else {
      ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.cType, contactInfoObj.radio1);
      extn = null;
    }
    associateContactInfo.push({
      "contactInfo": contactInfoObj.bMobile, "communicationType": phoneMechPurpTypeNm.cType,
      "contMechPurpTypeNm": phoneMechPurpTypeNm.pType, "tcChecked": contactInfoObj.radio1, "countryPhoneValue": contactInfoObj.ccodeValue1,
      "contactExtn": extn, "primary": true
    });
    ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.pType, phoneMechPurpTypeNm.cType);

  }
  if (!isBlank(contactInfoObj.sEmail)) {
    const contMechPurpTypeNm = getContMechPurpTypeNm(contactInfo, contactInfoObj.seType);
    associateContactInfo.push({
      "contactInfo": contactInfoObj.sEmail, "communicationType": "EMAIL",
      "contMechPurpTypeNm": contMechPurpTypeNm, "tcChecked": null, "countryPhoneValue": null,
      "contactExtn": null, "tsType": (contactInfoObj.radio5 == 'Yes' ? 'ACCEPT' : 'DECLINE')
    });
    ReactGA.sendevent(gaCategory, 'SECONDARY', 'EMAIL');
    if (contMechPurpTypeNm == "PERSONAL" && contactInfoObj.tsEligible)
      ReactGA.sendevent(gaCategory, 'TOTALSOURCE', contactInfoObj.radio5 == 'Yes' ? 'ACCEPT' : 'DECLINE');
  }
  if (!isBlank(contactInfoObj.pMobile)) {
    let extn = contactInfoObj.extn2;
    const phoneMechPurpTypeNm = getPhoneMechPurpTypeNm(contactInfo, contactInfoObj.pmType);
    if (phoneMechPurpTypeNm.cType == "OFFICE_PHONE") {
      contactInfoObj.radio2 = null;
    }
    else {
      ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.cType, contactInfoObj.radio2);
      extn = null;
    }
    if(contactInfoOptions.embeddedPicEmailNotUnique) {
       associateContactInfo.push({
      "contactInfo": contactInfoObj.pMobile, "communicationType": phoneMechPurpTypeNm.cType,
      "contMechPurpTypeNm": phoneMechPurpTypeNm.pType, "tcChecked": contactInfoObj.radio1, "countryPhoneValue": contactInfoObj.ccodeValue1,
      "contactExtn": extn
      });
    } else {
      associateContactInfo.push({
      "contactInfo": contactInfoObj.pMobile, "communicationType": phoneMechPurpTypeNm.cType,
      "contMechPurpTypeNm": phoneMechPurpTypeNm.pType, "tcChecked": contactInfoObj.radio2, "countryPhoneValue": contactInfoObj.ccodeValue2,
      "contactExtn": extn
      });
    }
    
    ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.pType, phoneMechPurpTypeNm.cType);

  }
  if (!isBlank(contactInfoObj.bLandline)) {
    let extn = contactInfoObj.extn3;
    const phoneMechPurpTypeNm = getPhoneMechPurpTypeNm(contactInfo, contactInfoObj.blType);
    if (phoneMechPurpTypeNm.cType == "OFFICE_PHONE") {
      contactInfoObj.radio3 = null;
    }
    else {
      ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.cType, contactInfoObj.radio3);
      extn = null;
    }
    associateContactInfo.push({
      "contactInfo": contactInfoObj.bLandline, "communicationType": phoneMechPurpTypeNm.cType,
      "contMechPurpTypeNm": phoneMechPurpTypeNm.pType, "tcChecked": contactInfoObj.radio3, "countryPhoneValue": contactInfoObj.ccodeValue3,
      "contactExtn": extn
    });
    ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.pType, phoneMechPurpTypeNm.cType);
  }
  if (!isBlank(contactInfoObj.pLandline)) {
    let extn = contactInfoObj.extn4;
    const phoneMechPurpTypeNm = getPhoneMechPurpTypeNm(contactInfo, contactInfoObj.plType);
    if (phoneMechPurpTypeNm.cType == "OFFICE_PHONE") {
      contactInfoObj.radio4 = null;
    }
    else {
      ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.cType, contactInfoObj.radio4);
      extn = null;
    }
    associateContactInfo.push({
      "contactInfo": contactInfoObj.pLandline, "communicationType": phoneMechPurpTypeNm.cType,
      "contMechPurpTypeNm": phoneMechPurpTypeNm.pType, "tcChecked": contactInfoObj.radio4, "countryPhoneValue": contactInfoObj.ccodeValue4,
      "contactExtn": extn
    });
    ReactGA.sendevent(gaCategory, phoneMechPurpTypeNm.pType, phoneMechPurpTypeNm.cType);
  }
  reqObj.associateContactInfo = associateContactInfo;
  return sendingFinalSubmit(ajax, getState, reqObj);
}

/******************************Preparing Submit Response Object END *******************************************/

const getContMechPurpTypeNm = (contactInfo, type) => {
  const index = contactInfo.contactInfoOptions.emailItems.indexOf(type);
  if (index == 0) return "BUSINESS";
  else return "PERSONAL";

}
const getCountryCodeValue = (contactInfo, value) => {
  if (!isBlank(value)) {
    const index = contactInfo.contactInfoOptions.countryCodes.indexOf(value);
    if (index == -1) return null;
    else return contactInfo.contactInfoOptions.countryCodesList[index].value;
  }
  else return null;
}


const getPhoneMechPurpTypeNm = (contactInfo, type) => {
  const index = contactInfo.contactInfoOptions.phoneItems.indexOf(type);
  if(contactInfo.contactInfoOptions.embeddedPicEmailNotUnique) {
    return { "cType": "CELL_PHONE", "pType": "PERSONAL" };
  } else if (contactInfo.contactInfoOptions.runAdminFlow) {
    if (index == 0) return { "cType": "CELL_PHONE", "pType": "BUSINESS" };
    else return { "cType": "CELL_PHONE", "pType": "PERSONAL" };
  }
  else {
    if (index == 0) return { "cType": "CELL_PHONE", "pType": "BUSINESS" };
    if (index == 1) return { "cType": "OFFICE_PHONE", "pType": "BUSINESS" };
    if (index == 2) return { "cType": "CELL_PHONE", "pType": "PERSONAL" };
    else return { "cType": "HOME_PHONE", "pType": "PERSONAL" };
  }


}

const handleSubmitResponse = (response, getState) => {
  getState.verifyPrc.sendRedirect = false;
  const contactInfo = Object.assign({}, getState.contactInfo);
  let gaCategory = contactInfo.gaCategory;
  let contactInfoObj = contactInfo.contactInfoObj;
  let errorFields = response.errorFieldType;
  contactInfoObj.fieldValid = [true, true, true, true, true, true];
  contactInfoObj.showAlert = false;
  contactInfoObj.contactAdminMsg = response.contactAdminMsg || null;
  contactInfoObj.showNudgePage = response.showNudgePage;
  if (response.code !== undefined && response.code == 'Err_NotAuthorizedforTask') {
    let showModalError = true;
    contactInfoObj.showModalMessage = response.message;
    contactInfoObj.showModalTitle = response.title;
    let isAuthorized = false;
    ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'NOT_AUTHORIZED');
    return onSetFinalSubmit({ isAuthorized, contactInfoObj, showModalError });
  }

  if (response.showOtpVerification) {
    contactInfoObj.isProofingContactInfo = response.showOtpVerification;
    return onSetFinalSubmit({ contactInfoObj });
  }

  if (errorFields != null) {

    errorFields = errorFields.split(",");
    errorFields.map((value, key) => {
      let field = value.split("-");

      if (field[0] == "EMAIL" && field[1] == "PERSONAL") {

        const pos = getErrorFieldIndex(contactInfo, null, 1, 1);

        contactInfoObj.fieldValid[pos] = false;

      }
      else if (field[0] == "EMAIL" && field[1] == "BUSINESS") {
        const pos = getErrorFieldIndex(contactInfo, null, 0, 1);
        contactInfoObj.fieldValid[pos] = false;

      }
      else if (field[0] == "CELL_PHONE" && field[1] == "PERSONAL") {
        const pos = getErrorFieldIndex(contactInfo, 2, null, 2);
        contactInfoObj.fieldValid[pos] = false;

      }
      else if (field[0] == "CELL_PHONE" && field[1] == "BUSINESS") {
        const pos = getErrorFieldIndex(contactInfo, 0, null, 2);
        contactInfoObj.fieldValid[pos] = false;

      }
      else if (field[0] == "HOME_PHONE" && field[1] == "PERSONAL") {
        const pos = getErrorFieldIndex(contactInfo, 3, null, 2);
        contactInfoObj.fieldValid[pos] = false;

      }
      else if (field[0] == "OFFICE_PHONE" && field[1] == "BUSINESS") {
        const pos = getErrorFieldIndex(contactInfo, 1, null, 2);
        contactInfoObj.fieldValid[pos] = false;

      }
    });
  }

  if (response.code == 'Lbl_AccountLocked') {
    contactInfoObj.showModalMessage = response.message;
    contactInfoObj.showModalTitle = response.title;
    ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'ACCOUNT_LOCKED');
    return onSetFinalSubmit({ showModalLock: "true", contactInfoObj })
  } else if (response.code == 'EXCEEDED_ATTEMPTS') {
    let showModalError = true;
    contactInfoObj.showModalMessage = response.message;
    contactInfoObj.showModalTitle = response.title;
    ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'EXCEEDED_ATTEMPTS');
    return onSetFinalSubmit({ showModalLock: "true", contactInfoObj, showModalError })
  }
  else if (response.modelType != null && response.validationPassed == true) {
    ReactGA.sendevent(gaCategory, 'CONTACT_INFO', response.code);
    if (response.message != null) {
      let showModalInfo = false;
      let showModalWarn = false;
      let showModalError = false;
      if (response.modalType == "ERROR") {
        showModalError = true;
      }
      else if (response.modalType == "INFO") {
        showModalInfo = true;
      }
      else {
        showModalWarn = true;
      }
      contactInfoObj.showModalMessage = response.message;
      contactInfoObj.showModalTitle = response.title;
      return onSetFinalSubmit({ viewId: response.viewId, showModalInfo, showModalWarn, showModalError, contactInfoObj })
    }

  }
  else if (response.validationPassed == false) {
    ReactGA.sendevent(gaCategory, 'CONTACT_INFO', response.code);
    contactInfoObj.showAlert = true;
    if (response.message != null) {
      contactInfoObj.showAlertMessage = response.message;
    }
    contactInfo.contactInfoObj = contactInfoObj;
    return onSetFinalSubmitAlert({ viewId: response.viewId, contactInfo });
  }
  else {
    return onSetFinalSubmit({ viewId: response.viewId, sendRedirect: true, contactInfoObj });
  }

}

const getContactInfo = (ajax, getState) => getFeedDataApi(ajax, getState).pipe(
  map(response => handleResponse(response.response, getState)),
  catchError(err => getContactInfoOptionsFail(getErrorMessage(err))),
);

const handleResponse = (data, getState) => {
  /*************************************Constructing contact info options *******************************************/
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_CONTACT";
  if (isMobile) gaCategory = "MOBILE_REGISTRATION_CONTACT";
  if (window.innerWidth <= 479 && isMobile == false) gaCategory = "MOBILEBROSWER_REGISTRATION_CONTACT";

  let contactInfo = Object.assign({}, getState.contactInfo);
  let contactInfoOptions = contactInfo.contactInfoOptions;
  let contactInfoObj = contactInfo.contactInfoObj;
  contactInfoObj.cssTitle = false;
  contactInfoObj.headerTitle = getState.intl.messages['contactInfo.title'];
  let pagePath = '/ssr/capitalOne/contactInfo';
  if (data.extAccountVedorName && data.extAccountVedorName == 'CAPITALONE') {
    console.log("in capitalOne success");
    getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.verifyUser);
    contactInfoObj.headerTitle = getState.intl.messages['contactInfo.capitalOneTitle'];
    contactInfoObj.cssTitle = true;
  }
  else pagePath = '/ssr/pic/contactInfo';
  let pageView = pagePath + "/web";
  if (isMobile) pageView = pagePath + "/mobile";
  if (window.innerWidth <= 479 && isMobile == false) pageView = pagePath + "/mobilebrowser";
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  let isAuthorized = true;
  // progress bar start
  contactInfoObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Contact_Info, getState);
  // progress bar end

  if (data.code !== undefined && data.code == 'Err_NotAuthorizedforTask') {
    let showModalError = true;
    contactInfoObj.showModalMessage = data.message;
    contactInfoObj.showModalTitle = data.title;
    ReactGA.sendevent(gaCategory, 'CONTACT_INFO', 'NOT_AUTHORIZED');
    isAuthorized = false;
    return setContactFieldFailSent({ isAuthorized, contactInfoObj, showModalError });
  }

  var priorityList = [5, 5, 5, 5];
  var valueList = [null, null, null, null];
  var EFlag = [null, null, null, null];
  contactInfoOptions.emailItems = [];
  contactInfoOptions.phoneItems = [];
  contactInfoOptions.countryCodes = [];
  contactInfoOptions.emailValidationPolicy = data.emailRegex;
  contactInfoOptions.mobileValidationPolicy = data.phoneRegex;
  contactInfoOptions.emailInvalidMessage = getState.intl.messages['emailInvalidMessage'];
  contactInfoOptions.mobileInvalidMessage = getState.intl.messages['mobileInvalidMessage'];
  contactInfoOptions.emailItems.push(getState.intl.messages['emailItem1']);
  contactInfoOptions.emailItems.push(getState.intl.messages['emailItem2']);
  contactInfoOptions.runAdminFlow = getState.verifyUser.runAdminFlow || false;
  contactInfoOptions.embeddedPicEmailNotUnique = data.embeddedPicEmailNotUnique || false;
  if (contactInfo.contactInfoOptions.embeddedPicEmailNotUnique) {
    contactInfoObj.phoneCount = 0;
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem2'] + ', ' + getState.intl.messages['phoneItem1']);
  } else if (getState.verifyUser.runAdminFlow) {
    contactInfoObj.phoneCount = 0;
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem1'] + ', ' + getState.intl.messages['phoneItem1']);
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem2'] + ', ' + getState.intl.messages['phoneItem1']);

  } else {
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem1'] + ', ' + getState.intl.messages['phoneItem1']);
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem1'] + ', ' + getState.intl.messages['phoneItem2']);
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem2'] + ', ' + getState.intl.messages['phoneItem1']);
    contactInfoOptions.phoneItems.push(getState.intl.messages['emailItem2'] + ', ' + getState.intl.messages['phoneItem2']);

  }


  if (data.regisNoCode) {
    contactInfoObj.bMobileRequired = true;
  }
  else {
    contactInfoObj.bMobileRequired = false;
  }



  contactInfoObj.onlyCountries = [];
  for (var i = 0; i < data.countryCodes.length; i++) {
    contactInfoObj.onlyCountries.push(data.countryCodes[i].value.toLowerCase());
  }

  if (data.tsEligible) {
    contactInfoObj.tsEligible = true;
    data.tsTypes.map((value, key) => {
      contactInfoObj.tsTypes[key] = value.label;
    });

    let t = contactInfoObj.tsTypes[0];
    contactInfoObj.tsTypes[3] = contactInfoObj.tsTypes[1];
    let start = t.indexOf(">") + 1;
    let end = t.indexOf("</a>") - start;
    contactInfoObj.tsTypes[1] = t.substr(start, end);
    contactInfoObj.tsTypes[0] = t.substr(0, t.indexOf("<a") - 1) + " ";
    contactInfoObj.tsTypes[2] = t.substr(t.indexOf("</a>") + 4);
    contactInfoObj.tsUrl = data.tsUrl;



  }

  data.associateContactInfoDetails.associateContactInfoCollection.associateContactInfo.map((value, key) => {

    if (value.communicationType == "EMAIL" && value.contMechPurpTypeNm == "PERSONAL" && value.contactInfo != null) {
      EFlag[1] = value.contactInfo;
      EFlag[3] = value.enabled;
      EFlag[5] = value.primary;

    }
    if (value.communicationType == "EMAIL" && value.contMechPurpTypeNm == "BUSINESS" && value.contactInfo != null) {
      EFlag[0] = value.contactInfo;
      EFlag[2] = value.enabled;
      EFlag[4] = value.primary;

    }

    if (value.communicationType == "EMAIL" && value.contMechPurpTypeNm == "BUSINESS" && data.businessEmail == true) {
      EFlag[0] = value.contactInfo;
      EFlag[2] = value.enabled;
      EFlag[4] = value.primary;

    }
    
    if (contactInfo.contactInfoOptions.embeddedPicEmailNotUnique) {
      if (value.communicationType == "CELL_PHONE" && value.contMechPurpTypeNm == "PERSONAL" && value.contactInfo != null) {

        priorityList[0] = 0;
        valueList[0] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };

      }
    } else if (getState.verifyUser.runAdminFlow) {
      if (value.communicationType == "CELL_PHONE" && value.contMechPurpTypeNm == "PERSONAL" && value.contactInfo != null) {

        priorityList[1] = 1;
        valueList[1] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };

      }

      if (value.communicationType == "CELL_PHONE" && value.contMechPurpTypeNm == "BUSINESS" && value.contactInfo != null) {
        priorityList[0] = 0;
        valueList[0] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };
      }
    } else {
      if (value.communicationType == "CELL_PHONE" && value.contMechPurpTypeNm == "PERSONAL" && value.contactInfo != null) {

        priorityList[2] = 2;
        valueList[2] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };

      }

      if (value.communicationType == "CELL_PHONE" && value.contMechPurpTypeNm == "BUSINESS" && value.contactInfo != null) {
        priorityList[0] = 0;
        valueList[0] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };
      }

      if (value.communicationType == "HOME_PHONE" && value.contMechPurpTypeNm == "PERSONAL" && value.contactInfo != null) {
        priorityList[3] = 3;
        valueList[3] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };
      }

      if (value.communicationType == "OFFICE_PHONE" && value.contMechPurpTypeNm == "BUSINESS" && value.contactInfo != null) {
        priorityList[1] = 1;
        valueList[1] = { field: value.contactInfo, countrycode: value.countryPhoneCode, enabled: value.enabled, primary: value.primary, countrycodeValue: value.countryPhoneValue };
      }
    }




  });

  /******************************Setting EMAIL and Phone fields *******************************************/

  if (EFlag[0] == null && EFlag[1] == null && data.businessEmail) {
    contactInfoObj.fieldDisable[2] = true;
    contactInfoObj.emailDisabled = true;
    contactInfoObj.seType = contactInfoOptions.emailItems[0];
    contactInfoObj.peType = contactInfoOptions.emailItems[1];
  }

  if (EFlag[0] != null && EFlag[1] != null) {
    contactInfoObj.showSecoundaryForm = true;
    if (EFlag[4]) {
      contactInfoObj.seType = contactInfoOptions.emailItems[1];
      contactInfoObj.pEmail = EFlag[0];
      contactInfoObj.sEmail = EFlag[1];
      contactInfoObj.peType = contactInfoOptions.emailItems[0];
      if (EFlag[2] == false) {
        contactInfoObj.fieldDisable[0] = true;
        contactInfoObj.emailDisabled = true;
      }
      if (EFlag[3] == false) {
        contactInfoObj.fieldDisable[2] = true;
        contactInfoObj.emailDisabled = true;
      }

    }
    else {
      contactInfoObj.seType = contactInfoOptions.emailItems[0];
      contactInfoObj.pEmail = EFlag[1];
      contactInfoObj.sEmail = EFlag[0];
      contactInfoObj.peType = contactInfoOptions.emailItems[1];
      if (EFlag[2] == false) {
        contactInfoObj.fieldDisable[2] = true;
        contactInfoObj.emailDisabled = true;
      }
      if (EFlag[3] == false) {
        contactInfoObj.fieldDisable[0] = true;
        contactInfoObj.emailDisabled = true;
      }
    }
  }
  else if (EFlag[0] != null) {
      contactInfoObj.seType = contactInfoOptions.emailItems[1];
      contactInfoObj.pEmail = EFlag[0];
      contactInfoObj.peType = contactInfoOptions.emailItems[0];
      if (EFlag[2] == false) {
        contactInfoObj.fieldDisable[0] = true;
        contactInfoObj.emailDisabled = true;
      } 
  }
  else if (EFlag[1] != null) {
    if (data.businessEmail) {
      contactInfoObj.fieldDisable[2] = true;
      contactInfoObj.emailDisabled = true;
      contactInfoObj.seType = contactInfoOptions.emailItems[0];
      contactInfoObj.peType = contactInfoOptions.emailItems[1];
      contactInfoObj.pEmail = EFlag[1];
    } else {
      contactInfoObj.seType = contactInfoOptions.emailItems[0];
      contactInfoObj.pEmail = EFlag[1];
      contactInfoObj.peType = contactInfoOptions.emailItems[1];
      if (EFlag[3] == false) {
        contactInfoObj.fieldDisable[0] = true;
        contactInfoObj.emailDisabled = true;
      }
    }

  }

  priorityList = priorityList.sort();
  /********************Sort according to Primary Flag START *********************************/
  const primaryPos = findPrimaryPos(valueList);

  if (primaryPos != -1) {

    let pos = priorityList.indexOf(primaryPos);

    let temp = priorityList[pos];
    priorityList.splice(pos, 1);
    priorityList.unshift(temp);

  }

  /********************Sort according to Primary Flag END *********************************/

  contactInfo.contactInfoOptions = contactInfoOptions;
  contactInfo.contactInfoObj = contactInfoObj;

  if (contactInfo.contactInfoOptions.embeddedPicEmailNotUnique) {
    contactInfo = setPhoneFieldValue("pmType", "pMobile", "ccode1", "ccodeValue1", 0, contactInfo, priorityList, valueList)
  } else if (getState.verifyUser.runAdminFlow) {
    contactInfo = setPhoneFieldValue("bmType", "bMobile", "ccode1", "ccodeValue1", 0, contactInfo, priorityList, valueList)
    contactInfo = setPhoneFieldValue("pmType", "pMobile", "ccode2", "ccodeValue2", 1, contactInfo, priorityList, valueList)
  }
  else {
    contactInfo = setPhoneFieldValue("bmType", "bMobile", "ccode1", "ccodeValue1", 0, contactInfo, priorityList, valueList)
    contactInfo = setPhoneFieldValue("pmType", "pMobile", "ccode2", "ccodeValue2", 1, contactInfo, priorityList, valueList)
    contactInfo = setPhoneFieldValue("blType", "bLandline", "ccode3", "ccodeValue3", 2, contactInfo, priorityList, valueList)
    contactInfo = setPhoneFieldValue("plType", "pLandline", "ccode4", "ccodeValue4", 3, contactInfo, priorityList, valueList)
  }
  
  /******************************Setting Phone fields END *******************************************/

  contactInfoOptions = contactInfo.contactInfoOptions;
  contactInfoObj = contactInfo.contactInfoObj;

  /******************************Setting INITIAL STATE if Null *******************************************/

  if (contactInfoObj.peType == null && contactInfoObj.seType == null) {
    contactInfoObj.peType = contactInfoOptions.emailItems[0];
    contactInfoObj.seType = contactInfoOptions.emailItems[1];
  }

  if(contactInfoOptions.embeddedPicEmailNotUnique && contactInfoObj.pmType == null) {
    contactInfoObj.pmType = contactInfoOptions.phoneItems[0];
      contactInfoObj.phoneHistory[0] = "pmType";
  }
  else if (contactInfoObj.bmType == null) {
    if (getState.verifyUser.runAdminFlow) {
      contactInfoObj.bmType = contactInfoOptions.phoneItems[1];
      contactInfoObj.phoneHistory[1] = "bmType";
    } else {
      contactInfoObj.bmType = contactInfoOptions.phoneItems[2];
      contactInfoObj.phoneHistory[2] = "bmType";
    }

  }

  if (!contactInfoObj.showSecoundaryForm) {
    if (contactInfoObj.pEmail != null && contactInfoObj.bMobile != null) {
      contactInfoObj.showSecoundaryForm = true;
      if (contactInfoObj.seType == null) {
        if (EFlag[0] != null) {
          contactInfoObj.seType = contactInfoOptions.emailItems[1];

        }
        else if (EFlag[1] != null) {
          contactInfoObj.seType = contactInfoOptions.emailItems[0];
        }
      }

      const pos = getEmptyPosition(getState);
      if (pos != -1 && contactInfoObj.pmType == null) {
        contactInfoObj.pmType = contactInfoOptions.phoneItems[pos];
        contactInfoObj.phoneHistory[pos] = "pmType";
      }


    }
  }
  else if (contactInfoObj.sEmail != null && contactInfoObj.pMobile == null) {
    const pos = getEmptyPosition(getState);
    if (pos != -1 && contactInfoObj.pmType == null) {
      contactInfoObj.pmType = contactInfoOptions.phoneItems[pos];
      contactInfoObj.phoneHistory[pos] = "pmType";
    }
  }


  /*************************************END *******************************************/
  return getContactInfoOptionsSuccess({
    contactInfoOptions: contactInfoOptions,
    contactInfoObj: contactInfoObj,
    isMobileBrowser,
    isMobile,
    gaCategory,
    isAuthorized
  });

}

const getErrorFieldIndex = (contactInfo, pos, emailType, type) => {
  const contactInfoObj = contactInfo.contactInfoObj;
  const contactInfoOptions = contactInfo.contactInfoOptions;

  if (type == 1) {

    if (contactInfoOptions.emailItems[emailType] == contactInfoObj.peType) return 0;
    else return 2;


  }
  else {
    if (contactInfoObj.phoneHistory[pos] == "bmType") return 1;
    if (contactInfoObj.phoneHistory[pos] == "pmType") return 3;
    if (contactInfoObj.phoneHistory[pos] == "blType") return 4;
    if (contactInfoObj.phoneHistory[pos] == "plType") return 5;


  }
}



const setSForm = getState =>
  setSFormToTrue(getState).pipe(map(setSecoundaryFormEnabled));

const setPField = getState =>
  setPhoneFieldEnable(getState).pipe(map(setPhoneFieldEnabled));

const formatResponse = (response) => {

  if (response.associateContactInfoCollection != null) {
    response.associateContactInfoCollection.associateContactInfo.map((value, key) => {
      if (!isBlank(value.communicationType) && !isBlank(value.contMechPurpTypeNm)) {
        if (value.communicationType == "email" && value.contMechPurpTypeNm == "business") {
          response.emailDropdown = "Business";
          response.email = value.contactInfo;
        }
        if (value.communicationType == "email" && value.contMechPurpTypeNm == "personal") {
          response.emailDropdown1 = "Secondary";
          response.semail = value.contactInfo;
          response.showSecoundaryForm = true;
        }

      }
    })
  }

  return response;
}

const validateAndSetContactField = (payload, getState) => {


  const contactInfoOptions = getState.contactInfo.contactInfoOptions;

  const contactInfoObj = Object.assign({}, getState.contactInfo.contactInfoObj)

  switch (payload.fieldkey) {

    /*************************************Field Set  start case 0 *******************************************/
    case constants.contactInfoIds.fieldSet: {

      const regEx = new RegExp(contactInfoOptions[payload.fieldValue.validationPolicy]);
      if (isBlank(payload.fieldValue.value) || regEx.test(payload.fieldValue.value)) {

        contactInfoObj[payload.fieldValue.fieldName] = payload.fieldValue.value;
        contactInfoObj[payload.fieldValue.fieldValid] = true;
        contactInfoObj.fieldValid[payload.fieldValue.errorIndex] = true;
        return of({
          contactInfoObj
        });
      }
      return throwError(new Error(contactInfoOptions[payload.fieldValue.invalidMessage]));
    }

    /*************************************Field Set  end  *******************************************/

    case constants.contactInfoIds.phoneSet: {
      if (!isBlank(payload.fieldValue.formattedNumber)) {
        contactInfoObj[payload.fieldValue.fieldName] = payload.fieldValue.formattedNumber;
        contactInfoObj[payload.fieldValue.fieldValid] = payload.fieldValue.isValid;
        contactInfoObj[payload.fieldValue.ccodeValueField] = payload.fieldValue.iso2;
        contactInfoObj[payload.fieldValue.ccodeField] = payload.fieldValue.ccode;
        contactInfoObj.fieldValid[payload.fieldValue.errorIndex] = payload.fieldValue.isValid;


      }
      else {
        contactInfoObj[payload.fieldValue.fieldName] = null;
        contactInfoObj[payload.fieldValue.fieldValid] = true;
        contactInfoObj[payload.fieldValue.ccodeValueField] = null;
        contactInfoObj.fieldValid[payload.fieldValue.errorIndex] = true;
        contactInfoObj[payload.fieldValue.radioField] = null;

      }

      return of({
        contactInfoObj
      });
    }







    /*************************************Country Code Start case 1 *******************************************/
    case constants.contactInfoIds.cCode: {
      contactInfoObj[payload.fieldValue.fieldName] = payload.fieldValue.value;
      return of({
        contactInfoObj: contactInfoObj
      });
    }

    /*************************************Country Code end *******************************************/

    case constants.contactInfoIds.emailTypeSet: {
      contactInfoObj[payload.fieldValue.fieldName2] = contactInfoObj[payload.fieldValue.fieldName1];
      contactInfoObj[payload.fieldValue.fieldName1] = payload.fieldValue.value;
      return of({
        contactInfoObj
      });

    }
    /*************************************email type  end *******************************************/

    case constants.contactInfoIds.mobileTypeSet: {

      /*************************************check disabled checkbox  *******************************************/
      const pos = contactInfoOptions.phoneItems.indexOf(payload.fieldValue.value);

      const Obj = getState.contactInfo.contactInfoObj;


      if (Obj.phoneHistoryDisable[pos]) {
        return of();
      }
      /*************************************End *******************************************/

      const contactInfoObj = smartSwitchDropDown(getState, payload.fieldValue.value, payload.fieldValue.fieldName);
      contactInfoObj[payload.fieldValue.fieldName] = payload.fieldValue.value;
      return of({

        contactInfoObj
      });

    }

    /*************************************Mobile type  end *******************************************/

    case constants.contactInfoIds.radioSet: {
      let phoneValue = contactInfoObj[payload.fieldValue.phoneField];
      if (phoneValue == undefined || phoneValue == null)
        return of();

      contactInfoObj[payload.fieldValue.fieldName] = payload.fieldValue.value;
      return of({
        contactInfoObj: contactInfoObj
      });

    }

    case constants.contactInfoIds.extnSet: {
      let num = null;
      if (!isBlank(payload.fieldValue.value))
        num = payload.fieldValue.value.replace(/\D/g, '');
      contactInfoObj[payload.fieldValue.fieldName] = num;
      return of({
        contactInfoObj: contactInfoObj
      });

    }

    default: {
      return throwError(new Error('somethig went wrong'));
    }
  }
};

const findPrimaryPos = (valueList) => {

  let pos = -1;
  valueList.map((value, key) => {
    if (value != null) {

      if (value.primary == true) pos = key;
    }
  });

  return pos;
}


const setPhoneFieldValue = (fieldName, textName, cc, ccValue, pos, contactInfo, priorityList, valueList) => {

  const contactInfoObj = contactInfo.contactInfoObj;
  const contactInfoOptions = contactInfo.contactInfoOptions;

  if (priorityList[pos] != 5) {

    const index = priorityList[pos];
    const value = valueList[index];
    contactInfoObj[textName] = value.field;
    contactInfoObj[cc] = value.countrycode;
    contactInfoObj[ccValue] = value.countrycodeValue;

    if (index == 0) {
      contactInfoObj[fieldName] = contactInfoOptions.phoneItems[0];
    }

    if (index == 1) {
      contactInfoObj[fieldName] = contactInfoOptions.phoneItems[1];
    }

    if (index == 2) {
      contactInfoObj[fieldName] = contactInfoOptions.phoneItems[2];
    }


    if (index == 3) {
      contactInfoObj[fieldName] = contactInfoOptions.phoneItems[3];
    }
    contactInfoObj.phoneHistory[index] = fieldName;
    if (pos == 1) {
      contactInfoObj.showSecoundaryForm = true;
    }
    if (pos == 0 && value.enabled == false) {
      contactInfoObj.fieldDisable[1] = true;
      contactInfoObj.phoneHistoryDisable[index] = true;
    }
    if (pos == 1 && value.enabled == false) {
      contactInfoObj.fieldDisable[3] = true;
      contactInfoObj.phoneHistoryDisable[index] = true;
    }
    if (pos == 2) {
      contactInfoObj.phoneCount = 1;
      contactInfoOptions.bLandlineRenderable = true;
      if (value.enabled == false) {
        contactInfoObj.fieldDisable[4] = true;
        contactInfoObj.phoneHistoryDisable[index] = true;
      }
    }
    if (pos == 3) {
      contactInfoObj.phoneCount = 0;
      contactInfoOptions.pLandlineRenderable = true;
      if (value.enabled == false) {
        contactInfoObj.fieldDisable[5] = true;
        contactInfoObj.phoneHistoryDisable[index] = true;
      }
    }

  }
  return contactInfo;
}


const getHistoryPosition = (getState, key) => {


  const phoneHistory = getState.contactInfo.contactInfoObj.phoneHistory;
  if (phoneHistory[key] == null) {

    return -1;

  }
  else {
    return key;
  }

}

const smartSwitchDropDown = (getState, fieldValue, pType) => {
  const contactInfo = Object.assign({}, getState.contactInfo.contactInfoObj);
  const contactInfoOptions = Object.assign({}, getState.contactInfo.contactInfoOptions);
  const pos = getHistoryPosition(getState, contactInfoOptions.phoneItems.indexOf(fieldValue));
  if (pos != -1) {
    contactInfo[contactInfo.phoneHistory[pos]] = contactInfo[pType];
    contactInfo.phoneHistory[contactInfo.phoneHistory.indexOf(pType)] = contactInfo.phoneHistory[pos];
    contactInfo.phoneHistory[pos] = pType;

  }
  else {
    const existingPos = contactInfo.phoneHistory.indexOf(pType);
    if (existingPos != -1) {
      contactInfo.phoneHistory[existingPos] = null;
    }
    contactInfo.phoneHistory[contactInfoOptions.phoneItems.indexOf(fieldValue)] = pType;
  }

  return contactInfo;

}

const getEmptyPosition = (getState) => {

  const phoneHistory = getState.contactInfo.contactInfoObj.phoneHistory;

  for (var i = 0; i < phoneHistory.length; i++) {
    if (phoneHistory[i] == null) {
      return i;
    }
  }
  return -1;
}
const setSFormToTrue = (getState) => {
  const pos = getEmptyPosition(getState);
  const contactInfo = Object.assign({}, getState.contactInfo.contactInfoObj);
  const contactInfoOptions = getState.contactInfo.contactInfoOptions;
  const pmType = contactInfo.pmType;
  if (pos != -1 && pmType == null) {

    contactInfo.pmType = contactInfoOptions.phoneItems[pos];
    contactInfo.phoneHistory[pos] = "pmType";
  }

  if (contactInfo.seType == null) {
    if (contactInfo.peType == contactInfoOptions.emailItems[0]) {
      contactInfo.seType = contactInfoOptions.emailItems[1];
    }
    else contactInfo.seType = contactInfoOptions.emailItems[0];
  }

  contactInfo.showSecoundaryForm = true;

  return of({

    contactInfoObj: contactInfo

  });

};

const setPhoneFieldEnable = (getState) => {

  const contactInfoOptions = Object.assign({}, getState.contactInfo.contactInfoOptions);
  const contactInfo = Object.assign({}, getState.contactInfo.contactInfoObj);

  var count = contactInfo.phoneCount;

  count = count - 1;
  contactInfo.phoneCount = count;
  if (count == 0) {
    contactInfoOptions.pLandlineRenderable = true;
    const pos = getEmptyPosition(getState);
    if (pos != -1 && contactInfo.plType == null) {

      contactInfo.plType = contactInfoOptions.phoneItems[pos];
      contactInfo.phoneHistory[pos] = "plType";
    }

  }
  else if (count == 1) {
    contactInfoOptions.bLandlineRenderable = true;
    const pos = getEmptyPosition(getState);
    if (pos != -1 && contactInfo.blType == null) {

      contactInfo.blType = contactInfoOptions.phoneItems[pos];
      contactInfo.phoneHistory[pos] = "blType";
    }

  }
  return of({
    contactInfoObj: contactInfo,
    contactInfoOptions

  });

};


const handleSetContactFieldFailResponse = (getState, payload, errorMessage) => {

  const contactInfoObj = Object.assign({}, getState.contactInfo.contactInfoObj)


  switch (payload.fieldkey) {

    /*************************************Failed  start case 0 *******************************************/


    case constants.contactInfoIds.fieldSet:


      contactInfoObj[payload.fieldValue.fieldName] = payload.fieldValue.value;
      contactInfoObj[payload.fieldValue.fieldValid] = false;
      contactInfoObj.errorMessage[payload.fieldValue.errorIndex] = errorMessage;
      contactInfoObj.fieldValid[payload.fieldValue.errorIndex] = false;


      return setContactFieldFailSent({
        errorMessage,
        contactInfoObj

      });

    /*************************************Failed end case 0 *******************************************/

    default: {
      return setSivFieldFailSent({
        errorMessage,
      });
    }
  }
};

const personalPhoneInfo = (ajax, getState) => {
  return getPersonalPhoneInfoApi(ajax, getState)
    .pipe(map(response => handleResponsePersonalPhone(response.response, getState)),
      catchError(err => [getContactInfoOptionsFail(getErrorMessage(err))]));
}

const handleResponsePersonalPhone = (response, getState) => {
  let contactInfoObj = Object.assign({}, getState.contactInfo.contactInfoObj)
  contactInfoObj.showAlert = false;
  contactInfoObj.prcValid = true;
  let showModalError = false;

  if (response.expiry !== undefined) {
    contactInfoObj.showVerify = true;
    contactInfoObj.showOnScreen = response.showOnScreen;
    contactInfoObj.accessCode = response.accessCode;
    contactInfoObj.expiry = response.expiry;
    contactInfoObj.selectedIdentityValue = response.maskedValue;
    return onSetFinalSubmit({
      contactInfoObj
    });

  }
  else {
    showModalError = true;
    contactInfoObj.showModalMessage = response.message;
    contactInfoObj.showModalTitle = response.title;

    return onSetFinalSubmit({
      contactInfoObj, showModalError
    });
  }

}

const setSubmitAccessCode = (ajax, getState) => {
  let inputPRC = getState.contactInfo.otpCode;
  if (getState.contactInfo.isOTPFormValid != undefined && getState.contactInfo.isOTPFormValid == false)
    return of();
  return setSubmitAccessCodeApi(ajax, inputPRC, getState)
    .pipe(map(response => handleResponseAccessCode(response.response, getState)),
      catchError(err => [getContactInfoOptionsFail(getErrorMessage(err))]));
}

const handleResponseAccessCode = (response, getState) => {
  let contactInfoObj = Object.assign({}, getState.contactInfo.contactInfoObj);

  if (response.code == constants.TYPE_SUCCESS) {
    return onSetFinalSubmit({
      viewId: response.viewId,
      sendRedirect: true
    });

  }
  else {
    if (response.code == constants.TYPE_ERROR_INVALID || response.code == constants.TYPE_ERROR_EXPIRED) {
      contactInfoObj.showAlert = true;
      contactInfoObj.prcValid = false;
      contactInfoObj.showAlertMessage = response.message;

      return onSetFinalSubmit({
        contactInfoObj
      });
    }
    else {
      let showModalError = true;
      contactInfoObj.showModalMessage = response.message;
      contactInfoObj.showModalTitle = response.title;

      return onSetFinalSubmit({
        contactInfoObj, showModalError
      });
    }
  }

}

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId
  if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const setContactField = (payload, getState) => validateAndSetContactField(payload, getState).pipe(
  map(setContactFieldSuccessSent),
  catchError(err => [handleSetContactFieldFailResponse(getState, payload, getErrorMessage(err))]),
);

export const getContactInfoEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CONTACT_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getContactInfo(ajax, state.value))),
);

export const setContactFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_CONTACT_FIELD),
  switchMap(action => sendingAsyncRequest(setContactField(action.payload, state.value))),
);

export const setSecoundaryFormEpic = (action$, state) => action$.pipe(
  ofType(ENABLE_SECOUNDARY_FORM),
  switchMap(action => sendingAsyncRequest(setSForm(state.value))),
);

export const setPhoneFieldEpic = (action$, state) => action$.pipe(
  ofType(ENABLE_PHONE_FIELD),
  switchMap(action => sendingAsyncRequest(setPField(state.value))),
);

export const onFinalSubmitEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CONTACT_FINAL_SUBMIT),
  switchMap(action => sendingAsyncRequest(setFinalSubmit(ajax, state.value))),
);

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_CONTACT_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);

export const onSetFinalSubmitEpic = (action$, state) => action$.pipe(
  ofType(ON_FINAL_CONTACT_INFO_SUCCESS),
  switchMap(action$ => {
    state.value.contactInfo.showModalInfo = false;
    state.value.contactInfo.showModalWarn = false;
    state.value.contactInfo.showModalError = false;
    ReactGA.sendevent(state.value.contactInfo.gaCategory, 'CONTACT_INFO', 'SUCCESS');
    return of(push(state.value.contactInfo.viewId))
  }),
);

export const getContactInfoOptionsFailureEpic = action$ => action$.pipe(
  ofType(CONTACT_INFO_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))),
);

export const personalPhoneInfoEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(PERSONAL_PHONE_INFO),
  switchMap(action => sendingAsyncRequest(personalPhoneInfo(ajax, state.value))),
);

export const setSubmitAccessCodeEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(CONTACT_INFO_ACCESS_CODE_SENT),
  switchMap(action => sendingAsyncRequest(setSubmitAccessCode(ajax, state.value))));