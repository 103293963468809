export const greenTextColor = '#115F11';
export const redTextColor = '#940a0a';
export const infoPromptsTextColor = '#4a4a4a';
export const greenBarColor = '#51dc51';
export const redBarColor = '#D40f0f';//#940a0a
export const orangeBarColor = '#ffb400';
export const backgroundBarColor = '#e4e4e4';
export const strongest = 'strongest';
export const veryStrong = 'veryStrong';
export const strong = 'strong';
export const good = 'good';
export const veryStrongUC = 'veryStrong_UC';
export const veryStrongLC = 'veryStrong_LC';
export const veryStrongSC = 'veryStrong_SC';
export const veryStrong12 = 'veryStrong_12';
export const strongUC = 'strong_UC';
export const strongLC = 'strong_LC';
export const strongSC = 'strong_SC';
export const strongUCSC = 'strong_UC&SC';
export const strongLCSC = 'strong_LC&SC';
export const goodUCSC = 'good_UC&SC';
export const goodLCSC = 'good_LC&SC';
export const min1number = 'min1number';
export const min1letter = 'min1letter';
export const min8char = 'min8char';
export const min1number1letter = 'min1number_1letter';
export const min8char1number = 'min8char_1number';
export const min8char1letter = 'min8char_1letter';
export const min8char1number1letter = 'min8char_1number_1letter';
