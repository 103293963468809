import React, { Component } from 'react';
import classNames from 'classnames';
import { findIndex } from 'lodash/array';
import countryData from './country_data.js';
import { some, find, map, filter, includes } from 'lodash/collection';
import ReactDOM from 'react-dom';
import { debounce, memoize } from 'lodash/function';
import { startsWith } from 'lodash/string';
import {
  Popover,
  OverlayTrigger,
} from '@synerg/vdl-react-components';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';

let allCountries = countryData.allCountries;
let dropDownWidth = null;
let isModernBrowser = Boolean(document.createElement('input').setSelectionRange);

const isBlank = str => (str || '').trim().length === 0;

var style = require('./email-phone-input-style.scss');

let keys = {
  UP: 38,
  DOWN: 40,
  RIGHT: 39,
  LEFT: 37,
  ENTER: 13,
  ESC: 27,
  PLUS: 43,
  A: 65,
  Z: 90,
  SPACE: 32
};

function getOnlyCountries(onlyCountriesArray) {

  if (onlyCountriesArray.length === 0) {
    return allCountries;
  } else {
    let selectedCountries = [];
    allCountries.map(function (country) {
      onlyCountriesArray.map(function (selCountry) {
        if (country.iso2 === selCountry) {
          selectedCountries.push(country);
        }
      });
    });
    return selectedCountries;
  }
}

function excludeCountries(selectedCountries, excludedCountries) {
  if (excludedCountries.length === 0) {
    return selectedCountries;
  } else {
    return filter(selectedCountries, function (selCountry) {
      return !includes(excludedCountries, selCountry.iso2);
    });
  }
}

class EmailPhoneInput extends Component {


  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  constructor(props) {
    super(props);
    let showAutoFocus = this.props.showAutoFocus || false;
    let iso = this.props.defaultCountry;
    let onlyCountries = excludeCountries(getOnlyCountries(props.onlyCountries), props.excludeCountries);
    let preferredCountries = filter(allCountries, (country) => {
      return some(this.props.preferredCountries, (preferredCountry) => {
        return preferredCountry === country.iso2;
      });
    });
    let selectedCountry = find(onlyCountries, { iso2: iso });
    if (selectedCountry == undefined) {
      selectedCountry = find(onlyCountries, { iso2: 'us' });
      inputNumber = '';
    }

    let dialCode = selectedCountry.dialCode;

    let selectedCountryIndex = findIndex(allCountries, selectedCountry);

    this.handleFlagDropdownClick = this.handleFlagDropdownClick.bind(this);
    this.handleFlagItemClick = this.handleFlagItemClick.bind(this);
    this.getElement = this.getElement.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._moveHighlight = this._moveHighlight.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
	this.handleCountryCdKeyDown = this.handleCountryCdKeyDown.bind(this);
    this._getHighlightCountryIndex = this._getHighlightCountryIndex.bind(this);
    this._searchCountry = this._searchCountry.bind(this);
    this.searchCountry = this.searchCountry.bind(this);
    this.getFieldDetails = this.getFieldDetails.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onChangeResponse = this.onChangeResponse.bind(this);
    this.state = {
      emailRegEx: props.emailRegEx,
      showAutoFocus,
      phoneRegEx: props.phoneRegEx,
      placeholder: props.placeholder,
      onlyCountries: onlyCountries,
      preferredCountries: preferredCountries,
      selectedCountry: selectedCountry,
      dialCode: dialCode,
      highlightCountryIndex: selectedCountryIndex,
      showDropDown: false,
      debouncedQueryStingSearcher: debounce(this.searchCountry, 300),
      type: "email",
      errorMessage: props.errorMessage,
      value: '',
      isValid: true,
      queryString: ''


    };
  }

  onChangeResponse() {
    let response = {};
    response.type = this.state.type;
    response.value = this.state.value;
    response.isValid = this.state.isValid;

    // preparing phone value
    if (this.state.type == "phone") {
      response.dialCode = this.state.selectedCountry.dialCode;
      response.iso = this.state.selectedCountry.iso2;
      let code = response.dialCode;
      let initialPos = code.length + 2;
      let value = this.state.value.substr(initialPos, this.state.value.length);
      response.phoneValue = value.replace(/\D/g, '');
    }
    else {
      response.phoneValue = null;
      response.dialCode = null;
      response.iso = null;
    }
    return response;
  }


  // put the cursor to the end of the input (usually after a focus event)
  _cursorToEnd() {
    let input = ReactDOM.findDOMNode(this.refs.numberInput);

    input.focus();
    if (isModernBrowser) {
      let len = input.value.length;
      input.setSelectionRange(len, len);
    }
  }

  getElement(index) {
    return ReactDOM.findDOMNode(this.refs[`flag_no_${index}`]);
  }

  scrollTo(country, middle) {
    if (!country) return;

    let container = ReactDOM.findDOMNode(this.refs.flagDropdownList);

    if (!container) return;

    let containerHeight = container.offsetHeight;
    let containerOffset = container.getBoundingClientRect();
    let containerTop = containerOffset.top + document.body.scrollTop;
    let containerBottom = containerTop + containerHeight;

    let element = country;
    let elementOffset = element.getBoundingClientRect();

    let elementHeight = element.offsetHeight;
    let elementTop = elementOffset.top + document.body.scrollTop;
    let elementBottom = elementTop + elementHeight;
    let newScrollTop = elementTop - containerTop + container.scrollTop;
    let middleOffset = (containerHeight / 2) - (elementHeight / 2);

    if (elementTop < containerTop) {
      // scroll up
      if (middle) {
        newScrollTop -= middleOffset;
      }
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      var heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  }

  handleClickOutside(e) {
    var target = e.target;
    var id = ReactDOM.findDOMNode(this.refs.flagDropDownButton);
    if (id != undefined && !id.contains(target)) {
      this.setState({ showDropDown: false });
      document.removeEventListener('click', this.handleClickOutside, false);

    }
  }
  handleFlagItemClick(country) {
    let currentSelectedCountry = this.state.selectedCountry;
    let nextSelectedCountry = find(this.state.onlyCountries, country);

    if (currentSelectedCountry.iso2 !== nextSelectedCountry.iso2) {
      let ccode = nextSelectedCountry.dialCode;
      let oldCode = currentSelectedCountry.dialCode;
      let initialPos = oldCode.length + 2;
      let value = this.state.value.substr(initialPos, this.state.value.length);
      value = "+" + ccode + " " + value;

      this.setState({
        showDropDown: false,
        dialCode: nextSelectedCountry.dialCode,
        selectedCountry: nextSelectedCountry,
        value: value
      }, function () {
        this._cursorToEnd();
        if (this.props.onChange) {

          this.props.onChange(this.onChangeResponse());
        }
      });
    }

  }

  searchCountry() {
    const probableCandidate = this._searchCountry(this.state.queryString) || this.state.onlyCountries[0];
    const probableCandidateIndex = findIndex(this.state.onlyCountries, probableCandidate) +
      this.state.preferredCountries.length;

    this.scrollTo(this.getElement(probableCandidateIndex), true);

    this.setState({
      queryString: '',
      highlightCountryIndex: probableCandidateIndex
    });
  }

  _getHighlightCountryIndex(direction) {

    // had to write own function because underscore does not have findIndex. lodash has it
    var highlightCountryIndex = this.state.highlightCountryIndex + direction;

    if (highlightCountryIndex < 0
      || highlightCountryIndex >= (this.state.onlyCountries.length + this.state.preferredCountries.length)) {
      return highlightCountryIndex - direction;
    }

    return highlightCountryIndex;
  }

  _moveHighlight(direction) {
    this.setState({
      highlightCountryIndex: this._getHighlightCountryIndex(direction)
    }, () => {
      this.scrollTo(this.getElement(this.state.highlightCountryIndex), true);
    });
  };

  handleCountryCdKeyDown(event) {
    if (event.which == keys.ENTER) {
    this.setState({ showDropDown: true });
    this._moveHighlight(0);
    }
  }
  
  handleKeydown(event) {
    if (!this.state.showDropDown) {
      return;
    }

    // ie hack
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }



    switch (event.which) {
      case keys.DOWN:
        this._moveHighlight(1);
        break;
      case keys.UP:
        this._moveHighlight(-1);
        break;
      case keys.ENTER:
        this.handleFlagItemClick(this.state.onlyCountries[this.state.highlightCountryIndex], event);
        break;
      case keys.ESC:
        this.setState({ showDropDown: false }, this._cursorToEnd);
        break;
      default:
        if ((event.which >= keys.A && event.which <= keys.Z) || event.which === keys.SPACE) {
          this.setState({
            queryString: this.state.queryString + String.fromCharCode(event.which)
          }, this.state.debouncedQueryStingSearcher);
        }
    }
  }


  handleFlagDropdownClick(event) {
    document.addEventListener('click', this.handleClickOutside, false);
    // need to put the highlight on the current selected country if the dropdown is going to open up
    this.setState({
      showDropDown: !this.state.showDropDown,
      highlightCountry: find(this.state.onlyCountries, this.state.selectedCountry),
      highlightCountryIndex: findIndex(this.state.onlyCountries, this.state.selectedCountry)
    }, () => {
      if (this.state.showDropDown) {
        let countrySize = this.state.onlyCountries.length;
        let currentIndex = this.state.highlightCountryIndex;
        if (countrySize - currentIndex <= 6) {
          this.scrollTo(this.getElement(countrySize - 1));

        } else {
          //this.scrollTo(this.getElement(this.state.highlightCountryIndex + this.state.preferredCountries.length + 5));
          let container = ReactDOM.findDOMNode(this.refs.flagDropdownList);
          let country = this.getElement(currentIndex);
          let containerOffset = container.getBoundingClientRect();
          let containerTop = containerOffset.top + document.body.scrollTop;
          let element = country;
          let elementOffset = element.getBoundingClientRect();

          let elementTop = elementOffset.top + document.body.scrollTop;
          let newScrollTop = elementTop - containerTop + container.scrollTop;
          container.scrollTop = newScrollTop;
        }
      }
    });

  }

  getFieldDetails(value, caretPosition) {
    let fieldData = {};
    fieldData.caretPosition = caretPosition;
    fieldData.value = value;
    fieldData.valueChanged = true;
    fieldData.fieldValid = false;
    fieldData.type = "email";
    const emailRegEx = new RegExp(this.state.emailRegEx);
    const phoneRegEx = this.state.phoneRegEx;
    let defaultSelectedCountry = find(this.state.onlyCountries, { iso2: this.props.defaultCountry });
    fieldData.currentSelectedCountry = this.state.selectedCountry;
    if (value.length == 0) {
      fieldData.currentSelectedCountry = defaultSelectedCountry;
      return fieldData;
    }

    if (this.state.type == "phone") {


      // freezing +
      let ccode = this.state.selectedCountry.dialCode;
      let pos = ccode.length + 1;
      if (value[0] != "+") {
        if (value[1] == "+") {
          let userValue = value[0] + value.slice(pos + 2);
          if (phoneRegEx.test(userValue)) {
            fieldData.value = '+' + ccode + " " + userValue;
            fieldData.type = "phone";
            fieldData.fieldValid = true;
            fieldData.caretPosition = caretPosition + ccode.length + 2;
            return fieldData;
          }
          else {
            fieldData.value = userValue;
            fieldData.type = "email";
            return fieldData;
          }
        }
        else {

          fieldData.valueChanged = false;
          fieldData.type = "phone";
          fieldData.value = '+' + value;
          fieldData.fieldValid = true;
          return fieldData;
        }
      }
      else if (ccode != value.slice(1, pos)) {
        // update value not matching with ccode
        fieldData.valueChanged = false;
        fieldData.type = "phone";
        fieldData.value = this.state.value;
        fieldData.fieldValid = true;
        return fieldData;
      }
      //freezing space after ccode
      if (value[pos] != " ") {
        if (value[pos + 1] == " ") {
          let userValue = value[pos] + value.slice(pos + 2);
          if (phoneRegEx.test(userValue)) {
            fieldData.value = '+' + ccode + " " + userValue;
            fieldData.type = "phone";
            fieldData.caretPosition = caretPosition + 1;
            fieldData.fieldValid = true;
            return fieldData;
          }
          else {
            fieldData.value = userValue;
            fieldData.caretPosition = caretPosition - (ccode.length + 1);
            return fieldData;
          }
        }
        else {
          fieldData.valueChanged = false;
          fieldData.value = '+' + ccode + " " + value.slice(pos);
          fieldData.type = "phone";
          fieldData.fieldValid = true;
          return fieldData;
        }
      }

      else if (!phoneRegEx.test(value) || value.length <= 3) {
        fieldData.value = value.slice(pos + 1);
        fieldData.caretPosition = caretPosition - (ccode.length + 2);
        return fieldData;

      }


    }

    if (!isBlank(value) && phoneRegEx.test(value) && value.length > 3 && this.state.type == "phone") {
      fieldData.fieldValid = true;
      fieldData.type = "phone";
      let ccode = this.state.selectedCountry.dialCode;
      let pos = ccode.length + 1;
      if (value[0] == "+") {
        let phoneValue = value.slice(pos + 1);
        //handling invalid entered data
        if (!phoneRegEx.test(phoneValue)) {
          fieldData.fieldValid = false;
          fieldData.type = "email";
          fieldData.value = phoneValue;
          fieldData.caretPosition = caretPosition - (ccode.length + 2);

          return fieldData;
        }
        else if (phoneValue.length <= 3) {
          fieldData.fieldValid = false;
          fieldData.type = "email";
          fieldData.value = phoneValue;
          fieldData.caretPosition = caretPosition - (ccode.length + 2);

          return fieldData;

        }
        fieldData.value = '+' + ccode + " " + phoneValue;
      }
      else if (value.length <= 3) {
        fieldData.value = value.slice(pos);
        fieldData.type = "email";
        fieldData.fieldValid = false;
        return fieldData;

      }

    }

    else if (!isBlank(value) && phoneRegEx.test(value) && value.length > 3) {
      fieldData.fieldValid = true;
      fieldData.type = "phone";
      let ccode = this.state.selectedCountry.dialCode;
      // fresh data
      fieldData.caretPosition = caretPosition + ccode.length + 2;
      if (value[0] == "+") {
        fieldData.value = '+' + ccode + " " + value.slice(1, value.length);
      }
      else {
        fieldData.value = '+' + ccode + " " + value;
      }
    }
    else if (!isBlank(value) && emailRegEx.test(value)) {
      fieldData.fieldValid = true;
      fieldData.type = "email";
    }
    return fieldData;
  }



  handleInput(event) {
    let inputValue = null;
    let caretPosition = event.target.selectionStart;
    let currentDialCode = this.state.selectedCountry.dialCode;



    if (event.target.value != undefined || event.target.value != null) {
      inputValue = event.target.value;

      if (inputValue.length != 0 && this.state.type == "phone") {
        if (caretPosition <= currentDialCode.length + 1) {
          return;
        }
      }

      let data = this.getFieldDetails(inputValue, caretPosition);
      if (data != null) {
        this.setState({
          value: data.value,
          isValid: data.fieldValid,
          type: data.type,
          selectedCountry: data.currentSelectedCountry
        }, function () {
          if (isModernBrowser) {

            let input = ReactDOM.findDOMNode(this.refs.numberInput);
            input.focus();
            input.setSelectionRange(data.caretPosition, data.caretPosition);

          }

          if (this.props.onChange && data.valueChanged) {
            this.props.onChange(this.onChangeResponse());
          }
        });
      }
    }

  }

  getCountryDropDownList() {
    let countryDropDownList = map(this.state.preferredCountries.concat(this.state.onlyCountries), (country, index) => {

      let itemClasses = classNames({
        country: true,
        preferred: country.iso2 === 'us' || country.iso2 === 'gb',
        active: country.iso2 === 'us',
        highlight: this.state.highlightCountryIndex === index
      });

      return (
        <li
          ref={`flag_no_${index}`}
          key={`flag_no_${index}`}
          id={`flag_no_${index}`}
          data-flag-key={`flag_no_${index}`}
          className={itemClasses}
          data-dial-code="1"
          data-country-code={country.iso2}
          onClick={this.handleFlagItemClick.bind(this, country)}>
          <span className='country-name'>{country.name}</span>
          <span className='dial-code'>{'+' + country.dialCode}</span>
        </li>
      );
    });

    const dashedLi = (<li key={'dashes'} className='divider' />);
    // let's insert a dashed line in between preffered countries and the rest
    countryDropDownList.splice(this.state.preferredCountries.length, 0, dashedLi);

    const dropDownClasses = classNames({
      'country-list': true,
      'hide': !this.state.showDropDown
    });
    dropDownWidth = document.getElementById(this.props.id + "-textId").offsetWidth;

    return (
      <ul ref="flagDropdownList" className={dropDownClasses} style={{ width: dropDownWidth }}>
        {countryDropDownList}
      </ul >
    );
  }
  render() {
    const errorPopover = () =>
      (this.state.isValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
      />) ||
      (!this.state.isValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          {this.state.errorMessage}
        </Popover>
      ));


    let flagViewClasses = classNames({
      "flag-dropdown": true,
      "open-dropdown": this.state.showDropDown
    });

    let flagClasses = classNames({
      "selected-flag": true,
    });

    let arrowClasses = classNames({
      "arrow": true,
      "up": this.state.showDropDown
    });

    let fieldClasses = classNames({
      "validation-error": !this.state.isValid
    });

    let inputFlagClasses = `flag ${this.state.selectedCountry.iso2}`;

    return (

      <div>
        <div className="email-phone-input" >
          {/*********** Phone Field Start ****************/}
          {this.state.type == "phone" && (
            <OverlayTrigger
              trigger={'click'}
              placement="bottom" isAriaExpanded={ false }
              overlay={errorPopover()}
            >
              <input aria-label={this.props.intl.formatMessage({ id: 'findMe.emailPhone.placeholder' })}
                onChange={this.handleInput} autocomplete="tel"
                placeholder={this.state.placeholder}
                value={this.state.value}
                ref="numberInput"
                type="tel"
                id={this.props.id + "-textId"}
                className={fieldClasses}
                autoFocus={this.state.showAutoFocus}
              />
            </OverlayTrigger>
          )}
          {/*********** Phone Field End ****************/}

          {/*********** Email Field Start ****************/}
          {this.state.type == "email" && (
            <OverlayTrigger
              trigger={'click'} isAriaExpanded={ false }
              placement="bottom"
              overlay={errorPopover()}
            >
              <input aria-label={this.props.intl.formatMessage({ id: 'findMe.emailPhone.placeholder' })}
                onChange={this.handleInput} autocomplete="email"
                value={this.state.value}
                placeholder={this.state.placeholder}
                ref="numberInput"
                type="text"
                id={this.props.id + "-textId"}
                className={fieldClasses}
                autoFocus={this.state.showAutoFocus}
              />
            </OverlayTrigger>
          )}
          {/*********** Email Field End ****************/}


          {this.state.type == "phone" && (<div ref="flagDropDownButton" className={flagViewClasses} tabIndex="0" onKeyPress={this.handleCountryCdKeyDown} >
            <div ref='selectedFlag' id={this.props.flagId} role="combobox" onKeyPress={this.handleKeydown}
              onClick={this.handleFlagDropdownClick}
              className={flagClasses}
              aria-label={`${this.state.selectedCountry.name}: + ${this.state.selectedCountry.dialCode}`}>
              <div className={inputFlagClasses}>
              <span className="iso2-class">{this.state.selectedCountry.iso2}</span><div className={arrowClasses}></div>
              </div>
            </div>
            {this.state.showDropDown ? this.getCountryDropDownList() : ''}
          </div>)}


        </div>

      </div >

    )
  }


}

EmailPhoneInput.prototype._searchCountry = memoize(function (queryString) {
  if (!queryString || queryString.length === 0) {
    return null;
  }
  // don't include the preferred countries in search
  let probableCountries = filter(this.state.onlyCountries, function (country) {
    return startsWith(country.name.toLowerCase(), queryString.toLowerCase());
  }, this);
  return probableCountries[0];
});

EmailPhoneInput.defaultProps = {
  value: '',
  validationRule: '',
  placeholder: "",
  onlyCountries: [],
  excludeCountries: [],
  errorMessage: "Enter a valid value.",
  defaultCountry: 'us',
  emailRegEx: "[A-Za-z0-9!#$%&'*+/=?^_`{|}~\\-ÀàÈèÌìÒòÙù]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~\\-ÀàÈèÌìÒòÙù]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9\\-]*[A-Za-z0-9])?\\.)+[A-Za-z][A-Za-z0-9](?:[A-Za-z0-9\\-]*[A-Za-z0-9])?",
  phoneRegEx: /^(?=.*[0-9])[- +/()0-9]+$/
};


export default injectIntl(EmailPhoneInput);

