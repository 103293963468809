export const constants = {
  getuserIdOptionsEndPoint : '/pages/sms/ess/v3/ws/rest/pub/ssr/registration/initData',
  postUserIdPswOptionsEndPoint : '/pages/sms/ess/v3/ws/rest/pub/ssr/registration/save',
  checkuserIdAvailabilityEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/registration/uidAvailability',
  registerOptionIds: {
    userid: 0,
    partialuserid:1,
    password: 2,
    confirmpassword:3,
    firstquestion:4,
    secondquestion:5,
    thirdquestion:6,
    firstanswer:7,
    secondanswer:8,
    thirdanswer:9
  },
  ERROR: 'Error',
  REGISTRATION_FAILED: 'REGISTRATION_FAILED',
  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE',
  CLIENT_SETUP_ERROR: 'CLIENT_SETUP_ERROR',
  INVALID_REG_DATA: 'INVALID_REG_DATA',
  securityQnA : '/securityqna',
  confirmationPage: '/confirmation',
  validUserId: 'VALID_USERID',
  invalidUserId: 'INVALID_USERID',
  TROUBLE_SHOOT_MSG: 'something went wrong',
  fullUserIdType: 'fully_selectable',
  partialUserIdType: 'partial_selectable',
  sysgeneratedUserIdType: 'system_generated',
  SecurityAnsRegex:'[^\\w \\-\\r\\n]{2}|(?:[\\w \\-]+[^\\w \\-\\r\\n]){5}',
  PATHREGISTRATION: '/ssr/CPC/registration',
  PATHSEQCURITYQA: '/ssr/CPC/securityqna',
  checkUserIdAvailabilityDebounceTime:1000
};
