import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../find-me-constants';
import { types } from '../find-me-actions';
import { ReactGA, PendoUtil } from '../../../../util';
import { WindowUtil } from '../../../../util';
import Crypto from 'crypto-js';
import { constants as registrationConstants } from '../../registration/registration-constants';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';
import {  getLocaleInitialState } from '../../../../util/locale';
import Cookies from 'js-cookie';

const {
  FIND_ME_INFO_OPTIONS,
  FIND_ME_UPDATE_FIELD,
  FIND_ME_FAIL,
  FIND_ME_UPDATE_LOCATION,
  FIND_ME_UPDATE_FIELD_VALUE,
  FIND_ME_SUBMIT_IDENTITY_OPTIONS,
  ON_FINAL_FIND_ME_SUCCESS,
  CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES,
  FIND_ME_HAS_REG_CODE_OPTION,
  FIND_ME_ORG_SELECTED,
  FIND_ME_VERIFY_USER,
  FIND_ME_GO_TO_MAIN_PAGE,
  FIND_ME_GO_TO_PII_PAGE,
  FIND_ME_CHANNEL_SELECT,
  FIND_ME_VERIFY_OTP,
  FIND_ME_RESEND_OTP,
  FIND_ME_PII_INFO_OPTIONS,
  FIND_ME_GO_TO_SEARCH_PAGE,
  FIND_ME_GO_TO_ERROR_OPTIONS_PAGE,
  FIND_ME_SET_LOCALE,
  REDIRECT_TO_IDENTITY_PAGE,
  REDIRECT_TO_IDENTITY_PAGE_SUCCESS,
  UPDATE_LOCALE_FIELD,
  UPDATE_PAGE_DETAILS,
  SET_FINDME_SIV_FIELD,
  SET_FINDME_SIV_FIELD_SUCCESS,
  SET_FINDME_SIV_FIELD_FAIL,
  VERIFY_SIV_DATA,VERIFY_SIV_DATA_SUCCESS,
  FIND_ME_CONTRACTOR_OPTIONS,
  VERIFY_CONTRACTOR_SIV_DATA,VERIFY_CONTRACTOR_SIV_DATA_SUCCESS,
  SET_CONTRACTOR_SIV_FIELD,
  SET_CONTRACTOR_SIV_FIELD_SUCCESS,
  SET_CONTRACTOR_SIV_FIELD_FAIL,
  CONTRACTOR_ORG_SELECTED,
  CONTRACTOR_VERIFY_USER,
  REGISTRATION_LESS_FLOW,
  CONTINUE_REGISTRATION_FLOW,
  CONTINUE_REGISTRATION_FLOW_SUCCESS
} = types;

const isBlank = str => (str || '').trim().length === 0;

const setFindMeFieldSuccess = createAction(FIND_ME_UPDATE_FIELD);
const setUpdateLocaleSuccess = createAction(UPDATE_LOCALE_FIELD);
const setFindMeFieldFail = createAction(FIND_ME_FAIL);
const redirectToIdentitySuccess = createAction(REDIRECT_TO_IDENTITY_PAGE_SUCCESS);
const cancelAndRedirectSuccess = createAction(CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES);

const setSivFieldSuccessSent = createAction(SET_FINDME_SIV_FIELD_SUCCESS);
const setSivFieldFailSent = createAction(SET_FINDME_SIV_FIELD_FAIL);
const verifySivDataSuccessSent = createAction(VERIFY_SIV_DATA_SUCCESS);
const verifyContractorSivSuccess = createAction(VERIFY_CONTRACTOR_SIV_DATA_SUCCESS);
const continueRegistrationSuccess = createAction(CONTINUE_REGISTRATION_FLOW_SUCCESS);

/*************************************find me info**************************************** */
const getFindMeInfo = (getState) =>
  getFindMeInfoHandler(getState)
    .pipe(map(response => handleResponse(response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const getFindMeInfoHandler = (getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_FIND_ME_LANDING";
  let pagePath = '/ssr/flowType/findme/landing';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_FIND_ME_LANDING"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REGISTRATION_FIND_ME_LANDING"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  return of({
    isMobileBrowser, isMobile, gaCategory
  });
};

const handleResponse = (data, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.title = getState.intl.messages['verifyUser.title'];
  findMeObj.showDiv = true;
  findMeObj.page = constants.LANDING_PAGE;

   // progress bar start
  findMeObj.progressBarData = {};
  findMeObj.progressBarData.showProgressBar = false;
  // progress bar end

  findMeObj.redirectionFrom = '';
  let progressBarData = '';
  if(getState.verifyUser.regCodeToFindMeFlow !== undefined && getState.verifyUser.regCodeToFindMeFlow) {
    findMeObj.page = constants.ERROR_OPTIONS_PAGE;
    findMeObj.redirectionFrom = constants.REGCODE_PAGE;
    findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Enter_Code, getState);  
  } else if(getState.identifyYourself.identityToFindMeFlow !== undefined && getState.identifyYourself.identityToFindMeFlow) {
    findMeObj.page = constants.ERROR_OPTIONS_PAGE;
    findMeObj.redirectionFrom = constants.ORC_IDENTITY_PAGE;
    findMeObj.companyName = getState.identifyYourself.companyName;
    findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);  
  }
  let sendRedirect = false;
  findMeObj.locale =  WindowUtil.getLocaleValue(getState.locale.locale);
  findMeObj.isCodelessVariant = false;
  findMeObj.isCodelessVariant = getState.registration.codelessVariantEnabled;
  findMeObj.radioOption = constants.EMAIL_PHONE_RADIO;
  
  return setFindMeFieldSuccess({
    findMeObj,
    isMobileBrowser: data.isMobileBrowser,
    isMobile: data.isMobile,
    gaCategory: data.gaCategory,
    sendRedirect
  });
}

/**********************Reg Code YES/NO**********************/
const hasRegCode = (payload, getState) =>
  hasRegCodeApi(payload, getState)
    .pipe(map(response => hasRegCodeHandler(response.response, payload, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const hasRegCodeApi = (payload, getState) => {
  if (!getState.registration.isDeviceAssessmentDone && payload === 'EMAIL_OR_PHONE') {
    if (getState.registration.orgIdForDeviceAssessment != '') {
      WindowUtil.deviceAssessment(getState.registration.orgIdForDeviceAssessment, getState.registration.transactionId);
      getState.registration.isDeviceAssessmentDone = true;
    }
  }
  return ajax({
    url: constants.hasRegCodeApiEndPoint,
    method: 'POST',
    responseType: 'json',
    body: payload,
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
}

const hasRegCodeHandler = (response, payload, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = null;

  findMeObj = {};
  findMeObj.isCodelessVariant = getState.registration.codelessVariantEnabled;
  findMeObj.radioOption = constants.EMAIL_PHONE_RADIO;
  findMeObj.showDiv = true;
  findMeObj.title = getState.intl.messages['verifyUser.title'];
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;

  if (response.code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'LANDING_PAGE', 'SUCCESS');

    if (response.viewId == constants.findMeEmailPhoneSearchViewId) {
      if(findMeObj.isCodelessVariant) {
         gaCategory = "WEB_REGISTRATION_CODELESS_EMAILPHONE_PII";
        let pagePath = '/ssr/flowType/codeless/emailphone_pii';
        let pageView = pagePath + "/web";
        if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_CODELESS_EMAILPHONE_PII"; }
        if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REGISTRATION_CODELESS_EMAILPHONE_PII"; }
        ReactGA.pageview(pageView);
        PendoUtil.pendoUpdate(pageView)
      } else {
         gaCategory = "WEB_REGISTRATION_CODELESS_EMAIL_PHONE";
        let pagePath = '/ssr/flowType/codeless/email_phone';
        let pageView = pagePath + "/web";
        if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_CODELESS_EMAIL_PHONE"; }
        if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REGISTRATION_CODELESS_EMAIL_PHONE"; }
        ReactGA.pageview(pageView);
        PendoUtil.pendoUpdate(pageView)
      }
     
      // progress bar start and handling back btn
      getState.registration.currentStage = WindowUtil.getProgressBarViewId(progressBarConstants.findMeViewId);
      findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Search, getState);
      // progress bar end
      findMeObj.page = constants.EMAILPHONESEARCH_PAGE;
      findMeObj.emailPhoneValid = true;
      findMeObj.onlyCountries = [];
      for (var i = 0; i < response.countryCodes.length; i++) {
        findMeObj.onlyCountries.push(response.countryCodes[i].value.toLowerCase());
      }
      findMeObj.contractorEnableFlag = false;
      if(getState.registration.contractorEnableFlag){
        findMeObj.contractorEnableFlag = true;
      }
    }
    else {
      //changing current stage for progressbar
      getState.registration.currentStage = WindowUtil.getProgressBarViewId(response.viewId);

      let sendRedirect = true;
      let viewId = response.viewId;
      getState.verifyUser.regCodeToFindMeFlow = false;
      findMeObj.redirectionFrom = '';
      let errorPageInfo = '';
      return setFindMeFieldSuccess({
        findMeObj, sendRedirect, viewId , errorPageInfo
      });
    }
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'LANDING_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj, isMobileBrowser, isMobile, gaCategory
  });
}
/**********************Set language**********************/
const setLocale = (payload, getState) =>
  setLocaleApi(payload, getState)
    .pipe(map(response => setLocaleHandler(response.response, payload, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const setLocaleApi = (payload, getState) => ajax({
  url:  constants.setLocaleEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload.payload.suffix,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const setLocaleHandler = (response, payload, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.locale = payload.payload.language;
  const localeResponse = getLocaleInitialState(payload.payload.suffix);
  getState.locale.locale = localeResponse.locale.locale;
  getState.intl.locale = localeResponse.intl.locale;
  getState.intl.messages = localeResponse.intl.messages;
  if (response.code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'SET_LOCALE', 'SUCCESS');
    // setting language cookie

    WindowUtil.setCookieValue(getState.locale.locale);
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'LANDING_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setUpdateLocaleSuccess({
    findMeObj
  });
}
/*****************************Email/Phone search page******************************************* */
const channelSelect = (payload, getState) =>
  channelSelectApi(payload, getState)
    .pipe(map(response => channelSelectHandler(response.response, payload, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const channelSelectApi = (payload, getState) => ajax({
  url: payload.endPoint,
  method: 'POST',
  responseType: 'json',
  body: payload.value,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const channelSelectHandler = (response, payload, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.verifyUserCall = false;
  let code = response.code || "500";
  findMeObj.showAlert = false;
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = null;

  if (code == constants.SUCCESS) {

    // backend call verify user
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'SUCCESS');
    findMeObj.verifyUserCall = true;
    findMeObj.callVerifyUserFromSearch = true;
  }
  else if (code == constants.FINDME_USER_NOT_FOUND) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'NOT_FOUND');
    findMeObj.errorPageInfo = '<b>' + findMeObj.searchValue + '</b>' + getState.intl.messages['findMe.notFoundInfo'];
    let errorPageInfo = findMeObj.errorPageInfo;
    findMeObj.page = constants.ERROR_OPTIONS_PAGE;
    gaCategory = "WEB_REG_CODELESS_ERROR_OPTIONS_NO_USER";
    let pagePath = '/ssr/flowType/findme/errorpage/no_user_found';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_ERROR_OPTIONS_NO_USER"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_ERROR_OPTIONS_NO_USER"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)

    return setFindMeFieldSuccess({
      findMeObj,
      errorPageInfo, gaCategory, isMobileBrowser, isMobile
    });
  }
  else if (code == constants.FINDME_MULTIPLE_USERS_FOUND) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'MULTI_USER');
    let multipleError = getState.intl.messages['findMe.foundMultipleEntries'];
    findMeObj.errorPageInfo = multipleError.replace("{0}", '<b>' + findMeObj.searchValue + '</b>');
    let errorPageInfo = findMeObj.errorPageInfo;
    findMeObj.page = constants.ERROR_OPTIONS_PAGE;
    gaCategory = "WEB_REG_CODELESS_ERROR_OPTIONS_MULTI_USER";
    let pagePath = '/ssr/flowType/findme/errorpage/multi_user_found';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_ERROR_OPTIONS_MULTI_USER"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_ERROR_OPTIONS_MULTI_USER"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)

    return setFindMeFieldSuccess({
      findMeObj,
      errorPageInfo, gaCategory, isMobileBrowser, isMobile
    });
  }
  else if (code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'ALREADY_REGISTERED');
    findMeObj.errorPageInfo = getState.intl.messages['findMe.alreadyRegistered'];
    let errorPageInfo = findMeObj.errorPageInfo;
    findMeObj.redirectToPiiPage = true;
    findMeObj.radioOption = constants.PII_RADIO;
    gaCategory = "WEB_REG_CODELESS_ALREADY_REGISTERED_PII";
    let pagePath = '/ssr/flowType/findme/already_registered/pii';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_ALREADY_REGISTERED_PII"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_ALREADY_REGISTERED_PII"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)
    return setFindMeFieldSuccess({
      findMeObj,
      errorPageInfo, gaCategory, isMobileBrowser, isMobile,
      code: constants.ALREADY_REGISTERED
    });
  }
  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else if (response.message != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'ERROR');
    findMeObj.showAlertMessage = response.message;
    showModalWarn = false;
    findMeObj.showAlert = true;
    return setFindMeFieldSuccess({
      showModalWarn, findMeObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CHANNELS_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj
  });
}

/***********************************Init load Pii otions********************************************** */

const getPiiOptions = (getState) =>
  getPiiOptionsApi(getState)
    .pipe(map(response => handlePiiOptionsResponse(response.response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const getPiiOptionsApi = (getState) => ajax({
  url: constants.piiOptionsEndPoint,
  method: 'POST',
  responseType: 'json',
  body: 'PII',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handlePiiOptionsResponse = (response, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj = {};
  findMeObj.isCodelessVariant = getState.registration.codelessVariantEnabled;
  findMeObj.radioOption = constants.PII_RADIO;
  findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Search, getState);
  findMeObj.showDiv = true;
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  findMeObj.verifyUserCall = false;

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = null;

  if (response.code == constants.SUCCESS) {
    if (response.viewId == constants.findMePiiSearchViewId && response.sivOptions != undefined && response.sivOptions != null) {
      findMeObj.sivOptionsCalenderItems = response.sivOptionsCalenderItems;
      findMeObj.sivOptions = response.sivOptions;
      findMeObj.page = constants.PII_PAGE;
      if(findMeObj.isCodelessVariant) {
        gaCategory = "WEB_REGISTRATION_CODELESS_EMAILPHONE_PII";
        let pagePath = '/ssr/flowType/codeless/emailphone_pii';
        let pageView = pagePath + "/web";
        if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_CODELESS_EMAILPHONE_PII"; }
        if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REGISTRATION_CODELESS_EMAILPHONE_PII"; }
        ReactGA.pageview(pageView);
        PendoUtil.pendoUpdate(pageView)
      } else {
        gaCategory = "WEB_REG_CODELESS_PII"; 
        let pagePath = '/ssr/flowType/findme/pii';
        let pageView = pagePath + "/web";
        if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_PII"; }
        if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_PII"; }
        ReactGA.pageview(pageView);
        PendoUtil.pendoUpdate(pageView)
      }      

      let countryDropdown = [];
      countryDropdown.push(getState.intl.messages['findMe.USA']);
      countryDropdown.push(getState.intl.messages['findMe.CANADA']);
      findMeObj.countryDropdown = countryDropdown;
      findMeObj.selectedCountry = countryDropdown[0];
      if(findMeObj.isCodelessVariant) {
        findMeObj.radio1 = constants.SSN;
      } else {
        findMeObj.radio1 = constants.EMP_ID;
      }      
      let errorFields = [];
      findMeObj.ssnType = "siv_ssn";
      for (var key in findMeObj.sivOptions) {
        // handling ssn4 case
        if (findMeObj.sivOptions[key].idenDataId == "siv_ssn4") {
          findMeObj.sivOptions["siv_ssn"] = findMeObj.sivOptions["siv_ssn4"];
          findMeObj.sivOptions["siv_ssn"].idenDataId = "siv_ssn";
          delete findMeObj.sivOptions["siv_ssn4"];
          let fieldName = findMeObj.sivOptions["siv_ssn"].idenDataId;
          errorFields[fieldName] = {};
          errorFields[fieldName].validationError = false;
          findMeObj.ssnType = "siv_ssn4";
        }
        else {
          let fieldName = findMeObj.sivOptions[key].idenDataId;
          errorFields[fieldName] = {};
          errorFields[fieldName].validationError = false;
        }
      }
      findMeObj.errorFields = errorFields;
    }
  } else {
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj, gaCategory, isMobileBrowser, isMobile
  });
}

/***********************************verify Pii otions********************************************** */

const getCountryValue = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  const index = findMeObj.countryDropdown.indexOf(findMeObj.selectedCountry);
  if (index == 0) return "USA";
  else return "CANADA";
}
const submitIdentityOptions = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let reqObj = {};
  if (findMeObj.sivOptions["siv_country"]) {
    reqObj.siv_country = {};
    reqObj.siv_country.idenDataId = findMeObj.sivOptions["siv_country"].idenDataId;
    reqObj.siv_country.idenDataValue = getCountryValue(getState);
  }
  if (findMeObj.sivOptions["siv_firstname"]) {
    reqObj.siv_firstname = {};
    reqObj.siv_firstname.idenDataId = findMeObj.sivOptions["siv_firstname"].idenDataId;
    reqObj.siv_firstname.idenDataValue = findMeObj.sivOptions["siv_firstname"].idenDataValue;
  }
  if (findMeObj.sivOptions["siv_lastname"]) {
    reqObj.siv_lastname = {};
    reqObj.siv_lastname.idenDataId = findMeObj.sivOptions["siv_lastname"].idenDataId;
    reqObj.siv_lastname.idenDataValue = findMeObj.sivOptions["siv_lastname"].idenDataValue;
  }

  if (findMeObj.sivOptions["siv_fulldateofbirth"]) {
    var inputmonthId = findMeObj.sivOptionsCalenderItems.fullMonthItems.indexOf(findMeObj.fullMonth) + 1;
    inputmonthId = ('0' + inputmonthId).slice(-2);
    var fulldob = '' + findMeObj.fullYear + "-" + inputmonthId + "-" + findMeObj.fullDay;
    reqObj.siv_fulldateofbirth = {};
    reqObj.siv_fulldateofbirth.idenDataId = findMeObj.sivOptions["siv_fulldateofbirth"].idenDataId;
    reqObj.siv_fulldateofbirth.idenDataValue = fulldob;

  }

  if (findMeObj.selectedCountry == findMeObj.countryDropdown[0]) {
    if (findMeObj.radio1 == "empId") {
      reqObj.siv_empid = {};
      reqObj.siv_empid.idenDataId = findMeObj.sivOptions["siv_empid"].idenDataId;
      reqObj.siv_empid.idenDataValue = findMeObj.sivOptions["siv_empid"].idenDataValue;
    }
    else {
      reqObj[findMeObj.ssnType] = {};
      reqObj[findMeObj.ssnType].idenDataId = findMeObj.ssnType;
      let ssnRequest = findMeObj.sivOptions["siv_ssn"].idenDataValue.replace(/-/g, "");
      reqObj[findMeObj.ssnType].idenDataValue = '' + Crypto.AES.encrypt(ssnRequest, registrationConstants.registrationEncryption);
    }
  }

  if (findMeObj.selectedCountry != findMeObj.countryDropdown[0]) {
    reqObj.siv_empid = {};
    reqObj.siv_empid.idenDataId = findMeObj.sivOptions["siv_empid"].idenDataId;
    reqObj.siv_empid.idenDataValue = findMeObj.sivOptions["siv_empid"].idenDataValue;
  }

  return submitIdentityOptionsHandler(reqObj, getState);
}

const submitIdentityOptionsHandler = (reqObj, getState) =>
  submitIdentityOptionsApi(reqObj, getState)
    .pipe(map(response => handleIdentityOptions(response.response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const submitIdentityOptionsApi = (reqObj, getState) => ajax({
  url: constants.submitIdentityOptionsApiEndPoint,
  method: 'POST',
  responseType: 'json',
  body: reqObj,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleIdentityOptions = (response, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.showAlert = false;
  findMeObj.verifyUserCall = false;
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let code = response.code || "500";

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = null;

  if (code == constants.SUCCESS) {
    if (response.clientNames != undefined && response.clientNames != null) {
      // progress bar start
      findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
      // progress bar end
      ReactGA.sendevent(getState.findMe.gaCategory, 'PII_SUBMIT', 'MULTI_ORG');
      var multiOrgs = [];
      var multiOrgIndex = [];
      let k = 0;
      for (var key in response.clientNames) {
        multiOrgs[k] = response.clientNames[key];
        multiOrgIndex[k] = key;
        k++;
      }
      findMeObj.multiOrgs = multiOrgs;
      findMeObj.multiOrgIndex = multiOrgIndex;
      findMeObj.page = constants.MULTIORG_PAGE;
      gaCategory = "WEB_REG_CODELESS_PII_MULTIORG";
      let pagePath = '/ssr/flowType/findme/multiorg';
      let pageView = pagePath + "/web";
      if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_MULTIORG"; }
      if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_MULTIORG"; }
      ReactGA.pageview(pageView);
      PendoUtil.pendoUpdate(pageView)
    }
    else {
      ReactGA.sendevent(getState.findMe.gaCategory, 'PII_SUBMIT', 'SUCCESS');
      findMeObj.verifyUserCall = true;
    }
  }
  else if (code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'PII_SUBMIT', 'ALREADY_REGISTERED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    findMeObj.alreadyRegistered = true;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'PII_SUBMIT', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else if (response.message != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'PII_SUBMIT', 'ERROR');
    findMeObj.showAlertMessage = response.message;
    showModalWarn = false;
    findMeObj.showAlert = true;
    if (findMeObj.sivOptions["siv_ssn"]) {
      findMeObj.sivOptions["siv_ssn"].idenDataValue = '';
    }
    return setFindMeFieldSuccess({
      showModalWarn, findMeObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'PII_SUBMIT', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }

  return setFindMeFieldSuccess({
    findMeObj, gaCategory, isMobileBrowser, isMobile
  });
}

/************************************verifyUser call details********************************************* */

const verifyUserInfo = (getState) => verifyUserApi(getState).pipe(map(response => handleVerifyUserResponse(response.response, getState)),
  catchError(err => [(getErrorMessage(err))]));

const verifyUserApi = (getState) => ajax({
  url: constants.submitPodRedirectionApiEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleVerifyUserResponse = (response, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let code = response.code || "500";
  findMeObj.firstName = response.firstName;
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  findMeObj.contactAdminMsg = response.contactAdminMsg || null;
  findMeObj.verifyUserCall = false;
  findMeObj.contractorEnableFlag = false;
  if(getState.registration.contractorEnableFlag){
    findMeObj.contractorEnableFlag = true;
  }
  //if code is success and contains sivoptions then show new SIV options page
    if(code === constants.SUCCESS && response.sivOptionsBean) {
      ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'EMAIL_PHONE_SIV_PAGE');
      let viewId = response.viewId;
      if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {
        
        if(response.fedOnlyEnabled !== undefined && response.fedOnlyEnabled) {

          findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
        } else {
          findMeObj.page = constants.REGISTRATIONLESS_DUAL_PAGE;
        }     
        findMeObj.emailSearchFed = true;   
        //sivoptions add
      } else { 
        findMeObj.page = constants.EMAIL_PHONE_SIV_PAGE;
      }      
      let emailPhoneSearchSivObj = Object.assign({}, getState.findMe.emailPhoneSearchSivObj);
      emailPhoneSearchSivObj.sivOptions = response.sivOptionsBean;
      emailPhoneSearchSivObj.availableSivOptions = response.sivOptionsBean !== undefined ? response.sivOptionsBean.availableSivOptions : null;
      findMeObj.identifiedUserFirstName = response.firstName;
      findMeObj.firstName = response.firstName;
      findMeObj.clientName = response.clientName;
      PendoUtil.pendoIdentify(response.orgCd,response.clientName,getState.registration.transactionId)
      PendoUtil.pendoVisitorIdUpdate(response.associateId)
      

      if(emailPhoneSearchSivObj.availableSivOptions.siv_ssn4 !== undefined || emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth !== undefined ||
      emailPhoneSearchSivObj.availableSivOptions.siv_empid !== undefined || emailPhoneSearchSivObj.availableSivOptions.siv_wfnid !== undefined) {
        emailPhoneSearchSivObj.initialLoad = true;
        emailPhoneSearchSivObj.ssn4Valid = true;emailPhoneSearchSivObj.ssn4='';
        emailPhoneSearchSivObj.empIdValid = true;emailPhoneSearchSivObj.empId='';
        emailPhoneSearchSivObj.wfnIdValid = true;emailPhoneSearchSivObj.wfnId='';
        emailPhoneSearchSivObj.dobValid = true;emailPhoneSearchSivObj.month='';emailPhoneSearchSivObj.day='';
      }
      //picking one identity option to load on first view
      if(emailPhoneSearchSivObj.availableSivOptions.siv_ssn4 !== undefined)
        emailPhoneSearchSivObj.initialSivData = constants.SSN4;
      else if(emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth !== undefined) 
        emailPhoneSearchSivObj.initialSivData = constants.DOB;
      else if(emailPhoneSearchSivObj.availableSivOptions.siv_empid !== undefined)
        emailPhoneSearchSivObj.initialSivData = constants.EMPID;
      else if(emailPhoneSearchSivObj.availableSivOptions.siv_wfnid !== undefined)
        emailPhoneSearchSivObj.initialSivData = constants.WFNID;  

      let gaCategory = "WEB_CODELESS_EMAIL_PHONE_SIV";
      let pagePath = '/ssr/flowType/findme/emailphone/siv';
      let pageView = pagePath + "/web";
      findMeObj.verifyflow = false;
      findMeObj.multiOrg = false;
      if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_CODELESS_EMAIL_PHONE_SIV"; }
      if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_CODELESS_EMAIL_PHONE_SIV"; }
      ReactGA.pageview(pageView);
      PendoUtil.pendoUpdate(pageView)

      // progress bar start
      findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
      // progress bar end

      return setFindMeFieldSuccess({
          findMeObj,emailPhoneSearchSivObj,gaCategory,isMobile,isMobileBrowser,viewId
        });
        // new page calls end
    }
  if (code == constants.SUCCESS) {
    if (response.viewId == constants.findMeViewId) {
    //  let sendRedirect = false;
      if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {
        
        if(response.fedOnlyEnabled !== undefined && response.fedOnlyEnabled) {

          findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
        } else {
          findMeObj.page = constants.REGISTRATIONLESS_DUAL_PAGE;
        }     
        findMeObj.emailSearchFed = true;   
        //sivoptions add
      } else {       
        //sendRedirect = true;
        ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'OTP_PAGE');
        findMeObj.page = constants.OTP_PAGE;
        
        PendoUtil.pendoIdentify(response.orgCd,response.clientName,getState.registration.transactionId)
        PendoUtil.pendoVisitorIdUpdate(response.associateId)
        
      }
      let gaCategory = "WEB_REG_CODELESS_OTP";
      let pagePath = '/ssr/flowType/findme/otp';
      let pageView = pagePath + "/web";        
      if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_OTP"; }
      if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_OTP"; }
      ReactGA.pageview(pageView);
      PendoUtil.pendoUpdate(pageView);

      findMeObj.prcValid = true;
      findMeObj.selectedIdentityValue = response.maskedChannel;
      findMeObj.expiry = response.expiry;
      findMeObj.showOnScreen = response.showOnScreen;
      findMeObj.otpChannelId = response.contactId;
      findMeObj.accessCode = response.accessCode;
      findMeObj.firstName = response.firstName;
      findMeObj.clientName = response.clientName;
      findMeObj.verifyflow = false;
      findMeObj.multiOrg = false;
    
      // progress bar start
      findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
      // progress bar end
      return setFindMeFieldSuccess({
        findMeObj, gaCategory, isMobileBrowser, isMobile, viewId: response.viewId
      });
    }
    else if (response.viewId == constants.findMeVerifyPrcViewId) {
      ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'VERIFY_PRC');
      findMeObj.verifyflow = true;
      if (findMeObj.multiOrgs != null || findMeObj.multiOrgs != null) { findMeObj.multiOrg = true; }
      findMeObj.clientName = response.clientName;
      findMeObj.firstName = response.firstName;
     
      PendoUtil.pendoIdentify(response.orgCd,response.clientName,getState.registration.transactionId)
      PendoUtil.pendoVisitorIdUpdate(response.associateId);
      let sendRedirect = false;
      if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {

        if(response.fedOnlyEnabled !== undefined && response.fedOnlyEnabled) {

          findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
        } else {
          findMeObj.page = constants.REGISTRATIONLESS_DUAL_PAGE;
        }        
      } else {
        sendRedirect = true;
      }  
      let viewId = response.viewId;
      return setFindMeFieldSuccess({
        findMeObj, viewId, sendRedirect
      });
    }
    else {
      if (findMeObj.multiOrgs != null || findMeObj.multiOrgs != null) {
        ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'MULTI_ORG');
        let viewId = response.viewId;
        let sendRedirect = false;
        if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {

          if(response.fedOnlyEnabled !== undefined && response.fedOnlyEnabled) {

            findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
          } else {
            findMeObj.page = constants.REGISTRATIONLESS_DUAL_PAGE;
          }        
        } else {
          sendRedirect = true;
        }
        return setFindMeFieldSuccess({
          findMeObj, viewId, sendRedirect
        });
      }
      else {
        findMeObj.identityText = response.contactInfoUpFront;
        findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
        ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'IDENTITY_PAGE');
        if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {

          if(response.fedOnlyEnabled !== undefined && response.fedOnlyEnabled) {

            findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
          } else {
            findMeObj.page = constants.REGISTRATIONLESS_DUAL_PAGE;
          } 
        } else {
          findMeObj.page = constants.IDENTIFIED_PAGE;
        }        
        findMeObj.clientName = response.clientName;
        findMeObj.firstName = response.firstName;
      
        PendoUtil.pendoIdentify(response.orgCd,response.clientName,getState.registration.transactionId)
        PendoUtil.pendoVisitorIdUpdate(response.associateId)
        
        let viewId = response.viewId;
        return setFindMeFieldSuccess({
          findMeObj, viewId
        });
      }

    }
  }
  else if (code == constants.FINDME_USER_NOT_FOUND && findMeObj.callVerifyUserFromSearch != undefined && findMeObj.callVerifyUserFromSearch === true) {
    findMeObj.errorPageInfo = '<b>' + findMeObj.searchValue + '</b>' + getState.intl.messages['findMe.notFoundInfo'];
    let errorPageInfo = findMeObj.errorPageInfo;
    findMeObj.page = constants.ERROR_OPTIONS_PAGE;
    let gaCategory = "WEB_REG_CODELESS_ERROR_OPTIONS_NO_USER";
    let pagePath = '/ssr/flowType/findme/errorpage/no_user_found';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_ERROR_OPTIONS_NO_USER"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_ERROR_OPTIONS_NO_USER"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'NOT_FOUND');
    return setFindMeFieldSuccess({
      findMeObj, errorPageInfo, gaCategory, isMobileBrowser, isMobile
    });
  }
  else if (code == constants.ALREADY_REGISTERED && findMeObj.callVerifyUserFromSearch != undefined && findMeObj.callVerifyUserFromSearch === true) {
    findMeObj.errorPageInfo = getState.intl.messages['findMe.alreadyRegistered'];
    let errorPageInfo = findMeObj.errorPageInfo;
    //findMeObj.page = constants.ERROR_OPTIONS_PAGE;
    if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {
      findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
      findMeObj.emailSearchFed = true;   
      findMeObj.emailSearchAlreadyRegistered = true;
    } else {
      findMeObj.redirectToPiiPage = true;
    }       
    let prevPage = constants.EMAILPHONESEARCH_PAGE;
    let gaCategory = "WEB_REG_CODELESS_ERROR_OPTIONS_ALREADY_REGISTERED";
    let pagePath = '/ssr/flowType/findme/errorpage/already_registered';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_ERROR_OPTIONS_ALREADY_REGISTERED"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_ERROR_OPTIONS_ALREADY_REGISTERED"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'ALREADY_REGISTERED');
    return setFindMeFieldSuccess({
      findMeObj, errorPageInfo, gaCategory, isMobileBrowser, isMobile, prevPage,
      code: constants.ALREADY_REGISTERED,
    });
  }
  else if (code == constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'FAILED');
    if(response.registrationLessFlowEnabled !== undefined && response.registrationLessFlowEnabled) {
       findMeObj.page = constants.REGISTRATIONLESS_FED_PAGE;
       findMeObj.piiAlreadyRegistered = true;
       return setFindMeFieldSuccess({
       findMeObj
      });
    } else {
      showModalMessage = response.message;
      showModalWarn = true;
      showModalTitle = response.title;
      findMeObj.alreadyRegistered = true;
    }     
    return cancelAndRedirectSuccess ({
      findMeObj      
    })
  /*  return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    }); */
  }
  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else if (response.message != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'ERROR');
    findMeObj.showAlertMessage = response.message;
    showModalWarn = false;
    findMeObj.showAlert = true;
    return setFindMeFieldSuccess({
      showModalWarn, findMeObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_USER', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj
  });
}

/*****************************************Otp page******************************************************/

const submitAccessCode = (getState) => {
  let inputPRC = getState.findMe.findMeObj.otpValue;
  if (getState.findMe.isFormValid != undefined && getState.findMe.isFormValid == false)
    return of();
  return setSubmitAccessCodeApi(inputPRC, getState)
    .pipe(map(response => handleResponseAccessCode(response.response, getState))
      , catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));
}

const setSubmitAccessCodeApi = (payload, getState) => ajax({
  url: constants.verifyOTPEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleResponseAccessCode = (response, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.verifyUserCall = false;
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let code = response.code || "500";

  if (code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'OTP_PAGE', 'SUCCESS');
    return setFindMeFieldSuccess({
      viewId: response.viewId,
      sendRedirect: true
    });

  }
  else if (response.code == constants.TYPE_ERROR_INVALID || response.code == constants.TYPE_ERROR_EXPIRED) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'OTP_PAGE', 'ERROR');
    findMeObj.showAlertMessage = response.message;
    showModalWarn = false;
    findMeObj.showAlert = true;
    return setFindMeFieldSuccess({
      showModalWarn, findMeObj
    });
  }
  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'OTP_PAGE', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else if (response.message != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'OTP_PAGE', 'ERROR');
    findMeObj.showAlertMessage = response.message;
    showModalWarn = false;
    findMeObj.showAlert = true;
    return setFindMeFieldSuccess({
      showModalWarn, findMeObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'OTP_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj
  });
}

/***************************************Resend otp **************************************** */
const resendOTP = (reqObj, getState) =>
  resendOTPApi(reqObj, getState)
    .pipe(map(response => handleResendOTP(response.response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const resendOTPApi = (payload, getState) => ajax({
  url: constants.generateOTPEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleResendOTP = (response, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  findMeObj.verifyUserCall = false;
  let code = response.code || "500";
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;

  if (code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'RESEND_PAGE', 'SUCCESS');
    let gaCategory = "WEB_REG_CODELESS_RESEND_OTP";
    let pagePath = '/ssr/flowType/findme/resend-otp';
    let pageView = pagePath + "/web";
    if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_RESEND_OTP"; }
    if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_RESEND_OTP"; }
    ReactGA.pageview(pageView);
    PendoUtil.pendoUpdate(pageView)

    findMeObj.disableResend = true;
    findMeObj.selectedIdentityValue = response.maskedChannel;
    findMeObj.expiry = response.expiry;
    findMeObj.showOnScreen = response.showOnScreen;
    findMeObj.accessCode = response.accessCode;
    return setFindMeFieldSuccess({
      findMeObj, gaCategory, isMobileBrowser, isMobile
    });
  }

  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'RESEND_PAGE', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else if (response.message != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'RESEND_PAGE', 'ERROR');
    findMeObj.showAlertMessage = response.message;
    showModalWarn = false;
    findMeObj.showAlert = true;
    return setFindMeFieldSuccess({
      showModalWarn, findMeObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'RESEND_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj
  });
}

/********************************Multi orgs- org selected api****************************************************** */

const orgSelected = (payload, getState) => {
  var selectedOrgIndex = getState.findMe.findMeObj.multiOrgIndex[payload];
  var selectedOrg = getState.findMe.findMeObj.multiOrgs[payload]
  return orgSelectedApi(selectedOrgIndex, getState)
    .pipe(map(response => orgSelectedHandler(response.response,selectedOrg , getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));
}


const orgSelectedApi = (payload, getState) => ajax({
  url: constants.submitMultiOrgOptionApiEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const orgSelectedHandler = (response,selectedOrg, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let code = response.code || "500";
  if (code == constants.SUCCESS) {
    PendoUtil.pendoIdentify(response.orgCd,selectedOrg,getState.registration.transactionId)
    PendoUtil.pendoVisitorIdUpdate(response.associateId)
    ReactGA.sendevent(getState.findMe.gaCategory, 'ORG_PAGE', 'SUCCESS');
    findMeObj.verifyUserCall = true;
  }
  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'ORG_PAGE', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'ORG_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj
  });
}

/**************************update all the field values******************************************************** */
const updateFieldValue = (payload, getState) =>
  updateFieldValueHandler(payload, getState)
    .pipe(map(setFindMeFieldSuccess));

const updateFieldValueHandler = (payload, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.showAlert = false;
  if (payload.fieldName == "otpValue") {
    findMeObj.otpValue = payload.fieldValue;
  }
  else if (payload.fieldName == "email") {
    findMeObj.emailPhoneValid = payload.isValid;
    findMeObj.email = payload.fieldValue;
    findMeObj.searchType = payload.fieldName;
    findMeObj.searchValue = payload.fieldValue;
  }
  else if (payload.fieldName == "phone") {
    findMeObj.searchType = payload.fieldName;
    if (!isBlank(payload.fieldValue)) {
      findMeObj.phone = payload.fieldValue;
      findMeObj.emailPhoneValid = payload.isValid;
      findMeObj.isoCode = payload.isoCode;
      findMeObj.searchValue = payload.fieldValue;
    } else {
      findMeObj.phone = null;
      findMeObj.emailPhoneValid = false;
      findMeObj.isoCode = null;
    }
  }
  else if (payload.fieldName == "radio") {
    findMeObj.radio = payload.fieldValue;
  }
  else if (payload.fieldName == "radio1") {
    findMeObj.radio1 = payload.fieldValue;
    if (payload.fieldValue == "empId") {
      findMeObj.sivOptions["siv_ssn"].idenDataValue = '';
    }
    if (payload.fieldValue == "ssn") {
      findMeObj.sivOptions["siv_empid"].idenDataValue = '';
    }
  } else if (payload.fieldName == "radio2") {
    findMeObj.selectedCountry = payload.fieldValue;
  }
  else if (!payload.isDateField) {
    let sivOption = findMeObj.sivOptions[payload.fieldName];
    if (sivOption.regExp != null && !isBlank(sivOption.regExp)) {
      const regEx = new RegExp(sivOption.regExp);
      if (isBlank(payload.fieldValue) || regEx.test(payload.fieldValue)) {
        findMeObj.errorFields[payload.fieldName].validationError = false;
      } else {
        findMeObj.errorFields[payload.fieldName].validationError = true;
      }
    } else {
      findMeObj.errorFields[payload.fieldName].validationError = false;
    }
    findMeObj.sivOptions[payload.fieldName].idenDataValue = payload.fieldValue;
  }
  else {
    if (payload.fieldName == "fullMonth") {
      const inputMonthId = findMeObj.sivOptionsCalenderItems.fullMonthItems.indexOf(payload.fieldValue) + 1;
     
      if (inputMonthId == 1 || inputMonthId == 3 || inputMonthId == 5 || inputMonthId == 7 || inputMonthId == 8 || inputMonthId == 10 || inputMonthId == 12) {
        findMeObj.sivOptionsCalenderItems.fullDayItems = constants.monthWith31days;
      }
      if (inputMonthId == 4 || inputMonthId == 6 || inputMonthId == 9 || inputMonthId == 11) {
        findMeObj.sivOptionsCalenderItems.fullDayItems = constants.monthWith30days;
      }
      if (inputMonthId == 2) {
        findMeObj.sivOptionsCalenderItems.fullDayItems = constants.monthWith29days;
      }
      findMeObj["fullDay"] = '';
    }
    findMeObj[payload.fieldName] = payload.fieldValue;
  }

  return of({
    findMeObj
  });
}

/**********************************Handlers***************************************** */

const goToMainPage = (getState) => goToMainPageHandler(getState)
  .pipe(map(setFindMeFieldSuccess));

const goToPiiInfoPage = (getState) => goToPiiInfoPageHandler(getState)
  .pipe(map(setFindMeFieldSuccess));

const goToEmailPhoneSearchPage = (getState) => goToEmailPhoneSearchPageHandler(getState)
  .pipe(map(setFindMeFieldSuccess));

const goToErrorOptionsPage = (getState) => goToErrorOptionsPageHandler(getState)
  .pipe(map(setFindMeFieldSuccess));


const goToMainPageHandler = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.page = constants.LANDING_PAGE;
  findMeObj.showAlert = false;

  const isMobile = getState.registration.mobileFlow;
  var locale = getState.locale.locale;
  findMeObj.locale = WindowUtil.getLocaleValue(locale);
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_FIND_ME_LANDING";
  let pagePath = '/ssr/flowType/findme/landing';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_FIND_ME_LANDING"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REGISTRATION_FIND_ME_LANDING"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)

  return of({
    findMeObj, gaCategory, isMobileBrowser, isMobile, code: ''
  });
}

const goToPiiInfoPageHandler = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.page = constants.PII_PAGE;
  findMeObj.showAlert = false;

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REG_CODELESS_PII";
  let pagePath = '/ssr/flowType/findme/pii';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_PII"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_PII"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)

  return of({
    findMeObj, gaCategory, isMobileBrowser, isMobile, code: ''
  });
}

const goToEmailPhoneSearchPageHandler = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let emailPhoneSearchSivObj = Object.assign({}, getState.findMe.emailPhoneSearchSivObj);
  emailPhoneSearchSivObj.singleSiv = false;
  findMeObj.page = constants.EMAILPHONESEARCH_PAGE;
  findMeObj.showAlert = false;

  // progress bar start
  findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Search, getState);
  // progress bar end
  findMeObj.radioOption = constants.EMAIL_PHONE_RADIO;
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_CODELESS_EMAIL_PHONE";
  let pagePath = '/ssr/flowType/codeless/email_phone';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_CODELESS_EMAIL_PHONE"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REGISTRATION_CODELESS_EMAIL_PHONE"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView);

  findMeObj.contractorEnableFlag = false;
  if(getState.registration.contractorEnableFlag){
    findMeObj.contractorEnableFlag = true;
  }

  return of({
    findMeObj, gaCategory, isMobileBrowser, isMobile, code: '',emailPhoneSearchSivObj,errorInSIV:''
  });
}

const goToErrorOptionsPageHandler = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj.page = constants.ERROR_OPTIONS_PAGE;
  findMeObj.showAlert = false;
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REG_CODELESS_ERROR_OPTIONS_BACK";
  let pagePath = '/ssr/flowType/findme/errorpage/back';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_ERROR_OPTIONS_BACK"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_ERROR_OPTIONS_BACK"; }
  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView);
  findMeObj.contractorEnableFlag = false;
  if(getState.registration.contractorEnableFlag){
    findMeObj.contractorEnableFlag = true;
  }

  return of({
    findMeObj, gaCategory, isMobileBrowser, isMobile, code: ''
  });
}

const redirectToIdentityPage = (payload,getState) => {
  let viewId = constants.findMeViewId;

  if(payload === constants.ORC_IDENTITY_PAGE) {
    viewId = constants.identifyYourselfViewId;
  }
  return redirectToRespectiveIdentityPage(viewId, getState).pipe(
      map(response => handleRedirectionResponse(getState, response)),
      catchError(err => [(getErrorMessage(err))]));  
}

const redirectToRespectiveIdentityPage = (viewId,getState) => of({
  viewId: viewId
});

const handleRedirectionResponse = (getState,response) => {
  return redirectToIdentitySuccess({
    viewId : response.viewId
  });
}

/****************SIV options page ********************/

const setSivField = (payload, getState) =>
  validateAndSetSivField(payload, getState)
    .pipe(map(setSivFieldSuccessSent),
      catchError(err => [handleSetSivFieldFailResponse(payload, getState,getErrorMessage(err))]));
  
const validateAndSetSivField = (payload, getState) => {
  let emailPhoneSearchSivObj = Object.assign({}, getState.findMe.emailPhoneSearchSivObj);
 
  switch (payload.fieldKey) {
    case constants.SIV_EMPID: {
      const employeeIdRegEx = new RegExp(emailPhoneSearchSivObj.availableSivOptions.siv_empid.regExp);

      if (isBlank(payload.fieldValue) || employeeIdRegEx.test(payload.fieldValue)) {
        emailPhoneSearchSivObj.empId = payload.fieldValue;
        emailPhoneSearchSivObj.empIdValid = true;
        emailPhoneSearchSivObj.wfnId = '';
        emailPhoneSearchSivObj.ssn4 = '';
        emailPhoneSearchSivObj.month = '';
        emailPhoneSearchSivObj.day = '';
        return of({
          emailPhoneSearchSivObj
        });
      }
       return throwError(new Error(emailPhoneSearchSivObj.availableSivOptions.siv_empid.invalidMessage));
    }
    case constants.SIV_WFNID: {
      const wfnAssociateIdRegEx = new RegExp(emailPhoneSearchSivObj.availableSivOptions.siv_wfnid.regExp);

      if (isBlank(payload.fieldValue) || wfnAssociateIdRegEx.test(payload.fieldValue)) {
        emailPhoneSearchSivObj.wfnId = payload.fieldValue;
        emailPhoneSearchSivObj.wfnIdValid = true;
        emailPhoneSearchSivObj.empId = '';
        emailPhoneSearchSivObj.ssn4 = '';
        emailPhoneSearchSivObj.month = '';
        emailPhoneSearchSivObj.day = '';
        return of({
          emailPhoneSearchSivObj
        });
      }
       return throwError(new Error(emailPhoneSearchSivObj.availableSivOptions.siv_wfnid.invalidMessage));
    }
    case constants.SIV_SSN4: {
      const ssn4RegEx = new RegExp(emailPhoneSearchSivObj.availableSivOptions.siv_ssn4.regExp);

      if (isBlank(payload.fieldValue) || ssn4RegEx.test(payload.fieldValue)) {
        emailPhoneSearchSivObj.ssn4 = payload.fieldValue;
        emailPhoneSearchSivObj.ssn4Valid = true;
        emailPhoneSearchSivObj.empId = '';
        emailPhoneSearchSivObj.wfnId = '';
        emailPhoneSearchSivObj.month = '';
        emailPhoneSearchSivObj.day = '';
        return of({
          emailPhoneSearchSivObj
        });
      }
       return throwError(new Error(emailPhoneSearchSivObj.availableSivOptions.siv_ssn4.invalidMessage));
    }
    case constants.SIV_MONTH: {
      emailPhoneSearchSivObj.month = payload.fieldValue;
      emailPhoneSearchSivObj.day = '';
      emailPhoneSearchSivObj.empId = '';
      emailPhoneSearchSivObj.wfnId = '';
      emailPhoneSearchSivObj.ssn4 = '';
      return of({
        emailPhoneSearchSivObj
      });
    }
    case constants.SIV_DAY: {
      emailPhoneSearchSivObj.day = payload.fieldValue;
      emailPhoneSearchSivObj.empId = '';
      emailPhoneSearchSivObj.wfnId = '';
      emailPhoneSearchSivObj.ssn4 = '';
      return of({
        emailPhoneSearchSivObj
      });
    }
  }
};  

const handleSetSivFieldFailResponse = (payload,getState, errorMessage) => {
  let emailPhoneSearchSivObj = Object.assign({}, getState.findMe.emailPhoneSearchSivObj);

  switch (payload.fieldKey) {
    case constants.SIV_EMPID:{
        emailPhoneSearchSivObj.empIdValid = false;
        emailPhoneSearchSivObj.empIdErrorMsg = errorMessage;
        emailPhoneSearchSivObj.empId = payload.fieldValue;
        return setSivFieldFailSent({
          emailPhoneSearchSivObj
        });
     }
     case constants.SIV_WFNID:{
        emailPhoneSearchSivObj.wfnIdValid = false;
        emailPhoneSearchSivObj.wfnIdErrorMsg = errorMessage;
        emailPhoneSearchSivObj.wfnId = payload.fieldValue;
        return setSivFieldFailSent({
          emailPhoneSearchSivObj
        });
     }
     case constants.SIV_SSN4:{
        emailPhoneSearchSivObj.ssn4Valid = false;
        emailPhoneSearchSivObj.ssn4ErrorMsg = errorMessage;
        emailPhoneSearchSivObj.ssn4 = payload.fieldValue;
        return setSivFieldFailSent({
          emailPhoneSearchSivObj
        });
     }
   
    default: {
      return setSivFieldFailSent({
        errorMessage,
      });
    }
  }
};

/***********************************Verify SIV data*********************************************** */

const verifySivData = (getState) =>
  verifySivOptions(getState)
    .pipe(map(response => handleVerifySivDataResponse(response.response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const verifySivOptions = (getState) => {
  let emailPhoneSearchSivObj = Object.assign({}, getState.findMe.emailPhoneSearchSivObj);
  const sivOptions = emailPhoneSearchSivObj.sivOptions;
  var uiOptionsMap = {};
  var radioOptionValue = '';

  if (sivOptions.availableSivOptions.siv_empid && emailPhoneSearchSivObj.empId != '') {
    uiOptionsMap.siv_empid = emailPhoneSearchSivObj.empId;
    radioOptionValue = constants.SIV_EMPID;
    sivOptions.availableSivOptions.siv_empid.idenDataValue = emailPhoneSearchSivObj.empId;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'EMP_ID');
  }
  if (sivOptions.availableSivOptions.siv_wfnid && emailPhoneSearchSivObj.wfnId != '') {
    uiOptionsMap.siv_wfnid = emailPhoneSearchSivObj.wfnId;
    radioOptionValue = constants.SIV_WFNID;
    sivOptions.availableSivOptions.siv_wfnid.idenDataValue = emailPhoneSearchSivObj.wfnId;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'WFN_ID');
  }
  if (sivOptions.availableSivOptions.siv_ssn4 && emailPhoneSearchSivObj.ssn4 != '') {
    if (emailPhoneSearchSivObj.ssn4 != '' && emailPhoneSearchSivObj.ssn4 != undefined) {
      var encryptedSsn = Crypto.AES.encrypt(emailPhoneSearchSivObj.ssn4, registrationConstants.registrationEncryption);
      uiOptionsMap.siv_ssn4 = encryptedSsn + '';
    }
    radioOptionValue = constants.SIV_SSN4;
    sivOptions.availableSivOptions.siv_ssn4.idenDataValue = emailPhoneSearchSivObj.ssn4;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'SSN4');
  }
  var inputFullMonthId = sivOptions.sivOptionsCalenderItems.monthItems.indexOf(emailPhoneSearchSivObj.month) + 1;
  inputFullMonthId = ('0' + inputFullMonthId).slice(-2);
  var fulldob = '' + inputFullMonthId + emailPhoneSearchSivObj.day;

  if (sivOptions.availableSivOptions.siv_dateofbirth && emailPhoneSearchSivObj.day != '') {
    uiOptionsMap.siv_dateofbirth = fulldob;
    radioOptionValue = constants.SIV_DOB;
    sivOptions.availableSivOptions.siv_dateofbirth.idenDataValue = fulldob;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'DOB');
  }

  return ajax({
    url: constants.verifySivDataEndPoint,
    body: uiOptionsMap,
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
};
      
const handleVerifySivDataResponse = (response, getState) => {
  let emailPhoneSearchSivObj = Object.assign({}, getState.findMe.emailPhoneSearchSivObj);
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);

  if (response.viewId.indexOf(constants.ERROR) == -1 && response.code === constants.SUCCESS) {
    //when identity verification is success redirect to otp page 
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_EMAIL_PHONE_SIV', 'SUCCESS');
    findMeObj.page = constants.OTP_PAGE;
    findMeObj.selectedIdentityValue = response.maskedChannel;
    findMeObj.expiry = response.expiry;
    findMeObj.showOnScreen = response.showOnScreen;
    findMeObj.otpChannelId = response.contactId;
    return verifySivDataSuccessSent({
      findMeObj
    });
  }
  //when identity verification fails show either other siv options or redirect to login
  if (response.code != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_EMAIL_PHONE_SIV', response.code);
    if (response.code === constants.INVALID_SIVDATA) {
      emailPhoneSearchSivObj.ssn4 = '';
      emailPhoneSearchSivObj.ssn4Valid = true;
      emailPhoneSearchSivObj.empIdValid = true;
      emailPhoneSearchSivObj.wfnIdValid = true;
      emailPhoneSearchSivObj.ssn4ErrorMsg = '';
      emailPhoneSearchSivObj.empIdErrorMsg = '';
      emailPhoneSearchSivObj.wfnIdErrorMsg = '';
     
      //check the count of siv data so that we can load page accordingly
      let countofSiv = 0; 
      if(emailPhoneSearchSivObj.availableSivOptions.siv_ssn4 !== undefined)
        countofSiv += 1;
      if(emailPhoneSearchSivObj.availableSivOptions.siv_dateofbirth !== undefined) 
        countofSiv += 1;
      if(emailPhoneSearchSivObj.availableSivOptions.siv_empid !== undefined)
        countofSiv += 1;
      if(emailPhoneSearchSivObj.availableSivOptions.siv_wfnid !== undefined)
        countofSiv += 1;

	let errorInSIV = getState.intl.messages['findMe.sivpage.notFoundInfo'];

      if(countofSiv > 1) {
         //make intial load to false to display radio options
        emailPhoneSearchSivObj.initialLoad = false;

        //clearing the values so as to not show values on next siv prompt
        emailPhoneSearchSivObj.month = '';
        emailPhoneSearchSivObj.day = '';
        emailPhoneSearchSivObj.empId = '';
        emailPhoneSearchSivObj.wfnId = '';

      } else {
        errorInSIV = getState.intl.messages['findMe.sivpage.single.notFoundInfo'];   
        emailPhoneSearchSivObj.singleSiv = true;
      }

      return verifySivDataSuccessSent({
        errorInSIV,
        emailPhoneSearchSivObj
      });
    }
     if (response.code === constants.MAX_SECURITY_OPTION_ATTEMPTS_REACHED || (response.title !== undefined && response.message !== undefined)) {
      return verifySivDataSuccessSent({
        showModalWarn: true,
        showModalTitle: response.title,
        showModalMessage: response.message,
        ssn4: ''
      });
    }
  }
 
    ReactGA.sendevent(getState.findMe.gaCategory, 'EMAIL_PHONE_SIV_PAGE', 'FAILED');
    return verifySivDataSuccessSent({
      showModalMessage: getState.intl.messages['Err_SystemErrorinRequest'],
      showModalWarn: true, 
      showModalTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'], findMeObj,emailPhoneSearchSivObj
    });
};
/*******************Already registered user************************************** */

const sendOkForAlreadyRegisteredUser = (getState) => ajax({
  url: constants.redirectToForgotFlowEndPoint,
  method: 'GET',
  headers: {
    'Content-Type': 'text/plain',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
  responseType: 'text/plain'
});

const handleAlreadyRegisteredResponse = (response) => {
  var wnd = window.open("about:blank", "_self");
  wnd.document.write(response.response);
  wnd.document.close();
}
const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const findMeObj = Object.assign({}, getState.findMe.findMeObj);
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId
  if (findMeObj.alreadyRegistered) {
    return sendOkForAlreadyRegisteredUser(getState).pipe(map(response => handleAlreadyRegisteredResponse(response)),
      catchError(err => [(getErrorMessage(err))]));
  } else if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}
/*********************************Update radio option value******************************************************* */

const updateRadioChangeInfo = (payload, getState) =>
  updateRadioChangeInfoHandler(payload, getState)
    .pipe(map(setFindMeFieldSuccess));

 const updateRadioChangeInfoHandler = (payload,getState) =>{
   let findMeObj = Object.assign({}, getState.findMe.findMeObj);
   findMeObj.showAlert = false;
   findMeObj.showAlertMessage = '';
   findMeObj.contractorEnableFlag = false;
    if(getState.registration.contractorEnableFlag){
      findMeObj.contractorEnableFlag = true;
    }
   if (payload === constants.EMAIL_PHONE_RADIO) {
    findMeObj.page = constants.EMAILPHONESEARCH_PAGE;
    findMeObj.radioOption = constants.EMAIL_PHONE_RADIO;
    if(findMeObj.sivOptions !== undefined && findMeObj.sivOptions !== null) {
      findMeObj.sivOptions["siv_firstname"].idenDataValue = '';
      findMeObj.sivOptions["siv_lastname"].idenDataValue = '';
      findMeObj.sivOptions["siv_empid"].idenDataValue = '';
    }    
    let code = '';
    return of({
    findMeObj,code
    });
   } else {
    findMeObj.page = constants.PII_PAGE;
    findMeObj.radioOption = constants.PII_RADIO;    
    return of({
    findMeObj
    });
  }  
 }

/***********************************Init load Contractor otions********************************************** */

const getContractorOptions = (getState) =>
  getContractorOptionsApi(getState)
    .pipe(map(response => handleContractorOptionsResponse(response.response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const getContractorOptionsApi = (getState) => ajax({
  url: constants.contractorOptionsEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleContractorOptionsResponse = (response, getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  findMeObj = {};
  findMeObj.isCodelessVariant = getState.registration.codelessVariantEnabled;
  findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Search, getState);
  findMeObj.showDiv = true;
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = null;

  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);
  contractorSivObj.showMultiOrgs = false;
  if (response.sivOptions !== null && response.sivOptions !== undefined) {
      contractorSivObj.sivOptions = response.sivOptions;
      contractorSivObj.sivOptionsCalenderItems = response.sivOptionsCalenderItems;
      findMeObj.page = constants.CONTRACTOR_PAGE;
      contractorSivObj.radioOption = constants.SSN;
      contractorSivObj.firstNameValid = true;
      contractorSivObj.lastNameValid = true;
      contractorSivObj.zipcodeValid = true;
      contractorSivObj.ssnValid = true;
      contractorSivObj.tinValid = true;
      contractorSivObj.companyNameValid = true;
      gaCategory = "WEB_REG_CODELESS_CONTRACTOR";
      let pagePath = '/ssr/flowType/findme/contractor';
      let pageView = pagePath + "/web";
      if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CODELESS_CONTRACTOR"; }
      if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CODELESS_CONTRACTOR"; }
      ReactGA.pageview(pageView);
      PendoUtil.pendoUpdate(pageView);    
  } else {
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, findMeObj
    });
  }
  return setFindMeFieldSuccess({
    findMeObj, contractorSivObj, gaCategory, isMobileBrowser, isMobile
  });
}

/****************SIV options page ********************/

const setContractorSivField = (payload, getState) =>
  validateAndSetContractorSivField(payload, getState)
    .pipe(map(setSivFieldSuccessSent),
      catchError(err => [handleSetContractorSivFail(payload, getState,getErrorMessage(err))]));
  
const validateAndSetContractorSivField = (payload, getState) => {
  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);
 
  switch (payload.fieldKey) {
    case constants.RADIO : {
        return of({
          contractorSivObj,
          radioValue : payload.fieldValue
        });
    }
    case contractorSivObj.sivOptions['siv_firstname'].idenDataId : {
      const firstNameRegex = new RegExp(contractorSivObj.sivOptions['siv_firstname'].regExp);

      if (isBlank(payload.fieldValue) || firstNameRegex.test(payload.fieldValue)) {
        contractorSivObj.firstName = payload.fieldValue;
        contractorSivObj.firstNameValid = true;
        return of({
          contractorSivObj
        });
      }
       return throwError(new Error(contractorSivObj.sivOptions['siv_firstname'].invalidMessage));
    }
    case contractorSivObj.sivOptions['siv_lastname'].idenDataId : {
      const lastNameRegex = new RegExp(contractorSivObj.sivOptions['siv_lastname'].regExp);

      if (isBlank(payload.fieldValue) || lastNameRegex.test(payload.fieldValue)) {
        contractorSivObj.lastName = payload.fieldValue;
        contractorSivObj.lastNameValid = true;
        return of({
          contractorSivObj
        });
      }
       return throwError(new Error(contractorSivObj.sivOptions['siv_lastname'].invalidMessage));
    }
     case contractorSivObj.sivOptions['siv_ssn'].idenDataId : {
      const ssnRegex = new RegExp(contractorSivObj.sivOptions['siv_ssn'].regExp);

      if (isBlank(payload.fieldValue) || ssnRegex.test(payload.fieldValue)) {
        var replacedSSN = payload.fieldValue.replace(/-/g, "");
        contractorSivObj.ssn = replacedSSN;
        contractorSivObj.ssnValid = true;
        contractorSivObj.tin = '';
        contractorSivObj.companyName = '';
        return of({
          contractorSivObj
        });
      }
       return throwError(new Error(contractorSivObj.sivOptions['siv_ssn'].invalidMessage));
    }
    case contractorSivObj.sivOptions['siv_tin'].idenDataId : {
      const tinRegex = new RegExp(contractorSivObj.sivOptions['siv_tin'].regExp);

      if (isBlank(payload.fieldValue) || tinRegex.test(payload.fieldValue)) {
        contractorSivObj.tin = payload.fieldValue;
        contractorSivObj.tinValid = true;
        contractorSivObj.ssn = ''
        return of({
          contractorSivObj
        });
      }
       return throwError(new Error(contractorSivObj.sivOptions['siv_tin'].invalidMessage));
    }
    case contractorSivObj.sivOptions['siv_zipcode'].idenDataId : {
      const zipcodeRegex = new RegExp(contractorSivObj.sivOptions['siv_zipcode'].regExp);

      if (isBlank(payload.fieldValue) || zipcodeRegex.test(payload.fieldValue)) {
        contractorSivObj.zipcode = payload.fieldValue;
        contractorSivObj.zipcodeValid = true;
        return of({
          contractorSivObj
        });
      }
       return throwError(new Error(contractorSivObj.sivOptions['siv_zipcode'].invalidMessage));
    }
    case contractorSivObj.sivOptions['siv_companyname'].idenDataId : {
      const companynameRegex = new RegExp(contractorSivObj.sivOptions['siv_companyname'].regExp);

      if (isBlank(payload.fieldValue) || companynameRegex.test(payload.fieldValue)) {
        contractorSivObj.companyName = payload.fieldValue;
        contractorSivObj.companyNameValid = true;
        contractorSivObj.ssn = '';
        return of({
          contractorSivObj
        });
      }
       return throwError(new Error(contractorSivObj.sivOptions['siv_companyname'].invalidMessage));
    }
    case constants.SIV_MONTH: {
      contractorSivObj.fullMonth = payload.fieldValue;
      contractorSivObj.fullDay = '';
      return of({
        contractorSivObj
      });
    }
    case constants.SIV_DAY: {
      contractorSivObj.fullDay = payload.fieldValue;
      return of({
        contractorSivObj
      });
    }
    case constants.SIV_YEAR: {
      contractorSivObj.fullYear = payload.fieldValue;
      return of({
        contractorSivObj
      });
    }
  }
};  

const handleSetContractorSivFail = (payload,getState, errorMessage) => {
  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);

  switch (payload.fieldKey) {
    case contractorSivObj.sivOptions["siv_firstname"].idenDataId:{
        contractorSivObj.firstNameValid = false;
        contractorSivObj.firstNameErrorMsg = errorMessage;
        contractorSivObj.firstName = payload.fieldValue;
        return setSivFieldFailSent({
          contractorSivObj
        });
     }
     case contractorSivObj.sivOptions["siv_lastname"].idenDataId:{
        contractorSivObj.lastNameValid = false;
        contractorSivObj.lastNameErrorMsg = errorMessage;
        contractorSivObj.lastName = payload.fieldValue;
        return setSivFieldFailSent({
          contractorSivObj
        });
     }
   case contractorSivObj.sivOptions["siv_ssn"].idenDataId:{
        contractorSivObj.ssnValid = false;
        contractorSivObj.ssnErrorMsg = errorMessage;
        contractorSivObj.ssn = payload.fieldValue;
        return setSivFieldFailSent({
          contractorSivObj
        });
     }
    case contractorSivObj.sivOptions["siv_tin"].idenDataId:{
        contractorSivObj.tinValid = false;
        contractorSivObj.tinErrorMsg = errorMessage;
        contractorSivObj.tin = payload.fieldValue;
        return setSivFieldFailSent({
          contractorSivObj
        });
     }
    case contractorSivObj.sivOptions["siv_zipcode"].idenDataId:{
        contractorSivObj.zipcodeValid = false;
        contractorSivObj.zipcodeErrorMsg = errorMessage;
        contractorSivObj.zipcode = payload.fieldValue;
        return setSivFieldFailSent({
          contractorSivObj
        });
     } 
     case contractorSivObj.sivOptions["siv_companyname"].idenDataId:{
        contractorSivObj.companyNameValid = false;
        contractorSivObj.companyNameErrorMsg = errorMessage;
        contractorSivObj.companyName = payload.fieldValue;
        return setSivFieldFailSent({
          contractorSivObj
        });
     }
    default: {
      return setSivFieldFailSent({
        errorMessage,
      });
    }
  }
};

/***********************************Verify Contractor SIV data*********************************************** */

const verifyContractorSivData = (getState) =>
  verifyContractorSivOptions(getState)
    .pipe(map(response => handleVerifyContractorSivResponse(response.response, getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const verifyContractorSivOptions = (getState) => {
  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);
  const sivOptions = contractorSivObj.sivOptions;
  let firstName = '';
  if (sivOptions.siv_firstname && contractorSivObj.firstName != '') {
    firstName = contractorSivObj.firstName;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'FIRST_NAME');
  }
  let lastName = '';
  if (sivOptions.siv_lastname && contractorSivObj.lastName != '') {
    lastName = contractorSivObj.lastName;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'LAST_NAME');
  }
  let zipcode = '';
  if (sivOptions.siv_zipcode && contractorSivObj.zipcode != '') {
    zipcode = contractorSivObj.zipcode;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'ZIP_CODE');
  }
  let fulldob = '';
  if (sivOptions.siv_fullhiredate && contractorSivObj.fullDay != '') {
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'HIRE_DATE');
    var inputmonthId = contractorSivObj.sivOptionsCalenderItems.fullMonthItems.indexOf(contractorSivObj.fullMonth) + 1;
    inputmonthId = ('0' + inputmonthId).slice(-2);
    fulldob = '' + contractorSivObj.fullYear + "-" + inputmonthId + "-" + contractorSivObj.fullDay;
  }
  let legalId = '';
  if (sivOptions.siv_ssn && contractorSivObj.ssn != '') {
    legalId = contractorSivObj.ssn;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'SSN');
  }
  if (sivOptions.siv_tin && contractorSivObj.tin != '') {
    legalId = contractorSivObj.tin;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'TIN');
  }
  let companyName = '';
  if (sivOptions.siv_companyname && contractorSivObj.companyName != '') {
    companyName = contractorSivObj.companyName;
    ReactGA.sendevent(getState.findMe.gaCategory, 'SUBMITTED', 'COMPANY_NAME');
  }
  var payload = {};
  if(sivOptions.siv_ssn && contractorSivObj.ssn != '') {
    payload= {
          'firstName':  firstName,
          'lastName': lastName,
          'hireDate': fulldob,
          'legalIdentifier': legalId,
          'zipCode': zipcode,
          'radioOptionValue':constants.SSN
        }
  }
  if(sivOptions.siv_tin && contractorSivObj.tin != '') {
    payload= {
          'firstName':  firstName,
          'lastName': lastName,
          'hireDate': fulldob,
          'legalIdentifier': legalId,
          'zipCode': zipcode,
          'companyName': companyName,
          'radioOptionValue':constants.TIN
        }
  }

  return ajax({
    url: constants.verifyContractorSivEndPoint,
    body: payload,
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      "cache-control": "no-cache",
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
};
      
const handleVerifyContractorSivResponse = (response, getState) => {
  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = null;

  if (response.code === constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_CONTRACTOR_SIV', 'SUCCESS');
    //when identity verification is success and multiorgs found
    if (response.clientNames != undefined && response.clientNames != null) {
      // progress bar start
      findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
      // progress bar end
      ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_CONTRACTOR_SIV_SUBMIT', 'MULTI_ORG');
      var multiOrgs = [];
      var multiOrgIndex = [];
      let k = 0;
      for (var key in response.clientNames) {
        multiOrgs[k] = response.clientNames[key];
        multiOrgIndex[k] = key;
        k++;
      }
      contractorSivObj.showAlert = false;
      contractorSivObj.showAlertMessage = '';
      contractorSivObj.multiOrgs = multiOrgs;
      contractorSivObj.multiOrgIndex = multiOrgIndex;
      contractorSivObj.showMultiOrgs = true;
      gaCategory = "WEB_REG_CONTRACTOR_MULTIORG";
      let pagePath = '/ssr/flowType/contractor/multiorg';
      let pageView = pagePath + "/web";
      if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REG_CONTRACTOR_MULTIORG"; }
      if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROWSER_REG_CONTRACTOR_MULTIORG"; }
      ReactGA.pageview(pageView);
      PendoUtil.pendoUpdate(pageView);

      return setFindMeFieldSuccess({
        findMeObj,  contractorSivObj, gaCategory , isMobile , isMobileBrowser
      });
    }
    //when identity verification is success and single usercase-> call contractorverifyUser
     contractorSivObj.showMultiOrgs = false;
     contractorSivObj.verifyUserCall = true;
    return setFindMeFieldSuccess({
     contractorSivObj
    });
  }
  //when identity verification fails
  if (response.code != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_CONTRACTOR_SIV', response.code);
    if (response.code === constants.FINDME_USER_NOT_FOUND) {
      contractorSivObj.showAlert = true;
      contractorSivObj.showAlertMessage = response.message;
      return verifySivDataSuccessSent({
        contractorSivObj
      });
    }
    if (response.code === constants.ALREADY_REGISTERED) {
      findMeObj.alreadyRegistered = true;
      return cancelAndRedirectSuccess ({
        findMeObj      
      })
    }
  }
  
  if (response.title !== undefined && response.message !== undefined) {
       contractorSivObj.ssn = '';
      return verifySivDataSuccessSent({
        showModalWarn: true,
        showModalTitle: response.title,
        showModalMessage: response.message,
        contractorSivObj
      });
    }

   ReactGA.sendevent(getState.findMe.gaCategory, 'VERIFY_CONTRACTOR_SIV_SUBMIT', 'FAILED');
    return verifySivDataSuccessSent({
      showModalMessage: getState.intl.messages['Err_SystemErrorinRequest'],
      showModalWarn: true, 
      showModalTitle: getState.intl.messages['Txt_VGeneralErrorWindowTitle'], findMeObj,emailPhoneSearchSivObj
    });
};

/********************************Contractor Multi orgs- org selected api****************************************************** */

const contractorOrgSelected = (payload, getState) => {
  var selectedOrgIndex = getState.findMe.contractorSivObj.multiOrgIndex[payload];
  var selectedOrg = getState.findMe.contractorSivObj.multiOrgs[payload]
  return contractorOrgSelectedApi(selectedOrgIndex, getState)
    .pipe(map(response => contractorOrgSelectedHandler(response.response,selectedOrg , getState)),
      catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));
}


const contractorOrgSelectedApi = (payload, getState) => ajax({
  url: constants.contractorMultiOrgApiEndPoint,
  method: 'POST',
  responseType: 'json',
  body: payload,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const contractorOrgSelectedHandler = (response,selectedOrg, getState) => {
  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let code = response.code || "500";
  if (code == constants.SUCCESS) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'ORG_PAGE', 'SUCCESS');
    contractorSivObj.verifyUserCall = true;
  }
  else if (code === constants.ALREADY_REGISTERED) {
      findMeObj.alreadyRegistered = true;
      return cancelAndRedirectSuccess ({
        findMeObj      
      })
    }
  else if (response.message !== null && response.title !== null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'ORG_PAGE', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, contractorSivObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'ORG_PAGE', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, contractorSivObj
    });
  }
  return setFindMeFieldSuccess({
    contractorSivObj
  });
}
/************************************contractor verifyUser call details********************************************* */

const contractorVerifyUserInfo = (getState) => contractorVerifyUserApi(getState).pipe(map(response => handleContractorVerifyUserResponse(response.response, getState)),
  catchError(err => [(getErrorMessage(err))]));

const contractorVerifyUserApi = (getState) => ajax({
  url: constants.contractorPodRedirectionEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const handleContractorVerifyUserResponse = (response, getState) => {
  let contractorSivObj = Object.assign({}, getState.findMe.contractorSivObj);
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let showModalWarn = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let code = response.code || "500";

  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  contractorSivObj.verifyUserCall = false;
  //if code is success then redirect to verifyPRC page
    if(code === constants.SUCCESS && response.viewId.indexOf(constants.ERROR) == -1) {
      ReactGA.sendevent(getState.findMe.gaCategory, 'CONTRACTOR_VERIFY_USER', 'SUCCESS');
      // progress bar start
      findMeObj.progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
      // progress bar end
      findMeObj.clientName = response.clientName;
      findMeObj.firstName = response.firstName;
      contractorSivObj.showAlert = false;
      contractorSivObj.showAlertMessage = '';
      return setFindMeFieldSuccess({
          viewId: response.viewId,
          findMeObj,contractorSivObj,
          sendRedirect:true
        });
    }
  else if (code === constants.ALREADY_REGISTERED) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CONTRACTOR_VERIFY_USER', 'FAILED');
    findMeObj.alreadyRegistered = true;
    return cancelAndRedirectSuccess ({
      findMeObj      
    })
  }
  else if (response.message != null && response.title != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CONTRACTOR_VERIFY_USER', 'FAILED');
    showModalMessage = response.message;
    showModalWarn = true;
    showModalTitle = response.title;
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, contractorSivObj
    });
  }
  else if (response.message != null) {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CONTRACTOR_VERIFY_USER', 'ERROR');
    contractorSivObj.showAlertMessage = response.message;
    showModalWarn = false;
    contractorSivObj.showAlert = true;
    return setFindMeFieldSuccess({
      showModalWarn, contractorSivObj
    });
  }
  else {
    ReactGA.sendevent(getState.findMe.gaCategory, 'CONTRACTOR_VERIFY_USER', 'FAILED');
    showModalMessage = getState.intl.messages['Err_SystemErrorinRequest'];
    showModalWarn = true;
    showModalTitle = getState.intl.messages['Txt_VGeneralErrorWindowTitle'];
    return setFindMeFieldSuccess({
      showModalMessage, showModalWarn, showModalTitle, contractorSivObj
    });
  }
 
}

const registrationLessFlow = (ajax, getState) => registrationLessFlowApi(ajax, getState).pipe(
  map(response => handleReglessRedirectResponse(response.response, getState)),
  catchError(err => [getFindMeFailureEpic(getErrorMessage(err))]),
);

const registrationLessFlowApi = (ajax, getState) => ajax({
  url: constants.getRegistrationlessEndPoint,
  method: 'POST',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId,
  },
});

const handleReglessRedirectResponse = (response, getState) => {
  const findMeObj = Object.assign({}, getState.findMe.findMeObj);
  const url = response.targetUrl;
  const showAlert = false;
  let showModalTitle = null;
  let showModalMessage = null;
  let errorCode = null;
  if (response.code == constants.SUCCESS && url !== undefined) {
   // ReactGA.sendevent(getState.findMe.gaCategory, 'REGISTRATION_LESS', 'SUCCESS');
  
   Cookies.set(constants.FIRSTNAME_COOKIE, findMeObj.firstName, {
    expires: constants.cookieExpiry,
    domain: constants.cookieDomain,
    path: constants.cookiePath,
    });
    let spInfoData = findMeObj.clientName + '~' + response.mobifedUrl;
    Cookies.set(constants.SPINFO_COOKIE, spInfoData, {
      expires: constants.cookieExpiry,
      domain: constants.cookieDomain,
      path: constants.cookiePath,
    });
    return window.open(url, '_self');
  } else if (response.code == constants.TYPE_UNAUTHORIZED && response.title != undefined && response.title != null) {
    showModalTitle = response.title;
    showModalMessage = response.message;
    errorCode = response.code;
  } else {
    showModalTitle = getState.intl.messages['registrationless.errorTitle'];
    showModalMessage = getState.intl.messages['registrationless.errorMessage'];
    errorCode = '';
  }

   findMeObj.showReglessModalWarn = true;
   findMeObj.errorCode = errorCode;

  ReactGA.sendevent(getState.findMe.gaCategory, 'REGISTRATION_LESS', 'FAILED');
  return setFindMeFieldSuccess({
    findMeObj,
    showModalMessage,
    showModalTitle,
    showAlert,
    errorCode
  });
};
  
const continueRegistration = (getState) =>
  continueRegistrationFlowRes(getState)
    .pipe(map(response => continueRegistrationFlowHandler(response, getState)),
    catchError(err => [setFindMeFieldFail(getErrorMessage(err))]));

const continueRegistrationFlowRes = (getState) => {
  let findMeObj = Object.assign({}, getState.findMe.findMeObj);
  let viewId = getState.findMe.viewId;
  if(findMeObj.emailSearchFed === true) {
    viewId = constants.findMeViewId;
   if(getState.findMe.emailPhoneSearchSivObj !== undefined && getState.findMe.emailPhoneSearchSivObj.sivOptions !== undefined && getState.findMe.emailPhoneSearchSivObj.sivOptions !== null) {
    findMeObj.page = constants.EMAIL_PHONE_SIV_PAGE;
   } else {
    findMeObj.page = constants.OTP_PAGE;
   }
  }
  return of({
    findMeObj, viewId
  });
}
const continueRegistrationFlowHandler = (response,getState) => {
  let findMeObj = response.findMeObj;
  let viewId = response.viewId;
 // let sendRedirect = true;
  return continueRegistrationSuccess({
    findMeObj, viewId
  });
}
/********************************** Epics declarations ******************************************** */

export const getFindMeInfoEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getFindMeInfo(state.value))));

export const updateFieldValueEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_UPDATE_FIELD_VALUE),
  switchMap(action => sendingAsyncRequest(updateFieldValue(action.payload, state.value))));

export const submitIdentityOptionsEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_SUBMIT_IDENTITY_OPTIONS),
  switchMap(action => sendingAsyncRequest(submitIdentityOptions(state.value))));

export const resendOTPEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_RESEND_OTP),
  switchMap(action => sendingAsyncRequest(resendOTP(action.payload, state.value))));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_FINDME_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))));

export const hasRegCodeEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_HAS_REG_CODE_OPTION),
  switchMap(action => sendingAsyncRequest(hasRegCode(action.payload, state.value))));

export const orgSelectedEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_ORG_SELECTED),
  switchMap(action => sendingAsyncRequest(orgSelected(action.payload, state.value))));

export const setRedirectVerifyUserEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_VERIFY_USER),
  switchMap(action => sendingAsyncRequest(verifyUserInfo(state.value))));

export const goToMainPageEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_GO_TO_MAIN_PAGE),
  switchMap(action => sendingAsyncRequest(goToMainPage(state.value))));

export const goToPiiPageEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_GO_TO_PII_PAGE),
  switchMap(action => sendingAsyncRequest(goToPiiInfoPage(state.value))));

export const goToSearchPageEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_GO_TO_SEARCH_PAGE),
  switchMap(action => sendingAsyncRequest(goToEmailPhoneSearchPage(state.value))));

export const goToErrorOptionsPageEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_GO_TO_ERROR_OPTIONS_PAGE),
  switchMap(action => sendingAsyncRequest(goToErrorOptionsPage(state.value))));

export const getContractorOptionsEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_CONTRACTOR_OPTIONS),
  switchMap(action => sendingAsyncRequest(getContractorOptions(state.value))));

export const channelSelectEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_CHANNEL_SELECT),
  switchMap(action => sendingAsyncRequest(channelSelect(action.payload, state.value))));

export const submitAccessCodeEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_VERIFY_OTP),
  switchMap(action => sendingAsyncRequest(submitAccessCode(state.value))));

export const onSetFinalSubmitEpic = (action$, state) => action$.pipe(
  ofType(ON_FINAL_FIND_ME_SUCCESS),
  switchMap(action$ => of(push(state.value.findMe.viewId))));

export const getFindMeFailureEpic = action$ => action$.pipe(
  ofType(FIND_ME_FAIL),
  switchMap(action$ => of(push('/error'))));

export const getPiiInfoEpic = (action$, state) => action$.pipe(
  ofType(FIND_ME_PII_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getPiiOptions(state.value))));

export const setLocaleEpic = (action$, state) => action$.pipe(
    ofType(FIND_ME_SET_LOCALE),
    switchMap(action => sendingAsyncRequest(setLocale(action.payload, state.value))));

export const onRedirectToIdentityEpic = (action$, state) => action$.pipe(
  ofType(REDIRECT_TO_IDENTITY_PAGE),
  switchMap(action => sendingAsyncRequest(redirectToIdentityPage(action.payload,state.value))));

export const onSetFinalRedirectionEpic = (action$, state) => action$.pipe(
  ofType(REDIRECT_TO_IDENTITY_PAGE_SUCCESS),
  switchMap(action$ => of(push(state.value.findMe.viewId))));

export const updateRadioEpic = (action$, state) => action$.pipe(
  ofType(UPDATE_PAGE_DETAILS),
  switchMap(action => sendingAsyncRequest(updateRadioChangeInfo(action.payload,state.value))));

export const setSivFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_FINDME_SIV_FIELD),
  switchMap(action => sendingAsyncRequest(setSivField(action.payload, state.value))));  

export const verifySivDataEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_SIV_DATA),
  switchMap(action => sendingAsyncRequest(verifySivData(state.value))));

export const setContractorSivFieldEpic = (action$, state) => action$.pipe(
  ofType(SET_CONTRACTOR_SIV_FIELD),
  switchMap(action => sendingAsyncRequest(setContractorSivField(action.payload, state.value))));  

export const verifyContractorSivDataEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_CONTRACTOR_SIV_DATA),
  switchMap(action => sendingAsyncRequest(verifyContractorSivData(state.value))));

export const contractorOrgSelectedEpic = (action$, state) => action$.pipe(
  ofType(CONTRACTOR_ORG_SELECTED),
  switchMap(action => sendingAsyncRequest(contractorOrgSelected(action.payload, state.value))));

export const setRedirectContractorVerifyUserEpic = (action$, state) => action$.pipe(
  ofType(CONTRACTOR_VERIFY_USER),
  switchMap(action => sendingAsyncRequest(contractorVerifyUserInfo(state.value))));

export const setReglessRedirectEpic = (action$, state, {ajax}) => action$.pipe(
    ofType(REGISTRATION_LESS_FLOW),
    switchMap(action => sendingAsyncRequest(registrationLessFlow(ajax, state.value))),
  );

export const continueRegRedirectEpic = (action$, state, {ajax}) => action$.pipe(
    ofType(CONTINUE_REGISTRATION_FLOW),
    switchMap(action => sendingAsyncRequest(continueRegistration(state.value))),
  );

export const onSetContinueRegEpic = (action$, state) => action$.pipe(
  ofType(CONTINUE_REGISTRATION_FLOW_SUCCESS),
  switchMap(action$ => of(push(state.value.findMe.viewId))));