import React, { Component } from 'react';
import {
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  DropdownButton
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { ReactGA, WindowUtil } from '../../util';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import BuildingIcon from 'adp-react-icons/lib/fa/building-o';
import CountryCodeDropdown from '../../components/country-code';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import { constants } from '../../redux/ducks/find-me/find-me-constants';
import EmailPhoneInput from '../../components/email-phone-input';
import LockIcon from 'adp-react-icons/lib/fa/lock';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import FindMeEmailPhoneSivForm from './FindMeEmailPhoneSivForm';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
var ua = window.navigator.userAgent;
var isIE = (ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident") > -1 || ua.indexOf("Edge") > -1) ? true : false;
let overlayPlacement = "right";
let showAlertFlag = false;
let isFirstTimeLoad = false;
let setAutoFocusOff;

class FindMeForm extends Component {

  componentWillMount() {
    if (!isFirstTimeLoad)
      this.props.getFindMeInfo();
    isFirstTimeLoad = true;
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sendRedirect)
      nextProps.setRedirect();
    if (nextProps.findMeObj.showAlert && showAlertFlag) {
      showAlertFlag = false;
      window.scrollTo(0, 0);
    }
    const redirectToPiiFLow = nextProps.findMeObj.redirectToPiiPage || false;
    if (redirectToPiiFLow) {
      nextProps.getPiiOptions();
      nextProps.findMeObj.redirectToPiiPage = false;
    }
    if (nextProps.findMeObj.page == constants.PII_PAGE) {
      this.checkFormInfoReadyness(nextProps);
    }
    else if (nextProps.findMeObj.page == constants.EMAILPHONESEARCH_PAGE && nextProps.findMeObj.searchType == constants.EMAIL) {
      this.checkFormEmailReadyness(nextProps);
    }
    else if (nextProps.findMeObj.page == constants.EMAILPHONESEARCH_PAGE && nextProps.findMeObj.searchType == constants.PHONE) {
      this.checkFormPhoneReadyness(nextProps);
    }
    else if (nextProps.findMeObj.page == constants.OTP_PAGE) {
      this.checkEmailPhoneOTP(nextProps);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.findMeObj != undefined) {
      const nextProps = this.props
      const nextVerifyUser = nextProps.findMeObj.verifyUserCall || false;
      const prevVerifyUser = oldProps.findMeObj.verifyUserCall || false;

      if (nextVerifyUser && !prevVerifyUser) {
        nextProps.setRedirectVerifyUser();
      }
    }

  }

  checkEmailPhoneOTP = (nextProps) => {
    let formValid = true;
    if (isBlank(nextProps.findMeObj.otpValue)) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  }

  checkFormEmailReadyness = (nextProps) => {
    let formValid = true;
    if (isBlank(nextProps.findMeObj.email) || !nextProps.findMeObj.emailPhoneValid) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  }

  checkFormPhoneReadyness = (nextProps) => {
    let formValid = true;
    if (isBlank(nextProps.findMeObj.phone) || !nextProps.findMeObj.emailPhoneValid) {
      formValid = false;
    }
    this.props.enableSubmitButton(formValid);
  }

  checkFormInfoReadyness = (nextProps) => {
    let formValid = true;

    if (nextProps.findMeObj.sivOptions) {

      if (nextProps.findMeObj.sivOptions["siv_firstname"] &&
        (isBlank(nextProps.findMeObj.sivOptions["siv_firstname"].idenDataValue)
          || nextProps.findMeObj.errorFields["siv_firstname"].validationError)) {
        formValid = false;
      }

      if (nextProps.findMeObj.sivOptions["siv_lastname"] &&
        (isBlank(nextProps.findMeObj.sivOptions["siv_lastname"].idenDataValue)
          || nextProps.findMeObj.errorFields["siv_lastname"].validationError)) {
        formValid = false;
      }

      if (nextProps.findMeObj.sivOptions["siv_fulldateofbirth"] &&
        (isBlank(nextProps.findMeObj.fullMonth) || isBlank(nextProps.findMeObj.fullDay) || isBlank(nextProps.findMeObj.fullYear))) {
        formValid = false;
      }

      {/*** Radio option check**/ }

      if (nextProps.findMeObj.selectedCountry == nextProps.findMeObj.countryDropdown[0]) {
        if (nextProps.findMeObj.radio1 == "empId" &&
          (isBlank(nextProps.findMeObj.sivOptions["siv_empid"].idenDataValue)
            || nextProps.findMeObj.errorFields["siv_empid"].validationError)) {
          formValid = false;
        }
        else if (nextProps.findMeObj.radio1 == "ssn" &&
          (isBlank(nextProps.findMeObj.sivOptions["siv_ssn"].idenDataValue)
            || nextProps.findMeObj.errorFields["siv_ssn"].validationError)) {
          formValid = false;
        }

      }

      if (nextProps.findMeObj.selectedCountry != nextProps.findMeObj.countryDropdown[0] &&
        (isBlank(nextProps.findMeObj.sivOptions["siv_empid"].idenDataValue)
          || nextProps.findMeObj.errorFields["siv_empid"].validationError)) {
        formValid = false;
      }

    }
    this.props.enableSubmitButton(formValid);
  };

  handleTooltipClick = (e) => {
    e.stopPropagation();
    ReactGA.sendevent(this.props.gaCategory, 'REGCODE', 'TOOLTIP');
  }


  render() {
    const {
      findMeObj,
      isMobileBrowser,
      isMobile,
      gaCategory,
      updateCountryOption,
      updateFieldValue,
      isFormValid,
      submitIdentityOptions,
      showModalMessage,
      showModalWarn,
      showModalTitle,
      cancelAndCheckYourEntries,
      hasRegCode,
      orgSelected,
      goToMainPage,
      goToPiiInfoPage,
      channelSelect,
      submitAccessCode,
      resendOTP,
      getPiiOptions,
      setPrevPage,
      prevPage,
      goToErrorOptionsPage,
      goToEmailPhoneSearchPage,
      errorPageInfo,
      setErrorPageInfo,
      redirectToContactInfo,
      code,
      setLocale,
      changeLocale,
      redirectToIdentityPage,
      emailPhoneSearchSivObj
    } = this.props;

    const updateFieldValueHandler = (fieldName, fieldValue, isDateField) => {
      let payload = {};
      isDateField = isDateField || false;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.isDateField = isDateField;
      return updateFieldValue(payload);
    }

    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );
    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="findmeErrorPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{msg}</div>
        </Popover>
      ));

    const handleEmailPhoneChange = (value) => {
      const obj = {};
      obj.fieldName = value.type;
      obj.fieldValue = value.value;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "EMAIL_PHONE_SEARCH" });
      if (value.type != undefined && value.type != null && value.type === constants.PHONE) {
        obj.fieldValue = value.phoneValue;
        obj.isoCode = value.iso;
        obj.ccode = value.dialCode;
      }
      obj.isValid = value.isValid;
      return updateFieldValue(obj);
    }

    const updatePrevPage = (value) => {
      let prevPage = value;
      setPrevPage(prevPage);
      return getPiiOptions();
    }

    const _handleSearchKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'SUBMIT');
      if (this.props.findMeObj.searchType == constants.EMAIL) {
        let payload = {};
        payload.value = findMeObj.email;
        payload.endPoint = constants.findUserbyEmail;
        ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'EMAIL');
        return channelSelect(payload);
      }
      else if (this.props.findMeObj.searchType == constants.PHONE) {
        let payload = {};
        let value = {};
        value.siv_mobile = findMeObj.phone;
        value.siv_country = findMeObj.isoCode;
        payload.value = value;
        payload.endPoint = constants.findUserbyPhone;
        ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'PHONE');
        return channelSelect(payload);
      }
    }

    const _handlePiiKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'PII_INFO', 'SUBMIT');
      this.props.submitIdentityOptions();
    }

    const _handleOtpKeyPress = (e) => {
      e.preventDefault();
      ReactGA.sendevent(gaCategory, 'OTP_CODE', 'SUBMIT');
      this.props.submitAccessCode();
    }


    return (
      findMeObj.showDiv && (<div>
        <div className="registration-content">

          <div className="registration-form-container" >
            {(window.innerWidth > 479) && <div className="findme-securepage"> <SecurePage gaCategory={gaCategory} /></div>
            }
            {
              (window.innerWidth <= 479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
            }
            {(!isMobile) && (findMeObj.page == constants.LANDING_PAGE) && (

              <div className="registration-lang-dropdown">

                <DropdownButton 
                  id="findMe_langLocale"
                  valueField="language"
                  textField="language"
                  buttonStyle='link'
                  data={WindowUtil.getLocaleList()}
                  onSelect={(valueField) => {
                    changeLocale(valueField.suffix);
                    ReactGA.sendevent(gaCategory, 'LANGUAGE_DROPDOWN_SELECT', valueField.suffix);
                    return setLocale(valueField)

                  }}>
                  <span style={{width:'100px'}}>{findMeObj.locale}</span>
                </DropdownButton>
              </div>

            )}
            {(!isMobile) && (findMeObj.page != constants.LANDING_PAGE) && (
              <div className="registartion-cancel-icon-container">
                <svg id="findMe_cancel" role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })} onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CODELESS', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor" style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block", overflow: "visible"
                }}><g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>

            )}
          </div>

          {(findMeObj.page != constants.LANDING_PAGE && findMeObj.progressBarData != undefined && findMeObj.progressBarData.showProgressBar && !isMobile) && (
            <div className="registration-progress-bar">
              <Stepper steps={findMeObj.progressBarData.steps} activeStep={findMeObj.progressBarData.activeStep} />
            </div>
          )}

          {(!isMobile) && <hr style={{ borderTop: "1px solid #BEBEBE" }} />
          }
          <div className="registration-form padding-align-allflows">
            <div className="welcome-title title-width ev-margin-h">
              <div className="vdl-col-md-12">


              </div>
              <div className="vdl-col-md-12">

                {(findMeObj.page == constants.LANDING_PAGE) && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-lp-signup-title">{<FormattedMessage id="findMeWeb.signUptitle" />}</h1>
                  <div className="div-h5 fm-web-landingpage"> {<FormattedMessage id="findMeWeb.Landingpage1" />}</div>
                  <div className="div-h5 fm-web-landingpage"> {<FormattedMessage id="findMeWeb.Landingpage2" />}</div>
                </div>)}

                {(findMeObj.page == constants.EMAILPHONESEARCH_PAGE) && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="findMe.emailPhoneSearch.title" />}</h1>
                </div>)}

                {findMeObj.page == constants.OTP_PAGE && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="otp.title" />}</h1>
                </div>)}

                {findMeObj.page == constants.IDENTIFIED_PAGE && (<div>
                  <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="identified.title" values={{ 0: WindowUtil.firstNameFormat(findMeObj.sivOptions["siv_firstname"].idenDataValue) }} />}</h1>
                </div>)}

                {(findMeObj.page == constants.MULTIORG_PAGE) && (
                  <div>
                    <h1 id="registration-layout_welcome" className="page-title fm-emailsearch-title" >{<FormattedMessage id="findMe.multiorg.title" values={{ 0: WindowUtil.firstNameFormat(findMeObj.sivOptions["siv_firstname"].idenDataValue) }} />}</h1>
                    <div className="fm-centre">{<FormattedMessage id="findMe.multiorg.subTitle" />}</div>
                  </div>
                )}

                {(findMeObj.page === constants.ERROR_OPTIONS_PAGE) && (
                  <div>
                    {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && (<h1 id="registration-layout_welcome" className="page-title fm-error-options-page">{<FormattedMessage id="findMeWeb.title" />}</h1>)}

                    {(findMeObj.redirectionFrom === constants.REGCODE_PAGE) && (<div>
                      <h1 id="registration-layout_welcome" className="fm-regcode-page-options" >
                        {<FormattedMessage id="invalidAttempts_RegcodeOptionsPage" />}</h1>
                      <div className="fm-invalid-attempts-options-page">{<FormattedMessage id="invalidAttemptsInfo_RegcodeOptionsPage" />}</div></div>)}

                 {(findMeObj.redirectionFrom === constants.ORC_IDENTITY_PAGE) && (<div>
                   <h1 id="registration-layout_welcome" className="fm-regcode-page-options">
                   {<FormattedMessage id="invalidAttempts_IdentityOptionsPage" />}</h1>
                <div className="fm-invalid-attempts-options-page">{<FormattedMessage id="invalidAttemptsInfo_IdentityOptionsPage" values={{ 0 : <b>{findMeObj.companyName}</b>}}/>}</div></div>)}

                  </div>
                )}

                {(findMeObj.page == constants.PII_PAGE) && (
                  <div className="fm-centre">
                    <h1 id="registration-layout_welcome" className="fm-pii-page">{(code !== undefined && code === constants.ALREADY_REGISTERED) ? errorPageInfo : <FormattedMessage id="findMeWeb.title" />}</h1>
                  </div>
                )}
              </div>
            </div>

            {/*********************Find Me main page start *****************************/}
            {(findMeObj.page == constants.LANDING_PAGE) && (<div className="fm-form-padding" style={{ paddingBottom: "0 !important" }}>
              <div
                id="regcodeNo"
                className="form-group submit vdl-text-xs-center submit-button-align"
              >
                <Button type="submit" buttonStyle="primary" style={{
                  width: window.innerWidth <= 479 ? '100%' : "83%", fontSize: '12px', height: "40px",
                }}
                  id="findMe_findmeButton" className="safari-button-align"
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'SUBMIT');
                    return hasRegCode("EMAIL_OR_PHONE");
                  }}>
                  <div style={{ textAlign: 'center' }}>{<FormattedMessage id="findMe.newUserSearchButton" />}</div>
                </Button>
              </div>

              <div
                id="regcodeYes"
                className="form-group submit vdl-text-xs-center submit-button-align"
              >
                <Button type="submit" buttonStyle="secondary" style={{
                  width: window.innerWidth <= 479 ? '100%' : "83%", fontSize: '12px', height: "40px",
                }}
                  id="findMe_regcodeButton" className="safari-button-align"
                  onClick={() => {
                    isFirstTimeLoad = false;
                    ReactGA.sendevent(gaCategory, 'HAVE_REG_CODE', 'SUBMIT');
                    return hasRegCode("REGCODE");
                  }}>
                  {<FormattedMessage id="findMe.haveRegCodeButton" />}
                </Button>
              </div>
              {((isMobileBrowser && !isMobile) || window.innerWidth > 479) && (<Button
                id="landingPage_old_backBtn"
                style={{ paddingBottom: "0px" }}
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'CODELESS_LANDING', 'BACK');
                  return cancelAndCheckYourEntries();
                }}
              >
                  <ChevronLeft className="back-icon-align"  />
                  <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button>)}

            </div>)}

            {/**************************find me page 2 email/phone search***************/}
            {(findMeObj.page == constants.EMAILPHONESEARCH_PAGE) && (<div className="fm-form-code-padding">
              {(findMeObj.showAlert) && (<Alert style={{ width: '100%' }} type="error" id="searchalert" >
                <p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(findMeObj.showAlertMessage)}</p>
              </Alert>
              )}
              <form onSubmit={_handleSearchKeyPress}>
                <div>
                  <div id="emailPhoneSearch" className="fm-emailphone-page-content">{<FormattedMessage id="findMe.emailPhoneSearchInfo" />}</div>

                  <div style={{ margin: "0 auto" }} className="fm-emailphone-width">
                    <label>{<FormattedMessage id="findMe.emailPhone.placeholder" />}</label>
                    <EmailPhoneInput flagId="fm_old_PhoneCountryCd" id="emailPhone" onChange={handleEmailPhoneChange} showAutoFocus = {setAutoFocusOff} 
                      onlyCountries={findMeObj.onlyCountries} errorMessage={this.props.intl.formatMessage({ id: 'findMe.searchValueError' })} />
                  </div>
                </div>
                <div id="noEmailPhoneAvailable" className="fm-noemailphone-div br-padding">{<FormattedMessage id="findMe.noemailAndphone" />}
                  <span style={{ display: 'inline-block', marginLeft: '-2%' }}><Button id="piiLink" className="primary cf-btn-cnt" buttonStyle="link"
                    className="cf-font-size-btn cf-cancel-btn"
                    onClick={() => {
                      ReactGA.sendevent(gaCategory, 'LINK', 'HELP_US_FIND_YOU');
                      updatePrevPage(constants.EMAILPHONESEARCH_PAGE);
                    }}>
                    {<FormattedMessage id="findMe.helpusFindLink" />}
                  </Button></span>
                </div>
                <div className="fm-continue-btn-div"><Button
                  style={{ minHeight: '35px', minWidth: '180px' }}
                  id="emailPhone_old_continueBtn" type="submit"
                  className="primary cf-btn-cnt safari-button-align"
                  disabled={!isFormValid}
                  onClick={() => {
                    showAlertFlag = true;
                  }}
                >
                  {<FormattedMessage id="findMe.continueBtn" />}
                </Button>
                </div><div className="fm-emailphonesearch-back-btn-div"><Button
                  style={{ paddingBottom: '0px' }}
                  id="emailPhone_old_backBtn"
                  className="primary cf-btn-cnt"
                  buttonStyle="link"
                  className="cf-font-size-btn cf-cancel-btn"
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'EMAIL_PHONE_SEARCH', 'BACK');
                    return goToMainPage();
                  }}
                >
                  <ChevronLeft className="back-icon-align"  />
                  <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
                </Button>
                </div>
              </form>
            </div>)}

            {/**************************Identity page after email/phone search page start***************/}

            {findMeObj.page === constants.EMAIL_PHONE_SIV_PAGE && emailPhoneSearchSivObj != undefined && emailPhoneSearchSivObj.sivOptions !== undefined && (
              <FindMeEmailPhoneSivForm {...this.props}/>
            )}


            {/**************************Identity page after email/phone search page end***************/}



            {/**************************Identified user page***************/}
            {(findMeObj.page == constants.IDENTIFIED_PAGE) && (<div className="fm-form-code-padding" style={{ textAlign: "center" }}>
              {(!findMeObj.identityText) && (

                <FormattedMessage id="findMe.identifiedUserconfirm" values={{ 0: <b>{findMeObj.clientName}</b> }} />)
              }
              {(findMeObj.identityText) && (

                <FormattedMessage id="findMe.identifiedUserenter" values={{ 0: <b>{findMeObj.clientName}</b> }} />)
              }
              
              <div className='fm-identity-margins'><Button
                style={{ minHeight: "35px", minWidth: "180px" }}
                id="findMe_iden_continueBtn" type="submit"
                className="primary cf-btn-cnt safari-button-align"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'IDENTITY_PAGE', 'CLICK');
                  redirectToContactInfo();
                }}
              >
                {<FormattedMessage id="findMe.continueBtn" />}
              </Button>
              </div>
            </div>)}

            {/*********************Find Me Pii flow page*****************************/}

            {(findMeObj.page == constants.PII_PAGE) && (<div className="fm-form-info-padding">
              {(findMeObj.showAlert) && (<Alert style={{ width: '100%' }} type="error" id="piiAlert" >
                <p role="alert" style={{marginTop: 0, marginBottom: 0}}>{ReactHtmlParser(findMeObj.showAlertMessage)}</p>
              </Alert>
              )}
              <form onSubmit={_handlePiiKeyPress}>
                {/* Web pii options start */}
                {window.innerWidth > 479 && (<div>
                  <div className="vdl-row">
                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <label id="firstnameLabel">{findMeObj.sivOptions["siv_firstname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                        <span className="vb-question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                          <OverlayTrigger placement={overlayPlacement} trigger="click"
                            overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc' }))} >
                            <InfoCircle style={{ marginBottom: "2px" }} />
                          </OverlayTrigger>
                        </span></label>
                    </div>

                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <label id="lastnameLabel">{findMeObj.sivOptions["siv_lastname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                      </label>
                    </div>
                  </div>

                  <div className="vdl-row">
                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <OverlayTrigger
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(!findMeObj.errorFields["siv_firstname"].validationError, findMeObj.sivOptions["siv_firstname"].invalidMessage)}
                      >
                        <Textbox style={{ marginTop: "5px" }}
                          id="firstname" autoFocus={setAutoFocusOff}
                          value={findMeObj.sivOptions["siv_firstname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_firstname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_firstname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_firstname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>

                    <div className="vdl-col-sm-6 vdl-col-xs-12">
                      <OverlayTrigger
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(!findMeObj.errorFields["siv_lastname"].validationError, findMeObj.sivOptions["siv_lastname"].invalidMessage)}
                      >
                        <Textbox style={{ marginTop: "5px" }}
                          id="firstname"
                          value={findMeObj.sivOptions["siv_lastname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_lastname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_lastname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_lastname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>

                  </div>

                  {/* DOB And Country Start */}

                  <div className="form-group form-align" style={{ marginTop: "15px" }}>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="fulldob" htmlFor="fulldob_Label">{findMeObj.sivOptions["siv_fulldateofbirth"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="countryLabel" htmlFor="fulldob_Label">{<FormattedMessage id="findMe.country" />}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div">
                          <div className="fm-pii-month-dropdown-div">
                            <DropdownList aria-label="monthDropdown" 
                              id="fullMonth"
                              value={findMeObj.fullMonth}
                              data={findMeObj.sivOptionsCalenderItems.fullMonthItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                                return updateFieldValueHandler("fullMonth", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-pii-day-dropdown-div">
                            <DropdownList aria-label="dayDropdown" 
                              id="fullDay"
                              value={findMeObj.fullDay}
                              data={findMeObj.sivOptionsCalenderItems.fullDayItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                                return updateFieldValueHandler("fullDay", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-pii-year-dropdown-div">
                            <DropdownList aria-label="yearDropdown" 
                              id="fullYear"
                              value={findMeObj.fullYear}
                              data={findMeObj.sivOptionsCalenderItems.fullYearItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_year' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR');
                                return updateFieldValueHandler("fullYear", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div">
                          <div className="fm-pii-country-dropdown-div">
                            <DropdownList aria-label="countryDropdown" 
                              id="country"
                              value={findMeObj.selectedCountry}
                              data={findMeObj.countryDropdown}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'COUNTRY');
                                return updateFieldValueHandler("radio2", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {/* DOB and Country End */}

                  {/* EMP and SSN start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[0] && (<div style={{ marginTop: "20px" }}>
                    <div className="vdl-row">

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="radio_empId"
                          radioGroupValue={findMeObj.radio1}
                          value="empId"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_empid"].labelName}
                        </Radio>
                      </div>

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="radio_ssn"
                          radioGroupValue={findMeObj.radio1}
                          value="ssn"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'SSN');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_ssn"].labelName}
                        </Radio>
                        <span className="fm-pii-ssn-span" onClick={() =>

                          ReactGA.sendevent(gaCategory, findMeObj.ssnType == "siv_ssn4" ? "SSN4" : "SSN", 'TOOLTIP')}>
                          <OverlayTrigger placement="left" trigger="click"
                            overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                            <InfoCircle style={{ marginBottom: "2px" }} />
                          </OverlayTrigger>
                        </span>

                      </div>
                    </div>

                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px" }}
                            id="empId"
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            disabled={findMeObj.radio1 == "ssn" ? true : false}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_ssn"].validationError, findMeObj.sivOptions["siv_ssn"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px", width: findMeObj.ssnType == "siv_ssn4" ? '30%' : '92%' }}
                            id="ssn" type="password"
                            disabled={findMeObj.radio1 == "empId" ? true : false}
                            value={findMeObj.sivOptions["siv_ssn"].idenDataValue}
                            className={!findMeObj.errorFields["siv_ssn"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_ssn"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_ssn"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                      </div>
                    </div>
                  </div>)}

                  {/* ********************* EMP and SSN End ********************* */}


                  {/* EMP start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[1] && (<div>
                    <div className="vdl-row">

                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="empLabel">{findMeObj.sivOptions["siv_empid"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px" }}
                            id="empTextBox"
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>)}
                </div>)}
                {/* EMP End */}
                {/* Web pii options End */}


                {/* Mobile pii options start */}
                {window.innerWidth <= 479 && (<div>

                  <div className="vdl-row">
                    <div className="vdl-col-xs-12">
                      <label id="firstnameLabel">{findMeObj.sivOptions["siv_firstname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                        <span className="vb-question-circle" onClick={() => ReactGA.sendevent(gaCategory, 'FIRST_NAME', 'TOOLTIP')}>
                          <OverlayTrigger placement={overlayPlacement} trigger="click"
                            overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc' }))} >
                            <InfoCircle style={{ marginBottom: "2px" }} />
                          </OverlayTrigger>
                        </span></label>
                    </div>
                    <div className="vdl-col-xs-12">
                      <OverlayTrigger
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(!findMeObj.errorFields["siv_firstname"].validationError, findMeObj.sivOptions["siv_firstname"].invalidMessage)}
                      >
                        <Textbox style={{ marginBottom: "20px" }}
                          id="firstname" autoFocus={setAutoFocusOff}
                          value={findMeObj.sivOptions["siv_firstname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_firstname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_firstname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_firstname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="vdl-row">
                    <div className="vdl-col-xs-12">
                      <label id="lastnameLabel">{findMeObj.sivOptions["siv_lastname"].labelName}
                        <span className="fm-pii-required-field-icon">*</span>
                      </label>
                    </div>
                    <div className="vdl-col-xs-12">
                      <OverlayTrigger
                        trigger={['click', 'focus']} placement="bottom"
                        overlay={errorPopover(!findMeObj.errorFields["siv_lastname"].validationError, findMeObj.sivOptions["siv_lastname"].invalidMessage)}
                      >
                        <Textbox style={{ marginBottom: "20px" }}
                          id="firstname"
                          value={findMeObj.sivOptions["siv_lastname"].idenDataValue}
                          className={!findMeObj.errorFields["siv_lastname"].validationError ? undefined : 'vdl-validation-error'}
                          onChange={(value) => {
                            WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_lastname"].idenDataId });
                            return updateFieldValueHandler(findMeObj.sivOptions["siv_lastname"].idenDataId, value);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>

                  {/* DOB And Country Start */}

                  <div className="form-group form-align" style={{ marginBottom: "20px" }}>
                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <label id="fulldob" htmlFor="fulldob_Label">{findMeObj.sivOptions["siv_fulldateofbirth"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                      <div className="vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div">
                          <div className="fm-mobile-pii-month-dropdown-div">
                            <DropdownList aria-label="monthDropdown" 
                              id="fullMonth"
                              value={findMeObj.fullMonth}
                              data={findMeObj.sivOptionsCalenderItems.fullMonthItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_month' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH');
                                return updateFieldValueHandler("fullMonth", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-mobile-pii-day-dropdown-div">
                            <DropdownList aria-label="dayDropdown" 
                              id="fullDay"
                              value={findMeObj.fullDay}
                              data={findMeObj.sivOptionsCalenderItems.fullDayItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_day' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY');
                                return updateFieldValueHandler("fullDay", value, true)
                              }}
                            />
                          </div>
                          <div className="fm-mobile-pii-day-dropdown-div">
                            <DropdownList aria-label="yearDropdown" 
                              id="fullYear"
                              value={findMeObj.fullYear}
                              data={findMeObj.sivOptionsCalenderItems.fullYearItems}
                              placeholder={this.props.intl.formatMessage({ id: 'findMe_lbl_year' })}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR');
                                return updateFieldValueHandler("fullYear", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="vdl-row" style={{ marginTop: "20px" }}>
                      <div className="vdl-col-xs-12">
                        <label id="countryLabel" htmlFor="fulldob_Label">{<FormattedMessage id="findMe.country" />}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>

                      <div className="vdl-col-xs-12">
                        <div className="fm-pii-dropdown-container-div">
                          <div className="fm-pii-country-dropdown-div">
                            <DropdownList aria-label="countryDropdown" 
                              id="country"
                              value={findMeObj.selectedCountry}
                              data={findMeObj.countryDropdown}
                              onChange={(value) => {
                                ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'COUNTRY');
                                return updateFieldValueHandler("radio2", value, true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {/* DOB and Country End */}

                  {/* EMP and SSN start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[0] && (<div >
                    <div className="vdl-row">

                      <div className="vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="radio_empId"
                          radioGroupValue={findMeObj.radio1}
                          value="empId"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'EMP_ID');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_empid"].labelName}
                        </Radio>
                      </div>
                      <div className="vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px", marginBottom: "20px" }}
                            id="empId"
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            disabled={findMeObj.radio1 == "ssn" ? true : false}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>


                    </div>

                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <Radio className="cf-radio-toggle"
                          id="radio_ssn"
                          radioGroupValue={findMeObj.radio1}
                          value="ssn"
                          inline={true}
                          onChange={(value) => {
                            ReactGA.sendevent(gaCategory, 'CLICK', 'SSN');
                            return updateFieldValueHandler("radio1", value)
                          }}
                        >
                          {findMeObj.sivOptions["siv_ssn"].labelName}
                        </Radio>
                        <span className="fm-pii-ssn-span" onClick={() => ReactGA.sendevent(gaCategory, findMeObj.ssnType == "siv_ssn4" ? "SSN4" : "SSN", 'TOOLTIP')}>
                          <OverlayTrigger placement="right" trigger="click"
                            overlay={tooltip(this.props.intl.formatMessage({ id: 'ssn_info' }))} >
                            <InfoCircle style={{ marginBottom: "2px" }} />
                          </OverlayTrigger>
                        </span>
                      </div>


                      <div className="vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_ssn"].validationError, findMeObj.sivOptions["siv_ssn"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px", width: findMeObj.ssnType == "siv_ssn4" ? '30%' : '92%' }}
                            id="ssn" type="password"
                            disabled={findMeObj.radio1 == "empId" ? true : false}
                            value={findMeObj.sivOptions["siv_ssn"].idenDataValue}
                            className={!findMeObj.errorFields["siv_ssn"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_ssn"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_ssn"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger><span className="lock-icon-align"><LockIcon /></span>
                      </div>
                    </div>
                  </div>)}

                  {/* ********************* EMP and SSN End ********************* */}


                  {/* EMP start */}
                  {findMeObj.selectedCountry == findMeObj.countryDropdown[1] && (<div>
                    <div className="vdl-row">

                      <div className="vdl-col-xs-12">
                        <label id="empLabel">{findMeObj.sivOptions["siv_empid"].labelName}
                          <span className="fm-pii-required-field-icon">*</span>
                        </label>
                      </div>
                    </div>

                    <div className="vdl-row">
                      <div className="vdl-col-xs-12">
                        <OverlayTrigger
                          trigger={['click', 'focus']} placement="bottom"
                          overlay={errorPopover(!findMeObj.errorFields["siv_empid"].validationError, findMeObj.sivOptions["siv_empid"].invalidMessage)}
                        >
                          <Textbox style={{ marginTop: "5px" }}
                            id="empTextBox"
                            value={findMeObj.sivOptions["siv_empid"].idenDataValue}
                            className={!findMeObj.errorFields["siv_empid"].validationError ? undefined : 'vdl-validation-error'}
                            onChange={(value) => {
                              WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: findMeObj.sivOptions["siv_empid"].idenDataId });
                              return updateFieldValueHandler(findMeObj.sivOptions["siv_empid"].idenDataId, value);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>)}
                </div>)}
                {/* EMP End */}
                {/* Mobile pii options End */}

               
                <div className="fm-width-100 br-padding"><Button
                  style={{ minHeight: "35px", minWidth: "180px" }}
                  id="searchPiiBtn" type="submit"
                  className="primary cf-btn-cnt safari-button-align"
                  disabled={!isFormValid}
                  onClick={() => {
                    showAlertFlag = true;
                  }}>
                  {<FormattedMessage id="findMe.searchBtn" />}
                </Button>
                </div>
                <div className="fm-emailphonesearch-back-btn-div"><Button
                  style={{ paddingBottom: "0px" }}
                  id="piibackBtn"
                  className="primary cf-btn-cnt"
                  buttonStyle="link"
                  className="cf-font-size-btn cf-cancel-btn"
                  onClick={() => {
                    ReactGA.sendevent(gaCategory, 'PII_INFO', 'BACK'); if (prevPage == constants.EMAILPHONESEARCH_PAGE) return goToEmailPhoneSearchPage();
                    else if (prevPage == constants.ERROR_OPTIONS_PAGE) return goToErrorOptionsPage();
                    else return goToMainPage();
                  }}
                >
                  <ChevronLeft className="back-icon-align"  />
                  <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
                </Button>
                </div>

              </form>
            </div>)}

            {/* Multi Org start */}
            {(findMeObj.page == constants.MULTIORG_PAGE) && (<div className="fm-multi-form-padding" style={{ marginBottom: "20px" }}>
             <div id="findMe_morg"> 
              {findMeObj.multiOrgs.map((value, index) => (
                <div className="vdl-row vf-row-size fm-row-size highlight-options" id={index} key={index} onClick={() => { ReactGA.sendevent(gaCategory, 'CLICK', 'ORG'); return orgSelected(index) }} >
                  <BuildingIcon className="vf-col-sm-2 vf-email-icon" />
                  <div className="vf-col-sm-9 vf-text-margin-left">
                    <div className="vf-text fm-multi-org-div">{value}</div>
                  </div>
                  <FaAngleRight style={{ fontSize: "24px" }} className="vf-col-sm-1 vf-chevron-icon" />
                </div>
              ))}
             </div>  
            </div>)}
            {/* Multi Org end */}

            {/* ONE TIME CODE START */}
            {(findMeObj.page == constants.OTP_PAGE) && (
              <form onSubmit={_handleOtpKeyPress} style={{ width: "100%" }}>
                <div id="verify-prc-form-2" className="vf-sub-form-padding ">
                  <div style={{ textAlign: "center", paddingBottom: "3%" }} id="verify-otp-info-2" >{
                    <FormattedMessage
                      id="findMeUniqueChannel.verifyOtp"
                      values={{
                        0: <b>{findMeObj.firstName}</b>,
                        1: <b>{findMeObj.clientName}</b>,
                        2: <b>{findMeObj.selectedIdentityValue}</b>,
                        3: findMeObj.expiry
                      }}
                    />}</div>
                  <div className="fm-form-code-padding fm-emailphone-width" style={{ margin: "0 auto" }}>
                    {(!findMeObj.showAlert) &&
                      (<label id="verifyOtpCode" style={{ float: "left" }}>{<FormattedMessage id="findMe.otpCode" />}</label>
                      )}
                    {(findMeObj.showAlert) && (<div style={{ textAlign: "center", color: "red" }} role="alert">
                      {ReactHtmlParser(findMeObj.showAlertMessage)}
                    </div>
                    )}

                    <Textbox
                      id="otpTextBox" autoFocus={setAutoFocusOff}
                      maxLength={100}
                      value={findMeObj.otpValue}
                      onChange={(value) => {
                        WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: "OTP_VALUE" });
                        return updateFieldValueHandler("otpValue", value)
                      }}
                    />
                    {findMeObj.showOnScreen && (<div id="accessCode" className="vf-access-code">{findMeObj.accessCode}</div>)}

                  </div>
                  <div className="fm-width-100 br-padding"><Button
                    style={{ minHeight: "35px", minWidth: "180px" }}
                    id="otpBtn" type="submit"
                    disabled={!isFormValid}
                    className="primary cf-btn-cnt safari-button-align"
                    onClick={() => {
                      showAlertFlag = true;
                    }}
                  >
                    {<FormattedMessage id="submitCBtn.Label" />}
                  </Button>
                  </div>
                </div></form>)}

            {/* ONE TIME CODE END */}



            {/*************************************Error page with options for invalid email/mobile entry*******************************************/}

            {(findMeObj.page == constants.ERROR_OPTIONS_PAGE) && (<div className="fm-multi-form-padding" >

             {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && (<div className="fm-text-align-centre"><WarnIcon className="warn-icon-info" style={{ verticalAlign: 'sub' }} />
                <span className="fm-error-page-info-span">{ReactHtmlParser(errorPageInfo)}</span></div>)} 

              {(errorPageInfo === undefined || errorPageInfo === '') && (<div id="signupOption_errorPage" className="vdl-row fm-row-size fm-error-page-options-div"

                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'CLICK');
                  return hasRegCode("EMAIL_OR_PHONE");
                }}>

                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>              
                 <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.invalidAttmpt_searchInfo" />}</div>

                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
              </div>)}

              {(findMeObj.redirectionFrom !== constants.ORC_IDENTITY_PAGE) && (<div id="piiOption" className="vdl-row fm-row-size fm-error-page-options-div" 
              onClick={() => {
                setErrorPageInfo(errorPageInfo);
                ReactGA.sendevent(gaCategory, 'PII_INFO', 'CLICK');
                if(findMeObj.redirectionFrom === constants.REGCODE_PAGE)
                  return updatePrevPage(constants.EMAILPHONESEARCH_PAGE);
                else  
                  return updatePrevPage(constants.ERROR_OPTIONS_PAGE);
              }}>
                 <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>

                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.piiInfo" />}</div>
                </div>
                <FaAngleRight style={{ fontSize: "24px" }} className={isIE === true ? "ev-col-1 ie-chevron-icon" : "ev-col-1 ev-chevron-icon"} />
              </div>)}

               {errorPageInfo !== undefined && errorPageInfo !== '' && (<div id="signupOption" className="vdl-row fm-row-size fm-error-page-options-div"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'NEW_USER_SEARCH', 'CLICK');
                  return hasRegCode("EMAIL_OR_PHONE");
                }}>
                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" :"ev-col-11 ev-margin-auto"}>
                  {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && (
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.signUpInfo" />}</div>)}
                   {(findMeObj.redirectionFrom === constants.REGCODE_PAGE || findMeObj.redirectionFrom === constants.ORC_IDENTITY_PAGE || findMeObj.redirectionFrom === constants.PRC_IDENTITY_PAGE) && (
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.invalidAttmpt_searchInfo" />}</div>)}
                </div>
                <FaAngleRight style={{fontSize:"24px"}} className={isIE === true ? "ev-col-1 ie-chevron-icon" :"ev-col-1 ev-chevron-icon"}/>
               </div>)}

             {(findMeObj.redirectionFrom !== constants.ORC_IDENTITY_PAGE) && ( <div id="regcodeOption" className="vdl-row fm-row-size fm-error-page-options-div"
                onClick={() => {
                  isFirstTimeLoad = false;
                  ReactGA.sendevent(gaCategory, 'HAVE_REG_CODE', 'CLICK');
                  return hasRegCode("REGCODE");
                }}>
                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" : "ev-col-11 ev-margin-auto"}>
                  <div className="fm-font-size fm-error-page-option-color">
                    {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') ? <FormattedMessage id="findMe.errPage.regCodeInfo" />
                      : <FormattedMessage id="findMe.errPage.invalidAttmpt_regcodeInfo" />}

                    {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && <span className="question-circle" onClick={this.handleTooltipClick}>
                      <OverlayTrigger placement="right" trigger="click"
                        overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyUser_pageInfo' }))} >
                        <InfoCircle style={{ verticalAlign: 'sub' }} />
                      </OverlayTrigger>
                    </span>}
                  </div>
                </div>
                <FaAngleRight style={{ fontSize: "24px" }} className={isIE === true ? "ev-col-1 ie-chevron-icon" : "ev-col-1 ev-chevron-icon"} />
              </div>)}

              {(findMeObj.redirectionFrom === constants.ORC_IDENTITY_PAGE) && (<div id="identityRedirection" className="vdl-row fm-row-size fm-error-page-options-div"
                onClick={() => {
                  isFirstTimeLoad = false;
                  ReactGA.sendevent(gaCategory, 'IDENTITY', 'CLICK');
                  return redirectToIdentityPage(constants.ORC_IDENTITY_PAGE);
                }}>
                <div className={isIE === true ? "ev-col-11 ie-margin-auto-fix" : "ev-col-11 ev-margin-auto"}>
                  <div className="fm-font-size fm-error-page-option-color">{<FormattedMessage id="findMe.errPage.invalidAttmpt_tryagain" />}</div>
                </div>
                <FaAngleRight style={{ fontSize: "24px" }} className={isIE === true ? "ev-col-1 ie-chevron-icon" : "ev-col-1 ev-chevron-icon"} />
              </div>)}

             {(findMeObj.redirectionFrom === undefined || findMeObj.redirectionFrom === '') && ( <div className="fm-width-100"><Button
              style={{paddingBottom:"0px",paddingTop:"20px"}}

                id="errorOptionsBackBtn"
                className="primary cf-btn-cnt"
                buttonStyle="link"
                className="cf-font-size-btn cf-cancel-btn"
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'ERROR_OPTIONS', 'BACK');
                  return goToMainPage();
                }}
              >
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
              </Button>
              </div>)}
              <div className="fm-error-page-bottom-padding"></div>
            </div>)}

            {/*************************************Error page end*******************************************/}

            {/*************************************Error MODAL*******************************************/}
            {showModalTitle && (<Modal id="errorModal"
              className="vdl-modal--alert"
              show={showModalWarn}
            >
              <ModalHeader closeButton={false} className="modal-header">
                <ModalTitle className='modal-title-error'>
                  <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                  {showModalTitle}
                </ModalTitle>
              </ModalHeader>
              <ModalBody className="modal-body-footercolor">
                <div>
                  <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(showModalMessage)}</label>
                </div>
              </ModalBody>
              <ModalFooter className="modal-body-footercolor">
                <Button id="findMe_okBtn" className="safari-button-align" onClick={() => {
                  ReactGA.sendevent(gaCategory, 'ERROR_MODAL', 'CANCEL');
                  return cancelAndCheckYourEntries()
                }}>
                  {<FormattedMessage id="Lbl_OkButton" />}
                </Button>
              </ModalFooter>
            </Modal>)}
            {/*************************************Error MODAL END*******************************************/}


          </div>
        </div>


        { /** **************************Footer OTP page starts************************************** */}

        {(findMeObj.page == constants.OTP_PAGE) && (
          <div>
            <div
              className="registration-content"
              className="fm-otc-footer"
            >
              <div>
                <span
                  className="vf-code-subpanel1 fm-otp-footer-span"
                  id="subTitle1"
                >
                  {<FormattedMessage id="findMe.otpFooterTitle" />}
                </span>
                <Button
                  style={{ fontSize: "14px", marginLeft: "-2%" }}
                  className="vf-code-subpanel2"
                  id="methodResend"
                  disabled={findMeObj.disableResend}
                  className="fm-otc-footer-font"
                  buttonStyle="link"
                  onClick={() => {
                    showAlertFlag = true;
                    let payload = findMeObj.otpChannelId;
                    ReactGA.sendevent(gaCategory, 'RESEND_OTP', 'CLICK');
                    return resendOTP(payload);
                  }}

                > {<FormattedMessage id="verifyPrc.btn1" />}
                </Button>
              </div>
            </div>
          </div>
        )}

        { /** **************************Footer OTP page end************************************** */}
      </div>
      )
    );
  }
}
export default injectIntl(FindMeForm);