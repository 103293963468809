import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'GET_USER_ID_OPTIONS', 'GET_USER_ID_OPTIONS_SUCCESS', 'GET_USER_ID_OPTIONS_FAIL',
  'SET_REGISTER_OPTIONS_FIELD', 'SET_REGISTER_OPTIONS_FIELD_SUCCESS', 'SET_REGISTER_OPTIONS_FIELD_FAIL',
  'VERIFY_REGISTER_OPTIONS', 'VERIFY_REGISTER_OPTIONS_SUCCESS', 'VERIFY_REGISTER_OPTIONS_FAIL',
  'ENABLE_REGISTER_BUTTON',
  'SET_QUESTIONS_FIELD', 'SET_QUESTIONS_FIELD_SUCCESS', 'SET_QUESTIONS_FIELD_FAIL',
  'SET_ANSWERS_FIELD', 'SET_ANSWERS_FIELD_SUCCESS', 'SET_ANSWERS_FIELD_FAIL',
  'VERIFY_SECURITY_QUESTIONS', 'VERIFY_SECURITY_QUESTIONS_SUCCESS', 'VERIFY_SECURITY_QUESTIONS_FAIL',
  'ENABLE_SECURITY_QA_BUTTON',
  'CHECK_USERID_AVAILABILITY','CHECK_USERID_AVAILABILITY_SUCCESS','CHECK_USERID_AVAILABILITY_FAIL',
  'SET_MODAL_FIELD','SET_AGREE_TERMS_CONDITION','CANCEL_REGISTRATION'
);

const {
  GET_USER_ID_OPTIONS,
  SET_REGISTER_OPTIONS_FIELD,
  VERIFY_REGISTER_OPTIONS,
  ENABLE_REGISTER_BUTTON,
  SET_QUESTIONS_FIELD,
  SET_ANSWERS_FIELD,
  VERIFY_SECURITY_QUESTIONS,
  ENABLE_SECURITY_QA_BUTTON,
  CHECK_USERID_AVAILABILITY,
  SET_MODAL_FIELD,
  SET_AGREE_TERMS_CONDITION,
  CANCEL_REGISTRATION
} = types;

const getUserIdOptionsSent = createAction(GET_USER_ID_OPTIONS);

const setRegisterOptionsFieldSent = createAction(SET_REGISTER_OPTIONS_FIELD);

const verifyRegisterOptionsSent = createAction(VERIFY_REGISTER_OPTIONS);

const enableRegisterButtonSent = createAction(ENABLE_REGISTER_BUTTON);

const setQuestionsFieldSent = createAction(SET_QUESTIONS_FIELD);

const setAnswersFieldSent = createAction(SET_ANSWERS_FIELD);

const verifySecurityQuestionsSent = createAction(VERIFY_SECURITY_QUESTIONS);

const enableSecurityQAButtonSent = createAction(ENABLE_SECURITY_QA_BUTTON);

const checkUserIdAvailabilitySent = createAction(CHECK_USERID_AVAILABILITY);

const setCancelModalSent = createAction(SET_MODAL_FIELD);

const setAgreeTermsConditionSent = createAction(SET_AGREE_TERMS_CONDITION);

const cancelRegistrationSent = createAction(CANCEL_REGISTRATION);

const setRegisterOptionsField = (fieldkey, fieldValue) => setRegisterOptionsFieldSent({ fieldkey, fieldValue });

const verifyRegisterOptions = () => verifyRegisterOptionsSent();

const getUserIdOptions = () => getUserIdOptionsSent();

const enableRegisterButton = isFormValid => enableRegisterButtonSent({ isFormValid });

const setQuestionsField = (fieldkey, fieldValue) => setQuestionsFieldSent({ fieldkey, fieldValue });

const setAnswersField = (fieldkey, fieldValue) => setAnswersFieldSent({ fieldkey, fieldValue });

const verifySecurityQuestions = () => verifySecurityQuestionsSent();

const enableSecurityQAButton = isFormValid => enableSecurityQAButtonSent({ isFormValid });

const checkUserIdAvailability = () => checkUserIdAvailabilitySent();

const setCancelTnCModal = (showTnCModal) => setCancelModalSent({ showTnCModal });

const setAgreeTermsCondition = (agreeTnC) => setAgreeTermsConditionSent({ agreeTnC });

const cancelRegistration = () => cancelRegistrationSent();

const setPwdValid = passwordValid => enableRegisterButtonSent({ passwordValid });

export const actions = {
  getUserIdOptions,
  setRegisterOptionsField,
  verifyRegisterOptions,
  enableRegisterButton,
  setQuestionsField,
  setAnswersField,
  verifySecurityQuestions,
  enableSecurityQAButton,
  checkUserIdAvailability,
  setCancelTnCModal,
  setAgreeTermsCondition,
  cancelRegistration,
  setPwdValid
};
