import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions as localeActions } from '../../redux/ducks/locale';
import { actions as verifyKbaActions } from '../../redux/ducks/verify-kba';
import { actions as timeoutActions } from '../../redux/ducks/timeout';
import { RegistrationLayout } from '../../layouts';
import { VerifyKbaForm } from '../../components';

export const VerifyKbaContainer = props => <RegistrationLayout { ...props } />;

const mapStateToProps = ({
  locale, sendingRequest, verifyKba, timeout, registration
}) => {
  const branding = {};
  branding.footer = registration.footer;
  branding.organizationLogo = registration.organizationLogo;
  branding.productLogo = registration.productLogo;
  branding.background = registration.background;

  return ({
  ...locale,
  ...sendingRequest,
  ...verifyKba,
  ...timeout,
  ...branding
});}
// prettier-ignore
const mapDispatchToProps = dispatch => bindActionCreators(
  Object.assign({}, { ...localeActions }, { ...verifyKbaActions }, { ...timeoutActions }), dispatch,
);
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };

  Object.assign(props, { mainPane: <VerifyKbaForm { ...props } /> });

  return props;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(VerifyKbaContainer);
