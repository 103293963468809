import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { IntlProvider, addLocaleData } from 'react-intl';
import {
  map, switchMap, catchError, concatMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../verify-prc-constants';
import { types } from '../verify-prc-actions';
import { ReactGA, WindowUtil, PendoUtil } from '../../../../util';
import { constants as progressBarConstants } from '../../../../util/window/progress-bar-constants';

const {
  VERIFY_PRC_INFO_OPTIONS,
  VERIFY_PRC_INFO_OPTIONS_SUCCESS,
  VERIFY_PRC_INFO_OPTIONS_FAIL,
  CANCEL_PRC_AND_CHECK_YOUR_ENTRIES,
  SET_IDENTITY_SELECTED,
  SET_IDENTITY_SELECTED_SUCCESS,
  SET_IDENTITY_SELECTED_FAIL,
  ACCESS_CODE_SENT,
  ACCESS_CODE_SENT_SUCCESS,
  SET_VERIFY_PRC_REDIRECT,
  SET_VERIFY_PRC_KBA_REDIRECT,
  SET_VERIFY_PRC_POSTAL_REDIRECT,
  VERIFY_PHONE_ON_FLY,
  VERIFY_PHONE_ON_FLY_BACK,
  SET_PHONEONFLY_SELECTED,
  RESEND_OTP,
  EDIT_CONTACT_INFO,
  SKIP_NUDGE_ACTIVATION

} = types;


const getConfirmationInfoOptionsSuccess = createAction(VERIFY_PRC_INFO_OPTIONS_SUCCESS);
const getVerifyPrcOptionsFail = createAction(VERIFY_PRC_INFO_OPTIONS_FAIL);
const setIdentitySelectedSuccess = createAction(SET_IDENTITY_SELECTED_SUCCESS);
const setAccessCodeSuccess = createAction(ACCESS_CODE_SENT_SUCCESS);
const setIdentitySelectedFail = createAction(SET_IDENTITY_SELECTED_FAIL);
const setSubmitAccessCodeFail = createAction(SET_IDENTITY_SELECTED_FAIL);
const setVerifyPhoneOnFlyCodeSuccess = createAction(SET_PHONEONFLY_SELECTED);


const isBlank = str => (str || '').trim().length === 0;



//const getFeedDataApi = () => Observable.ajax.getJSON('/public/mocks/verifyPrc.json');
//const setIdentitySelectedApi = () => Observable.ajax.getJSON('/public/mocks/verifyPrcS.json');
//const setSubmitAccessCodeApi = () => Observable.ajax.getJSON('/public/mocks/verifyPrcF.json');


const getFeedDataApi = (ajax, getState) =>{
  var url = getState.contactInfo.contactInfoObj.showNudgePage ? constants.getNudgeInitialDataAPI : constants.getVerifyPrcInitialDataAPI
  return ajax({
    url: url,
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-TRANSACTIONID': getState.registration.transactionId
    }
  });
}

const setIdentitySelectedApi = (ajax, contactId, getState) => ajax({
  url: constants.getVerifyPrcGenerateCodeAPI,
  method: 'POST',
  responseType: 'json',
  body: contactId,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const setSubmitAccessCodeApi = (ajax, inputPRC, getState) => ajax({
  url: constants.getVerifyPrcSubmitCodeAPI,
  method: 'POST',
  responseType: 'json',
  body: inputPRC,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});

const setverifyPhoneOnFlyApi = (ajax, mobileObj, getState) => ajax({
  url: constants.getPhoneOnFlyAPI,
  method: 'POST',
  responseType: 'json',
  body: mobileObj,
  headers: {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'X-TRANSACTIONID': getState.registration.transactionId
  }
});


const setverifyPhoneOnFlyReEntry = () => {
   return of({
    "title": constants.PHONEONTHEFLYFAIL_TITLE,
    "hideText": true,
   });
};


const getVerifyPrcInfo = (ajax, getState) =>
  getFeedDataApi(ajax, getState)
    .pipe(map(response => handleResponse(response.response, getState)),
      catchError(err => [getVerifyPrcOptionsFail(getErrorMessage(err))]));


const setIdentitySelected = (ajax, payload, getState) => {
  let contactId = getState.verifyPrc.verifyPrcObj.associateContactInfo[payload].contactId;
  let channelObj={contactId:contactId}
  let commType = getState.verifyPrc.verifyPrcObj.associateContactInfo[payload].communicationType;
  const gaCategory = getState.verifyPrc.gaCategory;
  if (constants.TYPE_EMAIL == commType) {
    ReactGA.sendevent(gaCategory, 'COMMUNICATION_CHANNEL', 'EMAIL');
    channelObj.commType='EMAIL';
  } else if (constants.TYPE_CELL_PHONE == commType) {
    ReactGA.sendevent(gaCategory, 'COMMUNICATION_CHANNEL', 'MOBILE');
    channelObj.commType='MOBILE';
  } else {
    ReactGA.sendevent(gaCategory, 'COMMUNICATION_CHANNEL', 'POSTAL_MAIL');
    channelObj.commType='POSTAL_MAIL';
  }
  return setIdentitySelectedApi(ajax, contactId, getState)
    .pipe(map(response => handleResponseIdentitySelected(response.response, payload, getState,channelObj)),
      catchError(err => [setIdentitySelectedFail(getErrorMessage(err))]));
}
const goToPostalCode = (ajax, getState) => {
  const gaCategory = getState.verifyPrc.gaCategory;
  let contactId = constants.TYPE_POSTAL_CODE;
  ReactGA.sendevent(gaCategory, 'COMMUNICATION_CHANNEL', 'POSTAL_MAIL');
  return setIdentitySelectedApi(ajax, contactId, getState)
    .pipe(map(response => handlePostalCodeSelected(response.response, getState)),
      catchError(err => [setIdentitySelectedFail(getErrorMessage(err))]));
}

const verifyOtpPhoneOnFly = (ajax, payload, getState) => {

  const gaCategory = getState.verifyPrc.gaCategory;
  let contactId = constants.TYPE_PHONE_ON_FLY;
  let channelObj={contactId:contactId,commType:'PHONE_ON_FLY'};
  ReactGA.sendevent(gaCategory, 'COMMUNICATION_CHANNEL', 'PHONE_ON_FLY');
  payload.length = getState.verifyPrc.verifyPrcObj.disableFields.length - 1;
  return setIdentitySelectedApi(ajax, contactId, getState)
    .pipe(map(response => handleResponseIdentitySelected(response.response, payload, getState,channelObj)),
      catchError(err => [setIdentitySelectedFail(getErrorMessage(err))]));
}

const resendOtpToselectedContactId = (ajax, payload, getState) => {
  const gaCategory = getState.verifyPrc.gaCategory;
  let contactId = getState.verifyPrc.verifyPrcObj.contactId;
  ReactGA.sendevent(gaCategory, 'COMMUNICATION_CHANNEL',  getState.verifyPrc.verifyPrcObj.commType);
  payload.length = getState.verifyPrc.verifyPrcObj.disableFields.length - 1;
  return setIdentitySelectedApi(ajax, contactId, getState)
    .pipe(map(response => setIdentitySelectedSuccess(getState.verifyPrc)),
      catchError(err => [setIdentitySelectedFail(getErrorMessage(err))]));
}

const handlePostalCodeSelected = (response, getState) => {
  let verifyPrcObj = Object.assign({}, getState.verifyPrc.verifyPrcObj);
  if (response.code == constants.TYPE_SUCCESS) {
    let showModalInfo = true;
    verifyPrcObj.showModalTitle = response.title;
    verifyPrcObj.showModalMessage = response.message;
    return setIdentitySelectedSuccess({
      verifyPrcObj, showModalInfo
    });
  }
  else {
    let showModalError = true;
    let showOtcModal = true;
    verifyPrcObj.showModalTitle = response.title;
    verifyPrcObj.showModalMessage = response.message;
    return setIdentitySelectedSuccess({
      verifyPrcObj, showModalError, showOtcModal
    });
  }
}


const setSubmitAccessCode = (ajax, payload, getState) => {
  let inputPRC = getState.verifyPrc.otcCode;
  if (getState.verifyPrc.isFormValid != undefined && getState.verifyPrc.isFormValid == false)
    return of();
  return setSubmitAccessCodeApi(ajax, inputPRC, getState)
    .pipe(map(response => handleResponseAccessCode(response.response, payload, getState)),
      catchError(err => [setSubmitAccessCodeFail(getErrorMessage(err))]));
}

const setverifyPhoneOnFly = (ajax, payload, getState) => {
  let mobileObj = {};
  mobileObj.contactInfo = getState.verifyPrc.mnumber;
  mobileObj.dialCode = getState.verifyPrc.dialCode;
  mobileObj.purposeType = getState.verifyPrc.verifyPrcObj.phoneItems[0];
  mobileObj.countryCode = getState.verifyPrc.countryCode;
  if (getState.verifyPrc.prevDialCode === mobileObj.dialCode && getState.verifyPrc.prevNumber ===  mobileObj.contactInfo)
    {
      return setverifyPhoneOnFlyReEntry()
      .pipe(map(response => handleResponsePhoneReEntry(response,getState)));
  }
  else{
    return setverifyPhoneOnFlyApi(ajax, mobileObj, getState)
      .pipe(map(response => handleResponsePhoneOnFlyVerification(response.response, payload, getState)),
        catchError(err => [setSubmitAccessCodeFail(getErrorMessage(err))]));
  }
}

const handleResponse = (data, getState) => {
  const isMobile = getState.registration.mobileFlow;
  let isMobileBrowser = true;
  if (window.innerWidth <= 479 && isMobile) isMobileBrowser = false;
  let gaCategory = "WEB_REGISTRATION_VERIFY_PRC";
  let pagePath = '/ssr/cpc/prc';
  let pageView = pagePath + "/web";
  if (isMobile) { pageView = pagePath + "/mobile"; gaCategory = "MOBILE_REGISTRATION_VERIFY_PRC"; }
  if (window.innerWidth <= 479 && isMobile == false) { pageView = pagePath + "/mobilebrowser"; gaCategory = "MOBILEBROSWER_REGISTRATION_VERIFY_PRC"; }
  let showPhoneOnFly = false;

  ReactGA.pageview(pageView);
  PendoUtil.pendoUpdate(pageView)
  // progress bar start
  let progressBarData;
  if( getState.contactInfo.contactInfoObj && getState.contactInfo.contactInfoObj.showNudgePage ==true){
    console.log("enetered into nudge flow");
    progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Contact_Info, getState);
  }else{
    console.log("enetered into normal flow");
    progressBarData = WindowUtil.getProgressBarData(progressBarConstants.Identification, getState);
  }
  // progress bar end

  let verifyPrcObj = Object.assign({}, getState.verifyPrc.verifyPrcObj);
  verifyPrcObj = data;
  verifyPrcObj.phoneItems = [];
  verifyPrcObj.onlyCountries = ["us"];
  verifyPrcObj.phoneItems.push(getState.intl.messages['emailItem2']);
  verifyPrcObj.phoneItems.push(getState.intl.messages['emailItem1']);
  let selectedPhone = getState.intl.messages['emailItem2'];
  let mobilePhoneValid = true;
  verifyPrcObj.multiOrg = false;
  verifyPrcObj.isPhoneOnFlyEnabled = verifyPrcObj.phoneOnFlyFlag;
  let phoneOnFlyFail = false;
  let hideText = false;
  let phoneOnFlyAlert = false;
  let phoneOnFlyAlertError = false;
  verifyPrcObj.mykplanFlow = false;
  let phoneReEntryError = false;
  verifyPrcObj.firstName = WindowUtil.firstNameFormat(verifyPrcObj.firstName);
  verifyPrcObj.findMeFlow = getState.findMe.findMeObj.verifyflow || false;
  if (getState.registration.viewId == constants.mykPlan_viewId) {
    verifyPrcObj.mykplanFlow = true;
  }

  if (getState.findMe.findMeObj.multiOrg || getState.verify401k.userWithMultiOrg) {
    verifyPrcObj.multiOrg = true;
  }
  verifyPrcObj.showAlert = false;
  let showVerify = true;
  let showOtcModal = false;
  verifyPrcObj.showDiv = true;
  verifyPrcObj.showDiffMethodBtn = true;
  let disableFields = [];
  let prcAlreadyExists = data.prcAlreadyExists;
  let isPhoneOnFlyBlocked = data.phoneOnFlyBlocked;
  let phoneCommunicationExist = false ;
  verifyPrcObj.prcValid = true;
  verifyPrcObj.enablePostal = false;

  if (data.associateContactInfo == undefined || data.associateContactInfo.length == 0) {
    if (data.kbaDisabled)
      verifyPrcObj.showDiffMethodBtn = false;
    else verifyPrcObj.showDiffMethodBtn = true;
  }
  else {
    verifyPrcObj.associateContactInfo = data.associateContactInfo;
    verifyPrcObj.kbaDisabled = verifyPrcObj.kbaDisabled;
    data.associateContactInfo.map((value, key) => {
      if (value.communicationType == constants.TYPE_POSTAL_CODE) {
        verifyPrcObj.enablePostal = true;
        verifyPrcObj.postalInfo = value.contactInfo;
      }
      if(value.communicationType == constants.TYPE_CELL_PHONE){
        phoneCommunicationExist = true;
      }
      if (!value.enabled)
        disableFields.push(true);
      else disableFields.push(false);
    });
  }
  let mnumber;
  let dialCode;
  let pofBackEnabled = false;
  if (verifyPrcObj.isPhoneOnFlyEnabled) {
    verifyPrcObj.showDiffMethodBtn = true
    if(disableFields.length == 0) {
      phoneCommunicationExist = false
    }
    pofBackEnabled =  showDifferentMethodBtn(disableFields, verifyPrcObj.kbaDisabled, verifyPrcObj.enablePostal);
    if (isPhoneOnFlyBlocked) {
      disableFields.push(true);
    }
    else {
      disableFields.push(false);
    }
    mnumber = '';
    dialCode = '';
  }
  verifyPrcObj.disableFields = disableFields;
  let title = constants.VERIFYPRC_TITLE;
  if (prcAlreadyExists) {
    ReactGA.sendevent(gaCategory, 'PRC', 'ALREADY_EXISTS');
    title = constants.OTP_HEADING_ALREADY_PRC_EXIST;
  }

  PendoUtil.pendoIdentify(verifyPrcObj.orgCd,verifyPrcObj.clientName,getState.registration.transactionId)
  PendoUtil.pendoVisitorIdUpdate(verifyPrcObj.associateId)
  verifyPrcObj.showNudgePage = getState.contactInfo.contactInfoObj? getState.contactInfo.contactInfoObj.showNudgePage:false;
  getState.contactInfo.sendRedirect=false;
  return getConfirmationInfoOptionsSuccess({
    verifyPrcObj, phoneCommunicationExist, pofBackEnabled, phoneReEntryError, phoneOnFlyAlertError, hideText, showVerify, phoneOnFlyFail, phoneOnFlyAlert, mobilePhoneValid, mnumber, dialCode, selectedPhone, showPhoneOnFly, showOtcModal, title, prcAlreadyExists, isMobileBrowser, isMobile, gaCategory, progressBarData
  });
}


const handleResponseIdentitySelected = (response, payload, getState, channelObj) => {
  let verifyPrcObj = Object.assign({}, getState.verifyPrc.verifyPrcObj);
  let showOtcModal = false;
  let pofBackEnabled =  getState.verifyPrc.onlyPofExist;
  verifyPrcObj.contactId = channelObj.contactId;
  verifyPrcObj.commType = channelObj.commType;
  if (!getState.verifyPrc.showPhoneOnFly) {
    verifyPrcObj.disableFields[payload] = true;
  }
  else {
    verifyPrcObj.disableFields[payload.length] = true;
  }

  if(  verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1] === false){
    verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1] = true
    pofBackEnabled = showDifferentMethodBtn(verifyPrcObj.disableFields, verifyPrcObj.kbaDisabled, verifyPrcObj.enablePostal);
    verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1] = false
  }

  verifyPrcObj.showDiffMethodBtn = showDifferentMethodBtn(verifyPrcObj.disableFields, verifyPrcObj.kbaDisabled, verifyPrcObj.enablePostal);
 
  verifyPrcObj.showAlert = false;
  verifyPrcObj.prcValid = true;
  let otcCode = "";
  let phoneOnFlyAlert = false;

  if (response.code == constants.TYPE_SUCCESS) {
    let showVerify = false;
    let title = constants.OTP_HEADING;
    if (!getState.verifyPrc.showPhoneOnFly) {
      verifyPrcObj.selectedIdentityValue = verifyPrcObj.associateContactInfo[payload].contactInfo;
    }
    else {
      verifyPrcObj.selectedIdentityValue = payload.phoneOnFlyNumber;
      title = constants.POF_OTP_HEADING;
    }
    verifyPrcObj.showOnScreen = response.showOnScreen;
    verifyPrcObj.accessCode = response.accessCode;
    verifyPrcObj.expiry = response.expiry;
    let showPhoneOnFly = false;
    return setIdentitySelectedSuccess({
      verifyPrcObj, showVerify, pofBackEnabled, title, showOtcModal, otcCode, showPhoneOnFly, phoneOnFlyAlert
    });

  }
  else {
    if (!getState.verifyPrc.showPhoneOnFly) {
      showOtcModal = false;
    }
    else {
      showOtcModal = true;
    }
    let showPhoneOnFly = false;
    let showModalError = true;
    verifyPrcObj.showModalTitle = response.title;
    verifyPrcObj.showModalMessage = response.message;
    return setIdentitySelectedSuccess({
      verifyPrcObj, showModalError, pofBackEnabled, showOtcModal, otcCode, phoneOnFlyAlert, showPhoneOnFly
    });
  }

}

const showDifferentMethodBtn = (disableFields, showKba, enablePostal) => {
  if (showKba == false)
    return true;
  if (enablePostal) return true;
  for (var i = 0; i < disableFields.length; i++) {
    if (disableFields[i] == false) return true;
  }
  return false;
}

const handleResponseAccessCode = (response, payload, getState) => {
  let verifyPrcObj = Object.assign({}, getState.verifyPrc.verifyPrcObj);
  if (response.code == constants.TYPE_SUCCESS) {
    return setAccessCodeSuccess({
      viewId: response.viewId,
      sendRedirect: true
    });

  }
  else {
    if (response.code == constants.TYPE_ERROR_INVALID || response.code == constants.TYPE_ERROR_EXPIRED) {
      verifyPrcObj.showAlert = true;
      verifyPrcObj.prcValid = false;
      verifyPrcObj.showAlertMessage = response.message;

      return setAccessCodeSuccess({
        verifyPrcObj
      });
    }
    else {
      let showOtcModal = true;
      let showModalError = true;
      verifyPrcObj.showModalTitle = response.title;
      verifyPrcObj.showModalMessage = response.message;
      return setAccessCodeSuccess({
        verifyPrcObj, showModalError, showOtcModal
      });
    }
  }

}

const handleResponsePhoneReEntry = (response ,getState ) => {
  if(getState.verifyPrc.phoneOnFlyAlertError){
    let title = constants.PHONEONTHEFLY_TITLE;
    let phoneOnFlyAlert = true;
    return setAccessCodeSuccess({
      title,
      phoneOnFlyAlert,
      phoneReEntryError:false
     });
  }
  else{ 
   return setAccessCodeSuccess({
     title: response.title,
     hideText: response.hideText,
     phoneReEntryError:true
    });
  }
}

const handleResponsePhoneOnFlyVerification = (response, payload, getState) => {
  let verifyPrcObj = Object.assign({}, getState.verifyPrc.verifyPrcObj);
  let isCodeless = response.codelessFlowFlag;
  let phoneOnFlyNumber;
  let prevNumber = getState.verifyPrc.mnumber;
  let prevDialCode = getState.verifyPrc.dialCode;
  const gaCategory = getState.verifyPrc.gaCategory;
  if (response.code == constants.PHONEONFLY_SUCCESS) {
    ReactGA.sendevent(gaCategory, 'PHONEONTHEFLY', 'SUCCESS');
    phoneOnFlyNumber = response.contactInfo;
    return setVerifyPhoneOnFlyCodeSuccess({
      verifyPrcObj,
      phoneOnFlyNumber,
    });
  }
  else if (response.code == constants.INVALID_PHONE) {
    ReactGA.sendevent(gaCategory, 'PHONEONTHEFLY', 'INVALID_PHONE');
      verifyPrcObj.enteredNumber = getState.verifyPrc.mnumber;
      let phoneOnFlyAlert = false;
      let phoneOnFlyAlertError = false; 
      let title = constants.PHONEONTHEFLYFAIL_TITLE;
      let phoneOnFlyFail = false;

      return setAccessCodeSuccess({
        verifyPrcObj,
        title,
        phoneOnFlyAlert,
        hideText:true,
        phoneOnFlyFail,
        prevNumber,
        prevDialCode,
        phoneOnFlyAlertError,
        phoneReEntryError:false
      });
  }
  else if (response.code == constants.MAX_ATTEMPTS_EXCEEDED) {
    ReactGA.sendevent(gaCategory, 'PHONEONTHEFLY', 'MAX_ATTEMPTS_EXCEEDED');
    verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1] = true;
    verifyPrcObj.showDiffMethodBtn = showDifferentMethodBtn(verifyPrcObj.disableFields, verifyPrcObj.kbaDisabled, verifyPrcObj.enablePostal);
      if (verifyPrcObj.showDiffMethodBtn == false) {
        if (isCodeless) {
          return setAccessCodeSuccess({
            viewId: response.viewId,
            sendRedirect: true,
          });
        }
        else {
          let showOtcModal = true;
          let showModalError = true;
          verifyPrcObj.showModalTitle = response.title;
          verifyPrcObj.showModalMessage = response.message;
          return setAccessCodeSuccess({
            verifyPrcObj, showModalError, showOtcModal
          });
        }
      }
      else {
        verifyPrcObj.disableFields[verifyPrcObj.disableFields.length - 1] = true;
        let title = "verifyprctitle";
        let phoneOnFlyFail = false;
        return setAccessCodeSuccess({
          showPhoneOnFly: false,
          showVerify: true,
          verifyPrcObj,
          title,
          phoneOnFlyFail
        });
      }
  }
  else {
    if (response.code == constants.NON_UNIQUE_PHONE) {
      ReactGA.sendevent(gaCategory, 'PHONEONTHEFLY', 'NON_UNIQUE_PHONE');
      let phoneOnFlyAlert = true;
      verifyPrcObj.showAlertMessage = response.message;
      let title = constants.PHONEONTHEFLY_TITLE;
      let phoneOnFlyAlertError = true;
      return setAccessCodeSuccess({
        phoneOnFlyAlert,
        verifyPrcObj,
        hideText:false,
        title,
        prevNumber,
        prevDialCode,
        phoneOnFlyAlertError,
        phoneReEntryError:false
      });
    }
    else {
      ReactGA.sendevent(gaCategory, 'PHONEONTHEFLY', 'FAIL');
      let showOtcModal = true;
      let showModalError = true;
      verifyPrcObj.showModalTitle = response.title;
      verifyPrcObj.showModalMessage = response.message;
      return setAccessCodeSuccess({
        verifyPrcObj, showModalError, showOtcModal
      });
    }
  }

}


export const getVerifyPrcInfoEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_PRC_INFO_OPTIONS),
  switchMap(action => sendingAsyncRequest(getVerifyPrcInfo(ajax, state.value))));

export const goToKBASentCallEpic = action$ => action$.pipe(
  ofType(SET_VERIFY_PRC_KBA_REDIRECT),
  switchMap(action$ => of(push('/verifyKba'))));

export const editContactInfo = action$ => {
 return action$.pipe(
    ofType(EDIT_CONTACT_INFO),
    switchMap(action$ => of(push('/contactInfo'))));
};

export const setIdentitySelectedEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(SET_IDENTITY_SELECTED),
  switchMap(action => sendingAsyncRequest(setIdentitySelected(ajax, action.payload, state.value))),
);

export const resendOTP = (action$, state, { ajax }) => action$.pipe(
  ofType(RESEND_OTP),
  switchMap(action => sendingAsyncRequest(resendOtpToselectedContactId(ajax, {}, state.value))),
);

export const verifyOtpPhoneOnFlyEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(SET_PHONEONFLY_SELECTED),
  switchMap(action => sendingAsyncRequest(verifyOtpPhoneOnFly(ajax, action.payload, state.value))),
);

export const setSubmitAccessCodeEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(ACCESS_CODE_SENT),
  switchMap(action => sendingAsyncRequest(setSubmitAccessCode(ajax, action.payload, state.value))));

export const setverifyPhoneOnFlyEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(VERIFY_PHONE_ON_FLY),
  switchMap(action => sendingAsyncRequest(setverifyPhoneOnFly(ajax, action.payload, state.value))));

export const goToPostalCodeEpic = (action$, state, { ajax }) => action$.pipe(
  ofType(SET_VERIFY_PRC_POSTAL_REDIRECT),
  switchMap(action => sendingAsyncRequest(goToPostalCode(ajax, state.value))));

const redirectToLoginPage = getState => {
  const isMobile = getState.registration.mobileFlow;
  const url = getState.registration.returnUrl;
  const organizationId = getState.registration.organizationId;
  if (isMobile) {
    closeMobileContainer(); //this is part of index.html
    return true;
  } else {
    return window.open(unescape(WindowUtil.validateRtnUrl(decodeURIComponent(url), organizationId)), '_self');
  }
}

const verifyPhoneOnFlyBack = getState => verifyPhoneOnFlyBackClick(getState).pipe(
  map(setAccessCodeSuccess),
);

const verifyPhoneOnFlyBackClick = getState => {

  let title;
  let phoneOnFlyAlert = false;
  if (!getState.verifyPrc.phoneOnFlyFail) {
    title = constants.VERIFYPRC_TITLE;
  }
  else {
    title = constants.PHONEONTHEFLYFAIL_TITLE;
  }
  return of({
    title,
    showVerify: true,
    showPhoneOnFly: false,
    phoneOnFlyAlert
  });
}

export const getVerifyPrcInfoOptionsFailureEpic = action$ => action$.pipe(
  ofType(VERIFY_PRC_INFO_OPTIONS_FAIL),
  switchMap(action$ => of(push('/error'))));

export const setRedirectSentEpic = (action$, state) => action$.pipe(
  ofType(SET_VERIFY_PRC_REDIRECT),
  switchMap(action$ => {
    ReactGA.sendevent(state.value.verifyPrc.gaCategory, 'VERIFY_PRC', 'SUCCESS');
    return of(push(state.value.verifyPrc.viewId))
  }));

export const setIdentitySelectedFailureEpic = action$ => action$.pipe(
  ofType(SET_IDENTITY_SELECTED_FAIL),
  switchMap(action$ => of(push('/error'))));

export const cancelAndCheckYourEntriesEpic = (action$, state) => action$.pipe(
  ofType(CANCEL_PRC_AND_CHECK_YOUR_ENTRIES),
  switchMap(action => sendingAsyncRequest(redirectToLoginPage(state.value))),
);
export const verifyPhoneOnFlyBackEpic = (action$, state) => action$.pipe(
  ofType(VERIFY_PHONE_ON_FLY_BACK),
  switchMap(action => sendingAsyncRequest(verifyPhoneOnFlyBack(state.value))),
);
export const skipNudgeActivation = action$ => {
  return action$.pipe(
     ofType(SKIP_NUDGE_ACTIVATION),
     switchMap(action$ => of(push('/registration'))));
 };
