import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { actions as localeActions } from '../../redux/ducks/locale';
import { actions as findMeActions } from '../../redux/ducks/find-me';
import { actions as timeoutActions } from '../../redux/ducks/timeout';
import { RegistrationLayout } from '../../layouts';
import { FindMeForm , FindMeVariantForm} from '../../components';

export const FindMeContainer = props => <RegistrationLayout { ...props } />;

const mapStateToProps = ({
  locale, sendingRequest, findMe, timeout, registration
}) => {
  const branding = {};
  branding.footer = registration.footer;
  branding.organizationLogo = registration.organizationLogo;
  branding.productLogo = registration.productLogo;
  branding.background = registration.background;
  branding.codelessVariantEnabled = registration.codelessVariantEnabled || false;

  return ({
  ...locale,
  ...sendingRequest,
  ...findMe,
  ...timeout,
  ...branding
});}
// prettier-ignore
const mapDispatchToProps = dispatch => bindActionCreators(
  Object.assign({}, { ...localeActions }, { ...findMeActions }, { ...timeoutActions }), dispatch,
);
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };
  if(props.codelessVariantEnabled) 
     Object.assign(props, {  mainPane: <FindMeVariantForm { ...props } /> });
  else 
     Object.assign(props, {  mainPane: <FindMeForm { ...props } /> });
  return props;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FindMeContainer);
