import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';
import constants from '../../../util/locale/constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_PRC_INFO_OPTIONS',
  'VERIFY_PRC_INFO_OPTIONS_SUCCESS',
  'VERIFY_PRC_INFO_OPTIONS_FAIL',
  'CANCEL_PRC_AND_CHECK_YOUR_ENTRIES',
  'SET_IDENTITY_SELECTED',
  'SET_IDENTITY_SELECTED_SUCCESS',
  'SET_IDENTITY_SELECTED_FAIL',
  'ACCESS_CODE_SENT',
  'ACCESS_CODE_SENT_SUCCESS',
  'VERIFY_PHONE_ON_FLY',
  'SET_VERIFY_PRC_FIELD_VALUE',
  'SET_VERIFY_PRC_REDIRECT',
  'SET_VERIFY_PRC_KBA_REDIRECT',
  'SET_VERIFY_PRC_POSTAL_REDIRECT',
  'VERIFY_PHONE_ON_FLY_BACK',
  'SET_PHONEONFLY_SELECTED',
  'RESEND_OTP',
  'EDIT_CONTACT_INFO',
  'SKIP_NUDGE_ACTIVATION'


);

const {
  VERIFY_PRC_INFO_OPTIONS,
  CANCEL_PRC_AND_CHECK_YOUR_ENTRIES,
  VERIFY_PRC_INFO_OPTIONS_SUCCESS,
  SET_IDENTITY_SELECTED,
  ACCESS_CODE_SENT,
  VERIFY_PHONE_ON_FLY,
  VERIFY_PHONE_ON_FLY_BACK,
  SET_VERIFY_PRC_FIELD_VALUE,
  SET_VERIFY_PRC_REDIRECT,
  SET_VERIFY_PRC_KBA_REDIRECT,
  SET_VERIFY_PRC_POSTAL_REDIRECT,
  SET_PHONEONFLY_SELECTED,
  RESEND_OTP,
  EDIT_CONTACT_INFO,
  SKIP_NUDGE_ACTIVATION


} = types;

const getVerfiyPicInfoSent = createAction(VERIFY_PRC_INFO_OPTIONS);
const setverifyOtpPhoneOnFlySent = createAction(SET_PHONEONFLY_SELECTED);
const cancelAndCheckYourEntriesSent = createAction(CANCEL_PRC_AND_CHECK_YOUR_ENTRIES);
const setIdentitySelectedSent = createAction(SET_IDENTITY_SELECTED);
const setDifferentMethodSent = createAction(VERIFY_PRC_INFO_OPTIONS_SUCCESS);
const setSubmitAccessCodeSent = createAction(ACCESS_CODE_SENT);
const  setverifyPhoneOnFly = createAction(VERIFY_PHONE_ON_FLY);
const  setverifyPhoneOnFlyBack = createAction(VERIFY_PHONE_ON_FLY_BACK);
const setVerifyPrcValueSent = createAction(SET_VERIFY_PRC_FIELD_VALUE);
const setRedirectSent = createAction(SET_VERIFY_PRC_REDIRECT);
const goToKBASentCall = createAction(SET_VERIFY_PRC_KBA_REDIRECT);
const goToPostalSent = createAction(SET_VERIFY_PRC_POSTAL_REDIRECT);
const resendOTP = createAction(RESEND_OTP);
const editContactInfo = createAction(EDIT_CONTACT_INFO);
const skipNudgeActivation = createAction(SKIP_NUDGE_ACTIVATION);

const getVerfiyPicInfo = () => getVerfiyPicInfoSent();
const setverifyOtpPhoneOnFly = () => setverifyOtpPhoneOnFlySent();
const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();
const setIdentitySelected = (payload) => setIdentitySelectedSent(payload);
const setDifferentMethod = (showVerify, title, prcAlreadyExists, showModalWarn, phoneOnFlyFail) => setDifferentMethodSent({ showVerify, title, prcAlreadyExists, showModalWarn, phoneOnFlyFail });
const setSubmitAccessCode = () => setSubmitAccessCodeSent();
const verifyPhoneOnFly = () => setverifyPhoneOnFly();
const enableSubmitButton = isFormValid => setVerifyPrcValueSent({ isFormValid });
const setCancelModal = showModalError => setVerifyPrcValueSent({ showModalError });
const setOtcValue = otcCode => setVerifyPrcValueSent({ otcCode });
const setPhoneOnFly = () => setVerifyPrcValueSent({phoneReEntryError:false,mnumber:'',showPhoneOnFly:true,showVerify:false,title:"phoneonethyflytitle",hideText:false });
const onSetMobileType = selectedPhone => setVerifyPrcValueSent({ selectedPhone });
const onSetMobilePhoneValue = fieldValue => setVerifyPrcValueSent({ phoneReEntryError:false,mobilePhoneValid:fieldValue.isValid,mnumber: fieldValue.formattedNumber.replace(/\D/g, '').slice(fieldValue.selectedCountry.dialCode.length),dialCode:fieldValue.selectedCountry.dialCode,countryCode:fieldValue.selectedCountry.iso2});
const backToOptionsPage = () => setverifyPhoneOnFlyBack();
const setRedirect = () => setRedirectSent();
const goToKBASent = () => goToKBASentCall();
const goToPostal = () => goToPostalSent();
const setWarnModal = showModalWarn => setVerifyPrcValueSent({ showModalWarn });


export const actions = {
  getVerfiyPicInfo,
  setverifyOtpPhoneOnFly,
  cancelAndCheckYourEntries,
  setIdentitySelected,
  setDifferentMethod,
  setSubmitAccessCode,
  verifyPhoneOnFly,
  enableSubmitButton,
  setCancelModal,
  setOtcValue,
  onSetMobileType,
  setPhoneOnFly,
  onSetMobilePhoneValue,
  backToOptionsPage,
  setverifyPhoneOnFlyBack,
  setRedirect,
  goToKBASent,
  goToPostal,
  setWarnModal,
  resendOTP,
  editContactInfo,
  skipNudgeActivation

};
