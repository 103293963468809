import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  Tooltip
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-bev/verify-bev-constants';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import Draggable from 'react-draggable';
import WindowClose from 'adp-react-icons/lib/fa/window-close';

const isBlank = str => (str || '').trim().length === 0;
let overlayPlacement = "right";


export default class VerifyBevPCSNonPCSForm extends Component {

  componentWillMount() {
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
  }
  
  render() {
    const {
      verifyBevObj,
      hideDraggableSet,
      showDraggableModal,
      showDraggable,
      onDropDownSelect,
      handleUpdateData,
      handleIpayRadioBtn,
      showIpayDraggable,
      showIpayDraggableModal,
      gaCategory

    } = this.props;

    const gaToolTip = (label) => ReactGA.sendevent(gaCategory, label, 'TOOLTIP');

    const errorPopover = (fieldValid, msg) =>
      (fieldValid && <Popover
        id="contactPopOver"
        style={{ display: 'none' }}
      />) ||
      (!fieldValid && (
        <Popover
          id="err-popover"
          placement="right"
          className={'vdl-alert--default-style vdl-alert--sm vdl-alert--error'}
        >
          <div role="alert">{msg}</div>
        </Popover>
      ));


    const {
      iPayPayStmtNonPCSMBean,
      iPayPayStmtMBean,
      productMBean
    } = this.props.verifyBevObj.initData;

    const handleDataChange = (parentName, fieldName, fieldValue, regEx, index) => {
      let payload = {};
      payload.type = 3;
      payload.parentName = parentName;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      payload.regEx = regEx;
      payload.index = index;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory + "_" + "IPAY", fieldName: fieldName });
      return handleUpdateData(payload);
    }
    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );
    const handleDraggable = (value) => {
      let showDraggable = value;
      showDraggableModal(showDraggable);
    }

    const handleIpayDraggable = (value) => {
      let showIpayDraggable = value;
      showIpayDraggableModal(showIpayDraggable);
    }
    return (
      <div>
        <div className="form-group form-align">
          <div className="form-group form-align">
            <div className="vdl-row">
              <div id="ipayLabel" className="vdl-col-sm-12 vdl-col-xs-12">
                <span className="heading-text">{productMBean.ipayLabel}</span>
              </div>
              <div id="ipayButton" className="vdl-col-sm-12 vdl-col-xs-12">
                <span role="button" id="ipay_sampleDocBtn" className="vb-sample-doc focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'PCS_NON_PCS_IPAY_DOC', 'MODAL');
                      return handleIpayDraggable(true);
                    }
                  }} onClick={() => {
                  ReactGA.sendevent(gaCategory, 'PCS_NON_PCS_IPAY_DOC', 'MODAL');
                  return handleIpayDraggable(true)
                }}>{productMBean.ipayButton}</span>
              </div>
            </div>
          </div>
          <div>
            <div className="inline">
              <Radio className="cf-radio-toggle"
                value="yes"
                radioGroupValue={verifyBevObj.radio}
                inline={true}
                id="ipay_radio1"
                style={{ marginBottom: "10px" }}
                onChange={(value) => {
                  ReactGA.sendevent(gaCategory, 'CLICK', 'PCS');
                  return handleIpayRadioBtn(value)
                }}
              >
                {productMBean.iPayFormTypes[0].label}
              </Radio>
              <Radio className="cf-radio-toggle"
                value="no"
                radioGroupValue={verifyBevObj.radio}
                inline={true}
                id="ipay_radio2"
                onChange={(value) => {
                  ReactGA.sendevent(gaCategory, 'CLICK', 'NON_PCS');
                  return handleIpayRadioBtn(value)
                }}
              >
                {productMBean.iPayFormTypes[1].label}
              </Radio>
            </div>
          </div>


        </div>
        {/* Ipay Draggable component start */}
        {showIpayDraggable && (
          <div className="vb-draggable-header focus-selector" tabIndex = "0" id="ipayDraggable">
            <Draggable defaultPosition={{ x: 10, y: -600 }}>
              <div className="vb-draggable-body " >
                <div style={{ textAlign: 'right' }}>
                  <WindowClose className="focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return handleIpayDraggable(false);
                    }
                  }} onClick={() => handleIpayDraggable(false)
                  } className="vb-draggable-close" />
                </div>
                <div className="vb-touch-scroll vb-form-draggable-div">
                  <div >
                    <div className="vb-form-draggable-label">{ReactHtmlParser(productMBean.ipayFindCompanyCodeSampleDoc.label)}</div>
                    <img id="cs-andriod-badget" width="300px" height="400px"
                      src={productMBean.ipayFindCompanyCodeSampleDoc.value} draggable="false"
                    />
                  </div>
                </div>
              </div>
            </Draggable>
          </div>)}
        {/* Ipay Draggable component end */}

        {/* Draggable component start */}
        {showDraggable && verifyBevObj.isIPayYes && (
          <div className="vb-draggable-header" id="isIPayYes">
            <Draggable defaultPosition={{ x: 50, y: -600 }} >
              <div className="vb-draggable-body " >
                <div style={{ textAlign: 'right' }}>
                  <WindowClose className="focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return handleDraggable(false);
                    }
                  }} onClick={() => handleDraggable(false)} className="vb-draggable-close" />
                </div>
                <div className="vb-touch-scroll vb-form-draggable-div">
                  {iPayPayStmtMBean.viewSampleDocs.options.map((value, index) => (
                    <div key={index}>
                      <div className="vb-form-draggable-label">{value.label}</div>
                      <img id="cs-andriod-badget" width="300px" height="400px"
                        src={value.value} draggable="false"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Draggable>
          </div>)}

        {showDraggable && !verifyBevObj.isIPayYes && (
          <div className="vb-draggable-header" id="isIPayNo">
            <Draggable defaultPosition={{ x: 50, y: -600 }} >
              <div className="vb-draggable-body " >
                <div style={{ textAlign: 'right' }}>
                  <WindowClose className="focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return handleDraggable(false);
                    }
                  }} onClick={() => handleDraggable(false)} className="vb-draggable-close" />
                </div>
                <div className="vb-touch-scroll vb-form-draggable-div">
                  {iPayPayStmtNonPCSMBean.viewSampleDocs.options.map((value, index) => (
                    <div key={index}>
                      <div className="vb-form-draggable-label">{value.label}</div>
                      <img id="cs-andriod-badget" width="300px" height="400px"
                        src={value.value} draggable="false"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Draggable>
          </div>)}
        {/* Draggable component end */}

        {/* RADIO YES component start */}

        {verifyBevObj.isIPayYes && verifyBevObj.iPayShow && (<div>

          <div className="form-group form-align">
            <div className="vdl-row">
              <div id="sampleDocLabel" className="vdl-col-sm-12 vdl-col-xs-12">
                <span role="button" className="vb-sample-doc focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'PCS_SAMPLE_DOC', 'MODAL');
                      return handleDraggable(true);
                    }
                  }} onClick={() => {
                  ReactGA.sendevent(gaCategory, 'PCS_SAMPLE_DOC', 'MODAL');
                  return handleDraggable(true)
                }}>{iPayPayStmtMBean.viewSampleDocs.label}</span>
              </div>
            </div>
          </div>

          {/* co component start */}
        <div id={iPayPayStmtMBean.co.label}>
          <div role="group" aria-labelledby={iPayPayStmtMBean.co.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="coLabel">{iPayPayStmtMBean.co.label}
                  {iPayPayStmtMBean.co.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtMBean.co.toolTip) && (<span role="button" aria-label="tooltip" id="ipayyesCO_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_CO')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtMBean.co.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[0], iPayPayStmtMBean.co.message)}
              >
                <Textbox aria-label={iPayPayStmtMBean.co.label}
                  id="ipayyesCO_textBox"
                  maxLength={iPayPayStmtMBean.co.maxLength}
                  placeholder={iPayPayStmtMBean.co.placeHolder}
                  value={iPayPayStmtMBean.co.value}
                  className={!verifyBevObj.errorFieldsBackend[0] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtMBean", "co", value, iPayPayStmtMBean.co.regEx, 0)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* co component end */}


          {/* fileNumber component start */}
        <div id={iPayPayStmtMBean.fileNumber.label}>
          <div role="group" aria-labelledby={iPayPayStmtMBean.fileNumber.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="fileLabel">{iPayPayStmtMBean.fileNumber.label}
                  {iPayPayStmtMBean.fileNumber.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtMBean.fileNumber.toolTip) && (<span role="button" aria-label="tooltip" id="ipayyesFileNo_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_FILE_NUMBER')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtMBean.fileNumber.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[1], iPayPayStmtMBean.fileNumber.message)}
              >
                <Textbox aria-label={iPayPayStmtMBean.fileNumber.label}
                  id="ipayyesFileNo_textBox"
                  maxLength={iPayPayStmtMBean.fileNumber.maxLength}
                  placeholder={iPayPayStmtMBean.fileNumber.placeHolder}
                  value={iPayPayStmtMBean.fileNumber.value}
                  className={!verifyBevObj.errorFieldsBackend[1] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtMBean", "fileNumber", value, iPayPayStmtMBean.fileNumber.regEx, 1)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* fileNumber component end */}

          {/* Date of Birth component start */}
        <div id={iPayPayStmtMBean.fullMonthId.label}>
          <div role="group" aria-labelledby={iPayPayStmtMBean.fullMonthId.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-8 vdl-col-xs-15">
                <label id="fulldob" htmlFor="fulldob_Label">{iPayPayStmtMBean.fullMonthId.label}
                  <span className="vb-form-dob-req-icon">*</span></label>
              </div>
            </div>
            <div className="vb-form-dropdown-div">
              <div style={{ width: window.innerWidth <= 479 ? '36%' : "33%" }}>
                <DropdownList aria-label="monthDropdown" 
                  id="ipayyes_month"
                  data={verifyBevObj.fullMonthItems}
                  placeholder={iPayPayStmtMBean.fullMonthId.placeHolder}
                  onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return handleDataChange("iPayPayStmtMBean", "fullMonthId", value, null) }}
                  value={iPayPayStmtMBean.fullMonthId.value}
                />
              </div>
              <div style={{ width: window.innerWidth <= 479 ? '30%' : "33%", marginLeft: "2%" }}>
                <DropdownList aria-label="dayDropdown" 
                  id="ipayyes_day"
                  data={verifyBevObj.fullDayItems}
                  placeholder={iPayPayStmtMBean.fullDayId.placeHolder}
                  onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return handleDataChange("iPayPayStmtMBean", "fullDayId", value, null) }}
                  value={iPayPayStmtMBean.fullDayId.value}
                />
              </div>
              <div className="vb-form-dropdown-year-div">
                <DropdownList aria-label="yearDropdown" 
                  id="ipayyes_year"
                  data={verifyBevObj.w2YearList}
                  placeholder={iPayPayStmtMBean.fullYearId.placeHolder}
                  onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return handleDataChange("iPayPayStmtMBean", "fullYearId", value, null) }}
                  value={iPayPayStmtMBean.fullYearId.value}
                />
              </div>
            </div>
          </div>
        </div>
          {/* Date of Birth component end */}


          {/* adviceNumber component start */}
        <div id={iPayPayStmtMBean.adviceNumber.label}>
          <div role="group" aria-labelledby={iPayPayStmtMBean.adviceNumber.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="adviceLabel">{iPayPayStmtMBean.adviceNumber.label}
                  {iPayPayStmtMBean.adviceNumber.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtMBean.adviceNumber.toolTip) && (<span role="button" aria-label="tooltip" id="ipayyesAN_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_ADVICE_NUMBER')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtMBean.adviceNumber.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[2], iPayPayStmtMBean.adviceNumber.message)}
              >
                <Textbox aria-label={iPayPayStmtMBean.adviceNumber.label}
                  id="ipayyesAN_textBox"
                  maxLength={iPayPayStmtMBean.adviceNumber.maxLength}
                  placeholder={iPayPayStmtMBean.adviceNumber.placeHolder}
                  value={iPayPayStmtMBean.adviceNumber.value}
                  className={!verifyBevObj.errorFieldsBackend[2] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtMBean", "adviceNumber", value, iPayPayStmtMBean.adviceNumber.regEx, 2)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* adviceNumber component end */}


          {/* ssn component start */}
        <div id={iPayPayStmtMBean.ssn.label}>
          <div role="group" aria-labelledby={iPayPayStmtMBean.ssn.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="ssnLabel">{iPayPayStmtMBean.ssn.label}
                  {iPayPayStmtMBean.ssn.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtMBean.ssn.toolTip) && (<span role="button" aria-label="tooltip" id="ipayyesssn_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_SSN')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtMBean.ssn.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[3], iPayPayStmtMBean.ssn.message)}
              >
                <Textbox aria-label={iPayPayStmtMBean.ssn.label}
                  id="ipayyesssn_textBox"
                  maxLength={iPayPayStmtMBean.ssn.maxLength}
                  type="password"
                  placeholder={iPayPayStmtMBean.ssn.placeHolder}
                  value={iPayPayStmtMBean.ssn.value}
                  className={!verifyBevObj.errorFieldsBackend[3] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtMBean", "ssn", value, iPayPayStmtMBean.ssn.regEx, 3)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* ssn component end */}



        </div>)}

        {/*  RADIO YES component END */}


        {/* RADIO NO component start */}
        {!verifyBevObj.isIPayYes && verifyBevObj.iPayShow && (<div>

          <div className="form-group form-align">
            <div className="vdl-row">
              <div id="sampleDocLabel" className="vdl-col-sm-12 vdl-col-xs-12">
                <span role="button" className="vb-sample-doc focus-selector" tabIndex = "0" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'NON_PCS_SAMPLE_DOC', 'MODAL');
                      return handleDraggable(true);
                    }
                  }} onClick={() => {
                  ReactGA.sendevent(gaCategory, 'NON_PCS_SAMPLE_DOC', 'MODAL');
                  return handleDraggable(true)
                }}>{iPayPayStmtNonPCSMBean.viewSampleDocs.label}</span>
              </div>
            </div>
          </div>
          {/* co component start */}
        <div id={iPayPayStmtNonPCSMBean.co.label}>
          <div role="group" aria-labelledby={iPayPayStmtNonPCSMBean.co.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="coLabel">{iPayPayStmtNonPCSMBean.co.label}
                  {iPayPayStmtNonPCSMBean.co.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtNonPCSMBean.co.toolTip) && (<span role="button" aria-label="tooltip" id="ipaynoCO_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_CO')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtNonPCSMBean.co.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[0], iPayPayStmtNonPCSMBean.co.message)}
              >
                <Textbox aria-label={iPayPayStmtNonPCSMBean.co.label}
                  id="ipaynoCO_textBox"
                  maxLength={iPayPayStmtNonPCSMBean.co.maxLength}
                  placeholder={iPayPayStmtNonPCSMBean.co.placeHolder}
                  value={iPayPayStmtNonPCSMBean.co.value}
                  className={!verifyBevObj.errorFieldsBackend[0] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtNonPCSMBean", "co", value, iPayPayStmtNonPCSMBean.co.regEx, 0)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* co component end */}

          {/* fileNumber component start */}
        <div id={iPayPayStmtNonPCSMBean.fileNumber.label}>
          <div role="group" aria-labelledby={iPayPayStmtNonPCSMBean.fileNumber.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="fileLabel">{iPayPayStmtNonPCSMBean.fileNumber.label}
                  {iPayPayStmtNonPCSMBean.fileNumber.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtNonPCSMBean.fileNumber.toolTip) && (<span role="button" aria-label="tooltip" id="ipaynoFileNo_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_FILE_NUMBER')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtNonPCSMBean.fileNumber.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[1], iPayPayStmtNonPCSMBean.fileNumber.message)}
              >
                <Textbox aria-label={iPayPayStmtNonPCSMBean.fileNumber.label}
                  id="ipaynoFileNo_textBox"
                  maxLength={iPayPayStmtNonPCSMBean.fileNumber.maxLength}
                  placeholder={iPayPayStmtNonPCSMBean.fileNumber.placeHolder}
                  value={iPayPayStmtNonPCSMBean.fileNumber.value}
                  className={!verifyBevObj.errorFieldsBackend[1] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtNonPCSMBean", "fileNumber", value, iPayPayStmtNonPCSMBean.fileNumber.regEx, 1)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* fileNumber component end */}


          {/* adviceNumber component start */}
        <div id={iPayPayStmtNonPCSMBean.adviceNumber.label}>
          <div role="group" aria-labelledby={iPayPayStmtNonPCSMBean.adviceNumber.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="adviceLabel">{iPayPayStmtNonPCSMBean.adviceNumber.label}
                  {iPayPayStmtNonPCSMBean.adviceNumber.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtNonPCSMBean.adviceNumber.toolTip) && (<span role="button" aria-label="tooltip" id="ipaynoAN_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_ADVICE_NUMBER')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtNonPCSMBean.adviceNumber.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[2], iPayPayStmtNonPCSMBean.adviceNumber.message)}
              >
                <Textbox aria-label={iPayPayStmtNonPCSMBean.adviceNumber.label}
                  id="ipaynoAN_textBox"
                  maxLength={iPayPayStmtNonPCSMBean.adviceNumber.maxLength}
                  placeholder={iPayPayStmtNonPCSMBean.adviceNumber.placeHolder}
                  value={iPayPayStmtNonPCSMBean.adviceNumber.value}
                  className={!verifyBevObj.errorFieldsBackend[2] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtNonPCSMBean", "adviceNumber", value, iPayPayStmtNonPCSMBean.adviceNumber.regEx, 2)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* adviceNumber component end */}

          {/* ssn component start */}
        <div id={iPayPayStmtNonPCSMBean.ssn.label}>
          <div role="group" aria-labelledby={iPayPayStmtNonPCSMBean.ssn.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-12 vdl-col-xs-12">
                <label id="ssnLabel">{iPayPayStmtNonPCSMBean.ssn.label}
                  {iPayPayStmtNonPCSMBean.ssn.required && (<span className="vb-form-req-icon">*</span>)}

                  {!isBlank(iPayPayStmtNonPCSMBean.ssn.toolTip) && (<span role="button" aria-label="tooltip" id="ipaynosnn_ques" className="vb-question-circle" onClick={() => gaToolTip('PCS_NON_PCS_SSN')}>
                    <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                      overlay={tooltip(iPayPayStmtNonPCSMBean.ssn.toolTip)} >
                      <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                    </OverlayTrigger>
                  </span>)}</label>
              </div>
            </div>
            <div className="input-group-full">
              <OverlayTrigger isAriaExpanded={ false }
                trigger={['click']} placement="bottom"
                overlay={errorPopover(!verifyBevObj.errorFields[3], iPayPayStmtNonPCSMBean.ssn.message)}
              >
                <Textbox aria-label={iPayPayStmtNonPCSMBean.ssn.label}
                  id="ipaynossn_textBox"
                  maxLength={iPayPayStmtNonPCSMBean.ssn.maxLength}
                  type="password"
                  placeholder={iPayPayStmtNonPCSMBean.ssn.placeHolder}
                  value={iPayPayStmtNonPCSMBean.ssn.value}
                  className={!verifyBevObj.errorFieldsBackend[3] ? undefined : 'vdl-validation-error'}
                  onChange={(value) => handleDataChange("iPayPayStmtNonPCSMBean", "ssn", value, iPayPayStmtNonPCSMBean.ssn.regEx, 3)}
                />
              </OverlayTrigger>
            </div>
            <div />
          </div>
        </div>
          {/* ssn component end */}

          {/* Date of Birth component start */}
        <div id={iPayPayStmtNonPCSMBean.fullMonthId.label}>
          <div role="group" aria-labelledby={iPayPayStmtNonPCSMBean.fullMonthId.label} className="form-group form-align">
            <div className="vdl-row">
              <div className="vdl-col-sm-8 vdl-col-xs-15">
                <label id="fulldob" htmlFor="fulldob_Label">{iPayPayStmtNonPCSMBean.fullMonthId.label}
                  <span className="vb-form-dob-req-icon">*</span></label>
              </div>
            </div>
            <div className="vb-form-dropdown-div">
              <div style={{ width: window.innerWidth <= 479 ? '36%' : "33%" }}>
                <DropdownList aria-label="monthDropdown" 
                  id="ipayno_month"
                  data={verifyBevObj.fullMonthItems}
                  placeholder={iPayPayStmtNonPCSMBean.fullMonthId.placeHolder}
                  onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'MONTH'); return handleDataChange("iPayPayStmtNonPCSMBean", "fullMonthId", value, null) }}
                  value={iPayPayStmtNonPCSMBean.fullMonthId.value}
                />
              </div>
              <div style={{ width: window.innerWidth <= 479 ? '30%' : "33%", marginLeft: "2%" }}>
                <DropdownList aria-label="dayDropdown" 
                  id="ipayno_day"
                  data={verifyBevObj.fullDayItems}
                  placeholder={iPayPayStmtNonPCSMBean.fullDayId.placeHolder}
                  onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'DAY'); return handleDataChange("iPayPayStmtNonPCSMBean", "fullDayId", value, null) }}
                  value={iPayPayStmtNonPCSMBean.fullDayId.value}
                />
              </div>
              <div className="vb-form-dropdown-year-div">
                <DropdownList aria-label="yearDropdown" 
                  id="ipayno_year"
                  data={verifyBevObj.w2YearList}
                  placeholder={iPayPayStmtNonPCSMBean.fullYearId.placeHolder}
                  onChange={(value) => { ReactGA.sendevent(gaCategory, 'DROPDOWN_SELECT', 'YEAR'); return handleDataChange("iPayPayStmtNonPCSMBean", "fullYearId", value, null) }}
                  value={iPayPayStmtNonPCSMBean.fullYearId.value}
                />
              </div>
            </div>
          </div>
        </div>
          {/* Date of Birth component end */}


        </div>)}

        {/*  RADIO NO component END */}




      </div>)


  }
}
