import { handleActions } from 'redux-actions';
import { types } from './find-me-mobile-actions';

const {
  FIND_ME_MOBILE_INFO_OPTIONS_SUCCESS,
  FIND_ME_MOBILE_INFO_OPTIONS_FAIL


} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  findMeMobileObj: { showDiv: false }

};

export default handleActions(
  {
    [FIND_ME_MOBILE_INFO_OPTIONS_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [FIND_ME_MOBILE_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload })

  },
  initialState,
);
