import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'VERIFY_USER_PIN',
  'VERIFY_USER_PIN_SUCCESS',
  'VERIFY_USER_PIN_FAIL',
  'SET_USER_PIN',
  'ENABLE_USERPIN_SUBMIT_BUTTON',
  'CANCEL_REGISTRATION_VERIFY_USERPIN',  
  'CHECK_ERROR_MESSAGE'
);

const { VERIFY_USER_PIN, SET_USER_PIN , ENABLE_USERPIN_SUBMIT_BUTTON , CANCEL_REGISTRATION_VERIFY_USERPIN , CHECK_ERROR_MESSAGE } = types;

const verifyUserPinSent = createAction(VERIFY_USER_PIN);

const setUserPinSent = createAction(SET_USER_PIN);

const enableSubmitButtonSent = createAction(ENABLE_USERPIN_SUBMIT_BUTTON);

const cancelRegistrationSent = createAction(CANCEL_REGISTRATION_VERIFY_USERPIN);

const checkUserPinErrMessageSent = createAction(CHECK_ERROR_MESSAGE);

const verifyUserPin = inputUserPin => verifyUserPinSent(inputUserPin);

const setUserPin = inputUserPin => setUserPinSent({ userPin: inputUserPin });

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelRegistration = () => cancelRegistrationSent();

const checkUserPinErrMessage = () => checkUserPinErrMessageSent();

export const actions = {
  verifyUserPin,
  setUserPin,
  enableSubmitButton,
  cancelRegistration,
  checkUserPinErrMessage
};
