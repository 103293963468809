import React from 'react';
import PropTypes from 'prop-types';
import TimesIcon from 'adp-react-icons/lib/fa/times-circle';

export const StrengthTextComponent = (props) => {
  const { children, strengthTextColor } = props;

  return (
    <span style={ { fontWeight: 600, color: strengthTextColor } }>
      { children }
      &nbsp; &nbsp;
    </span>
  );
};

export const StrengthTextInfoPromptsComponent = (props) => {
  const { children } = props;

  return (
    <span
      style={ {
        color: '#4a4a4a',
      } }
    >
      { children }
    </span>
  );
};

export const PasswordRule = (props) => {
  const { valid, strengthTextColor, children } = props;

  return (
    <li className="vdl-password-strength__rule">
      <div style={ { color: strengthTextColor } }>
       <p role="status"> <span className="vdl-password-strength__rule-icon">{ !valid && <TimesIcon /> }</span>
        { children }</p>
      </div>
    </li>
  );
};

PasswordRule.propTypes = {
  valid: PropTypes.bool,
  strengthTextColor: PropTypes.string,
  children: PropTypes.node,
};

StrengthTextComponent.propTypes = {
  children: PropTypes.element,
  strengthTextColor: PropTypes.string,
};

StrengthTextInfoPromptsComponent.propTypes = {
  children: PropTypes.element,
};
