export const constants = {
  getContactDetailsFeedDataEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/contactInfo/getFeedData',
  getVerifyContactDetailsEndPoint: '/pages/sms/ess/v3/ws/rest/pub/ssr/contactInfo/validate',
  contactInfoIds: {
      fieldSet:0,
      cCode:1,    
     emailTypeSet:2,
     mobileTypeSet:3,
     radioSet:4,
    extnSet: 5,
	 phoneSet: 6,
    PATHCONTACTFLOW: '/ssr/PIC/contactinfo',
    PATHCONTACTC1FLOW: '/ssr/CAPITALONE/contactinfo'
  },
  getVerifyPrcGenerateCodeAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/generateCode',
  getVerifyPrcSubmitCodeAPI: '/pages/sms/ess/v3/ws/rest/pub/ssr/verifyPrc/verifyCode',
  TYPE_SUCCESS: 'SUCCESS',
  TYPE_ERROR_INVALID: "Error_SecCodeInvalid",
  TYPE_ERROR_EXPIRED: "Error_SecurityCodeExpired",
  PROOFING_PERSONAL_PHONE: 'PROOFING_PERSONAL_PHONE',
    
};
