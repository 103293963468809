import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert
} from '@synerg/vdl-react-components';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { injectIntl, intlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-kba/verify-kba-constants';
import ChevronLeft from 'adp-react-icons/lib/fa/chevron-left';
import FaAngleRight from 'adp-react-icons/lib/fa/angle-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
let answerCount = 0;
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

class VerifyKbaForm extends Component {

  componentWillMount() {
    this.props.getDisclaimerInfo();
    WindowUtil.setlayoutback(false);
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sendRedirect)
      nextProps.setRedirect();
    if (nextProps.sendCancelRedirect)
      nextProps.cancelAndCheckYourEntries();
    this.timerId != undefined ? this.timerId.setTimer() : undefined;

  }


  render() {
    const {
      verifyKbaObj,
      setAnswerSelected,
      setCancelErrorModal,
      setOnTimeExpired,
      setDisableFields,
      cancelAndCheckYourEntries,
      setConfirmModal,
      showModalCancel,
      getVerfifyKbaPrcRedirect,
      gaCategory,
      cancelKbaCheckYourEntries,
      progressBarData,
      isMobile
    } = this.props;


    const disclaimerCancel = () => {
      if (this.props.fromPrc) {
        ReactGA.sendevent(gaCategory, 'DISCLAIMER_CANCEL', 'PRC');
        return getVerfifyKbaPrcRedirect();
      }
      else {
        ReactGA.sendevent(gaCategory, 'DISCLAIMER_CANCEL', 'LOGIN');
        return cancelAndCheckYourEntries();
      }
    }


    const answerSelected = (index) => {
      if (this.props.disableFields) return;
      return setAnswerSelected(index);
    }

    const onCancelErrorModal = () => {
      let sendRedirect = true;
      return setCancelErrorModal(sendRedirect);
    }

    const onTimeExpired = () => {
      disableFields();
      ReactGA.sendevent(gaCategory, 'VERIFY_KBA', 'TIME_EXPIRED');
      return setOnTimeExpired();
    }

    const disableFields = () => {
      let disableFields = true;
      return setDisableFields(disableFields);
    }

    const cancelYourEntries = () => {
      ReactGA.sendevent(gaCategory, 'VERIFY_KBA', 'CANCEL');
      let showModalCancel = true;
      return setConfirmModal(showModalCancel);
    }
    const onNoModalError = () => {
      ReactGA.sendevent(gaCategory, 'VERIFY_KBA_CANCEL', 'NO');
      let showModalCancel = false;
      return setConfirmModal(showModalCancel);
    }
    return (

      <div>
        {!verifyKbaObj.showDisclaimer && verifyKbaObj.showDiv && (
          <div className="registration-content">
            <div className="registration-form-container" >
              { 
              (window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
               }
              {
               (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
               }
              <div className="vk-cancel-icon focus-selector" tabIndex = "0" onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        return cancelYourEntries();
                      }
                    }}>
                <svg id="kba_cancel" onClick={cancelYourEntries} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                  }}>
                  <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                </svg>
              </div>
            </div>
            {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
              <div className="registration-progress-bar">
                <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
              </div>
            )}
            {(!isMobile) && <hr className="header-hr" />}
            <div className="vdl-row registration-form">
              <div className="welcome-title title-width vk-margin-h">
                <div className="vdl-col-md-12">
                  <h1 style={{ marginBottom: "0px" }} id="registration-layout_welcome" className="page-title">{<FormattedMessage id="Lbl_AnswerKBAQuestion" />}</h1>
                </div>
              </div>


              {/*********************Verify Kba  page start *****************************/}
              <div className="vk-form-padding padding-align-inc-width vk-page-form-div">
                <div className="vk-timer-div">
                  <Timer noOfSecs={verifyKbaObj.kbaQuestExpiryTime} ref={instance => { this.timerId = instance; }} onFinalCount={onTimeExpired} isBlocked={verifyKbaObj.isBlocked} />
                </div>
                <div className="heading-text vk-heading vk-answersobj-div button-padding" tabIndex="0" id="promptId">{verifyKbaObj.prompt}</div>
                {verifyKbaObj.answers &&
                 <div id='kba_answers'> 
                  {verifyKbaObj.answers.map((value, index) => (
                  
                    <div role="button" tabIndex="0" id={"p" + value.answerID} key={"p" + index} onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        answerCount = answerCount + 1;
                        ReactGA.sendevent(gaCategory, 'CLICK', 'ANSWER' + answerCount);
                        return answerSelected(index)
                      }
                    }}
                    onClick={() => {
                      answerCount = answerCount + 1;
                      ReactGA.sendevent(gaCategory, 'CLICK', 'ANSWER' + answerCount);
                      return answerSelected(index)
                    }}
                      className="vdl-row vk-row-size focus-selector highlight-options">
                      <div className="vk-col-11">
                        <div className="vk-text-label vk-font-size" ><span className="link-content">{value.answer}</span></div>
                      </div>
                      <FaAngleRight style={{ fontSize: "24px" }} className="link-content vk-col-1 vk-chevron-icon" />
                    </div>
                  
                  ))}
                  </div>   
                }
              </div>
              {/*********************Verify Kba  page start *****************************/}
            </div>
          </div>)}


        {/*********************Verify Kba   Disclaimer start *****************************/}

        {verifyKbaObj.showDisclaimer && (<div className="registration-content">
          <div className="registration-form-container" >
              { 
              (window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
               }
              {
               (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
               }
            {(!isMobile) && (
            <div tabIndex = "0" className="vk-cancel-icon focus-selector" onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                ReactGA.sendevent(gaCategory, 'DISCLAIMER_CANCEL', 'CANCEL');
                return cancelAndCheckYourEntries();
              }
            }}>
              <svg id="kba_disc_cancel" onClick={() => {
                ReactGA.sendevent(gaCategory, 'DISCLAIMER_CANCEL', 'CANCEL');
                return cancelAndCheckYourEntries();
              }} role="button"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                style={{
                  cursor: "pointer",
                  float: "right",
                  fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                }}>
                <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
              </svg>
            </div>
             )}
          </div>
          {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile) && (
            <div className="registration-progress-bar">
              <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
            </div>
          )}
          {(!isMobile) && <hr className="header-hr" />}

          <h1 id="disclaimerTitle" className="page-title vk-answerkba-title-div" >{<FormattedMessage id="Lbl_AnswerKBAQuestion" />}</h1>

          <div className="heading-text vk-challengen-verf-div">
            <div className="kba-info-text-align kba-text-spacing">{<FormattedMessage id="Txt_VChallengeVerificationIntro1" />}</div>
           
            <div className="kba-info-text-align">{<FormattedMessage id="Txt_VChallengeVerificationIntro2" />}</div>
          </div>


          <div className="vk-sbt-btn-div">
            <Button id="kba_disc_continueBtn" style={{ minHeight: "30px", minWidth: "130px" }} className="safari-button-align"
              onClick={() => {
                ReactGA.sendevent(gaCategory, 'DISCLAIMER', 'CONTINUE');
                return this.props.getVerifyKbaInfo();
              }}>
              {<FormattedMessage id="submitCBtn.Label" />}
            </Button>
          </div>


          <div className="vk-back-btn-div">
            <Button id="kba_disc_backBtn" className="primary cf-btn-cnt" style={{ textDecoration: "none", margin:"0px auto", paddingTop: "15px" }}
              buttonStyle="link" tabIndex = "0"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  return disclaimerCancel();
                }
              }}
              onClick={disclaimerCancel}>
                <ChevronLeft className="back-icon-align"  />
                <span className="fm-pii-back-span">{<FormattedMessage id="findMe.backBtn" />}</span>
            </Button>
          </div>

        </div>)}


        {/*********************Verify Kba  Disclaimer end *****************************/}

        {/********************Error modal KBA page start  *****************************/}
        {verifyKbaObj.showModalMessage && (<Modal id="errorModal"
          className="vdl-modal--alert"
          show={verifyKbaObj.showModalError}
          onEnter={() => { WindowUtil.applyBrandingChanges(); }}
        >
          <ModalHeader closeButton={false} className="modal-header">
            <ModalTitle className='modal-title-error'>
              <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
              {verifyKbaObj.showModalTitle}
            </ModalTitle>
          </ModalHeader>
          <ModalBody className="modal-body-footercolor">
            <div>
              <label className="modal-text">{ReactHtmlParser(verifyKbaObj.showModalMessage)}</label>
            </div>
          </ModalBody>
          <ModalFooter className="modal-body-footercolor">
            <Button id="kba_okBtn" onClick={cancelAndCheckYourEntries} className="safari-button-align">
              {<FormattedMessage id="Lbl_OkButton" />}
            </Button>
          </ModalFooter>
        </Modal>)}

        {/*********************Error modal KBA page end*****************************/}


        {/********************Cancel KBA modal start  *****************************/}
        <Modal id="cancelModal"
          className="vdl-modal--alert"
          show={showModalCancel}
          onEnter={() => { WindowUtil.applyBrandingChanges(); }}
        >
          <ModalHeader closeButton={false} className="modal-header">
            <ModalTitle className='modal-title-error'>
              <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
              {<FormattedMessage id="Msg_ConfirmCancelSSR" />}
            </ModalTitle>
          </ModalHeader>
          <ModalBody className="modal-body-footercolor">
            <div>
              <label className="modal-text">
                {<FormattedMessage id="Msg_ConfirmCancelSSRKBAInfo1" />}
                <div className="button-padding">{ReactHtmlParser(verifyKbaObj.contactAdminMsg)}</div>
              </label>
            </div>
          </ModalBody>
          <ModalFooter className="modal-body-footercolor">
            <Button id="kba_yesBtn" onClick={onNoModalError} className="safari-button-align">
              {<FormattedMessage id="Msg_VCompanyIdentificationNo" />}
            </Button>
            <Button className="safari-button-align" onClick={() => {
              ReactGA.sendevent(gaCategory, 'VERIFY_KBA_CANCEL', 'YES');
              return cancelKbaCheckYourEntries()
            }}>
              {<FormattedMessage id="Msg_VCompanyIdentificationYes" />}
            </Button>
          </ModalFooter>
        </Modal>

        {/*********************Cancel KBA modal page end*****************************/}




      </div>



    );
  }
}
export default injectIntl(VerifyKbaForm);