// needed for IE
import '@babel/polyfill';
import 'custom-event-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-intl-redux';
import './scss/smsess-react-ui.scss';
import App from './js/App';
import { history, store as createStore } from './js/redux/store';
import { actions as initialLoadActions } from './js/redux/ducks/initial-load';

const render = () => {
  ReactDOM.render(
    <Provider store={ store }>
      <App history={ history } />
    </Provider>,
    document.getElementById('app'),
  );};
const store = createStore(render);

const { initialLoad } = initialLoadActions;

store.dispatch(initialLoad());

