import { handleActions } from 'redux-actions';
import { types } from './timeout-actions';

const {
  SESSION_TIMEOUT,
  SESSION_TIMEOUT_WARN,
  GET_SESSION_TIMEOUT_CONFIG_SUCCESS,
  UPDATE_SESSION_TIMEOUT,
} = types;

export const initialState = {
  timeout: false,
  timeoutWarn: false,
  signoutTime: null,
  warningTime: null,
  userActivityCutoff: null,
  timeoutWarning: true,
};

export default handleActions(
  {
    [SESSION_TIMEOUT]: (state, { payload }) => {
      const timeout = payload.timeout;
      const timeoutWarn = payload.timeoutWarn;

      return {
        ...state, ...payload, timeout, timeoutWarn,
      };
    },
    [SESSION_TIMEOUT_WARN]: (state, { payload }) => {
      const timeout = payload.timeout;
      const timeoutWarn = payload.timeoutWarn;

      return {
        ...state, ...payload, timeout, timeoutWarn,
      };
    },
    [GET_SESSION_TIMEOUT_CONFIG_SUCCESS]: (state, { payload }) => {
      const warningTime = payload.warningTime;
      const signoutTime = payload.signoutTime;
      const userActivityCutoff = payload.userActivityCutoff;

      return {
        ...state, ...payload, warningTime, signoutTime, userActivityCutoff,
      };
    },
    [UPDATE_SESSION_TIMEOUT]: (state, { payload }) => {
      payload = payload.payload;
      const timeoutWarn = payload.timeoutWarn;
      const timeout = payload.timeout;
      const reloadTimeout = payload.reloadTimeout;
      return {
        ...state, timeoutWarn, timeout, reloadTimeout
      };
    },
  },
  initialState,
);
