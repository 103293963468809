import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BusyIndicator,
  Textbox,
  Popover,
  OverlayTrigger,
  DropdownList,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  ModalTitle,
  Alert,
  Tooltip
} from '@synerg/vdl-react-components';
import FaArrowDown from 'adp-react-icons/lib/fa/arrow-down';
import ReactHtmlParser from 'react-html-parser';
import WarnIcon from 'adp-react-icons/lib/fa/exclamation-triangle';
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { WindowUtil } from '../../util';
import { ReactGA } from '../../util';
import { constants } from '../../redux/ducks/verify-bev/verify-bev-constants';
import ChevronRight from 'adp-react-icons/lib/fa/chevron-right';
import Timer from '../../components/timer';
import TimesIcon from 'adp-react-icons/lib/fa/times';
import InfoCircle from 'adp-react-icons/lib/fa/info-circle';
import Draggable from 'react-draggable';
import WindowClose from 'adp-react-icons/lib/fa/window-close';
import VerifyBevFreedomForm from './VerifyBevFreedomForm';
import VerifyBevEtimeForm from './VerifyBevEtimeForm';
import VerifyBevHRNetForm from './VerifyBevHRNetForm';
import VerifyBevW2Form from './VerifyBevW2Form';
import VerifyBevPCSNonPCSForm from './VerifyBevPCSNonPCSForm';
import VerifyBevIpayW2Form from './VerifyBevIpayW2Form';
import Recaptcha from 'react-recaptcha';
import Stepper from '../../components/react-stepper-horizontal';
import SecurePage from '../../components/secure-page';
import AdpIcon from 'adp-react-icons/lib/adp/adp';

const isBlank = str => (str || '').trim().length === 0;
let setAutoFocusOff;
let showAlertFlag = false;
let overlayPlacement = "right";

let downIndicator;
let continueButton;
//let stickyBottom = '300px';

class VerifyBevForm extends Component {

  componentWillMount() {
    if(!WindowUtil.getlayoutback()){
    this.props.getVerifyBevInfo();
  }
  WindowUtil.setlayoutback(false);
    if (window.innerWidth <= 450) {
      overlayPlacement = "bottom";
    }
    setAutoFocusOff = WindowUtil.getAutoFocusOff();
  }

  componentDidMount() {
    this.downIndicatorUpdate();
    window.addEventListener('scroll', this.scrollFunction, true);
  }

  componentDidUpdate() {
    window.addEventListener('scroll', this.scrollFunction,true);
    this.downIndicatorUpdate();
  }

  componentWillReceiveProps = (nextProps) => {
    window.addEventListener('scroll', this.scrollFunction,true);
    this.downIndicatorUpdate();
    if (nextProps.sendRedirect)
      nextProps.setRedirectPage();

    if (nextProps.verifyBevObj.showAlert && showAlertFlag) {
      showAlertFlag = false;
      window.scrollTo(0, 0);
    }

    this.checkFormReadyness(nextProps);

  }
  downIndicatorUpdate = () => {
    downIndicator = document.getElementById("downIndicator");
    continueButton = document.getElementById("bev-form");
    if (window.innerWidth <= 479) {
      if (continueButton != undefined && continueButton !== null) {
        if (window.innerHeight > continueButton.scrollHeight) {
          downIndicator.style.display = "none";
        }
        else {
          if(document.documentElement.scrollTop < document.documentElement.scrollHeight-window.innerHeight-1)
          {  downIndicator.style.display = "block";}
          else{
            downIndicator.style.display = "none";
        }
        }
      }
    }
  }

  checkFormReadyness = (nextProps) => {
    let isFormValid = true;
    if (nextProps.verifyBevObj.showDiv) {
      let verifyBevObj = nextProps.verifyBevObj;
      if (verifyBevObj.initData.freedomMBean.rendered) {
        let freedomMBean = nextProps.verifyBevObj.initData.freedomMBean;
        if ((freedomMBean.employeeNumber.required && (verifyBevObj.errorFields[0] || isBlank(freedomMBean.employeeNumber.value)))
          || (!freedomMBean.employeeNumber.required && verifyBevObj.errorFields[0])) {
          isFormValid = false;
        }
        if ((!isBlank(freedomMBean.niNumber.value) && verifyBevObj.errorFields[1])) {
          isFormValid = false;
        }
        if (isBlank(freedomMBean.fullDayId.value)) {
          isFormValid = false;
        }
        if (isBlank(freedomMBean.fullMonthId.value)) {
          isFormValid = false;
        }
        if (isBlank(freedomMBean.fullYearId.value)) {
          isFormValid = false;
        }
        if ((freedomMBean.postalCode.required && (verifyBevObj.errorFields[2] || isBlank(freedomMBean.postalCode.value)))
          || (!freedomMBean.postalCode.required && verifyBevObj.errorFields[2])) {
          isFormValid = false;
        }


      }
      if (verifyBevObj.initData.etimeBev.rendered) {
        let etimeBev = nextProps.verifyBevObj.initData.etimeBev;
        if ((etimeBev.employee.required && (verifyBevObj.errorFields[0] || isBlank(etimeBev.employee.value)))
          || (!etimeBev.employee.required && verifyBevObj.errorFields[0])) {
          isFormValid = false;
        }

      }



      if (verifyBevObj.initData.hrNetMBean.rendered) {
        let hrNetMBean = nextProps.verifyBevObj.initData.hrNetMBean;
        if ((hrNetMBean.employeeNumber.required && (verifyBevObj.errorFields[0] || isBlank(hrNetMBean.employeeNumber.value)))
          || (!hrNetMBean.employeeNumber.required && verifyBevObj.errorFields[0])) {
          isFormValid = false;
        }
        if ((!isBlank(hrNetMBean.niNumber.value) && verifyBevObj.errorFields[1])) {
          isFormValid = false;
        }
        if (isBlank(hrNetMBean.fullDayId.value)) {
          isFormValid = false;
        }
        if (isBlank(hrNetMBean.fullMonthId.value)) {
          isFormValid = false;
        }
        if (isBlank(hrNetMBean.fullYearId.value)) {
          isFormValid = false;
        }
        if ((hrNetMBean.postalCode.required && (verifyBevObj.errorFields[2] || isBlank(hrNetMBean.postalCode.value)))
          || (!hrNetMBean.postalCode.required && verifyBevObj.errorFields[2])) {
          isFormValid = false;
        }


      }

      if (verifyBevObj.initData.w2ServiceMBean.rendered) {
        let w2ServiceMBean = nextProps.verifyBevObj.initData.w2ServiceMBean;
        if (isBlank(w2ServiceMBean.w2Year.value)) {
          isFormValid = false;
        }
        if ((w2ServiceMBean.employeeId.required && (verifyBevObj.errorFields[0] || isBlank(w2ServiceMBean.employeeId.value)))
          || (!w2ServiceMBean.employeeId.required && verifyBevObj.errorFields[0])) {
          isFormValid = false;
        }
        if ((w2ServiceMBean.companyCode.required && (verifyBevObj.errorFields[1] || isBlank(w2ServiceMBean.companyCode.value)))
          || (!w2ServiceMBean.companyCode.required && verifyBevObj.errorFields[1])) {
          isFormValid = false;
        }
        if ((w2ServiceMBean.zipCode.required && (verifyBevObj.errorFields[2] || isBlank(w2ServiceMBean.zipCode.value)))
          || (!w2ServiceMBean.zipCode.required && verifyBevObj.errorFields[2])) {
          isFormValid = false;
        }
        if ((w2ServiceMBean.ssn.required && (verifyBevObj.errorFields[3] || isBlank(w2ServiceMBean.ssn.value)))
          || (!w2ServiceMBean.ssn.required && verifyBevObj.errorFields[3])) {
          isFormValid = false;
        }

      }

      if (verifyBevObj.initData.iPayPayStmtNonPCSMBean.rendered) {
        if (isBlank(verifyBevObj.radio)) {
          isFormValid = false;
        }
        if (verifyBevObj.isIPayYes && verifyBevObj.iPayShow) {
          let iPayPayStmtMBean = nextProps.verifyBevObj.initData.iPayPayStmtMBean;

          if ((iPayPayStmtMBean.co.required && (verifyBevObj.errorFields[0] || isBlank(iPayPayStmtMBean.co.value)))
            || (!iPayPayStmtMBean.co.required && verifyBevObj.errorFields[0])) {
            isFormValid = false;
          }
          if ((iPayPayStmtMBean.fileNumber.required && (verifyBevObj.errorFields[1] || isBlank(iPayPayStmtMBean.fileNumber.value)))
            || (!iPayPayStmtMBean.fileNumber.required && verifyBevObj.errorFields[1])) {
            isFormValid = false;
          }
          if (isBlank(iPayPayStmtMBean.fullDayId.value)) {
            isFormValid = false;
          }
          if (isBlank(iPayPayStmtMBean.fullMonthId.value)) {
            isFormValid = false;
          }
          if (isBlank(iPayPayStmtMBean.fullYearId.value)) {
            isFormValid = false;
          }

          if ((iPayPayStmtMBean.adviceNumber.required && (verifyBevObj.errorFields[2] || isBlank(iPayPayStmtMBean.adviceNumber.value)))
            || (!iPayPayStmtMBean.adviceNumber.required && verifyBevObj.errorFields[2])) {
            isFormValid = false;
          }

          if ((iPayPayStmtMBean.ssn.required && (verifyBevObj.errorFields[3] || isBlank(iPayPayStmtMBean.ssn.value)))
            || (!iPayPayStmtMBean.ssn.required && verifyBevObj.errorFields[3])) {
            isFormValid = false;
          }
        }
        else {
          let iPayPayStmtNonPCSMBean = nextProps.verifyBevObj.initData.iPayPayStmtNonPCSMBean;

          if (isBlank(iPayPayStmtNonPCSMBean.fullDayId.value)) {
            isFormValid = false;
          }
          if (isBlank(iPayPayStmtNonPCSMBean.fullMonthId.value)) {
            isFormValid = false;
          }
          if (isBlank(iPayPayStmtNonPCSMBean.fullYearId.value)) {
            isFormValid = false;
          }

          if ((iPayPayStmtNonPCSMBean.co.required && (verifyBevObj.errorFields[0] || isBlank(iPayPayStmtNonPCSMBean.co.value)))
            || (!iPayPayStmtNonPCSMBean.co.required && verifyBevObj.errorFields[0])) {
            isFormValid = false;
          }

          if ((iPayPayStmtNonPCSMBean.fileNumber.required && (verifyBevObj.errorFields[1] || isBlank(iPayPayStmtNonPCSMBean.fileNumber.value)))
            || (!iPayPayStmtNonPCSMBean.fileNumber.required && verifyBevObj.errorFields[1])) {
            isFormValid = false;
          }
          if ((iPayPayStmtNonPCSMBean.adviceNumber.required && (verifyBevObj.errorFields[2] || isBlank(iPayPayStmtNonPCSMBean.adviceNumber.value)))
            || (!iPayPayStmtNonPCSMBean.adviceNumber.required && verifyBevObj.errorFields[2])) {
            isFormValid = false;
          }
          if ((iPayPayStmtNonPCSMBean.ssn.required && (verifyBevObj.errorFields[3] || isBlank(iPayPayStmtNonPCSMBean.ssn.value)))
            || (!iPayPayStmtNonPCSMBean.ssn.required && verifyBevObj.errorFields[3])) {
            isFormValid = false;
          }
        }


      }

      if (verifyBevObj.initData.iPayW2ServiceMBean.rendered) {
        let iPayW2ServiceMBean = nextProps.verifyBevObj.initData.iPayW2ServiceMBean;

        if (isBlank(iPayW2ServiceMBean.w2Year.value)) {
          isFormValid = false;
        }
        if ((iPayW2ServiceMBean.controlNumber.required && (verifyBevObj.errorFields[0] || isBlank(iPayW2ServiceMBean.controlNumber.value)))
          || (!iPayW2ServiceMBean.controlNumber.required && verifyBevObj.errorFields[0])) {
          isFormValid = false;
        }
        if ((iPayW2ServiceMBean.ssn.required && (verifyBevObj.errorFields[1] || isBlank(iPayW2ServiceMBean.ssn.value)))
          || (!iPayW2ServiceMBean.ssn.required && verifyBevObj.errorFields[1])) {
          isFormValid = false;
        }
        if ((iPayW2ServiceMBean.wages.required && (verifyBevObj.errorFields[2] || isBlank(iPayW2ServiceMBean.wages.value)))
          || (!iPayW2ServiceMBean.wages.required && verifyBevObj.errorFields[2])) {
          isFormValid = false;
        }
      }


      if (isBlank(nextProps.verifyBevObj.firstName)) isFormValid = false;
      if (isBlank(nextProps.verifyBevObj.lastName)) isFormValid = false;
      if (isBlank(nextProps.verifyBevObj.serviceSelected)) isFormValid = false;
      if (nextProps.verifyBevObj.isRecaptchaOn == true && nextProps.recaptchaResponse == null) isFormValid = false;

    }
    this.props.enableSubmitButton(isFormValid);
  };

  handleRecaptchaResponse(value) {
    this.props.setRecaptchaResponse(value);
  }

  callback(value) {
    console.log('value callback======');
  }

  handleRecaptchaExpired(value) {
    this.props.setRecaptchaResponse(null);
  }

  scrollFunction = (nextProps) => {
    if (window.innerWidth <= 479) {
      if (downIndicator !== null && downIndicator !== undefined) {
        if (document.documentElement.scrollTop < document.documentElement.scrollHeight-window.innerHeight-1) {
          downIndicator.style.display = "block";
          } else {
           
            downIndicator.style.display = "none";
          }
      }
    }
  }

  render() {
    const {
      verifyBevObj,
      hideDraggableSet,
      showDraggableModal,
      showDraggable,
      onDropDownSelect,
      isFormValid,
      handleUpdateData,
      recaptchaResponse,
      isRecaptchaOn,
      onBevSubmitBtn,
      cancelAndCheckYourEntries,
      isMobileBrowser,
      isMobile,
      gaCategory,
      progressBarData,
      footer,

    } = this.props;

    const onDropDownChange = (value) => {
      if (window.innerWidth <= 479) {
        downIndicator.style.display = "block";
      }
      if (!verifyBevObj.initData.productMBean.hasMultipleBEVProducts) {
        return;
      }
      ReactGA.sendevent(gaCategory, 'CLICK', 'SERVICE_NAME');
      (this.recaptchaInstance != undefined) ? this.recaptchaInstance.reset() : undefined;
      return onDropDownSelect(value);
    }

    const bottomFunction = () => {
      document.body.scrollTop = document.body.scrollHeight - document.body.clientHeight;
      document.documentElement.scrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    }

    const onBevSubmit = () => {
      showAlertFlag = true;
      return onBevSubmitBtn();
    }
    const gaToolTip = (label) => ReactGA.sendevent(gaCategory, label, 'TOOLTIP');

    const tooltip = (message) =>
      (<Popover id="tooltip" className="vb-tooltip-align-identity">
        <div className="popover-align" role="alert" aria-label="tooltip">{ReactHtmlParser(message)}</div>
      </Popover>
      );
    const handleDraggable = (value) => {
      let showDraggable = value;
      showDraggableModal(showDraggable);
    }

    const handleDataChange = (fieldName, fieldValue) => {
      let payload = {};
      payload.type = 1;
      payload.fieldName = fieldName;
      payload.fieldValue = fieldValue;
      WindowUtil.setFirstAttemptGA({ gaCategory: gaCategory, fieldName: fieldName });
      return handleUpdateData(payload);
    }
    const _handleKeyPress = (e) => {
      if (e.key === 'Enter' && isFormValid) {
        e.preventDefault();
        onBevSubmit();
      }

    }

    return (
      verifyBevObj.showDiv && (
        <form onKeyPress={_handleKeyPress} id="bev-form" >
          {(window.innerWidth <= 479) && (<Button onClick={bottomFunction} id="downIndicator" 
          className={(footer != undefined && footer.showFooterContent != undefined && footer.showFooterContent === true)?"downIndicatorMobileWeb":null}
          style={{ cursor: "pointer" }} aria-label="Scroll to bottom" >
           <FaArrowDown className="down-arrow" />
          </Button>)}
          <div id="bevId" className="registration-content vb-registration-content-div" >
            <div>
              <div className="registration-form-container" >
              {(window.innerWidth>479) && <div className="secure-page-div"> <SecurePage gaCategory={gaCategory} /></div>
               }
              {
               (window.innerWidth<=479) && <div className="adp-icon-padding"> <AdpIcon id="adpIcon" style={{ display: 'block', color: '#d40f0f', float: 'right', fontSize: '20px' }} /></div>
               }
                {(!isMobile) && (
                  <div tabIndex = "0" className="reg-cancel-icon focus-selector" onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      ReactGA.sendevent(gaCategory, 'VERIFY_BEV', 'CANCEL');
                      return cancelAndCheckYourEntries();
                    }
                  }}>
                    <svg id="bev_cancel" onClick={() => {
                      ReactGA.sendevent(gaCategory, 'VERIFY_BEV', 'CANCEL');
                      return cancelAndCheckYourEntries()
                    }} role="button" aria-label={this.props.intl.formatMessage({ id: 'Lbl_CancelButton' })}
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.816 1043.776" height="1.5em" width="0.9828em" fill="currentColor"
                      style={{
                        cursor: "pointer",
                        float: "right",
                        fontSize: "14px", verticalAlign: "text-bottom", display: "inline-block;", overflow: "visible;"
                      }}>
                      <g><path d="M36.69 1030.16L.124 993.594 989.25 4.468l36.566 36.566L36.69 1030.16zm989.016-36.532l-36.566 36.566L.014 41.068 36.58 4.502l989.126 989.126z"></path></g>
                    </svg>
                  </div>
                )}
              </div>
              {(progressBarData != undefined && progressBarData.showProgressBar && !isMobile)&&(
                <div className="registration-progress-bar">
                  <Stepper steps={progressBarData.steps} activeStep={progressBarData.activeStep} />
                </div>
              )}
             {(!isMobile)&&<hr className="header-hr" />}
            </div>
            <div className="vdl-row registration-form">
              <div className="welcome-title title-width">
                <div className="vdl-col-md-12">
                  <h1 id="registration-layout_welcome" className="page-title">{verifyBevObj.initData.headerLabel}</h1>
                </div>
              </div>
              <div className="vdl-col-md-12">
                <div className="adp-form padding-align-inc-width-bev vb-alertmsg-div" >

                  {(verifyBevObj.showAlert) && (<Alert style={{ width: '100%' }} type="error" id="alertId" >
                   <p role="alert" style={{marginTop: 0, marginBottom: 0}}> {ReactHtmlParser(verifyBevObj.showAlertMessage)}</p>
                  </Alert>
                  )}


                  {/* First Name start */}
                <div id={verifyBevObj.initData.firstNameValueLabel.label}>
                  <div role="group" aria-labelledby={verifyBevObj.initData.firstNameValueLabel.label} className="form-group form-align">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="firstnameLabel">{verifyBevObj.initData.firstNameValueLabel.label}
                          <span className="vb-form-req-icon">*</span>
                          <span role="button" aria-label="tooltip" id="bev_fn_ques" className="vb-question-circle" onClick={() => gaToolTip('FIRST_NAME')}>
                            <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(ReactHtmlParser(this.props.intl.formatMessage({ id: verifyBevObj.runAdminFlow === true ? 'verifyPic_run_linkdesc' :'verifyPic_linkdesc' })))} >
                              <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="input-group-full">

                      <Textbox autocomplete="given-name" aria-label={verifyBevObj.initData.firstNameValueLabel.label}
                        id="bev_fn_textBox" autoFocus = {setAutoFocusOff}
                        value={verifyBevObj.firstName}
                        onChange={(value) => handleDataChange("firstName", value)}
                      />
                    </div>
                    <div />
                  </div>
                </div>

                  {/*  First Name end */}

                  {/* Last Name start */}
                <div id={verifyBevObj.initData.lastNameValueLabel.label}>
                  <div role="group" aria-labelledby={verifyBevObj.initData.lastNameValueLabel.label} className="form-group form-align">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-6 vdl-col-xs-12">
                        <label id="lastnameLabel">{verifyBevObj.initData.lastNameValueLabel.label}
                          <span className="vb-form-req-icon">*</span>

                          <span role="button" aria-label="tooltip" id="bev_ln_ques" className="vb-question-circle" onClick={() => gaToolTip('LAST_NAME')}>
                            <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(this.props.intl.formatMessage({ id: 'verifyPic_linkdesc2' }))} >
                              <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="input-group-full">

                      <Textbox autocomplete="family-name" aria-label={verifyBevObj.initData.lastNameValueLabel.label}
                        id="bev_ln_textBox"
                        value={verifyBevObj.lastName}
                        onChange={(value) => handleDataChange("lastName", value)}

                      />
                    </div>
                    <div />
                  </div>
                </div>
                  {/* Last Name end */}


                  {/* Service name and doc start */}
                <div id={verifyBevObj.initData.serviceNameAndDoc.label}>
                  <div role="group" aria-labelledby={verifyBevObj.initData.serviceNameAndDoc.label} className="form-group form-align">
                    <div className="vdl-row">
                      <div className="vdl-col-sm-12 vdl-col-xs-12">
                        <label id="servicename">{verifyBevObj.initData.serviceNameAndDoc.label}
                          <span className="vb-form-req-icon">*</span>

                          <span role="button" aria-label="tooltip" id="bev_dropDown_ques" className="vb-question-circle" onClick={() => gaToolTip('SERVICE_NAME')}>
                            <OverlayTrigger placement={overlayPlacement} trigger={['click','focus']} isAriaExpanded={ false }
                              overlay={tooltip(verifyBevObj.initData.serviceNameAndDoc.toolTip)} >
                              <InfoCircle tabIndex="0" className="focus-selector" style={{ marginBottom: "2px" }} />
                            </OverlayTrigger>
                          </span></label>
                      </div>
                    </div>
                    <div className="cf-vdl-dropdown">
                      <DropdownList aria-label={verifyBevObj.initData.serviceNameAndDoc.label} 
                        id="bev_service_dropDown"
                        disabled={!verifyBevObj.initData.productMBean.hasMultipleBEVProducts}
                        data={verifyBevObj.serviceDropDownList}
                        placeholder={verifyBevObj.initData.serviceNameAndDoc.placeHolder}
                        onChange={onDropDownChange}
                        value={verifyBevObj.serviceSelected}
                      />
                    </div>
                    <div />
                  </div>
                </div>
                  {/* Service name and doc end */}

                  {/* ADP Freedom component start */}

                  {verifyBevObj.initData.freedomMBean.rendered && <VerifyBevFreedomForm  {...this.props} />}

                  {/* ADP Freedom component end */}


                  {/* ETime component start */}

                  {verifyBevObj.initData.etimeBev.rendered && <VerifyBevEtimeForm  {...this.props} />}

                  {/* ETime component end */}

                  {/* hrNet component start */}

                  {verifyBevObj.initData.hrNetMBean.rendered && <VerifyBevHRNetForm  {...this.props} />}

                  {/* hrNet component end */}

                  {/* W2 component start */}

                  {verifyBevObj.initData.w2ServiceMBean.rendered && <VerifyBevW2Form  {...this.props} />}

                  {/* W2 component end */}

                  {/* PCSNONPCS component start */}

                  {verifyBevObj.initData.iPayPayStmtNonPCSMBean.rendered && <VerifyBevPCSNonPCSForm  {...this.props} />}

                  {/* PCSNONPCS component end */}


                  {/* PCSNONPCS component start */}

                  {verifyBevObj.initData.iPayW2ServiceMBean.rendered && <VerifyBevIpayW2Form  {...this.props} />}

                  {/* PCSNONPCS component end */}
                  {verifyBevObj.isRecaptchaOn && (
                    <div className="form-group form-align" id="recaptcha">
                      <Recaptcha sitekey={verifyBevObj.sitekey} value={recaptchaResponse}
                        hl={verifyBevObj.locale} render="explicit"
                        ref={instance => { this.recaptchaInstance = instance; }}
                        verifyCallback={this.handleRecaptchaResponse.bind(this)}
                        onloadCallback={this.callback.bind(this)}
                        expiredCallback={this.handleRecaptchaExpired.bind(this)} />
                    </div>
                  )}

                </div>
              </div>

            </div>



            { (
              <div className="vb-form-sbt-btn-div"><Button id="bev_continueBtn"
                style={{ minHeight: "35px", minWidth: "180px" }}
                onClick={() => {
                  ReactGA.sendevent(gaCategory, 'VERIFY_BEV', 'SUBMIT');
                  return onBevSubmit()
                }}
                className="vb-submit safari-button-align" disabled={!isFormValid}>
                 {<FormattedMessage id="continueBtn.Label" />}
              </Button>
              </div>)}
          </div>



          {/*************************************ERROR MODAL*******************************************/}
          {verifyBevObj.showModalTitle && (<Modal id="errorModal"
            className="vdl-modal--alert"
            show={verifyBevObj.showModalError}
            onEnter={() => { WindowUtil.applyBrandingChanges(); }}
          >
            <ModalHeader closeButton={false} className="modal-header">
              <ModalTitle className='modal-title-error'>
                <WarnIcon className="vdl-modal__title-icon warn-icon-error" />
                {verifyBevObj.showModalTitle}
              </ModalTitle>
            </ModalHeader>
            <ModalBody className="modal-body-footercolor">
              <div>
                <label htmlFor="showModalMessage" className="modal-text">{ReactHtmlParser(verifyBevObj.showModalMessage)}</label>
              </div>
            </ModalBody>
            <ModalFooter className="modal-body-footercolor">
              <Button id="bev_okBtn" onClick={cancelAndCheckYourEntries} className="safari-button-align">
                {<FormattedMessage id="Lbl_OkButton" />}
              </Button>
            </ModalFooter>
          </Modal>)}

          {/*************************************ERROR MODAL*******************************************/}


        </form>
      )

    );
  }
}
export default injectIntl(VerifyBevForm);
