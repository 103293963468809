import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'GET_SIV_OPTIONS','GET_SIV_OPTIONS_SUCCESS','GET_SIV_OPTIONS_FAIL',
  'SET_SIV_FIELD',
  'SET_SIV_FIELD_SUCCESS',
  'SET_SIV_FIELD_FAIL',
  'VERIFY_SIV_OPTIONS',
  'VERIFY_SIV_OPTIONS_SUCCESS',
  'VERIFY_SIV_OPTIONS_FAIL',
  'ENABLE_SUBMIT_BUTTON',
  'CANCEL_AND_CHECK_YOUR_ENTRIES',
  'SET_RECAPTCHA_RESPONSE',
  'NAVIGATE_TO_BEV','NAVIGATE_TO_BEV_SUCCESS'
);

const {
  GET_SIV_OPTIONS,
  SET_SIV_FIELD,
  VERIFY_SIV_OPTIONS,
  ENABLE_SUBMIT_BUTTON,
  CANCEL_AND_CHECK_YOUR_ENTRIES,
  SET_RECAPTCHA_RESPONSE,
  NAVIGATE_TO_BEV
} = types;

const getSivOptionsSent = createAction(GET_SIV_OPTIONS);

const setSivFieldSent = createAction(SET_SIV_FIELD);

const verifySivOptionsSent = createAction(VERIFY_SIV_OPTIONS);

const enableSubmitButtonSent = createAction(ENABLE_SUBMIT_BUTTON);

const cancelAndCheckYourEntriesSent = createAction(CANCEL_AND_CHECK_YOUR_ENTRIES);

const setRecaptchaResponseSent = createAction(SET_RECAPTCHA_RESPONSE);

const navigateToBEVSent = createAction(NAVIGATE_TO_BEV);

const setSivField = (fieldkey, fieldValue) => setSivFieldSent({ fieldkey, fieldValue });

const verifySivOptions = () => verifySivOptionsSent();

const getSivOptions = () => getSivOptionsSent();

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();

const setRecaptchaResponse = recaptchaResponse => setRecaptchaResponseSent({recaptchaResponse});

const setCancelModalError = (showModalError) => setCancelModalSent({ showModalError });

const setCancelBevModalError = (showModalWhenBEVData) => enableSubmitButtonSent({ showModalWhenBEVData });

const navigateToBev = () => navigateToBEVSent();

const setCancelModalSent = createAction(ENABLE_SUBMIT_BUTTON);

export const actions = {
  getSivOptions,
  setSivField,
  verifySivOptions,
  enableSubmitButton,
  cancelAndCheckYourEntries,
  setRecaptchaResponse,
  setCancelModalError,
  setCancelBevModalError,
  navigateToBev
};
