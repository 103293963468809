import React, { Component } from 'react';
import { injectIntl} from 'react-intl';
import { FormattedMessage } from 'react-intl';
import {Button} from '@synerg/vdl-react-components';

class OTPFooter extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        
        return(
            <div className="otp-footer-div">
                <div className=" fm-otc-footer">
                 <hr style={{borderTop: '1px solid rgb(190, 190, 190)',marginBottom:'10px'}}/>
                    <div>
                        <span
                            className="heading-text vf-code-subpanel1 fm-otp-footer-span"
                            id="subTitle1"
                        >
                            {<FormattedMessage id={this.props.footerText} />}
                        </span>
                        <Button
                            style={{ fontSize: "14px" }}
                            className="vf-code-subpanel2"
                            id={this.props.idVal}
                            disabled={this.props.disableResendBtn}
                            className="fm-otc-footer-font opt-resend"
                            buttonStyle="link"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();
                                return this.props.btnClick();
                                }
                                }}
                            onClick={this.props.btnClick}

                        > {<FormattedMessage id={this.props.btnName} />}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(OTPFooter);