import { createTypes } from 'redux-action-types';
import { createAction } from 'redux-actions';
import { SMS_NAMESPACE } from '../constants';

export const types = createTypes(
  SMS_NAMESPACE,
  'GET_401k_SIV_OPTIONS','GET_401k_SIV_OPTIONS_SUCCESS','GET_401k_SIV_OPTIONS_FAIL',
  'SET_401k_SIV_FIELD',
  'SET_401k_SIV_FIELD_SUCCESS',
  'SET_401k_SIV_FIELD_FAIL',
  'VERIFY_401k_SIV_OPTIONS',
  'VERIFY_401k_ORG_RESPONSE',
  'VERIFY_401k_MULTIORG_RESPONSE',
  'ENABLE_401k_SUBMIT_BUTTON',
  'CANCEL_AND_CHECK_401k_ENTRIES',
  'SET_401k_RECAPTCHA_RESPONSE',
  'SET_401k_ORG_SELECTED','SET_401k_ORG_SELECTED_SUCCESS','SET_401k_ORG_SELECTED_FAIL',
  'LAUNCH_401k_IDENTITY','LAUNCH_401k_IDENTITY_SUCCESS','LAUNCH_401k_IDENTITY_FAIL',
  'VERIFY_401k_SIV_OPTIONS_FAIL'
);

const {
  GET_401k_SIV_OPTIONS,
  SET_401k_SIV_FIELD,
  VERIFY_401k_SIV_OPTIONS,
  ENABLE_401k_SUBMIT_BUTTON,
  CANCEL_AND_CHECK_401k_ENTRIES,
  SET_401k_RECAPTCHA_RESPONSE,
  SET_401k_ORG_SELECTED,
  LAUNCH_401k_IDENTITY
} = types;

const getSivOptionsSent = createAction(GET_401k_SIV_OPTIONS);

const setSivFieldSent = createAction(SET_401k_SIV_FIELD);

const verifySivOptionsSent = createAction(VERIFY_401k_SIV_OPTIONS);

const enableSubmitButtonSent = createAction(ENABLE_401k_SUBMIT_BUTTON);

const cancelAndCheckYourEntriesSent = createAction(CANCEL_AND_CHECK_401k_ENTRIES);

const setRecaptchaResponseSent = createAction(SET_401k_RECAPTCHA_RESPONSE);

const setOrgSelectedSent = createAction(SET_401k_ORG_SELECTED);

const launch401kIdentitySent = createAction(LAUNCH_401k_IDENTITY);

const setSivField = (fieldkey, fieldValue) => setSivFieldSent({ fieldkey, fieldValue });

const verifySivOptions = () => verifySivOptionsSent();

const getSivOptions = () => getSivOptionsSent();

const enableSubmitButton = isFormValid => enableSubmitButtonSent({ isFormValid });

const cancelAndCheckYourEntries = () => cancelAndCheckYourEntriesSent();

const setRecaptchaResponse = recaptchaResponse => setRecaptchaResponseSent({recaptchaResponse});

const setCancelModalError = (showModalError) => setCancelModalSent({ showModalError });

const setOrgSelected = (payload) => setOrgSelectedSent(payload);

const launch401kIdentity = () => launch401kIdentitySent();

const setCancelModalSent = createAction(ENABLE_401k_SUBMIT_BUTTON);

export const actions = {
  getSivOptions,
  setSivField,
  verifySivOptions,
  enableSubmitButton,
  cancelAndCheckYourEntries,
  setRecaptchaResponse,
  setCancelModalError,
  setOrgSelected,
  launch401kIdentity
};
