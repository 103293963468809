import { handleActions } from 'redux-actions';
import { types } from './contact-info-actions';

const {
  CONTACT_INFO_OPTIONS_SUCCESS,
  CONTACT_INFO_OPTIONS_FAIL,
  SET_CONTACT_FIELD_SUCCESS,
  SET_CONTACT_FIELD_FAIL,
  ENABLE_SECOUNDARY_FORM_SUCCESS,
  ENABLE_PHONE_FIELD_SUCCESS,
  ENABLE_SUBMIT_BUTTON,
  ON_FINAL_SUBMIT_SUCCESS,
  ON_FINAL_SUBMIT_FAILURE,
  SET_MODAL_FIELD,
  ON_FINAL_SUBMIT_ALERT_SUCCESS

} = types;

export const initialState = {
  flowType: 'SSR-REACT',
  contactInfoOptions: {
    "pemailRenderable": true,
    "semailRenderable": true,
    "bmobileRenderable": true,
    "pMobileRenderable": true,
    "bLandlineRenderable": false,
    "pLandlineRenderable": false,
    "emailItems": null,
    emailValidationPolicy: null,
    emailInvalidMessage: null,
    mobileValidationPolicy: null,
    mobileInvalidMessage: null
  },
  transactionId: '',
  contactInfoObj: {
    phoneCount: 2,
    pEmailValid: true,
    bMobileValid: true,
    sEmailValid: true,
    pMobileValid: true,
    bLandlineValid: true,
    pLandlineValid: true,
    showSecoundaryForm: false,
    phoneHistory: [null, null, null, null],
    phoneHistoryDisable: [false, false, false, false],
    bMobileRequired: false,
    peType: null,
    bmType: null,
    errorMessage: [],
    showAlert: false,
    showAlertMessage: null,
    fieldValid: [true, true, true, true, true, true],
    fieldDisable: [false, false, false, false, false],
    showModalMessage: [],
    tsTypes: [],
    tsEligible: false,
    radio1: null, radio2: null, radio3: null, radio4: null, radio5: null,
    showNudgePage:false
  }
};

export default handleActions(
  {
    [CONTACT_INFO_OPTIONS_SUCCESS]: (state, { payload }) => {
      const contactInfoOptions = payload.contactInfoOptions;
      const contactInfoObj = payload.contactInfoObj;
      const isMobile = payload.isMobile;
      const isMobileBrowser = payload.isMobileBrowser;
      const gaCategory = payload.gaCategory;
      const isAuthorized = payload.isAuthorized;
      return ({ contactInfoOptions, contactInfoObj, isMobile, isMobileBrowser, gaCategory, isAuthorized })
    },
    [CONTACT_INFO_OPTIONS_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_CONTACT_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_CONTACT_FIELD_FAIL]: (state, { payload }) => ({ ...state, ...payload }),
    [ENABLE_PHONE_FIELD_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [ENABLE_SUBMIT_BUTTON]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [ENABLE_SECOUNDARY_FORM_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),
    [ON_FINAL_SUBMIT_SUCCESS]: (state, { payload }) => ({ ...state, ...payload }),

    [ON_FINAL_SUBMIT_ALERT_SUCCESS]: (state, { payload }) => {
      return ({ ...state, ...payload, contactInfoObj: payload.contactInfo.contactInfoObj, contactInfoOptions: payload.contactInfo.contactInfoOptions })
    },
    [ON_FINAL_SUBMIT_FAILURE]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_MODAL_FIELD]: (state, { payload }) => ({ ...state, ...payload })

  },
  initialState,
);
